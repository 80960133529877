

import React from 'react';
import PropTypes from 'prop-types';
import { SportsLimitsGeneral } from './SportsLimitsGeneral';
import { Card } from '../../../../Centrum/Card/Card';
import { Button } from '../../../../Controls/Buttons/Button';
import { application, Endpoints } from '../../../../Application/Application';



export class SportsLimits extends React.Component {
    constructor(props) {
        super(props);

        var state = Object.assign({}, props);
        this.state = state;
    }

    componentDidMount() {
        try {
            application().resolve(null, Endpoints.GetApplicationSportLimits, {}, 'fetching limits', 'unable to fetch limits').then((limits) => {
                if (!limits.result) {
                    limits.result = {
                        general: {},
                        groups: {}
                    };
                } else {
                    limits.result = JSON.parse(limits.result);
                }

                this.setState({ limits: limits.result });
            });
        } catch (err) {
            console.log(err);
        }
    }
    save() {
        const limits = this.generalLimits.getData();

        try {
            this.setState({ limits: limits }, () => {
                application().resolve(null, Endpoints.SaveApplicationSportLimits, limits, 'saving application limits', 'unable to save limits').then(() => {
                });
            });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        if (!this.state.limits) return <React.Fragment />;
        return <React.Fragment>
            <SportsLimitsGeneral limits={this.state.limits} saveEnabled={false} ref={(r) => this.generalLimits = r} />
            <Card className='margin-top centrum-flex'>
                <Button title='Save' type='submit' className='margin alignRight' onClick={() => this.save()} />
            </Card>
        </React.Fragment >;
    }
}

SportsLimits.defaultProps = {
    selectedCurrency: 'USD',
    limits: null
};

SportsLimits.propTypes = {
    selectedCurrency: PropTypes.string,
    limits: PropTypes.object
};

