import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { Card } from '../../Centrum/Card/Card';
import { currency, lang } from '../../Centrum/Localization/lang';
import { Search } from '../../Centrum/Search/Search';
import { apiResult, application, Endpoints } from '../../Application/Application';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { RetailTypes } from './common';
import { ActionTypes, getAction } from '../../Centrum/Common/ActionManager';
import { getHistory } from '../../routers/AppRouter';
import { RetailOperations } from './Components/RetailOperations/RetailOperations';
import { toPascalCase } from '../../Centrum/helpers';
import { getStore } from '../../..';


const searchRetailModel = {
    fields: [
        {
            name: 'Type',
            display: 'Type',
            type: InputTypes.select,
            values: RetailTypes,
            acceptEmptyValues: true
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Code',
            display: 'Code',
            queryBuilderOptions: {
                Exact: true
            }
        }
    ]
};



const retailModel = {
    fields: [
        {
            name: 'Id'
        },
        {
            name: 'Type',
            display: 'Type',
            type: InputTypes.select,
            values: RetailTypes,
            acceptEmptyValues: true
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'Balance',
            display: 'Balance',
            type: InputTypes.number,
            formatter: (val, row) => {
                return currency(val, 2, row.DefaultCurrency);
            }
        },
        {
            name: 'Enabled',
            display: 'Enabled'
        }
    ]
};


const ListRetails = (props) => {
    const [retail, selectRetail] = useState(null);
    const onActionClicked = (action, items) => {
        const item = items[0];
        switch (action.type) {
            case ActionTypes.AddModifyRetail:
                getHistory().push(`/Retails/${ActionTypes.AddModifyRetail}?&id=${item.Id}`);
                break;
            case ActionTypes.RetailOperations:
                selectRetail(item);
                break;
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        if (!isNaN(id)) {
            if (window.location.pathname.toLowerCase().indexOf(ActionTypes.AddModifyRetail.toLowerCase()) > 0) {
                return;
            }
            getRetail(id);
        }
    }, []);

    useEffect(() => {
        if (!retail) return;
        getStore().dispatch({
            type: '_retail_FULFILLED',
            payload: retail
        });
        getHistory().push(`/Retails/${ActionTypes.ListRetails}?&id=${retail.Id}`);
    }, [retail]);

    const getRetail = (id) => {
        application().resolve(null, `${Endpoints.GetRetail}/${id}`, null, 'fetching please wait', 'unable to get retail').then((result) => {
            result.result.configuration = JSON.parse(result.result.configuration);
            selectRetail(toPascalCase(result.result));
        });
    };

    const closeOperations = () => {
        getHistory().push(`/Retails/${ActionTypes.ListRetails}`);
        selectRetail(null);
    };

    if (retail) return <RetailOperations retail={retail} onClose={() => closeOperations()} />;

    return <Card caption={lang('Retails')}>
        <Search
            model={searchRetailModel}
            resultModel={retailModel}
            data={props.retails}
            exportAllPagesToExcel={false}
            actions={getAction(ActionTypes.ListRetails).convertToItems()}
            onActionClicked={(action, items) => onActionClicked(action, items)}
            onSubmit={(data, qb) => { apiResult('retails', Endpoints.ListRetails, qb, 'searching retails', 'unable to fetch results'); }}
        />
    </Card>;
};

ListRetails.propTypes = {
    retails: PropTypes.object
};



const mapStateToProps = state => {
    return {
        retails: state.data.retails
    };
};
const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ListRetails);
export { hoc as ListRetails };