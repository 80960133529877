import './_betslipBonus.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { BonusGeneralSettings, BonusPlayerSettings } from '../Components/BonusComponents';
import { Tab } from '../../../Centrum/Tab/Tab';
import { Card } from '../../../Centrum/Card/Card';
import { application, Endpoints } from '../../../Application/Application';
import { SportsBettingRules } from './SportsConfiguration/SportsConfiguration';


export class BetslipBonus extends React.Component {
    constructor(props) {
        super(props);
        var state = Object.assign({}, props);
        this.state = state;
        this.state.promotion.Configuration = (typeof this.state.promotion.Configuration === 'object' && this.state.promotion.Configuration !== null) ? this.state.promotion.Configuration : JSON.parse(this.state.promotion.Configuration);
    }


    componentDidMount() {
        application().resolve(null, Endpoints.GetPromotionPlayerGroups + '/' + (this.state.promotion.Id), {}, 'fetching promotion player groups', 'unable to fetch promotion player groups').then((groups) => {
            var converted = [];
            converted.push({ value: null, display: 'All' });
            groups.result.map(x => {
                converted.push({ display: x.name, value: x.id });
            });

            this.setState({ loaded: true, playerGroups: converted });
        });

    }


    save() {
        var promotion = this.generalSettings.save();
        var result = Object.assign(promotion, {});

        var playerSettings = this.playerSettings.save();

        result = Object.assign(playerSettings, result);
        result.Configuration = JSON.stringify({
            sportsBettingRules: this.sportsBettingRules.save()
        });

        console.log(result);

        return result;
    }

    render() {
        if (!this.state.loaded) return <div />;
        var tabButtons = [
            {
                title: 'General'
            },
            {
                title: 'Player Settings'
            },
            {
                title: 'Rules'
            },
        ];
        return <React.Fragment>
            <Tab buttons={tabButtons}>
                <Card key='general'>
                    <BonusGeneralSettings promotion={this.state.promotion} ref={(r) => this.generalSettings = r} />
                </Card>
                <Card key='player'>
                    <BonusPlayerSettings promotion={this.state.promotion} ref={(r) => this.playerSettings = r} />
                </Card>
                <SportsBettingRules context={(context) => this.sportsBettingRules = context} promotion={this.state.promotion} />
            </Tab >
        </React.Fragment >;
    }
}

BetslipBonus.propTypes = {
    promotion: PropTypes.object
};

