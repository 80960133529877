import React from 'react';

export const SportsBetTypes = {
    0: 'Prematch',
    1: 'Live',
    2: 'Pre & Live'
};

export const SportsTicketTypes = {
    0: 'Single',
    1: 'Combined',
    2: 'System'
};

export const SportsTicketStatus = {
    0: 'Open',
    1: 'Won',
    2: 'Lost',
    3: 'Cashout',
    4: 'Cancel',
    5: 'Cashback',
    9: 'Bet Cancel',
    10: 'Pending For Manual Settlement'
};

export const SportsTicketResolvedStatus = {
    1: 'Won',
    2: 'Lost',
    3: 'Cashout',
    4: 'Cancel',
    5: 'Cashback',
    9: 'Bet Cancel'
};

export const TicketFlags = {
    0x00400000: 'Manual Ticket Win',
    0x00800000: 'Manual Ticket Lost',
    0x02000000: 'Manual Ticket Cancel',
    0x04000000: 'Bet Cancel',
    0x08000000: 'Rollback Bet Cancel',
    0x10000000: 'Rollback Win',
    0x20000000: 'Rollback Lost'
};

export const PlayerGameHistoryTypes = {
    0: 'Win',
    1: 'Bet',
    2: 'Cancel'
};

export const ProviderTypes = {
    1: 'Undefined',
    2: 'Rng Games',
    4: 'Live Dealer',
    8: 'Virtual Games',
    16: 'Skill Games',
    32: 'Esport',
    64: 'Sports Book',
    128: 'Payment Gateway'
};

export const ProviderGameTypes = {
    0: 'Undefined',
    1: 'Card Game',
    2: 'Table Game',
    3: 'Casual Game',
    4: 'Classic Slot',
    5: 'Video Poker',
    6: 'Scratch Game',
    7: 'Video Slots',
    8: 'Virtual Sport',
    9: 'Lottery',
    10: 'Black Jack',
    11: 'Roulette',
    12: 'Baccarat',
    13: 'Casino Holdem',
    14: 'Keno',
    15: 'Live Slots',
    16: 'MagicCard',
    17: 'Tombala',
    18: 'TombalaSlot',
    19: 'Casino Over Under Live',
    20: 'CasinoWar Live',
    21: 'ProvablyFair',
    22: 'Esport',
    23: 'Sicbo',
    24: 'Wheel Games',
    25: 'Lobby',
    26: 'Poker',
    27: 'Craps',
    28: 'Bingo',
    29: 'Caribbean Stud Poker',
    30: 'Texas Holdem',
    31: 'Dream Catcher',
    32: 'American Roulette',
    33: 'Football Studio',
    34: 'Dragon Tiger',
    35: 'Auto Roulette',
    36: 'Oasis Poker',
    37: 'Monopoly',
    38: 'Andar bahar',
    39: 'Pachinko',
    40: 'Teen Patti',
    41: 'Skill Games',

    3000: 'Live.Roulette',
    3001: 'Live.AutoRoulette',
    3002: 'Live.Baccarat',
    3003: 'Live.BlackJack',
    3004: 'Live.Casino Holdem',
    3005: 'Live.Poker',
    3006: 'Live.Keno',
    3007: 'Live.SicBo',
    3008: 'Live.ScratchCards',
    3009: 'Live.MegaWheel',
    3010: 'Live.GameShow',

    4000: 'Animal Fights - Cockfight'
};


export const NotificationTypes = {
    0: 'Information',
    1: 'Warning',
    2: 'Error'
};

export const NodeRestrictionTypes = {
    0: 'Ip',
    1: 'Player'
};


export const EventStatusTypes = {
    0: 'Undefined',
    1: 'Not Set',
    2: 'Scheduled',
    3: 'Started',
    4: 'Planning Live',
    5: 'Finished',
    6: 'Suspended',
    7: 'Cancelled',
    8: 'Interrupted'
};


export const TradingStatusTypes = {
    0: 'Open',
    1: 'Suspended',
    2: 'Closed',
    3: 'Unchanged',
    4: 'Return',
    5: 'Forbidden'
};

export const EmailGroups = {
    0: 'All',
    1: 'Mailing',
    2: 'Deposit',
    3: 'Withdraw',
    4: 'Profile',
    5: 'Registration',
    6: 'Loyalty',
    7: 'Other',
    8: 'Affiliate'
};

export const WebSites = {
    0: 'All',
    1: 'NtegraGaming'
};


export const Languages = {
    'en-En': 'English',
    'tr-Tr': 'Turkish',
    'ru-Ru': 'Russian',
    'de-De': 'German',
    'fr-Fr': 'French',
    'ku-Ku': 'Kurdish',
    'ar-Ar': 'Arabic',
    'pt-Pt': 'Porteguese',
    'ce-Ce': 'Chineses'
};


export const UserGroupTypes = {
    '2': 'Owner',
    '3': 'Manager',
    '4': 'Setup',
    '5': 'Call Center',
    '6': 'Finance',
    '7': 'Marketting',
    '8': 'Affiliate',
    '9': 'Virtual Retail',
    '10': 'Retail Owner',
    '11': 'Retail Manager',
    '12': 'Retail Cashier',
    '13': 'Risk Manager'
};


export const Currencies = {
    'USD': 'US Dollar',
    'EUR': 'Euro',
    'GBP': 'Pound Sterling',
    'TRY': 'Turkish Lira',
    'TND': 'Tunusian Dinar',
    'IQD': 'Iraqi Dinar',
    'TTD': 'Trinidad and Tobago Dollar',
    'JOD': 'Jordon Dinnar',
    'ETB': 'Ethiopian Birr',
    'MNT': 'Mongolian Tögrög',
    'SSP': 'South Sudanese Pound'
};

export const BonusTypes = {
    //'Manual': 0,
    'Welcome': 1,
    'Deposit': 2,
    'Discount': 3,
    //'Free Bet': 4,
    //'Free Spin': 5,
    'Betslip Bonus': 6,
    'Near Miss Bonus': 7,
    'Cash Bonus': 8,
    'Slip Lottery': 9,
    'Bet Booster': 10,
    'Multiplier Bonus': 11
    //'Lottery Bonus': 99
};

export const BonusStatus = {
    0: 'Open',
    1: 'Completed',
    2: 'Removed'
};

export const BonusPeriods = {
    0: 'Every Day',
    1: 'Every Week',
    2: 'Every 15 Days',
    3: 'Every Month'
};

export const BonusPeriodStartDay = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
};

export const ProductTypes = {
    '0': 'Casino',
    '1': 'Sports'
};


export const OperatorActivities = [
    'Create Node Restriction',
    'Deposit Confirmed',
    'Deposit Rejected',
    'Login',
    'Logout',
    'New Operator User',
    'New User Group',
    'Operator User Disabled',
    'Operator User Enabled',
    'Player Created',
    'Provider Configurations Changed',
    'Provider Disabled',
    'Provider Disabled For Operator',
    'Provider Enabled',
    'Provider Enabled For Operator',
    'Provider Game Disabled',
    'Provider Game Disabled For Operator',
    'Provider Game Enabled',
    'Provider Game Enabled For Operator',
    'Signed out due to new login',
    'Update Operator Features',
    'Update Operator Information',
    'Update Operator User',
    'Withdraw Confirmed',
    'Withdraw Rejected'
];


export const ShopTypes = {
    0: 'Shop',
};

export const ShopTransactionTypes = {
    0: 'Deposit',
    1: 'Withdraw',
    2: 'Credit Deposit',
    3: 'Credit Withdraw',
    4: 'Settlement'
};

export const TransactionTypes = {
    0: 'Deposit',
    1: 'Withdraw',
    2: 'Win',
    3: 'Bet',
    4: 'Cancel',
};

export const BonusTransactionTypes = {
    0: 'Bonus Created',
    1: 'Bonus Removed'
};


export const ArrayToKeys = (arr) => {
    var res = {};
    arr.map(x => {
        res[x] = x;
    });
    return res;
};



export const GenderSources = {
    male: 'Male',
    female: 'Female',
};

export const PlayerFlags = {
    1: 'New Player',
    2: 'Confirmed',
    4: 'Deposited',
    8: 'Witdrawen',
    16: 'Used same ip with another player',
    32: 'Player has risk management',
    64: 'Payment Restriction',
    128: 'Self Limitation',
    256: 'Self Excluded',
    512: 'Temporary Password'
};


export const PlayerFlagsLegends = {
    1: 'N',
    2: 'C',
    4: 'D',
    8: 'W',
    16: 'IP',
    32: 'RM',
    64: 'PR',
    128: 'SL',
    256: 'SE'
};


export const TurnoverMultiplier = {
    '0': 'No multiplier',
    '1': '1x',
    '2': '2x',
    '3': '3x',
    '4': '4x',
    '5': '5x',
    '6': '6x',
    '7': '7x',
    '8': '8x',
    '9': '9x',
    '10': '10x',
    '11': '11x',
    '12': '12x',
    '13': '13x',
    '14': '14x',
    '15': '15x',
};

export const DeductBonusAmountWithdrawMultiplier = {
    '1': '1x',
    '2': '2x',
    '3': '3x',
    '4': '4x',
    '5': '5x',
    '6': '6x',
    '7': '7x',
    '8': '8x',
    '9': '9x',
    '10': '10x',
    '11': '11x',
    '12': '12x',
    '13': '13x',
    '14': '14x',
    '15': '15x',
};


export const SportEventStatus = {
    0: 'Not started',
    1: 'Live',
    2: 'Suspended',
    3: 'Ended',
    4: 'Closed',
    5: 'Cancelled',
    6: 'Delayed',
    7: 'Interrupted',
    8: 'Postponed',
    9: 'Abandoned',
    10: 'Unknown',
    11: 'About to start',
};



export const TestAccountFilter = {
    0: 'Exclude',
    1: 'Include',
    2: 'Only Test Accounts'
};



export const WebSiteApplicationTypes = {
    HomePage: 'HomePage',
    Gaming: 'Gaming',
    Promotions: 'Promotions'
};



export const PlayerVerificationType = {
    0: 'Not Verified',
    1: 'Pending',
    2: 'Rejected',
    3: 'Verified'
};



export const LSportSportStatusCodes = {
    1: 'Not Started',
    2: 'In Progress',
    3: 'Finished',
    4: 'Cancelled',
    5: 'Postponed',
    6: 'Interrupted',
    7: 'Abondoned',
    8: 'Coverage Lost',
    9: 'About to Start'
};

export const EnumToArray = (e, numericIndex) => {
    var res = {};
    Object.keys(e).map((key, index) => {
        if (numericIndex) {
            res[e[key]] = key;
        } else {
            res[index] = key;
        }
    });

    return res;
};

export const PlayerFlagsToClass = (flags, big = false) => {
    return flags.map(flag => {
        return <a key={flag} className={'legend-flag-' + PlayerFlagsLegends[flag] + ' tooltip'} title={PlayerFlags[flag]}>{!big ? PlayerFlagsLegends[flag] : PlayerFlags[flag]}</a>;
    });
};

export const resolveFlags = (flags) => {
    flags = parseInt(flags);
    var result = [];
    Object.keys(PlayerFlags).map(key => {
        var flag = parseInt(key);

        if ((flags & flag) == flag && flag != 1) {
            result.push(key);
        }
    });
    return result;
};
