import { defaultPending, defaultFulfilled, listerInitial, defaultRejected } from './ApplicationReducer';
import {
    SEARCH_PASSIVE_CRASH_GAME_ROOMS_PENDING,
    SEARCH_PASSIVE_CRASH_GAME_ROOMS_FULFILLED,
    SEARCH_PASSIVE_CRASH_GAME_ROOMS_REJECTED,
    SEARCH_CRASH_GAME_ROOMS_PENDING,
    SEARCH_CRASH_GAME_ROOMS_FULFILLED,
    SEARCH_CRASH_GAME_ROOMS_REJECTED,
    SEARCH_CRASH_GAME_NOTIFICATIONS_PENDING,
    SEARCH_CRASH_GAME_NOTIFICATIONS_FULFILLED,
    SEARCH_CRASH_GAME_NOTIFICATIONS_REJECTED,
    SEARCH_CRASH_SERVER_RESTRICTIONS_PENDING,
    SEARCH_CRASH_SERVER_RESTRICTIONS_FULFILLED,
    SEARCH_CRASH_SERVER_RESTRICTIONS_REJECTED,
    SEARCH_CRASH_GAME_ROUND_PENDING,
    SEARCH_CRASH_GAME_ROUND_FULFILLED,
    SEARCH_CRASH_GAME_ROUND_REJECTED,
    SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_PENDING,
    SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_FULFILLED,
    SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_REJECTED,
    SEARCH_CRASH_GAME_LEADERBOARD_PENDING,
    SEARCH_CRASH_GAME_LEADERBOARD_FULFILLED,
    SEARCH_CRASH_GAME_LEADERBOARD_REJECTED,
    SEARCH_CRASH_GAME_BANKROLLS_PENDING,
    SEARCH_CRASH_GAME_BANKROLLS_FULFILLED,
    SEARCH_CRASH_GAME_BANKROLLS_REJECTED,
} from '../actions/CrashGameActions';


const initialState = {
    rooms: listerInitial,
    rounds: listerInitial,
    roundTransactions: listerInitial,
    leaderboard: listerInitial,
    passiveRooms: listerInitial,
    notifications: listerInitial,
    restrictions: listerInitial,
    bankRolls: listerInitial,
    loading: false,
    loaded: false,
    failed: false
};

// REDUCER
export const CrashGameReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_CRASH_GAME_ROOMS_PENDING:
            return defaultPending('rooms', state);
        case SEARCH_CRASH_GAME_ROOMS_FULFILLED:
            return defaultFulfilled('rooms', state, action);
        case SEARCH_CRASH_GAME_ROOMS_REJECTED:
            return defaultRejected('rooms', state, action);

        case SEARCH_CRASH_GAME_ROUND_PENDING:
            return defaultPending('rounds', state);
        case SEARCH_CRASH_GAME_ROUND_FULFILLED:
            return defaultFulfilled('rounds', state, action);
        case SEARCH_CRASH_GAME_ROUND_REJECTED:
            return defaultRejected('rounds', state, action);

        case SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_PENDING:
            return defaultPending('roundTransactions', state);
        case SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_FULFILLED:
            return defaultFulfilled('roundTransactions', state, action);
        case SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_REJECTED:
            return defaultRejected('roundTransactions', state, action);

        case SEARCH_PASSIVE_CRASH_GAME_ROOMS_PENDING:
            return defaultPending('passiveRooms', state);
        case SEARCH_PASSIVE_CRASH_GAME_ROOMS_FULFILLED:
            return defaultFulfilled('passiveRooms', state, action);
        case SEARCH_PASSIVE_CRASH_GAME_ROOMS_REJECTED:
            return defaultRejected('passiveRooms', state, action);

        case SEARCH_CRASH_GAME_NOTIFICATIONS_PENDING:
            return defaultPending('notifications', state);
        case SEARCH_CRASH_GAME_NOTIFICATIONS_FULFILLED:
            return defaultFulfilled('notifications', state, action);
        case SEARCH_CRASH_GAME_NOTIFICATIONS_REJECTED:
            return defaultRejected('notifications', state, action);

        case SEARCH_CRASH_SERVER_RESTRICTIONS_PENDING:
            return defaultPending('restrictions', state);
        case SEARCH_CRASH_SERVER_RESTRICTIONS_FULFILLED:
            return defaultFulfilled('restrictions', state, action);
        case SEARCH_CRASH_SERVER_RESTRICTIONS_REJECTED:
            return defaultRejected('restrictions', state, action);

        case SEARCH_CRASH_GAME_BANKROLLS_PENDING:
            return defaultPending('bankRolls', state);
        case SEARCH_CRASH_GAME_BANKROLLS_FULFILLED:
            return defaultFulfilled('bankRolls', state, action);
        case SEARCH_CRASH_GAME_BANKROLLS_REJECTED:
            return defaultRejected('bankRolls', state, action);

        case SEARCH_CRASH_GAME_LEADERBOARD_PENDING:
            return defaultPending('leaderboard', state);
        case SEARCH_CRASH_GAME_LEADERBOARD_FULFILLED:
            return defaultFulfilled('leaderboard', state, action);
        case SEARCH_CRASH_GAME_LEADERBOARD_REJECTED:
            return defaultRejected('leaderboard', state, action);

        default:
            return state;
    }
};