import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../Application/Application';
import { lang } from '../../Centrum/Localization/lang';
import { Switch } from '../../Controls/Inputs/Switch/Switch';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';


const getDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
};

export const PeriodicPromotionRunConfiguration = (props) => {
    const [customDate, setCustomDate] = useState(props.promotion.LastRun ? false : true);
    const [retails, setRetails] = useState(false);
    const [retailCode, setRetailCode] = useState(null);
    const [startDate, setStartDate] = useState(getDate(props.promotion.LastRun ? new Date(props.promotion.LastRun) : new Date()));
    const [endDate, setEndDate] = useState(getDate(new Date()));

    useEffect(() => {
        props.onDateSet({
            onlyRetails: retails,
            retailCode: retailCode,
            dateStart: customDate ? startDate : null,
            dateEnd: customDate ? endDate : null
        });
    }, [customDate, startDate, endDate]);


    return <React.Fragment>
        <div className='padding centrum-flex justify-center vertical start'>
            {props.promotion.LastRun && <span>{lang('Calculation of the bonus will start from %1', [getDate(new Date(props.promotion.LastRun))])}</span>}
        </div>
        <div className='paddingLeft paddingRight centrum-flex justify-center stretch'>
            <div className='centrum-flex ustify-center start'>
                <Switch title='Only Retails' onChange={(value) => setRetails(value)} />
            </div>
            <div className='centrum-flex'>
                <label>{lang('Retail Code')}</label>
                <input type='text' onChangeCapture={(e) => setRetailCode(e.target.value)} />
            </div>
        </div>
        <div className='centrum-flex  justify-center'>
            {props.promotion.LastRun && <Switch title='Custom Date' onChange={(value) => setCustomDate(value)} />}
        </div>
        {
            (!props.promotion.LastRun || customDate) &&
            <form>
                <label>{lang('Run from custom Date')}</label>
                <div className='row'>
                    <div className='form-group'>
                        <label>{lang('Start Time')}</label>
                        <input type='datetime-local' name='start' defaultValue={startDate.toISOString().split('.')[0] + 'Z'} onChangeCapture={(e) => setStartDate(new Date(e.target.value))} />
                    </div>
                    <div className='form-group'>
                        <label>{lang('End Time')}</label>
                        <input type='datetime-local' name='end' value={endDate.toISOString().split('.')[0 + 'Z']} onChangeCapture={(e) => setEndDate(new Date(e.target.value))} />
                    </div>
                </div>
            </form>
        }
    </React.Fragment>;
};

PeriodicPromotionRunConfiguration.propTypes = {
    promotion: PropTypes.object,
    onDateSet: PropTypes.func
};

export const runPeriodicBonus = (promotion, onComplete) => {
    var dates = null;
    application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
        dates.preview = true;
        application().resolve(null, `${Endpoints.RunPeriodicPromotion}/${promotion.Id}`, dates).then((result) => {
            onComplete({ dates: dates, results: result.result });
        });
    }}>
        <PeriodicPromotionRunConfiguration promotion={promotion} onDateSet={(d) => dates = d} />
    </ConfirmationModal>);
};

export const payPeriodicBonus = (promotion, data, onComplete) => {
    application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
        data.preview = false;
        application().resolve(null, `${Endpoints.RunPeriodicPromotion}/${promotion.Id}`, data).then((result) => {
            onComplete(result.result);
        });
    }}>
        <span className='information padding'>{lang('Bonus will be deposit to selected players.')}</span>
        <span className='padding group'><label className='padding-right'>{lang('Date Start')}</label>{data.dateStart.toLocaleString('en-Gb')}</span>
        <span className='padding group'><label className='padding-right'>{lang('Date End')}</label>{data.dateEnd.toLocaleString('en-Gb')}</span>
        <span className='padding group'><label className='padding-right'>{lang('Only Retails')}</label>{data.onlyRetails}</span>
        <span className='padding group'><label className='padding-right'>{lang('Retail Code')}</label>{data.retailCode}</span>

    </ConfirmationModal>);
};
