// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCurrentLanguage } from '../../config';
import { changeLanguage } from '../../state/actions/LanguageActions';
import './_language.scss';

// COMPONENT
class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.selectedLanguage = getCurrentLanguage();
        this.opened = false;
        this.languageRef = null;
        this.languages = {
            'ru': 'Ru',
            'en': 'En',
            'tr': 'Tr'
        };
    }

    changeLanguage(item) {

        if (!this.opened) {
            this.opened = true;
            this.languageRef.classList.add('languageOpen');
            return;
        }


        if (this.selectedLanguage === this.languages[item]) {
            if (this.opened) {
                this.opened = false;
                this.languageRef.classList.remove('languageOpen');
            }
            return;
        }

        this.selectedLanguage = this.languages[item];
        this.opened = false;
        this.languageRef.classList.remove('languageOpen');
        this.props.changeLanguage(this.languages[item]);
        this.setState({ languageUpdate: true });
    }

    renderLanguages() {

        var items = Object.keys(this.languages);
        return items.map(item => {
            return <span key={item} className={this.languages[item].toLowerCase() === this.selectedLanguage.toLowerCase() ? 'selected' : ''} onClick={this.changeLanguage.bind(this, item)}><i>{this.languages[item]}</i></span>;
        });
    }


    render() {
        return (
            <div className='language-selection'>
                <div className='language' ref={(languageRef) => this.languageRef = languageRef}>
                    <div className='selections'>
                        {this.renderLanguages()}
                    </div>
                </div>
            </div>
        );
    }

}


LanguageSelector.defaultProps = {
};

LanguageSelector.propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    language: PropTypes.object
};




const mapStateToProps = state => {
    return {
        language: state.language
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ changeLanguage: changeLanguage }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);

