import './_result-prices.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Silk } from '../Silk/Silk';
import { lang } from '../../../Lib/Common/language';

export const ResultPrices = (props) => {
    const event = props.event;

    if (!event) return <></>;
    return <div className='result-prices'>
        {
            event.resultPrices.map((p, index) => {
                return <div className='flex gap-5 result-types' key={index}>
                    <label>{lang(p.type == 0 ? 'Forecast' : 'Tricast')}</label>
                    <span className='light'>{p.price.toFixed(2)}</span>
                    <div className='flex gap-5 runner-prices'>{p.betPlaces.sort((a, b) => { return a.place - b.place; }).map(betPlace => {
                        const competitor = event.runners.find(r => r.id == betPlace.runnerId);
                        return <div key={betPlace.runnerId} className='flex gap-5'>
                            {!props.small && <Silk competitor={competitor} raceType={event.raceType} />}
                            {!props.small && <span className='primary'>{competitor.name}</span>}
                            {props.small && <React.Fragment><span className='light'>{competitor.number}</span><span className='primary'>{competitor.name}</span></React.Fragment>}
                        </div>;
                    })}
                    </div>
                </div>;
            })
        }
    </div>;
};

ResultPrices.propTypes = {
    event: PropTypes.object,
    small: PropTypes.bool
};
