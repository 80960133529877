import { 
    SEARCH_PLAYER_PENDING, 
    SEARCH_PLAYER_REJECTED, 
    SEARCH_PLAYER_FULFILLED, 
    SEARCH_PLAYER_ACTIVITY_PENDING, 
    SEARCH_PLAYER_ACTIVITY_FULFILLED, 
    SEARCH_PLAYER_ACTIVITY_REJECTED, 
    SEARCH_PLAYER_GAME_HISTORY_PENDING, 
    SEARCH_PLAYER_GAME_HISTORY_FULFILLED, 
    SEARCH_PLAYER_GAME_HISTORY_REJECTED, 
    GET_PLAYER_PROFILE_PENDING,
    GET_PLAYER_PROFILE_FULFILLED,
    GET_PLAYER_PROFILE_REJECTED
} from '../actions/PlayerActions';
import { listerInitial, defaultPending, defaultFulfilled, defaultRejected } from './ApplicationReducer';



const initialState = {
    players: listerInitial,
    playerActivity: listerInitial,
    player: {},
    profile:{},
    loading: false,
    loaded: false,
    failed: false,
    error: {},
    errorDisplayed: false
};




const RESET_STATE = 'RESET_STATE';
export function resetState(state) {
    return {
        type: RESET_STATE,
        payload: state
    };
}


// REDUCER
export const PlayerReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STATE:
            var object = Object.assign({}, state);
            if (action.payload === '' ||
                action.payload === undefined ||
                action.payload === null) {
                //console.log('can not reset undefined or null state object');
                object.failed = false;
                return object;
            }
            object[action.payload].failed = false;
            return object;
        // PLAYER
        case SEARCH_PLAYER_PENDING:
            return defaultPending('players', state,state.players);
        case SEARCH_PLAYER_FULFILLED:
            return defaultFulfilled('players', state, action);
        case SEARCH_PLAYER_REJECTED:
            return defaultRejected('players', state, action);

        case SEARCH_PLAYER_ACTIVITY_PENDING:
            return defaultPending('playerActivity', state);
        case SEARCH_PLAYER_ACTIVITY_FULFILLED:
            return defaultFulfilled('playerActivity', state, action);
        case SEARCH_PLAYER_ACTIVITY_REJECTED:
            return defaultRejected('playerActivity', state, action);

        // PROFILE
        case GET_PLAYER_PROFILE_PENDING:
            return defaultPending('profile',state,action);
        case GET_PLAYER_PROFILE_FULFILLED:
            return defaultFulfilled('profile',state,action);
        case GET_PLAYER_PROFILE_REJECTED:
            return defaultRejected('profile',state,action);

        // Gaming
        case SEARCH_PLAYER_GAME_HISTORY_PENDING:
            return defaultPending('playerGameHistory', state, action);
        case SEARCH_PLAYER_GAME_HISTORY_FULFILLED:
            return defaultFulfilled('playerGameHistory', state, action);
        case SEARCH_PLAYER_GAME_HISTORY_REJECTED:
            return defaultRejected('playerGameHistory', state, action);

        default:
            return state;
    }
};