// IMPORT PACKAGE REFERENCES
import './_modals.scss';

import React, { Component } from 'react';


export class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({open:false},props);
    }

    close() {
        this.setState({open:false,modal:null});
    }

    open(modal) {
        this.setState({open:true,modal:modal});
    }

    render() {
        if (!this.state.open) return <React.Fragment/>;
        return <div className='modal'>{this.state.modal}</div>;
    }
}

Modal.defaultProps = {
  
};

Modal.propTypes = {
  
};
