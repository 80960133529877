import { InputTypes } from './CentrumForm/Inputs/CentrumInput';
import { PlayerModels } from './_models/PlayerModels';
import { TransactionModels } from './_models/Transactions';
import { AdminModels } from './_models/Admin';
import { OperatorModels } from './_models/Operators';
import { ServerOperationModels } from './_models/ServerOperations';
import { ProviderModels } from './_models/Providers';
import { CentrumHubModels } from './_models/CentrumHub';
import { CrashModels } from './_models/CrashModels';
import { DashboardModels } from './_models/DashboardModels';
import { MarketingModels } from './_models/MarketingModels';
import { ReportsModel } from './_models/ReportsModel';
import { UserGroupModels } from './_models/UserGroupModels';
import { OperatorUsersModel } from './_models/OperatorUsers';
import { MyProfileModels } from './_models/MyProfileModels';
import { ShopsModel } from './_models/ShopsModel';
import { ExchangeRatesModels } from './_models/ExchangeRatesModels';
import { RiskManagementModels } from './_models/RiskManagementModels';
import { CMSModels } from './_models/CMSModels';
import { BonusModels } from './_models/BonusModels';
import { PromotionModels } from './_models/PromotionModels';
import { AffiliatesModel } from './_models/AffiliatesModel';
import { LotteryModels } from './_models/LotteryModels';

export const cacheData = {

};

export const cacheDataService = async (endPointData) => {
    if (!(endPointData.source in cacheData)) {
        cacheData[endPointData.source] = {
            values: [],
            loaded: false,
            loading: false,
            promise: null
        };
    }

    var data = '';

    if (endPointData.data !== undefined) {
        cacheData[endPointData.source].loaded = false;
        cacheData[endPointData.source].promise = null;
        data = '?&' + endPointData.updateDataOnFieldChange + '=' + endPointData.data;
    } else {
        setTimeout(() => {
            cacheData[endPointData.source].loaded = false;
        }, 30000);
    }

    if (!cacheData[endPointData.source].loaded) {
        if (cacheData[endPointData.source].promise != null) {
            return cacheData[endPointData.source].promise;
        }

        cacheData[endPointData.source].promise = new Promise((resolve) => {
            cacheData[endPointData.source].loading = true;
            fetch('/api/data/' + endPointData.source + data, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json()).then((result) => {
                if (result.status === 1) {
                    let key = 'Id';
                    let text = ['Name'];
                    if (endPointData.bindingKey !== undefined) {
                        key = endPointData.bindingKey;
                    }

                    if (endPointData.bindingText !== undefined) {
                        text = endPointData.bindingText;
                    }

                    cacheData[endPointData.source].loaded = true;
                    cacheData[endPointData.source].loading = false;

                    var values = [];
                    result.result.map(item => {
                        var texts = [];
                        text.map(t => {
                            texts.push(item[t]);
                        });

                        values.push({ key: item[key], value: texts.join(' ') });
                    });


                    cacheData[endPointData.source].values = Object.assign([], values);
                    resolve(values);
                } else {
                    resolve([]);
                }
            });
        }).catch(() => {

        });

        return cacheData[endPointData.source].promise;
    } else {
        return new Promise((resolve) => {
            resolve(cacheData[endPointData.source].values);
        });
    }
};



export const icons = {
    'users': 'user',
    'info-circle': 'info-circle',
    futbol: 'futbol',
    'user-friends': 'user-friends',
    bars: 'bars',
    'search': 'search',
    'history': 'history',
    'money': 'dollar-sign',
    'list-alt': 'list-alt',
    'list': 'list',
    'money-check-alt': 'money-check-alt',
    'exclamation-circle': 'exclamation-circle',
    'hand-holding-usd': 'hand-holding-usd',
    'wrench': 'wrench',
    'folder': 'folder',
    'file': 'file',
    'folderOpen': 'folder-open',
    'shoppingBag': 'shopping-bag',
    faEnvelopeOpen: 'envelope-open',
    'edit': 'edit',
    'delete': 'trash',
    'configure': 'cog',
    'arrowLeft': 'arrow-left',
    'arrowRight': 'arrow-roght',
    arrowDown: 'sort-down',
    arrowUp: 'sort-up',
    'arrow-up': 'arrow-up',
    'arrow-down': 'arrow-down',
    listOl: 'list-ol',
    crashGame: 'chart-line',
    calendar: 'calendar-alt',
    calendarcheck: 'calendar-check',
    confirm: 'ticket-alt',
    reject: 'ban',
    faAngleDoubleLeft: 'angle-double-left',
    home: 'home',
    logout: 'door-open',
    close: 'times',
    checked: 'check-square',
    empty: 'square',
    changePassword: 'lock',
    'id-card': 'id-card',
    'dice-d6': 'dice-six',
    'user-clock': 'user-clock',
    'add-new': 'plus-square',
    industry: 'industry',
    equal: 'equals',
    notEqual: 'not-equal',
    greaterThan: 'greater-than-equal',
    lessThan: 'less-than-equal',
    percentage: 'percentage',
    mobile: 'mobile-alt',
    desktop: 'desktop',
    info: 'info-circle',
    warning: 'exclamation',
    error: 'exclamation-triangle',
    gamepad: 'gamepad',
    disable: 'times-circle',
    enable: 'check-circle',
    star: 'star',
    globe: 'globe',
    reports: 'chart-bar',
    server: 'server',
    heartbeat: 'heartbeat',
    heart: 'heart',
    image: 'image',
    trophy: 'trophy',
    moneyCheckAlt: 'money-check-alt',
    dice: 'dice',
    diagnoses: 'diagnoses',
    envelope: 'evelope',
    stickyNote: 'sticky-note',
    dashboard: 'tachometer-alt',
    idCard: 'id-card',
    question: 'question',
    cogs: 'cogs',
    task: 'tasks',
    sync: 'sync-alt',
    chartPie: 'chart-pie',
    menuDown: 'angle-down',
    glob: 'globe'
};




export const models = {
    get(target) {
        if (typeof target === 'object' && target !== null) {
            return target;
        }

        let model = modelSources[target];

        if (model === undefined) {
            model = {
                fields: [

                ]
            };
        }

        var source = Object.assign({}, model);
        /*
        if (source.fields.length < 15) {
            for (var i = 0; i < 20; i++) {
                source.fields.push({
                    name: 'field_' + i,
                    max: 5
                });
            }
        }
        */
        return source;
    }
};

const modelSources = {
    qbdefault: {
        fields: [
            {
                name: 'And',
                display: 'And',
                type: InputTypes.bool,
                value: false
            }
        ]
    },
    qbtext: {
        fields: [
            {
                name: 'Exact',
                display: 'Exact',
                type: InputTypes.bool,
                value: true
            }
        ]
    },
    qbnumber: {
        fields: [
            {
                name: 'Between',
                display: 'Between Values',
                type: InputTypes.bool
            }
        ]
    },
    qbtime: {
        fields: [
            {
                name: 'Between',
                display: 'Between Times',
                type: InputTypes.bool
            }
        ]
    },
    qbdate: {
        fields: [
            {
                name: 'Between',
                display: 'Between Dates',
                type: InputTypes.bool
            }
        ]
    },
    qbdateTime: {
        fields: [
            {
                name: 'Between',
                display: 'Between Dates',
                type: InputTypes.bool
            }
        ]
    }
};

Object.assign(modelSources, PlayerModels);
Object.assign(modelSources, TransactionModels);
Object.assign(modelSources, AdminModels);
Object.assign(modelSources, ServerOperationModels);
Object.assign(modelSources, ProviderModels);
Object.assign(modelSources, CentrumHubModels);
Object.assign(modelSources, CrashModels);
Object.assign(modelSources, DashboardModels);
Object.assign(modelSources, MarketingModels);
Object.assign(modelSources, ReportsModel);
Object.assign(modelSources, UserGroupModels);
Object.assign(modelSources, OperatorModels);
Object.assign(modelSources, OperatorUsersModel);
Object.assign(modelSources, MyProfileModels);
Object.assign(modelSources, ShopsModel);
Object.assign(modelSources, ExchangeRatesModels);
Object.assign(modelSources, RiskManagementModels);
Object.assign(modelSources, CMSModels);
Object.assign(modelSources, BonusModels);
Object.assign(modelSources, PromotionModels);
Object.assign(modelSources, AffiliatesModel);
Object.assign(modelSources, LotteryModels);



