import '../RiskLevels/_riskLevelConfiguration.scss';

import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Card } from '../../../../v2/Components/Card/Card';
import { currency } from '../../../../v2/Lib/Common/currency';
import { API, RiskLevelEndpoints } from '../../../../v2/Lib/Api/Api';
import { PrepareRiskCombinations } from '../RiskLevels/RiskLevelLimitsConfiguration';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Tab } from '../../../Centrum/Tab/Tab';
import { MonitorMarketLevels } from './MonitorMarketLevels';
import { MonitorEventLevels } from './MonitorEventLevels';
import { LabelValue } from './BetMonitorRiskLevels';



export const MonitorEvent = (props) => {
    const event = props.event;
    const [riskLevels, setRiskLevels] = useState(null);
    const [view, setView] = useState('Pool');
    const sportLevel = props.volumes.sports.find(x => x.id == event.sportId)?.level;
    const leagueLevel = props.volumes.leagues.find(x => x.id == event.leagueId)?.level;
    const maxCompanyLiability = riskLevels ? riskLevels[sportLevel + leagueLevel + 'LowLow']?.liability : null;


    useEffect(() => {
        API.post(RiskLevelEndpoints.Get).then((result) => {
            var levels = result.result ? JSON.parse(result.result) : {};
            var elements = PrepareRiskCombinations(levels);
            setRiskLevels(elements);
        });
    }, []);


    if (!riskLevels) return <></>;

    const riskPercentage = () => {
        if (!maxCompanyLiability) return;
        const perc = parseFloat(((event.volume.liability * 100) / maxCompanyLiability).toFixed(2));
        var className = 'Low';

        if (perc > 12.5) {
            className = 'Low2';
        }
        if (perc > 25) {
            className = 'Medium';
        }
        if (perc > 37.5) {
            className = 'Medium2';
        }
        if (perc > 50) {
            className = 'Medium2';
        }
        if (perc > 62.5) {
            className = 'High';
        }
        if (perc > 75) {
            className = 'High2';
        }
        if (perc > 87.5) {
            className = 'High3';
        }
        if (perc > 95) {
            className = 'High4';
        }
        return <div className='risk-volume-progress'>
            <div className='bar-holder risk-levels'>
                <div className={'bar ' + className} style={{ maxWidth: perc + '%' }}>&nbsp;</div>
                <span>{`${perc}%`}</span>
            </div>
        </div>;
    };



    return <Card title={`[${event.id}] ${event.name}`} buttons={() => <Button title='Close' onClick={() => props.onClose()} />}>
        <div className='flex vertical gap-10'>
            <div className='flex fit-width col-2'>
                <div className='flex vertical gap-5 border padding align-start'>
                    <LabelValue label='Match Date' value={new Date(event.eventDate).toLocaleString('en-En', { hours12: false })} />
                    <div className='flex gap-5'>
                        <LabelValue label='Sport' value={props.volumes.sports.find(x => x.id == event.sportId).name} />
                        <div className='risk-levels align-right'>
                            <div className={sportLevel}>{sportLevel}</div>
                        </div>
                    </div>
                    <div className='flex gap-5'>
                        <LabelValue label='League' value={`${props.volumes.regions.find(x => x.id == event.regionId).name}/${props.volumes.leagues.find(x => x.id == event.leagueId).name}`} />
                        <div className='risk-levels align-right'>
                            <div className={leagueLevel}>{leagueLevel}</div>
                        </div>
                    </div>
                </div>
                <div className='flex vertical gap-5 border padding align-stretch'>
                    <div className='flex gap-5 justify-space-between'>
                        <LabelValue label='Stake' value={currency(event.volume.stake, 'EUR', 2, false)} />
                        <LabelValue label='Liability' value={currency(event.volume.liability, 'EUR', 2, false)} />
                        <LabelValue label='Betslips' value={event.volume.tickets} />
                        <LabelValue label='Last Leg Betslips' value={event.sllCount} />
                    </div>
                    <div className='hidden'><LabelValue label='Maximum Company Liability' value={currency(maxCompanyLiability ?? 0, 'EUR', 2, false)} /></div>
                    {riskPercentage()}
                </div>
            </div>
        </div>
        <Tab buttons={[{ name: 'Pool', title: 'Pool' }, { name: 'Markets', title: 'Markets' }]} onTabSelected={(index, key, tab) => setView(tab.name)} />
        {view == 'Pool' && <MonitorEventLevels riskLevels={riskLevels} {...props} />}
        {view == 'Markets' && <MonitorMarketLevels riskLevels={riskLevels} {...props} />}
    </Card>;
};

MonitorEvent.propTypes = {
    event: PropTypes.object,
    volumes: PropTypes.object,
    onClose: PropTypes.func
};