// IMPORT PACKAGE REFERENCES
import './_header.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { icons } from '../Centrum/Shared';
import { format } from 'date-fns';
import { lang } from '../Centrum/Localization/lang';
import LanguageSelector from './LanguageSelector';
import { CentrumInput, InputTypes } from '../Centrum/CentrumForm/Inputs/CentrumInput';
import { postData } from '../../state/services/ServiceHelpers';
import { application, Endpoints } from '../Application/Application';
import { getBrand, getLocalStorage, setLocalStorage } from '../Centrum/helpers';
import { Icon } from '../Controls/Icon/Icon';
import { convertTimeZoneToTimeDiff } from '../../v2/Lib/Common/dateHelpers';



class SelectPointOfView extends React.Component {
    constructor(props) {
        super(props);
    }

    changePointOfView(field, value) {
        postData({}, Endpoints.ChangePointOfView + '?&operatorId=' + value).then(() => {
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        var field = {
            name: 'Operator',
            display: this.props.caption,
            type: InputTypes.select,
            value: this.props.team,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        };
        return (
            <CentrumInput field={field} onValueChanged={this.changePointOfView.bind(this)} />
        );
    }
}

SelectPointOfView.propTypes = {
    team: PropTypes.number,
    caption: PropTypes.string
};


const GMTSelector = () => {
    const [zones, setZones] = useState([]);
    const [zone, setZone] = useState(null);

    useEffect(() => {
        var copy = [];
        var i = 0;
        for (i = 0; i < 10; i++) {
            copy.push({
                title: 'GMT +' + (i.toString().padStart(2, '0')) + ':00',
                value: i
            });
        }
        for (i = 1; i < 10; i++) {
            copy.push({
                title: 'GMT -' + ((i).toString().padStart(2, '0')) + ':00',
                value: -i
            });
        }
        window.user.timeZoneConverted = convertTimeZoneToTimeDiff(window.user.TimeZone);
        setZone(window.user.timeZoneConverted);
        setZones(copy);
    }, []);


    if (!zones.length) return <></>;
    if (!zone) return <></>;
    return <div className='flex gap-5 padding'>
        <label className='light-color'>Time zone</label>
        <select defaultValue={zone} onChangeCapture={(e) => {
            window.user.timeZoneConverted = parseInt(e.target.value);
        }}>{
                zones.map(x => {
                    return <option key={x} value={x.value}>{x.title}</option>;
                })
            }</select>
    </div>;
};

// COMPONENT
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.serverTimeRef = null;
        this.state = Object.assign({}, props);
        this.state.selectedCurrency = getLocalStorage('selected-currency');
        if (!this.state.selectedCurrency) this.state.selectedCurrency = '';
    }

    componentDidMount() {
        var that = this;
        application().resolve(null, Endpoints.MyConfiguration, null).then((result) => {
            try {
                var data = result.result;
                var currencies = JSON.parse(!data.currencies ? '[]' : data.currencies);
                this.setState({ currencies: currencies });
            } catch (err) {
                //
            }
        });
        setInterval(function () {
            var now = new Date();
            try {
                if (that.serverTimeRef != null) {
                    that.serverTimeRef.innerHTML = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0') + ':' + now.getSeconds().toString().padStart(2, '0');
                }
            } catch (err) {
                //
            }
        }, 1000);
    }

    toggleMenu() {
        if (this.props.onToggleMenuClicked === null) return;
        this.setState({ collapsed: !this.state.collapsed }, () => {
            this.props.onToggleMenuClicked();
        });
    }

    changeCurrency(currency) {
        setLocalStorage('selected-currency', currency);
        this.setState({ selectedCurrency: currency });
    }

    renderOperators() {
        if (this.props.user.loginInformation.SuperUser) return <SelectPointOfView caption={this.props.user.loginInformation.Email} team={this.props.user.loginInformation.UserPrivileges.PointOfView} />;
        var operators = this.props.user.loginInformation.UserPrivileges.AvailableOperators;
        if (operators.length > 1) {
            return <SelectPointOfView caption={this.props.user.loginInformation.Email} team={this.props.user.loginInformation.UserPrivileges.PointOfView} />;
        }
        return <React.Fragment>
            {this.props.user.loginInformation.Operator + '/' + this.props.user.loginInformation.UserGroupName}
        </React.Fragment>;
    }

    render() {
        var brand = getBrand();
        if (!this.state.currencies) return <div />;
        return (
            <header>
                <img className='brand' src={'//cdn-d.tentangle.com/providers/' + brand.logo} />
                <div className='menu-toggle' onClick={() => this.toggleMenu()}>
                    <Icon icon={this.state.collapsed ? icons.close : icons.bars} size="lg" />
                </div>
                <div className='team'>
                    {this.renderOperators()}
                </div>
                <div className='server-time' ref={(r) => this.serverTimeRef = r}></div>
                <select onChangeCapture={(e) => this.changeCurrency(e.target.value)} defaultValue={this.state.selectedCurrency}>
                    <option value=''>All</option>
                    {
                        this.state.currencies.map((x) => {
                            return <option value={x.CurrencyCode} key={x.CurrencyCode}>{x.CurrencyCode}</option>;
                        })
                    }
                </select>
                <GMTSelector />
                <LanguageSelector />
                <div className='user-information'>
                    <div className='details'>
                        <span>{this.props.user.loginInformation.Email}</span>
                        <i>{lang('Last Login')}:{format(new Date(this.props.user.loginInformation.LastLogin), 'dd/MM/yyyy hh:mm', {
                            awareOfUnicodeTokens: true
                        })}:{this.props.user.loginInformation.LastIp}</i>
                    </div>
                </div>
            </header>
        );
    }
}

Header.defaultProps = {
    onToggleMenuClicked: null
};

Header.propTypes = {
    onToggleMenuClicked: PropTypes.func,
    user: PropTypes.object
};




const mapStateToProps = state => {
    return {
        user: state.user
    };
};


export default connect(mapStateToProps)(Header);


