// IMPORT PACKAGE REFERENCES

import React from 'react';

// COMPONENT

export const Footer = () => (
    <footer></footer>
);

