import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const CHANGE_LANGUAGE            = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_PENDING    = 'CHANGE_LANGUAGE_PENDING';
export const CHANGE_LANGUAGE_FULFILLED  = 'CHANGE_LANGUAGE_FULFILLED';
export const CHANGE_LANGUAGE_REJECTED   = 'CHANGE_LANGUAGE_REJECTED';



// ACTION CREATORS

function changeLanguageAction(lang) {
    return {
        type: CHANGE_LANGUAGE,
        payload: postData({},Endpoints.UserChangeLanguage + lang.toLowerCase())
    };
}




// EXPORT ACTIONS
export { changeLanguageAction as changeLanguage };

