import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const GET_MARKETING_EMAIL_TEMPLATE = 'GET_MARKETING_EMAIL_TEMPLATE';
export const GET_MARKETING_EMAIL_TEMPLATE_PENDING = 'GET_MARKETING_EMAIL_TEMPLATE_PENDING';
export const GET_MARKETING_EMAIL_TEMPLATE_FULFILLED = 'GET_MARKETING_EMAIL_TEMPLATE_FULFILLED';
export const GET_MARKETING_EMAIL_TEMPLATE_REJECTED = 'GET_MARKETING_EMAIL_TEMPLATE_REJECTED';


export const SEARCH_MARKETING_EMAIL_TEMPLATE = 'SEARCH_MARKETING_EMAIL_TEMPLATE';
export const SEARCH_MARKETING_EMAIL_TEMPLATE_PENDING = 'SEARCH_MARKETING_EMAIL_TEMPLATE_PENDING';
export const SEARCH_MARKETING_EMAIL_TEMPLATE_FULFILLED = 'SEARCH_MARKETING_EMAIL_TEMPLATE_FULFILLED';
export const SEARCH_MARKETING_EMAIL_TEMPLATE_REJECTED = 'SEARCH_MARKETING_EMAIL_TEMPLATE_REJECTED';

export const SEARCH_MARKETING_TEMPLATES_EVENTS = 'SEARCH_MARKETING_TEMPLATES_EVENTS';
export const SEARCH_MARKETING_TEMPLATES_EVENTS_PENDING = 'SEARCH_MARKETING_TEMPLATES_EVENTS_PENDING';
export const SEARCH_MARKETING_TEMPLATES_EVENTS_FULFILLED = 'SEARCH_MARKETING_TEMPLATES_EVENTS_FULFILLED';
export const SEARCH_MARKETING_TEMPLATES_EVENTS_REJECTED = 'SEARCH_MARKETING_TEMPLATES_EVENTS_REJECTED';


export const ADD_MARKETING_EMAIL_TEMPLATE = 'ADD_MARKETING_EMAIL_TEMPLATE';
export const ADD_MARKETING_EMAIL_TEMPLATE_PENDING = 'ADD_MARKETING_EMAIL_TEMPLATE_PENDING';
export const ADD_MARKETING_EMAIL_TEMPLATE_FULFILLED = 'ADD_MARKETING_EMAIL_TEMPLATE_FULFILLED';
export const ADD_MARKETING_EMAIL_TEMPLATE_REJECTED = 'ADD_MARKETING_EMAIL_TEMPLATE_REJECTED';


export const UPDATE_MARKETING_EMAIL_TEMPLATE = 'UPDATE_MARKETING_EMAIL_TEMPLATE';
export const UPDATE_MARKETING_EMAIL_TEMPLATE_PENDING = 'UPDATE_MARKETING_EMAIL_TEMPLATE_PENDING';
export const UPDATE_MARKETING_EMAIL_TEMPLATE_FULFILLED = 'UPDATE_MARKETING_EMAIL_TEMPLATE_FULFILLED';
export const UPDATE_MARKETING_EMAIL_TEMPLATE_REJECTED = 'UPDATE_MARKETING_EMAIL_TEMPLATE_REJECTED';

export const DELETE_MARKETING_EMAIL_TEMPLATE = 'DELETE_MARKETING_EMAIL_TEMPLATE';
export const DELETE_MARKETING_EMAIL_TEMPLATE_PENDING = 'DELETE_MARKETING_EMAIL_TEMPLATE_PENDING';
export const DELETE_MARKETING_EMAIL_TEMPLATE_FULFILLED = 'DELETE_MARKETING_EMAIL_TEMPLATE_FULFILLED';
export const DELETE_MARKETING_EMAIL_TEMPLATE_REJECTED = 'DELETE_MARKETING_EMAIL_TEMPLATE_REJECTED';

export const ADD_MARKETING_TEMPLATES_EVENTS = 'ADD_MARKETING_MARKETING_TEMPLATES_EVENTS';
export const ADD_MARKETING_TEMPLATES_EVENTS_PENDING = 'ADD_MARKETING_MARKETING_TEMPLATES_EVENTS_PENDING';
export const ADD_MARKETING_TEMPLATES_EVENTS_FULFILLED = 'ADD_MARKETING_MARKETING_TEMPLATES_EVENTS_FULFILLED';
export const ADD_MARKETING_TEMPLATES_EVENTS_REJECTED = 'ADD_MARKETING_MARKETING_TEMPLATES_EVENTS_REJECTED';

// ACTION CREATORS




export function getMarketingEmailTemplate(id) {
    return {
        type: GET_MARKETING_EMAIL_TEMPLATE,
        payload: postData(Endpoints.MarketingGetEmailTemplate + id)
    };
}

export function searchMarketingEmailTemplate(query) {
    return {
        type: SEARCH_MARKETING_EMAIL_TEMPLATE,
        payload: postData(query, Endpoints.MarketingSearchEmailTemplate)
    };
}

export function searchMarketingTemplatesEvents(id) {
    return {
        type: SEARCH_MARKETING_TEMPLATES_EVENTS,
        payload: postData(null,Endpoints.MarketingSearchTemplateEvents + id)
    };
}

export function addMarketingEmailTemplate(query) {
    return {
        type: ADD_MARKETING_EMAIL_TEMPLATE,
        payload: postData(query, Endpoints.MarketingAddEmailTemplate)
    };
}

export function upddateMarketingEmailTemplate(query) {
    return {
        type: UPDATE_MARKETING_EMAIL_TEMPLATE,
        payload: postData(query, Endpoints.MarketingUpdateEmailTemplate)
    };
}

export function deleteMarketingEmailTemplate(id) {
    return {
        type: DELETE_MARKETING_EMAIL_TEMPLATE,
        payload: postData(null, Endpoints.MarketingDeleteEmailTemplate + id)
    };
}


export function addTemplatesEvents(query) {
    return {
        type: ADD_MARKETING_TEMPLATES_EVENTS,
        payload: postData(query, Endpoints.MarketingAddTemplatesEvents)
    };
}

