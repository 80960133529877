import { LOAD_WIDGET_PENDING,LOAD_WIDGET_FULFILLED,LOAD_WIDGET_REJECTED } from '../actions/WidgetActions';



const defaultPending = (state) => {
    let res = {
        ...state,
        loading: true,
        loaded: false,
        failed: false
    };
    return res;
};

const defaultFulfilled = (state, action) => {
    let res = {
        ...state,
        errorDisplayed: false,
        error: action.payload.error,
        loading: false,
        loaded: true,
        failed: false
    };

    res[action.payload.target] = action.payload;
    return res;
};

const defaultRejected = (state, action) => {

    var res = {
        ...state,
        errorDisplayed: false,
        error: action.payload.toString() === 'SyntaxError: Unexpected token < in JSON at position 0' ? generalError : action.payload.error,
        loading: false,
        loaded: false,
        failed: true
    };

    return res;
};





const initialState = {
    loading: false,
    loaded: false,
    failed: false,
    error: {},
    errorDisplayed: false
};

const generalError = {
    errorNumber: 0,
    message: 'An error occured please try later.'
};




// REDUCER
export const WidgetReducer = (state = initialState, action) => {
    switch (action.type) {
        // Update operator
        case LOAD_WIDGET_PENDING:
            return defaultPending(state);
        case LOAD_WIDGET_FULFILLED:
            return defaultFulfilled(state, action);
        case LOAD_WIDGET_REJECTED:
            return defaultRejected(state, action);

        default:
            return state;
    }
};