import { defaultPending, defaultFulfilled, listerInitial, defaultRejected } from './ApplicationReducer';
import {
    SEARCH_WITHDRAWS_PENDING,
    SEARCH_DEPOSITS_PENDING,
    SEARCH_WITHDRAWS_FULFILLED,
    SEARCH_WITHDRAWS_REJECTED,
    SEARCH_DEPOSITS_FULFILLED,
    SEARCH_DEPOSITS_REJECTED,
    DEPOSIT_OPERATION_PENDING,
    DEPOSIT_OPERATION_FULFILLED,
    DEPOSIT_OPERATION_REJECTED,
    WITHDRAW_OPERATION_PENDING,
    WITHDRAW_OPERATION_FULFILLED,
    WITHDRAW_OPERATION_REJECTED,
    SEARCH_TRANSACTIONS_PENDING,
    SEARCH_TRANSACTIONS_FULFILLED,
    SEARCH_TRANSACTIONS_REJECTED,
} from '../actions/TransactionActions';


const initialState = {
    data: null,
    transactions: listerInitial,
    deposits: listerInitial,
    withdraws: listerInitial,
    pendingDeposits:listerInitial,
    pendingWithdraws:listerInitial,
    bulkOperationDepositResults: listerInitial,
    bulkOperationWithdrawResults: listerInitial,
    loading: false,
    loaded: false,
    failed: false
};

// REDUCER
export const TransactionsReducer = (state = initialState, action) => {
    switch (action.type) {
        // DEPOSITS
        case SEARCH_DEPOSITS_PENDING:
            return defaultPending('pendingDeposits', state, { bulkOperationDepositResults: listerInitial, bulkOperationWithdrawResults: listerInitial });
        case SEARCH_DEPOSITS_FULFILLED:
            return defaultFulfilled('pendingDeposits', state, action);
        case SEARCH_DEPOSITS_REJECTED:
            return defaultRejected('pendingDeposits', state, action, { bulkOperationDepositResults: listerInitial, bulkOperationWithdrawResults: listerInitial });

        // WITHDRAWS
        case SEARCH_WITHDRAWS_PENDING:
            return defaultPending('pendingWithdraws', state, { bulkOperationDepositResults: listerInitial, bulkOperationWithdrawResults: listerInitial });
        case SEARCH_WITHDRAWS_FULFILLED:
            return defaultFulfilled('pendingWithdraws', state, action);
        case SEARCH_WITHDRAWS_REJECTED:
            return defaultRejected('pendingWithdraws', state, action, { bulkOperationDepositResults: listerInitial, bulkOperationWithdrawResults: listerInitial });

        // DEPOSITS
        case DEPOSIT_OPERATION_PENDING:
            return defaultPending('bulkOperationDepositResults', state);
        case DEPOSIT_OPERATION_FULFILLED:
            return defaultFulfilled('bulkOperationDepositResults', state, action);
        case DEPOSIT_OPERATION_REJECTED:
            return defaultRejected('bulkOperationDepositResults', state, action);

        // WITHDRAWS
        case WITHDRAW_OPERATION_PENDING:
            return defaultPending('bulkOperationWithdrawResults', state);
        case WITHDRAW_OPERATION_FULFILLED:
            return defaultFulfilled('bulkOperationWithdrawResults', state, action);
        case WITHDRAW_OPERATION_REJECTED:
            return defaultRejected('bulkOperationWithdrawResults', state, action);

        // TRANSACTIONS
        case SEARCH_TRANSACTIONS_PENDING:
            return defaultPending('transactions', state, { bulkOperationDepositResults: listerInitial, bulkOperationWithdrawResults: listerInitial });
        case SEARCH_TRANSACTIONS_FULFILLED:
            return defaultFulfilled('transactions', state, action);
        case SEARCH_TRANSACTIONS_REJECTED:
            return defaultRejected('transactions', state, action, { bulkOperationDepositResults: listerInitial, bulkOperationWithdrawResults: listerInitial });

        default:
            return state;
    }
};