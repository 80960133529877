import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../../../../v2/Components/Card/Card';
import { Table } from '../../../../../v2/Components/Controls/Table/Table';
import { currency } from '../../../../../v2/Lib/Common/currency';


const volumeSummaryModel = {
    fields: [
        {
            name: 'Currency'
        },
        {
            name: 'Prematch',
            title: 'Prematch',
            renderRow: (row) => {
                return currency(Math.abs(row.Prematch), row.Currency);
            }
        },
        {
            name: 'LiveStake',
            title: 'Live Stake',
            renderRow: (row) => {
                return currency(Math.abs(row.LiveStake), row.Currency);
            }
        },
        {
            name: 'Liability',
            title: 'Liability',
            renderRow: (row) => {
                return currency(Math.abs(row.Liability), row.Currency);
            }
        },
        {
            name: 'LiveLiability',
            title: 'Live Liability',
            renderRow: (row) => {
                return currency(Math.abs(row.LiveLiability), row.Currency);
            }
        }
    ]
};

export const VolumesSummary = () => {
    const summary = useSelector((state) => state.volumes).volumesSummary;

    return <Card title='Volumes' className='no-padding border'>
        <Table model={volumeSummaryModel} data={summary} key={summary.key} />
    </Card>;
};

