
// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Search } from '../Centrum/Search/Search';
import { Page } from '../Centrum/Page/Page';
import { searchMarketingEmailTemplate, deleteMarketingEmailTemplate, searchMarketingTemplatesEvents, addTemplatesEvents, upddateMarketingEmailTemplate, addMarketingEmailTemplate } from '../../state/actions/MarketingActions';
import { resetState } from '../../state/reducers/ApplicationReducer';
import { ActionTypes, getActions, getAction } from '../Centrum/Common/ActionManager';
import { EmailTemplate } from '../Marketing/EmailTemplate';
import { TemplatesEvents } from '../Marketing/TemplatesEvents';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../Centrum/Application/CentrumApplication';
import { Card } from '../Centrum/Card/Card';
import { lang } from '../Centrum/Localization/lang';
import { PromotionsPage } from './Promotions/PromotionsPage';

const applicationActionType = ActionTypes.Marketing;
const applicationActions = getActions(applicationActionType);



const editModalActions = {
    items: [
        {
            type: 'submit',
            caption: 'Update',
            icon: 'confirm'
        }
    ]
};

const createModalActions = {
    items: [
        {
            type: 'submit',
            caption: 'Create',
            icon: 'confirm'
        }
    ]
};



// COMPONENT
class MarketingPage extends Page {
    constructor(props) {
        super(props);
        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);
        state.modal = null;
        state.action = action;
        this.state = state;
        this.applicationContext = null;
    }

    searchMarketingEmailTemplate(data, qb) {
        this.props.searchMarketingEmailTemplate(qb);
        this.setState({ templateList: data, searchQB: qb });
    }

    searchTemplatesEvents(data, qb) {
        if (data) {
            this.props.searchMarketingTemplatesEvents(data.OperatorId);
            this.setState({ templatesEvents: data, searchQB: qb });
        }
    }

    onEmailTemplateRowActionClicked(action, items) {
        if (action.type === ActionTypes.UpdateEmailTemplate) {
            this.createModal(
                'Edit Email Template',
                items[0],
                <EmailTemplate onSubmit={(button, data) => this.onUpdateEmailTemplate(button, data)} actions={editModalActions.items} modelName='emailTemplateEditModel' emailTemplateModel={items[0]} />
            );
        } else if (action.type === ActionTypes.DeleteEmailTemplate) {
            this.applicationContext.confirmActionAndPost(items[0], 'deleteEmailTemplate', '/marketing/deleteEmailTemplate?&id=' + items[0].Id, null, 'col2', action.caption, 'Operation Completed', false);
        } else if (action.type === ActionTypes.PreviewEmailTemplate) {
            this.createModal(
                'Preview',
                items[0],
                <div dangerouslySetInnerHTML={{ __html: items[0].Body }}></div>
            );
            
        }
    }

    createModal(title, data, content) {
        var modal = {
            title: lang(title),
            data: data,
            size: 'large',
            content: content,
            onActionClicked: () => { this.applicationContext.closeModal(); }
        };
        this.applicationContext.openModal(modal);
    }

    onCreateEmailTemplate(button, data) {
        const posDataModel = {
            fields: [
                { Name: 'Id', Value: data.Id },
                { Name: 'Body', Value: data.Body },
                { Name: 'Language', Value: data.Language },
                { Name: 'Name', Value: data.Name },
                { Name: 'OperatorId', Value: data.OperatorId },
                { Name: 'Removal', Value: data.Removal },
                { Name: 'Title', Value: data.Title }]
        };
        this.props.addMarketingEmailTemplate(posDataModel).then((res) => {
            var result = res.value;
            if (result !== undefined && result.status === 1) {
                this.openInformationModal(<p>{lang('Data Created')}</p>);
                this.searchMarketingEmailTemplate(this.state.templateList, this.state.searchQB);
            } else {
                this.openErrorModal(<p>{lang('Unable to create data, please try later')}</p>);
            }
        }).catch((err) => {
            console.log(err);
            this.openErrorModal(<p>{lang('Unable to create data, please try later')}</p>);
        });
    }

    onUpdateEmailTemplate(button, data) {
        this.setState({ dialogClassName: null });
        const posDataModel = {
            fields: [
                { Name: 'Id', Value: data.Id },
                { Name: 'Body', Value: data.Body },
                { Name: 'Language', Value: data.Language },
                { Name: 'Name', Value: data.Name },
                { Name: 'OperatorId', Value: data.OperatorId },
                { Name: 'Removal', Value: data.Removal },
                { Name: 'Title', Value: data.Title }
            ]
        };
        this.openBusyModal(lang('Loading'), <p>{lang('Please wait')}</p>);
        this.props.upddateMarketingEmailTemplate(posDataModel).then((res) => {
            var result = res.value;
            if (result !== undefined && result.status === 1) {
                this.openInformationModal(<p>{lang('Data updated')}</p>);
                //this.searchMarketingEmailTemplate(this.state.templateList, this.state.searchQB);
            } else {
                this.openErrorModal(<p>{lang('Unable to update data, please try later')}</p>);
            }
        }).catch((err) => {
            console.log(err);
            this.openErrorModal(<p>{lang('Unable to update data, please try later')}</p>);
        });
    }

    updateTemplatesEvents(button, data) {
        const updatedDataModel = {
            fields: [
                { Name: 'Id', Value: data.Id },
                { Name: 'EmailTemplateId', Value: data.emailTemplateId },
                { Name: 'EventCategory', Value: data.eventCategory },
                { Name: 'EventType', Value: data.eventType },
                { Name: 'OperatorId', Value: data.operatorId }]
        };
        this.props.addTemplatesEvents(updatedDataModel).then((res) => {
            var result = res.value;
            if (result !== undefined && result.status === 1) {
                this.openInformationModal(<p>{lang('Data updated')}</p>);
                //this.searchTemplatesEvents(this.state.templateList, this.state.searchQB);
            } else {
                this.openErrorModal(<p>{lang('Unable to update data, please try later')}</p>);
            }
        }).catch((err) => {
            console.log(err);
            this.openErrorModal(<p>{lang('Unable to update data, please try later')}</p>);
        });
    }


    onTemplatesEventsRowActionClicked(action, items) {
        if (action.type === ActionTypes.UpdateTemplatesEvents) {
            this.createModal(
                'Edit Templates Events',
                items[0],
                <TemplatesEvents onSubmit={(button, data) => this.updateTemplatesEvents(button, data)} key='temlatesEventsEditPage' selectedData={items[0]} />
            );
        }
    }

    render() {
        return (
            <CentrumApplication caption='Marketing' icon='folder' type={applicationActionType} loading={this.props.loading}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <Card caption='New Template'>
                                        <EmailTemplate 
                                            modelName='emailTemplateCreateModel' 
                                            onSubmit={(button, data) => this.onCreateEmailTemplate(button, data)} 
                                            actions={createModalActions.items} 
                                            emailTemplateModel={this.state.emailTemplateModel} />
                                    </Card>
                                    <Card caption='Search Email Template'>
                                        <Page>
                                            <Search
                                                model='searchEmailTemplate'
                                                resultModel='emailTemplateSearchResult'
                                                data={this.props.templateList}
                                                onSubmit={(data, qb) => { this.props.searchMarketingEmailTemplate(qb); }}
                                                onActionClicked={this.onEmailTemplateRowActionClicked.bind(this)}
                                                actions={getAction(ActionTypes.EmailTemplatesActions).convertToItems()}
                                            />
                                        </Page>
                                    </Card>

                                    <Card caption='Search Tepmlates Events'>
                                        <Search
                                            model='searchTemplatesEvents'
                                            resultModel='templateEventsSearchResult'
                                            onActionClicked={this.onTemplatesEventsRowActionClicked.bind(this)}
                                            data={this.props.templatesEventsList}
                                            onSubmit={this.searchTemplatesEvents.bind(this)}
                                            actions={getAction(ActionTypes.TemplatesEventsActions).convertToItems()}
                                        />
                                    </Card>
                                    <PromotionsPage />
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

MarketingPage.defaultProps = {
    action: null,
    show: true,
    error: null,
    emailTemplateModel: {},
    emailTemplateSearchRequest: {},
    dialogClassName: null
};

MarketingPage.propTypes = {
    resetState: PropTypes.func,
    action: PropTypes.object,
    show: PropTypes.bool,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    failed: PropTypes.bool,
    error: PropTypes.object,
    emailTemplateModel: PropTypes.object,
    emailTemplateSearchRequest: PropTypes.object,
    dialogClassName: PropTypes.string
};


const mapStateToProps = state => {
    return {
        templatesEvents: state.marketing.templatesEvents,
        templateList: state.marketing.templateList,
        templatesEventsList: state.marketing.templatesEventsList,
        loading: state.application.loading,
        loaded: state.application.loaded,
        failed: state.application.failed,
        error: state.application.error
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        searchMarketingEmailTemplate: searchMarketingEmailTemplate,
        upddateMarketingEmailTemplate: upddateMarketingEmailTemplate,
        deleteMarketingEmailTemplate: deleteMarketingEmailTemplate,
        searchMarketingTemplatesEvents: searchMarketingTemplatesEvents,
        addTemplatesEvents: addTemplatesEvents,
        addMarketingEmailTemplate: addMarketingEmailTemplate,
        resetState: resetState
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(MarketingPage);
export { hoc as MarketingPage };

