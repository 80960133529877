import './_ticket.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../../../Lib/Common/language';
import { formatDateWithMonth, getTime } from '../../../../Lib/Common/dateHelpers';
import { SportsTicketTypes } from '../BettingEnums';
import { currency } from '../../../../Lib/Common/currency';


export const SelectionStatus = (status) => {
    switch (status) {
        case 0:
            return <span className='information'>{lang('Open')}</span>;
        case 3:
            return <span className='warning'>{lang('Lost')}</span>;
        case 4:
            return <span className='success'>{lang('Win')}</span>;
        case 8:
            return <span className='success'>{lang('Cashout')}</span>;
        case 9:
            return <span className='critical'>{lang('Cancel')}</span>;
        default:
            return <span>{status}</span>;
    }
};

export const Ticket = (props) => {
    const ticket = props.ticket;

    const renderBonus = (ticket) => {
        var classList = ['flex gap-5'];
        if (!ticket.ticketBonus) classList.push('hidden');
        var percentage = ticket.ticketBonusPercentage ? '%' + ticket.ticketBonusPercentage : '-';
        var amount = ticket.ticketBonus ? (currency(ticket.ticketBonusLimit && ticket.ticketBonusAmount >= ticket.ticketBonusLimit ? ticket.ticketBonusLimit : ticket.ticketBonusAmount, ticket.currency)) : '-';
        return <div className={classList.join(' ')}>
            <label>{lang('Bonus')}</label><span>{percentage}</span>
            <label>{lang('Bonus Amount')}</label><span>{amount}</span>
        </div>;
    };

    const renderStatus = (ticket) => {
        switch (ticket.status) {
            case 0:
                return <span className='information rectangle border radius padding half'>{lang('Open')}</span>;
            case 1:
                return <span className='success rectangle border radius padding half'>{lang('Win')}</span>;
            case 2:
                return <span className='warning rectangle border radius padding half'>{lang('Lost')}</span>;
            case 3:
                return <span className='success rectangle border radius padding half'>{lang('Cashout')}</span>;
            case 4:
                return <span className='critical rectangle border radius padding half'>{lang('Cancel')}</span>;
            case 5:
                return <span className='critical rectangle border radius padding half'>{lang('Cashback')}</span>;
            default:
                return <span>{ticket.status}</span>;
        }
    };



    const date = ticket.entryDate.indexOf('Z') > 0 ? ticket.entryDate : ticket.entryDate + 'Z';
    return <div className='sport-ticket border radius'>
        <ul className='list surface'>
            <li className='surface med'>
                <div className='flex gap-5 padding-left padding-right padding-top align-start'>
                    <div className='flex vertical align-start'>
                        <div className='ticket-info'>
                            <label>{lang('Date')}</label><span>{formatDateWithMonth(new Date(date))} {getTime(new Date(date))}</span>
                            <label>{lang('Type')}</label><span>{SportsTicketTypes[ticket.ticketType]}</span>
                            <label>{lang('Stake')}</label><span>{currency(ticket.bet, ticket.currency)}</span>
                            <label>{lang('Odds')}</label><span>{currency(ticket.odds, ticket.currency)}</span>
                            <label>{lang('Possible Win')}</label><span>{currency(ticket.possibleWin, ticket.currency)}</span>
                            <label>{lang('Bets')}</label><span>{ticket.selections}</span>
                        </div>
                        {renderBonus(ticket)}
                    </div>
                    <div className='align-right flex gap-5'>
                        {renderStatus(ticket)}
                    </div>
                </div>
            </li>
        </ul>
        {props.children}
    </div >;
};

Ticket.propTypes = {
    ticket: PropTypes.object,
    mobile: PropTypes.bool,
    children: PropTypes.node
};