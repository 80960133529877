
const VerificationType = {
    NotVerified: 0,
    Pending: 1,
    Reject: 2,
    Verified: 3
};
const UploadedDocumentKey = {
    Address: 'address',
    Id: 'id'
};
module.exports = { VerificationType, UploadedDocumentKey };
