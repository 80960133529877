export const RetailTypes = {
    '0': 'Virtual',
    '1': 'Physical'
};

export const RetailUserTypes = {
    '9': 'VR. Owner',
    '13': 'VR. Manager',
    '14': 'VR. Agent',
    '15': 'VR. Cashier',
    '16': 'VR. Accountant',
    '18': 'VR. Shop Owner',
    '19': 'VR. Shop Manager',
    '10': 'P. Owner',
    '11': 'P. Manager',
    '12': 'P. Cashier',
};


export const VirtualRetailUserTypes = {
    '9': 'Owner',
    '13': 'Manager',
    '14': 'Agent',
    '15': 'Cashier',
    '16': 'Accountant',
    '18': 'Shop Owner',
    '19': 'Shop Manager',
};


export const PhysicalRetailUserTypes = {
    '10': 'Owner',
    '11': 'Manager',
    '12': 'Cashier'
};


export const TransactionTypes = {
    0: 'Shop Deposit',
    1: 'Shop Withdraw',
    2: 'Player Deposit',
    3: 'Player Withdraw',
    4: 'Cancel'
};
