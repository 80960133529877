

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';


import { getActions, ActionTypes, getAction } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';

import { apiResult, Endpoints } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { Search } from '../../Centrum/Search/Search';
import { eventsOfOpenSlipsModel, searchEventsOfOpenSlipsModel, searchManualSettledTicketsModel } from '../SportTickets/models';
import { TicketMarkets } from '../SportTickets/TicketMarkets';
import { betSlipResults, manualBetSlipResults } from '../RiskManagement/models';
import { EventRecovery } from '../SportTickets/EventRecovery';
import { LSportSportStatusCodes } from '../../Centrum/_models/ModelEnums';


const applicationActionType = ActionTypes.Sports;
const applicationActions = getActions(applicationActionType);


const eventModel = {
    fields: [
        {
            name: 'Name'
        }
    ]
};

// COMPONENT
export const SportOperationsPage = () => {
    const [selectedEvent, selectEvent] = useState(null);
    const [filter, setFilters] = useState(null);
    const [selections, setSelections] = useState(false);

    const betSlipSearchResults = useSelector((state) => state.data.betSlipSearchResults);
    const searchEventResults = useSelector((state) => state.data.searchEventResults);
    const searchManualSettledTicketsResult = useSelector((state) => state.data.searchManualSettledTicketsResult);
    const eventsOfOpenSlipResults = useSelector((state) => state.data.eventsOfOpenSlipResults);


    const onActionClicked = (action, items) => {
        const item = items[0];
        switch (action.type) {
            case ActionTypes.GetTicketSelections:
                //var fields = Object.assign([], eventsOfOpenSlipsModel.fields);
                var fields = [];
                fields.push({ name: 'EventId', value: item.EventId, and: true, exact: true });
                setFilters(fields);
                selectEvent(item);
                setSelections(true);
                break;
        }
    };




    const feedType = window.user.features.feedType;

    return <CentrumApplication
        caption='Sportsbook' icon='futbol'
        type={applicationActionType}
    >
        <ApplicationContext.Consumer>
            {
                () => {
                    return <React.Fragment>
                        <CentrumApplicationActions actions={applicationActions} />
                        <CentrumApplicationContents>
                            <Card caption='Open Tickets'>
                                {!selections &&
                                    <Search
                                        model={'openTickets'}
                                        resultModel={betSlipResults}
                                        showPagination={false}
                                        data={betSlipSearchResults}
                                        onSubmit={(data, qb) => { return apiResult('betSlipSearchResults', Endpoints.SearchSportTickets, qb, 'fetching please wait', 'unable to fetch report'); }}
                                    />
                                }
                            </Card>
                            <Card caption='Search Events'>
                                <Search
                                    model={eventModel}
                                    resultModel={{
                                        fields: [
                                            {
                                                name: 'date',
                                                display: 'Date',
                                                formatter: (val) => {
                                                    var converted = val.indexOf('Z') > 0 ? val : val + 'Z';
                                                    return new Date(converted).toLocaleString('en-Gb', { hours12: false });
                                                }
                                            },
                                            {
                                                name: 'id',
                                                display: 'Id'
                                            },
                                            {
                                                name: 'name',
                                                display: 'Name'
                                            },
                                            {
                                                name: 'outright',
                                                display: 'Outright'
                                            },
                                            {
                                                name: 'sport',
                                                display: 'Sport'
                                            },
                                            {
                                                name: 'region',
                                                display: 'Region'
                                            },
                                            {
                                                name: 'league',
                                                display: 'League'
                                            },
                                            {
                                                name: 'status',
                                                display: 'Status',
                                                formatter: (val) => {
                                                    switch (feedType) {
                                                        case 1:
                                                            return LSportSportStatusCodes[val];
                                                        default:
                                                            break;
                                                    }
                                                    return val;
                                                }
                                            }
                                        ]
                                    }}
                                    showPagination={false}
                                    data={searchEventResults}
                                    onSubmit={(data) => {
                                        if (data.Name && data.Name.length < 4) return;
                                        if (!data.Name) return;
                                        const send = false;
                                        if (!send) return;
                                        return apiResult('searchEventResults', `${Endpoints.SportEvents}`, data, 'fetching please wait', 'unable to fetch report');
                                    }}
                                />
                            </Card>
                            <Card caption='Unresolved Events'>
                                {!selections && feedType == 0 &&
                                    <Search
                                        model={searchEventsOfOpenSlipsModel}
                                        actions={getAction(ActionTypes.SportOpenTicketOperations).convertToItems()}
                                        onActionClicked={(action, selection) => onActionClicked(action, selection)}
                                        resultModel={eventsOfOpenSlipsModel}
                                        showPagination={false}
                                        data={eventsOfOpenSlipResults}
                                        onSubmit={(data, qb) => { return apiResult('eventsOfOpenSlipResults', Endpoints.SearchEventsOfOpenSlipsModel, qb, 'fetching please wait', 'unable to fetch report'); }}
                                    />
                                }
                                {selections && <TicketMarkets key={selectedEvent.Id} event={selectedEvent} filter={filter}
                                    onClose={() => setSelections(false)} />}
                            </Card>
                            <Card caption='Manual Settled Tickets'>
                                <Search
                                    model={searchManualSettledTicketsModel}
                                    resultModel={manualBetSlipResults}
                                    data={searchManualSettledTicketsResult}
                                    onSubmit={(data, qb) => {
                                        qb.fields = qb.fields.filter(x => x.name != 'Flags');
                                        qb.fields.push({ name: 'SelectionFlags', value: 2 ^ 4, exact: true, and: true });
                                        return apiResult('searchManualSettledTicketsResult', Endpoints.SearchManualSportTickets, qb, 'fetching please wait', 'unable to fetch report');
                                    }}
                                />
                            </Card>
                            <EventRecovery />
                        </CentrumApplicationContents>
                    </React.Fragment>;
                }
            }
        </ApplicationContext.Consumer>
    </CentrumApplication>;
};

SportOperationsPage.defaultProps = {
    configuration: {}
};

SportOperationsPage.propTypes = {
    configuration: PropTypes.object,
    eventsOfOpenSlipsModel: PropTypes.object,
    searchManualSettledTicketsModel: PropTypes.object
};
