import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Card } from '../../../Centrum/Card/Card';
import { Search } from '../../../Centrum/Search/Search';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { TestAccountFilter } from '../../../Centrum/_models/ModelEnums';
import { currency } from '../../../Centrum/Localization/lang';
import { apiResult, application, Endpoints } from '../../../Application/Application';
import { ActionTypes, getAction } from '../../../Centrum/Common/ActionManager';
import { bulkRemovePromotion } from './actions';
import { getHistory } from '../../../routers/AppRouter';
import { completeBonus } from '../../Players/actions';




const searchModel = {
    fields: [
        {
            name: 'Username',
            type: InputTypes.text,
            hidden: true
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            value: 3,
            values: {
                0: 'In Progress',
                1: 'Completed',
                3: 'Waiting for confirmation'
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        },
        {
            name: 'PromotionId',
            display: 'Promotion',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'promotion',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        }
    ]
};

const resultModel = {
    fields: [
        {
            name: 'ConsumedDate',
            display: 'Start Date',
            type: InputTypes.dateTime
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'Username'
        },
        {
            name: 'PlayerCode',
            display: 'Player Code'
        },
        {
            name: 'Group',
            display: 'Player Group'
        },
        {
            name: 'PromotionName',
            display: 'Promotion'
        },
        {
            name: 'TurnoverMultiplier',
            display: 'Turnover Multiplier'
        },
        {
            name: 'PlayerBalance',
            display: 'Starting Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'Amount',
            display: 'Bonus Amount',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'TotalBet',
            display: 'Total Stake',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'SportsBets',
            display: 'Bets'
        },
        {
            name: 'TotalWin',
            display: 'Win',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },

        {
            name: 'Balance',
            display: 'Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'BonusBalance',
            display: 'Bonus Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        }
    ]
};



export const PlayerWithBonus = () => {
    const [searchRef, setSearchRef] = useState(null);
    const [promotions, setPromotions] = useState(null);
    const data = useSelector((state) => state.data);
    useEffect(() => {
        application().resolve(null, Endpoints.ListPromotions, {}).then((result) => {
            let filtered = result.result.filter(x => x.OperatorId == window.user.OperatorId && x.Type != 7);
            setPromotions(filtered);
        });
    }, []);

    const onActionClicked = (action, items) => {
        var player = items[0];
        switch (action.type) {
            case ActionTypes.CompleteBonus:
                var promotion = promotions.find(x => x.Id == player.ActivePromotion);
                completeBonus({
                    playerId: player.PlayerId,
                    balance: player.Balance,
                    bonusBalance: player.BonusBalance,
                    currency: player.Currency,
                    consumedPromotionId: player.ActivePromotion,
                    promotion: promotion
                }, () => searchRef.submit());
                break;
            case ActionTypes.RemoveBonusFromPlayer:
                bulkRemovePromotion(items, () => {
                    searchRef.submit();
                });
                break;
            case ActionTypes.PlayerProfile:
                var location = `/Player/playerShortcuts/profile//${player.PlayerId}`;
                getHistory().push({
                    pathname: location,
                    state: { id: player.Id, profile: true }
                });
                break;
        }

    };

    return <Card caption='Players With Bonus'>
        <Search
            ref={(r) => setSearchRef(r)}
            multipleSelect={true}
            model={searchModel}
            resultModel={resultModel}
            onActionClicked={onActionClicked.bind(this)}
            actions={getAction(ActionTypes.SearchPlayersWithBonus).convertToItems()}
            data={data.playerWithBonus}
            onSubmit={(data, qb) => {
                return apiResult('playerWithBonus',
                    Endpoints.SearchPlayerBonusProgress, qb, 'fetching reports', 'unable to fetch report');
            }}
        />
    </Card>;
};


