import React from 'react';
import { API, HorseEndpoints } from '../../../../v2/Lib/Api/Api';
import { application } from '../../../Application/Application';
import { lang } from '../../../Centrum/Localization/lang';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';

export const getHorseEvent = (eventKey) => {
    return API.get(`${HorseEndpoints.GetEventById}/${eventKey}`);
};

export const saveHorseManualSettlementData = (data) => {
    var copy = { ...data };
    var status = {
        0: 'O',
        1: 'A',
        2: 'V',
        3: 'D',
        4: 'S'
    };

    var resultStatusCode = {
        0: 'O',
        1: 'Q',
        2: 'R',
        3: 'S',
        4: 'U',
        5: 'V',
        6: 'W',
        7: 'Y',
        8: 'Z'
    };
    var settlingStatus = {
        0: 'F',
        1: 'P',
        2: 'S',
        3: 'D',
        4: 'H',
        5: 'R',
        6: 'V'
    };

    copy.status = status[parseInt(data.status)];
    copy.result.statusCode = resultStatusCode[parseInt(data.result.statusCode)];
    copy.result.settlingStatus = settlingStatus[parseInt(data.result.settlingStatus)];

    application().modal.open(<ConfirmationModal title='Save Race Data' onConfirm={() => {
        API.post(`${HorseEndpoints.ManualSettlement}`, copy).then((result) => {
            console.log(result);
        });
    }}>
        <p>{lang('Confirm updating race data for race %1 [%2]?', [data.title, data.id])}</p>
    </ConfirmationModal>);
};