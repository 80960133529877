import './_dialog.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../Controls/Buttons/Button';
import { application } from '../../../Application/Application';
import { lang } from '../../../Centrum/Localization/lang';
import { Icon } from '../../../Controls/Icon/Icon';


const CloseModalButton = () => {
    return <Button onClick={() => application().modal.close()} title='Close'><Icon icon={'window-close'} size='lg' /></Button>;
};

export class Dialog extends Component {
    constructor(props) {
        super(props);
    }


    onActionClicked(action) {
        switch (action.type) {
            case 'close':
            default:
                if (this.props.onActionClicked !== null)
                    this.props.onActionClicked(action, this.props.data);
                break;
        }
    }

    renderActions() {
        if (!this.props.actions) return;
        return this.props.actions.items.map(action => {
            return <button type='button' key={action.type} onClick={() => this.onActionClicked(action)}>{lang(action.caption)}</button>;
        });
    }


    render() {
        var footer = this.props.footer;
        if (footer == null && this.props.actions == null) footer = <CloseModalButton />;
        return <React.Fragment>
            <div className={'modal-window dialog ' + this.props.className}>
                <div className='title'><h1>{this.props.title}</h1>{this.props.invisibileCloseIcon ==false && <Icon icon={'window-close'} size='lg' onClick={() => application().modal.close()} />}</div>
                <div className='body'>{this.props.children}</div>
                <div className='footer'>{this.renderActions()}{footer}</div>
            </div>
        </React.Fragment>;
    }
}

Dialog.defaultProps = {
    className: '',
    data: null,
    children: null,
    actions: null,
    footer: null,
    onActionClicked: null,
    invisibileCloseIcon : false
};

Dialog.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.any,
    children: PropTypes.node,
    actions: PropTypes.object,
    footer: PropTypes.node,
    onActionClicked: PropTypes.func,
    invisibileCloseIcon: PropTypes.bool
};
