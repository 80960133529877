import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { API, SportEndpoints } from '../../../v2/Lib/Api/Api';
import { lang } from '../../../v2/Lib/Common/language';
import { apiResult, Endpoints } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { Search } from '../../Centrum/Search/Search';
import { eventRevenueReportResult, sportEventRevenueReport } from './models';




export const EventSummaryReport = () => {
    const report = useSelector((state) => state.data.eventRevenueReport);
    const [eventData, setEventData] = useState(null);

    const renderEventData = () => {
        if (!eventData) return 'Event Summary';
        var date = eventData.date.indexOf('Z') == -1 ? new Date(eventData.date + 'Z').toLocaleString('en-Gb', { hours12: false }) : new Date(eventData.date).toLocaleString('en-Gb', { hours12: false });
        return <Card caption='Event' className='margin-top'>
            <div className='flex vertical gap-5 padding'>
                <div className='flex gap-5'>
                    <label>{lang('Event')}</label>
                    <span>{eventData.name}</span>
                </div>
                <div className='flex gap-5'>
                    <label>{lang('Date')}</label>
                    <span>{date}</span>
                </div>
            </div>
        </Card>;
    };

    return <Card caption={renderEventData()}>
        <Search
            model={sportEventRevenueReport}
            resultModel={eventRevenueReportResult}
            data={report}
            onSubmit={(data, qb) => {
                var eventId = qb.fields.find(x => x.name == 'EventId').value;
                API.post(`${SportEndpoints.GetEventById}/${eventId}`).then((e) => {
                    console.log(e);
                    setEventData(e.result);
                });
                return apiResult('eventRevenueReport', Endpoints.SportEventRevenueReport, qb, 'fetching sport tickets', 'unable to fetch report');
            }}
            onRenderExcel={(row) => {
                try {
                    var date = eventData.date.indexOf('Z') == -1 ? new Date(eventData.date + 'Z').toLocaleString('en-Gb', { hours12: false }) : new Date(eventData.date).toLocaleString('en-Gb', { hours12: false });
                    row.push({ name: `${eventData.name} ${date}` });
                } catch (err) {
                    console.log(err);
                }
            }}
        >
            {renderEventData()}
        </Search>
    </Card>;
};