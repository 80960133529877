import { defaultPending, defaultFulfilled, defaultRejected, listerInitial } from './ApplicationReducer';
import { GET_MY_PROFILE_PENDING, GET_MY_PROFILE_FULFILLED, GET_MY_PROFILE_REJECTED, SEARCH_MY_ACTIVITY_PENDING, SEARCH_MY_ACTIVITY_FULFILLED, SEARCH_MY_ACTIVITY_REJECTED } from '../actions/ProfileActions';

const initialState = {
    data: {
        result: {},
    },
    activity:listerInitial,
    loading: false,
    loaded: false,
    failed: false
};

// REDUCER
export const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_PROFILE_PENDING:
            return defaultPending('data', state);
        case GET_MY_PROFILE_FULFILLED:
            return defaultFulfilled('data', state, action);
        case GET_MY_PROFILE_REJECTED:
            return defaultRejected('data', state, action);

        case SEARCH_MY_ACTIVITY_PENDING:
            return defaultPending('activity', state);
        case SEARCH_MY_ACTIVITY_FULFILLED:
            return defaultFulfilled('activity', state, action);
        case SEARCH_MY_ACTIVITY_REJECTED:
            return defaultRejected('activity', state, action);

        default:
            return state;
    }
};