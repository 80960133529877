
import { defaultPending, defaultFulfilled, defaultRejected } from './ApplicationReducer';
import { GET_OPERATOR_CONFIGURATION, GET_OPERATOR_CONFIGURATION_FULFILLED, GET_OPERATOR_CONFIGURATION_REJECTED } from '../actions/OperatorActions';



const initialState = {
    configuration: {},
    operators:{},
    loading: false,
    loaded: false,
    failed: false,
    error: {},
    errorDisplayed: false
};




// REDUCER
export const OperatorReducer = (state = initialState, action) => {
    switch (action.type) {

        // CONFIGURATION
        case GET_OPERATOR_CONFIGURATION:
            return defaultPending('configuration', state, state.players);
        case GET_OPERATOR_CONFIGURATION_FULFILLED:
            return defaultFulfilled('configuration', state, action);
        case GET_OPERATOR_CONFIGURATION_REJECTED:
            return defaultRejected('configuration', state, action);

        default:
            return state;
    }
};