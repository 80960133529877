import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../../../../v2/Components/Card/Card';
import { RiskLevelEndpoints } from '../../../../../v2/Lib/Api/Api';
import { currency } from '../../../../../v2/Lib/Common/currency';
import { apiResult } from '../../../../Application/Application';
import { InputTypes } from '../../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Search } from '../../../../Centrum/Search/Search';
import { gotoProfile } from '../../../../Centrum/_models/Transactions';
import { openBetModal } from '../../models';


const searchLastRemaining = {
    fields: [
        {
            name: 'EventId',
            display: 'Event Id'
        },
        {
            name: 'MinStake',
            display: 'Min Stake'
        },
        {
            name: 'MaxStake',
            display: 'Max Stake'
        },
        {
            name: 'GroupId',
            display: 'Player Groups',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'MyPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Username',
            display: 'Username'
        }
    ]
};

const resultLastRemaining = {
    fields: [
        {
            name: 'ticketId',
            display: 'Ticket Id',
            // eslint-disable-next-line react/display-name
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return <a href='#' onClick={(e) => {
                    e.preventDefault();
                    openBetModal(val, false);
                }}>
                    {val}
                </a>;
            }
        },
        {
            name: 'username',
            display: 'Username',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return gotoProfile(row.playerId, row.username);
            }
        },
        {
            name: 'playerId',
            display: 'Player Id'
        },
        {
            name: 'stake',
            display: 'Stake',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, row.currency, 2, false);
            }
        },
        {
            name: 'odds',
            display: 'Odds',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return parseFloat(val).toFixed(2);
            }
        },
        {
            name: 'possibleWin',
            display: 'Possible Win',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, row.currency, 2, false);
            }
        }
    ]
};

export const LastRemainingTickets = () => {

    const lastRemaining = useSelector((state) => state.data.lastRemaining) ?? { result: [] };
    const user = useSelector((state) => state.user.loginInformation);
    const operatorId = user.UserPrivileges.PointOfView;

    const [sorting, setSorting] = useState({ column: 'possibleWin', direction: 1 })
    var sorted = { ...lastRemaining };
    if (sorted && sorted.result) {
        switch (sorting.column) {
            default:
                sorted.result = sorted.result.sort(sorting.direction == 1 ? (a, b) => a[sorting.column] > b[sorting.column] ? -1 : 1 : (b, a) => a[sorting.column] > b[sorting.column] ? -1 : 1);
                break;
        }

        sorted.sorting = sorting;
    }

    return <Card title='Last Remaining Tickets'>
        <Search
            model={searchLastRemaining}
            resultModel={resultLastRemaining}
            data={sorted}
            showPagination={false}
            onSortingClicked={(s) => {
                setSorting(s);
            }}
            onSubmit={(qb) => {
                return apiResult('lastRemaining', `${RiskLevelEndpoints.LastRemainingTickets}/${operatorId}`, qb ?? {}, 'fetching operator revenues reports', 'unable to fetch report');
            }}
        />
    </Card>;
};


LastRemainingTickets.propTypes = {

};