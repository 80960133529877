import { getStore } from '../../..';
import { getCurrentLanguage } from '../../../config';
import { formatCurrency, formats } from './currency';



const parameterizeString = (source, parameters) => {
    if (parameters === undefined) return source;
    if (parameters.length == 0) return source;
    var index = 1;
    parameters.map(p => {
        source = source.replace(new RegExp('%' + index, 'g'), p);
        index++;
    });
    return source;
};


let debug = false;
export const lang = (target, parameters) => {
    if (target === undefined) return '';
    var currentLanguage = getCurrentLanguage();
    if ((target in window.__languageData)) {
        if (currentLanguage in window.__languageData[target]) {
            return parameterizeString(window.__languageData[target][currentLanguage], parameters);
        } else {
            if (debug) console.warn('local key not found', target);
        }
    } else {
        if (debug) console.warn('local key not found', target);
    }

    return parameterizeString(target, parameters);
};

export const currency = (amount, decimalPlaces, currency, showSign = true) => {
    const format = true;
    if (isNaN(amount)) return;
    if (!format) {
        const targetCurrency = currency ? formats[currency].symbol : '';
        return `${amount}${showSign ? `${targetCurrency}` : ''}`;
    }
    if (isNaN(amount) || amount === null || amount === undefined || amount == 'NaN' || amount === '') {
        return '';
    }
    if (currency === undefined || currency === null) {
        currency = '';
    }

    const number = amount;
    const numberAfterDecimal = (number % 1).toString().substring(2);
    const numberBeforeDecimal = parseInt(number);

    amount = parseFloat(numberBeforeDecimal + '.' + numberAfterDecimal.substring(0, 2));


    return formatCurrency(amount, currency, showSign);
};

export const updateLanguage = (data) => {
    window.__languageData = Object.assign({}, data);
    getStore().dispatch({
        type: '_languageData_FULFILLED',
        payload: data
    });
};
