
// IMPORT PACKAGE REFERENCES

import React from 'react';
import { Page } from '../../Centrum/Page/Page';
import { ActionTypes, getActions } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { ManagePromotions } from './ManagePromotions/ManagePromotions';
import { PeriodicPromotions } from './PeriodicPromotions';
import { PlayerWithBonus } from './PlayerBonus/PlayerWithBonus';
import { PlayerWithoutBonus } from './PlayerBonus/PlayerWithoutBonus';
import { PendingPromotionsPage } from './PlayerBonus/PendingPromotionsPage';
import { SlipLotteryTicketsPage } from './PlayerBonus/SlipLotteryTicketsPage';


const applicationActionType = ActionTypes.Promotions;
const applicationActions = getActions(applicationActionType);



// COMPONENT
export class PromotionsPage extends Page {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }


    render() {
        return (
            <CentrumApplication caption='Promotions' icon='heart' type={applicationActionType}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <ManagePromotions />
                                    <PeriodicPromotions />
                                    <PlayerWithBonus />
                                    <PlayerWithoutBonus />
                                    <PendingPromotionsPage />
                                    <SlipLotteryTicketsPage />
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

PromotionsPage.defaultProps = {
};

PromotionsPage.propTypes = {

};


