import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';



export const Tracker = (props) => {
    const [widgetLoaded, setWidgetLoaded] = useState(false);

    useEffect(() => {
        if (props.id && !widgetLoaded) {
            setWidgetLoaded(false);
            window.SIR('addWidget', '#sr-widget', 'match.lmtPlus', { matchId: props.id });
        }
    });

    return <div className={'surface border shadow padding margin-bottom dark-med tracker-holder'}>
        <div id="sr-widget" data-sr-widget="match.lmt" data-sr-match-id={props.id}></div>
    </div>;
};

Tracker.propTypes = {
    id: PropTypes.number
};


