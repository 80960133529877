import './_application.scss';

import { createBrowserHistory } from 'history';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { icons } from '../Shared';
import { LoadingIndicator, ErrorIndicator } from '../../shared/LoadingIndicator/LoadingIndicator';
import { lang } from '../Localization/lang';


import { appHistory } from '../../..';
import { hasAccess } from '../Common/ActionManager';
import { postData } from '../../../state/services/ServiceHelpers';
import { CentrumForm } from '../CentrumForm/CentrumForm';
import { Table } from '../Table/Table';
import { application } from '../../Application/Application';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';
import { ErrorModal } from '../../Modal/Modals/Error/ErrorModal';
import { Icon } from '../../Controls/Icon/Icon';

export const ApplicationContext = React.createContext();
export const ActionContext = React.createContext();

export const ComponentTypes = {
    Page: 'Page',
    Table: 'Table',
    Card: 'Card',
    Tab: 'Tab'
};

export const ActionYesNo = {
    items: [
        {
            type: 'confirm',
            caption: 'Yes',
            icon: 'confirm'
        },
        {
            type: 'cancel',
            caption: 'Cancel',
            icon: 'confirm'
        }
    ]
};

export const ActionUpdateCancel = {
    items: [
        {
            type: 'confirm',
            caption: 'Update',
            icon: 'confirm'
        },
        {
            type: 'cancel',
            caption: 'Cancel',
            icon: 'confirm'
        }
    ]
};

export const ActionCreateCancel = {
    items: [
        {
            type: 'confirm',
            caption: 'Create',
            icon: 'confirm'
        },
        {
            type: 'cancel',
            caption: 'Cancel',
            icon: 'confirm'
        }
    ]
};



export const ActionClose = {
    items: [
        {
            type: 'cancel',
            caption: 'Close',
            icon: 'confirm'
        }
    ]
};




export class ActionContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ActionContext.Provider value={
                {
                    state: this.state,
                    onActionClicked: (action) => this.props.onActionClicked.bind(this, action)
                }}>
                <div className={'action-container ' + this.props.className}>
                    {this.props.children}
                    {this.props.actions != null && this.props.actions.map(action => { return <ActionButton key={action.type} type={action.type} caption={action.caption} icon={action.icon} />; })}
                </div>
            </ActionContext.Provider>
        );
    }
}

ActionContainer.defaultProps = {
    className: '',
    actions: null
};

ActionContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    actions: PropTypes.array,
    onActionClicked: PropTypes.func
};


export class ActionButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ActionContext.Consumer>
                {
                    (context) => {
                        return <button className='action-button'
                            onClick={context.onActionClicked({ type: this.props.type })}>{this.props.caption}</button>;
                    }
                }
            </ActionContext.Consumer>
        );
    }
}

ActionButton.defaultProps = {
    caption: '',
    icon: ''
};

ActionButton.propTypes = {
    caption: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string.isRequired
};

export class CentrumApplicationAction extends React.Component {
    constructor(props) {
        super(props);
        this.actionRef = null;
        this.index = -1;
        this.selected = false;
        this.childrenItemsRef = null;
    }

    getIndex() {
        return this.index;
    }

    getElement() {
        return this.actionRef;
    }

    hasChildren() {
        return this.props.action.children != undefined && this.props.action.children != null && this.props.action.children.length > 0;
    }

    scrollIntoView() {
        var element = this.getElement();
        if (element) {
            if (this.props.parent != null) element = this.props.parent.getElement();
            element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }

    setIndex(index) {
        this.index = index;
    }

    renderChildren() {
        if (this.props.action == null) return;
        if (this.props.action.children == undefined) return;
        if (this.props.action.children.length == 0) return;
        setTimeout(() => this.calculateMenuPosition(), 100);
        return (
            <React.Fragment>
                <div className='menuIcon'><Icon icon={'angle-down'} /></div>
                <div className='action-children-items' ref={(r) => this.childrenItemsRef = r}>
                    {
                        this.props.action.children.map(action => {
                            return <CentrumApplicationAction
                                key={action.type}
                                caption={action.title}
                                icon={action.icon}
                                parent={this}
                                type={action.type} url={action.url}
                                action={action}
                            />;
                        })
                    }
                </div>
            </React.Fragment>
        );
    }

    calculateMenuPosition() {
        if (this.props.parent != null) {
            this.props.parent.calculateMenuPosition();
            return;
        }
        if (this.childrenItemsRef == null) return;
        var element = this.getElement();
        var x = element.getBoundingClientRect().x;
        this.childrenItemsRef.style.left = x + 'px';
        this.childrenItemsRef.style.width = element.offsetWidth + 'px';
    }

    render() {
        var iconObject = null;
        if (icons[this.props.icon]) {
            iconObject = <Icon icon={icons[this.props.icon]} className='app-icon' />;
        } else {
            iconObject = <Icon icon={this.props.icon} className='app-icon' />;
        }

        return (
            <ApplicationContext.Consumer>
                {
                    (context) => {
                        this.selected = context.isSelected(this);
                        var className = this.props.className;
                        if (this.selected) className += ' active';
                        if (!hasAccess(this.props.type)) return <React.Fragment />;
                        if (this.hasChildren()) className += ' action-menu';
                        return (
                            <div className={className}
                                onClick={() => { if (!this.hasChildren()) context.applicationActionClicked(this); }}
                                ref={(r) => this.actionRef = r}>
                                {iconObject}<span>{lang(this.props.caption !== null ? this.props.caption : this.props.type)}</span>
                                {this.renderChildren()}
                            </div>
                        );
                    }
                }
            </ApplicationContext.Consumer>
        );
    }
}

CentrumApplicationAction.defaultProps = {
    className: 'application-action',
    type: '',
    caption: null,
    icon: '',
    action: null,
    parent: null
};

CentrumApplicationAction.propTypes = {
    className: PropTypes.string,
    caption: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    action: PropTypes.object,
    parent: PropTypes.object
};

export class CentrumApplicationActions extends React.Component {
    constructor(props) {
        super(props);
        this.startX = 0;
        this.scrollLeft = 0;
        this.isDown = false;
        this.slider = null;
        this.actions = [];
        this.scrollLastPosition = 0;
    }

    componentDidMount() {
        if (this.applicationContext.getSelectedAction() == null) return;
        this.applicationContext.getSelectedAction().scrollIntoView();
    }

    renderActions() {
        if (this.props.actions === null) return;
        return this.props.actions.items.map(action => {
            return <CentrumApplicationAction
                key={action.type}
                caption={action.title}
                icon={action.icon}
                type={action.type} url={action.url}
                action={action}
            />;
        });
    }

    onMouseDown(e) {
        this.isDown = true;
        this.startX = e.pageX - this.slider.offsetLeft;
        this.scrollLeft = this.slider.scrollLeft;
        this.scrollStarted();
    }

    onMouseUp() {
        this.isDown = false;
        this.scrollFinished();
    }

    onMouseMove(e) {
        if (!this.isDown) return;
        e.preventDefault();

        const x = e.pageX - this.slider.offsetLeft;
        const walk = (x - this.startX) * 3; //scroll-fast
        this.slider.scrollLeft = this.scrollLeft - walk;

        if (this.scrollLastPosition != this.slider.scrollLeft) {
            this.calculateMenuPositions();
            this.scrollLastPosition = this.slider.scrollLeft;
        }

        this.scrollStarted();
    }

    onWheel(e) {
        this.slider.scrollLeft = this.slider.scrollLeft + e.deltaY;
        this.slider.classList.add('scrolling');
        setTimeout(() => {
            this.scrollFinished();
        }, 25);
    }

    scrollStarted() {
        this.slider.classList.add('scrolling');
    }

    scrollFinished() {
        this.slider.classList.remove('scrolling');
        this.calculateMenuPositions();
    }

    calculateMenuPositions() {
        this.applicationContext.getActionsWithChildren().map(action => {
            action.calculateMenuPosition();
        });
    }

    render() {
        return (
            <ApplicationContext.Consumer>
                {
                    (context) => {
                        this.applicationContext = context;
                        context.prepareActions(this.props.actions);
                        return (
                            <div
                                ref={(r) => this.slider = r}
                                onMouseDown={this.onMouseDown.bind(this)}
                                onMouseLeave={this.onMouseUp.bind(this)}
                                onMouseUp={this.onMouseUp.bind(this)}
                                onMouseMove={this.onMouseMove.bind(this)}
                                onWheel={this.onWheel.bind(this)}
                                className={this.props.className + ' ' + (context.isActionsHidden() ? '' : '')} >
                                {this.props.actions != null && this.renderActions()}
                                {this.props.actions == null && this.props.children}
                            </div>
                        );
                    }
                }
            </ApplicationContext.Consumer>
        );
    }
}

CentrumApplicationActions.defaultProps = {
    className: 'centrum-application-actions',
    children: null,
    actions: null
};

CentrumApplicationActions.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    actions: PropTypes.object
};


export class CentrumApplicationContent extends React.Component {
    constructor(props) {
        super(props);
        this.contentRef = null;
        this.index = -1;
        this.setTimeout = null;
        this.action = null;
    }

    componentWillUnmount() {
        if (this.setTimeout == null) return;
        clearTimeout(this.setTimeout);
        this.setTimeout = null;
    }

    setAction(action) {
        this.action = action;
    }

    getAction() {
        return this.action;
    }

    activateContent() {
        this.contentRef.classList.remove('activating');
    }

    render() {
        return (
            <ApplicationContext.Consumer>
                {
                    (context) => {
                        var selected = context.isApplicationContentSelected(this);
                        var isAvailable = context.isApplicationContentAvailable(this);
                        if (!isAvailable) return <React.Fragment />;
                        if (selected) {
                            this.setTimeout = setTimeout(this.activateContent.bind(this), 100);
                        }
                        var className = 'hidden';
                        if (selected) {
                            className = ' activating';
                        }
                        return (
                            <div className={'application-content centrum-flex vertical ' + this.props.className + className} ref={(r) => this.contentRef = r}>{this.props.children}</div>
                        );
                    }
                }
            </ApplicationContext.Consumer>
        );
    }
}

CentrumApplicationContent.defaultProps = {
    className: ''
};

CentrumApplicationContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};



export const CentrumApplicationContents = (props) => {
    const [selectedAction, selectAction] = useState(null);
    const [allActions, setAllActions] = useState(null);

    useEffect(() => {
        if (props.renderAll) return;
        var actions = [];
        props.actions.items.map(x => {
            if (x.children) {
                x.children.map(y => {
                    actions.push(y);
                });
            } else {
                actions.push(x);
            }
        });

        setAllActions(actions);
        var s = findAction(actions);
        selectAction(s);
    }, []);

    useEffect(() => {
        if (props.renderAll) return;
        if (!selectedAction) return;
        var s = findAction(allActions);
        if (s != selectedAction) {
            selectAction(s);
        }
    });

    const findAction = (actions)=>{
        var history = createBrowserHistory();
        let locations = history.location.pathname.split('/');
        let currentLocation = locations[locations.length - 1];
        let action = actions.find(x => x.type.toLocaleLowerCase() === currentLocation.toLocaleLowerCase());
        return action;
    };

    const renderChildren = () => {
        var index = 0;
        if (props.children.length === undefined) {
            return <CentrumApplicationContent>
                {props.children}
            </CentrumApplicationContent>;
        }

        if (props.renderAll) {
            return props.children.map(child => {
                index++;
                return (
                    <CentrumApplicationContent key={index}>
                        {child}
                    </CentrumApplicationContent>
                );
            });
        } else {
            if (!selectedAction) return;
            if (!allActions) return;

            return props.children.map((child, actionIndex) => {
                var currentAction = allActions[actionIndex];
                if (currentAction.type == selectedAction.type) return <CentrumApplicationContent key={actionIndex}>{child}</CentrumApplicationContent>;
                return <CentrumApplicationContent key={actionIndex}>

                </CentrumApplicationContent>;
            });
        }
    };

    return <div className='application-contents'>
        {renderChildren()}
    </div>;
};


CentrumApplicationContents.defaultProps = {
    renderAll: true
};

CentrumApplicationContents.propTypes = {
    actions: PropTypes.array,
    children: PropTypes.node,
    renderAll: PropTypes.bool
};


export class CentrumApplication extends React.Component {
    constructor(props) {
        super(props);
        this.selectedAction = null;
        this.actionIndex = 0;
        this.applicationContentIndex = 0;
        this.actions = [];
        this.availableActions = [];
        this.actionsWithChildren = [];
        this.components = [];

        this.actionFromRoute = this.getActiveActionFromRoute();
        if (this.actionFromRoute === '') this.actionFromRoute = null;

        this.state = Object.assign({}, props);
    }

    componentDidCatch(e) {
        console.warn('Error on application', e);
    }

    componentDidMount() {
        if (this.selectedAction === null) {
            // first look if the actionFromRoute has children action
            var action = this.getActionByType(this.getChildActionFromRoute());
            if (action != null) {
                this.selectedAction = action.props.type;
                this.navigate(this.selectedAction);

            } else {
                // nothing found, find the first accessible action
                this.availableActions.map(action => {
                    if (hasAccess(action.type) && this.selectedAction === null) {
                        this.selectedAction = action.props.type;
                        this.navigate(this.selectedAction);
                        return;
                    }
                });
            }
        }
        this.historyUnlisten = appHistory.listen(this.onHistoryChanged.bind(this));
    }

    componentWillUnmount() {
        this.historyUnlisten();
    }

    getActionByType(type) {
        if (type == null) return null;
        var action = this.availableActions.find(a => a.props.type.toLowerCase() === type.toLowerCase());
        if (action == undefined) return null;
        return action;
    }

    getActiveActionFromRoute() {
        let locations = appHistory.location.pathname.split('/');
        let indexOfApplicationType = locations.findIndex(x => x.toLowerCase() === this.props.type.toLowerCase());
        indexOfApplicationType++;

        if (locations[indexOfApplicationType] !== undefined) {
            return locations[indexOfApplicationType];
        }

        return null;
    }

    getChildActionFromRoute() {
        let locations = appHistory.location.pathname.split('/');
        let indexOfApplicationType = locations.findIndex(x => x.toLowerCase() === this.props.type.toLowerCase());
        indexOfApplicationType += 2;

        if (locations[indexOfApplicationType] !== undefined) {
            return locations[indexOfApplicationType];
        }

        return null;
    }

    onHistoryChanged() {
        var actionFromRoute = this.getActiveActionFromRoute();
        var action = this.getActionByType(actionFromRoute);
        if (action === null) {
            // try child
            action = this.getActionByType(this.getChildActionFromRoute());
            if (action == null) return;
            if (this.selectedAction != action) {
                this.navigate(action.props.type);
            }
            return;
        }

        if (this.selectedAction == null) {
            this.applicationActionClicked(action);
            return;
        }

        if (this.selectedAction.props.type.toLowerCase() !== actionFromRoute.toLowerCase()) {
            if (!action.hasChildren()) {
                this.applicationActionClicked(action);
                return;
            }
            if (this.selectedAction.props.parent != null) {
                action = this.getActionByType(this.getChildActionFromRoute());
                if (action == null) return;
                if (this.selectedAction.props.type.toLowerCase() === action.props.type.toLowerCase()) {
                    return;
                }
                return;
            }
        }
    }

    prepareActions(actions) {
        actions.items.map(action => {
            if (action.children != undefined) {
                action.children.map(actionChild => {
                    this.actions.push(actionChild);
                });
            } else {
                this.actions.push(action);
            }
        });
    }

    navigate(actionType) {
        var action = this.getActionByType(actionType);
        if (action != null) this.applicationActionClicked(action);
    }

    applicationActionClicked(action) {
        if (this.selectedAction == action) return;
        this.selectedAction = action;
        this.setState({ selected: action });

        action.scrollIntoView();

        const searchParams = window.location.search;


        if (action.props.parent != null) {
            appHistory.push('/' + this.props.type + '/' + action.props.parent.props.action.type + '/' + action.props.type + searchParams);
        } else {
            appHistory.push('/' + this.props.type + '/' + action.props.type + searchParams);
        }
    }

    isSelected(action) {
        if (action.getIndex() == -1) {
            if (action.props.action.children !== undefined) {
                this.actionsWithChildren.push(action);
                return (this.selectedAction != null && (this.selectedAction.props.parent != null && (this.selectedAction.props.parent.props.type === action.props.type)));
            } else {
                this.availableActions.push(action);
                action.setIndex(this.actionIndex);
                this.actionIndex++;
            }
        }

        if (this.selectedAction == null) {
            if (this.actionFromRoute == null) {
                this.selectedAction = action;
                return true;
            } else if (this.actionFromRoute.toLowerCase() === action.props.type.toLowerCase()) {
                this.selectedAction = action;
                return true;
            }
        } else {
            if (action.props.action.children !== undefined) {
                if (action.props.action.children.find(x => x.type == this.selectedAction.props.type) != undefined) {
                    return true;
                }
            }
        }

        if (this.selectedAction == action) {
            return true;
        }

        return false;
    }

    isApplicationContentAvailable(applicationContent) {
        var action = applicationContent.getAction();
        if (action == null) return false;
        if (!hasAccess(action.type)) {
            return false;
        }
        return true;
    }

    isApplicationContentSelected(applicationContent) {
        if (applicationContent.getAction() == null) {
            var action = this.actions[this.applicationContentIndex];
            applicationContent.setAction(action);
            this.applicationContentIndex++;
        }

        if (this.selectedAction == null) {
            return true;
        }

        var targetAction = applicationContent.getAction();
        if (!targetAction) return false;
        if (targetAction.type === this.selectedAction.props.type) {
            return true;
        }

        return false;
    }


    openModal(modalData) {
        application().modal.open(<Dialog title={modalData.title} actions={modalData.actions} data={modalData.data} onActionClicked={modalData.onActionClicked}>{modalData.content}</Dialog>);
    }

    closeModal() {
        application().modal.close();
    }

    toggleActionShow() {
        this.setState({ showActions: !this.state.showActions });
    }


    getComponentByName(name) {
        return this.components.find(x => x.getName() === name);
    }

    registerComponent(type, component) {
        this.components.push(component);
    }


    loadData(body, endpoint, resultHandler) {
        this.setState({ loading: true });
        postData(body, endpoint).then((res) => {
            this.setState({ loading: false });
            resultHandler(res);
        }).catch((err) => {
            var msg = err.message; // javascript errors
            if (msg == undefined) {
                msg = err.error.message;
            }
            this.openModal({
                title: 'Error',
                data: null,
                actions: ActionClose,
                content: <p>{msg}</p>,
                onActionClicked: () => { this.closeModal(); }
            });

            this.setState({ loading: false });
        });
    }

    edit(data, title, model, endpoint, successMessage, formStyle, resultCallback) {
        var dataCopy = Object.assign({}, data);
        var onConfirm = (action) => {
            this.closeModal();

            if (action.type === 'confirm') {
                this.openBusyModal(lang('Loading'), <p>{lang('Please wait')}</p>);

                postData(this.editingData, endpoint).then(() => {
                    data = Object.assign(data, this.editingData);
                    //this.openInformationModal(<p>{successMessage == undefined ? lang('Data updated') : successMessage}</p>);
                    application().modal.close();
                    application().snackbar.open(successMessage == undefined ? lang('Data updated') : successMessage);
                    if (resultCallback) {
                        resultCallback(this.editingData);
                    }
                }).catch((err) => {
                    data = dataCopy;
                    var msg = 'Unable to update data, please try later';
                    if (err.error !== undefined && err.error != null) {
                        msg = err.error.message;
                    }
                    this.closeModal();
                    this.openErrorModal(<p>{lang(msg)}</p>);
                });
            }
        };


        this.editingData = dataCopy;

        this.openModal({
            title: lang(title),
            data: data,
            actions: ActionUpdateCancel,
            content: <CentrumForm className={!formStyle ? 'vertical' : formStyle} data={data} model={model} buttons={[]} onFieldValueChanged={(field, value) => this.editingData[field.name] = value} />,
            onActionClicked: onConfirm.bind(this)
        });
    }

    openInformationModal(content) {
        this.openModal({
            title: 'Information',
            data: null,
            actions: ActionClose,
            content: content,
            type: 'success',
            onActionClicked: () => { this.closeModal(); }
        });
    }

    openErrorModal(content) {
        this.openModal({
            title: 'Error',
            data: null,
            type: 'error',
            actions: ActionClose,
            content: content,
            onActionClicked: () => { this.closeModal(); }
        });
    }

    openBusyModal(title, content) {
        this.closeModal();
        this.openModal({
            title: title,
            data: null,
            actions: { items: [] },
            content: content,
            onActionClicked: () => { }
        });
    }

    confirmActionAndPost(data, model, endPoint, handler, className, title, successMessage, readonly) {
        if (className === undefined) className = 'col2';
        if (title === undefined) title = 'Confirm?';
        if (successMessage === undefined) successMessage = 'Saved';
        if (readonly == undefined) readonly = true;


        var onActionConfirmed = (action) => {
            this.closeModal();

            if (action.type === 'confirm') {

                postData(data, endPoint).then((res) => {
                    application().snackbar.open(<p>{successMessage == undefined ? lang('Data updated') : successMessage}</p>);
                    if (handler != null && handler !== undefined) {
                        handler(res);
                    }
                }).catch((err) => {
                    var msg = 'Unable to update data, please try later';
                    if (err.error !== undefined && err.error != null) {
                        msg = err.error.message;
                    }
                    application().modal.open(<ErrorModal title={lang('Error')}><p>{lang(msg)}</p></ErrorModal>);
                });
            }
        };

        this.openModal({
            title: lang(title),
            data: data,
            actions: ActionYesNo,
            content: <CentrumForm className={className} data={data} model={model} readonly={readonly} buttons={[]} onFieldValueChanged={(field, value) => data[field.name] = value} />,
            onActionClicked: onActionConfirmed.bind(this)
        });
    }

    confirmActionAndPostTable(data, model, endPoint, handler, title, successMessage) {
        var onActionConfirmed = (action) => {
            this.closeModal();

            if (action.type === 'confirm') {
                this.openBusyModal(lang('Loading'), <p>{lang('Please wait')}</p>);

                postData(data, endPoint).then((res) => {
                    this.openInformationModal(<p>{lang(successMessage)}</p>);
                    if (handler != null && handler !== undefined) {
                        handler(res);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.openErrorModal(<p>{lang('Service is not available at the moment. Please try later.')}</p>);
                });
            }
        };



        this.openModal({
            title: lang(title),
            data: data,
            actions: ActionYesNo,
            content: <Table data={{ model: model, result: data }} model={model} selectable={false} showResultsSummary={false} showActions={false} showPagination={false} sortingEnabled={false} />,
            onActionClicked: onActionConfirmed.bind(this)
        });
    }


    showModalTable(data, model, title) {
        this.openModal({
            title: lang(title),
            data: data,
            actions: ActionClose,
            content: <Table data={{ model: model, result: data }} model={model} selectable={false} showResultsSummary={false} showActions={false} showPagination={false} sortingEnabled={false} />,
            onActionClicked: () => { this.closeModal(); }
        });
    }

    render() {
        var applicationContext = {
            state: this.state,
            edit: this.edit.bind(this),
            loadData: this.loadData.bind(this),
            confirmActionAndPost: this.confirmActionAndPost.bind(this),
            confirmActionAndPostTable: this.confirmActionAndPostTable.bind(this),
            showModalTable: this.showModalTable.bind(this),
            registerComponent: (type, component) => this.registerComponent(type, component),
            getComponentByName: (name) => { return this.getComponentByName(name); },
            navigate: (actionType) => this.navigate(actionType),
            applicationActionClicked: (action) => this.applicationActionClicked(action),
            getSelectedAction: () => { return this.selectedAction; },
            getActionsWithChildren: () => { return this.actionsWithChildren; },
            prepareActions: (actions) => this.prepareActions(actions),
            isSelected: (action) => this.isSelected(action),
            isApplicationContentAvailable: (applicationContent) => this.isApplicationContentAvailable(applicationContent),
            isApplicationContentSelected: (applicationContent) => this.isApplicationContentSelected(applicationContent),
            openModal: (modalData) => this.openModal(modalData),
            closeModal: () => this.closeModal(),
            isActionsHidden: () => { return !this.state.showActions; }
        };
        return (
            <ApplicationContext.Provider value={applicationContext}>
                <React.Fragment>
                    <div className={this.props.className}>
                        <div className='application-title' onClick={() => this.toggleActionShow()}>
                            <div className='application-icon'><Icon icon={icons[this.props.icon]} size='1x' /></div>
                            <h1>
                                {lang(this.props.caption)}
                            </h1>
                        </div>
                        {this.props.loading && <LoadingIndicator busy={this.props.loading} />}
                        {this.props.failed && this.props.error !== null &&
                            <ErrorIndicator busy={this.props.failed} title='Error' details={this.props.error !== null ? this.props.error.message : 'Service is not available, please try later.'} handleDismiss={() => this.props.onCloseError()} />}
                        {this.props.children}
                    </div>
                </React.Fragment>
            </ApplicationContext.Provider>
        );
    }
}


CentrumApplication.defaultProps = {
    className: 'application centrum-application',
    type: '',
    caption: '',
    icon: 'stickyNote',
    loading: false,
    loaded: false,
    failed: false,
    error: null,
    onCloseError: null
};

CentrumApplication.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    caption: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.string,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    failed: PropTypes.bool,
    error: PropTypes.object,
    onCloseError: PropTypes.func
};