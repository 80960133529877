import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = (props) => {
    var perc = (100 * props.value) / props.max;
    if (props.value > props.max) perc = 100;
    return <div className='progress-bar'>
        <div className='progress' style={{ width: perc > 100 ? 100 : perc + '%' }} />
    </div>;
};

ProgressBar.propTypes = {
    max: PropTypes.number,
    value: PropTypes.number
};

export const renderVolumeInformation = (max, value) => {
    if (max == 0) return;
    if (!max) return;
    var maxFormatted = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'USD' }).format(max);
    maxFormatted = maxFormatted.replace('US$', '');
    var valueFormatted = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'USD' }).format(value);
    valueFormatted = valueFormatted.replace('US$', '');
    return <div className='centrum-flex vertical'>
        <div className='group'><label>{valueFormatted}</label><span>/</span><label>{maxFormatted}</label></div>
        <ProgressBar max={max} value={value} />
    </div>;
};