import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { Languages, ProviderGameTypes } from './ModelEnums';


export const OperatorModels = {
    searchOperators: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Site',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true,
                max: 128
            }
        ]
    },
    operatorSearchResult: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'ApiKey',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'WebSite',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                max: 128
            }
        ]
    },
    newOperator: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
                hidden: true
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'WebSite',
                display: 'Web Site',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Prefix',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Description',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                max: 128
            }
        ]
    },
    operatorFeatures: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.number,
                hidden: true,
                readonly: true
            },
            {
                name: 'DefaultLanguage',
                display: 'Default Language',
                type: InputTypes.select,
                values: Languages
            },
            {
                name: 'Currency',
                display: 'Default Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'TimeZone',
                display: 'Time Zone'
            },
            {
                name: 'GamingApiEndpoint',
                display: 'Gaming Api Endpoint',
                layout: 'single-row',
                type: InputTypes.text,
            },
            {
                name: 'SMTPAdress',
                display: 'SMTP',
                layout: 'single-row',
                type: InputTypes.text,
            },
            {
                name: 'SupportEmail',
                display: 'Support Email',
                layout: 'single-row',
                type: InputTypes.text,
            },
            {
                name: 'FeedType',
                display: 'Feed Type',
                type: InputTypes.select,
                values: {
                    0: 'Sport Radar',
                    1: 'LSports'
                }
            },
        ]
    },
    editOperator: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly: true,
                identity: true,
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'WebSite',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'ApiKey',
                type: InputTypes.text,
                readonly: true,
                max: 128
            },
            {
                name: 'Description',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                max: 128
            }
        ]
    },
    'casinoConfiguration': {
        fields: [
            {
                name: 'gameType',
                display: 'Type',
                formatter: (val) => {
                    var res = ProviderGameTypes[parseInt(val)];
                    if (!res) {
                        return val;
                    }
                    return res;
                }
            },
            {
                name: 'liveDealer',
                display: 'Live',
                type: InputTypes.bool
            },
            {
                name: 'commission',
                display: '% of GGR'
            }
        ]
    },
    'paymentGatewayConfiguration': {
        fields: [
            {
                name: 'providerId',
                display: 'Id',
                hidden: true
            },
            {
                name: 'name',
                display: 'Name'
            },
            {
                name: 'configure',
                display: 'Configure',
                type: InputTypes.bool
            }
        ]
    },
    'operatorEnabledCountries': {
        fields: [
            {
                name: 'Name',
                display: 'Name'
            },
            {
                name: 'IsoCode',
                display: 'Iso'
            },
            {
                name: 'CountryCode',
                display: 'Code'
            }
        ]
    },
    'operatorEnabledCurrencies': {
        fields: [
            {
                name: 'Name',
                display: 'Name'
            },
            {
                name: 'CurrencyCode',
                display: 'Code'
            }
        ]
    }
};