/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { currency } from '../../../Centrum/Localization/lang';
import { application } from '../../../Application/Application';

import { Icon } from '../../../Controls/Icon/Icon';
import { addHours } from 'date-fns';
import { useSelector } from 'react-redux';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { InputTypes } from '../../../../v2/Components/Controls/Form/FormInput';
import { RiskTickets } from './Components/RiskTickets/RiskTickets';



const convertCurrency = (value, context) => {
    const rate = context.DefaultCurrency == context.currency ? 1 : context.Rates.find(x => x.CurrencyCode == context.currency && x.Base == context.DefaultCurrency);
    if (!rate || !rate.Buy) return currency(value, 2, context.DefaultCurrency);
    return currency(value / (rate.Buy), 2, context.currency);
};


export const betSlipResults = {
    fields: [
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Updated',
            title: 'Update',
            type: InputTypes.DateTime
        },
        {
            name: 'Operator',
            title: 'Operator'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'Group',
            title: 'Group'
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Username',
            title: 'Username'
        },
        {
            name: 'Type',
            title: 'Betting Type',
        },
        {
            name: 'TicketType',
            title: 'Ticket Type'
        },
        {
            name: 'Status',
            title: 'Status'
        },
        {
            name: 'Selections',
            title: 'Bet / Win'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Bet',
            title: 'Stake',
            type: InputTypes.Number
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number
        },
        {
            name: 'PossibleWin',
            title: 'PossibleWin',
            type: InputTypes.Number
        },
        {
            name: 'TicketBonusPercentage',
            title: 'Bonus %',
            type: InputTypes.Number
        },
        {
            name: 'TicketBonusAmount',
            title: 'Bonus Amount',
            type: InputTypes.Number
        },
        {
            name: 'Win',
            title: 'Win',
            type: InputTypes.Number
        },
        {
            name: 'Bonus',
            title: 'Bonus',
            type: InputTypes.Bool
        }
    ],
    onRenderRow: (rowObject) => {
        if (!rowObject.data) return rowObject;
        if (rowObject.data.Status == 2) {
            rowObject.className = 'row-lost';
        }
        if (rowObject.data.Status == 1) {
            rowObject.className = 'row-win';
        }
        if (rowObject.data.Status == 3) {
            rowObject.className = 'row-cashout';
        }

        return rowObject;
    }
};



export const openBets = (endpoint, request) => {
    const search = (r) => {
        return application().resolve(null, endpoint, r);
    };

    search(request).then((result) => {
        application().modal.open(<RiskTickets search={(r) => openBets(endpoint, r)} data={result} key={Date.now()} />);
    });
};

const copyDataToClipboard = (data) => {
    const el = document.createElement('textarea');
    el.value = data;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

const model = {
    fields: [
        {
            name: 'EventId',
            title: 'Id',
            renderRow: (row) => {
                return <div className='flex vertical'>
                    <div className='flex'>
                        {row.Live ? <Icon icon='bolt' size='xs' /> : null}<span onClick={() => copyDataToClipboard(row.EventId)}>{row.EventId}</span>
                    </div>
                    <span className='small'>{`%${row.VolumePercentage.toFixed(2)}`}</span>
                </div>;
            }
        },
        {
            name: 'EventDate',
            title: 'Kickoff',
            renderRow: (row) => {
                row.EventDate = row.EventDate.split('.')[0];
                if (row.EventDate.indexOf('Z') == -1) row.EventDate += 'Z';
                const date = new Date(row.EventDate).toLocaleString('en-GB', { hours12: false, hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' });
                return <span>{date}</span>;

            }
        },
        {
            name: 'EventName',
            title: 'Event',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/event' onClick={(e) => {
                    e.preventDefault();
                    openBets(e.target.href, {
                        fields: [
                            { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                            { name: 'EventId', and: true, exact: true, value: row.EventId },
                            { name: 'Status', and: true, exact: true, value: 0 }
                        ]
                    });
                }}>{row.EventName}</a>;
            }
        },
        {
            name: 'Selection',
            title: 'Selection',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/selection'
                    title={row.Id}
                    onClick={(e) => {
                        e.preventDefault();
                        openBets(e.target.href, {
                            fields: [
                                { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                                { name: 'SelectionId', and: true, exact: true, value: row.Id },
                                { name: 'Status', and: true, exact: true, value: 0 }
                            ]
                        });
                    }}>{row.Selection}</a>;
            }
        },
        {
            name: 'Rate',
            title: 'Rate',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/rate'
                    onClick={(e) => {
                        e.preventDefault();
                        openBets(e.target.href, {
                            fields: [
                                { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                                { name: 'SelectionId', and: true, exact: true, value: row.Id },
                                { name: 'Price', and: true, exact: true, value: row.Rate },
                                { name: 'Status', and: true, exact: true, value: 0 }
                            ]
                        });
                    }}>{row.Rate.toFixed(2)}</a>;
            }
        },
        {
            name: 'Market',
            title: 'Market',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/market'
                    title={row.Market}
                    onClick={(e) => {
                        e.preventDefault();
                        openBets(e.target.href, {
                            fields: [
                                { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                                { name: 'SelectionId', and: true, exact: true, value: row.Id },
                                { name: 'MarketId', and: true, exact: true, value: row.MarketId },
                                { name: 'Status', and: true, exact: true, value: 0 }
                            ]
                        });

                    }}>{row.Market}</a>;
            }
        },
        {
            name: 'League',
            title: 'League',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/league'
                    title={row.League}
                    onClick={(e) => {
                        e.preventDefault();
                        openBets(e.target.href, {
                            fields: [
                                { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                                { name: 'LeagueId', and: true, exact: true, value: row.LeagueId },
                                { name: 'Status', and: true, exact: true, value: 0 }
                            ]
                        });
                    }}>{row.League}</a>;
            }
        },
        {
            name: 'Sport',
            title: 'Sport',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/sport'
                    title={row.Sport}
                    onClick={(e) => {
                        e.preventDefault();
                        openBets(e.target.href, {
                            fields: [
                                { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                                { name: 'SportId', and: true, exact: true, value: row.SportId },
                                { name: 'Status', and: true, exact: true, value: 0 }
                            ]
                        });

                    }}>{row.Sport}</a>;
            }
        },
        {
            name: 'OpenBets',
            title: 'Open Bets',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/open' onClick={(e) => {
                    e.preventDefault();
                    openBets(e.target.href, {
                        fields: [
                            { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                            { name: 'EventId', and: true, exact: true, value: row.EventId },
                            { name: 'Live', and: true, exact: true, value: row.Live },
                            { name: 'MarketName', and: true, exact: true, value: row.Market },
                            { name: 'SelectionName', and: true, exact: true, value: row.Selection },
                            { name: 'Status', and: true, exact: true, value: 0 }
                        ]
                    });

                }}>{row.OpenBets}</a>;
            }
        },
        {
            name: 'OpenStakes',
            title: 'Stakes',
            renderRow: (row, field, context) => {
                return convertCurrency(row.OpenStakes, context, row);
            }
        },
        {
            name: 'OpenWin',
            title: 'Win',
            renderRow: (row, field, context) => {
                return convertCurrency(row.OpenWin, context, row);
            }
        },
        {
            name: 'NResult',
            title: 'NResult',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                //const stake = row.OpenStakes;
                var total = 0;
                const otherSelections = Object.values(context.TicketVolumes.Selections).filter(x => x.MarketId == row.MarketId);
                otherSelections.map(x => {
                    if (x.Id != row.Id) {
                        let other = context.TicketVolumes.SelectionVolumes[x.Id];
                        if (other) {
                            total += (other.OpenStakes);
                        }
                    }
                });
                /*
                if (row.EventName == 'Fulham FC vs. Coventry City') {
                    console.log(total);
                }
                */
                return convertCurrency(total + row.OpenWin, context, row);
            }
        },
        {
            name: 'SLLBets',
            title: 'S&LL Bets',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/open' onClick={(e) => {
                    e.preventDefault();
                    openBets(e.target.href, {
                        fields: [
                            { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                            { name: 'EventId', and: true, exact: true, value: row.EventId },
                            { name: 'Live', and: true, exact: true, value: row.Live },
                            { name: 'MarketName', and: true, exact: true, value: row.Market },
                            { name: 'SelectionName', and: true, exact: true, value: row.Selection },
                            { name: 'Status', and: true, exact: true, value: 0 },
                            { name: 'Unresolved', and: true, exact: true, value: 1 }
                        ]
                    });

                }}>{row.SLLBets}</a>;
            }
        },
        {
            name: 'SLLStakes',
            title: 'Stakes',
            renderRow: (row, field, context) => {
                return convertCurrency(row.SLLStakes, context, row);
            }
        },
        {
            name: 'SLLRStakes',
            title: 'R.Stakes',
            renderRow: (row, field, context) => {
                return convertCurrency(row.SLLRStakes, context, row);
            }
        },
        {
            name: 'SLLWin',
            title: 'Win',
            renderRow: (row, field, context) => {
                return convertCurrency(row.SLLWin, context, row);
            }
        },
        {
            name: 'RunOnBets',
            title: 'Run on Bets',
            // eslint-disable-next-line react/title-name
            renderRow: (row, field, context) => {
                return <a href='/api/risk/bets/open' onClick={(e) => {
                    e.preventDefault();
                    openBets(e.target.href, {
                        fields: [
                            { name: 'OperatorId', and: true, exact: true, value: context.operatorIds },
                            { name: 'EventId', and: true, exact: true, value: row.EventId },
                            { name: 'Live', and: true, exact: true, value: row.Live },
                            { name: 'MarketName', and: true, exact: true, value: row.Market },
                            { name: 'SelectionName', and: true, exact: true, value: row.Selection },
                            { name: 'Status', and: true, exact: true, value: 0 },
                            { name: 'RunOnBets', and: true, value: true }
                        ]
                    });

                }}>{row.RunOnBets}</a>;
            }
        },
        {
            name: 'RunOnBetsStakes',
            title: 'Stakes',
            renderRow: (row, field, context) => {
                return convertCurrency(row.RunOnBetsStakes, context, row);
            }
        },
        {
            name: 'RunOnBetsRStakes',
            title: 'R.Stakes',
            renderRow: (row, field, context) => {
                return convertCurrency(row.RunOnBetsRStakes, context, row);
            }
        },
        {
            name: 'RunOnBetsWin',
            title: 'Win',
            renderRow: (row, field, context) => {
                return convertCurrency(row.RunOnBetsWin, context, row);
            }
        },
        {
            name: 'StruckBets',
            title: 'Struck Bets'
        },
        {
            name: 'StruckStakes',
            title: 'Stakes',
            renderRow: (row, field, context) => {
                return convertCurrency(row.StruckStakes, context, row);
            }
        }
    ]
};

export const SelectionVolumes = (props) => {
    const volumes = useSelector((state) => state.volumes.volumes);
    const currency = useSelector((state) => state.volumes.currency);
    const rate = useSelector((state) => state.volumes.rate);
    const operators = useSelector((state) => state.volumes.operators);
    const operatorIds = useSelector((state) => state.volumes.operatorIds);

    const [selections, setSelections] = useState([]);
    const [sorting, setSorting] = useState(window.selectionSorting != null ? window.selectionSorting : { field: 'EventDate', direction: 0 });
    const [eventFilter, setEventFilters] = useState(props.eventFilter);

    useEffect(() => {
        prepareData();
    }, []);

    useEffect(() => {
        window.selectionSorting = Object.assign({}, sorting);
        prepareData();
    }, [sorting]);

    useEffect(() => {
        if (eventFilter != props.eventFilter) {
            setEventFilters(props.eventFilter);
            prepareData();
        }
    });

    const prepareData = () => {
        var data = [];
        Object.values(volumes.TicketVolumes.Selections).map(x => {
            try {
                var e = volumes.Events[x.EventId];
                if (!e) return;

                var ticketVolumes = volumes.TicketVolumes.SelectionVolumes[x.Id];
                if (!ticketVolumes) {
                    return;
                }


                // open
                var openBets = ticketVolumes.OpenBets;
                var openStakes = ticketVolumes.OpenStakes;
                var openWin = ticketVolumes.OpenWins;
                //


                var sllBets = ticketVolumes.SLLBets;
                var sllStakes = ticketVolumes.SLLStakes;
                var sllLiability = ticketVolumes.SLLWins;
                var sllRStakes = ticketVolumes.SLLRStakes;

                var runOnBets = ticketVolumes.RunOnBetsBets;
                var runOnBetsStakes = ticketVolumes.RunOnBetsStakes;
                var runOnBetsRStakes = ticketVolumes.RunOnBetsRStakes;


                var vPercentage = 0;
                if (e.MaxStakeLimit > 0) {
                    var perc = (e.Volumes.Stake.Prematch * 100) / e.MaxStakeLimit;
                    vPercentage = perc;
                }

                if (props.hideEmpty && openBets < 1) return;

                data.push({
                    Id: x.Id,
                    VolumePercentage: vPercentage,
                    EventId: x.EventId,
                    EventDate: e.EventDate,
                    EventName: e.Name,
                    Selection: x.Selection,
                    Rate: volumes.TicketVolumes.LastPrice[x.Id],
                    MarketId: x.MarketId,
                    Market: x.Market,
                    League: e.League,
                    LeagueId: e.LeagueId,
                    Sport: e.Sport,
                    SportId: e.SportId,
                    OpenBets: openBets,
                    OpenStakes: openStakes,
                    //OpenWin: openWin - openStakes,
                    NResult: ticketVolumes.NResult,
                    AllSelections: ticketVolumes.AllSelections?.length > 0 ? ticketVolumes.AllSelections : [],
                    OpenWin: openWin,
                    SLLBets: sllBets,
                    SLLStakes: sllStakes,
                    SLLRStakes: sllRStakes == 0 ? 0 : sllRStakes + sllStakes,
                    //SLLWin: sllLiability - sllStakes,
                    SLLWin: sllLiability,
                    Live: x.Live,
                    RunOnBets: runOnBets,
                    RunOnBetsStakes: runOnBetsStakes,
                    RunOnBetsRStakes: runOnBetsRStakes,
                    //RunOnBetsWin: ticketVolumes.RunOnBetsWin - runOnBetsStakes,
                    RunOnBetsWin: ticketVolumes.RunOnBetsWin,
                    StruckBets: ticketVolumes.StruckBets,
                    StruckStakes: ticketVolumes.StruckBetsStakes,
                    StruckWin: ticketVolumes.StruckBetsWin
                });
            } catch (err) {
                console.log(err);
            }
        });



        const sort = (a, b) => {
            if (sorting.field == 'EventDate') return new Date(a.EventDate) - new Date(b.EventDate);
            if (sorting.field == 'Rate') {
                return a.Rate - b.Rate;
            }
            return a[sorting.field] > b[sorting.field] ? 1 : -1;
            /*
            if (sorting.field == 'Volumes.Stake') return a.Volumes.Stake.Prematch + a.Volumes.Stake.Live - b.Volumes.Stake.Prematch + b.Volumes.Stake.Live;
            if (sorting.field == 'Slips') return a.Slips.Prematch - b.Slips.Prematch;
            if (sorting.field == 'Volumes.Liability') return a.Volumes.Liability.Prematch + a.Volumes.Liability.Live - b.Volumes.Liability.Prematch + b.Volumes.Liability.Live;
            if (sorting.field == 'MaxStakeLimit') return a.MaxStakeLimit / a.Volumes.Stake.Prematch - b.MaxStakeLimit / b.Volumes.Stake.Prematch;
            if (sorting.field == 'MaxLiabilityLimit') return a.MaxLiabilityLimit / a.Volumes.Liability.Prematch - b.MaxLiabilityLimit / b.Volumes.Liability.Prematch;
            */
        };

        const volumeTreshold = 80;
        const exceedVolume = data.find(x => x.VolumePercentage >= volumeTreshold);
        if (exceedVolume) data = data.sort((a, b) => a.VolumePercentage - b.VolumePercentage);
        data = data.sort((a, b) => {
            if (exceedVolume && a.VolumePercentage >= volumeTreshold) {
                return -1;
            }
            if (sorting.direction == 0) return sort(a, b);
            return sort(b, a);
        });


        if (props.onlyMyEvents) {
            data = data.filter(x => {
                if (props.myEvents.hasOwnProperty(x.EventId)) return x;
                return;
            });

            if (props.onlyMyEvents) {
                Object.values(props.myEvents).map(x => {
                    const eventId = x.eventId;
                    const found = volumes.Events.hasOwnProperty(eventId);
                    if (!found && new Date(x.scheduled) > addHours(new Date(), -3)) {
                        try {
                            data.push({
                                Id: x.eventId,
                                VolumePercentage: 0,
                                EventId: eventId,
                                EventDate: x.scheduled,
                                EventName: x.name,
                                Selection: '',
                                Rate: '',
                                MarketId: '',
                                Market: '',
                                League: x.league,
                                LeagueId: x.leagueId,
                                Sport: x.sport,
                                SportId: x.sportId,
                                OpenBets: '',
                                OpenStakes: '',
                                OpenWin: '',
                                SLLBets: '',
                                SLLStakes: '',
                                SLLRStakes: '',
                                SLLWin: '',
                                Live: '',
                                RunOnBets: '',
                                RunOnBetsStakes: '',
                                RunOnBetsRStakes: '',
                                RunOnBetsWin: '',
                                StruckBets: '',
                                StruckStakes: '',
                                StruckWin: ''
                            });
                        } catch (err) {
                            //
                        }
                    }
                });
            }

        }
        if (props.live) data = data.filter(x => x.Live);


        if (!props.onlyMyEvents) {
            data = data.filter(x => {
                if (props.eventFilter) {
                    const eventName = props.eventFilter.toLowerCase();
                    const eventId = parseFloat(eventName);
                    if (!isNaN(eventId)) {
                        if (x.EventId == eventId) return x;
                        return;
                    }
                    if (x.EventName.toLowerCase().indexOf(eventName) >= 0) {
                        return x;
                    } else {
                        return;
                    }
                }


                if (props.sport && props.sport != x.SportId) return;
                if (props.region && props.region != x.RegionId) return;
                if (props.league && props.league != x.LeagueId) return;

                return x;
            });
        }

        data.key = Date.now();
        setSelections(data);
    };


    if (!selections) return <React.Fragment />;
    return <div className='centrum-flex vertical'>
        <Table
            className='selection-volumes' model={model} key={selections.key} data={selections} context={{ ...volumes, rate: rate, currency: currency, operators: operators, operatorIds: operatorIds }}
            options={{
                pagination: {
                    rows: 50
                },
                sorting: {
                    enabled: true,
                    field: {
                        column: sorting.field,
                        direction: sorting.direction
                    },
                    onSortingChange: (sorting) => {
                        setSorting({ field: sorting.column, direction: sorting.direction });
                    }
                }
            }}
            onRenderRow={(row, elements, className, context) => {
                const e = context.Events[row.EventId];
                if (!e) return <tr className={className}>
                    {elements}
                </tr>;

                if (e.MaxStakeLimit > 0) {
                    var perc = (e.Volumes.Stake.Prematch * 100) / e.MaxStakeLimit;
                    if (perc > 80) {
                        className = 'warning';
                    }
                }
                return <tr className={className}>
                    {elements}
                </tr>;
            }}
            onDoubleClick={(row) => {
                const event = volumes.Events[row.EventId];
                props.focusEvent(event);
            }}
        />
    </div>;
};


SelectionVolumes.propTypes = {
    live: PropTypes.bool,
    onlyMyEvents: PropTypes.bool,
    hideEmpty: PropTypes.bool,
    myEvents: PropTypes.object,
    eventFilter: PropTypes.string,
    sport: PropTypes.number,
    region: PropTypes.number,
    league: PropTypes.number,
    onEventSelected: PropTypes.func,
    focusEvent: PropTypes.func
};