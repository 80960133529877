// IMPORT PACKAGE REFERENCES

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';



import { getActions, ActionTypes } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { API, AuthenticationEndpoints } from '../../../v2/Lib/Api/Api';
import { QR } from '../../QR/QR';
import { lang } from '../../../v2/Lib/Common/language';
import { apiResult, application, Endpoints } from '../../Application/Application';
import { Search } from '../../Centrum/Search/Search';
import { Card } from '../../../v2/Components/Card/Card';



const applicationActionType = ActionTypes.MyProfile;
const applicationActions = getActions(applicationActionType);


export const MyProfileButtons = [
    {
        type: ActionTypes.ChangeMyPassword,
        name: 'ChangePassword',
        caption: 'Change my password'
    }
];


const EnableDisable2FA = (props) => {
    const dispatch = useDispatch();
    const [code, setCode] = useState(0);

    return <ConfirmationModal title={props.enable ? 'Enable 2FA' : 'Disable 2FA'} onConfirm={() => {
        API.post(`${AuthenticationEndpoints.Validate2FA}/${code}/${props.enable ? 'enable' : 'disable'}`, null).then((result) => {
            if (result.result) {
                application().snackbar.open(props.enable ? '2FA Enabled' : '2FA Disabled');
                dispatch({ type: props.enable ? 'ENABLE_2FA' : 'DISABLE_2FA', payload: null });
            }
        });
    }}>
        <div className='flex gap-20'>
            <div className='flex vertical gap-5'>
                <p>{lang('Please enter the validator code from your Google Authenticator Application')}</p>
                <div className='flex gap-5'>
                    <label>{lang('Enter the code')}</label>
                    <input type='number' onChange={(e) => setCode(e.target.value)} />
                </div>
            </div>
            {props.enable && <QR text={props.qr} width='200px' height='200px' />}
        </div>
    </ConfirmationModal>;
};

EnableDisable2FA.propTypes = {
    enable: PropTypes.bool,
    qr: PropTypes.string
};

// COMPONENT
export const MyProfilePage = () => {
    const myProfile = useSelector((state) => state.user).loginInformation ?? {};
    const activity = useSelector((state) => state.data.activity) ?? [];


    const changeMyPassword = () => {
        var p = null;
        application().modal.open(<ConfirmationModal tile='Change Password' onConfirm={() => {
            API.post(AuthenticationEndpoints.ChangePassword, {
                password: p,
                passwordConfirm: p
            }).then(() => {
                application().snackbar.open('Password changed');
            })

        }}>
            <div className='padding flex vertical gap-5'>
                <p>{lang('Please enter a valid password')}</p>
                <div className='flex gap-5'>
                    <label>Password</label>
                    <input type='text' onChangeCapture={(e) => p = e.target.value} />
                </div>
            </div>
        </ConfirmationModal>)
    };

    const enable2FA = () => {
        API.post(AuthenticationEndpoints.Enable2FA, {}).then((result) => {
            application().modal.open(<EnableDisable2FA qr={result.result} enable />);
        });
    };

    const disable2FA = () => {
        application().modal.open(<EnableDisable2FA disable />);
    };

    return <CentrumApplication
        caption='My Profile' icon='idCard'
        type={ActionTypes.MyProfile}
    >
        <ApplicationContext.Consumer>
            {
                () => {
                    return <React.Fragment>
                        <CentrumApplicationActions actions={applicationActions} />
                        <CentrumApplicationContents>
                            <Card title='My Profile'>
                                <div className='flex vertical gap-10 padding'>
                                    <div className='flex gap-5'>
                                        <label>Email</label><span>{myProfile.Email}</span>
                                    </div>
                                    <div className='flex gap-5'>
                                        <label>Username</label><span>{myProfile.Username}</span>
                                    </div>
                                    <div className='flex gap-5'>
                                        <label>Name</label><span>{myProfile.Name}</span>
                                    </div>
                                    <div className='flex gap-5'>
                                        <label>Surname</label><span>{myProfile.Surname}</span>
                                    </div>
                                    <div className='flex gap-5'>
                                        <label>Role</label><span>{myProfile.UserGroupName}</span>
                                    </div>
                                    <div className='flex gap-5'>
                                        <label>Ip</label><span>{myProfile.LastIp}</span>
                                    </div>
                                </div>
                                <div className='flex gap-10 padding'>
                                    <Button title='Change Password' onClick={() => changeMyPassword()} />
                                    {
                                        myProfile.AuthType == 0 && <Button title='Enable 2FA' onClick={() => {
                                            enable2FA();
                                        }} />
                                    }
                                    {
                                        myProfile.AuthType == 1 && <Button title='Disable 2FA' onClick={() => {
                                            disable2FA();
                                        }} />
                                    }
                                </div>
                            </Card>
                            <Card title='My Activity'>
                                <Search
                                    model='searchMyActivity'
                                    resultModel='operatorUserActivityInformation'
                                    data={activity}
                                    onSubmit={(data, qb) => { return apiResult('activity', Endpoints.OperatorUsersActivity, qb, 'fetching reports', 'unable to fetch report'); }}
                                />
                            </Card>
                        </CentrumApplicationContents>
                    </React.Fragment>;
                }
            }
        </ApplicationContext.Consumer>
    </CentrumApplication>;
};
