import './_breadCrumbs.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../Centrum/Localization/lang';
import { Icon } from '../Icon/Icon';


export class BreadCrumbs extends React.Component {
    constructor(props) {
        super(props);
        this.breadCrumbs = [props.root];
        this.state = Object.assign({}, props);
    }

    select(item) {
        this.breadCrumbs.splice(this.breadCrumbs.findIndex(x => x == item));
        if (this.breadCrumbs.length == 0) this.breadCrumbs.push(this.props.root);
        if (this.props.onClick) this.props.onClick(item);
        this.setState({ breadCrumbs: this.breadCrumbs });
    }

    renderBreadCrumbs() {
        return <React.Fragment>
            {
                this.breadCrumbs.map((x, index) => {
                    return <React.Fragment key={index}>{index > 0 && <Icon icon={'angle-right'} />}<a onClick={() => { index < this.breadCrumbs.length - 1 && this.select(x); }}>{lang(x)}</a></React.Fragment>;
                })
            }
        </React.Fragment>;
    }

    push(item) {
        this.breadCrumbs.push(item);
        this.setState({ breadCrumbs: this.breadCrumbs });
    }

    pop(item) {
        this.breadCrumbs = this.breadCrumbs.filter(x => x !== item);
        this.setState({ breadCrumbs: this.breadCrumbs });
    }

    render() {
        return <div className='breadcrumbs' key={this.state.breadCrumbs.length}>{this.renderBreadCrumbs()}</div>;
    }
}

BreadCrumbs.defaultProps = {
    root: '',
    breadCrumbs: [],
    onClick: () => { }
};

BreadCrumbs.propTypes = {
    root: PropTypes.string,
    breadCrumbs: PropTypes.array,
    onClick: PropTypes.func
};