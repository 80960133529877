import './_table.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { models } from '../../Centrum/Shared';
import { lang } from '../../Centrum/Localization/lang';
import { CheckBox } from '../Inputs/CheckBox/CheckBox';
import { Switch } from '../Inputs/Switch/Switch';
import { Icon } from '../Icon/Icon';


export class Table extends Component {
    constructor(props) {
        super(props);
        var state = Object.assign({}, props);
        const fields = props.model ? this.buildModel(props.model) : (props.fields.length > 0 ? props.fields : this.buildModel());


        if (props.inlineEdit) {
            this.inlineFields = {};
        }

        if (state.selectable) {
            var selectableField = null;
            fields.map(x => {
                if (x.selectable) {
                    selectableField = x;
                    x.hidden = true;
                }
            });

            state.selectableField = selectableField;
        }

        state.fields = fields;
        state.inlineEditingRow = -1;
        this.state = state;

        this.keyUpHandler = this.handleKeyUp.bind(this);
    }

    handleKeyUp(event) {
        if (event.key == 'Escape') {
            if (this.state.inlineEditingRow !== -1) {
                this.setState({ inlineEditingRow: -1 });
            }
            return;
        }
    }
    componentDidMount() {
        window.addEventListener('keyup', this.keyUpHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.keyUpHandler);
    }


    componentDidUpdate() {
        if (this.inputElement) {
            this.inputElement.focus();
        }
    }

    changeSort(row, index) {
        var data = this.state.data;
        var foundIndex = data.findIndex(x => x == row);
        if (foundIndex >= 0) {
            if (foundIndex == 0 && index == -1) {
                return;
            }

            if (foundIndex == data.length - 1) {
                return;
            }
            [data[foundIndex], data[foundIndex + index]] = [data[foundIndex + index], data[foundIndex]];
            this.setState({ data: data });
        }
    }

    buildModel(modelName) {
        if (!modelName) {
            if (!this.props.data) return [];
            if (this.props.data.length == 0) return [];
            var row = this.props.data[0];
            if (!row) return [];
            var model = [];
            Object.keys(row).map(x => {
                model.push({
                    name: x,
                    display: x
                });
            });

            return model;
        }
        if (typeof (modelName) === 'object') {
            return modelName.fields;
        }
        return models.get(modelName).fields;
    }


    isSelected(index) {
        if (this.state.selectable && this.state.selectableField) {
            return this.state.data[index][this.state.selectableField];
        }
        return this.state.data[index] ? this.state.data[index].__selected : null;
    }

    toggleAll(value) {
        if (!value) {
            this.state.data.map(x => {
                if (this.state.selectableField && this.state.selectableField) {
                    x[this.state.selectableField] = false;
                } else {
                    x.__selected = false;
                }
            });
        } else {
            this.state.data.map(x => {
                if (this.state.selectableField && this.state.selectableField) {
                    x[this.state.selectableField] = true;
                } else {
                    x.__selected = true;
                }
            });
        }


        this.setState({ data: this.state.data }, () => {
            this.props.onSelectionChange(null, -1, value);
        });
    }

    toggleCheck(row, index, value) {
        this.props.onSelectionChange(row, index, value);
    }


    /* events */
    onEditField(field, row) {
        this.editingFieldValue = row[field.name];
        this.setState({ editingRow: row, editingField: field });
    }

    onCancelEdit() {
        this.editingFieldValue = '';
        this.inputElement = null;
        this.setState({ editingRow: null, editingField: null });
    }

    onEditingFieldValueChanged(e) {
        var value = e.target.value;
        if (this.state.editingField.type == InputTypes.number) {
            if (value == '') {
                this.editingFieldValue = null;
                return;
            }
            value = parseFloat(e.target.value);
            if (isNaN(value)) {
                e.preventDefault();
                return;
            }
        }
        this.editingFieldValue = value;
    }

    onSaveField(field, row) {
        row[field.name] = this.editingFieldValue;
        this.inputElement = null;
        this.props.onDataChange(field, row, this.state.data);
        this.setState({ editingRow: null, editingField: null });
    }


    saveInlineEditing(inlineForm) {
        var data = this.state.data;
        if (this.state.inlineEditingRow == -1) {
            data.push(inlineForm);
        } else {
            var line = data[this.state.inlineEditingRow];
            this.state.fields.map(field => {
                line[field.name] = inlineForm[field.name];
            });
        }
        this.props.onDataChange(null, inlineForm, data);
        this.setState({ data: data, inlineEditingRow: -1 });

    }

    /**/

    renderField(field, row) {
        var val = row[field.name];
        switch (field.type) {
            case InputTypes.date:
            case InputTypes.dateTime:
                if (!val) return;
                if (val.indexOf('Z') == -1) {
                    val += 'Z';
                }
                return new Date(val).toLocaleString('en-Gb', { hour12: false });
            case InputTypes.bool:
                return <Switch
                    checked={row[field.name]}
                    enabled={this.props.editable && this.props.editableFields && this.props.editableFields.indexOf(field.name) >= 0}
                    onChange={(value) => {
                        this.editingFieldValue = value;
                        this.onSaveField(field, row);
                    }}
                />;
            default:
                return row[field.name];
        }
    }

    renderHead() {

        const renderSortable = (field) => {
            if (!this.props.sortable) return;
            return <div className='sorting vertical' key={field.name + this.state.sortingField}>
                <Icon icon='angle-up' size='xs' className={(this.state.sortingField == field.name && this.state.sortingDirection == 1) ? '' : 'passive'} onClick={() => {
                    this.setState({ sortingField: field.name, sortingDirection: 1 }, () => {
                        this.props.onSortingChanged(field.name, 1);
                    });
                }} />
                <Icon icon='angle-down' size='xs' className={(this.state.sortingField == field.name && this.state.sortingDirection == 0) ? '' : 'passive'} onClick={() => {
                    this.setState({ sortingField: field.name, sortingDirection: 0 }, () => {
                        this.props.onSortingChanged(field.name, 0);
                    });

                }} />
            </div>;
        };
        var header = this.state.fields.map(field => {
            var classNames = [];
            if (field.hidden) classNames.push('hidden');
            return <th key={field.name} className={classNames.join(' ')}><div className='flex gap-5'>{renderSortable(field)}{lang(field.display)}</div></th>;
        });


        if (this.props.buttons) {
            header.push(<th></th>);
        }

        if (this.props.draggableOrder) {
            header = [<th key='order' className='draggable'></th>].concat(header);
        }

        if (this.props.selectable) {
            var selected = this.state.data.filter(x => x.__selected).length == this.state.data.length;
            return <React.Fragment>
                <th>{this.props.multipleSelect && <CheckBox key={selected} onClick={this.toggleAll.bind(this)} checked={selected} />}</th>
                {header}
            </React.Fragment>;
        }


        if (this.state.inlineEdit) {
            return <React.Fragment>
                <th className='inlineEdit'></th>
                {header}
                <th className='inlineEdit'></th>
            </React.Fragment>;
        }

        if (!this.state.inlineEdit && this.state.deleteEnabled) {
            return <React.Fragment>
                {header}
                <th className='inlineEdit'></th>
            </React.Fragment>;
        }

        return header;
    }

    renderButtons(row) {
        return <td className=''><div className='flex align-right'>{this.props.buttons(row)}</div></td>;
    }

    renderBody() {
        return this.props.data.map((row, index) => {
            if (this.state.inlineEditingRow == index) {
                return this.renderInlineEdit();
            }
            var rowStyle = {
                className: ''
            };

            if (this.props.onRenderRow) {
                rowStyle = this.props.onRenderRow(row);
            }

            if (this.props.model instanceof Object) {
                if (this.props.model.onRenderRow && !this.props.onRenderRow) {
                    rowStyle = this.props.model.onRenderRow({ data: row, className: '' });
                }
            }

            var selected = this.isSelected(index);
            var element = <tr key={index} className={rowStyle.className}>
                {this.props.draggableOrder && <td className='draggable'>
                    <div className='flex'>
                        <Icon icon='angle-up' onClick={() => { this.changeSort(row, -1); }} />
                        <Icon icon='angle-down' className='marginLeft' onClick={() => { this.changeSort(row, 1); }} />
                    </div>
                </td>}

                {this.props.selectable && <td><CheckBox key={selected} checked={selected} onClick={(value) => this.toggleCheck(row, index, value)} /></td>}
                {this.props.inlineEdit && <td className='inlineEdit'><Icon icon={'edit'} className='icon-inline-edit' onClick={() => this.setState({ inlineEditingRow: index })} /></td>}

                {this.state.fields.map(field => {
                    var onClickHandler = null;
                    var editing = false;
                    var editable = false;
                    if (this.props.editable && this.props.editableFields != null && this.props.editableFields.indexOf(field.name) >= 0) {
                        onClickHandler = this.onEditField.bind(this, field, row);
                        editable = true;
                    }

                    if (editable && row == this.state.editingRow && field == this.state.editingField) {
                        editing = true;
                    }

                    var classNames = [];
                    if (field.className) classNames.push(field.className(field, row));
                    if (editable) classNames.push('editable');
                    if (editing) classNames.push('editing');
                    if (field.hidden) classNames.push('hidden');

                    if (editable) {
                        return <td key={field.name} className={classNames.join(' ')}>
                            <div className='flex'>
                                {editing && <input type={field.type} defaultValue={row[field.name]}
                                    onKeyUp={(e) => {
                                        if (e.key == 13) {
                                            e.preventDefault();
                                            this.onSaveField(field, row);
                                        }
                                    }}
                                    onChangeCapture={this.onEditingFieldValueChanged.bind(this)} ref={(r) => this.inputElement = r} />}
                                {!editing && (field.formatter ? field.formatter(row[field.name], row, this.props.data, this.props.context) : this.renderField(field, row))}
                                {editable && !editing && field.type != InputTypes.bool && <Icon icon={'edit'} className='icon-inline-edit alignRight' onClick={onClickHandler} />}

                                {editable && editing &&
                                    <div className='flex align-right'>
                                        <Icon icon={'check'} className='icon-inline-edit-complete alignRight' onClick={() => { this.onSaveField(field, row); }} />
                                        <Icon icon={'times'} className='icon-inline-edit-cancel' onClick={() => this.onCancelEdit()} />
                                    </div>
                                }
                            </div>
                        </td>;
                    } else {
                        return <td key={field.name} className={classNames.join(' ')}>
                            {editing && <input type={field.type} defaultValue={row[field.name]}
                                onKeyUp={(e) => {
                                    if (e.key == 13) {
                                        e.preventDefault();
                                        this.onSaveField(field, row);
                                    }
                                }}
                                onChangeCapture={this.onEditingFieldValueChanged.bind(this)} ref={(r) => this.inputElement = r} />}
                            {!editing && (field.formatter ? field.formatter(row[field.name], row, this.props.data, this.props.context) : this.renderField(field, row))}
                            {editable && !editing && field.type != InputTypes.bool && <Icon icon={'edit'} className='icon-inline-edit' onClick={onClickHandler} />}

                            {editable && editing &&
                                <React.Fragment>
                                    <Icon icon={'check'} className='icon-inline-edit-complete' onClick={() => { this.onSaveField(field, row); }} />
                                    <Icon icon={'times'} className='icon-inline-edit-cancel' onClick={() => this.onCancelEdit()} />
                                </React.Fragment>
                            }
                        </td>;
                    }
                })}

                {
                    this.state.inlineEdit && <td className='inlineEdit'>
                        {this.props.deleteEnabled && <Icon icon={'times'} className='icon-inline-edit-cancel' onClick={(row) => {
                            var data = this.state.data;
                            data.splice(data.findIndex(x => x == row), 1);
                            this.setState({ data: data });
                        }} />
                        }
                    </td>
                }
                {
                    !this.state.inlineEdit && this.props.deleteEnabled && <td className='' >
                        <Icon icon={'times'} className='icon-inline-edit-cancel' onClick={(row) => {
                            var data = this.state.data;
                            data.splice(data.findIndex(x => x == row), 1);
                            this.setState({ data: data }, () => {
                                this.props.onDataChange(null, row, this.state.data);
                            });
                        }} />
                    </td>
                }
                {this.props.buttons && this.renderButtons(row)}
            </tr>;
            return element;
        });
    }

    renderInlineEdit() {
        var inlineForm = {};
        if (this.state.inlineEditingRow >= 0) {
            inlineForm = this.state.data[this.state.inlineEditingRow];
        } else {
            this.state.fields.map(field => {
                inlineForm[field.name] = '';
            });
        }

        var row = this.state.data[this.state.inlineEditingRow];
        return <tr key={this.state.data.length}>
            <td className='inlineEdit'>
                {
                    this.state.inlineEditingRow >= 0 ?
                        <Icon icon={'times'} className='icon-inline-edit' onClick={() => this.setState({ inlineEditingRow: -1 })} /> :
                        <Icon icon={'angle-right'} className='icon-inline-edit disabled' />
                }
            </td>
            {
                this.state.fields.map(field => {
                    return <td key={field.name} className={'editable editing'}>
                        {
                            ((field.type && field.type.toLowerCase() == 'text') || (field.type && field.type.toLowerCase() == 'number')) && !field.readOnly &&
                            <input key={field.name} type={field.type}
                                ref={(r) => this.inlineFields[field.name] = r}
                                defaultValue={inlineForm ? inlineForm[field.name] : 0}
                                onChange={() => {
                                    var input = this.inlineFields[field.name];
                                    var value = input.value;
                                    if (field.type == InputTypes.number) {
                                        value = parseFloat(value);
                                    }

                                    inlineForm[field.name] = value;
                                }}
                                onKeyUp={(e) => {
                                    if (e.key == 'Enter' && e.ctrlKey) {
                                        this.saveInlineEditing(inlineForm);
                                        return;
                                    }
                                    if (e.key == 'Enter') {
                                        e.preventDefault();
                                        var input = this.inlineFields[field.name];
                                        var value = input.value;
                                        if (field.type == InputTypes.number) {
                                            value = parseFloat(value);
                                        }

                                        inlineForm[field.name] = value;
                                        var elements = Object.assign([], e.target.parentElement.parentElement.getElementsByTagName('input'));
                                        var index = elements.findIndex(x => x == e.target);
                                        index++;
                                        if (index < elements.length) {
                                            elements[index].focus();
                                        } else {
                                            //   
                                            this.saveInlineEditing(inlineForm);
                                        }

                                    }
                                }} />
                        }
                        {
                            field.readOnly &&
                            <span>{field.formatter ? field.formatter(row[field.name]) : row[field.name]}</span>
                        }
                        {
                            field.type && field.type.toLowerCase() == 'select' &&
                            <select key={field.name} type={field.type}
                                ref={(r) => this.inlineFields[field.name] = r}
                                value={inlineForm ? inlineForm[field.name] : null}
                                className={field.readOnly ? 'read-only' : ''}
                                readOnly={field.readOnly}
                                disabled={field.readOnly}
                                onChange={() => {
                                    var input = this.inlineFields[field.name];
                                    inlineForm[field.name] = input.value;
                                }}
                                onKeyUp={(e) => {
                                    if (e.key == 'Enter') {
                                        e.preventDefault();
                                        var input = this.inlineFields[field.name];
                                        inlineForm[field.name] = input.value;
                                    }
                                }} >
                                {
                                    field.values && field.values.map(x => {
                                        return <option key={x.value} value={x.value}>{x.display}</option>;
                                    })
                                }
                            </select>
                        }
                        {
                            field.type.toLowerCase() == 'bool' &&
                            <Switch
                                checked={inlineForm ? inlineForm[field.name] : false}
                                onChange={(value) => {
                                    inlineForm[field.name] = value;
                                }}
                            />
                        }
                    </td>;
                })
            }

            <td><div className='marginRight'>
                <Icon icon={'check'} className='icon-inline-edit-complete' onClick={() => {
                    this.saveInlineEditing(inlineForm);
                }} />
            </div>
            </td>
            {this.props.buttons && <td></td>}
        </tr>;
    }



    render() {
        var classNames = [];
        if (this.props.className) classNames.push(this.props.className);
        if (this.props.selectable) classNames.push('selectable');
        if (this.props.editable) classNames.push('editable');
        return (
            <React.Fragment>
                <table cellPadding='0' cellSpacing='0' className={'centrum-table ' + classNames.join(' ')}>
                    <thead>
                        <tr>
                            {this.renderHead()}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderBody()}
                        {this.state.inlineEditingRow == -1 && this.props.inlineNewRow && this.renderInlineEdit()}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}


Table.defaultProps = {
    className: null,
    model: null,
    fields: [],
    selectable: false,
    multipleSelect: true,

    draggableOrder: false,
    editable: false,
    editableFields: null,

    inlineEdit: false,
    inlineNewRow: false,
    deleteEnabled: false,

    data: [],
    buttons: null,

    onRenderRow: null,
    onSelectionChange: () => { },
    onDataChange: () => { },
    onSortingChanged: () => { }
};



Table.propTypes = {
    className: PropTypes.string,
    model: PropTypes.any,
    fields: PropTypes.array,
    data: PropTypes.array,

    context: PropTypes.object,
    buttons: PropTypes.func,

    selectable: PropTypes.bool,

    sortable: PropTypes.bool,
    sortingField: PropTypes.text,
    sortingDirection: PropTypes.number,

    draggableOrder: PropTypes.bool,
    editable: PropTypes.bool,
    multipleSelect: PropTypes.bool,
    editableFields: PropTypes.string,

    inlineEdit: PropTypes.bool,
    inlineNewRow: PropTypes.bool,
    deleteEnabled: PropTypes.bool,

    onRenderRow: PropTypes.func,

    onSortingChanged: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onDataChange: PropTypes.func
};