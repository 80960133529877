

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { Page } from '../../../Centrum/Page/Page';
import { Tab } from '../../../Centrum/Tab/Tab';
import { SportsLimits } from './ApplicationLimits/SportsLimits';
import { CasinoLimits } from './ApplicationLimits/CasinoLimits';
import { Panel } from '../../../Centrum/Card/Card';
import { CasinoProviderGameTypeLimits } from './ApplicationLimits/CasinoProviderGameTypeLimits';
import { CasinoGameLimits } from './ApplicationLimits/CasinoGameLimits';



// COMPONENT
class ApplicationLimits extends Page {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, props);
    }


    render() {
        let tabButtons = [
            {
                name: 'sport',
                title: 'Sports'
            },
            {
                name: 'casino',
                title: 'Casino'
            },
            {
                name: 'gameTypes',
                title: 'Game Types'
            },
            {
                name: 'games',
                title: 'Games'
            }
        ];

        return <React.Fragment>
            <Panel title='General Limits' className='marginBottom' />
            <Tab buttons={tabButtons}>
                <React.Fragment key='sports'>
                    <SportsLimits displayRegions={false} />
                </React.Fragment>
                <React.Fragment key='casino'>
                    <CasinoLimits />
                </React.Fragment>
                <React.Fragment key='gameTypes'>
                    <CasinoProviderGameTypeLimits />
                </React.Fragment>
                <React.Fragment key='games'>
                    <CasinoGameLimits />
                </React.Fragment>
            </Tab>
        </React.Fragment>;
    }
}

ApplicationLimits.defaultProps = {
};

ApplicationLimits.propTypes = {
    limits: PropTypes.object
};


const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ApplicationLimits);
export { hoc as ApplicationLimits };