import React from 'react';

import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { ProviderGameTypes, SportsBetTypes, SportsTicketTypes, TestAccountFilter } from './ModelEnums';
import { gotoProfile } from './Transactions';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';
import { convertTZ, getTime } from '../helpers';
import { currency } from '../../../v2/Lib/Common/currency';

export const currencyAndFooter = (val, row, isFooter, isExporting, displayCurrencySymbol = false) => {
    if (!val) return;
    if (isFooter) {
        if (Object.keys(val).length > 0) {
            var results = [];
            results = Object.keys(val).map(key => {
                if (isExporting) {
                    if (isExporting) return val[key] + key;
                }
                return <p key={key}>{currency(val[key], key, 2, true)}</p>;
            });
            return results;
        } else {
            if (isExporting) return val;
            return val.toString();
        }
    } else {
        if (isExporting) return val;
        return currency(val, row.Currency, 2, displayCurrencySymbol);
    }
};


export const ReportsModel = {
    casinoReport: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EventTime',
                display: 'Event Time',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }

        ]
    },
    casinoReportResult: {
        fields: [
            {
                name: 'Date',
                type: InputTypes.date
            },
            {
                name: 'Operator'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'CasinoHand',
                display: 'Total Games',
                type: InputTypes.number,
            },
            {
                name: 'CasinoBet',
                display: 'Casino Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'CasinoWin',
                display: 'Casino Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'CasinoGGR',
                display: 'Casino GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (!isFooter && !isExporting) {
                        return currency(row.CasinoBet - row.CasinoWin, row.Currency, 2);
                    }
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'RTP',
                display: 'Casino RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.CasinoBet;
                        if (bets == 0) bets = 1;
                        var g = bets - row.CasinoWin;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
            {
                name: 'SlotHand',
                display: 'Total Games',
                type: InputTypes.number,
            },
            {
                name: 'SlotBet',
                display: 'Slot Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'SlotWin',
                display: 'Slot Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'SlotGGR',
                display: 'Slot GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (!isFooter && !isExporting) {
                        return currency(row.SlotBet - row.SlotWin, row.Currency, 2);
                    }

                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Slot RTP',
                display: 'Slot RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.SlotBet;
                        if (bets == 0) bets = 1;
                        var g = bets - row.SlotWin;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
            {
                name: 'GGR',
                display: 'Overal GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (!isFooter && !isExporting) {
                        return currency(row.SlotBet - row.SlotWin, row.Currency, 2);
                    }

                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Overall RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.Bet;
                        if (bets == 0) bets = 1;
                        var g = bets - row.Win;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
        ]
    },
    casinoProviderReport: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EventTime',
                display: 'Event Time',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'ProviderId',
                display: 'Providers',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/gaming',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    casinoProviderReportResult: {
        fields: [
            {
                name: 'Date',
                type: InputTypes.date
            },
            {
                name: 'Operator'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'RetailName',
                display: 'Retail Name'
            },
            {
                name: 'ProviderName',
                display: 'Provider'
            },
            {
                name: 'Aggregator'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Bet',
                display: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    row.GGR = row.Bet - row.Win;
                    if (isExporting && isFooter) {
                        return currencyAndFooter(val, row, isFooter, isExporting);
                    }
                    if (isFooter) return currencyAndFooter(val, row, isFooter, isExporting);
                    var res = row.Bet - row.Win;
                    return currencyAndFooter(res, row, isFooter, isExporting);
                }
            },
            {
                name: 'RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.Bet;
                        if (bets == 0) bets = 1;
                        var g = bets - row.Win;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
            {
                name: 'TotalHands',
                display: 'Games',
                type: InputTypes.number,
            }
        ]
    },
    casinoGamesReport: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EventTime',
                display: 'Event Time',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'GameId',
                display: 'Game Id'
            },
            {
                name: 'GameName',
                display: 'Game'
            },
            {
                name: 'GameType',
                display: 'Game Type',
                type: InputTypes.select,
                values: ProviderGameTypes,
                acceptEmptyValues: true
            },
            {
                name: 'ProviderId',
                display: 'Providers',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/gaming',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    casinoGamesReportResult: {
        fields: [
            {
                name: 'Date',
                type: InputTypes.date
            },
            {
                name: 'Operator'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'ProviderName',
                display: 'Provider'
            },
            {
                name: 'Aggregator',
                display: 'Aggregator'
            },
            {
                name: 'GameId',
                display: 'Game Id'
            },
            {
                name: 'GameName',
                display: 'Game'
            },
            {
                name: 'Bet',
                display: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Cancel',
                display: 'Cancel',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting && isFooter) {
                        return currencyAndFooter(val, row, isFooter, isExporting);
                    }
                    if (isFooter) return currencyAndFooter(val, row, isFooter, isExporting);
                    var res = (row.Bet - row.Cancel) - row.Win;
                    return currencyAndFooter(res, row, isFooter, isExporting);
                }
            },
            {
                name: 'RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.Bet - row.Cancel;
                        if (bets == 0) bets = 1;
                        var g = bets - row.Win;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
            {
                name: 'TotalHands',
                display: 'Total Games',
                type: InputTypes.number,
            }
        ]
    },
    casinoProviderCommission: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EventTime',
                display: 'Event Time',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            }
        ]
    },
    casinoProviderCommissionResult: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'ProviderName',
                display: 'Provider'
            },
            {
                name: 'Aggregator',
                display: 'Aggregator'
            },
            {
                name: 'Bet',
                display: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    row.GGR = row.Bet - row.Win;
                    if (isExporting && isFooter) {
                        return currencyAndFooter(val, row, isFooter, isExporting);
                    }
                    if (isFooter) return currencyAndFooter(val, row, isFooter, isExporting);
                    var res = (row.Bet) - row.Win;
                    return currencyAndFooter(res, row, isFooter, isExporting);
                }
            },
            {
                name: 'RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.Bet;
                        if (bets == 0) bets = 1;
                        var g = bets - row.Win;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
            {
                name: 'TotalHands',
                display: 'Total Games',
                type: InputTypes.number,
            }
        ]
    },
    playerGameRevenuesReportResult: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'PlayerId',
                display: 'Id'
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'GroupName',
                display: 'Group',
                type: InputTypes.text
            },
            {
                name: 'Code',
                type: InputTypes.text
            },
            {
                name: 'RetailName',
                display: 'Retail Name',
                type: InputTypes.text
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Bet',
                display: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Cancel',
                display: 'Cancel',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting && isFooter) {
                        return currencyAndFooter(val, row, isFooter, isExporting);
                    }
                    if (isFooter) return currencyAndFooter(val, row, isFooter, isExporting);
                    var res = (row.Bet - row.Cancel) - row.Win;
                    return currencyAndFooter(res, row, isFooter, isExporting);
                }
            },
            {
                name: 'RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.Bet - row.Cancel;
                        if (bets == 0) bets = 1;
                        var g = bets - row.Win;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
            {
                name: 'TotalHands',
                display: 'Total Games',
                type: InputTypes.number,
            }
        ]
    },
    playerGroupsGameRevenuesReportResult: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'PlayerId',
                display: 'Player Id'
            },
            {
                name: 'Group'
            },
            {
                name: 'Username'
            },
            {
                name: 'Code',
                type: InputTypes.text
            },
            {
                name: 'RetailName',
                display: 'Retail Name',
                type: InputTypes.text
            },
            {
                name: 'GameName',
                display: 'Game'
            },
            {
                name: 'Currency'
            },
            {
                name: 'Bet',
                display: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Cancel',
                display: 'Cancel',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting && isFooter) {
                        return currencyAndFooter(val, row, isFooter, isExporting);
                    }
                    if (isFooter) return currencyAndFooter(val, row, isFooter, isExporting);
                    var res = (row.Bet - row.Cancel) - row.Win;
                    return currencyAndFooter(res, row, isFooter, isExporting);
                }
            },
            {
                name: 'RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.Bet - row.Cancel;
                        if (bets == 0) bets = 1;
                        var g = bets - row.Win;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
            {
                name: 'TotalHands',
                display: 'Total Games',
                type: InputTypes.number,
            }
        ]
    },
    operatorRevenuesReport: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'TransactionDate',
                display: 'Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            }
        ]
    },
    operatorRevenuesReportResult: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Players',
                type: InputTypes.number
            },
            {
                name: 'Deposit',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Withdraw',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Bonus',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Balance',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },

            {
                name: 'Hands',
                type: InputTypes.number
            }
        ]
    },

    providerRevenuesReport: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EventTime',
                display: 'Event Time',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'ProviderId',
                display: 'Providers',
                type: InputTypes.multipleSelect,
                multiple: true,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/gaming',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'LiveDealer',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    true: 'True',
                    false: 'False'
                }
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    providerRevenuesReportResult: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Provider',
                type: InputTypes.text
            },
            {
                name: 'LiveDealer',
                type: InputTypes.text
            },
            {
                name: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting && isFooter) {
                        return currencyAndFooter(val, row, isFooter, isExporting);
                    }
                    if (isFooter) return currencyAndFooter(val, row, isFooter, isExporting);
                    var res = (row.Bet - row.Cancel) - row.Win;
                    return currencyAndFooter(res, row, isFooter, isExporting);
                }
            },
            {
                name: 'Round',
                display: 'Hands',
                type: InputTypes.number
            },
            {
                name: 'RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.Bet - row.Cancel;
                        var g = bets - row.Win;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
        ]
    },
    sportsBettingRevenues: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EntryDate',
                display: 'Settlement Date',
                type: InputTypes.dateTime,
                and: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today,
                    TimeZone: 'UTC'
                }
            },
            {
                name: 'SportId',
                display: 'Sport',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'sportCategories',
                    bindingKey: 'id',
                    bindingText: ['name']
                }
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'EventId',
                display: 'Event Id',
                type: InputTypes.number
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    sportsBettingRevenuesOfPlayer: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.dateTime,
                and: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id'
            },
            {
                name: 'SportId',
                display: 'Sport',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'sportCategories',
                    bindingKey: 'id',
                    bindingText: ['name']
                }
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'Status',
                display: 'Status',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    0: 'Open',
                    1: 'Won',
                    2: 'Lost',
                    3: 'Cashout',
                    4: 'Cancel',
                    5: 'Cashback'
                }
            },
            {
                name: 'EventId',
                display: 'Event Id',
                type: InputTypes.number
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    sportsBettingRevenuesWithMarkets: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.dateTime,

                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'SportId',
                display: 'Sport',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'sportCategories',
                    bindingKey: 'id',
                    bindingText: ['name']
                }
            },
            {
                name: 'MarketName',
                display: 'Market'
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'Status',
                display: 'Status',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    0: 'Open',
                    1: 'Won',
                    2: 'Lost',
                    3: 'Cashout',
                    4: 'Cancel',
                    5: 'Cashback'
                }
            },
            {
                name: 'EventId',
                display: 'Event Id',
                type: InputTypes.number
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    searchSportsBettingRevenuesByWithEvent: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.dateTime,

                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'SportId',
                display: 'Sport',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'sportCategories',
                    bindingKey: 'id',
                    bindingText: ['name']
                }
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'Status',
                display: 'Status',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    0: 'Open',
                    1: 'Won',
                    2: 'Lost',
                    3: 'Cashout',
                    4: 'Cancel',
                    5: 'Cashback'
                }
            },
            {
                name: 'EventId',
                display: 'Event Id',
                type: InputTypes.number
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    sportBettingRevenuesReport: {
        fields: [
            {
                name: 'TicketDate',
                display: 'Date',
                formatter: (val, row, isFooter) => {
                    if (isFooter) return;
                    if (!row.TicketDate) return;
                    return new Date(row.TicketDate.split('T')[0] + 'T23:59:59').toLocaleDateString('en-GB');
                }
            },
            {
                name: 'Operator'
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'TicketBonusAmount',
                display: 'TicketBonusAmount',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'Profit',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            }
        ],
        onRenderRow: (rowObject) => {

            if (rowObject.data.GGR > 0) {
                rowObject.className = 'player-win';
            } else {
                rowObject.className = 'player-lost';
            }
            return rowObject;
        }
    },
    sportBettingPlayerRevenuesReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'PlayerId',
                display: 'Player Id'
            },
            {
                name: 'Username'
            },
            {
                name: 'Name'
            },
            {
                name: 'Surname',
            },
            {
                name: 'Code'
            },
            {
                name: 'Group'
            },
            {
                name: 'RetailName',
                display: 'Retail Name'
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Tax',
                display: 'Tax',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            }
        ]
    },
    sportPlayerGroupRevenues: {
        fields: [
            {
                name: 'Date',
                display: 'Date',
                type: InputTypes.date
            },
            {
                name: 'Operator'
            },
            {
                name: 'Group'
            },
            {
                name: 'RetailName',
                display: 'Retail Name'
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            }
        ]
    },
    sportCategoryRevenueSummaryReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Sport'
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }

            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            }
        ]
    },
    sportRegionRevenueSummaryReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Sport'
            },
            {
                name: 'Region',
                display: 'Region',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        const date = new Date(data[5]);
                        var ukDate = convertTZ(date, 'Europe/London');
                        var time = getTime(ukDate);

                        return <div>{`${data[1]}/${data[2]}/${data[4]} ${time}`}</div>;
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            }
        ]
    },
    sportLeagueRevenueSummaryReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Sport'
            },
            {
                name: 'Region',
                display: 'Region',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        return data[1];
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'League',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        return data[2];
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            }
        ]
    },
    sportEventRevenueSummaryReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Sport'
            },
            {
                name: 'Region',
                display: 'Region',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        return data[1];
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'League',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        return data[2];
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'EventId',
                display: 'Event Id'
            },
            {
                name: 'Event',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        const date = new Date(data[5]);
                        var ukDate = convertTZ(date, 'Europe/London');
                        var time = getTime(ukDate);

                        return `${time} / ${data[4]}`;
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            }
        ],
        onRenderRow: (rowObject) => {
            if (rowObject.data.Win > 0) {
                rowObject.className = 'player-win';
            } else {
                rowObject.className = 'player-lost';
            }
            return rowObject;
        }
    },
    sportMarketRevenueSummaryReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Sport'
            },
            {
                name: 'Region',
                display: 'Region',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        return data[1];
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'League',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        return data[2];
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'Event',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        const date = new Date(data[5]);
                        var ukDate = convertTZ(date, 'Europe/London');
                        var time = getTime(ukDate);

                        return `${time} / ${data[4]}`;
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'Market',
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            }
        ]
    },
    sportSelectionRevenueSummaryReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Sport'
            },
            {
                name: 'Region',
                display: 'Region',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        return data[1];
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'League',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        return data[2];
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'Event',
                formatter: (val, row) => {
                    if (row.Horse) {
                        var data = row.Region.split('|');
                        const date = new Date(data[5]);
                        var ukDate = convertTZ(date, 'Europe/London');
                        var time = getTime(ukDate);

                        return `${time} / ${data[4]}`;
                    } else {
                        return val;
                    }
                }
            },
            {
                name: 'Market',
            },
            {
                name: 'Selection',
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            }
        ]
    },
    sportPlayerRevenueSummaryReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Username'
            },
            {
                name: 'Slips',
                display: 'Slips'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Stake',
                display: 'Stake',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Tax',
                display: 'Tax',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Cashout',
                display: 'Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBet',
                display: 'Bonus Bet',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusWin',
                display: 'Bonus Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusCashout',
                display: 'Bonus Cashout',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusGGR',
                display: 'Bonus GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            }
        ]
    },
    searchReportByPlayer: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EventTime',
                display: 'Event Time',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number,
            },
            {
                name: 'Username',
                display: 'User Name',
                type: InputTypes.text,
            },
            {
                name: 'Code',
                display: 'Code',
                type: InputTypes.text,
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    playerRevenueReportResult: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'Code',
                display: 'Code',
                type: InputTypes.number
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return row.Username;

                    return gotoProfile(row.PlayerId, row.Username);
                }
            },
            {
                name: 'Currency',
                type: InputTypes.text
            },
            {
                name: 'Balance',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusBalance',
                display: 'Bonus',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Deposit',
                display: 'Deposit',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Withdraw',
                display: 'Withdraw',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'BonusDeposit',
                display: 'Bonus Deposit',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'CreditAdd',
                display: 'Credit',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'CreditRemove',
                display: 'Debit',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'Tickets',
                display: 'Tickets',
                type: InputTypes.number
            },
            {
                name: 'SportStake',
                display: 'Sport Stakes',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'SportWin',
                display: 'Sport Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'SportGGR',
                display: 'Sport GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'HorseBet',
                display: 'Horse',
                type: InputTypes.number
            },
            {
                name: 'HorseStake',
                display: 'Horse Stakes',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'HorseWin',
                display: 'Horse Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'HorseGGR',
                display: 'Horse GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'CasinoHands',
                display: 'Slots',
                type: InputTypes.number
            },
            {
                name: 'CasinoStake',
                display: 'Slot Stake',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'CasinoWin',
                display: 'Slot Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'CasinoGGR',
                display: 'Slot GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'LiveCasinoHands',
                display: 'Casino',
                type: InputTypes.number
            },
            {
                name: 'LiveCasinoStake',
                display: 'Casino Stake',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'LiveCasinoWin',
                display: 'Casino Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            },
            {
                name: 'LiveCasinoGGR',
                display: 'Casino GGR',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, row.Currency, 2, false);
                }
            }
        ],
        onRenderRow: (rowObject) => {
            if (rowObject.data.Win > (Math.abs(rowObject.data.Bet) - Math.abs(rowObject.data.Cancel))) {
                rowObject.className = 'player-lost';
            } else {
                rowObject.className = 'player-win';
            }
            return rowObject;
        }
    },
    searchPlayerGamesReport: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'DateStart',
                display: 'Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number,
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Code',
                display: 'Code',
                type: InputTypes.text,
            },
            {
                name: 'Username',
                display: 'User Name',
                type: InputTypes.text,
            },
            {
                name: 'ProviderId',
                display: 'Providers',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/gaming',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'LiveDealer',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    true: 'True',
                    false: 'False'
                }
            },
            {
                name: 'GameType',
                display: 'Game Type',
                type: InputTypes.select,
                values: ProviderGameTypes,
                acceptEmptyValues: true
            },
            {
                name: 'GameId',
                type: InputTypes.number,
            },
            {
                name: 'GameName',
                type: InputTypes.text,
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    resultPlayerGamesReport: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number,
            },
            {
                name: 'Code',
                display: 'Code'
            },
            {
                name: 'RetailName',
                display: 'Retail Name'
            },
            {
                name: 'Username',
                display: 'User Name',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return row.PlayerId;
                    return gotoProfile(row.PlayerId, row.Username);
                }
            },
            {
                name: 'GameId',
                display: 'Game Id'
            },
            {
                name: 'GameName',
                display: 'Game Name'
            },
            {
                name: 'Provider'
            },
            {
                name: 'LiveDealer'
            },
            {
                name: 'GameType',
                display: 'Game Type',
                type: InputTypes.select,
                values: ProviderGameTypes,
            },
            {
                name: 'Currency',
                type: InputTypes.text
            },
            {
                name: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting && isFooter) {
                        return currencyAndFooter(val, row, isFooter, isExporting);
                    }
                    if (isFooter) return currencyAndFooter(val, row, isFooter, isExporting);
                    var res = (row.Bet - row.Cancel) - row.Win;
                    return currencyAndFooter(res, row, isFooter, isExporting);
                }
            },
            {
                name: 'RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter) => {
                    if (!isFooter) {
                        var bets = row.Bet - row.Cancel;
                        var g = bets - row.Win;
                        var perc = 100 - ((g * 100) / bets);
                        if (perc < 0) perc = 0;

                        return perc.toFixed(2) + '%';
                    }
                }
            },
            {
                name: 'Round',
                display: 'Hands',
                type: InputTypes.number
            }
        ],
        onRenderRow: (rowObject) => {
            if (rowObject.data.Win > (Math.abs(rowObject.data.Bet) - Math.abs(rowObject.data.Cancel))) {
                rowObject.className = 'player-win';
            } else {
                rowObject.className = 'player-lost';
            }
            return rowObject;
        }
    },

    operatorTransactionsReport: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/payment',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'TransactionDate',
                display: 'Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
        ]
    },
    operatorTransactionsReportResult: {
        fields: [
            {
                name: 'Provider',
                type: InputTypes.text
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Deposit',
                display: 'Deposit',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'DepositCount',
                display: 'Deposit Count',
                type: InputTypes.number,
                formatter: (val) => {
                    return val;
                }
            },
            {
                name: 'Withdraw',
                display: 'Withdraw',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }

            },
            {
                name: 'WithdrawCount',
                display: 'Withdraw Count',
                type: InputTypes.number,
                formatter: (val) => {
                    return val;
                }
            },
        ]
    },
    playerTransactionsOfSelectedProvider: {
        fields: [
            {
                name: 'PlayerId',
                type: InputTypes.number
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return row.PlayerId;

                    return gotoProfile(row.PlayerId, row.Username);
                }
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Deposit',
                display: 'Deposit',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'DepositCount',
                display: 'Deposit Count',
                type: InputTypes.number,
                formatter: (val) => {
                    return val;
                }
            },
            {
                name: 'Withdraw',
                display: 'Withdraw',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }

            },
            {
                name: 'WithdrawCount',
                display: 'Withdraw Count',
                type: InputTypes.number,
                formatter: (val) => {
                    return val;
                }
            },
        ]
    },
    reportPlayerTransactionsByProvider: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'paymentProviders',
                    bindingKey: 'Id',
                    bindingText: ['Display']
                }
            },
            {
                name: 'TransactionDate',
                display: 'Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                type: InputTypes.number
            },
            {
                name: 'Username',
                type: InputTypes.text
            }
        ]
    },
    resultPlayerTransactionsByProvider: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'PlayerId'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return row.PlayerId;

                    return gotoProfile(row.PlayerId, row.Username);
                }
            },
            {
                name: 'Provider'
            },
            {
                name: 'Deposit',
                display: 'Deposit',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'DepositCount',
                display: 'Deposit Count',
                type: InputTypes.number,
                formatter: (val) => {
                    return val;
                }
            },
            {
                name: 'Withdraw',
                display: 'Withdraw',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }

            },
            {
                name: 'WithdrawCount',
                display: 'Withdraw Count',
                type: InputTypes.number,
                formatter: (val) => {
                    return val;
                }
            },
        ]
    },
};



export const searchPlayerBonusModel = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'TransactionDate',
            display: 'Transaction Date',
            max: 19,
            type: InputTypes.dateTime,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'Username',
            type: InputTypes.text,
            hidden: true
        },
        {
            name: 'UserId',
            type: InputTypes.text,
            hidden: true
        },
        {
            name: 'PromotionId',
            display: 'Promotion',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'promotion',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        }
    ]
};

