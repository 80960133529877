import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../Application/Application';
import { Card } from '../../../Centrum/Card/Card';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Button } from '../../../Controls/Buttons/Button';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import Table from '../../../Controls/Table/SortableTable/SortableTable';
import { API, SetupEndpoints } from '../../../../v2/Lib/Api/Api';


const topLeaguesModel = {
    fields: [
        {
            name: 'league',
            display: 'League',
            formatter: (field, row) => {
                return row.sport + '/' + row.region + '/' + row.league;
            }
        }
    ]
};



export const LeagueWeights = (props) => {
    const categories = props.categories;
    if (!categories) return <React.Fragment />;

    const [category, selectCategory] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [league, setLeague] = useState(null);
    const [regions, setRegions] = useState(null);
    const [region, setRegion] = useState(null);
    const [topLeagues, setTopLeagues] = useState(null);

    useEffect(() => {
        if (!topLeagues) {
            API.post(SetupEndpoints.GetLeagueWeights, {}, 'fetching league weights', 'unable to fetch league weights').then((result) => {
                try {
                    var data = JSON.parse(result.result);
                    if (!data) data = [];
                    setTopLeagues(data);
                } catch (err) {
                    setTopLeagues([]);
                }
            });
        }
    });

    const onCategoryChanged = (cat) => {
        application().resolve(null, Endpoints.SportRegions + '/' + cat.id, {}, 'fetching regions', 'unable to fetch regions').then((result) => {
            selectCategory(cat);
            setRegions(result.result.filter(x => x.name !== ''));
        });
    };

    const onRegionChanged = (regionId) => {
        application().resolve(null, Endpoints.SportLeagues + '/' + category.id + '/' + regionId, {}, 'fetching leagues', 'unable to fetch leagues').then((result) => {
            setRegion(regions.find(x => x.id == regionId));
            setLeagues(result.result);
        });
    };

    const save = () => {
        var saveData = Object.assign([], topLeagues);
        saveData.map(x => {
            delete x.__selected;
        });

        API.post(SetupEndpoints.SaveGetLeagueWeights, saveData, 'fetching leagues', 'unable to fetch leagues').then(() => {
            application().snackbar.open('Weights updated.');
        });

    };


    var sports = { 0: 'Please Select' };
    var countries = { 0: 'Please Select' };
    var leagueSelections = { 0: 'Please Select' };
    if (categories) categories.sort((a, b) => { return b.weight - a.weight; }).map(category => {
        sports[category.name] = category.name;
    });

    if (regions) regions.sort((a, b) => { return a.name > b.name ? 1 : -1; }).map(region => {
        countries[region.name + ':' + region.id] = region.name;
    });

    if (leagues) leagues.sort((a, b) => { return a.name > b.name ? 1 : -1; }).map(league => {
        leagueSelections[league.id] = league.name;
    });



    if (!topLeagues) return <div />;
    return <div className='surface'>
        <Card caption='Categories'>
            <Form>
                <div className='row margin-top'>
                    <FormInput title='Sports' type={InputTypes.select} values={sports} onChange={(form, field, value) => {
                        const selectedCategory = categories.find(x => x.name == value);
                        if (selectedCategory) {
                            onCategoryChanged(selectedCategory);
                        }
                    }} />
                </div>
                {
                    category &&
                    <div className='row margin-top'>
                        <FormInput title='Regions' type={InputTypes.select} values={countries} onChange={(form, field, value) => {
                            value = parseFloat(value.split(':')[1]);
                            if (value) {
                                onRegionChanged(value);
                            }
                        }} />
                    </div>
                }
                {
                    leagues && <div className='row margin-top'>
                        <FormInput title='Leagues' type={InputTypes.select} values={leagueSelections} onChange={(form, field, value) => {
                            value = parseFloat(value);
                            setLeague(leagues.find(x => x.id == value));
                        }} />
                        <Button title='Add' className='marginLeft' onClick={() => {
                            const leagueData = {
                                sport: category.name,
                                sportId: category.id,
                                region: region.name,
                                regionId: region.id,
                                league: league.name,
                                leagueId: league.id
                            };
                            if (!topLeagues.find(x => x.leagueId == league.id)) {
                                topLeagues.push(leagueData);
                                setTopLeagues(Object.assign([], topLeagues));
                            }
                        }} />
                    </div>
                }
            </Form>
        </Card>
        <Card caption='League Weights' className='margin-top'>
            <Table
                model={topLeaguesModel}
                data={topLeagues}
                key={topLeagues}
                options={{
                    data: {
                        delete: {
                            enabled: true,
                            header: true
                        }
                    },
                    draggable: {
                        enabled: true
                    },
                    selectable: true
                }}
                onDataChange={(row, index, value) => {
                    setTopLeagues(Object.assign([], value));
                }} />

            <div className='centrum-flex padding'>
                <Button title='Save' onClick={() => save()} className='button alignRight' />
            </div>
        </Card>
    </div>;
};

LeagueWeights.propTypes = {
    categories: PropTypes.object
};

