import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';

export const FormatField = (field, value) => {
    switch (field.type) {
        case InputTypes.dateTime:
            return formatDateTime(value);
    }

    return value;
};

export const formatDateTime = (date) => {
    try {
        return new Date(date.indexOf('Z') ? date : date + 'Z').toLocaleString('en-Gb', { hour12: false });
    } catch (err) {
        return date;
    }
};