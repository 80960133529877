import './_money.scss';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { currency } from '../../Centrum/Localization/lang';


export class Money extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    render() {
        var classNames = ['money', this.props.size];
        classNames.push(this.props.className);
        this.props.value < 0 && classNames.push('low');
        this.props.value > 0 && classNames.push('high');
        return <div className={classNames.join(' ')}>{currency(this.props.value, 2, this.props.currency)}</div>;
    }
}

Money.defaultProps = {
    className: null,
    value: 0,
    size: 'normal',
    currency: ''
};

Money.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    size: PropTypes.string,
    currency: PropTypes.string
};
