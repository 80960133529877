import './_error.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { application } from '../../../Application/Application';
import { Button } from '../../../Controls/Buttons/Button';
import { Icon } from '../../../Controls/Icon/Icon';


const CloseModalButton = (props) => {
    return <Button onClick={() => {
        application().modal.close();
        if (props.onClose) props.onClose();
    }} title='Close'><Icon icon={'times'} size='lg' /></Button>;
};

CloseModalButton.propTypes = {
    onClose: PropTypes.func
};

export class ErrorModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var footer = this.props.footer;
        if (footer == null) footer = <CloseModalButton onClose={this.props.onClose} />;
        return <React.Fragment>
            <div className='modal-window error-modal'>
                <div className='title'><h1>{this.props.title}</h1><Icon icon={'times'} size='lg' onClick={() => {
                    application().modal.close();
                    if (this.props.onClose) {
                        this.props.onClose();
                    }

                }} /></div>
                <div className='body'>{this.props.children}</div>
                <div className='footer'>{footer}</div>
            </div>
        </React.Fragment>;
    }
}

ErrorModal.defaultProps = {
    children: null,
    footer: null,

    onClose: null
};

ErrorModal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    footer: PropTypes.node,

    onClose: PropTypes.func
};
