import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';
import { UserGroupTypes } from './ModelEnums';

export const MyProfileModels = {
    myProfile: {
        fields: [
            {
                name: 'Operator',
                display: 'Operator',
                type: InputTypes.text,
                readonly: true
            },
            {
                name: 'Group',
                display: 'User Group',
                type: InputTypes.select,
                values:UserGroupTypes,
                readonly:true
            },
            {
                name: 'Email',
                type: InputTypes.text,
                readonly: true
            }
        ]
    },
    changeMyPassword: {
        fields: [
            
            {
                name: 'Password',
                type: InputTypes.text
            },
            {
                name: 'PasswordConfirm',
                type: InputTypes.text
            }
        ]
    },
    searchMyActivity: {
        fields: [
            {
                name:'Created',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data:DateRanges['Last Week']
                }
            },
            {
                name: 'Activity',
                type: InputTypes.text
            }
        ]
    }
};