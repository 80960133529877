import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Card } from '../../../../Centrum/Card/Card';
import { lang } from '../../../../Centrum/Localization/lang';
import { Tab } from '../../../../Centrum/Tab/Tab';
import { RetailTransactions } from './RetailTransactions';
import { RetailUsers } from './Users/RetailUsers';
import { RetailPlayers } from './Players/RetailPlayers';
import { RetailSummary } from './RetailSummary';
import { RetailSettlements } from './RetailSettlements';
import { Button } from '../../../../Controls/Buttons/Button';



const tabButtons = [
    {
        name: 'summary',
        title: 'Summary'
    },
    {
        name: 'users',
        title: 'Users'
    },
    {
        name: 'players',
        title: 'Players'
    },
    {
        name: 'transactions',
        title: 'Transactions'
    },
    {
        name: 'settlements',
        title: 'Settlements'
    }
];


const RetailOperations = (props) => {
    return <Card caption={lang('[%1] Retail Operations of #%2 - [%3]', [props.retail.Type == 0 ? 'Virtual' : 'Physical', props.retail.Name, props.retail.Code])}
        menu={<Button title='Close' onClick={() => props.onClose()} />}>
        <Tab buttons={tabButtons}>
            <RetailSummary retail={props.retail} />
            <RetailUsers retail={props.retail} />
            <RetailPlayers retail={props.retail} />
            <RetailTransactions retail={props.retail} />
            <RetailSettlements retail={props.retail} />
        </Tab>
    </Card>;
};

RetailOperations.propTypes = {
    retail: PropTypes.object,
    onClose: PropTypes.func
};



const mapStateToProps = state => {
    return {
        data: state.data
    };
};
const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(RetailOperations);
export { hoc as RetailOperations };