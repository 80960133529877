export const SportsTicketTypes = {
    null: 'All',
    0: 'Single',
    1: 'Combined',
    2: 'System'
};

export const SportsTicketStatus = {
    null: 'All',
    0: 'Open',
    1: 'Won',
    2: 'Lost',
    3: 'Cashout',
    4: 'Cancel',
    5: 'Cashback',
    6: 'W/Return',
    7: 'L/Return',
    9: 'Bet Cancel'
};


export const BettingFlags = {
    None: 0x00000001,
    Single: 0x00000002,
    Combination: 0x00000004,
    System: 0x00000008,
    Forecast: 0x00000010,
    Tricast: 0x00000020,
    StartingPrice: 0x00000040,
    Horse: 0x00000080,
    Greyhound: 0x00000100,
    Harness: 0x00000200,
    Virtual: 0x00000400,
    ForecastRFC: 0x00040000,
    ForecastCFC: 0x00080000,
    TricastCTC: 0x00100000,
    EachWay: 0x00200000,
    SingleEvent: 0x00000800,
    Prematch: 0x00001000,
    Live: 0x00002000,
    PrematchAndLive: 0x00004000,
    BookedBet: 0x01000000
};

export const RacingTicketTypes = {
    0: 'Single',
    1: 'Forecast',
    2: 'Tricast',
    3: 'Doubles',
    4: 'Triples',
    5: 'Trixie',
    6: 'Patent',
    7: 'Yankee',
    8: 'Lucky15',
    9: 'SuperYankee',
    10: 'Lucky31',
    11: 'Heinz',
    12: 'Lucky63',
    13: 'SuperHeinz',
    14: 'Quads',
    15: 'Quints',
    16: 'Sexts',
    17: 'Septs'
};

