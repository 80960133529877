import './_table.scss';

import React from 'react';
import PropTypes from 'prop-types';


import { models, icons, cacheData } from '../Shared';

import { CentrumComponent, keys } from '../CentrumComponent';
import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { lang } from '../Localization/lang';


import { getUtcDate } from '../helpers';
import { Button } from '../../Controls/Buttons/Button';
import { Icon } from '../../Controls/Icon/Icon';

const actionTypes = {
    edit: 'edit',
    delete: 'delete'
};


export const SortingDirection = {
    None: 0,
    Desc: 1,
    Asc: 2
};


class TableAction extends CentrumComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    onActionClicked() {
        if (!this.props.enabled) return;
        if (this.props.onActionClicked != null) this.props.onActionClicked(this.props.action, this.props.selection);
    }

    render() {
        var caption = this.props.action.caption;
        var icon = icons[this.props.action.icon];
        var enabled = this.props.enabled;

        if (this.props.onRenderAction != null) {
            var data = this.props.onRenderAction(this.props.action, this.props.selection);
            if (data !== undefined) {
                if (data === false) return <React.Fragment />;
                caption = data.caption;
                icon = icons[data.icon];
                enabled = data.enabled == undefined ? true : data.enabled;
            }
        }


        switch (this.props.action.type) {
            case actionTypes.edit:
                return (
                    <div onClick={() => this.onActionClicked(this.props.action, this.props.selection)} className={(enabled ? '' : 'disabled') + ' table-action-' + this.props.action.type}><Icon icon={icons.edit} />{lang('Edit')}</div>
                );
            case actionTypes.delete:
                return (
                    <div onClick={() => this.onActionClicked(this.props.action, this.props.selection)} className={(enabled ? '' : 'disabled') + ' table-action-' + this.props.action.type}><Icon icon={icons.delete} />{lang('Delete')}</div>
                );
            default:
                return (
                    <div onClick={() => this.onActionClicked(this.props.action, this.props.selection)} className={(enabled ? '' : 'disabled') + ' table-action-' + this.props.action.type}>{icon !== undefined && icon !== null && <Icon icon={icon} />}{lang(caption)}</div>
                );
        }
    }
}


TableAction.defaultProps = {
    onActionClicked: null,
    enabled: true,
    selection: null
};

TableAction.propTypes = {
    action: PropTypes.object,
    enabled: PropTypes.bool,
    selection: PropTypes.array,
    onActionClicked: PropTypes.func
};

export class TableActions extends CentrumComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    renderAction(action) {
        let enabled = true;
        if (!action.multiple && this.props.selection.length > 1) enabled = false;
        return <TableAction key={action.type} action={action} onActionClicked={this.props.onActionClicked} onRenderAction={this.props.onRenderAction} enabled={enabled} selection={this.props.selection} />;
    }

    render() {
        let className = 'table-actions';
        if (this.props.activateOnSelection && this.props.selection.length === 0) className = 'table-actions no-selection';
        return (
            <div className={className}>
                <h1>{lang(this.props.caption)}</h1>
                {
                    this.props.actions !== null && this.props.actions !== undefined && this.props.actions.items !== undefined && this.props.actions.items.map(action => {
                        return this.renderAction(action);
                    })
                }
            </div>
        );

    }
}

TableActions.defaultProps = {
    activateOnSelection: true,
    caption: 'Actions',
    onActionClicked: null,
    onRenderAction: null
};

TableActions.propTypes = {
    actions: PropTypes.object,
    selection: PropTypes.array,
    caption: PropTypes.string,
    activateOnSelection: PropTypes.bool,
    onActionClicked: PropTypes.func,
    onRenderAction: PropTypes.func
};




export class Table extends CentrumComponent {
    constructor(props) {
        super(props);
        this._data = {};
        this._selection = [];
        this._rowObjects = [];
        this._columns = {};
        this.state = Object.assign({}, props);
    }


    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    get selection() {
        return this._selection;
    }

    set selection(val) {
        this._selection = val;
    }

    get rowObjects() {
        return this._rowObjects;
    }

    set rowObjects(value) {
        this._rowObjects = value;
    }

    get columns() {
        return this._columns;
    }

    set columns(value) {
        this._columns = value;
    }

    getCurrentIndex() {
        let current = this.state.focused;
        if (current === null) {
            if (this.selection.length === 0) {
                current = -1;
            } else {
                current = this.selection[this.selection.length - 1];
            }
        }

        let index = this.props.data.rows.findIndex(x => x === current);
        return index;
    }

    focusNext() {
        let index = this.getCurrentIndex();
        if (index == -1) {
            index = 0;
        } else {
            index++;
        }

        if (index >= this.props.data.rows.length) index = 0;

        let current = this.props.data.rows[index];
        let state = Object.assign({}, this.state, {});
        state.focused = current;
        this.setState(state);
    }


    focusPrev() {
        let index = this.getCurrentIndex();
        if (index == -1) {
            index = 0;
        } else {
            index--;
        }

        if (index < 0) index = this.props.data.rows.length - 1;

        let current = this.props.data.rows[index];
        let state = Object.assign({}, this.state, {});
        state.focused = current;
        this.setState(state);
    }

    selectFocused() {
        if (this.state.focused === null) return;
        let index = this.props.data.rows.findIndex(x => x === this.state.focused);
        if (index === -1) return;
        this.rowClicked(this.state.focused);
    }

    blurFocused() {
        if (this.state.focused === null) return;
        let state = Object.assign({}, this.state, {});
        state.focused = null;
        this.setState(state);
    }

    onUIKeysPressed(key) {
        switch (key) {
            case keys.down:
                this.focusNext();
                break;
            case keys.up:
                this.focusPrev();
                break;
            case keys.enter:
                this.selectFocused();
                break;
            case keys.escape:
                this.blurFocused();
                break;
            default:
                break;
        }
    }

    //events
    pageClicked(index) {
        if (this.props.events.onPageClicked !== null && this.props.events.onPageClicked !== undefined) this.props.events.onPageClicked(index);
    }

    columnClicked(row, column) {
        if (this.props.events.onColumnClicked !== null && this.props.events.onColumnClicked !== undefined) this.props.events.onColumnClicked(row, column);
    }

    rowClicked(row) {
        if (this.props.events.onRowClicked !== null && this.props.events.onRowClicked !== undefined) this.props.events.onRowClicked(row);

        if (this.props.selectable) {
            let selection = this.selection;
            if (selection === undefined || selection === null) selection = [];

            let index = selection.findIndex(x => x === row);
            // we can clear items if multiple selection is false
            if (!this.props.multipleSelect) selection = [];

            // do not add same item 2 times
            if (index == -1) {
                selection.push(row);
            } else {
                selection.splice(index, 1);
            }

            let state = Object.assign({}, this.state, {});
            this.selection = selection;
            state.focused = row;
            this.setState(state);
        }
    }


    removeFromSelected(row) {
        let index = this.selection.findIndex(x => x.Id === row.Id);
        if (index >= 0) {
            this.selection.splice(index, 1);
        }
    }

    exportToExcel() {
    }

    exportAllPagesToExcel() {
        this.props.events.onExportAllPagesToExcel();
    }


    headerClicked(column) {
        if (this.props.events.onHeaderClicked !== null && this.props.events.onHeaderClicked !== undefined) this.props.events.onHeaderClicked(column, this.props.sorting);
    }

    footerClicked(column) {
        if (this.props.events.onFooterClicked !== null && this.props.events.onFooterClicked !== undefined) this.props.events.onFooterClicked(column);
    }

    renderData() {
        if (this.props.data === null) return;
        let model = models.get(this.props.model ? this.props.model : this.props.data.model);


        let rows = this.props.data.result;
        var self = this;

        // prepare columns to render
        for (var i = 0; i < model.fields.length; i++) {
            let field = model.fields[i];
            const context = this.props.context;
            this.columns[field.name] = {
                field: field,
                render: function (val, row) {
                    if (val === undefined) {
                        if (field.formatter) {
                            return this.field.formatter(val, row, false, context);
                        }
                        return '';
                    }
                    val = val === null ? '' : val.toString();
                    if (this.field.type === 'date' || this.field.type === 'dateTime') {
                        if (val === '' || val === null || val === undefined) {
                            val = '';
                        } else {
                            var date = new Date(val);
                            date = getUtcDate(date);
                            val = this.field.type == 'date' ? date.toLocaleDateString('en-Gb') : date.toLocaleString('en-Gb', { hour12: false });
                            val = val.replace(' 24', ' 00');
                            if (val === 'Invalid Date') val = '';
                        }
                    }

                    if (this.field.type === 'bool') {
                        val = val === 'true' ? 'yes' : 'no';
                        val = lang(val);
                    }

                    if (this.field.formatter) {
                        return this.field.formatter(val, row, false, context);
                    }

                    if (this.field.type === InputTypes.enum || this.field.type === InputTypes.select) {
                        if (this.field.values !== undefined) {
                            val = lang(this.field.values[val]);
                        } else {
                            if (this.field.valueEndPoint !== undefined && this.field.valueEndPoint !== null) {
                                let cacheValue = cacheData[this.field.valueEndPoint.source].values;
                                if (cacheValue !== undefined) {
                                    this.field.values = cacheValue;
                                    val = lang(this.field.values[val]);
                                }
                            }
                        }
                    }

                    if (this.field.type === InputTypes.image) {
                        return <img src={val} />;
                    }

                    if (this.field.link !== undefined && this.field.link !== null) {
                        var pattern = /\{.+?\}/g;
                        var url = this.field.link.url;
                        // an url is given
                        // look for parameters
                        // example: '/customers/playerProfile/{Id}'
                        if (url !== undefined && url !== null) {
                            var matches = url.match(pattern);
                            if (matches !== null) {
                                matches.map(key => {
                                    var targetFieldName = key.replace('{', '');
                                    targetFieldName = targetFieldName.replace('}', '');
                                    url = url.replace(key, row[targetFieldName]);
                                });

                                return <div className='column-lnk' onClick={self.onNavigate.bind(this, url)}>{val}</div>;
                            }
                        }

                        var action = this.field.link.action;
                        if (action !== undefined && action != null) {
                            if (self.props.actions !== null && self.props.actions !== undefined &&
                                self.props.events !== null && self.props.events !== undefined &&
                                self.props.events.onActionClicked !== null && self.props.events.onActionClicked !== undefined) {
                                var targetAction = self.props.actions.items.find(x => x.type === action);
                                if (targetAction !== null && targetAction !== undefined) {
                                    return <div className='column-lnk' onClick={self.props.events.onActionClicked.bind(this, targetAction, [row])}>{val}</div>;
                                }
                            }
                        }
                    }
                    return val;
                }
            };
        }



        this.rowObjects = [];
        //render rows
        if (rows === undefined) return;
        rows.map(row => {
            const initialObject = {
                data: row,
                render: true,
                disabled: false,
                className: '',
                selected: this.selection.find(x => x === row) !== undefined
            };

            var rowObject = Object.assign({}, initialObject);

            if (this.props.events.onRenderRow !== null && this.props.events.onRenderRow !== undefined) this.props.events.onRenderRow(rowObject, this);
            if (model.onRenderRow) {
                rowObject = model.onRenderRow(rowObject);
            }
            if (!rowObject.render) return;
            this.rowObjects.push(rowObject);
        });
    }

    renderHeader() {
        let model = models.get(this.props.model ? this.props.model : this.props.data.model);

        return (
            <div key='header' className={'table-header ' + this.props.data.model}>
                {
                    model.fields.map(field => {
                        var sortingClass = ' ';
                        var sorting = this.props.sorting;
                        if (this.props.sortingEnabled && sorting.column === field.name) {
                            switch (sorting.direction) {
                                case SortingDirection.Desc:
                                    sortingClass = ' sort-desc';
                                    break;
                                case SortingDirection.Asc:
                                    sortingClass = ' sort-asc';
                                    break;
                            }
                        }

                        return (
                            <div key={field.name}
                                className={'table-col ' + field.name + ' ' + field.type + sortingClass}
                                onClick={() => this.headerClicked(field.name)}>
                                {!field.renderHeader ? (lang((field.display === undefined || field.display === null || field.display === '') ? field.name : field.display)) : field.renderHeader()}
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    renderBody() {
        //render rows
        let index = 0;
        let columns = this.columns;
        return this.rowObjects.map(rowObject => {
            index++;
            var row = rowObject.data;
            if (!rowObject.render) return;
            rowObject.className = 'table-row' + (rowObject.selected ? ' selected' : '') + (this.state.focused === row ? ' focused ' : '') + ' ' + rowObject.className;
            return (
                <div key={index} className={rowObject.className}
                    onDoubleClick={!rowObject.disabled && this.props.events.onDoubleClick ? () => this.props.events.onDoubleClick(rowObject.data) : null}
                    onClick={!rowObject.disabled ? () => this.rowClicked(rowObject.data) : null}>
                    {
                        Object.keys(columns).map(column => {
                            var val = columns[column].render(row[column], row);
                            var id = columns[column].field.rowId ? columns[column].field.rowId(val, row) : null;
                            return <div key={column} id={id} className={'table-col ' + id} style={{ textAlign: (columns[column].field.type === InputTypes.number ? 'right' : '') }} onClick={() => this.columnClicked(row, column)}>{val}</div>;
                        })
                    }
                </div>
            );
        });
    }

    renderFooter() {
        let footer = this.props.data.footer;
        if (footer === null) return;
        if (footer === undefined) return;
        if (footer.length === 0) return;
        let model = models.get(this.props.model ? this.props.model : this.props.data.model);


        return (
            <div key='footer' className='table-footer'>
                {
                    model.fields.map(field => {
                        return (
                            <div key={field.name} className={'table-col ' + field.name + ' ' + field.type} onClick={() => this.footerClicked(field.name)} style={{ fontWeight: 'bold' }}>{(field.formatter !== null && field.formatter !== undefined) ? field.formatter(footer[field.name], footer, true, this.props.context) : footer[field.name]}</div>
                        );
                    })
                }
            </div>
        );
    }

    renderPagination() {
        if (this.props.data === null) return;
        if (this.props.data.result === undefined) return;
        if (this.props.data.result.length == 0) return;
        let totalPages = Math.ceil(this.props.data.total / this.props.data.recordsPerPage);
        let current = this.props.data.currentPage;
        let startPage = current;
        startPage -= 5;
        if (startPage < 0) startPage = 0;

        let max = startPage + 20;
        if (max > totalPages) max = totalPages;
        let pages = [];
        for (var i = startPage; i < max; i++) {
            pages.push({ index: i + 1 });
        }

        return (
            <div key='pagination' className='table-pagination'>
                <div className={current == 0 ? 'page-previous disabled' : 'page-previous'} onClick={() => this.pageClicked(current)}><button>{lang('Previous')}</button></div>
                <div className='pages'>
                    <button key='first' onClick={() => this.pageClicked(1)} className={current == 0 ? 'btn-first-page disabled' : 'btn-first-page'}><Icon icon={'step-backward'} /></button>
                    {
                        pages.map(page => {
                            return <button key={page.index} onClick={() => this.pageClicked(page.index)} className={(current + 1) === page.index ? 'table-pagination-current' : ''}>{page.index}</button>;
                        })
                    }
                    <button key='last' onClick={() => this.pageClicked(totalPages)} className={current == totalPages - 1 || totalPages <= 1 ? 'btn-last-page disabled' : 'btn-last-page'}><Icon icon={'step-forward'} /></button>
                </div>
                <div className={current == totalPages - 1 || totalPages <= 1 ? 'page-next disabled' : 'page-next'} onClick={() => this.pageClicked(current + 2)}><button>{lang('Next')}</button></div>
            </div>
        );
    }


    renderResults() {
        return (
            <div className='stats'>
                <span>{lang('Total')}<i>{this.props.data != null ? this.props.data.total : 0}</i></span>
                <span>{lang('Selected')}<i>{this.selection.length}</i></span>
            </div>
        );
    }

    renderComponent() {
        if (this.data !== this.props.data) {
            this._selection = [];
            this.data = this.props.data;
        }

        this.renderData();
        return (
            <div className={'centrum-table ' + this.props.className} style={this.props.style}>
                {this.props.children}
                {
                    this.props.showResults &&
                    <div className={(this.props.caption !== '' ? 'table-information' : 'table-information no-caption')} style={!this.props.showResultsSummary ? { display: 'none' } : {}}>
                        {
                            this.props.showRecordsPerPageSelection && <div className='flex gap-5 margin-right'>
                                <select onChange={(e) => this.props.events.onChangeRowsPerPage ? this.props.events.onChangeRowsPerPage(e.target.value) : null}>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        }
                        {
                            this.props.caption !== '' && <h1>{lang(this.props.caption)}</h1>
                        }
                        {this.props.showResultsSummary && this.renderResults()}
                        {this.props.showActions && <TableActions actions={this.props.actions} selection={this.selection} onActionClicked={this.props.events.onActionClicked} onRenderAction={this.props.events.onRenderAction} />}
                        {this.props.exportToExcel && <Button title={lang('Export to excel')} className='exportToExcel' onClick={() => this.exportAllPagesToExcel()} />}
                    </div>
                }
                <div className='table' key='table'>
                    <div key='content-holder' className='table-content-holder'>
                        <div key='content' className={'table-content ' + (this.props.data != null ? this.props.data.model : '')}>
                            {this.props.data !== null && this.props.showResults && this.renderHeader()}
                            {this.props.data !== null && this.props.showResults && this.renderBody()}
                            {this.props.data !== null && this.props.showResults && this.renderFooter()}
                        </div>
                    </div>
                    {this.props.data !== null && this.props.showPagination && this.renderPagination()}
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }

}

Table.defaultProps = {
    exportToExcel: false,
    className: '',
    style: {},
    data: {
        model: '',
        total: 0,
        currentPage: 0,
        result: []
    },
    model: null,
    camelCase: true,
    focused: null, // focused element by keyboard or selection
    selection: [],  // array of selection
    selectable: true,
    children: null,
    actions: {
        items: [
            {
                type: 'edit',
                multiple: false,
                caption: 'Edit',
                icon: 'edit'
            },
            {
                type: 'delete',
                multiple: true,
                caption: 'Delete',
                icon: 'delete'
            }
        ]
    },
    caption: null,
    multipleSelect: true,
    showResultsSummary: true,
    showActions: true,
    showPagination: true,
    showResults: true,
    sorting: {
        column: '',
        direction: 0  // 0 no sorting, 1 desc , 2 ascending
    },
    sortingEnabled: true,
    context: null,
    events: {
        onHeaderClicked: null,
        onDoubleClick: null,
        onPageClicked: null,
        onRowClicked: null,
        onColumnClicked: null,
        onFooterClicked: null,
        onActionClicked: null,
        onRenderAction: null,
        onRenderRow: null,
        onExportAllPagesToExcel: null,
        onChangeRowsPerPage: null
    }
};

Table.propTypes = {
    exportToExcel: PropTypes.bool,
    data: PropTypes.object,
    model: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    caption: PropTypes.string,
    children: PropTypes.node,
    camelCase: PropTypes.bool,
    actions: PropTypes.object,
    selection: PropTypes.array,
    selectable: PropTypes.bool,
    multipleSelect: PropTypes.bool,
    sorting: PropTypes.object,
    sortingEnabled: PropTypes.bool,
    context: PropTypes.object,
    events: PropTypes.object,
    showResultsSummary: PropTypes.bool,
    showActions: PropTypes.bool,
    showPagination: PropTypes.bool,
    showResults: PropTypes.bool,
    showRecordsPerPageSelection: PropTypes.bool
};
