import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';


import { getActions, ActionTypes, getAction } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents } from '../../Centrum/Application/CentrumApplication';
import { ApplicationLimits } from './Configurations/ApplicationLimits';
import { SportEventLimits } from './Configurations/SportsbookLimits/SportEventLimits';
import { RiskmanagementDashboard } from '../Dashboards/RiskManagement/RiskmanagementDashboard';
import { Card } from '../../Centrum/Card/Card';
import { Search } from '../../Centrum/Search/Search';
import { apiResult, Endpoints } from '../../Application/Application';
import { BetMonitor } from './BetMonitor/BetMonitor';
import { SportLeagueLimits } from './Configurations/LeagueLimits/SportLeagueLimits';
import { betSlipResults, lastRemainingResults, openBetModal, openSlipsResult } from './models';
import { manualCancel, manualLost, manualRollback, manualWin } from './actions/ticketActions';
import { SportCategoryLimits } from './Configurations/CategoryLimits/SportCategoryLimits';
import { SportPlayerLimits } from './Configurations/PlayerLimits/SportPlayerLimits';
import { EventManagement } from './EventManagement/EventManagement';
import { SportsConfiguration } from './Configurations/SportsConfiguration';
import { RiskLevelsConfiguration } from './RiskLevels/RiskLevelsConfiguration';
import { BetMonitorRiskLevels } from './BetMonitorRiskLevels/BetMonitorRiskLevels';
import { LastRemainingTickets } from './BetMonitorRiskLevels/LastRemainingTickets/LastRemainingTickets';
import { SportsbookMargins } from './Margins/SportsbookMargins';

const applicationActionType = ActionTypes.RiskManagement;
const applicationActions = getActions(applicationActionType);



// COMPONENT


export const RiskManagementPage = () => {
    const dailyTickets = useSelector((state) => state.data.dailyTickets);
    const openTickets = useSelector((state) => state.data.openTickets);
    const lastRemaining = useSelector((state) => state.data.lastRemaining);
    const resultedTickets = useSelector((state) => state.data.resultedTickets);

    const riskType = window.user.features?.configuration?.risk ? JSON.parse(window.user.features.configuration.risk).type : { type: 0 };
    const riskManagement = riskType.type == 0 ? <BetMonitor /> : <BetMonitorRiskLevels />;

    const onActionClicked = (action, items) => {
        const item = items[0];
        switch (action.type) {
            case ActionTypes.ManualTicketWin:
                manualWin(item);
                break;
            case ActionTypes.ManualTicketLost:
                manualLost(item);
                break;
            case ActionTypes.ManualTicketCancel:
                manualCancel(item);
                break;
            case ActionTypes.ManualTicketRollback:
                manualRollback(item);
                break;
            case ActionTypes.ManualTicketSettlement:
                openBetModal(item.ExternalTicketId, item.ticketType == 2, true);
                break;
        }
    };

    return (
        <CentrumApplication
            caption='Risk Management' icon='cogs'
            type={applicationActionType}
            loading={false}
        >
            <React.Fragment>
                <CentrumApplicationActions actions={applicationActions} />
                <CentrumApplicationContents actions={applicationActions} renderAll={false}>
                    <RiskmanagementDashboard />

                    <SportsConfiguration />
                    <ApplicationLimits />
                    <SportCategoryLimits title='Category Limits' displayRegions={false} />
                    <SportLeagueLimits title='League Limits' displayRegions={false} />
                    <SportEventLimits title='Event Limits' />
                    <SportPlayerLimits title='Player Limits' />
                    <RiskLevelsConfiguration />
                    <SportsbookMargins/>

                    <Card caption='Daily Tickets'>
                        <Search
                            model='dailyTickets'
                            resultModel={betSlipResults}
                            data={dailyTickets}
                            actions={getAction(ActionTypes.ManualTicketOptions).convertToItems()}
                            onActionClicked={onActionClicked.bind(this)}
                            onSubmit={(data, qb) => { return apiResult('dailyTickets', Endpoints.SearchSportTickets, qb, 'searching sport tickets', 'unable to fetch tickets'); }}
                        />
                    </Card>
                    <Card caption='Open Tickets'>
                        <Search
                            model='searchOpenBetSlips'
                            resultModel={openSlipsResult}
                            data={openTickets}
                            actions={getAction(ActionTypes.ManualTicketOptions).convertToItems()}
                            onActionClicked={onActionClicked.bind(this)}
                            onSubmit={(data, qb) => {
                                return apiResult('openTickets', Endpoints.SearchOpenTickets, qb, 'fetching operator revenues reports', 'unable to fetch report');
                            }}
                        />
                    </Card>
                    {
                        riskType.type == 0 ? <Card caption='Last Remaining Results'>
                            <Search
                                model='searchLastRemainingBetSlips'
                                resultModel={lastRemainingResults}
                                data={lastRemaining}
                                onSubmit={(data, qb) => { return apiResult('lastRemaining', Endpoints.SearchLastRemainingTickets, qb, 'fetching operator revenues reports', 'unable to fetch report'); }}
                            />
                        </Card> : <LastRemainingTickets />
                    }
                    <Card caption='Resulted Tickets'>
                        <Search
                            model='resultedTickets'
                            resultModel={openSlipsResult}
                            data={resultedTickets}
                            onSubmit={(data, qb) => { return apiResult('resultedTickets', Endpoints.SearchSportTickets, qb, 'fetching operator revenues reports', 'unable to fetch report'); }}
                        />
                    </Card>
                    <EventManagement />
                    {riskManagement}
                </CentrumApplicationContents>
            </React.Fragment>;
        </CentrumApplication>
    );
};


RiskManagementPage.defaultProps = {
    groups: [],
    group: null
};

RiskManagementPage.propTypes = {
    groups: PropTypes.array,
    group: PropTypes.object
};
