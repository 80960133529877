import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { emptyMargin, model } from './SportsbookMargins';
import { API, SportEndpoints } from '../../../../v2/Lib/Api/Api';
import { lang } from '../../../Centrum/Localization/lang';


export const RegionMargins = (props) => {
    const [sport, selectSport] = useState(null);
    const [regions, setRegions] = useState([]);

    const masterData = props.masterData;
    const [data, setData] = useState([]);
    const [updatedRows, setUpdatedRows] = useState({ regions: {} });

    const [showAll, setShowAll] = useState(null);

    const regionsRef = React.createRef();

    useEffect(() => {
        selectSport(props.sports);
        selectSport(props.sports[0]);
        setShowAll(true);
        setData(Object.values(props.margins.regions));
    }, []);

    useEffect(() => {
        if (showAll == null) return;
        var copy = { ...props.margins };
        var filtered = Object.values(copy.regions).filter(x => {
            if (showAll) return true;
            if (x.sport.id == sport.id) return true;
            return false;
        });

        setData(filtered);
    }, [showAll]);

    useEffect(() => {
        if (!sport) return;
        API.post(`${SportEndpoints.Regions}/${sport.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.name > b.name ? 1 : -1);
            result.result = result.result.filter((x) => x.name !== '');
            setRegions(result.result);
        }).catch((e) => {
            console.log(e);
        });

    }, [sport]);

    const context = {
        renderId: (row) => {
            return row.id;
        },
        renderName: (row) => {
            if (showAll) return `${row.sport.name}/${row.name}`;
            return row.name;
        },
        isChanged: (row) => {
            var key = `${row.sport.id}_${row.id}`;
            if (updatedRows.regions[key]) {
                return true;
            }
            return false;
        },
        addToChanges: (copy, key, payload, type = 'update') => {
            var copyOfUpdatedRows = { ...updatedRows };
            if (JSON.stringify(masterData.regions[key]) == JSON.stringify(copy.regions[key])) {
                delete copyOfUpdatedRows.regions[key];
            } else {
                copyOfUpdatedRows.regions[key] = { ...payload };
                copyOfUpdatedRows.regions[key]._type = type;
            }
            setUpdatedRows(copyOfUpdatedRows);
        },
        update: (row, value, field) => {
            var key = `${row.sport.id}_${row.id}`;
            const target = field.name;
            var copy = { ...props.margins };
            if (!copy.regions[key]) {
                copy.regions[key] = {
                    preMargin: 0,
                    preMin: 0,
                    preMax: 0,
                    liveMargin: 0,
                    liveMin: 0,
                    liveMax: 0
                };
            }
            copy.regions[key][target] = (value == true || value == false) ? value : parseFloat(value);
            props.setMargins(copy);

            context.addToChanges(copy, key, copy.regions[key]);
        }
    };

    const add = () => {
        var region = regions.find(x => x.id == parseInt(regionsRef.current.value));
        var copy = { ...props.margins };
        var key = `${sport.id}_${region.id}`;
        if (!copy.regions[key]) {
            copy.regions[key] = emptyMargin();
            copy.regions[key].sport = {
                id: sport.id,
                name: sport.name
            };
            copy.regions[key].id = region.id;
            copy.regions[key].name = region.name;
        }

        setData(Object.values(copy.regions));
        props.setMargins(copy);
    };

    const update = (row, empty) => {
        var payload = {
            regions: {

            }
        };

        var region = regions.find(x => x.id == parseInt(regionsRef.current.value));
        var key = `${sport.id}_${region.id}`;

        payload.regions[key] = empty ? emptyMargin() : row;
        return payload.regions[row.id];
    };


    const remove = (row) => {
        var copy = { ...props.margins };
        var key = `${row.sport.id}_${row.id}`;
        delete copy.regions[key];
        props.setMargins(copy);
        setData(Object.values(copy.regions));
        context.addToChanges(copy, key, update(row, true), 'delete');
    };

    return <div className='sport-margins'>
        <div className='content'>
            <div className='panel'>
                <div className='padding flex gap-5'>
                    <div className='flex gap-10'>
                        <select defaultValue={sport?.id} onChangeCapture={(e) => {
                            selectSport(props.sports.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {props.sports.sort((a, b) => a.weight > b.weight ? 1 : -1).map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        <select ref={regionsRef}>

                            {regions.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        <Button title='Add' onClick={() => add()} />
                    </div>
                    <div className='flex align-right'>
                        <Button title={showAll ? lang('Show %1 Only', [sport.name]) : 'Show All'} onClick={() => setShowAll(!showAll)} />
                    </div>
                </div>
            </div>
            <Table model={model}
                data={data}
                context={context}
                onRenderRow={(row, elements, className, context) => {
                    var c = context.isChanged(row) ? 'changed' : className;
                    return <tr className={c}>
                        {elements}
                    </tr>;
                }}
                buttons={(row) => {
                    return <div className='flex gap-10'>
                        <Button title='Remove' onClick={() => remove(row)} />
                    </div>;
                }}
            />
        </div>
        <div className='panel padding'>
            {Object.keys(updatedRows.regions).length > 0 ? <Button title={lang('Save %1 changes', [Object.keys(updatedRows.regions).length])} onClick={() => {
                props.save(updatedRows);
            }} /> : <Button title='Save' className='disabled' />}
        </div>
    </div>;
};

RegionMargins.propTypes = {
    sports: PropTypes.array,
    margins: PropTypes.object,
    masterData: PropTypes.object,
    setMargins: PropTypes.func,
    save: PropTypes.func
};