import './_snackBar.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../../Centrum/Localization/lang';
import { Button } from '../../../Controls/Buttons/Button';
import { application } from '../../../Application/Application';


export class SnackBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <div className='modal-window snackBar'>
                <React.Fragment>{<span>{lang(this.props.text)}</span>}<Button title='Dismiss' onClick={()=>application().snackbar.close()}/></React.Fragment>
            </div>
        </React.Fragment>;
    }
}

SnackBar.defaultProps = {
    text:''
};

SnackBar.propTypes = {
    text: PropTypes.string
};
