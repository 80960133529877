// IMPORT PACKAGE REFERENCES
import './_gameConfigurations.scss';

import React, { useEffect, useState } from 'react';
import { Card } from '../../../../v2/Components/Card/Card';
import { Form } from '../../../../v2/Components/Controls/Form/Form';
import { FormInput } from '../../../../v2/Components/Controls/Form/FormInput';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { API, CMSEndpoints } from '../../../../v2/Lib/Api/Api';
import { ProviderGameTypes } from '../../../Centrum/_models/ModelEnums';
import { Icon } from '../../../../v2/Components/Icon/Icon';


export const GameConfigurations = () => {
    const [games, setGames] = useState([]);
    const [providers, setProviders] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [filter, setFilter] = useState({ name: null, provider: 0, page: 0 });

    useEffect(() => {
        API.post(CMSEndpoints.GetGames, {}, 'fetching games').then((result) => {
            var p = {};
            p[0] = 'Please select';
            result.result.map(x => {
                p[x.providerId] = x.providerName;
            });
            setProviders(p);
            setGames(result.result);
            setFiltered(result.result);
        });
    }, []);

    const filterGames = () => {
        let copy = [...games];
        if (filter.offline) {
            copy = copy.filter(x => !x.online);
        } else if (filter.new && filter.popular) {
            copy = copy.filter(x => x.new && x.featured);
        } else if (filter.new) {
            copy = copy.filter(x => x.new);
        } else if (filter.popular) {
            copy = copy.filter(x => x.featured);
        } else if (filter.name && filter.name != '') {
            copy = copy.filter(x => x.name.toLowerCase().indexOf(filter.name) >= 0);
        } else if (filter.live) {
            copy = copy.filter(x => x.liveDealer);
        } else if (filter.provider) {
            copy = copy.filter(x => x.providerId == filter.provider);
        } else if (filter.offline) {
            copy = copy.filter(x => x.enabled == !filter.offline);
        }

        setFilter(filter);

        copy = copy.sort((b, a) => a.weight > b.weight ? 1 : -1);
        setFiltered(copy);
    };

    const previous = () => {
        filter.page--;
        if (filter.page < 0) filter.page = 0;
        filterGames();
    };

    const next = () => {
        filter.page++;
        if (filter.page >= Math.ceil(filtered.length / 200)) {
            filter.page = Math.ceil(filtered.length / 200) - 1;
        }
        filterGames();
    };

    const toggleNew = (game) => {
        let copy = [...filtered];
        copy.find(x => x.gameId == game.gameId).new = !game.new;

        setFiltered(copy);
    };

    const toggleFeatured = (game) => {
        let copy = [...filtered];
        copy.find(x => x.gameId == game.gameId).featured = !game.featured;

        API.post(CMSEndpoints.SaveGame, game, 'saving game').then(() => {
        });

        setFiltered(copy);
    };

    const toggleEnableDisable = (game) => {
        let copy = [...filtered];
        copy.find(x => x.gameId == game.gameId).enabled = !game.enabled;


        API.post(CMSEndpoints.SaveGame, game, 'saving game').then(() => {
        });


        setFiltered(copy);
    };

    const selectGame = (game) => {
        console.log(game);
    };


    let pages = [];
    for (var i = 0; i < Math.ceil(filtered.length / 200); i++) {
        pages.push(<option value={i} key={i}>{`Page ${i + 1}`}</option>);
    }

    return <div className='flex vertical cms-games'>
        <Card title='Filters'>
            <Form>
                <div className='row'>
                    <FormInput title='Name' onChange={(v) => {
                        let copy = { ...filter };
                        copy.name = v.toLowerCase();
                        copy.page = 0;
                        setFilter(copy);
                    }} />
                    <FormInput title='Providers' type={InputTypes.select}
                        values={providers}
                        onChange={(v) => {
                            let copy = { ...filter };
                            copy.page = 0;
                            copy.provider = parseFloat(v);
                            setFilter(copy);
                        }} />
                    <FormInput title='Live Dealer' type={InputTypes.bool} onChange={(v) => {
                        let copy = { ...filter };
                        copy.page = 0;
                        copy.live = v;
                        setFilter(copy);
                    }} />
                    <FormInput title='Show New' type={InputTypes.bool} onChange={(v) => {
                        let copy = { ...filter };
                        copy.page = 0;
                        copy.new = v;
                        setFilter(copy);
                    }} />
                    <FormInput title='Show Popular' type={InputTypes.bool} onChange={(v) => {
                        let copy = { ...filter };
                        copy.page = 0;
                        copy.popular = v;
                        setFilter(copy);
                    }} />
                    <FormInput title='Show Offline' type={InputTypes.bool} onChange={(v) => {
                        let copy = { ...filter };
                        copy.page = 0;
                        copy.offline = v;
                        setFilter(copy);
                    }} />
                </div>
                <Button title='Filter' onClick={() => filterGames()} />
            </Form>
        </Card>
        <div className='game-list'>
            {filtered.slice(filter.page * 200, (filter.page * 200) + 200).map((game) => {
                var cdnSource = 'http://d.tentangle.com/game/';

                var imageName = cdnSource + game.imageName + '?&' + Date.now();
                return <div className={game.enabled ? '' : 'game-offline'} key={game.gameId}>
                    <header className='flex gap-5'>
                        <Icon icon={game.enabled ? 'eye-slash' : 'eye'} onClick={() => toggleEnableDisable(game)} />
                        {game.providerName}
                    </header>
                    <img onClick={() => selectGame(game)} src={imageName} />
                    <span>{ProviderGameTypes[game.type]}</span>
                    <span>{game.name}</span>
                    <div className='flex gap-5 buttons'>
                        <Button title='New Game' icon='map-marker' className={game.new ? 'selected' : ''} onClick={() => toggleNew(game)} />
                        <Button title='Popular' icon='heart' className={game.featured ? 'selected' : ''} onClick={() => toggleFeatured(game)} />
                    </div>
                </div>;
            })}
        </div>
        <div className='flex padding pagination'>
            <Button title='Previous Page' onClick={() => previous()} />
            <Button title='Next Page' onClick={() => next()} />
            <select value={filter.page} onChangeCapture={(e) => {
                var p = parseInt(e.target.value);
                filter.page = p;
                filterGames();
            }}>{pages}</select>
            <label>Total {Math.ceil(filtered.length / 200)} Pages</label>
            <label>{filtered.length} Games</label>
        </div>
    </div>;
};