import React from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../Application/Application';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { currency, lang } from '../../Centrum/Localization/lang';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes } from '../../Centrum/_models/ModelEnums';
import { gotoProfile } from '../../Centrum/_models/Transactions';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';
import { SportTicketDetails } from '../../Sports/SportTicketDetails';
import { HorseTicket } from '../../../v2/Components/Sports/Tickets/HorseTicket/HorseTicket';
import { RacingTicketTypes } from '../../../v2/Components/Sports/Tickets/BettingEnums';
import { isMobile } from '../../Centrum/_models/PlayerModels';



const BettingFlags = {
    None: 0x00000001,
    Single: 0x00000002,
    Combination: 0x00000004,
    System: 0x00000008,
    Forecast: 0x00000010,
    Tricast: 0x00000020,
    StartingPrice: 0x00000040,
    Horse: 0x00000080,
    Greyhound: 0x00000100,
    Harness: 0x00000200,
    Virtual: 0x00000400,
    ForecastRFC: 0x00040000,
    ForecastCFC: 0x00080000,
    TricastCTC: 0x00100000,
    EachWay: 0x00200000,
    SingleEvent: 0x00000800,
    Prematch: 0x00001000,
    Live: 0x00002000,
    PrematchAndLive: 0x00004000,
    BookedBet: 0x01000000
};


export const showSportDetails = (eventResults, slips, history, system, manualSettlement, callback) => {
    var results = {};

    Object.keys(eventResults.result).map(eventId => {
        var details = eventResults.result[eventId];
        results[eventId] = JSON.parse(details);
    });

    if (!callback) {
        callback = (component) => {
            application().modal.open(<Dialog title={'Bets'} className='large no-padding'>
                {component}
            </Dialog >);
        };
    }
    callback(<SportTicketDetails data={slips} scores={results} history={history} manualSettlement={manualSettlement} displayHistory={!system} />);
};

const renderBetSlipStatus = (row, isExporting) => {
    var manual = row.Flags & 0x02000000;
    if (isExporting) {
        return manual > 0 ? 'manual' : SportsTicketStatus[row.Status];
    }
    return <div className='centrum-flex vertical'>
        {manual > 0 && <label>{lang('Manual')}</label>}<span>{SportsTicketStatus[row.Status]}</span>
    </div>;
};


const onRenderRow = (rowObject) => {
    if (rowObject.data.Status == 2) {
        if (rowObject.data.TicketBonusConsumed) {
            rowObject.className = 'row-bonus';
        } else {
            rowObject.className = 'row-lost';
        }

    }
    if (rowObject.data.Status == 1) {
        rowObject.className = 'row-win';
    }
    if (rowObject.data.Status == 3) {
        rowObject.className = 'row-cashout';
    }

    if (rowObject.data.Status == 5) {
        rowObject.className = 'row-cashback';
    }


    return rowObject;
};


export const getTicketDetails = (id, system, callback) => {
    application().resolve(null, `${Endpoints.TicketDetails}/${id}/${system}`, {}, !callback ? 'loading bets' : null, !callback ? 'unable to load bets' : null).then((result) => {

        var eventIds = {};
        var slips = result.result.details;
        var selections = result.result.selections;
        slips.map(x => {
            var bets = JSON.parse(x.bets);
            x.bets = bets;
            bets.map(bet => {
                if (selections) {
                    var selectionResult = selections.find(s => s.selectionId == bet.Id);
                    if (selectionResult) {
                        bet.SelectionStatus = selectionResult.status;
                    }
                }
            });
            x.bets.map(bet => {
                eventIds[bet.EventExternalId] = true;
            });
        });

        application().resolve(null, Endpoints.SportEventResults, Object.keys(eventIds), !callback ? 'loading event results' : null).then(eventResults => {
            callback(eventResults, slips, result.result.history.sort((a, b) => new Date(a.entryDate) - new Date(b.entryDate)), system);
        }).catch(() => {
            callback({ result: [] }, slips, result.result.history.sort((a, b) => new Date(a.entryDate) - new Date(b.entryDate)), system);
        });
    });
};

export const openBetModal = (id, system, manualSettlement = false, callback) => {
    getTicketDetails(id, system, (eventResults, slips, history) => {
        showSportDetails(eventResults, slips, history, system, manualSettlement, callback);
    });
};


export const openHorseBetModal = (row, id, type) => {
    var bets = JSON.parse(row.Bets);
    var summary = bets.Summary;
    var eventId = summary.split('|')[0];

    application().resolve(null, `${Endpoints.HorseTicketDetails}/${id}/${type}/${eventId}`, {}, 'loading bets', 'unable to load bets').then((result) => {
        if (result.result.selections) {
            var selections = result.result.selections.result;
            application().modal.open(<Dialog title={'Bets'} className='large no-padding'>
                {showHorseGreyhoundDetails(row, selections)}
            </Dialog >);
        }
    });
};


export const openBetsOfTicket = (val, row) => {
    if (row.Product == 0) {
        return <span onClick={() => openBetModal(row.ExternalTicketId, row.TicketType == 2)}><a href='javascript:void()'>{row.ExternalTicketId}</a></span>;
    }
    if (row.Product == 1) {
        return <span onClick={() => application().modal.open(<HorseTicket ticket={row} onClose={() => application().modal.close()} />)}><a href='javascript:void()'>{row.ExternalTicketId}</a></span>;
    }
};

const renderRunners = (event) => {
    return <React.Fragment>
        <div className='centrum-flex vertical runner'>{
            event.runners.sort((a, b) => {
                if (a.result && b.result) {
                    return a.result.place - b.result.place;
                }
                return a.number - b.number;
            }).map((x, index) => {
                return <div key={index} className='centrum-flex runners'><label>{x.number.toString().padStart(2, '0')}</label><span>{x.name}</span></div>;
            })
        }
        </div>
    </React.Fragment>;
};

export const ResultPrices = (props) => {
    if (!props.event.resultPrices) return;
    var classList = ['centrum-flex'];
    if (props.className) classList.push(props.className);
    return <div className={classList.join(' ')}>
        {props.event.resultPrices.map((p, index) => {
            return <div key={index} className='centrum-flex'>
                <span className='medium'>{lang(p.type == 0 ? 'Forecast' : 'Tricast')}</span>
                <span className='medium'>{p.price.toFixed(2)}</span>
                <div className='centrum-flex'>{p.betPlaces.sort((a, b) => { return a.place - b.place; }).map(betPlace => {
                    return <i className='indicator' key={betPlace.runnerId}>{props.event.runners.find(r => r.id == betPlace.runnerId).number}</i>;
                })}
                </div>
            </div>;
        })}
        <div className='centrum-flex vertical'>
            <span className='margin-right medium'>{lang('Off Time')}</span>
            {props.event.offTime && <span className='medium'>{props.event.offTime}</span>}
        </div>
    </div>;
};

ResultPrices.propTypes = {
    className: PropTypes.string,
    event: PropTypes.object
};

const showHorseGreyhoundDetails = (row, eventDetails) => {
    try {
        var data = JSON.parse(row.Bets);
        var eventData = data.Summary.split('|');
        var selections = data.Selection;
        //var eventKey = eventData[0];
        var countryCode = eventData[1];
        var venue = eventData[2];
        var metres = eventData[3];
        var distance = eventData[4];
        var date = eventData[5];


        var single = row.Flags & BettingFlags.Single;
        var forecast = row.Flags & BettingFlags.Forecast;
        var tricast = row.Flags & BettingFlags.Tricast;
        var eachWay = row.Flags & BettingFlags.EachWay;
        var bookedBet = row.Flags & BettingFlags.BookedBet;

        var type = null;
        if (single) type = <span>{lang('Single')} {eachWay ? 'Eachway' : ''}</span>;
        if (forecast) type = <span>{lang('Forecast')}</span>;
        if (tricast) type = <span>{lang('Tricast')}</span>;
        if (bookedBet) type = <span>{lang('Manual')}</span>;


        var selectionData = selections[0];


        var event = eventDetails ? eventDetails.find(x => x.eventKey == parseFloat(eventData[0])) : null;

        return <div className=''>
            <div className='centrum-flex vertical'>
                <div className='centrum-flex'>
                    <div className='group'>
                        <label className='medium'>{lang('Event')}</label>
                        <div className='group'>
                            <span className='medium'>{new Date(date).toLocaleString('en-Gb', { hour12: false }).replace(' 24:', ' 00:').replace(',', ' ')}</span>
                            {event != null && event.offTime && <div className='centrum-flex'><label>{lang('Off Time')}</label><span className='medium'>{event.offTime}</span></div>}
                            <span className='medium'>{countryCode} {venue}</span>
                            <span className='medium'>{distance} / {metres}m</span>
                        </div>
                    </div>
                    <div className='group'>
                        <label className='medium'>{lang('Type')}</label>
                        <span className='medium'>{type}</span>
                    </div>
                </div>
                <div className='centrum-flex'>

                    <div className='group'>
                        <label className='medium'>{lang('Bet')}</label>
                        {
                            (selections.length == 1) && <div className='group'>
                                <span className='medium'>{selectionData.Number}</span>
                                <span className='medium'>{selectionData.Name}</span>
                                <span className='medium'>{selectionData.PriceDecimal.toFixed(2)}</span>
                            </div>
                        }
                        {
                            (selections.length > 1) && selections.map((r, index) => {
                                return <div key={index} className='group'>
                                    <span className='medium'>{r.Number}</span>
                                    <span className='medium'>{r.Name}</span>
                                </div>;
                            })
                        }
                    </div>
                    <div className='group'>
                        <label className='medium'>{lang('Result')}</label>
                        {event && <ResultPrices event={event} className='vertical' />}
                    </div>
                    <div className='centrum-flex'>
                        <div className='group'>
                            <label className='medium'>{lang('Each Way')}:</label><span className='color-secondary margin-left medium'>{event.eachWayFraction}</span>
                        </div>
                        <div className='group'>
                            <label>{lang('Runners')}:</label><span className='secondary margin-left medium'>{event.runners.filter(x => x.status != 'N').length}</span>
                        </div>
                    </div>

                </div>
            </div>
            <table className='centrum-table bet-slips horse-greyhound'>
                <thead>
                    <tr>
                        <th>{lang('Runners')}</th>
                        <th>{lang('Non Runners')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{renderRunners(event)}</td>
                        <td>
                            <div className='centrum-flex vertical'>
                                {event.runners.filter(x => x.status == 'N').map(x => { return <span key={x.id} className='secondary margin-right sm all'>{x.number}</span>; })}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
    } catch (err) {
        console.log(err);
    }
};



export const openSlipsResult = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id',
            formatter: (val, row) => {
                row.Product = 0;
                return openBetsOfTicket(val, row);
            }
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Updated',
            display: 'Update',
            type: InputTypes.dateTime
        },
        {
            name: 'Operator',
            display: 'Operator'
        },
        {
            name: 'RetailName',
            display: 'Retail'
        },
        {
            name: 'Group',
            display: 'Group'
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Username',
            display: 'Username',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return gotoProfile(row.PlayerId, row.Username);
            }
        },
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            display: 'Status',
            formatter: (val, row, isFooter, isExporting) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(row, isExporting);
            }
        },
        {
            name: 'Selections',
            display: 'Bet / Win',
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) return;
                var result = `${row.Selections}/${row.WinSelection}`;
                if (isExporting) return result;
                return result;
            }
        },
        {
            name: 'Bet',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Odds',
            display: 'Odds',
            type: InputTypes.number
        },
        {
            name: 'PossibleWin',
            display: 'Possible Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'TicketBonusName',
            display: 'Ticket Bonus Name'
        },
        {
            name: 'TicketBonusPercentage',
            display: 'Bonus %',
            type: InputTypes.number,
            formatter: (val) => {
                if (!val) return;
                return `%${val}`;
            }
        },
        {
            name: 'TicketBonusAmount',
            display: 'Bonus Amount',
            type: InputTypes.number,
            formatter: (val, row) => {
                return currency((row.TicketBonusLimit > 0 && row.TicketBonusLimit < row.TicketBonusAmount) ? row.TicketBonusLimit : row.TicketBonusAmount, 2, row.Currency);
            }
        },
        {
            name: 'Bonus',
            type: InputTypes.bool
        },
        {
            name: 'TaxAmount',
            display: 'Tax',
            formatter: (val, row) => {
                if (row.TaxAmount > 0) return;
                return currency(row.TaxAmount, 2, row.Currency);
            }
        }
    ],
    onRenderRow: onRenderRow
};

export const betSlipResults = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id',
            formatter: (val, row) => {
                row.Product = 0;
                return openBetsOfTicket(val, row);
            }
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Updated',
            display: 'Update',
            type: InputTypes.dateTime
        },
        {
            name: 'Operator',
            display: 'Operator'
        },
        {
            name: 'RetailName',
            display: 'Retail'
        },
        {
            name: 'Group',
            display: 'Group'
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Agent',
            display: 'Device',
            formatter: (val, row, isFooter) => {
                if (isFooter) return;
                return isMobile(row.Agent);
            }
        },
        {
            name: 'Username',
            display: 'Username',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return gotoProfile(row.PlayerId, row.Username);
            }
        },
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            formatter: (val, row) => {
                var type = SportsTicketTypes[row.TicketType];
                if (row.TicketType == 2) {
                    type += ' ' + row.System;
                }
                return type;
            }
        },
        {
            name: 'Status',
            display: 'Status',
            formatter: (val, row, isFooter, isExporting) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(row, isExporting);
            }
        },
        {
            name: 'Selections',
            display: 'Bet / Win',
            formatter: (val, row, isFooter) => {
                if (isFooter) return;
                var result = `${row.Selections}/${row.WinSelection}`;
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    result = `${row.ChildTickets} / ${row.WinTickets}`;
                }
                return result;
            }
        },
        {
            name: 'BonusWallet',
            display: 'Bonus Wallet'
        },
        {
            name: 'BonusName',
            display: 'Bonus Name'
        },
        {
            name: 'Bet',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val.toString();
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Odds',
            display: 'Odds',
            type: InputTypes.number
        },
        {
            name: 'PossibleWin',
            display: 'Possible Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val.toString();
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'TicketBonusName',
            display: 'Ticket Bonus Name'
        },
        {
            name: 'TicketBonusPercentage',
            display: 'Ticket Bonus %',
            type: InputTypes.number,
            formatter: (val) => {
                if (!val) return;
                return `%${val}`;
            }
        },
        {
            name: 'TicketBonusAmount',
            display: 'Ticket Bonus Amount',
            type: InputTypes.number,
            formatter: (val, row) => {
                return currency((row.TicketBonusLimit > 0 && row.TicketBonusLimit < row.TicketBonusAmount) ? row.TicketBonusLimit : row.TicketBonusAmount, 2, row.Currency);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val?.toString();
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        }, 
        {
            name: 'Tax',
            display: 'Tax',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val?.toString();
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val.toString();
                    }
                } else {
                    if (row.Status !== 2) return;
                    var result = row.Bet - row.Win;
                    if (isExporting) return result;
                    return currency(result, 2, row.Currency);
                }
            }
        },
        {
            name: 'TaxAmount',
            display: 'Tax',
            formatter: (val, row, isFooter, isExporting) => {
                if (row.TaxAmount > 0) return;
                if (isFooter || isExporting) return;
                return currency(row.TaxAmount, 2, row.Currency);
            }
        }
    ],
    onRenderRow: onRenderRow
};



export const playersWithoutBetsResult = {
    fields: [
        {
            name: 'Created',
            display: 'Registration Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Operator',
            display: 'Operator'
        },
        {
            name: 'RetailName',
            display: 'Retail'
        },
        {
            name: 'Group',
            display: 'Group'
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Agent',
            display: 'Device',
            formatter: (val, row, isFooter) => {
                if (isFooter) return;
                return isMobile(row.Agent);
            }
        },
        {
            name: 'Username',
            display: 'Username',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return gotoProfile(row.PlayerId, row.Username);
            }
        },{
            name: 'Promotion',
            display: 'Promotion'
        },
        {
            name: 'Balance',
            display: 'Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) return;
                return currency(row.Balance, 2, row.Currency);
            }
        },
        {
            name: 'BonusBalance',
            display: 'Bonus Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) return;
                return currency(row.BonusBalance, 2, row.Currency);
            }
        }
    ]
};

export const manualBetSlipResults = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id',
            formatter: (val, row) => {
                row.Product = 0;
                return openBetsOfTicket(val, row);
            }
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'ManualCloseDate',
            display: 'Manual Close Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Admin'
        },
        {
            name: 'RetailName',
            display: 'Retail'
        },
        {
            name: 'Group',
            display: 'Group'
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Username',
            display: 'Username',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return gotoProfile(row.PlayerId, row.Username);
            }
        },
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            display: 'Status',
            formatter: (val, row, isFooter, isExporting) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(row, isExporting);
            }
        },
        {
            name: 'Selections',
            display: 'Bet / Win',
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) return;
                var result = `${row.Selections}/${row.WinSelection}`;
                if (isExporting) return result;
                return result;
            }
        },
        {
            name: 'BonusWallet',
            display: 'Bonus Wallet'
        },
        {
            name: 'BonusName',
            display: 'Bonus Name'
        },
        {
            name: 'Bet',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Odds',
            display: 'Odds',
            type: InputTypes.number
        },
        {
            name: 'PossibleWin',
            display: 'Possible Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'TicketBonusName',
            display: 'Ticket Bonus Name'
        },
        {
            name: 'TicketBonusPercentage',
            display: 'Ticket Bonus %',
            type: InputTypes.number,
            formatter: (val) => {
                if (!val) return;
                return `%${val}`;
            }
        },
        {
            name: 'TicketBonusAmount',
            display: 'Ticket Bonus Amount',
            type: InputTypes.number,
            formatter: (val, row) => {
                return currency((row.TicketBonusLimit > 0 && row.TicketBonusLimit < row.TicketBonusAmount) ? row.TicketBonusLimit : row.TicketBonusAmount, 2, row.Currency);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (row.Status !== 2) return;
                    var result = row.Bet - row.Win;
                    if (isExporting) return result;
                    return currency(result, 2, row.Currency);
                }
            }
        }
    ],
    onRenderRow: onRenderRow
};

export const lastRemainingResults = {
    fields: [
        {
            name: 'Operator',
            display: 'Operator'
        },
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id',
            formatter: (val, row) => {
                row.Product = 0;
                return openBetsOfTicket(val, row);
            }
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Updated',
            display: 'Update',
            type: InputTypes.dateTime
        },
        {
            name: 'RetailName',
            display: 'Retail'
        },
        {
            name: 'Group',
            display: 'Group'
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Username',
            display: 'Username',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return gotoProfile(row.PlayerId, row.Username);
            }
        },
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes
        },
        {
            name: 'Selections',
            display: 'Selections'
        },
        {
            name: 'Bet',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Odds',
            display: 'Odds',
            type: InputTypes.number
        },
        {
            name: 'PossibleWin',
            display: 'Possible Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'TicketBonusName',
            display: 'Ticket Bonus Name'
        },
        {
            name: 'TicketBonusPercentage',
            display: 'Bonus %',
            type: InputTypes.number,
            formatter: (val) => {
                if (!val) return;
                return `%${val}`;
            }
        },
        {
            name: 'TicketBonusAmount',
            display: 'Bonus Amount',
            type: InputTypes.number,
            formatter: (val, row) => {
                return currency((row.TicketBonusLimit > 0 && row.TicketBonusLimit < row.TicketBonusAmount) ? row.TicketBonusLimit : row.TicketBonusAmount, 2, row.Currency);
            }
        },
        {
            name: 'Bonus',
            type: InputTypes.bool
        }
    ],
    onRenderRow: onRenderRow
};




export const horseBetSlipResults = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id',
            formatter: (val, row) => {
                row.Product = 1;
                return openBetsOfTicket(val, row);
            }
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Updated',
            display: 'Update',
            type: InputTypes.dateTime
        },
        {
            name: 'Operator',
            display: 'Operator'
        },
        {
            name: 'RetailName',
            display: 'Retail'
        },
        {
            name: 'Group',
            display: 'Group'
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Username',
            display: 'Username',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return gotoProfile(row.PlayerId, row.Username);
            }
        },
        {
            name: 'Type',
            display: 'Betting Type',
            formatter: (val, row) => {
                if (row.System) return row.System;
                var single = row.Flags & BettingFlags.Single;
                var forecast = row.Flags & BettingFlags.Forecast;
                var tricast = row.Flags & BettingFlags.Tricast;
                var eachWay = row.Flags & BettingFlags.EachWay;


                if ((row.System == '' || !row.System) && row.Flags == 1) {
                    var result = RacingTicketTypes[row.TicketType];
                    return <div className='gap-5'>
                        <span>{lang(result)}</span>
                    </div>;
                }
                if (single) {
                    return <div className='gap-5'>
                        <span>{lang('Single')}</span>
                        {eachWay > 0 && <span>{lang('Eachway')}</span>}
                    </div>;
                }
                if (forecast) {
                    return <div className='gap-5'>
                        <span>{lang('Forecast')}</span>
                    </div>;
                }
                if (tricast) {
                    return <div className='gap-5'>
                        <span>{lang('Tricast')}</span>
                    </div>;
                }
            }
        },
        {
            name: 'Status',
            display: 'Status',
            formatter: (val, row, isFooter, isExporting) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(row, isExporting);
            }
        },
        {
            name: 'Events',
            display: 'Events',
            formatter: (val, row, isFooter) => {
                if (isFooter) return;
                try {
                    var bets = JSON.parse(row.Bets);
                    var summary = bets.Summary.split('~');
                    var events = {};
                    summary.map(x => {
                        var event = x.split('|');
                        var eventId = event[0];
                        events[eventId] = `${eventId} ${event[2]} ${event[3]}`;
                    });

                    return <div className='flex vertical'>
                        {
                            Object.values(events).map((x, index) => <div key={index}>{x}</div>)
                        }
                    </div>;
                } catch (err) {
                    //
                }
            }
        },
        {
            name: 'BonusName',
            display: 'Bonus Name'
        },
        {
            name: 'BonusWallet',
            display: 'Bonus Wallet',
            type: InputTypes.bool
        },
        {
            name: 'Bet',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Odds',
            display: 'Odds',
            type: InputTypes.number
        },
        {
            name: 'PossibleWin',
            display: 'Possible Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val;
                    }
                } else {
                    if (row.Status !== 2) return;
                    var result = row.Bet - row.Win;
                    if (isExporting) return result;
                    return currency(result, 2, row.Currency);
                }
            }
        }
    ],
    onRenderRow: (rowObject) => {
        if (rowObject.data.Status == 1) {
            rowObject.className = 'player-win';
        }
        if (rowObject.data.Status == 2) {
            rowObject.className = 'player-lost';
        }
        if (rowObject.data.Status == 5) {
            rowObject.className = 'player-cashback';
        }
        return rowObject;
    }
};