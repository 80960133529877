import './_affiliatePage.scss';

import React, { useState } from 'react';


import { getActions, ActionTypes } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { Search } from '../../Centrum/Search/Search';
import { Affiliates } from './Affiliates';
import { AffiliateGroups } from './AffiliateGroups';
import { Endpoints, application } from '../../Application/Application';
import { Card } from '../../../v2/Components/Card/Card';

const applicationActionType = ActionTypes.Affiliates;
const applicationActions = getActions(applicationActionType);


export const AffiliatePage = () => {
    const [settlements, setSettlments] = useState([]);

    const searchAffiliateSettlements = (data) => {
        application().resolve(null, Endpoints.SearchAffiliateSettlements, data, 'search settlements', 'unable to search settlements').then((result) => {
            setSettlments(result);
        });
    };

    return (
        <CentrumApplication
            caption='Affiliates' icon='money'
            type={applicationActionType}
        >
            <ApplicationContext.Consumer>
                {
                    () => {
                        return <React.Fragment>
                            <CentrumApplicationActions actions={applicationActions} />
                            <CentrumApplicationContents>
                                <AffiliateGroups />
                                <Affiliates />
                                <Card caption='Search'>
                                    <Search
                                        key='transactions'
                                        model='searchAffiliateSettlements'
                                        resultModel='affiliateSettlements'
                                        data={settlements}
                                        onSubmit={(data, qb) => { searchAffiliateSettlements(qb); }}
                                    />
                                </Card>
                            </CentrumApplicationContents>
                        </React.Fragment>;
                    }
                }
            </ApplicationContext.Consumer>
        </CentrumApplication>
    );
};
