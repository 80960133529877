import './_sportsbooklimits.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../../Application/Application';
import { Card } from '../../../../Centrum/Card/Card';

import { Button } from '../../../../Controls/Buttons/Button';
import { Dialog } from '../../../../Modal/Modals/Dialog/Dialog';
import { lang } from '../../../../Centrum/Localization/lang';
import { EditableTable } from '../../../../Controls/Table/Editable/EditableTable';
import { Icon } from '../../../../../v2/Components/Icon/Icon';


export const GroupSelector = (row, context) => {
    return <select style={{ minWidth: '200px' }}
        defaultValue={row.group}
        onChangeCapture={(e) => {
            const value = parseInt(e.target.value);
            if (isNaN(value)) {
                row.gorup = null;
            }
            row.group = value;
        }}>
        <option></option>
        {context.groups.map((g, index) => {
            return <option key={index} value={g.Id}>{g.Name}</option>;
        })}
    </select>;
};

export const CategorySelector = (row, context) => {
    const categories = context.categories;
    if (!categories) return;
    const style = { minWidth: '120px', maxWidth: '120px' };

    return <select style={style}
        onChangeCapture={(e) => {
            context.onCategoryChanged(row, parseInt(e.target.value));
        }}
        defaultValue={row.sportId}>
        {categories.map((c, index) => {
            return <option key={index} value={c.id}>{c.name}</option>;
        })}
    </select>;
};

export const RegionSelector = (row, context) => {
    const [regions, selectRegions] = useState(null);
    const onRegionSelected = (result) => {
        selectRegions(result.sort((a, b) => a.name > b.name ? 1 : -1));
    };

    const style = { minWidth: '120px', maxWidth: '120px' };

    if (!regions || row.regionId == -1) return <select style={style}
        onClick={() => context.onEditRegion(row, onRegionSelected)}><option>{row.regionId == -1 ? 'Select Region' : row.region}</option></select>;

    return <select style={style}
        defaultValue={row.regionId} onChangeCapture={(e) => {
            row.regionId = parseInt(e.target.value);
            row.region = regions.find(x => x.id == row.regionId).name;
            context.onRegionChanged(row);
        }}>
        {regions.map(region => {
            return <option key={region.id} value={region.id}>{region.name}</option>;
        })}
    </select>;
};


export const LeagueSelector = (row, context) => {
    const [leagues, selectLeagues] = useState(null);
    const onLeagueSelected = (result) => {
        selectLeagues(result);
    };

    const style = { minWidth: '200px', maxWidth: '200px' };


    if (!leagues || row.id == -1) return <select style={style}
        onClick={() => {
            context.onEditLeague(row, onLeagueSelected);
        }}>
        <option>{row.id == -1 ? 'Select League' : row.name}</option>
    </select>;

    return <select style={style}
        defaultValue={row.id} onChangeCapture={(e) => {
            row.id = parseInt(e.target.value);
            row.name = leagues.find(x => x.id == row.id).name;
            context.onLeagueSelected(row);
        }}>
        {leagues.map(league => {
            return <option key={league.id} value={league.id}>{league.name}</option>;
        })}
    </select>;
};


const leagueGroupModel = {
    fields: [
        {
            name: 'name',
            display: 'Name',
        },
        {
            name: 'minimumEvent',
            type: 'string',
            display: 'Minimum Event',
            editable: true
        },
        {
            name: 'marketCap',
            type: 'string',
            display: 'Market Cap',
            editable: true
        },
        {
            name: 'maximumStake',
            type: 'number',
            display: 'Stake Volume',
            editable: true
        },
        {
            name: 'maximumStakeLive',
            type: 'number',
            display: 'Stake Live Volume',
            editable: true
        },
        {
            name: 'maximumLiability',
            type: 'number',
            display: 'Liability',
            editable: true
        },
        {
            name: 'maximumLiabilityLive',
            type: 'number',
            display: 'Live Liability',
            editable: true
        },
        {
            name: 'offer',
            type: 'bool',
            display: 'Offer',
            editable: true
        }
    ]
};




const leagueLimitsModel = {
    fields: [
        {
            name: 'sport',
            display: 'Sport'
        },
        {
            name: 'region',
            display: 'Region'
        },
        {
            name: 'name',
            display: 'League',
            formatter: (row, context, readonly) => {
                if (readonly) return row.League;
                return LeagueSelector(row, context);
            }
        },
        {
            name: 'minimumEvent',
            type: 'string',
            display: 'Minimum Event',
            editable: true
        },
        {
            name: 'marketCap',
            type: 'number',
            display: 'Market Cap',
            editable: true
        },
        {
            name: 'maximumStake',
            type: 'number',
            display: 'Stake Volume',
            editable: true
        },
        {
            name: 'maximumStakeLive',
            type: 'number',
            display: 'Stake Live Volume',
            editable: true
        },
        {
            name: 'maximumLiability',
            type: 'number',
            display: 'Liability',
            editable: true
        },
        {
            name: 'maximumLiabilityLive',
            type: 'number',
            display: 'Live Liability',
            editable: true
        },
        {
            name: 'offer',
            type: 'bool',
            display: 'Offer',
            editable: true
        }
    ]
};


export const SportLeagueLimits = (props) => {
    const [configurationEnabled, setConfigurationEnabled] = useState(true);
    const [groups, setGroups] = useState(null);
    const [categories, setCategories] = useState(null);
    const [category, selectCategory] = useState(null);
    const [region, selectRegion] = useState(null);
    const [regions, setRegions] = useState(null);
    const [leagueLimits, setLeagueLimits] = useState([]);
    const [selectedLeague, selectLeague] = useState(null);
    const [selectedLeagueGroup, selectLeagueGroup] = useState(null);
    const [newKey, setKey] = useState(Date.now());
    const [leagueGroupKey, setLeagueGroupKey] = useState(Date.now());
    var list = null;

    const context = {
        categories: categories,
        groups: groups,
        onEditLeague: (row, handler) => {
            application().resolve(null, `${Endpoints.SportLeagues}/${row.sportId}/${row.regionId}`).then((leagues) => {
                var sorted = leagues.result.sort((a, b) => a.name > b.name ? 1 : -1);
                row.id = sorted[0].id;
                row.name = sorted[0].name;
                handler(sorted);
            });
        },
        onLeagueSelected: (row) => {
            console.log(row);
        }
    };

    useEffect(() => {
        const riskType = window.user.features.configuration?.risk ? JSON.parse(window.user.features.configuration.risk).type : { type: 0 };
        if (riskType.type == 1) {
            setConfigurationEnabled(false);
            return;
        }
        application().resolve(null, Endpoints.GetPlayerGroups).then((playerGroups) => {
            setGroups(playerGroups.result);

            application().resolve(null, Endpoints.SportCategories).then((result) => {
                const categoryList = result.result.sort((a, b) => (a.id > b.id) ? 1 : -1);
                application().resolve(null, Endpoints.GetLeagueLimits).then((result) => {
                    try {
                        var limitsConverted = result.result;
                        try {
                            limitsConverted = JSON.parse(limitsConverted);
                        } catch (err) {
                            limitsConverted = null;
                        }
                        var limits = limitsConverted ? limitsConverted : {};
                        var sorted = [];
                        Object.keys(limits).map((id, index) => {
                            const league = limits[id];
                            var limit = {
                                id: id,
                                index: index,
                                name: league.name,
                                sport: league.sport,
                                sportId: league.sportId,
                                region: league.region,
                                regionId: league.regionId,
                                minimumEvent: league.general.minimumEvent,
                                marketCap: league.general.marketCap,
                                maximumStake: league.general.stake.prematch,
                                maximumStakeLive: league.general.stake.live,
                                maximumLiability: league.general.liability.prematch,
                                maximumLiabilityLive: league.general.liability.live,
                                offer: league.general.offer,
                                general: league.general,
                                groups: league.groups
                            };
                            sorted.push(limit);
                        });

                        setCategories(categoryList);

                        if (props.league) {
                            var empty = props.league;
                            empty.index = Date.now();
                            var newRow = Object.assign({}, sorted.length > 1 ? leagueLimits[sorted.length - 1] : empty);
                            newRow.index = Date.now();
                            sorted.push(newRow);
                        }



                        setLeagueLimits(sorted);
                    } catch (err) {
                        console.log('league limits', err);
                    }
                });
            });
        });
    }, []);

    useEffect(() => {
        if (!selectedLeague) return;
        var leagueGroup = selectedLeague.groups;
        if (!leagueGroup) {
            leagueGroup = [];
            groups.map(x => {
                leagueGroup.push({
                    id: x.Id,
                    name: x.Name,
                    minimumEvent: 0,
                    marketCap: 0,
                    maximumStake: 0,
                    maximumStakeLive: 0,
                    maximumLiability: 0,
                    maximumLiabilityLive: 0,
                    offer: false
                });
            });
        } else {
            var newList = [];
            groups.map(x => {
                if (leagueGroup.hasOwnProperty(x.Id)) {
                    newList.push({
                        id: x.Id,
                        name: x.Name,
                        minimumEvent: leagueGroup[x.Id].minimumEvent,
                        marketCap: leagueGroup[x.Id].marketCap,
                        maximumStake: leagueGroup[x.Id].stake.prematch,
                        maximumStakeLive: leagueGroup[x.Id].stake.live,
                        maximumLiability: leagueGroup[x.Id].liability.prematch,
                        maximumLiabilityLive: leagueGroup[x.Id].liability.live,
                        offer: leagueGroup[x.Id].offer
                    });
                } else {
                    newList.push({
                        id: x.Id,
                        name: x.Name,
                        minimumEvent: 0,
                        marketCap: 0,
                        maximumStake: 0,
                        maximumStakeLive: 0,
                        maximumLiability: 0,
                        maximumLiabilityLive: 0,
                        offer: false
                    });
                }
            });

            leagueGroup = newList;
        }

        setKey(Date.now());
        selectLeagueGroup(leagueGroup);
    }, [selectedLeague]);

    useEffect(() => {
        setRegions(null);
        application().resolve(null, `${Endpoints.SportRegions}/${category}`).then((result) => {
            setRegions(result.result);
        });
    }, [category]);


    const onChange = () => {
        //
    };

    const deleteLimit = (row) => {
        if (row.index == undefined) return;
        const copy = Object.assign([], leagueLimits.filter(x => x.index != row.index));

        setKey(Date.now());
        setLeagueLimits(copy);
    };

    const addNew = () => {
        var empty = {
            index: Date.now(),
            id: -1,
            group: null,
            sportId: category,
            sport: categories.find(x => x.id == category).name,
            region: regions.find(x => x.id == region).name,
            regionId: region
        };

        var copy = Object.assign([], leagueLimits);
        copy.push(empty);
        setKey(Date.now());
        setLeagueLimits(copy);
        try {
            list.scrollTop = list.scrollHeight;
        } catch (err) {
            //
        }
    };

    const save = () => {
        var saveData = Object.assign([], leagueLimits);
        var data = {};
        saveData.map((x) => {
            //delete x.index;
            if (x.id == 'undefined' || !x.id) {
                return;
            }
            data[x.id] = {
                id: parseInt(!x.id ? 0 : x.id),
                name: x.name,
                sport: x.sport,
                sportId: parseInt(!x.sportId ? 0 : x.sportId),
                region: x.region,
                regionId: parseInt(!x.regionId ? 0 : x.regionId),
                general: {
                    minimumEvent: parseInt(x.minimumEvent),
                    marketCap: parseInt(!x.marketCap ? 0 : x.marketCap),
                    stake: {
                        live: parseInt(!x.maximumStakeLive ? 0 : x.maximumStakeLive),
                        prematch: parseInt(!x.maximumStake ? 0 : x.maximumStake)
                    },
                    liability: {
                        live: parseInt(!x.maximumLiabilityLive ? 0 : x.maximumLiabilityLive),
                        prematch: parseInt(!x.maximumLiability ? 0 : x.maximumLiability)
                    },
                    offer: x.offer
                },
                groups: x.groups
            };
        });

        application().resolve(null, Endpoints.SaveLeagueLimits, data).then(() => {
            application().modal.open(<Dialog title={'League Limits'}>
                {lang('League limits saved')}
            </Dialog >);
        });
    };

    const saveGroup = () => {
        var eleminated = {};
        selectedLeagueGroup.map(x => {
            var minimumEvent = parseInt(x.minimumEvent);
            var marketCap = parseInt(x.marketCap);
            var maximumStake = parseInt(x.maximumStake);
            var maximumStakeLive = parseInt(x.maximumStakeLive);
            var maximumLiability = parseInt(x.maximumLiability);
            var maximumLiabilityLive = parseInt(x.maximumLiabilityLive);
            if (isNaN(minimumEvent)) minimumEvent = 0;
            if (isNaN(marketCap)) marketCap = 0;
            if (isNaN(maximumStake)) maximumStake = 0;
            if (isNaN(maximumStakeLive)) maximumStakeLive = 0;
            if (isNaN(maximumLiability)) maximumLiability = 0;
            if (isNaN(maximumLiabilityLive)) maximumLiabilityLive = 0;
            if (minimumEvent > 0 || maximumStake > 0 || maximumStakeLive > 0 || maximumLiability > 0 || maximumLiabilityLive > 0) {
                eleminated[x.id] = {
                    marketCap: marketCap,
                    minimumEvent: minimumEvent,
                    stake: {
                        live: maximumStakeLive,
                        prematch: maximumStake
                    },
                    liability: {
                        live: maximumLiabilityLive,
                        prematch: maximumLiability
                    },
                    offer: x.offer
                };
            }
        });


        selectedLeague.groups = eleminated;
        if (Object.keys(selectedLeague.groups).length == 0) selectedLeague.groups = null;
    };

    const onGroupChange = () => {
        selectedLeague.maximumStake = 0;
        selectedLeague.maximumStakeLive = 0;
        selectedLeague.maximumLiability = 0;
        selectedLeague.maximumLiabilityLive = 0;

        selectedLeagueGroup.map(x => {
            var stake = parseFloat(x.maximumStake);
            var stakeLive = parseFloat(x.maximumStakeLive);
            var liability = parseFloat(x.maximumLiability);
            var liabilityLive = parseFloat(x.maximumLiabilityLive);
            if (isNaN(stake)) stake = 0;
            if (isNaN(stakeLive)) stakeLive = 0;
            if (isNaN(liability)) liability = 0;
            if (isNaN(liabilityLive)) liabilityLive = 0;
            selectedLeague.maximumStake += stake;
            selectedLeague.maximumStakeLive += stakeLive;
            selectedLeague.maximumLiability += liability;
            selectedLeague.maximumLiabilityLive += liabilityLive;
        });



        var marketCap = parseFloat(selectedLeague.marketCap);

        selectedLeague.general.marketCap = isNaN(marketCap) ? 0 : marketCap;
        selectedLeague.general.stake.prematch = selectedLeague.stake;
        selectedLeague.general.stake.live = selectedLeague.stakeLive;
        selectedLeague.general.liability.prematch = selectedLeague.maximumLiability;
        selectedLeague.general.liability.live = selectedLeague.maximumLiabilityLive;
        selectedLeague.general.offer = selectedLeague.offer;

        selectLeague(selectedLeague);
        setLeagueGroupKey(Date.now());
        saveGroup();
    };

    if (selectedLeague) {
        return <Card key={newKey} caption={parseInt(selectedLeague.id) > 0 ? selectedLeague.name : 'Default Player Group Configuration'} menu={
            <div className='centrum-flex'>
                <Button title='Close' onClick={() => selectLeague(null)} />
            </div>
        }>
            <Card caption='League configuration' >
                <EditableTable model={leagueLimitsModel} data={[selectedLeague]} key={leagueGroupKey} context={context} readonly={true} />
            </Card>
            <Card caption='Group configuration'>
                <EditableTable model={leagueGroupModel} data={selectedLeagueGroup}
                    onChange={() => onGroupChange()}
                    buttons={(row) => {
                        return <Button title='Clear' onClick={() => {
                            row.minimumEvent = 0;
                            row.marketCap = 0;
                            row.maximumStake = 0;
                            row.maximumStakeLive = 0;
                            row.maximumLiabilityLive = 0;
                            row.maximumLiability = 0;
                            row.offer = false;
                            saveGroup();
                            selectLeague(selectedLeague);
                            setKey(Date.now());
                        }} />;
                    }} />
            </Card>
        </Card>;
    }

    if (!configurationEnabled) {
        return <Card caption='League Limits' className='event-limits'>
            <div className='padding border radius margin flex gap-5'>
                <Icon icon='info-circle' />
                <p>{lang('League Limits is not enabled for this risk management configuration, use Risk Levels')}</p>
            </div>
        </Card>;
    }

    if (!categories) {
        return <React.Fragment />;
    }

    return <Card caption='League Limits' className='event-limits'
        menu={(category == null || region == null) ? null : <Button icon={'plus'} title='Add' onClick={() => addNew()} />}>
        <div className='padding'>
            <div className='row'>
                <div className='form-group'>
                    <label className='medium marginRight'>{lang('Category')}</label>
                    <select onChangeCapture={(e) => {
                        var categoryId = parseInt(e.target.value);
                        if (isNaN(categoryId)) categoryId = null;
                        selectCategory(categoryId);
                    }}>
                        <option value={null}>{lang('All')}</option>
                        {
                            categories.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })
                        }
                    </select>
                    {category && regions &&
                        <React.Fragment>
                            <label className='medium marginRight marginLeft'>{lang('Region')}</label>
                            <select onChangeCapture={(e) => {
                                var regionId = parseInt(e.target.value);
                                if (isNaN(regionId)) regionId = null;
                                setKey(Date.now());
                                selectRegion(regionId);
                            }}>
                                <option value={null}>{lang('All')}</option>
                                {
                                    regions.map(x => {
                                        return <option key={x.id} value={x.id}>{x.name}</option>;
                                    })
                                }
                            </select>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
        <div className={'centrum-flex stretch event-list'}>
            <div className='event-items' ref={(r) => list = r}>
                <EditableTable key={newKey} context={context} model={leagueLimitsModel} data={leagueLimits}
                    onRenderRow={(row) => {
                        if (!category) return true;
                        if (!region) return true;
                        return row.sportId == category && row.regionId == region;
                    }}
                    onChange={onChange.bind(this)} buttons={(row) => {
                        return <div className='centrum-flex'>
                            <Button title={'Edit Groups'} onClick={() => selectLeague(row)} />
                            {row.id != 0 && <Button title='Delete' onClick={() => deleteLimit(row)} />}
                        </div>;
                    }} />
            </div>
        </div>
        <Card>
            <div className='centrum-flex padding'><Button className='alignRight' icon={'save'} title='Save' onClick={() => save()} /></div>
        </Card>
    </Card>;
};

SportLeagueLimits.propTypes = {
    categories: PropTypes.object,
    league: PropTypes.object
};
