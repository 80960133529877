// IMPORT PACKAGE REFERENCES
import './_dropdown.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getHistory } from '../../../config';
import { Icon } from '../Icon/Icon';
// COMPONENT


export class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
        this.dropDownReference = null;

        this.handleClickOutside = this.handleClickOutside.bind(this);

    }


    handleClickOutside(event) {
        if (this.dropDownReference && !this.dropDownReference.contains(event.target)) {
            this.close();
        }
    }

    componentWillUnmount() {
        this.dropDownReference = null;
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate() {
        let items = this.dropDownReference.getElementsByClassName('item');
        let selected = items[this.state.selectedIndex];
        if (selected == null) return;
        selected.classList.add('selected');
    }

    toggle() {
        var open = this.dropDownReference.classList.contains('open');
        if (open) {
            this.close();
        } else {
            this.open();
        }
    }

    open() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.dropDownReference.classList.add('open');
    }

    close() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.dropDownReference.classList.remove('open');
    }

    select(idx) {
        let items = this.dropDownReference.getElementsByClassName('item');
        let selected = items[this.state.selectedIndex];
        selected.classList.remove('selected');

        this.close();
        this.setState({ selectedIndex: idx, selected: null });
        this.props.onSelected(idx);
    }

    renderItem(element, idx) {
        switch (element.type) {
            case 'span':
                if (element.props.className == 'group') {
                    this.group = true;
                    return element;
                }
                break;
            case 'a':
                return <div className={'item' + (this.group ? ' grp' : '')} onClick={() => { this.select(idx); getHistory().push(element.props.to); }}>{React.cloneElement(element, { ref: idx })}</div>;
        }
        return <div className='item' onClick={() => this.select(idx)}>{React.cloneElement(element, { ref: idx })}</div>;
    }

    renderSelectedItem(element) {
        return <div className='selected' onClick={() => this.toggle()}>{React.cloneElement(element)}</div>;
    }

    render() {
        return <div className={(this.props.className != '' ? this.props.className + ' ' : '') + 'dropdown ' + this.props.type} ref={(r) => this.dropDownReference = r}>
            {
                this.props.type == 'dropdown' && //RENDER SELECTED
                React.Children.map(this.props.children, (element, idx) => {
                    if (idx == this.state.selectedIndex) return this.renderSelectedItem(element, idx);
                })
            }
            {
                this.props.type == 'menu' && // RENDER MENU TITLE 
                <div className='title' onClick={() => this.toggle()}>{this.props.title}</div>
            }
            <div className={'items ' + this.props.position}>
                {
                    React.Children.map(this.props.children, (element, idx) => {
                        return this.renderItem(element, idx);
                    })
                }
            </div>
            <div className='dropdown-btn'>
                <Icon onClick={() => this.toggle()} icon={this.props.icon} />
            </div>
        </div>;
    }
}

Dropdown.defaultProps = {
    className: '',
    type: 'dropdown',
    position: 'left',
    icon: 'angle-down',
    title: '',
    selectedIndex: 0,
    selected: null,
    onSelected: () => { }
};

Dropdown.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    position: PropTypes.string,
    icon: PropTypes.object,
    title: PropTypes.string,
    selectedIndex: PropTypes.number,
    selected: PropTypes.node,
    children: PropTypes.node,

    onSelected: PropTypes.func
};
