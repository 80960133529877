import React, { useEffect, useState } from 'react';
import { Card } from '../../../v2/Components/Card/Card';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../v2/Components/Controls/Table/Table';
import { API, CMSEndpoints, DataEndpoints } from '../../../v2/Lib/Api/Api';
import { application } from '../../Application/Application';



const availableLanguages = [
    {
        name: 'English',
        key: 'En'
    },
    {
        name: 'Turkish',
        key: 'Tr'
    },
    {
        name: 'Russian',
        key: 'Ru'
    },
    {
        name: 'French',
        key: 'Fr'
    },
    {
        name: 'German',
        key: 'De'
    },
    {
        name: 'Dutch',
        key: 'Nl'
    },
    {
        name: 'Greek',
        key: 'El'
    },
    {
        name: 'Kurdish',
        key: 'Ku'
    },
    {
        name: 'Arabic',
        key: 'Ar'
    },
    {
        name: 'Persian',
        key: 'Fa'
    },
    {
        name: 'Portuguese',
        key: 'Pt'
    },
    {
        name: 'Chinese',
        key: 'Ce'
    },
    {
        name: 'Azeri',
        key: 'Az'
    },
    {
        name: 'Spanish',
        key: 'Es'
    },
    {
        name: 'Amharic',
        key: 'Am'
    },
    {
        name: 'Oromiifaa',
        key: 'Om'
    },
    {
        name: 'Mongolian',
        key: 'Mn'
    }
];


export const SetupLanguages = () => {
    const [languages, setLanguages] = useState([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        API.post(DataEndpoints.AvailableLanguages, {}).then((result) => {
            setLanguages(result.result);
            setLoaded(true);
        });
    }, []);

    const toggle = (row) => {
        const enabled = languages.find(x => x.name == row.name);
        if (enabled) {
            var copy = languages.filter(x => x.name !== row.name);
            setLanguages(copy);
        } else {
            setLanguages([row].concat(languages));
        }
    };

    const save = () => {
        API.post(CMSEndpoints.SaveAvailableLanguages, languages).then((result) => {
            setLanguages(result.result);
            setLoaded(true);
            application().snackbar.open('Languages saved.');
        });

    };


    return <Card title='Languages' className={loaded ? '' : 'disabled'}
        buttons={() => <Button title='Save' onClick={() => save()} />}>
        <Table model={{
            fields: [
                {
                    name: 'name',
                    title: 'Name'
                }
            ]
        }} onRenderRow={(row, elements) => {
            const enabled = languages.find(x => x.name == row.name);
            return <tr className={enabled ? 'success' : ''}>
                {elements}
            </tr>;
        }} data={availableLanguages} buttons={(row) => {
            const enabled = languages.find(x => x.name == row.name);
            const caption = enabled ? 'Disable' : 'Enable';
            return <Button title={caption} onClick={() => toggle(row)} />;
        }} />
    </Card>;
};