import './_kpiCard.scss';


import React from 'react';
import PropTypes from 'prop-types';

import { KPIValue } from '../KPIValue/KPIValue';
import { Card } from '../../../../Centrum/Card/Card';


// COMPONENT
export class KPICard extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
        if (this.state.indicatorTypes.length == 0) {
            for (var i = 0; i < this.state.indicators.length; i++) {
                this.state.indicatorTypes.push(this.state.type);
            }
        }

        props.context.assignCard(this);
    }

    componentDidMount() {
        this.props.context.load(this);
    }

    loading() {
        this.setState({ loading: true });
    }

    loaded(result) {
        this.setState(result);
    }

    render() {
        var range = this.props.context.range;
        return <Card caption={this.props.title} icon={this.props.icon} className='kpi'>
            <KPIValue
                loading={this.state.loading} range={range} type={this.props.type}
                indicators={this.state.indicators}
                indicatorTypes={this.state.indicatorTypes}
                currency={this.state.currency}
                value={this.state.value} values={this.state.details}
                trend={this.state.trend} change={this.state.change}
                onClick={this.props.onClick ? this.props.onClick.bind(this) : null} />
        </Card>;
    }
}

KPICard.defaultProps = {
    loading: true,
    context: null,

    type: '',
    currency: '',

    title: '',

    indicators: [],
    indicatorTypes: [],

    value: null,
    values: null,

    trend: null,
    change: null,
    icon: null,


    url: '',

    onClick: null,
};

KPICard.propTypes = {
    loading: PropTypes.bool,

    context: PropTypes.object,
    type: PropTypes.string,
    currency: PropTypes.string,

    range: PropTypes.number,

    title: PropTypes.string,

    indicators: PropTypes.array,
    indicatorTypes: PropTypes.array,

    value: PropTypes.any,
    values: PropTypes.array,

    trend: PropTypes.any,
    change: PropTypes.string,

    icon: PropTypes.node,

    url: PropTypes.string,

    onClick: PropTypes.func
};

