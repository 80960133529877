

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../Centrum/Card/Card';
import { CurrencyMinMaxConfiguration } from '../../../Promotions/Components/CurrencyMinMaxConfiguration';
import { Currencies } from '../../../../Centrum/_models/ModelEnums';
import { Button } from '../../../../Controls/Buttons/Button';
import { Form } from '../../../../Controls/Form/Form';
import { application, Endpoints } from '../../../../Application/Application';
import { Icon } from '../../../../Controls/Icon/Icon';

// COMPONENT
export class CasinoLimitsGeneral extends React.Component {
    constructor(props) {
        super(props);

        var state = Object.assign({}, props);
        state.selectedCurrency = window.user.DefaultCurrency ?? 'USD';
        state.limits = props.limits && Object.keys(props.limits).length > 0 ? { ...props.limits } : {
            general: {},
            groups: {}
        };


        
        Object.keys(Currencies).map(currency => {
            if (state.limits?.general && !state.limits.general.hasOwnProperty(currency)) {
                state.limits.general[currency] = this.createEmptyData();
            }
        });


        this.state = state;
    }

    componentDidMount() {
        application().resolve(null, Endpoints.GetPlayerGroups, {}, 'loading player groups', 'unable to load player groups').then((result) => {
            var groups = {};
            groups[0] = 'Select a group for configuration';
            result.result.map(x => {
                groups[x.Id] = x.Name;
            });

            this.setState({ groups: groups });
        });

    }

    selectGroup(group) {
        var limits = Object.assign({}, this.state.limits);

        var data = limits.groups[group];
        if (!data) {
            Object.keys(Currencies).map(currency => {
                if (!limits.groups[group]) {
                    limits.groups[group] = {};
                }

                limits.groups[group][currency] = this.defaultGroupLimitsData();
            });
        }
        this.setState({ limits: limits, selectedGroup: group });
    }

    defaultGroupLimitsData() {
        var data = [

        ];

        var row = {
            MinBet: 0,
            MaxBet: 0,
            MaxPayout: 0,
            BetStop: false
        };
        data.push(row);

        return data;
    }


    createEmptyData() {
        var data = [

        ];

        var row = {
            MinBet: 0,
            MaxBet: 0,
            MaxPayout: 0,
            BetStop: false
        };
        data.push(row);

        return data;
    }


    getData() {
        return { ...this.state.limits };
    }

    clearSelectedGroup() {
        var limits = this.state.limits;
        if (this.groupsSelect) {
            this.groupsSelect.selectedIndex = 0;
        }
        delete limits.groups[this.state.selectedGroup];
        this.setState({ selectedGroup: null });
    }

    render() {
        return <React.Fragment>
            <Card>
                <CurrencyMinMaxConfiguration
                    ref={(r) => this.betLimitsSettings = r}
                    selectedCurrency={this.state.selectedCurrency}
                    data={this.state.limits.general}
                    newRow={false}
                    deleteEnabled={false}
                    model={
                        [
                            {
                                name: 'MinBet',
                                display: 'Min Stake',
                                type: 'number'
                            },
                            {
                                name: 'MaxBet',
                                display: 'Max Stake',
                                type: 'number'
                            },
                            {
                                name: 'MaxPayout',
                                display: 'Max Payout',
                                type: 'number'
                            },
                            {
                                name: 'BetStop',
                                display: 'Bet Stop',
                                type: 'bool'
                            }
                        ]
                    }

                />
            </Card>
            <Card caption={'Player groups'} menu={this.state.selectedGroup && <Icon icon='trash' className='margin-top' onClick={() => this.clearSelectedGroup()} />}>
                <Form>
                    <div className='row'>
                        {
                            this.state.groups &&
                            <div className='form-group'>
                                <select ref={(r) => this.groupsSelect = r} onChangeCapture={(e) => {
                                    var id = parseInt(e.target.value);
                                    this.selectGroup(id);
                                }}>
                                    {Object.keys(this.state.groups).map(groupId => {
                                        return <option value={groupId} key={groupId}>{this.state.groups[groupId]}</option>;
                                    })}
                                </select>
                            </div>
                        }
                    </div>
                </Form>
            </Card>
            {this.state.selectedGroup && <React.Fragment>
                <Card>
                    <CurrencyMinMaxConfiguration
                        key={this.state.selectedGroup}
                        data={this.state.limits.groups[this.state.selectedGroup]}
                        newRow={false}
                        deleteEnabled={false}
                        model={
                            [
                                {
                                    name: 'MinBet',
                                    display: 'Min Stake',
                                    type: 'number'
                                },
                                {
                                    name: 'MaxBet',
                                    display: 'Max Stake',
                                    type: 'number'
                                },
                                {
                                    name: 'MaxPayout',
                                    display: 'Max Payout',
                                    type: 'number'
                                },
                                {
                                    name: 'BetStop',
                                    display: 'Bet Stop',
                                    type: 'bool'
                                }
                            ]
                        }
                        ref={(r) => this.payoutRules = r} />
                </Card>
            </React.Fragment>
            }
            {this.props.saveEnabled == true &&
                <Card className='margin-top'>
                    <div className='centrum-flex'>
                        <Button title='Save' type='submit' className='margin alignRight' />
                    </div>
                </Card>
            }
        </React.Fragment >;
    }
}

CasinoLimitsGeneral.defaultProps = {
    title: '',
    saveEnabled: true,

    selectedCurrency: 'USD',
    limits: {}
};

CasinoLimitsGeneral.propTypes = {
    title: PropTypes.string,
    saveEnabled: PropTypes.bool,

    selectedCurrency: PropTypes.string,
    limits: PropTypes.object
};

