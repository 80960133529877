export class ContextMenuManager {
    constructor() {
        var self = this;
        window.addEventListener('contextmenu', e => {
            //e.preventDefault();
            const origin = {
                left: e.pageX,
                top: e.pageY
            };
        
            self.setPosition(origin);
            //return false;
        });
        
    }

    setPosition(origin) {
        console.log('origin',origin);
    }
}

