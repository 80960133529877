import './_discountBonus.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { BonusGeneralSettings, BonusDiscountSettings, GameSettings, BonusPlayerSettings } from '../Components/BonusComponents';
import { Tab } from '../../../Centrum/Tab/Tab';
import { Card } from '../../../Centrum/Card/Card';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { SportsRules } from '../Components/SportsRules';
import { RacingRules } from '../Components/RacingRules';

export class DiscountBonus extends React.Component {
    constructor(props) {
        super(props);
        var state = Object.assign({}, props);
        state.multiplers = {};
        for (var i = 0; i < 100; i++) {
            state.multiplers[i] = 'x' + i;
        }


        this.state = state;
        this.state.promotion.Configuration = (typeof this.state.promotion.Configuration === 'object' && this.state.promotion.Configuration !== null) ? this.state.promotion.Configuration : JSON.parse(this.state.promotion.Configuration);
        if (!this.state.promotion.Configuration) {
            this.state.promotion.Configuration = {
                sports: {},
                turnover: {}
            };
        }
        if (!this.state.promotion.Configuration.turnover) {
            this.state.promotion.Configuration.turnover = {
                BonusAmountMultiplier: 0,
                DeductBonusAmountWithdrawMultiplier: false
            };
        }

        this.state.deductBonusAmountWithdrawMultiplier = this.state.promotion.Configuration.turnover.DeductBonusAmountWithdrawMultiplier;
    }


    save() {
        var promotion = this.generalSettings.save();
        var playerSettings = this.playerSettings.save();
        var discountSettings = this.discountSettings.save();

        var gameSettings = this.gameSettings.save();
        var withdrawForm = this.withdrawForm.submit();

        var result = Object.assign({}, promotion);
        result = Object.assign(playerSettings, result);

        result.Configuration.discountSettings = discountSettings;
        result.Configuration.games = gameSettings;
        result.Configuration.sport = this.sportSettings.save();
        result.Configuration.racing = this.racingSettings.save();
        result.Configuration.turnover = withdrawForm;
        console.log(result.Configuration.turnover);
        result.DeductBonusAmount = result.Configuration.turnover.DeductBonusAmountWithdrawMultiplier;
        result.Configuration = JSON.stringify(result.Configuration);
        return result;
    }

    render() {
        var tabButtons = [
            {
                title: 'General'
            },
            {
                title: 'Player Settings'
            },
            {
                title: 'Discount Settings'
            },
            {
                title: 'Turnover Settings'
            },
            {
                title: 'Sports'
            },
            {
                title: 'Racing'
            },
            {
                title: 'Game Settings'
            }
        ];
        return <React.Fragment>
            <Tab buttons={tabButtons}>
                <Card key='general'>
                    <BonusGeneralSettings promotion={this.state.promotion} ref={(r) => this.generalSettings = r} />
                </Card>
                <Card key='player'>
                    <BonusPlayerSettings promotion={this.state.promotion} ref={(r) => this.playerSettings = r} />
                </Card>
                <Card key='discount'>
                    <BonusDiscountSettings promotion={this.state.promotion} ref={(r) => this.discountSettings = r} />
                </Card>
                <Card key='withdraw'>
                    <Form ref={(r) => this.withdrawForm = r}
                        data={this.state.promotion.Configuration.turnover}>
                        <div className='row'>
                            <div className='half-width'>
                                <FormInput name='BonusAmountMultiplier' title='Bonus amount multiplier' type='select' values={this.state.multiplers} />
                            </div>
                        </div>

                        <Card caption='Deduction' className='margin-top centrum-flex stretch row'>
                            <div className='row padding'>
                                <FormInput name='DeductBonusAmount' title='Deduct bonus amount'
                                    type='bool' onChange={(form, field, value) => this.setState({ deductBonusAmount: value })} />
                            </div>
                        </Card>
                    </Form>
                </Card>
                <SportsRules configuration={this.state.promotion.Configuration.sport} context={(context) => this.sportSettings = context} />
                <RacingRules configuration={this.state.promotion.Configuration.racing} context={(context) => this.racingSettings = context} />
                <Card key='gameSettings'>
                    <GameSettings promotion={this.state.promotion} ref={(r) => this.gameSettings = r} />
                </Card>
            </Tab >
        </React.Fragment >;
    }
}

DiscountBonus.propTypes = {
    promotion: PropTypes.object
};

