// IMPORT PACKAGE REFERENCES

import React from 'react';
import { logout } from '../Application/Application';


// COMPONENT
export class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        let state = Object.assign({}, props);
        this.state = state;
    }

    componentDidMount() {
        logout();
    }

    render() {
        return <div></div>;
    }
}

LogoutPage.defaultProps = {
    showConfirmation: true
};

LogoutPage.propTypes = {
};


