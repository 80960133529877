import React from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';
import { Icon } from '../../Icon/Icon';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';


const SortableHandler = SortableHandle((props) => props.handle);
SortableHandler.defaultProps = {
    handle: <div className='handle'><Icon icon='grip-vertical' /></div>
};

SortableHandler.propTypes = {
    handle: PropTypes.node
};


const dateTimeFormatter = (value) => {
    return new Date(value).toLocaleString('en-Gb', { hour12: false });
};

const dateFormatter = (value) => {
    return new Date(value).toLocaleDateString('en-Gb');
};


const format = (field, row) => {
    var column = null;
    if (field.formatter) {
        column = field.formatter(field, row);
    } else {
        switch (field.type) {
            case InputTypes.dateTime:
                column = dateTimeFormatter(row[field.name]);
                break;
            case InputTypes.date:
                column = dateFormatter(row[field.name]);
                break;
            default:
                column = row[field.name];
        }

    }
    return column;
};

const EditableColumn = (props) => {
    const inline = props.options.editable.inline;
    if (inline) {
        // eslint-disable-next-line
        return <td>{format(props.field, props.row)}<Icon icon='edit' size='xs' /></td>;
    } else {
        // eslint-disable-next-line
        return <td><input type='text' defaultValue={format(props.field, props.row)} onChange={(e) => {
            // eslint-disable-next-line
            props.row[props.field.name] = e.target.value;
        }} /></td>;
    }
};

EditableColumn.propTypes = {
    field: PropTypes.field,
    options: PropTypes.object
};

const TableColumn = (props) => {
    const field = props.field;
    const editable = props.options && props.options.editable && props.options.editable.fields && props.options.editable.fields.find(x => x == field.name);
    if (editable) return <EditableColumn {...props} />;

    var column = null;
    if (field.formatter || field.render) {
        column = field.render ? field.render(props.field, props.row, props.context) : field.formatter(props.field, props.row, props.context);
    } else {
        switch (field.type) {
            case InputTypes.dateTime:
                column = dateTimeFormatter(props.row[props.field.name]);
                break;
            case InputTypes.date:
                column = dateFormatter(props.row[props.field.name]);
                break;
            default:
                column = props.row[props.field.name];
        }

    }
    return <td>{column}</td>;
};

TableColumn.propTypes = {
    field: PropTypes.object,
    options: PropTypes.object,
    context: PropTypes.object,
    row: PropTypes.object
};


const TableRow = (props) => {
    const columns = props.model.fields.map((field, index) => {
        return <TableColumn key={index} field={field} {...props} />;
    });
    const elements = <React.Fragment>
        {props.options.draggable.enabled &&
            <td className='table-option-draggable-column'>
                <SortableHandler />
            </td>
        }
        {props.options.selectable && <td className='table-option-selectable-column' onClick={() => props.onSelect(props.row)}><Icon icon={props.row.__selected ? 'check-square' : 'square'} type='far' /></td>}
        {columns}
        {props.options.data && props.options.data.delete.enabled &&
            <td className='table-option-delete-column' onClick={() => {
                props.onDelete(props.row);
            }} >
                <Icon icon='times' />
            </td>
        }
        {props.buttons && <td className='table-option-buttons-column'>{props.buttons(props.row)}</td>}
    </React.Fragment>;

    if (props.onRenderRow) {
        return props.onRenderRow(props.row, elements);
    }
    return <tr className={props.className}>
        {elements}
    </tr>;
};

TableRow.defaultProps = {
    onDelete: () => { }
};

TableRow.propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    row: PropTypes.object,
    options: PropTypes.object,
    buttons: PropTypes.func,
    onRenderRow: PropTypes.func,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
};

export default TableRow;
