import './_sports-tree.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API, SportEndpoints } from '../../../v2/Lib/Api/Api';
import { Icon } from '../../../v2/Components/Icon/Icon';
import { lang } from '../../Centrum/Localization/lang';

export const SportsTree = (props) => {
    const enableRegions = props.regions;
    const enableLeagues = props.leagues;
    const enableTeams = props.teams;
    const enableEvents = props.events;

    const [categories, setCategories] = useState(props.categories);
    const [regions, setRegions] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [events, setEvents] = useState(null);
    const [teams, setTeams] = useState(null);


    const [selectedCategory, selectCategory] = useState(null);
    const [selectedRegion, selectRegion] = useState(null);
    const [selectedLeague, selectLeague] = useState(null);
    const [selectedTeam, selectTeam] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!props.categories) {
            setLoading(true);
            API.post(SportEndpoints.Categories, null).then((result) => {
                var c = result.result.sort((a, b) => a.weight - b.weight);
                setCategories(c);
            });
        }
    }, []);

    useEffect(() => {
        if (!categories) return;
        selectCategory(categories[0]);
    }, [categories]);

    useEffect(() => {
        if (!selectedCategory) return;
        if (!enableRegions) return;
        setLoading(true);
        API.post(`${SportEndpoints.Regions}/${selectedCategory.id}`).then((result) => {
            setRegions(result.result);
            setLoading(false);
        });
    }, [selectedCategory]);


    useEffect(() => {
        if (!selectedCategory) return;
        if (!selectedRegion) return;
        setLoading(true);
        API.post(`${SportEndpoints.Leagues}/${selectedCategory.id}/${selectedRegion.id}`).then((result) => {
            var l = result.result;
            setLeagues(l);
            selectLeague(l.length > 0 ? l[0] : null);
            setLoading(false);
        });
    }, [selectedRegion]);



    useEffect(() => {
        if (!selectedCategory) return;
        if (!selectedRegion) return;
        if (!selectedLeague) return;

        if (!enableTeams && !enableEvents) return;
        setLoading(true);

        if (enableTeams) {
            API.post(`${SportEndpoints.Teams}/${selectedCategory.id}/${selectedRegion.id}/${selectedLeague.id}`).then((result) => {
                var l = result.result;
                console.log(l);
                setTeams(l);
                selectTeam(l.length > 0 ? l[0] : null);
                setLoading(false);
            });
        } else {
            API.post(SportEndpoints.Events, {
                sportId: selectedCategory.id,
                regionId: selectedRegion.id,
                leagueId: selectedLeague.id
            }).then((result) => {
                var l = result.result;
                setEvents(l);
                selectEvent(l.length > 0 ? l[0] : null);
                setLoading(false);
            });
        }
    }, [selectedLeague]);


    const renderTeams = () => {
        if (!enableLeagues) return;
        if (!teams || teams.length == 0) {
            return <li className='padding'>
                <span>{lang('No teams found')}</span>
            </li>;
        }
        return teams?.map(x => {
            const selected = x == selectedTeam;
            return <li key={x} className={`tree-child${selected ? ' selected' : ''}`}><span>{x.name}</span>{props.onRenderTeamOptions ? props.onRenderTeamOptions(x) : null}</li>;
        });
    };

    const renderLeagues = () => {
        if (!enableLeagues) return;
        return leagues?.map(x => {
            const selected = x == selectedLeague;
            var children = null;
            if (selected) {
                if (enableTeams) children = <li><ul>{renderTeams()}</ul></li>;
            }
            const element = <li key={x} className={`tree-child${selected ? ' selected' : ''}`}><span onClick={() => {
                if (props.onLeagueSelected) {
                    props.onLeagueSelected(x);
                }
                if (!enableTeams && !enableEvents) return;
                if (selectedLeague == x) {
                    selectLeague(null);
                    return;
                }
                selectLeague(x);
            }}>{x.name}</span>
                {props.onRenderLeagueOptions ? props.onRenderLeagueOptions(x) : null}
                {selected && (enableTeams || enableEvents) && <Icon icon='chevron-down' size='xs' className='toggle-icon' />}</li>;
            return <React.Fragment key={x.id}>
                {element}
                {children}
            </React.Fragment>;
        });
    };


    const renderRegions = () => {
        if (!enableRegions) return;
        return regions?.map(x => {
            var children = null;
            var selected = false;
            if (x == selectedRegion) {
                children = <li><ul>{renderLeagues()}</ul></li>;
                selected = true;
            }
            var element = <li key={x} className={`tree-child${selected ? ' selected' : ''}`}><span onClick={() => {
                if (selectedRegion == x) {
                    selectRegion(null);
                    return;
                }
                selectRegion(x);
            }}>{x.name}</span>{selected && <Icon icon='chevron-down' size='xs' className='toggle-icon' />}</li>;
            return <React.Fragment key={x.id}>
                {element}
                {children}
            </React.Fragment>;
        });
    };


    return <div className={`tree sports ${loading ? ' disabled' : ''}`}>
        <ul>
            {
                categories?.map(x => {
                    var children = null;
                    var selected = false;
                    if (x == selectedCategory) {
                        children = <li><ul>{renderRegions()}</ul></li>;
                        selected = true;
                    }
                    var element = <li key={x} className={`tree-child${selected ? ' selected' : ''}`}><span onClick={() => {
                        if (selectedCategory == x) {
                            selectCategory(null);
                            return;
                        }
                        selectCategory(x);
                    }}>{x.name}</span>{selected && <Icon icon='chevron-down' size='xs' className='toggle-icon' />}</li>;
                    return <React.Fragment key={x.id}>
                        {element}
                        {children}
                    </React.Fragment>;
                })
            }
        </ul>
    </div>;
};

SportsTree.propTypes = {
    categories: PropTypes.object,
    regions: PropTypes.bool,
    leagues: PropTypes.bool,
    teams: PropTypes.bool,
    events: PropTypes.bool,
    searchEnabled: PropTypes.bool,
    onLeagueSelected: PropTypes.func,
    onRenderLeagueOptions: PropTypes.func,
    onRenderTeamOptions: PropTypes.func
};