import React, { useEffect, useState } from 'react';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { API, RiskLevelEndpoints, SportEndpoints } from '../../../../v2/Lib/Api/Api';
import { application } from '../../../Application/Application';
import { lang } from '../../../Centrum/Localization/lang';
import { MarketLevels } from './RiskLevelsConfiguration';

const model = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'name',
            title: 'Market',
            renderRow: (row) => {
                return renderMarketTitle({ id: row.id, type: row.id, name: row.name, line: row.line }, { home: { name: 'Home' }, away: { name: 'Away' } });
            }
        },
        {
            name: 'line',
            title: 'Line'
        },
        {
            name: 'low',
            title: '',
            renderRow: (row, field, context) => {
                const selected = row.level == MarketLevels.Low;
                return <div className={`Low${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.sportId + ':' + row.key, MarketLevels.Low)}>Low</div>;
            }
        },
        {
            name: 'medium',
            title: '',
            renderRow: (row, field, context) => {
                const selected = row.level == MarketLevels.Medium;
                return <div className={`Medium${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.sportId + ':' + row.key, MarketLevels.Medium)}>Medium</div>;
            }
        },
        {
            name: 'high',
            title: '',
            renderRow: (row, field, context) => {
                const selected = row.level == MarketLevels.High;
                return <div className={`High${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.sportId + ':' + row.key, MarketLevels.High)}>High</div>;
            }
        }
    ]
};



export const renderMarketTitle = (market) => {
    var title = market.name;
    const homeRegex = /{\$competitor1}/ig;
    const awayRegex = /{\$competitor2}/ig;
    title = title.replace(homeRegex, '[Home]');
    title = title.replace(awayRegex, '[Away]');

    return title;
};





export const RiskLevelMarkets = (props) => {
    const [selectedSport, selectSport] = useState(null);


    const [levels, setLevels] = useState({ default: MarketLevels.High, markets: {} });
    const [markets, setMarkets] = useState([]);
    const [saving, setSaving] = useState(false);

    const [line, setLine] = useState(null);

    const marketRef = React.createRef();
    const lineRef = React.createRef();

    const context = {
        levels: levels,
        update: (key, level) => {
            var copy = structuredClone(levels);
            copy.markets[key].level = level;
            setLevels(copy);
        }
    };

    useEffect(() => {
        API.post(RiskLevelEndpoints.Markets).then((market) => {
            let config = market.result ? JSON.parse(market.result) : { default: MarketLevels.High, markets: {} };
            setLevels(config);
        });
        selectSport(props.sports[0]);
    }, []);

    useEffect(() => {
        if (!selectedSport) return;
        API.post(`${SportEndpoints.Markets}/${selectedSport.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.id > b.id);
            result.result = result.result.filter((x) => x.name !== '');

            setMarkets(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [selectedSport]);

    const save = () => {
        setSaving(true);
        API.post(RiskLevelEndpoints.SaveMarkets, levels).then(() => {
            application().snackbar.open('Levels Saved.');
            setSaving(false);
        });
    };

    const setDefault = (level) => {
        var copy = { ...levels };
        copy.default = level;
        setLevels(copy);
    };

    const remove = (row) => {
        var copy = { ...levels };
        delete copy.markets[row.sportId + ':' + row.key];
        copy.updated = Date.now();
        setLevels(copy);
    };

    const add = () => {
        var line = lineRef.current.value;
        var marketId = parseInt(marketRef.current.value);
        var market = null;
        market = markets.find(x => x.id == marketId);
        if (!market) {
            return;
        }

        var copy = { ...levels };
        var key = market.id + ':' + ((line || line != '') ? line : '');
        copy.markets[selectedSport.id + ':' + key] = {
            id: market.id,
            key: key,
            name: market.name,
            line: line,
            sportId: selectedSport.id,
            sport: selectedSport.name,
            level: MarketLevels.High
        };
        copy.updated = Date.now();
        setLevels(copy);
    };


    return <div className={`risk-level-limits${saving ? ' disabled' : ''}`}>
        <div className='content'>
            <div className='panel'>
                <div className='padding flex gap-5'>
                    <div className='flex gap-10'>
                        <select defaultValue={selectedSport?.id} onChangeCapture={(e) => {
                            selectSport(props.sports.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {
                                props.sports.map(x => {
                                    return <option key={x.id} value={x.id}>{x.name}</option>;
                                })
                            }
                        </select>
                        <select ref={marketRef} onChange={() => {
                            setLine(null);
                            lineRef.current.value = '';
                        }}>

                            {
                                markets.sort((a, b) => a.id - b.id).map((x, index) => {
                                    return <option key={index} value={x.id}>[{x.id}] {renderMarketTitle(x, { home: { name: 'Home' }, away: { name: 'Away' } })}</option>;
                                })
                            }
                        </select>
                        <div className='flex gap-5'>
                            <label>{lang('Line')}</label>
                            <input name='line' ref={lineRef} onChange={(e) => setLine(e.target.values)} defaultValue={line ?? ''} />
                        </div>
                        <Button title='Add' onClick={() => add()} />
                    </div>
                    <label className='align-right'>{lang('Default level for markets')}</label>
                    <div className='flex gap-5'>
                        <div className={`clickable Low${levels.default == 'Low' ? ' selected' : ' passive'}`} onClick={() => setDefault(MarketLevels.Low)}>{MarketLevels.Low}</div>
                        <div className={`clickable Medium${levels.default == 'Medium' ? ' selected' : ' passive'}`} onClick={() => setDefault(MarketLevels.Medium)}>{MarketLevels.Medium}</div>
                        <div className={`clickable High${levels.default == 'High' ? ' selected' : ' passive'}`} onClick={() => setDefault(MarketLevels.High)}>{MarketLevels.High}</div>
                    </div>
                </div>
            </div>
            <Table model={model} data={Object.values(levels.markets)} context={context} buttons={(row) => {
                return <Button title='Remove' onClick={() => remove(row)} />
            }} />
        </div>
        <div className='panel padding'>
            <Button title='Save' onClick={() => save()} />
        </div>
    </div>;
};