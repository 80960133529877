import './_sportCategoryLimits.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../../Application/Application';
import { Card } from '../../../../Centrum/Card/Card';

import { Button } from '../../../../Controls/Buttons/Button';
import { EditableTable } from '../../../../Controls/Table/Editable/EditableTable';
import { Dialog } from '../../../../Modal/Modals/Dialog/Dialog';
import { lang } from '../../../../Centrum/Localization/lang';
import { Icon } from '../../../../../v2/Components/Icon/Icon';


const categoryModel = {
    fields: [
        {
            name: 'name',
            display: 'Sport'
        },
        {
            name: 'minimumEvent',
            type: 'string',
            display: 'Min Event',
            editable: true
        },
        {
            name: 'minimumOddsPrematch',
            type: 'number',
            display: 'Min Odds Pre',
            editable: true
        },
        {
            name: 'minimumOddsLive',
            type: 'number',
            display: 'Min Odds Live',
            editable: true
        },
        {
            name: 'maximumOddsPrematch',
            type: 'number',
            display: 'Max Odds Pre',
            editable: true
        },
        {
            name: 'maximumOddsLive',
            type: 'number',
            display: 'Max Odds Live',
            editable: true
        },
        {
            name: 'minimumStakePrematch',
            type: 'number',
            display: 'Min Stake Pre',
            editable: true
        },
        {
            name: 'minimumStakeLive',
            type: 'number',
            display: 'Min Stake Pre',
            editable: true
        },
        {
            name: 'maxPayPrematch',
            type: 'number',
            display: 'Max Payout Pre',
            editable: true
        },
        {
            name: 'maxPayLive',
            type: 'number',
            display: 'Max Payout Live',
            editable: true
        },
        {
            name: 'marketCap',
            type: 'number',
            display: 'Market Cap',
            editable: true
        },
        {
            name: 'maximumStake',
            type: 'number',
            display: 'Stake Volume',
            editable: true
        },
        {
            name: 'maximumStakeLive',
            type: 'number',
            display: 'Stake Live Volume',
            editable: true
        },
        {
            name: 'maximumLiability',
            type: 'number',
            display: 'Liability',
            editable: true
        },
        {
            name: 'maximumLiabilityLive',
            type: 'number',
            display: 'Live Liability',
            editable: true
        },
        {
            name: 'offer',
            type: 'bool',
            display: 'Offer',
            editable: true
        }
    ]
};



const groupModel = {
    fields: [
        {
            name: 'name',
            display: 'Name',
        },
        {
            name: 'minimumEvent',
            type: 'string',
            display: 'Minimum Event',
            editable: true
        },
        {
            name: 'marketCap',
            type: 'string',
            display: 'Market Cap',
            editable: true
        },
        {
            name: 'maximumStake',
            type: 'number',
            display: 'Stake Volume',
            editable: true
        },
        {
            name: 'maximumStakeLive',
            type: 'number',
            display: 'Stake Live Volume',
            editable: true
        },
        {
            name: 'maximumLiability',
            type: 'number',
            display: 'Liability',
            editable: true
        },
        {
            name: 'maximumLiabilityLive',
            type: 'number',
            display: 'Live Liability',
            editable: true
        },
        {
            name: 'offer',
            type: 'bool',
            display: 'Offer',
            editable: true
        }
    ]
};


export const SportCategoryLimits = () => {
    const [configurationEnabled, setConfigurationEnabled] = useState(true);
    const [groups, setGroups] = useState(null);
    const [categories, setCategories] = useState(null);
    const [limits, setLimits] = useState([]);
    const [category, selectCategory] = useState(null);
    const [selectedGroup, selectCategoryGroup] = useState(null);
    const [groupKey, setGroupKey] = useState(Date.now());
    const [newKey, setKey] = useState(Date.now());

    const context = {
        categories: categories,
        groups: groups,
        onCategoryChanged: (row, category) => {
            row.id = category;
        }
    };

    useEffect(() => {
        const riskType = window.user.features.configuration?.risk ? JSON.parse(window.user.features.configuration.risk).type : { type: 0 };
        if (riskType.type == 1) {
            setConfigurationEnabled(false);
            return;
        }
        application().resolve(null, Endpoints.GetPlayerGroups).then((playerGroups) => {
            setGroups(playerGroups.result);

            application().resolve(null, Endpoints.SportCategories).then((result) => {
                const categoryList = result.result.sort((a, b) => (a.id > b.id) ? 1 : -1);
                application().resolve(null, Endpoints.GetCategoryLimits).then((result) => {
                    try {
                        var limitsConverted = result.result;
                        try {
                            limitsConverted = JSON.parse(limitsConverted);
                        } catch (err) {
                            limitsConverted = null;
                        }
                        var limits = limitsConverted ? limitsConverted : {};

                        var sorted = [];

                        if (!limits.hasOwnProperty(0)) {
                            limits[0] = {
                                id: 0,
                                name: 'All',
                                general: {
                                    minimumEvent: 0,
                                    marketCap: 0,
                                    stake: {
                                        live: 0,
                                        prematch: 0
                                    },
                                    liability: {
                                        live: 0,
                                        prematch: 0
                                    }
                                },
                                groups: null
                            };
                        }

                        sorted.push({
                            id: 0,
                            index: 0,
                            name: limits[0].name,
                            minimumEvent: limits[0].general.minimumEvent,
                            marketCap: limits[0].general.marketCap,
                            maximumStake: limits[0].general.stake.prematch,
                            maximumStakeLive: limits[0].general.stake.live,
                            maximumLiability: limits[0].general.liability.prematch,
                            maximumLiabilityLive: limits[0].general.liability.live,
                            offer: limits[0].general.offer,
                            general: limits[0].general,
                            groups: limits[0].groups
                        });


                        categoryList.map((x, index) => {
                            const id = x.id;
                            var sportLimit = limits[id];
                            if (!sportLimit) {
                                sportLimit = {
                                    id: x.id,
                                    name: x.name,
                                    general: {
                                        minimumEvent: 0,
                                        marketCap: 0,
                                        stake: {
                                            live: 0,
                                            prematch: 0
                                        },
                                        liability: {
                                            live: 0,
                                            prematch: 0
                                        },
                                        offer: false
                                    },
                                    groups: null
                                };
                            }

                            var limit = {
                                id: id,
                                index: index,
                                name: sportLimit.name,
                                minimumEvent: sportLimit.general.minimumEvent,
                                marketCap: sportLimit.general.marketCap,
                                maximumStake: sportLimit.general.stake.prematch,
                                maximumStakeLive: sportLimit.general.stake.Live,
                                maximumLiability: sportLimit.general.liability.prematch,
                                maximumLiabilityLive: sportLimit.general.liability.live,
                                offer: sportLimit.general.offer,
                                general: sportLimit.general,
                                groups: sportLimit.groups
                            };
                            sorted.push(limit);
                        });


                        setCategories(categoryList);
                        setLimits(sorted);
                        setKey(Date.now());
                    } catch (err) {
                        console.log('category limits', err);
                    }
                });
            });
        });
    }, []);



    useEffect(() => {
        if (!category) return;
        var categoryGroups = category.groups;
        if (!categoryGroups) {
            categoryGroups = {};
        }
        var newList = [];
        groups.map(x => {
            if (categoryGroups.hasOwnProperty(x.Id)) {
                newList.push({
                    id: x.Id,
                    name: x.Name,
                    minimumEvent: categoryGroups[x.Id].minimumEvent,
                    marketCap: categoryGroups[x.Id].marketCap,
                    maximumStake: categoryGroups[x.Id].stake.prematch,
                    maximumStakeLive: categoryGroups[x.Id].stake.live,
                    maximumLiability: categoryGroups[x.Id].liability.prematch,
                    maximumLiabilityLive: categoryGroups[x.Id].liability.live,
                    offer: categoryGroups[x.Id].offer
                });
            } else {
                newList.push({
                    id: x.Id,
                    name: x.Name,
                    minimumEvent: 0,
                    marketCap: 0,
                    maximumStake: 0,
                    maximumStakeLive: 0,
                    maximumLiability: 0,
                    maximumLiabilityLive: 0,
                    offer: false
                });
            }
        });

        categoryGroups = newList;

        setKey(Date.now());
        selectCategoryGroup(categoryGroups);
    }, [category]);



    const save = () => {
        var saveData = Object.assign([], limits);
        var data = {};
        saveData.map(x => {
            delete x.index;

            var minimumEvent = parseInt(x.minimumEvent);
            var marketCap = parseInt(!x.marketCap ? 0 : x.marketCap);
            var maximumStakeLive = parseInt(!x.maximumStakeLive ? 0 : x.maximumStakeLive);
            var maximumStake = parseInt(!x.maximumStake ? 0 : x.maximumStake);
            var maximumLiability = parseInt(!x.maximumLiability ? 0 : x.maximumLiability);
            var maximumLiabilityLive = parseInt(!x.maximumLiabilityLive ? 0 : x.maximumLiabilityLive);

            if (isNaN(minimumEvent)) minimumEvent = 0;
            if (isNaN(marketCap)) marketCap = 0;
            if (isNaN(maximumStakeLive)) maximumStakeLive = 0;
            if (isNaN(maximumStake)) maximumStake = 0;
            if (isNaN(maximumLiability)) maximumLiability = 0;
            if (isNaN(maximumLiabilityLive)) maximumLiabilityLive = 0;
            if (minimumEvent > 0 && marketCap > 0 || maximumStake > 0 || maximumStakeLive > 0 || maximumLiability > 0 || maximumLiabilityLive > 0 || x.groups) {
                data[x.id] = {
                    id: parseInt(!x.id ? 0 : x.id),
                    name: x.name,
                    general: {
                        minimumEvent: minimumEvent,
                        marketCap: marketCap,
                        stake: {
                            live: maximumStakeLive,
                            prematch: maximumStake
                        },
                        liability: {
                            live: maximumLiabilityLive,
                            prematch: maximumLiability
                        },
                        offer: x.offer
                    },
                    groups: x.groups
                };
            }
        });


        application().resolve(null, Endpoints.SaveCategoryLimits, data).then(() => {
            application().modal.open(<Dialog title={'Category Limits'}>
                {lang('Category limits saved')}
            </Dialog >);
        });
    };


    const deleteLimit = (row) => {
        row = limits.find(x => x.id == row.id);
        row.minimumEvent = 0;
        row.marketCap = 0;
        row.maximumStake = 0;
        row.maximumStakeLive = 0;
        row.maximumLiability = 0;
        row.maximumLiabilityLive = 0;
        row.offer = false;
        row.general = null;
        row.groups = null;

        const copy = Object.assign([], limits);
        setKey(Date.now());
        setLimits(copy);
    };



    const onGroupChange = () => {
        category.maximumStake = 0;
        category.maximumStakeLive = 0;
        category.maximumLiability = 0;
        category.maximumLiabilityLive = 0;

        selectedGroup.map(x => {
            var stake = parseFloat(x.maximumStake);
            var stakeLive = parseFloat(x.maximumStakeLive);
            var liability = parseFloat(x.maximumLiability);
            var liabilityLive = parseFloat(x.maximumLiabilityLive);
            if (isNaN(stake)) stake = 0;
            if (isNaN(stakeLive)) stakeLive = 0;
            if (isNaN(liability)) liability = 0;
            if (isNaN(liabilityLive)) liabilityLive = 0;
            category.maximumStake += stake;
            category.maximumStakeLive += stakeLive;
            category.maximumLiability += liability;
            category.maximumLiabilityLive += liabilityLive;
        });

        category.general.stake.prematch = category.stake;
        category.general.stake.live = category.stakeLive;
        category.general.liability.prematch = category.maximumLiability;
        category.general.liability.live = category.maximumLiabilityLive;
        category.general.offer = category.offer;

        selectCategory(category);
        setGroupKey(Date.now());
    };

    const saveGroup = () => {
        var eleminated = {};
        selectedGroup.map(x => {
            var minimumEvent = parseInt(x.minimumEvent);
            var marketCap = parseInt(x.marketCap);
            var maximumStake = parseInt(x.maximumStake);
            var maximumStakeLive = parseInt(x.maximumStakeLive);
            var maximumLiability = parseInt(x.maximumLiability);
            var maximumLiabilityLive = parseInt(x.maximumLiabilityLive);
            if (isNaN(minimumEvent)) minimumEvent = 0;
            if (isNaN(marketCap)) marketCap = 0;
            if (isNaN(maximumStake)) maximumStake = 0;
            if (isNaN(maximumStakeLive)) maximumStakeLive = 0;
            if (isNaN(maximumLiability)) maximumLiability = 0;
            if (isNaN(maximumLiabilityLive)) maximumLiabilityLive = 0;
            if (minimumEvent > 0 || maximumStake > 0 || maximumStakeLive > 0 || maximumLiability > 0 || maximumLiabilityLive > 0) {
                eleminated[x.id] = {
                    marketCap: marketCap,
                    minimumEvent: minimumEvent,
                    stake: {
                        live: maximumStakeLive,
                        prematch: maximumStake
                    },
                    liability: {
                        live: maximumLiabilityLive,
                        prematch: maximumLiability
                    },
                    offer: x.offer
                };
            }
        });

        category.groups = eleminated;
        if (Object.keys(category.groups).length == 0) category.groups = null;
    };

    if (category) {
        return <Card key={newKey} caption={parseInt(category.id) > 0 ? category.name : 'Default Player Group Configuration'} menu={
            <div className='centrum-flex'>
                <Button title='Close' onClick={() => {
                    saveGroup();
                    selectCategory(null);
                }} />
            </div>
        }>
            <Card caption='Event configuration'>
                <EditableTable model={categoryModel} data={[category]} key={groupKey} context={context} />
            </Card>
            <Card caption='Group configuration'>
                <EditableTable model={groupModel} data={selectedGroup}
                    onChange={() => onGroupChange()}
                    buttons={(row) => {
                        return <Button title='Clear' onClick={() => {
                            row.minimumEvent = 0;
                            row.marketCap = 0;
                            row.maximumStake = 0;
                            row.maximumStakeLive = 0;
                            row.maximumLiabilityLive = 0;
                            row.maximumLiability = 0;
                            row.offer = false;
                            saveGroup();
                            selectCategory(category);
                            setKey(Date.now());
                        }} />;
                    }} />
            </Card>
        </Card>;
    }

    if (!configurationEnabled) {
        return <Card caption='Category Limits' className='event-limits'>
            <div className='padding border radius margin flex gap-5'>
                <Icon icon='info-circle'/>
                <p>{lang('Sport Category Limits is not enabled for this risk management configuration, use Risk Levels')}</p>
            </div>
        </Card>;
    }

    return <Card caption='Category Limits' className='event-limits'>
        <div className={'centrum-flex stretch event-list'}>
            <div className='event-items'>
                <EditableTable key={newKey} context={context} model={categoryModel} data={limits} buttons={(row) => {
                    return <div className='centrum-flex'>
                        <Button title={'Edit Groups'} onClick={() => selectCategory(row)} />
                        {row.id != 0 && <Button title='Delete' onClick={() => deleteLimit(row)} />}
                    </div>;
                }} />
            </div>
        </div>
        <Card>
            <div className='centrum-flex padding'><Button className='alignRight' icon={'save'} title='Save' onClick={() => save()} /></div>
        </Card>
    </Card>;
};

SportCategoryLimits.propTypes = {
    categories: PropTypes.object,
    league: PropTypes.object
};
