import React from 'react';
import PropTypes from 'prop-types';

import { lang } from '../../../../Centrum/Localization/lang';
import { RangeDropdown } from '../../../../Controls/DropDown/Range/RangeDropdown';
import { apiResult } from '../../../../Application/Application';
import { FormInput, InputTypes } from '../../../../../v2/Components/Controls/Form/FormInput';
import { tonight, yesterday, today, tomorrow } from '../../../../../v2/Lib/Common/dateHelpers';
import { Button } from '../../../../../v2/Components/Controls/Button/Button';
import { Form } from '../../../../../v2/Components/Controls/Form/Form';
import { addHours, addDays } from 'date-fns';

var kpiRangeContext = {

};


export const KPIRanges = {
    'Today': 0,
    'WTD': 1,
    'MTD': 2,
    'QTD': 3,
    'YTD': 4,
    'Past24Hours': 5,
    'Past7Days': 6,
    'Past15Days': 7,
    'Past30Days': 8,
    'Custom': 9
};



export const KPIRangeContext = (name, range) => {
    var context = {
        name: name,
        assignSelector: (selector) => {
            context.selector = selector;
        },
        assignCard: (card) => {
            context.cards.push(card);
        },
        destroy: () => {
            context.cards = [];
        },
        load: (card) => {
            card.loading(true);
            context.queue.push(card);
            context.loadNext();
        },
        loadNext: () => {
            if (context.loading) return;
            if (context.queue.length == 0) return;
            var card = context.queue[0];
            context.queue.splice(0, 1);


            let start = context.dateStart;
            let end = context.dateEnd;

            if (start) {
                start = addHours(start, new Date().getTimezoneOffset() / 60);
            }

            if (end) {
                end = addHours(end, new Date().getTimezoneOffset() / 60);
            }
            apiResult(null, card.props.url, {
                rangeType: context.range,
                dateStart: start,
                dateEnd: end,
                timeZoneOffset: new Date().getTimezoneOffset() / 60 * -1,
            }, 'loading kpi', 'unable to fetch kpi ' + card.props.title).then((result) => {
                context.loading = false;
                card.loaded(Object.assign({ loading: false }, result.result));
            }).catch(() => {
                context.loading = false;
                context.loadNext();
            });
        },
        changeRange: (range) => {
            context.startDate = null;
            context.endDate = null;
            context.range = range;
            context.cards.map(x => {
                context.load(x);
            });
        },
        changeCustomRange: (range, dateStart, dateEnd) => {
            context.range = range;
            context.dateStart = dateStart;
            context.dateEnd = dateEnd;
            context.cards.map(x => {
                context.load(x);
            });
        },
        range: KPIRanges.Today,
        queue: [],
        loading: false,
        cards: [],
        selector: null,
        dateStart: null,
        dateEnd: null
    };

    kpiRangeContext[name] = context;
    return context;
};


// COMPONENT
export class KPIRangeSelector extends React.Component {
    constructor(props) {
        super(props);
        props.context.assignSelector(this);
        this.props.isCustomDate = false;
        this.props.startDate = null;
        this.props.endDate = null;
        this.state = Object.assign({}, props);

    }

    onRangeChanged(index) {
        this.setState({ startDate: null });
        this.setState({ endDate: null });
        if (index == KPIRanges['Custom']) {
            this.setState({ isCustomDate: true });
        } else {
            this.props.context.changeRange(index);
            this.setState({ isCustomDate: false });

        }

        this.props.onContextChange(index)
    }

    onHandlerCustomDate = () => {
        let startdate = this.state.startDate ?? today();
        let endDate = this.state.endDate ?? tomorrow();
        if (this.state.endDate) {
            endDate = addDays(endDate, 1)
        }
        this.props.context.changeCustomRange(KPIRanges['Custom'], startdate, endDate);
    }

    onHandleChangeDate(date, field) {
        if (field.name === 'StartDate') {
            this.setState({ startDate: date });
        } else {
            this.setState({ endDate: date });
        }
    }


    render() {
        return <div className={'flex gap-10 align-items-center auto-height'}>
            <label>{lang(this.props.title)}</label>
            <RangeDropdown className='align-right' onSelected={this.onRangeChanged.bind(this)} />
            {this.state.isCustomDate &&
                <Form onSubmit={this.onHandlerCustomDate.bind(this)}>
                    <div className='row vertical'><FormInput title='Date Start' type={InputTypes.DateTime} onChange={this.onHandleChangeDate.bind(this)} name='StartDate' value={today()} /></div>
                    <div className='row vertical'><FormInput title='Date End' type={InputTypes.DateTime} onChange={this.onHandleChangeDate.bind(this)} name='EndDate' value={today()} /></div>
                    <div className='row vertical'><Button type='submit' title='Filter' /></div>
                </Form>
            }
        </div>;
    }
}

KPIRangeSelector.defaultProps = {
    onContextChange: () => { }
};

KPIRangeSelector.propTypes = {
    title: PropTypes.string,
    context: PropTypes.object,
    isCustomDate: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    onContextChange: PropTypes.func
};
