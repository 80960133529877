import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { UserGroupTypes } from './ModelEnums';


export const UserGroupModels = {
    userGroupInformation: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'GroupType',
                display: 'Group Type',
                type: InputTypes.select,
                values: UserGroupTypes
            },
            {
                name: 'SuperGroup',
                display: 'Super Group',
                type: InputTypes.bool
            },
            {
                name: 'Enabled',
                type: InputTypes.bool
            }
        ]
    },
    editUserGroup: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                hidden: true
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'SuperGroup',
                display: 'Super Group',
                type: InputTypes.bool
            },
            {
                name: 'GroupType',
                display: 'Group Type',
                type: InputTypes.select,
                values: UserGroupTypes
            },
            {
                name: 'Enabled',
                type: InputTypes.bool
            }
        ]
    }
};