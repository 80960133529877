import './_search.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { CentrumForm, FormTypes } from '../CentrumForm/CentrumForm';
import { Table, SortingDirection } from '../Table/Table';
import { CentrumComponent } from '../CentrumComponent';
import AnimateHeight from 'react-animate-height';
import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { getHistory } from '../../routers/AppRouter';
import { models } from '../Shared';
import { exportExcel } from '../ExcelExport';
import { getLocalStorage } from '../helpers';
import { Icon } from '../../Controls/Icon/Icon';
import { isValidDate } from '../../../v2/Lib/Common/dateHelpers';
import { addHours, addSeconds } from 'date-fns';


const searchFormButtons = [
    {
        type: 'submit',
        name: 'Submit',
        caption: 'Search'
    },
    {
        type: 'reset',
        name: 'Reset',
        caption: 'Reset'
    }
];

export class SearchResult extends CentrumComponent {
    constructor(props) {
        super(props);
    }

    onPageClicked(index) {
        if (this.props.onPageClicked !== null) this.props.onPageClicked(index);
    }


    getTable() {
        return this.tableComponent;
    }

    render() {
        let data = this.props.data;
        if (data !== null) data.model = this.props.model;

        var sorting = {
            enabled: true,
            column: '',
            direction: SortingDirection.None
        };

        if (this.props.data != null) {
            sorting = this.props.data.sorting;
        }

        if (sorting === undefined || sorting === null) {
            sorting = {
                enabled: true,
                column: '',
                direction: SortingDirection.None
            };
        }
        return (
            <div className='search-results'>
                <Table
                    data={data}
                    model={this.props.model}
                    caption={this.props.caption} actions={this.props.actions}
                    multipleSelect={this.props.multipleSelect}
                    showPagination={this.props.showPagination}
                    sorting={sorting}
                    camelCase={this.props.camelCase}
                    showResults={this.props.showResults}
                    showResultsSummary={this.props.showSummary}
                    showRecordsPerPageSelection={this.props.showRecordsPerPageSelection}
                    exportToExcel={this.props.exportToExcel}
                    exportAllData={this.props.exportAllData}
                    context={this.props.context}
                    events={{
                        onHeaderClicked: this.props.onSortClicked,
                        onActionClicked: this.props.onActionClicked,
                        onRenderAction: this.props.onRenderAction,
                        onRenderRow: this.props.onRenderRow,
                        onRowClicked: this.props.onRowClicked,
                        onDoubleClick: this.props.onDoubleClick,
                        onColumnClicked: this.props.onColumnClicked,
                        onExportAllPagesToExcel: this.props.onExportAllPagesToExcel,
                        onPageClicked: this.onPageClicked.bind(this),
                        onChangeRowsPerPage: this.props.onChangeRowsPerPage != null ? this.props.onChangeRowsPerPage.bind(this) : null
                    }}
                    ref={(r) => this.tableComponent = r}>
                    {this.props.children}
                </Table>
            </div>
        );
    }
}

SearchResult.defaultProps = {
    caption: 'Results',
    data: null,
    children: null,
    actions: null,
    multipleSelect: false,
    exportToExcel: true,
    exportAllData: false,
    camelCase: true,
    showResults: true,
    showPagination: true,
    onActionClicked: null,
    onRowClicked: null,
    onColumnClicked: null,
    onRenderAction: null,
    onRenderRow: null,
    onDoubleClick: null,
    onSortClicked: null,
    onPageClicked: null,
    onChangeRowsPerPage: null
};

SearchResult.propTypes = {
    caption: PropTypes.string,
    model: PropTypes.string,
    multipleSelect: PropTypes.bool,
    showResults: PropTypes.bool,
    camelCase: PropTypes.bool,
    showPagination: PropTypes.bool,
    data: PropTypes.object,
    exportToExcel: PropTypes.bool,
    exportAllData: PropTypes.bool,
    children: PropTypes.node,
    actions: PropTypes.object,
    onActionClicked: PropTypes.func,
    onRowClicked: PropTypes.func,
    onColumnClicked: PropTypes.func,
    onRenderAction: PropTypes.func,
    onRenderRow: PropTypes.func,
    onPageClicked: PropTypes.func,
    onSortClicked: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onExportAllPagesToExcel: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func
};

export class Search extends CentrumComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }


    onSubmit(data, qb) {
        if (this.props.changeBrowserHistory) {
            var pathname = window.location.pathname;
            getHistory().push(pathname + '?&qb=' + encodeURIComponent(JSON.stringify(qb)));
        }
        //qb.timeDiff = new Date().getTimezoneOffset() / 60;
        qb.timeZone = window.user.timeZoneConverted;
        qb.timeDiff = -1 * window.user.timeZoneConverted;
        var timeDiff = new Date().getTimezoneOffset() / 60;
        qb.fields.map(x => {
            if (x.between) {
                var minDate = new Date(x.min);
                var maxDate = new Date(x.max);
                var dateValue = new Date(x.value);

                if (isValidDate(minDate)) {
                    x.min = addHours(minDate, timeDiff * -1);
                    x.min = addHours(x.min, qb.timeDiff);
                    //x.min = addHours(x.min, 1);
                    x.min = addSeconds(x.min, -1).toISOString();
                }
                if (isValidDate(maxDate)) {
                    x.max = addHours(maxDate, timeDiff * -1);
                    x.max = addHours(x.max, qb.timeDiff);
                    //x.max = addHours(x.max, 1);
                    x.max = addSeconds(x.max, -1).toISOString();
                }
                if (isValidDate(dateValue)) {
                    x.value = addHours(dateValue, timeDiff * -1);
                    x.value = addHours(x.value, qb.timeDiff).toISOString();
                }
            }
        });
        qb.currency = getLocalStorage('selected-currency');
        qb.recordsPerPage = this.state.recordsPerPage ?? 20;

        if (this.props.onSubmit !== null) this.props.onSubmit(data, qb);
    }

    sort(sorting) {
        var qb = Object.assign({}, this.props.data.request);
        qb.sorting = sorting;
        qb.exportToExcel = false;
        this.props.onSubmit(null, qb ?? {});
    }

    componentDidMount() {
        var data = null;
        if (this.searchOnStart) {
            if (this.props.model == this.searchOnStart) {
                data = this.form.getFormData();
                this.props.onSubmit(data.serialized, data.postData);
            }
        }
        if (this.props.searchOnStart) {
            data = { serialized: {}, postData: {} };
            if (this.form) data = this.form.getFormData();
            this.props.onSubmit(data.serialized, data.postData);
        }
    }

    submit() {
        var data = this.form.getFormData();
        data.exportToExcel = false;
        data.recordsPerPage = this.state.recordsPerPage ?? 20;
        this.props.onSubmit(data.serialized, data.postData);
    }

    getTable() {
        return this.resultComponent.getTable();
    }

    getData() {
        var hash = window.location.hash;
        const decodeHTML = (data) => {
            data = data.replaceAll('%20', ' ');
            data = data.replaceAll('%22', '');
            return data;
        };


        var fields = [];

        hash.split('#').map(info => {
            var field = info.substr(0, info.indexOf('='));
            var data = info.replace(field + '=', '');

            if (field == '_run') {
                this.searchOnStart = data;
            } else {
                fields.push({ name: field, value: decodeHTML(data) });
            }
        });
        return fields;
    }

    onModelSet(model) {
        var data = this.getData();
        if (this.props.onModelSet) {
            model = this.props.onModelSet(model);
        }

        this.model = model;


        model?.fields.map(x => {
            var field = data.find(f => f.name.toLowerCase() == x.name.toLowerCase());
            if (field) {
                if (x.type == InputTypes.dateTime) {
                    if (x.queryBuilderOptions) {
                        if (x.queryBuilderOptions.Between) {
                            //console.log(x);
                            //console.log(field);

                            var min = new Date(field.value.split('|')[0]);
                            var max = new Date(field.value.split('|')[1]);

                            x.queryBuilderOptions.Min = min;
                            x.queryBuilderOptions.Max = max;
                        }
                    }
                }
            }

        });
        return model;
    }

    refresh() {
        var request = Object.assign({}, this.props.data.request);
        request.exportToExcel = false;
        request.recordsPerPage = this.state.recordsPerPage ?? 20;
        this.props.onSubmit(null, request);
    }

    onPageClicked(index) {
        var request = Object.assign(this.props.data.request, { currentPage: index - 1, sorting: this.sorting });
        request.exportToExcel = false;
        request.recordsPerPage = this.state.recordsPerPage ?? 20;
        if (this.props.onSubmit !== null) this.props.onSubmit(null, request);
        if (this.props.onPageClicked !== null) this.props.onPageClicked(index);
    }

    onExportAllPagesToExcel() {
        if (!this.props.data) return;
        var qb = Object.assign({}, this.props.data.request);
        qb.exportToExcel = true;
        console.log(qb);
        this.props.onSubmit(null, qb).then((result) => {
            if (this.props.resultModel.onParseExcel) {
                this.props.resultModel.onParseExcel(result, this.props.context);
                return;
            }

            var option = {};
            var model = models.get(this.props.resultModel);

            var headers = [];
            var copyFields = [...model.fields];


            option.fileName = 'export.xlsx';


            var data = [];
            var sheetFields = [];



            if (this.props.exportAllData) {
                var row0 = result.result[0];
                Object.keys(row0).map(fieldName => {
                    var field = model.fields.find(x => x.name == fieldName);
                    if (!field) {
                        copyFields.push({
                            name: fieldName
                        });
                    }
                });
            }


            qb.fields.map(field => {
                var row = {};


                if (field.between) {
                    row[(field.display ? field.display : field.name)] = field.display ? field.display : field.name;
                    row['Min'] = 'Start';
                    row[(field.display ? field.display : field.name) + ' Start'] = field.min;
                    console.log(field.min);
                    row['Max'] = 'End';
                    row[(field.display ? field.display : field.name) + ' End'] = field.max;
                } else {
                    row[(field.display ? field.display : field.name) + '_Name'] = field.display ? field.display : field.name;
                    row[field.display ? field.display : field.name] = field.value;
                }
                sheetFields.push(row);
            });
            sheetFields.push({});
            var timeDiff = new Date().getTimezoneOffset() / 60;
            sheetFields.push({ 'GMT': 'GMT', 'diff': timeDiff });

            copyFields.map(x => {
                headers.push(x.display ? x.display : x.name);
            });

            if (this.props.onRenderExcel) this.props.onRenderExcel(data);

            result.result.map(x => {
                var row = {};
                copyFields.map(field => {
                    var val = x[field.name];
                    if (field.formatter) {
                        val = field.formatter(val, x, false, true);
                    } else if (field.type == InputTypes.dateTime && val) {
                        val = new Date(val + 'Z').toLocaleString('en-Gb', { hours12: false });
                    } else if (field.type == InputTypes.date && val) {
                        val = new Date(val + 'Z').toLocaleDateString('en-Gb', { hours12: false });
                    }

                    row[field.display ? field.display : field.name] = val;
                });
                data.push(row);
            });

            if (result.footer) {
                const footer = result.footer;
                var row = {};
                var rowCount = 0;

                var multipleRows = {};
                copyFields.map(field => {
                    var val = footer[field.name];
                    if (!val) return row[field.display ? field.display : field.name] = '';
                    if (field.formatter) {
                        val = field.formatter(val, null, true, true);
                    }
                    var name = field.display ? field.display : field.name;
                    if (val instanceof Array) {
                        if (rowCount < val.length) rowCount = val.length;
                        multipleRows[name] = true;
                        row[name] = val[0];
                    } else {
                        row[name] = val;
                    }
                });
                data.push(Object.assign({}, row));
                if (rowCount > 1) {
                    for (var i = 1; i < rowCount; i++) {
                        row = {};
                        copyFields.map(field => {
                            var val = footer[field.name];
                            var name = field.display ? field.display : field.name;
                            if (!val) return row[name] = '';

                            if (multipleRows[name]) {
                                val = field.formatter(val, null, true, true);
                                val = val[i];
                                if (val) {
                                    row[name] = val;
                                }
                            }
                        });
                        data.push(row);

                    }
                }
            }

            option.datas = [
                {
                    sheetData: data,
                    sheetName: 'report',
                    sheetHeader: headers
                },
                {
                    sheetData: sheetFields,
                    sheetName: 'information',
                    sheetHeader: null
                }
            ];
            option.workbook = {
                SheetNames: [],
                Sheets: {},
            };

            var excel = new exportExcel(option);
            excel.saveExcel();


        });
    }

    onSortClicked(column, sortingInformation) {
        var result = Object.assign({}, sortingInformation);
        switch (sortingInformation.direction) {
            case SortingDirection.Asc:
                result.direction = SortingDirection.Desc;
                result.column = column;
                break;
            case SortingDirection.Desc:
                result.direction = SortingDirection.Asc;
                result.column = column;
                break;
            case SortingDirection.None:
                result.direction = SortingDirection.Desc;
                result.column = column;
                break;
        }

        this.sorting = result;
        if (this.props.onSortingClicked) {
            var result = this.props.onSortingClicked({ column: column, direction: result.direction });
            if (!result) return;
        }

        this.sort(result);
    }

    minimize() {
        this.setState({ minimized: !this.state.minimized });
    }

    renderComponent() {
        return (
            <div className={'search ' + this.props.className} key={'search' + this.props.model}>
                {this.props.caption && <h1>{this.props.caption}</h1>}
                {this.state.showQuery &&
                    <React.Fragment>
                        <AnimateHeight
                            duration={500}
                            height={this.state.minimized ? 0 : 'auto'}
                        >
                            <CentrumForm
                                model={this.props.model}
                                onModelSet={this.onModelSet.bind(this)}
                                onSubmit={this.onSubmit.bind(this)}
                                type={FormTypes.search}
                                ref={(r) => this.form = r}
                                buttons={searchFormButtons} />
                        </AnimateHeight>
                        <div key='minimizer' className='minimize-layout' onClick={() => this.minimize()}><Icon icon={this.state.minimized ? 'angle-down' : 'angle-up'} size='1x' /></div>
                    </React.Fragment>
                }
                <SearchResult
                    ref={(r) => this.resultComponent = r}
                    model={this.props.resultModel}
                    data={this.props.data}
                    actions={this.props.actions}
                    exportToExcel={this.props.exportToExcel}
                    exportAllData={this.props.exportAllData}
                    context={this.props.context}
                    camelCase={this.props.camelCase}
                    showSummary={this.props.showSummary}
                    showResults={this.props.showResults}
                    showPagination={this.props.showPagination}
                    showRecordsPerPageSelection={this.props.showRecordsPerPageSelection}
                    multipleSelect={this.props.multipleSelect}
                    onActionClicked={this.props.onActionClicked}
                    onRowClicked={this.props.onRowClicked}
                    onColumnClicked={this.props.onColumnClicked}
                    onRenderAction={this.props.onRenderAction}
                    onRenderRow={this.props.onRenderRow}
                    onExportAllPagesToExcel={this.onExportAllPagesToExcel.bind(this)}
                    onSortClicked={this.onSortClicked.bind(this)}
                    onPageClicked={this.onPageClicked.bind(this)}
                    onDoubleClick={(row) => {
                        if (!this.props.onDoubleClick) return;
                        this.props.onDoubleClick(row, this.props.data.request);
                    }}
                    onChangeRowsPerPage={(amount) => {
                        this.setState({ recordsPerPage: parseInt(amount) });
                        if (!this.props.data?.request) {
                            return;
                        }
                        var request = Object.assign(this.props.data.request, { currentPage: this.props.data.request.currentPage, sorting: this.sorting });
                        request.recordsPerPage = parseInt(amount);
                        request.exportToExcel = false;
                        if (this.props.onSubmit !== null) this.props.onSubmit(null, request);

                    }}>
                    {this.props.children}
                </SearchResult>
            </div>
        );
    }

    render() {
        return super.render();
    }
}


Search.defaultProps = {
    loading: false,
    caption: null,
    changeBrowserHistory: false,

    minimized: false,
    multipleSelect: false,
    camelCase: true,
    data: null,
    children: null,
    resultModel: null,
    actions: null,
    showQuery: true,
    showResults: true,
    showSummary: true,
    showPagination: true,
    showRecordsPerPageSelection: false,
    onRenderExcel: null,
    onActionClicked: null,
    onColumnClicked: null,
    onRowClicked: null,
    onRenderAction: null,
    onRenderRow: null,
    onSubmit: null,
    onPageClicked: null,
    onDoubleClick: null,
    onModelSet: function (model) {
        return model;
    }
};

Search.propTypes = {
    laoding: PropTypes.bool,
    caption: PropTypes.string,
    model: PropTypes.string,
    changeBrowserHistory: PropTypes.bool,

    className: PropTypes.string,
    searchOnStart: PropTypes.bool,
    children: PropTypes.node,
    fields: PropTypes.array,
    camelCase: PropTypes.bool,
    resultModel: PropTypes.string,
    data: PropTypes.object,
    actions: PropTypes.object,
    multipleSelect: PropTypes.bool,
    showSummary: PropTypes.bool,
    showResults: PropTypes.bool,
    showQuery: PropTypes.bool,
    showPagination: PropTypes.bool,
    showRecordsPerPageSelection: PropTypes.bool,
    onRenderExcel: PropTypes.func,
    context: PropTypes.object,
    onActionClicked: PropTypes.func,
    onRowClicked: PropTypes.func,
    onColumnClicked: PropTypes.func,
    onRenderAction: PropTypes.func,
    onSortingClicked: PropTypes.func,
    onSubmit: PropTypes.func,
    onModelSet: PropTypes.func,
    onRenderRow: PropTypes.func,
    onPageClicked: PropTypes.func,
    onDoubleClick: PropTypes.func
};