// INITIALIZE STATE

import { prepareVolumesData, selectCurrency, volumeSummary } from './VolumesDataParser';

const initialState = {
    volumes: null,
    volumesSummary: [],
    betSummary: [],
    currency: null,
    sports: {},
    regions: {},
    leagues: {},
    events: {},
    rate: 1,
    operators: [],
    notifications: []
};




// REDUCER
export const VolumesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_VOLUMES':
            try {
                if (state.data && state.data.NotificationCount !== action.payload.NotificationCount) {
                    new Notification(`Check risk notifications ${action.payload.NotificationCount} notifications`);
                }
                state.data = action.payload;
                state = prepareVolumesData(state);
            } catch (err) {
                console.log(err);
            }

            return state;
        case 'VOLUME_OPERATORS':
            var operators = [];
            action.payload.map(x => operators.push(x.id));
            state.operators = action.payload;
            state.operatorIds = operators.join(',');
            return { ...state };
        case 'SELECT_VOLUME_OPERATOR':
            state.operator = action.payload;
            if (action.payload.indexOf('Main') == 0) {
                let operators = [];
                state.operators.map(x => operators.push(x.id));
                state.operatorIds = operators.join(',');
            } else {
                state.operatorIds = action.payload;
            }
            return { ...state };
        case 'SELECT_VOLUMES_CURRENCY':
            var result = selectCurrency(action.payload, state);
            result = volumeSummary(result);
            return result;
        case 'VOLUME_NOTIFICATIONS':
            state.notifications = action.payload;
            return { ...state };
    }
    return state;
};