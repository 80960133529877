import './_backoffice-language.scss';

import React, { useState } from 'react';
import { Button } from '../../../components/Controls/Buttons/Button';
import { FormInput } from '../../Components/Controls/Form/FormInput';
import { Icon } from '../../Components/Icon/Icon';
import { Page } from '../../Components/Page/Page';
import { API } from '../../Lib/Api/Api';
import { Endpoints } from '../../../components/Application/Application';

const languages = ['en', 'tr', 'de', 'ru', 'ar'];
const languageNames = {
    en: 'English',
    de: 'German',
    ru: 'Russian',
    ar: 'Arabic',
    tr: 'Turkish',
};

export const BackofficeLanguagePage = () => {
    const [data, setData] = useState(window.__languageData);
    const [filter, setFilter] = useState(null);
    const [t, setTimer] = useState(null);
    const [newKey, setNewKey] = useState(null);

    return <Page className='backoffice-language'>
        <div className='padding'>
            <Button title='Save' onClick={() => {
                var copy = { ...data };
                setData(copy);
                API.post(Endpoints.CMSSaveBackofficeLanguage, copy, 'please wait');
            }} />
        </div>
        <div className='flex padding gap-20'>
            <FormInput title='Search' onChange={(value) => {
                if (t) clearTimeout(t);
                if (value.length < 3) {
                    setTimer(null);
                    setFilter(null);
                    return;
                }
                setTimer(setTimeout(() => {
                    setFilter(value.toLowerCase());
                }, 100));
            }} />
            <div className='flex gap-10'>
                <FormInput title='New Key' onChange={(value) => {
                    setNewKey(value);
                }} />
                <Button title='Add New Key' onClick={() => {
                    var copy = { ...data };
                    console.log(newKey);
                    copy[newKey] = {

                    };
                    setData(copy);
                }} />
            </div>
        </div>
        <div className='language-cards'>
            {
                Object.keys(data).filter((x) => !filter ? true : x.toLowerCase().indexOf(filter) >= 0).sort((a, b) => a > b ? 1 : -1).map(title => {
                    return <div className='flex vertical gap-5 border radius surface language-card' key={title}>
                        <div className='flex gap-5 padding surface'>
                            <label className=''>{title}</label>
                            <Icon icon='times' className='clickable align-right' onClick={() => {
                                delete data[title];
                                setData({ ...data });
                            }} />
                        </div>
                        <div className='flex vertical gap-5 padding'>
                            {languages.map(x => {
                                return <FormInput key={x} title={languageNames[x]} value={data[title][x]} onChange={(value) => {
                                    data[title][x] = value;
                                }} >
                                    <Icon icon='times' className='clickable' onClick={(e) => {
                                        e.target.parentNode.getElementsByTagName('input')[0].value = '';
                                        delete data[title][x].value;
                                        setData({ ...data });
                                    }} />
                                </FormInput>;
                            })}
                        </div>
                    </div>;
                })
            }
        </div>
    </Page >;
};