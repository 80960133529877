import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../../v2/Components/Card/Card';
import { AutoCompleteGame } from '../../../../Casino/AutoCompleteGame/AutoCompleteGame';
import { Game } from '../../../../Casino/Game/Game';
import { Button } from '../../../../../v2/Components/Controls/Button/Button';
import { ProviderTypes } from '../../../../Centrum/_models/ModelEnums';
import { Icon } from '../../../../../v2/Components/Icon/Icon';
import { lang } from '../../../../Centrum/Localization/lang';
import { SliderConfigurator } from '../AppFeatures/AppSlider';





export const AppGamingPage = (props) => {
    const games = props.games.result;
    const page = props.configuration;
    const [providers, setProviders] = useState([]);
    const [tags, setTags] = useState({});

    const [configuration, setConfiguration] = useState(page.configuration);
    const [selectedGame, selectGame] = useState(configuration?.game);

    const providerSelectRef = React.createRef();
    const disabledProviderSelectRef = React.createRef();

    const [updateRef, setUpdateRef] = useState(0);



    useEffect(() => {
        var p = {};
        var t = {};
        games.map(x => {
            p[x.providerId] = {
                id: x.providerId,
                name: x.providerName
            };

            var tag = x.tag?.split(',') ?? [];
            if (tag.length > 0) {
                tag.map(y => {
                    if (y == '') y = 'other';
                    if (!t[y]) {
                        t[y] = 0;
                    }
                    t[y]++;
                });
            }
        });


        setTags(t);
        setProviders(Object.values(p).sort((a, b) => a.name > b.name ? 1 : -1));
    }, []);

    useEffect(() => {
        var copy = {...page};
        copy.configuration = configuration;
        props.onSave(page);
    }, [configuration]);

    useEffect(() => {
        var copy = { ...configuration };
        copy.game = selectedGame;
        if (!selectedGame) {
            delete copy.game;
        }

        setConfiguration(copy);
    }, [selectedGame]);

    const toggleProviderType = (type, value) => {
        var copy = { ...configuration };
        if (value) {
            copy.providerTypes |= type;
        } else {
            copy.providerTypes &= ~type;
        }

        setConfiguration(copy);
    };

    const toggleLiveGamesOnly = () => {
        var copy = { ...configuration };
        copy.liveGamesOnly = !copy.liveGamesOnly;
        setConfiguration(copy);
    };

    const toggleProvider = (id) => {
        id = parseInt(id);
        var copy = { ...configuration };
        if (!copy.providers) {
            copy.providers = {};
        }

        if (copy.providers[id]) {
            delete copy.providers[id];
        } else {
            copy.providers[id] = true;
        }

        if (Object.keys(copy.providers).length == 0) {
            delete copy.providers;
        }

        setUpdateRef(Date.now());
        setConfiguration(copy);
    };

    const toggleDisabledProvider = (id) => {
        id = parseInt(id);
        var copy = { ...configuration };
        if (!copy.disabledProviders) {
            copy.disabledProviders = {};
        }

        if (copy.disabledProviders[id]) {
            delete copy.disabledProviders[id];
        } else {
            copy.disabledProviders[id] = true;
        }

        if (Object.keys(copy.disabledProviders).length == 0) {
            delete copy.disabledProviders;
        }

        setUpdateRef(Date.now());
        setConfiguration(copy);
    };

    const toggleGame = (id) => {
        id = id.toString();
        var copy = { ...configuration };

        if (!copy.games) {
            copy.games = {};
        }

        if (copy.games[id]) {
            delete copy.games[id];
        } else {
            copy.games[id] = true;
        }

        if (Object.keys(copy.games).length == 0) {
            delete copy.games;
        }

        setUpdateRef(Date.now());
        setConfiguration(copy);
    };

    const toggleDisabledGame = (id) => {
        id = parseInt(id);
        var copy = { ...configuration };
        if (!copy.disabledGames) {
            copy.disabledGames = [];
        }

        if (copy.disabledGames.find(y => y == id)) {
            copy.disabledGames = copy.disabledGames.filter(x => x != id);
        } else {
            copy.disabledGames.push(id);
        }

        if (copy.disabledGames.length == 0) {
            delete copy.disabledGames;
        }
        setUpdateRef(Date.now());
        setConfiguration(copy);
    };

    const toggleTag = (tag) => {
        var copy = { ...configuration };
        if (!copy.tags) {
            copy.tags = [];
        }

        if (copy.tags.find(x => x == tag)) {
            copy.tags = copy.tags.filter(x => x != tag);
        } else {
            copy.tags.push(tag);
        }
        if (copy.tags.length == 0) {
            delete copy.tags;
        }
        setUpdateRef(Date.now());
        setConfiguration(copy);
    };

    const toggleDisabledTag = (tag) => {
        var copy = { ...configuration };
        if (!copy.disabledTags) {
            copy.disabledTags = [];
        }

        if (copy.disabledTags.find(x => x == tag)) {
            copy.disabledTags = copy.disabledTags.filter(x => x != tag);
        } else {
            copy.disabledTags.push(tag);
        }
        if (copy.disabledTags.length == 0) {
            delete copy.disabledTags;
        }
        setUpdateRef(Date.now());
        setConfiguration(copy);
    };


    return <div className='flex vertical gap-10 app-configuration'>
        <Card title='Slider'>
            <SliderConfigurator name={page.key} configuration={configuration?.slider} prefix={props.prefix} defaultLanguage={props.defaultLanguage} onSave={(data) => {
                var copy = {...page};
                copy.configuration.slider = data;
                props.onSave(copy);
            }} />
        </Card>
        <Card title='Launch Game' collapsible={true} collapsed={true} >
            <div className='row'>
                <div className='flex gap-5'>
                    <AutoCompleteGame games={games} onSelected={(game) => selectGame(game.gameId)} />
                </div>

            </div>
            <div className='row'>
                {configuration.game && <Game gameId={configuration.game} games={props.games.result} details={true} >
                    <div className='padding center'>
                        <Button title='Remove' onClick={() => {
                            selectGame(null);
                        }} />
                    </div>
                </Game>}
            </div>
        </Card>
        <Card title='Filters' className={configuration.game ? 'disabled' : ''}>
            <div className='flex vertical gap-10'>
                <div className='flex vertical border radius'>
                    <label className='padding-left padding-right padding'>Provider Types</label>
                    <div className='flex gap-10 border-top padding'>
                        {
                            Object.keys(ProviderTypes).map((type) => {
                                var checked = configuration.providerTypes & parseInt(type);
                                if (type == 1) return;
                                if (type == 64) return;
                                if (type == 128) return;
                                return <div className='flex gap-5 clickable' key={type} onClick={() => {
                                    toggleProviderType(type, !checked);
                                }}>
                                    <Icon icon={checked ? 'check-square' : 'square'} type='far' />
                                    <span>{ProviderTypes[type]}</span>
                                </div>;
                            })
                        }
                    </div>
                </div>
                <div className='flex vertical border radius'>
                    <div className='padding-left padding-right padding flex gap-5' onClick={() => {
                        toggleLiveGamesOnly();
                    }}><label>{lang('Live games only')}</label><Icon icon={configuration.liveGamesOnly ? 'check-square' : 'square'} type='far' /></div>
                    <div className='flex gap-10 border-top padding'>
                        <span>{lang('This option will only enable live games.')}</span>
                    </div>
                </div>
                <div className='flex vertical border radius'>
                    <label className='padding-left padding-right padding'>Providers</label>
                    <div className='flex gap-10 border-top padding'>
                        <select ref={providerSelectRef} key={updateRef}>{providers.map(x => {
                            if (configuration?.providers && configuration.providers[x.id]) return;
                            return <option key={x} value={x.id}>{x.name}</option>;
                        }
                        )}</select>
                        <Button title='Add' onClick={() => {
                            var id = providerSelectRef.current.value;
                            toggleProvider(parseInt(id));
                        }} />
                    </div>
                    <div className='flex gap-10 padding'>
                        {configuration?.providers ? Object.keys(configuration?.providers).sort((a, b) => {
                            var pA = providers.find(y => y.id == parseInt(a));
                            var pB = providers.find(y => y.id == parseInt(b));
                            return pA.name > pB.name ? 1 : -1;
                        }).map(x => {
                            var provider = providers.find(y => y.id == x);
                            return <div className='flex gap-5 clickable' key={x} onClick={() => {
                                toggleProvider(x);
                            }}>
                                <Icon icon={'check-square'} />
                                <span>{provider.name}</span>
                            </div>;
                        }) : null}
                    </div>
                </div>
                <div className='flex vertical border radius'>
                    <label className='padding-left padding-right padding'>Disabled Providers</label>
                    <div className='flex gap-10 border-top padding'>
                        <select ref={disabledProviderSelectRef} key={updateRef}>{providers.map(x => {
                            if (configuration?.disabledProviders && configuration.disabledProviders[x.id]) return;
                            return <option key={x} value={x.id}>{x.name}</option>;
                        }
                        )}</select>
                        <Button title='Add' onClick={() => {
                            var id = disabledProviderSelectRef.current.value;
                            toggleDisabledProvider(parseInt(id));
                        }} />
                    </div>
                    <div className='flex gap-10 padding'>
                        {configuration?.disabledProviders ? Object.keys(configuration?.disabledProviders).sort((a, b) => {
                            var pA = providers.find(y => y.id == parseInt(a));
                            var pB = providers.find(y => y.id == parseInt(b));
                            return pA.name > pB.name ? 1 : -1;
                        }).map(x => {
                            var provider = providers.find(y => y.id == x);
                            return <div className='flex gap-5 clickable' key={x} onClick={() => {
                                toggleDisabledProvider(x);
                            }}>
                                <Icon icon={'check-square'} />
                                <span>{provider.name}</span>
                            </div>;
                        }) : null}
                    </div>
                </div>
                <div className='flex vertical border radius'>
                    <label className='padding-left padding-right padding'>Enabled Games</label>
                    <div className='flex gap-10 border-top padding'>
                        <AutoCompleteGame games={games} onSelected={(game) => toggleGame(game.gameId)} />
                    </div>
                    <div className='flex gap-10 padding'>
                        {configuration?.games ? Object.keys(configuration?.games).sort((a, b) => {
                            var pA = games.find(y => y.gameId == parseInt(a));
                            var pB = games.find(y => y.gameId == parseInt(b));
                            return pA.name > pB.name ? 1 : -1;
                        }).map(x => {
                            var game = games.find(y => y.gameId == x);
                            return <div className='flex gap-5 clickable' key={x}>
                                <Game gameId={game.gameId} games={props.games.result} details={true} >
                                    <Button title='Remove' onClick={() => toggleGame(game.gameId)} />
                                </Game>
                            </div>;
                        }) : null}
                    </div>
                </div>
                <div className='flex vertical border radius'>
                    <label className='padding-left padding-right padding'>Disabled Games</label>
                    <div className='flex gap-10 border-top padding'>
                        <AutoCompleteGame games={games} onSelected={(game) => {
                            toggleDisabledGame(game.gameId);
                        }} />
                    </div>
                    <div className='flex gap-10 padding'>
                        {configuration?.disabledGames ? configuration?.disabledGames.sort((a, b) => {
                            var pA = games.find(y => y.gameId == parseInt(a));
                            var pB = games.find(y => y.gameId == parseInt(b));
                            return pA.name > pB.name ? 1 : -1;
                        }).map(x => {
                            var game = games.find(y => y.gameId == x);
                            if (!game) return <div className='padding warning gap-5 flex' key={x}>
                                <Icon icon='triangle-exclamation' /><span>{lang('Game not found')}</span><span>{lang('Id: %1', [x])}</span>
                                <Button title='Remove' onClick={() => toggleDisabledGame(x)} />
                            </div>;
                            return <div className='flex gap-10 clickable' key={x}>
                                <Game gameId={game.gameId} games={props.games.result} details={true} >
                                    <Button title='Remove' onClick={() => toggleDisabledGame(game?.gameId)} />
                                </Game>
                            </div>;
                        }) : null}
                    </div>
                </div>
                <div className='flex vertical border radius'>
                    <label className='padding-left padding-right padding'>Enabled Tags</label>
                    <div className='flex gap-10 padding'>
                        {Object.keys(tags).map(x => {
                            var totalGames = tags[x];
                            var checked = configuration?.tags && configuration.tags.find(y => y == x);
                            return <div className='flex gap-5 clickable' key={x} onClick={() => {
                                toggleTag(x);
                            }}>
                                <Icon icon={checked ? 'check-square' : 'square'} type='far' />
                                <div className='flex gap-5'>
                                    <span>{x}</span><span className='small bold'>({totalGames})</span>
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
                <div className='flex vertical border radius'>
                    <label className='padding-left padding-right padding'>Disabled Tags</label>
                    <div className='flex gap-10 border-top padding'>
                        {Object.keys(tags).map(x => {
                            var totalGames = tags[x];
                            var checked = configuration?.disabledTags && configuration.disabledTags.find(y => y == x);
                            return <div className='flex gap-5 clickable' key={x} onClick={() => {
                                toggleDisabledTag(x);
                            }}>
                                <Icon icon={checked ? 'check-square' : 'square'} type='far' />
                                <div className='flex gap-5'>
                                    <span>{x}</span><span className='small bold'>({totalGames})</span>
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
            </div>
        </Card>
    </div>;
};


AppGamingPage.defaultProps = {
    configuration: {},
    prefix: '',
    defaultLanguage: 'en',
    games: [],
    onSave: () => { }

};

AppGamingPage.propTypes = {
    configuration: PropTypes.object,
    prefix: PropTypes.string,
    defaultLanguage: PropTypes.string,
    games: PropTypes.array,
    onSave: PropTypes.func,
};


