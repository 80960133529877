import { CHANGE_LANGUAGE_PENDING, CHANGE_LANGUAGE_FULFILLED, CHANGE_LANGUAGE_REJECTED } from '../actions/LanguageActions';



// INITIALIZE STATE

const initialState = {
    selected: 'En'
};




// REDUCER
export const LanguageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE_PENDING:
            return {
                ...state
            };
        case CHANGE_LANGUAGE_FULFILLED:
            localStorage.setItem('portal_backend_admin_language',action.payload.result);
            return {
                ...state,
                selected:action.payload.result
            };
        case CHANGE_LANGUAGE_REJECTED:
            return {
                ...state
            };
        default:
            return state;
    }
};