import './_volumes.scss';
import React, { useEffect, useState } from 'react';

//import MiniChart from 'react-mini-chart';
import { application, Endpoints } from '../../../Application/Application';
import { getLocalStorage, setLocalStorage } from '../../../Centrum/helpers';
import { currency, lang } from '../../../Centrum/Localization/lang';
import { Tab } from '../../../Centrum/Tab/Tab';
import { SportEventStatus } from '../../../Centrum/_models/ModelEnums';
import { Button } from '../../../Controls/Buttons/Button';
import { Switch } from '../../../Controls/Inputs/Switch/Switch';
import { Table } from '../../../Controls/Table/Table';
import { betStart, betStop } from '../actions/eventActions';
import { EventSummary } from './EventDetails';
import { LeagueVolumes } from './LeagueVolumes';
import { renderVolumeInformation } from './VolumeInformation';
import { Icon } from '../../../Controls/Icon/Icon';
import { SportEventLimits } from '../Configurations/SportsbookLimits/SportEventLimits';
import { PendingTickets } from './PendingTickets';
import { EditableTable } from '../../../Controls/Table/Editable/EditableTable';
import { SelectionVolumes } from './SelectionVolumes';
import { API, DataEndpoints } from '../../../../v2/Lib/Api/Api';
import { useDispatch, useSelector } from 'react-redux';
import { VolumesSummary } from './Components/VolumeSummary';
import { Card } from '../../../../v2/Components/Card/Card';
import { BetsSummary } from './Components/BetsSummary';
import { RiskNotifications } from './RiskNotifications';


const volumeSummaryModel = {
    fields: [
        {
            name: 'Currency'
        },
        {
            name: 'Prematch',
            display: 'Prematch',
            formatter: (val, row) => {
                return currency(Math.abs(val), 2, row.Currency);
            }
        },
        {
            name: 'LiveStake',
            display: 'Live Stake',
            formatter: (val, row) => {
                return currency(Math.abs(val), 2, row.Currency);
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            formatter: (val, row) => {
                return currency(Math.abs(val), 2, row.Currency);
            }
        },
        {
            name: 'LiveLiability',
            display: 'Live Liability',
            formatter: (val, row) => {
                return currency(Math.abs(val), 2, row.Currency);
            }
        }
    ]
};


const eventSummaryModel = {
    fields: [
        {
            name: 'Id',
            display: 'Id'
        },
        {
            name: 'EventDate',
            display: 'Event Date',
            formatter: (row) => {
                row.EventDate = row.EventDate.split('.')[0];
                if (row.EventDate.indexOf('Z') == -1) row.EventDate += 'Z';
                return new Date(row.EventDate).toLocaleString('en-GB', { hours12: false, hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' });
            }
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Sport',
            display: 'Sport',
            formatter: (row) => {
                return renderEventSummary(row.Sport, row);
            }
        },
        {
            name: 'Status',
            display: 'Status',
            formatter: (row) => {
                return renderEventStatus(row.Status, row);
            }
        },
        {
            name: 'Slips',
            display: 'Slips',
            formatter: (row) => {
                if (row.EmptyEvent) return;
                return row.Slips.Prematch + '/ Live:' + row.Slips.Live;
            }
        },
        {
            name: 'Volumes.Stake',
            display: 'Stakes',
            formatter: (row) => {
                if (row.EmptyEvent) return;
                return currency(row.Volumes.Stake.Prematch + row.Volumes.Stake.Live, 2, row.DefaultCurrency);
            }
        },
        {
            name: 'Volumes.Liability',
            display: 'Liabilities',
            formatter: (row) => {
                if (row.EmptyEvent) return;
                return currency(row.Volumes.Liability.Prematch + row.Volumes.Liability.Live, 2, row.DefaultCurrency);
            }
        },
        {
            name: 'MaxStakeLimit',
            display: 'Stake',
            formatter: (row) => {
                if (row.EmptyEvent) return renderVolumeInformation(row.MaxStakeLimit, 0);
                return renderVolumeInformation(row.MaxStakeLimit, row.Volumes.Stake.Prematch);
            }
        },
        {
            name: 'MaxStakeLimitLive',
            display: 'Stake Live',
            formatter: (row) => {
                if (row.EmptyEvent) return renderVolumeInformation(row.MaxStakeLimitLive, 0);
                return renderVolumeInformation(row.MaxStakeLimitLive, row.Volumes.Stake.Live);
            }
        },
        {
            name: 'MaxLiabilityLimit',
            display: 'Liability',
            formatter: (row) => {
                if (row.EmptyEvent) return renderVolumeInformation(row.MaxLiabilityLimit, 0);
                return renderVolumeInformation(row.MaxLiabilityLimit, row.Volumes.Liability.Prematch);
            }
        },
        {
            name: 'MaxLiabilityLimitLive',
            display: 'Liability Live',
            formatter: (row) => {
                if (row.EmptyEvent) return renderVolumeInformation(row.MaxLiabilityLimitLive, 0);
                return renderVolumeInformation(row.MaxLiabilityLimitLive, row.Volumes.Liability.Live);
            }
        },
        {
            name: 'TPS',
            display: 'TPS',
            formatter: (row) => {
                if (row.EmptyEvent) return;
                return `${(row.TPS / 1000).toFixed(2)}s`;
            }
        },
        /*
        {
            name: 'Inspector',
            display: 'Inspector',
            formatter: (val) => {
                return renderInspector(val);
            }
        }
        */
    ]
};

/*
const renderInspector = () => {
    return <div style={{ transform: 'scale(0.5)' }}><MiniChart dataSet={[0, -20, 343, 49.3, -100, 200, 78]} /></div>;
};
*/


export const renderEventSummary = (val, row) => {
    return <div className='centrum-flex'>{`${val} / ${row.Region} / ${row.League}`}</div>;
};

export const renderEventStatus = (val, row) => {
    return SportEventStatus[row.Status];
};


const eventGroupLimits = {
    fields: [
        {
            name: 'Name',
            display: 'Group'
        },
        {
            name: 'Stake Volumes',
            display: 'Stake',
            formatter: (val, row) => {
                return renderVolumeInformation(row.Stake.Prematch ? row.Stake.Prematch : row.MaxStake, row.Volumes.Stake.Prematch);
            }
        },
        {
            name: 'Stake Volumes',
            display: 'Stake Live',
            formatter: (val, row) => {
                return renderVolumeInformation(row.Stake.Live ? row.Stake.Live : row.MaxStakeLive, row.Volumes.Stake.Live);
            }
        },
        {
            name: 'Liability Volumes',
            display: 'Liability',
            formatter: (val, row) => {
                return renderVolumeInformation(row.Liability.Prematch ? row.Liability.Prematch : row.MaxLiability, row.Volumes.Liability.Prematch);
            }
        },
        {
            name: 'Liability Volumes',
            display: 'Liability Live',
            formatter: (val, row) => {
                return renderVolumeInformation(row.Liability.Live ? row.Liability.Live : row.MaxLiabilityLive, row.Volumes.Liability.Live);
            }
        },
    ]
};


var volumeRefreshInterval = null;
const enabledCurrenciesSymbols = ['USD', 'EUR', 'TRY', 'IQD', 'TTD','MNT','ETB'];

export const Volumes = () => {
    const dispatch = useDispatch();
    const volumes = useSelector((state) => state.volumes.volumes);
    const sports = useSelector((state) => state.volumes.sports);
    const regions = useSelector((state) => state.volumes.regions);
    const leagues = useSelector((state) => state.volumes.leagues);
    const selectedCurrency = useSelector((state) => state.volumes.currency);
    const operators = useSelector((state) => state.volumes.operators);
    const selectedOperator = useSelector((state) => state.volumes.operator ?? state.user.loginInformation.OperatorId);

    const [currencies, setCurrencies] = useState([]);
    const [autoRefresh, toggleAutoRefresh] = useState(true);
    const [refreshInterval, setRefreshInterval] = useState(50000);

    const [playerGroups, setPlayerGroups] = useState([]);
    const [onlyMyEvents, setShowOnlyMyEvents] = useState(false);
    const [onlyLiveEvents, setShowOnlyLiveEvents] = useState(false);
    const [hideEmpty, setHideEmpty] = useState(true);


    const [sport, selectSport] = useState(null);
    const [region, selectRegion] = useState(null);
    const [league, selectLeague] = useState(null);
    const [eventFilter, setEventFilter] = useState(null);

    const [focusedEvent, focusEvent] = useState(null);

    const [selectedEvents, selectEvents] = useState(getLocalStorage('selectedEvents') ? getLocalStorage('selectedEvents') : {});

    const [sorting, setSorting] = useState({ field: 'Slips', direction: 1 });

    const [selectedEventGroupLimits, setSelectedEventGroupLimits] = useState({});

    const [eventLimitEditing, editEventLimit] = useState(null);

    const [myEvents, setMyEvents] = useState({});

    const [updateKey, setUpdateKey] = useState(0);


    const refresh = () => {
        if (!autoRefresh) return;
        loadReport();
        loadMyEvents();
    };

    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies).then((currencyResult) => {
            var enabledCurrencies = [];

            currencyResult.result.filter(x => enabledCurrenciesSymbols.includes(x.CurrencyCode)).map(x => enabledCurrencies.push({ name: x.Name, value: x.CurrencyCode }));
            API.post(DataEndpoints.Operators).then((result) => {
                var operatorData = [];
                result.result.map(x => {
                    operatorData.push({
                        id: x.Id,
                        name: x.Name
                    });
                });

                dispatch({ type: 'VOLUME_OPERATORS', payload: operatorData });
                setCurrencies(enabledCurrencies);
            });
        });

        return () => {
            clearInterval(volumeRefreshInterval);
        };
    }, []);

    useEffect(() => {
        if (!selectedOperator) return;
        window.___s = selectedOperator;
        refresh();
    }, [selectedOperator]);

    useEffect(() => {
        refresh();
    }, [autoRefresh]);

    useEffect(() => {
        setTimeout(() => {
            refresh();
        }, 300);
    }, [refreshInterval]);

    useEffect(() => {
        if (!volumes) return;
        clearInterval(volumeRefreshInterval);
        volumeRefreshInterval = null;
        volumeRefreshInterval = setInterval(() => {
            refresh();
        }, refreshInterval);
    }, [volumes]);

    useEffect(() => {
        setLocalStorage('selectedEvents', selectedEvents);
    }, [selectedEvents]);

    useEffect(() => {
        setUpdateKey(Date.now());
    }, [sorting]);

    const renderOperators = () => {
        if (operators.length == 1 || !operators) return;
        return <select className='select-operator' defaultValue={selectedOperator} onChangeCapture={(e) => {
            dispatch({ type: 'SELECT_VOLUME_OPERATOR', payload: e.target.value });
        }}>
            <option value={'MainOperator:22'}>{lang('All')}</option>
            {
                operators.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
            }
        </select>;
    };

    const renderCurrency = () => {
        return <select className='select-currency' defaultValue={selectedCurrency} onChangeCapture={(e) => {
            dispatch({ type: 'SELECT_VOLUMES_CURRENCY', payload: e.target.value });
        }}>
            {
                currencies.map(x => <option key={x.name} value={x.value}>{x.name}</option>)
            }
        </select>;
    };

    const loadReport = () => {
        //console.log((new Date()).toLocaleString('en-Gb'));
        try {
            document.getElementsByClassName('refresh-limits-progress-bar')[0].classList.remove('start-loading');
        } catch (err) {
            //
        }
        application().post(Endpoints.GetPlayerGroups).then(groups => {
            setPlayerGroups(groups.result);
            application().post(`${Endpoints.RiskVolumes}/${selectedOperator}`).then((result) => {
                try {
                    document.getElementsByClassName('refresh-limits-progress-bar')[0].classList.add('start-loading');
                } catch (err) {
                    //
                }

                dispatch({ type: 'UPDATE_VOLUMES', payload: result.result });
            });
        });
    };

    const loadMyEvents = () => {
        application().post(Endpoints.GetMyAssignedEvents).then(assignedEvents => {
            setMyEvents(assignedEvents.result);
        });
    };

    const renderSummary = () => {
        return <Card title='General' className='no-padding' buttons={() =>
            <div className='centrum-flex volume-refresh-options'>
                {renderOperators()}
                {renderCurrency()}
                <div>
                    <input type='number' className='refresh-input' min='5' max='60' defaultValue={refreshInterval / 1000} onChangeCapture={(e) => {
                        var val = parseInt(e.target.value);
                        if (!val && !isNaN(val)) {
                            setRefreshInterval(val);
                        }
                    }} />
                </div>
                <div className='flex gap-5'>
                    <Icon icon={autoRefresh ? 'pause' : 'play'} size='xs' onClick={() => toggleAutoRefresh(!autoRefresh)} />
                    <Icon icon={'sync'} size='xs' onClick={() => {
                        loadReport();
                        loadMyEvents();
                    }} />
                </div>
                <span className='refresh-limits'>
                    <div className='refresh-limits-progress-bar'><Icon icon='circle-notch fa-spin' size='xs' /></div>
                </span>
            </div>
        }>
            <div className='centrum-flex auto-width padding'>
                <BetsSummary />
                <VolumesSummary />
            </div>
        </Card>;
    };

    if (!volumes) return <div>{lang('loading volumes please wait')}</div>;
    if (focusedEvent) {
        return <div className='centrum-flex vertical auto-width'>
            <Card title={focusedEvent.Name} className='no-padding' buttons={() =>
                <div className='centrum-flex alignRight padding-right'>
                    {
                        Object.keys(myEvents).length > 0 &&
                        <select defaultValue={focusedEvent.Id} onChangeCapture={(e) => {
                            const event = volumes.Events[parseFloat(e.target.value)];
                            if (event) focusEvent(event);
                        }}>
                            {
                                Object.values(myEvents).map(x => {
                                    return <option value={x.eventId} key={x.eventId}>{x.name}</option>;
                                })
                            }
                        </select>
                    }
                    <Button title='Close' onClick={() => {
                        focusEvent(null);
                    }} />
                </div>}>
            </Card >
            <EventSummary event={focusedEvent} volumes={volumes} />
        </div >;
    }
    const tabButtons = [
        {
            name: 'selections',
            title: 'Selection Volumes'
        },
        {
            name: 'events',
            title: 'Event Volumes'
        },
        {
            name: 'leagues',
            title: 'League Volumes'
        },        
        {
            name: 'list',
            title: 'Selected Events'
        },
        
        {
            name: 'pending',
            title: `Pending tickets (${Object.keys(volumes.PendingTickets).length})`
        },
        {
            name: 'notifications',
            title: `Notifications (${volumes.NotificationCount})`
        }

    ];

    var timeout = null;
    const searchEvent = (value) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setEventFilter(value.toLowerCase());
        }, 300);
    };

    var filteredEvents = Object.values(volumes.Events).filter(event => {
        if (eventFilter) {
            if (event.Name.toLowerCase().indexOf(eventFilter) >= 0) {
                return event;
            } else {
                return;
            }
        }

        if (sport && sport != event.SportId) return;
        if (region && region != event.RegionId) return;
        if (league && league != event.LeagueId) return;
        if (onlyMyEvents) {
            if (myEvents.hasOwnProperty(event.Id)) {
                return event;
            }
            return;
        }
        if (onlyLiveEvents && event.Status != 1) return;
        event.__selected = selectedEvents.hasOwnProperty(event.Id);
        return event;
    }).sort((b, a) => a.Volumes.Liability.Prematch - b.Volumes.Liability.Prematch);

    if (onlyMyEvents) {
        Object.values(myEvents).map(x => {
            const eventId = x.eventId;
            const found = volumes.Events.hasOwnProperty(eventId);
            if (!found) {
                filteredEvents.push({
                    Id: eventId,
                    EventDate: x.scheduled,
                    Name: x.name,
                    EmptyEvent: true,
                    Sport: x.sport,
                    SportId: x.sportId,
                    RegionId: x.regionId,
                    Region: x.region,
                    LeagueId: x.leagueId,
                    League: x.league
                });
            }
        });
    }

    const sort = (a, b) => {
        if (sorting.field == 'Name') return a.Name > b.Name ? 1 : -1;
        if (sorting.field == 'Sport') return a.Sport > b.Sport ? 1 : -1;
        if (sorting.field == 'Status') return a.Status > b.Status ? 1 : -1;
        if (sorting.field == 'EventDate') return new Date(a.EventDate) - new Date(b.EventDate);

        if (a.EmptyEvent) return;
        if (sorting.field == 'Volumes.Stake') return a.Volumes.Stake.Prematch + a.Volumes.Stake.Live - b.Volumes.Stake.Prematch + b.Volumes.Stake.Live;
        if (sorting.field == 'Slips') return a.Slips.Prematch - b.Slips.Prematch;
        if (sorting.field == 'Volumes.Liability') return a.Volumes.Liability.Prematch + a.Volumes.Liability.Live - b.Volumes.Liability.Prematch + b.Volumes.Liability.Live;
        if (sorting.field == 'MaxStakeLimit') return a.MaxStakeLimit / a.Volumes.Stake.Prematch - b.MaxStakeLimit / b.Volumes.Stake.Prematch;
        if (sorting.field == 'MaxLiabilityLimit') return a.MaxLiabilityLimit / a.Volumes.Liability.Prematch - b.MaxLiabilityLimit / b.Volumes.Liability.Prematch;
    };

    const sortedEvents = filteredEvents.sort((a, b) => {
        if (sorting.direction == 0) return sort(a, b);
        return sort(b, a);
    });


    const showGroupLimitsOfEvent = (eventId) => {
        var copy = Object.assign({}, selectedEventGroupLimits);
        copy[eventId] = true;
        setSelectedEventGroupLimits(copy);
    };

    const renderGroupLimitsOfEvent = (eventId) => {
        const event = volumes.Events[eventId];
        var limits = [];
        var groupLimits = (volumes.EventLimits && volumes.EventLimits.hasOwnProperty(eventId) && volumes.EventLimits[eventId].Groups) ? volumes.EventLimits[eventId].Groups : null;
        if (!groupLimits) groupLimits = (volumes.LeagueLimits && volumes.LeagueLimits.hasOwnProperty(event.LeagueId) && volumes.LeagueLimits[event.LeagueId].Groups) ? volumes.LeagueLimits[event.LeagueId].Groups : null;
        if (!groupLimits) groupLimits = (volumes.LeagueLimits && volumes.LeagueLimits.hasOwnProperty('0') && volumes.LeagueLimits['0'].Groups) ? volumes.LeagueLimits['0'].Groups : null;
        if (!groupLimits) return;
        Object.keys(groupLimits).map(groupId => {
            var limit = groupLimits[groupId];
            limit.Volumes = event.GroupVolumes[groupId];
            if (!limit.Volumes) {
                limit.Volumes = {
                    Stake: { Prematch: 0, Live: 0 },
                    Liability: { Prematch: 0, Live: 0 }
                };
            }
            var group = playerGroups.find(x => x.Id == groupId);
            if (group) {
                limit.Name = group.Name;
                limits.push(limit);
            }
        });

        return <Table model={eventGroupLimits} data={limits} />;
    };

    if (eventLimitEditing) {
        return <SportEventLimits event={eventLimitEditing} onClose={() => {
            editEventLimit(null);
        }} />;
    }

    const renderSelectedEvents = () => {
        const allowRender = false;
        if (!allowRender) return;
        return <div className='col2 padding'>
            {Object.keys(volumes.Events).map(eventId => {
                if (!selectedEvents.hasOwnProperty(eventId)) return;
                const event = volumes.Events[eventId];
                var vSummary = [];

                Object.keys(event.Stakes).map(c => {
                    vSummary.push({
                        Currency: c,
                        PrematchStake: event.Stakes[c].Prematch,
                        LiveStake: event.Stakes[c].Live,
                        Liability: event.Liabilities[c].Prematch,
                        LiveLiability: event.Liabilities[c].Live
                    });
                });

                return <Card key={eventId} title={`[${eventId}] ${event.Name}`} className='no-padding' buttons={() => <Button title='Close' className='alignRight marginRight half' onClick={() => {
                    var selectedRows = Object.assign({}, selectedEvents);
                    delete selectedRows[eventId];

                    selectEvents(selectedRows);

                    var copy = Object.assign({}, volumes);
                    copy.Events[eventId].__selected = false;
                    dispatch({ type: 'UPDATE_VOLUMES', payload: copy });

                }} />}>
                    <div className='centrum-flex vertical auto-width padding'>
                        <div className='group'>
                            <label>{lang('Slips')}</label>
                        </div>
                        <div className='group'>
                            <label>{lang('Prematch')}</label><span>{event.Slips.Prematch}</span>
                            <label>{lang('Live')}</label><span>{event.Slips.Live}</span>
                        </div>
                        <div className='centrum-flex'>
                            <div className='centrum-flex vertical border radius padding'>
                                <div className='group border radius padding'>
                                    <label>{lang('Prematch Max Stakes')}</label>
                                    {renderVolumeInformation(event.MaxStakeLimit, event.Volumes.Stake.Prematch)}
                                </div>
                                <div className='group border radius padding'>
                                    <label>{lang('Live Max Stakes')}</label>
                                    {renderVolumeInformation(event.MaxStakeLimitLive, event.Volumes.Stake.Live)}
                                </div>
                            </div>
                            <div className='centrum-flex vertical border radius padding'>
                                <div className='group border radius padding'>
                                    <label>{lang('Prematch Liability Limit')}</label>
                                    {renderVolumeInformation(event.MaxLiabilityLimit, event.Volumes.Liability.Prematch)}
                                </div>
                                <div className='group border radius padding'>
                                    <label>{lang('Live Liability Limit')}</label>
                                    {renderVolumeInformation(event.MaxLiabilityLimitLive, event.Volumes.Liability.Live)}
                                </div>
                            </div>
                        </div>
                        <div className='centrum-flex vertical'>
                            <div className='center'>
                                <Button title='Change Limits' onClick={() => {
                                    var e = {
                                        id: event.Id,
                                        date: event.EventDate,
                                        sport: event.Sport,
                                        sportId: event.SportId,
                                        region: event.Region,
                                        regionId: event.RegionId,
                                        leagueId: event.LeagueId,
                                        league: event.League,
                                        name: event.Name
                                    };
                                    editEventLimit(e);
                                }}
                                />
                                <Button title='Show Groups' onClick={() => showGroupLimitsOfEvent(eventId)} />
                            </div>
                            {selectedEventGroupLimits.hasOwnProperty(eventId) && renderGroupLimitsOfEvent(eventId)}
                        </div>
                        {vSummary.length > 0 && <Table model={volumeSummaryModel} data={vSummary} key={vSummary} />}
                        <div className='centrum-flex auto-width'>
                            <Button title='Event Details' onClick={() => focusEvent(event)} />
                            <Button title={event.Stop ? 'Bet Start' : 'Bet Stop'} onClick={() => {
                                if (event.Stop) {
                                    betStart(event);
                                } else {
                                    betStop(event);
                                }

                            }} />
                        </div>
                    </div>
                </Card>;
            })}
        </div>;
    };


    return <div className='centrum-flex vertical auto-width'>
        {renderSummary()}
        <Card title='Events' className='no-padding border-top' buttons={() => <div className='centrum-flex alignRight padding-right'>
            <div className='centrum-flex center'>
                <span className='medium no-wrap'>{lang('My Events')}</span>
                <Switch checked={onlyMyEvents} onChange={(v) => setShowOnlyMyEvents(v)} />
            </div>
            <div className='centrum-flex center'>
                <span className='medium no-wrap'>{lang('Only Live')}</span>
                <Switch checked={onlyLiveEvents} onChange={(v) => setShowOnlyLiveEvents(v)} />
            </div>
            <div className='centrum-flex center'>
                <span className='medium no-wrap'>{lang('Hide Empty')}</span>
                <Switch checked={hideEmpty} onChange={(v) => setHideEmpty(v)} />
            </div>
            <select onChangeCapture={(e) => {
                var id = parseFloat(e.target.value);
                if (isNaN(id)) {
                    selectSport(null);
                } else {
                    selectSport(id);
                }
            }}>
                <option>{lang('All Sports')}</option>
                {
                    Object.keys(sports).map(id => {
                        var name = sports[id];
                        return <option key={id} value={id}>{name}</option>;
                    })
                }
            </select>
            <select onChangeCapture={(e) => {
                var id = parseFloat(e.target.value);
                if (isNaN(id)) {
                    selectRegion(null);
                } else {
                    selectRegion(id);
                }
            }}>
                <option>{lang('All Regions')}</option>
                {
                    Object.keys(regions).map(id => {
                        var name = regions[id];
                        return <option key={id} value={id}>{name}</option>;
                    })
                }
            </select>
            <select onChangeCapture={(e) => {
                var id = parseFloat(e.target.value);
                if (isNaN(id)) {
                    selectLeague(null);
                } else {
                    selectLeague(id);
                }
            }}>
                <option>{lang('All Leagues')}</option>
                {
                    Object.keys(leagues).map(id => {
                        var name = leagues[id];
                        return <option key={id} value={id}>{name}</option>;
                    })
                }
            </select>
            <div className='centrum-flex center'>
                <span className='medium no-wrap center'>{lang('Event')}</span>
                <input name='Event' onChangeCapture={(e) => searchEvent(e.target.value)} />
            </div>
        </div>
        }>
            <Tab buttons={tabButtons}>
                <SelectionVolumes
                    key={volumes.Key}
                    myEvents={myEvents}
                    onlyMyEvents={onlyMyEvents}
                    sport={sport}
                    region={region}
                    league={league}
                    eventFilter={eventFilter}
                    live={onlyLiveEvents}
                    hideEmpty={hideEmpty}
                    focusEvent={(e) => focusEvent(e)} />
                <div className='centrum-flex vertical'>
                    <EditableTable model={eventSummaryModel} data={sortedEvents} key={updateKey} selectable={true}
                        sortable={true}
                        sortingField={window.selectionSorting != null ? window.selectionSorting.field : 'EventDate'}
                        sortingDirection={window.selectionSorting != null ? window.selectionSorting.direction : 1}
                        onDoubleClick={(row) => {
                            const event = volumes.Events[row.Id];
                            focusEvent(event);
                        }}
                        onRenderRow={(row) => {
                            if (row.MaxStakeLimit > 0) {
                                var perc = (row.Volumes.Stake.Prematch * 100) / row.MaxStakeLimit;
                                if (perc > 90) {
                                    return { className: 'warning' };
                                }
                            }
                            return row;
                        }}
                        onSortingChanged={(field, direction) => {
                            setSorting({ field: field, direction: direction });
                        }}
                        onSelectionChange={(row, value) => {
                            var selectedRows = Object.assign({}, selectedEvents);
                            if (value) {
                                selectedRows[row.Id] = true;
                            } else {
                                delete selectedRows[row.Id];
                            }
                            selectEvents(selectedRows);
                        }} buttons={(row) => {
                            return <div className='centrum-flex'>
                                <Button title={'Limits'} onClick={() => {
                                    var event = {
                                        id: row.Id,
                                        date: row.EventDate,
                                        sport: row.Sport,
                                        sportId: row.SportId,
                                        region: row.Region,
                                        regionId: row.RegionId,
                                        leagueId: row.LeagueId,
                                        league: row.League,
                                        name: row.Name
                                    };
                                    editEventLimit(event);
                                }} />
                                <Button title={row.Stop ? 'Start' : 'Bet Stop'} onClick={() => {
                                    if (row.Stop) {
                                        betStart(row);
                                    } else {
                                        betStop(row);
                                    }
                                }} /></div>;
                        }} />
                </div>
                <div>
                    <LeagueVolumes volumes={volumes} defaultCurrency={volumes.DefaultCurrency} />
                </div>
                {renderSelectedEvents()}
                <div>
                    <PendingTickets volumes={volumes} onRemove={() => {
                        const copy = Object.assign({}, volumes);
                        dispatch({ type: 'UPDATE_VOLUMES', payload: copy });
                    }} />
                </div>
                <div>
                    <RiskNotifications />
                </div>
            </Tab>
        </Card>
    </div >;
};

