
import React from 'react';
import PropTypes from 'prop-types';


import { models, icons } from '../Shared';
import { QueryBuilder } from './QueryBuilder';
import AnimateHeight from 'react-animate-height';
import { CentrumInput, InputTypes } from './Inputs/CentrumInput';
import { lang } from '../Localization/lang';
import { Icon } from '../../Controls/Icon/Icon';



export const FormTypes = {
    default: 'default',
    search: 'search',
    edit: 'edit'
};


export const UpdateButtons = [
    {
        type: 'submit',
        name: 'Update',
        caption: 'Update'
    },
    {
        type: 'reset',
        name: 'Reset',
        caption: 'Reset'
    }
];

export const SaveButtons = [
    {
        type: 'submit',
        name: 'Save',
        caption: 'Save'
    },
    {
        type: 'reset',
        name: 'Reset',
        caption: 'Reset'
    }
];


export class CentrumForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, props);

        this.queryBuilderOptions = {};
        this.defaultFieldValues = {};
        this.fieldsLoading = {};
        this.fieldsToBeUpdatedWhenChange = {};
        this.changedFields = {};
        this.setModel();
    }


    onQueryBuilderOpen(field) {
        this.setState({ showQueryBuilder: field });
    }

    onQueryBuilderUpdate(values) {
        let queryBuilder = this.state.showQueryBuilder;
        queryBuilder.values = values;

        this.setState({ showQueryBuilder: values });
    }

    setModel() {
        var modelCopy = {};
        if (typeof this.props.model==='object') {
            modelCopy = Object.assign({}, this.props.model);
            if (this.props.onModelSet !== null) {
                let combinedModel = this.props.onModelSet(modelCopy);
                modelCopy = combinedModel;
            }
            this.model = modelCopy;
            return;    
        }
        let model = models.get(this.props.model);
        if (model === undefined) {
            //fetch model from server?
            console.log('model not found', this.props.model);
            return;
        }

        modelCopy = Object.assign({}, model);
        if (this.props.onModelSet !== null) {
            let combinedModel = this.props.onModelSet(modelCopy);
            modelCopy = combinedModel;
        }
        this.model = modelCopy;
    }


    onFieldValueChanged(field, value) {
        if ((field.name in this.fieldsToBeUpdatedWhenChange)) {
            this.changedFields[field.name] = value;
            this.setState({ changedFields: this.changedFields });
        }
        if (this.props.onFieldValueChanged != null) this.props.onFieldValueChanged(field, value);
    }

    onQueryFieldValueChanged(field, queryField, value) {
        if (!(field.name in this.queryBuilderOptions)) {
            this.queryBuilderOptions[field.name] = {};
        }
        this.queryBuilderOptions[field.name][queryField.name] = value;
        this.setState({ queryBuilderOptions: this.queryBuilderOptions });
    }

    onQueryFormReset(field, values) {
        this.queryBuilderOptions[field.name] = {};
        Object.keys(values).map(key => {
            this.queryBuilderOptions[field.name][key] = values[key];
        });

        this.setState({ queryBuilderOptions: this.queryBuilderOptions });
    }

    showQueryBuilder() {
        return <QueryBuilder model={this.model} field={this.state.showQueryBuilder}
            onClose={this.closeQueryBuilder.bind(this)}
            onReset={this.onQueryFormReset.bind(this)}
            onFieldValueChanged={this.onQueryFieldValueChanged.bind(this)} />;
    }

    closeQueryBuilder() {
        this.setState({ showQueryBuilder: null });
    }

    getFormData() {
        const data = new FormData(this.form);
        let serialized = {};

        let postData = {
            model: this.props.model,
            fields: []
        };

        this.model?.fields?.map(field => {
            let formValue = data.get(field.name);
            if(field.type === InputTypes.multipleSelect){
                formValue = data.getAll(field.name);
                formValue = (formValue != null && formValue != undefined) ? formValue.join(',') : formValue; 
            }
            
            if (field.readonly) {
                let fieldValue = Object.assign({}, { name: field.name, value: formValue });
                let queryBuilderOptions = this.queryBuilderOptions[field.name];
                if (queryBuilderOptions !== undefined) {
                    fieldValue.exact = queryBuilderOptions.Exact;
                    fieldValue.and = queryBuilderOptions.And;
                }

                if (field.type === InputTypes.number) {
                    fieldValue.value = parseFloat(fieldValue.value);
                }

                postData.fields.push(fieldValue);
                serialized[field.name] = fieldValue.value;
            } else {
                if (formValue !== '') {
                    let fieldValue = Object.assign({}, { name: field.name, value: formValue });
                    let queryBuilderOptions = this.queryBuilderOptions[field.name];
                    if (queryBuilderOptions !== undefined) {
                        fieldValue.between = queryBuilderOptions.Between;
                        fieldValue.exact = queryBuilderOptions.Exact;
                        fieldValue.and = queryBuilderOptions.And;
                        fieldValue.timeZone = queryBuilderOptions.TimeZone;
                    }

                    if (queryBuilderOptions !== undefined) {
                        if (queryBuilderOptions.Between) {
                            let minFieldValue = data.get(field.name + '_min');
                            let maxFieldValue = data.get(field.name + '_max');

                            fieldValue.min = minFieldValue;
                            fieldValue.max = maxFieldValue;
                            fieldValue.timeZone = queryBuilderOptions.TimeZone;

                            delete fieldValue.value;
                        }
                    } else {
                        if (field.type === InputTypes.date || field.type === InputTypes.dateTime) {
                            fieldValue.value = formValue != null ? (formValue.substring(6, 10) + '-' + formValue.substring(3, 5) + '-' + formValue.substring(0, 2) + (field.type === InputTypes.dateTime ? (' ' + formValue.substring(11, 13) + ':' + formValue.substring(14, 17)) : '')) : null;
                        }

                        if (field.type === InputTypes.select) {
                            fieldValue.exact = true;
                        }

                        if (field.type === InputTypes.number) {
                            fieldValue.exact = true;
                        }

                        if (field.type === InputTypes.bool) {
                            if (fieldValue.value === 'on') fieldValue.value = true;
                            if (fieldValue.value === 'off') fieldValue.value = false;
                            if (fieldValue.value === null) fieldValue.value = false;
                        }
                    }



                    if (fieldValue != null) {
                        postData.fields.push(fieldValue);
                        if (fieldValue.value !== undefined && fieldValue.value !== null) {
                            var value = fieldValue.value.toString();
                            if (field.type === InputTypes.number) value = parseFloat(value);
                            if (field.type === InputTypes.bool) value = value === 'true';
                            serialized[field.name] = value;
                        } else {
                            serialized[field.name] = null;
                        }
                    }
                }
            }
        });

        return { postData: postData, serialized: serialized };
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.props.enabled) return;

        let data = this.getFormData();

        if (this.props.onSubmit !== null) {
            this.props.onSubmit(data.serialized, data.postData);
        }
    }

    handleReset() {
        if (this.props.onReset !== null) {
            this.props.onReset(this.defaultFieldValues);
        }
    }

    onFieldReady(field) {
        var fieldsLeftToLoading = false;
        this.fieldsLoading[field.props.field.name] = true;
        Object.keys(this.fieldsLoading).map(key => {
            if (!this.fieldsLoading[key]) {
                fieldsLeftToLoading = true;
            }
        });


        if (!fieldsLeftToLoading) {
            this.setState({ loading: false });
        }
    }

    renderForm() {
        var data = Object.assign({}, this.props.data);
        return this.model?.fields?.map(/*fieldRaw=>*/fieldSource => {
            var field = Object.assign({}, fieldSource);
            try {
                /*
                After form posting, forms with same models get the same values
                in order to fix this reference field as a new object
    
                var field = Object.assign({},fieldRaw);
                */

                if (this.props.type === FormTypes.search) {
                    field.showQueryBuilderOptions = true;
                }

                let value = field.value;

                if (this.props.data !== null && this.props.data !== undefined) {
                    // todo
                    // json lowercase bug
                    if (this.props.data[field.name] === undefined) {
                        var lowered = field.name.charAt(0).toLowerCase() + field.name.slice(1);
                        value = this.props.data[lowered];
                    } else {
                        value = this.props.data[field.name];
                    }
                }
                if (value === undefined) {
                    if (field.type === 'bool') { value = false; }
                    else value = '';
                }

                // override query builder options
                if (field.queryBuilderOptions !== undefined && field.queryBuilderOptions !== null) {
                    this.queryBuilderOptions[field.name] = field.queryBuilderOptions;
                }


                this.defaultFieldValues[field.name] = value;
                field.value = value;
                if (this.props.readonly) {
                    field.readonly = true;
                }

                if (!(field.name in this.changedFields)) {
                    this.changedFields[field.name] = field.value;
                }


                var valueEndPointData = '';

                if (field.valueEndPoint != null && field.valueEndPoint !== undefined) {

                    if (!(field.name in this.fieldsLoading)) {
                        this.fieldsLoading[field.name] = false;
                    }

                    if (field.valueEndPoint.updateDataOnFieldChange !== undefined) {
                        this.fieldsToBeUpdatedWhenChange[field.valueEndPoint.updateDataOnFieldChange] = true;
                        field.valueEndPoint.data = this.changedFields[field.valueEndPoint.updateDataOnFieldChange];
                        valueEndPointData = field.valueEndPoint.data;
                    }

                }

                return <CentrumInput
                    enabled={this.props.enabled}
                    readonly={this.props.readonly}
                    hidden={this.props.hidden}
                    key={field.name + '' + valueEndPointData}
                    field={field}
                    multiLine={field.multiLine}
                    formData={data}
                    onReady={this.onFieldReady.bind(this)}
                    queryBuilderOptions={this.queryBuilderOptions}
                    onQueryBuilderOpen={field.showQueryBuilderOptions ? this.onQueryBuilderOpen.bind(this) : null}
                    onValueChanged={this.onFieldValueChanged.bind(this)} />;
            } catch (err) {
                return (
                    <CentrumInput
                        enabled={this.props.enabled}
                        readonly={this.props.readonly}
                        hidden={this.props.hidden}
                        formData={data}
                        key={field.name} field={field}
                        queryBuilderOptions={this.queryBuilderOptions}
                        onQueryBuilderOpen={field.showQueryBuilderOptions ? this.onQueryBuilderOpen.bind(this) : null}
                        onValueChanged={this.onFieldValueChanged.bind(this)} />
                );

            }
        });
    }


    buttonClicked(button) {
        if (this.props.onButtonClicked !== null) {
            this.props.onButtonClicked(button, this);
            return;
        }
    }

    renderButtons() {
        var key = 0;
        if (this.props.buttons.length == 0) return;
        return (
            <div className='form-element actions'>
                {this.props.buttons.map(button => {
                    key++;
                    return (
                        <button key={key} type={button.type} onClick={(event) => this.buttonClicked(button, event)}>
                            {
                                button.icon !== null && button.icon !== undefined &&
                                <Icon icon={icons[button.icon]} style={{ marginRight: '5px' }} />
                            }
                            {lang(button.caption)}
                        </button>
                    );
                })}
            </div>
        );
    }




    isFormLoading() {
        var res = true;
        this.model?.fields?.map(fieldSource => {
            if (fieldSource.valueEndPoint != null && fieldSource.valueEndPoint !== undefined) {
                if (!this.fieldsLoading[fieldSource.name]) res = false;
            }
        });
        return res;
    }

    render() {

        var isFormLoading = this.isFormLoading();
        return (
            <div key={'form-' + this.props.model} className='centrum-form'>
                {this.props.caption !== '' && this.props.caption !== null && <h1>{lang(this.props.caption)}</h1>}
                <AnimateHeight duration={500}
                    height={this.state.showQueryBuilder !== null ? 'auto' : 0}>
                    {this.showQueryBuilder()}
                </AnimateHeight>
                <form onSubmit={this.handleSubmit.bind(this)} onReset={this.handleReset.bind(this)} className={'centrum-form ' + this.props.type + (!this.props.enabled ? ' disabled' : '') + ' ' + this.props.className + ' ' + (!isFormLoading ? 'elementsLoading' : '')} ref={(ref) => this.form = ref}>
                    {this.renderForm()}
                    {this.props.children}
                    {this.renderButtons()}
                </form>
            </div>
        );
    }
}



CentrumForm.defaultProps = {
    buttons: [
        {
            type: 'submit',
            name: 'Submit',
            caption: 'Submit'
        },
        {
            type: 'reset',
            name: 'Reset',
            caption: 'Reset'
        }
    ],
    className: '',
    enabled: true,
    hidden: false,
    readonly: false,
    data: null,
    caption: '',
    showQueryBuilder: null,
    type: FormTypes.default,
    children: null,
    onSubmit: null,
    onReset: null,
    onButtonClicked: null,
    onFieldValueChanged: null,
    onModelSet: null
};

CentrumForm.propTypes = {
    className: PropTypes.string,
    model: PropTypes.string.isRequired,
    data: PropTypes.object,
    type: PropTypes.string.isRequired,
    caption: PropTypes.string,
    buttons: PropTypes.array,
    enabled: PropTypes.bool,
    readonly: PropTypes.bool,
    hidden: PropTypes.bool,
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    onButtonClicked: PropTypes.func,
    onFieldValueChanged: PropTypes.func,
    onModelSet: PropTypes.func
};