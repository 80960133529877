import { InputTypes, ValidationTypes } from '../CentrumForm/Inputs/CentrumInput';


export const AdminModels = {
    adminInformation: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true
            }
        ]
    },
    editAdmin: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
                readonly:true
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true
            }
        ]        
    },
    changePassword: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
                readonly:true
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128,
                readonly:true
            },
            {
                name: 'Name',
                type: InputTypes.text,
                max: 32,
                readonly:true
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                max: 32,
                readonly:true
            },
            {
                name: 'Password',
                type: InputTypes.password,
                validation:ValidationTypes.password,
                minimumLength:6,
                max:32
            }
        ]        
    },
    adminSearch: {
        fields: [
            {
                name: 'Email',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Name',
                max: 64,
                type: InputTypes.string
            },
            {
                name: 'Surname',
                max: 64,
                type: InputTypes.string
            }
        ]
    },
    activitySearch: {
        fields: [
            {
                name: 'Email',
                max: 32,
                type: InputTypes.text
            },
            {
                name: 'Created',
                display:'Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'IP',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Agent',
                max: 255,
                type: InputTypes.string
            }
        ]
    }
};