
// React
import React, { useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import PropTypes from 'prop-types';

// PAGES
import { Dashboard } from '../pages/Dashboards/Dashboard';
import { ConfigurationsPage } from '../pages/Configuration/ConfigurationsPage';
import { PlayersPage } from '../pages/PlayersPage';
import { ReportsPage } from '../pages/ReportsPage';

import { ProvidersPage } from '../pages/ProvidersPage';
import { LoginPage } from '../pages/Login/LoginPage';


import { LogoutPage } from '../pages/LogoutPage';
import { GamingPage } from '../pages/GamingPage';
import { TransactionsPage } from '../pages/TransactionsPage';


// COMPONENTS
import { Menu } from '../Centrum/Menu/Menu';
import Header from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { FrontendSocket } from '../FrontendSocket';
import { MarketingPage } from '../pages/MarketingPage';
import { OperatorPage } from '../pages/Operators/OperatorsPage';
import { MyProfilePage } from '../pages/MyProfile/MyProfilePage';
import { ManagementPage } from '../pages/Management/ManagementPage';
import { CMSPage } from '../pages/CMS/CMSPage';
import { Application, Endpoints } from '../Application/Application';
import { RiskManagementPage } from '../pages/RiskManagement/RiskManagementPage';
import { RetailsPage } from '../pages/Retails/RetailsPage';
import { ManualBetsPage } from '../pages/ManualBet/ManualBetsPage';

import { AffiliatePage } from '../pages/Affiliates/AffiliatePage';
import { AffiliatePlayersPage } from '../pages/Affiliates/AffiliatePlayersPage';
import { PromotionsPage } from '../pages/Promotions/PromotionsPage';
import { LotteryPage } from '../pages/Lottery/LotteryPage';
import { SetupPage } from '../pages/Setup/SetupPage';
import { SportOperationsPage } from '../pages/Sports/SportOperationsPage';
import { useDispatch, useSelector } from 'react-redux';
import { postData } from '../../state/services/ServiceHelpers';
import { HorsePage } from '../pages/Horse/HorsePage';
import { Card } from '../../v2/Components/Card/Card';
import { lang } from '../Centrum/Localization/lang';
import { Button } from '../../v2/Components/Controls/Button/Button';
import { API, AuthenticationEndpoints } from '../../v2/Lib/Api/Api';


var _history;

export const getHistory = () => {
    return _history;
};


export const AppRouter = (props) => {
    _history = props.history;
    const settings = useSelector((state) => state.settings);
    const [ping, setPing] = useState(null);
    const user = useSelector((state) => state.user)?.loginInformation;
    const dispatch = useDispatch();
    const [authError, setAuthError] = useState(false);

    const onMenuItemSelected = (selectedItem) => {
        if (selectedItem.props.item.url !== undefined) {
            props.history.push(selectedItem.props.item.url);
        } else {
            // auto enable url
            if (selectedItem.props.item.children == undefined) {
                var parent = selectedItem.props.parent;
                if (parent.props.item === undefined) {
                    props.history.push('/' + selectedItem.props.item.type);
                } else {
                    if (parent.props.parent !== undefined && parent.props.parent.props.item !== undefined) {
                        props.history.push('/' + parent.props.parent.props.item.type + '/' + parent.props.item.type + '/' + selectedItem.props.item.type);
                    } else {
                        props.history.push('/' + parent.props.item.type + '/' + selectedItem.props.item.type);
                    }
                }
            }
        }
    };

    const toggleMenu = () => {
        document.getElementsByTagName('header')[0].classList.toggle('collapse');
        dispatch({ type: 'TOGGLE_MENU', payload: !settings.menu.minimized });
        try {
            const app = document.getElementsByClassName('application');
            if (app) {
                app[0].classList.toggle('menu-collapsed');
            }
        } catch (err) {
            //
        }

    };


    useEffect(() => {
        if (user && !ping) {
            setPing(setInterval(() => {
                postData(null, Endpoints.Ping);
            }, 1000 * 60 * 5));
        }

    });

    if (!user) {
        return <React.Fragment>
            <Application />
            <LoginPage />
        </React.Fragment>;
    }

    if (!user.TwoFactorAuthenticated && user.AuthType == 1) {
        var code = 0;
        return <React.Fragment>
            <Application />
            <div className='flex vertical gap-20 center'>
                <Card title='Authenticate' className='center'>
                    <p className='padding'>
                        {lang('User your google authenticator to continue')}
                    </p>
                    <div className='flex gap-5 padding'>
                        <label>{lang('Code')}</label>
                        <input type='number' maxLength={6} size={6} onChange={(e) => code = e.target.value} />
                    </div>
                    <div className='padding flex vertical gap-5'>
                        {authError && <div className='warning padding half'>Invalid code</div>}
                        <Button title='Continue' onClick={() => {
                            if (code.length < 6) {
                                return;
                            }
                            API.post(`${AuthenticationEndpoints.Validate2FA}/${code}/login`, null).then((result) => {
                                if (result.result) {
                                    dispatch({ type: 'TWO_FA_AUTHENTICATED', payload: null });
                                } else {
                                    setAuthError(true);
                                }
                            });
                        }} />
                    </div>
                </Card>
            </div>
        </React.Fragment>;
    }

    return <Router history={props.history}>
        <React.Fragment>
            <FrontendSocket />
            <Application />
            <Header onToggleMenuClicked={() => toggleMenu()} onSelect={onMenuItemSelected.bind(this)} />
            <div className={'main-switch user'}>
                <Menu history={props.history} onSelected={onMenuItemSelected.bind(this)} />
                <Switch>
                    <Route exact path='/' render={(props) => <Dashboard {...props} routerHistory={props.history} />} />
                    <Route exact path='/home' render={(props) => <Dashboard {...props} routerHistory={props.history} />} />
                    <Route path='/configuration' component={ConfigurationsPage} />
                    <Route path='/reports' component={ReportsPage} />
                    <Route path='/player' component={PlayersPage} />
                    <Route path='/riskManagement' component={RiskManagementPage} />
                    <Route path='/providers' component={ProvidersPage} />
                    <Route path='/gaming' component={GamingPage} />
                    <Route path='/transactions' component={TransactionsPage} />
                    <Route path='/affiliates' component={AffiliatePage} />
                    <Route path='/affiliatePlayers' component={AffiliatePlayersPage} />
                    <Route path='/retails' component={RetailsPage} />
                    <Route path='/manualBet' component={ManualBetsPage} />
                    <Route path='/lottery' component={LotteryPage} />
                    <Route path='/sports' component={SportOperationsPage} />
                    <Route path='/HorseGreyHound' component={HorsePage} />
                    <Route path='/setup' component={SetupPage} />
                    <Route path='/operators' component={OperatorPage} />
                    <Route path='/marketing' component={MarketingPage} />
                    <Route path='/promotions' component={PromotionsPage} />
                    <Route path='/cms' component={CMSPage} />
                    <Route path='/myProfile' component={MyProfilePage} />
                    <Route path='/management' component={ManagementPage} />
                    <Route path='/logout' component={LogoutPage} />
                </Switch>
                <Footer />
            </div>
        </React.Fragment>
    </Router>;

};


AppRouter.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object
};
