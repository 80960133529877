import React, { useEffect, useState } from 'react';
import { application, Endpoints } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { exportExcel } from '../../Centrum/ExcelExport';
import { lang } from '../../Centrum/Localization/lang';
import { BonusPeriods, BonusPeriodStartDay, BonusTypes } from '../../Centrum/_models/ModelEnums';
import { Button } from '../../Controls/Buttons/Button';
import { EditableTable } from '../../Controls/Table/Editable/EditableTable';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';
import { payPeriodicBonus, runPeriodicBonus } from './actions';


const exportToExcel = (model, results) => {
    var option = {};
    var headers = [];
    model.fields.map(x => {
        headers.push(x.display ? x.display : x.name);
    });

    option.fileName = 'export.xlsx';


    var data = [];
    results.map(x => {
        var row = {};
        model.fields.map(field => {
            var val = x[field.name];
            if (field.formatter) {
                val = field.formatter(val, x, false, true);
            } else if (field.type == InputTypes.dateTime && val) {
                val = new Date(val + 'Z').toLocaleString('en-Gb', { hours12: false });
            } else if (field.type == InputTypes.date && val) {
                val = new Date(val + 'Z').toLocaleDateString('en-Gb', { hours12: false });
            }

            row[field.display ? field.display : field.name] = val;
        });
        data.push(row);
    });


    option.datas = [
        {
            sheetData: data,
            sheetName: 'sheet',
            sheetHeader: headers
        }
    ];
    option.workbook = {
        SheetNames: [],
        Sheets: {},
    };

    var excel = new exportExcel(option);
    excel.saveExcel();
};

const payoutPlayersModel = {
    fields: [
        { name: 'playerId', display: 'Id' },
        { name: 'playerGroup', display: 'Group' },
        { name: 'username', display: 'Username' },
        { name: 'retailName', display: 'Retail' },
        { name: 'retailCode', display: 'Retail Code' },
        { name: 'currency', display: 'Currency' },
        { name: 'ggr', display: 'GGR' },
        { name: 'activePromotion', display: 'Active Promotion' },
        { name: 'amount', display: 'Payout', editable: false },
    ]
};

export const PeriodicPromotions = () => {
    const [dates, setDates] = useState(null);
    const [promotions, setPromotions] = useState(null);
    const [promotion, selectPromotion] = useState(null);
    const [results, setPromotionResults] = useState(null);
    //const user = useSelector((state) => state.user?.loginInformation);    

    useEffect(() => {
        application().resolve(null, Endpoints.ListPromotions, {}).then((result) => {
            setPromotions(result.result.filter(x => x.Periodic));
        });
    }, []);


    if (!promotions) return <div />;
    if (promotions.length == 0) return <div className='padding'>
        <span>{lang('there are no periodic promotions found')}</span>
    </div>;

    if (results != null) {
        results.map(x => x.__selected = true);
        return <Card key={results} caption={`${promotion.Name} Payouts`} menu={
            <div>
                <Button title='Change Filters' onClick={() => {
                    selectPromotion(Object.assign({}, promotion));
                    runPeriodicBonus(promotion, (promotionResults) => {
                        setDates(promotionResults.dates);
                        setPromotionResults(promotionResults.results);
                    });    
                }} />
                <Button title='Pay' onClick={() => {
                    var data = {};
                    data.removed = [];
                    results.filter(x => !x.__selected).map(x => {
                        data.removed.push(x.playerId);
                    });
                    data.dateStart = dates.dateStart;
                    data.dateEnd = dates.dateEnd;
                    data.onlyRetails = dates.onlyRetails;
                    data.retailCode = dates.retailCode;

                    payPeriodicBonus(promotion, data, () => {
                        application().modal.open(<Dialog title='Payout complete'>{lang('Bonus are assigned')}</Dialog>);
                    });
                }} />
                <Button title='Export to excel' onClick={() => {
                    exportToExcel(payoutPlayersModel, results);
                }} />
                <Button title='Close' onClick={() => {
                    selectPromotion(null);
                    setPromotionResults(null);
                }} />
            </div>
        }>
            <EditableTable
                model={payoutPlayersModel}
                selectable={true}
                data={results} />
        </Card >;
    }

    return <Card caption='Periodic Promotions'>
        <EditableTable model={{
            fields: [
                { name: 'Operator', display: 'Operator'},
                { name: 'Type', display: 'Type', formatter: (row) => Object.keys(BonusTypes)[Object.values(BonusTypes).findIndex(x => x == row.Type)] },
                { name: 'Name', display: 'Promotion' },
                { name: 'Period', display: 'Period', formatter: (row) => BonusPeriods[row.Period] },
                { name: 'PeriodStartDay', display: 'Start Day', formatter: (row) => BonusPeriodStartDay[row.PeriodStartDay] },
                { name: 'PeriodStartTime', display: 'Start Time' },
                { name: 'LastRun', display: 'Last Run', type: InputTypes.dateTime }
            ]
        }} data={promotions} buttons={(row) => {
            return <Button title='Run' onClick={() => {
                selectPromotion(row);
                runPeriodicBonus(row, (promotionResults) => {
                    setDates(promotionResults.dates);
                    setPromotionResults(promotionResults.results);
                });
            }} />;
        }} />
    </Card>;
};

PeriodicPromotions.propTypes = {

};