// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Search } from '../Centrum/Search/Search';

import { Page } from '../Centrum/Page/Page';
import { searchProviderGames, toggleProviderGameStatus, searchOperatorProviderGameConfiguration, toggleProviderGameStatusForOperator, updateProviderGame } from '../../state/actions/ProviderActions';

import { resetState } from '../../state/reducers/ApplicationReducer';
import { getActions, ActionTypes, getAction } from '../Centrum/Common/ActionManager';
import { searchOperators } from '../../state/actions/OperatorActions';
import { CentrumApplication, ApplicationContext, CentrumApplicationActions, CentrumApplicationContents } from '../Centrum/Application/CentrumApplication';
import { application, Endpoints } from '../Application/Application';
import { ConfirmationModal } from '../Modal/Modals/Confirmation/ConfirmationModal';
import { lang } from '../Centrum/Localization/lang';

const applicationActionType = ActionTypes.Gaming;
const applicationActions = getActions(applicationActionType);




// COMPONENT
class GamingPage extends Page {
    constructor(props) {
        super(props);
        let state = Object.assign({ selectedOperator: null }, props);
        this.state = state;
        this.applicationContext = null;
    }

    onActionClicked(action, items) {
        var item = items[0];
        var caption = item.Enabled ? 'Disable game' : 'Enable game';
        switch (action.type) {
            case ActionTypes.ToggleGameStatus:
                application().modal.open(<ConfirmationModal title={lang(caption)}
                    onConfirm={
                        () => {
                            application().resolve('', Endpoints.ToggleGameStatus + item.Id, null, action.caption, 'Operation Completed', 'Operation failed').then(() => {
                                item.Enabled = !item.Enabled;
                            });
                        }
                    }>
                    {lang('After your action all of the operators will be affected. Please confirm your action.')}
                </ConfirmationModal>);
                break;
            case ActionTypes.EditGame:
                this.applicationContext.edit(item, 'Edit Game', 'gameEdit', '/api/provider/updateGame/', 'large', 'col2', 'Operation Completed');
                break;
        }
    }

    onRenderConfigurationAction(action, items) {
        var item = items[0];
        if (item === undefined) return;
        if (action.type === ActionTypes.ToggleGameStatus) {
            return item.Enabled ? { caption: 'Global Disable', icon: 'disable' } : { caption: 'Global Enable', icon: 'enable' };
        }
    }


    render() {
        return (
            <CentrumApplication caption='Gaming' icon='gamepad' type={applicationActionType} loading={this.props.loading}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <Page>
                                        <Search
                                            caption='Search Provider Games'
                                            model='searchProviderGames'
                                            resultModel='providerGamesInformation'
                                            data={this.props.providerGames}
                                            actions={getAction(ActionTypes.GlobalGamingConfiguration).convertToItems()}
                                            onSubmit={(data, qb) => this.props.searchProviderGames(qb)}
                                            onRenderAction={this.onRenderConfigurationAction.bind(this)}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            ref={(r) => this.searchProviderGamesComponent = r}
                                        />
                                    </Page>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

GamingPage.defaultProps = {
    action: null,
    show: true,
    error: null,
};

GamingPage.propTypes = {
    resetState: PropTypes.func,
    action: PropTypes.object,
    show: PropTypes.bool,
    user: PropTypes.object,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    failed: PropTypes.bool,
    error: PropTypes.object
};


const mapStateToProps = state => {
    return {
        user: state.user,
        providerGames: state.providers.providerGames,
        operators: state.operator.operators,
        operatorProviderGameConfiguration: state.application.operatorProviderGameConfiguration,
        loading: state.application.loading,
        loaded: state.application.loaded,
        failed: state.application.failed,
        error: state.application.error
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        searchProviderGames: searchProviderGames,
        searchOperators: searchOperators,
        toggleProviderGameStatus: toggleProviderGameStatus,
        searchOperatorProviderGameConfiguration: searchOperatorProviderGameConfiguration,
        toggleProviderGameStatusForOperator: toggleProviderGameStatusForOperator,
        updateProviderGame: updateProviderGame,
        resetState: resetState
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(GamingPage);
export { hoc as GamingPage };