import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Tab } from '../../../Centrum/Tab/Tab';
import { BonusGeneralSettings } from '../Components/BonusGeneralSetttings';
import { BonusPlayerSettings } from '../Components/BonusPlayerSettings';
import { SportsBettingRules } from './SportsConfiguration/SportsConfiguration';


export const MultiplierBonus = (props) => {
    const [promotion, setPromotion] = useState(props.promotion);
    const [context, setContext] = useState(null);

    var tabButtons = [
        {
            title: 'General'
        },
        {
            title: 'Player Settings'
        },
        {
            title: 'Rules'
        },
    ];

    useEffect(() => {
        updateContext();
    }, [promotion]);

    useEffect(() => {
        updateContext();
    }, [context]);

    const updateContext = () => {
        props.context({
            save: () => {
                var copy = { ...promotion };
                if (!copy.Configuration) {
                    copy.Configuration = {
                        sportsBettingRules: null
                    };
                }

                if (typeof copy.Configuration === 'object') {
                    copy.Configuration.sportsBettingRules = context.save();
                } else {
                    copy.Configuration = JSON.parse(copy.Configuration);
                    copy.Configuration.sportsBettingRules = context.save();
                }
                return copy;
            }
        });
    };


    return <Tab buttons={tabButtons}>
        <BonusGeneralSettings promotion={promotion} onChange={(data) => {
            setPromotion(data);
        }} />
        <BonusPlayerSettings promotion={promotion} onChange={(data) => { setPromotion(data); }} />
        <SportsBettingRules promotion={promotion} context={(c) => {
            setContext(c);
        }} />
    </Tab>;
};

MultiplierBonus.propTypes = {
    promotion: PropTypes.object,
    context: PropTypes.func
};