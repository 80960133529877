import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Panel } from '../../../Centrum/Card/Card';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { CurrencyMinMaxConfiguration } from './CurrencyMinMaxConfiguration';

export const Payout = (props) => {
    const [payout, setPayout] = useState(props.payout ? props.payout :
        {
            automaticPayout: false,
            amount: null
        });
    var payoutConfigurationTable = null;
    const context = {
        save: () => {
            var copy = Object.assign({}, payout);
            copy.amount = payoutConfigurationTable.save();
            setPayout(copy);
            return copy;
        }
    };

    useEffect(() => {
        if (!props.context) return;
        props.context(context);
    });

    return <Card>
        <Form data={Object.assign({}, payout)}>
            <div className='row'>
                <FormInput type='bool' name='automaticPayout' title='Automatic payout' onChange={(f, t, value) => {
                    setPayout({
                        automaticPayout: value,
                        amount: payout.amount
                    });
                }} />
            </div>
        </Form>
        <Panel className='margin' title='Payout amount'>
            <CurrencyMinMaxConfiguration
                model={[
                    { name: 'Max', display: 'Max', type: 'number' }
                ]}
                data={payout.amount}
                fieldTitles={{ Max: 'Max' }} ref={(r) => payoutConfigurationTable = r} />
        </Panel>
    </Card >;
};

Payout.propTypes = {
    payout: PropTypes.object,
    context: PropTypes.func
};