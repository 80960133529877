import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';

export const AffiliatesModel = {
    'affiliates': {
        fields: [
            {
                name: 'Name',
                title: 'Name'
            },
            {
                name: 'GroupName',
                title: 'Group'
            },
            {
                name: 'Link',
                title: 'Link'
            },
            {
                name: 'Promotion',
                title: 'Promotion'
            },
            {
                name: 'LastSettlementDate',
                title: 'Last Settlement'
            },
            {
                name: 'LastSettlementAmount',
                title: 'Amount'
            },
            {
                name: 'Balance',
                title: 'Balance'
            },
            {
                name: 'TotalPlayers',
                title: 'Total Players'
            },
            {
                name: 'Enabled',
                title: 'Enabled',
                type: InputTypes.bool
            }
        ]
    },
    'affiliatesGroups': {
        fields: [
            {
                name: 'Name',
                title: 'Name'
            },
            {
                name: 'Description',
                title: 'Description'
            },
            {
                name: 'Type',
                title: 'Type',
                formatter: (val) => {
                    if (val == 0) return 'GGR';
                    return null;
                }
            },
            {
                name: 'ExcludeBonus',
                title: 'Exclude Bonus',
                type: InputTypes.bool
            },
            {
                name: 'ExcludePaymentGatewayComissions',
                title: 'Exclude Payment Gateway Commisions',
                type: InputTypes.bool
            },
            {
                name: 'Enabled',
                title: 'Enabled',
                type: InputTypes.bool
            }
        ]
    },
    'affiliateCommissionRanges': {
        fields: [
            {
                name: 'from',
                display: 'From'
            },
            {
                name: 'to',
                display: 'To'
            },
            {
                name: 'commission',
                display: 'Commission'
            }
        ]
    }
};