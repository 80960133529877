// IMPORT PACKAGE REFERENCES
import './_gameThumbsModal.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../../Application/Application';
import { lang } from '../../../../Centrum/Localization/lang';
import { LoadingIndicator } from '../../../../shared/LoadingIndicator/LoadingIndicator';
import { Icon } from '../../../../Controls/Icon/Icon';



// COMPONENT
export class GameThumbsModal extends Component {
    constructor(props) {
        super(props);

        this.providers = {};
        var selectedConfiguration = props.configuration?.find(x => x.name == 'default');
        this.state = Object.assign(
            {
                updating: false,
                selectedConfiguration: selectedConfiguration,
                newConfiguration: false
            }, props);
    }

    componentDidMount() {
        this.dropArea = document.getElementById('dropArea');
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.preventDefaults.bind(this), false);
            document.body.addEventListener(eventName, this.preventDefaults.bind(this), false);
        });

        ['dragenter', 'dragover'].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.highlight.bind(this), false);
        });

        ['dragleave', 'drop'].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.unhighlight.bind(this), false);
        });

        this.dropArea.addEventListener('drop', this.handleDrop.bind(this), false);


        if (this.props.game.filename == null) {
            var data = this.props.gameData;
            if (data) data = JSON.parse(data);

            if (this.props.game.providerName.toLowerCase().indexOf('softgaming') >= 0) {
                this.setState({ imageSource: data.ImageFullPath });
                return;
            }
            /*
            if (this.props.game.providerName == 'PragmaticPlay') {

                this.setState({ imageSource: data.dealer.avatarUrl });
            }
            */

            if (this.props.game.providerName == 'PragmaticPlay') {
                this.setState({ imageSource: data['s325x234'] });

                //{   "s325x234": "//https://superpari-dk2.pragmaticplay.net/game_pic/rec/325/vs10bbbonanza.png",   "s188x83": "//https://superpari-dk2.pragmaticplay.net/game_pic/rec/188/vs10bbbonanza.png",   "s160x115": "//https://superpari-dk2.pragmaticplay.net/game_pic/rec/160/vs10bbbonanza.png",   "s200x200": "//https://superpari-dk2.pragmaticplay.net/game_pic/square/200/vs10bbbonanza.png",   "s138x138": "//https://superpari-dk2.pragmaticplay.net/game_pic/square/138/vs10bbbonanza.jpg" }
            }


            if (this.props.game.providerName == 'SoftSwiss') {

                this.setState({ imageSource: data.image3 });
            }

            if (this.props.game.providerName == 'PlayPearls') {

                this.setState({ imageSource: data['1000x720'] });
            }

            switch (this.props.game.providerName) {
                case 'Vivo':
                case 'TombalaLive':
                case 'NetEnt,Egt':
                case 'Novomatic':
                case 'Apollo':
                case 'GClub':
                case 'Igrosoft':
                case 'Igt':
                case 'Merkur':
                case 'Microgaming':
                case 'Quickspin':
                case 'TomHorn':
                case 'Kiron':
                case 'Wazdan':
                    this.setState({ imageSource: data.Logo });
                    break;
            }

            if (data.Logo) this.setState({ imageSource: data.Logo });


            if (this.props.game.providerName == 'MediaLive') {
                this.setState({ imageSource: data.Picture });
            }

            if (this.props.game.providerName == 'WMCasino') {
                this.setState({ imageSource: data.Image });
            }

            if (this.props.game.providerName == 'NetEnt' || this.props.game.providerName == 'Egt' || this.props.game.providerName == 'Novomatic') {

                this.setState({ imageSource: data.Logo });
            }

            if (this.props.game.providerName == 'BetSolutions') {
                const thumbs = data.thumbnails;
                try {
                    this.setState({ imageSource: thumbs.find(x => x.Lang == 'en-US' && x.Width == 400).Url });
                } catch (err) {
                    console.log('error thumbnail not found');
                }
            }

        }
    }

    preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    highlight() {
        this.dropArea.classList.add('highlight');
    }

    unhighlight() {
        this.dropArea.classList.remove('highlight');
    }

    handleDrop(e) {
        var dt = e.dataTransfer;
        var files = dt.files;
        this.handleFiles(files[0]);
    }

    handleFiles(file) {
        this.previewFile(file);
    }

    previewFile(file) {
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {

                var newImage = this.dropArea.getElementsByClassName('newImage')[0];
                if (newImage) {
                    newImage.remove();
                }

                let img = document.createElement('img');
                img.src = reader.result;
                img.classList.add('newImage');
                var previewImage = this.dropArea.getElementsByClassName('previewImage')[0];
                previewImage.style.display = 'none';

                this.dropArea.appendChild(img);
            };
        } catch (e) {
            console.log(e);
        }
    }




    getBase64Image() {
        var image = this.dropArea.getElementsByClassName('newImage')[0];
        if (!image) {
            return this.state.imageSource;
        }


        var canvas = document.createElement('canvas');
        canvas.width = image.clientWidth;
        canvas.height = image.clientHeight;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        return canvas.toDataURL('image/png', 1);
    }

    createImageContent(url, data) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                processData: false,
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then((result) => {
                    resolve(result);
                }
                ).catch(e => {
                    reject(e);
                });
        });
    }

    save() {
        var width = this.state.selectedConfiguration?.width;
        var height = this.state.selectedConfiguration?.height;
        var ext = this.state.selectedConfiguration?.ext;


        if (this.state.newConfiguration) {
            width = parseInt(document.getElementById('config-width').value);
            height = parseInt(document.getElementById('config-height').value);
            ext = document.getElementById('config-ext').value;
        }


        var data = {
            image: this.getBase64Image(),
            width: width,
            height: height,
            name: this.props.game.gameId,
            extension: ext
        };

        this.setState({ updating: true });
        this.createImageContent(Endpoints.CMSCreateGameImage + '/' + this.props.game.gameId, data).then((result) => {
            if (result.result === 'ok') {
                this.props.onSave(this.props.game.gameId);
                this.setState({ updating: false, succes: true, error: false });
            } else {
                this.setState({ updating: false, error: true });
            }
        });
    }

    mediaConfigurationSelected(e) {
        this.setState({ selectedConfiguration: this.props.configuration?.find(x => x.name == e.target.value) });
    }

    selectImageFromUrl(e) {
        var newImage = this.dropArea.getElementsByClassName('newImage')[0];
        if (newImage) {
            newImage.remove();
        }

        var previewImage = this.dropArea.getElementsByClassName('previewImage')[0];
        previewImage.style.display = 'unset';


        this.setState({ imageSource: e.target.value });
    }

    renderProviderData() {
        try {
            var data = this.props.gameData;
            if (data) data = JSON.parse(data);
            data = JSON.stringify(data, null, 2);
            return <pre>{data}</pre>;
        } catch (err) {
            console.log(err);
        }
    }

    renderNewConfiguration() {
        if (!this.state.newConfiguration) {
            return;
        }

        var configDefaults = this.props.configuration.find(x => x.name == 'default');
        return <div className='newConfiguration'>
            <div className='name'><label>{lang('Name')}</label><input type='text' name='name' id='config-name' /></div>
            <div className='size'><label>{lang('Width')}</label><input type='number' name='width' id='config-width' defaultValue={configDefaults.width} /></div>
            <div className='size'><label>{lang('Height')}</label><input type='number' name='height' id='config-height' defaultValue={configDefaults.height} /></div>
            <div className='extension'><label>{lang('Extension')}</label>
                <select name='extension' defaultValue={configDefaults.ext} id='config-ext'>
                    <option value='png'>PNG</option>
                    <option value='jpg'>JPG</option>
                </select>
            </div>
            <div className='description'><label>{lang('Description')}</label><input type='text' name='description' id='config-description' /></div>
            <div><button type='button' onClick={() => this.setState({ newConfiguration: false })}>{lang('Cancel')}</button></div>
        </div>;
    }

    renderConfigurations() {
        return this.props.configuration?.map(config => {
            return <option key={config.name} value={config.name}>{config.name} {config.description} {config.width}x{config.height}px</option>;
        });
    }

    render() {
        var game = this.props.game;
        var source = this.props.cdnSource + game.filename;

        if (this.state.imageSource) {
            source = this.state.imageSource;
        }


        var description = '';

        return <React.Fragment>
            <div className={'modal-window gameThumbEditor ' + (this.state.updating ? ' busy' : '')}>
                <div className='title'><h1>[{this.props.game.gameId}] {this.props.game.providerName} / {this.props.game.name}</h1><Icon icon={'window-close'} size='lg' onClick={() => application().modal.close()} /></div>
                <div className='body'>
                    {this.state.error == true && <div className='error'>{lang('Could not save image')}</div>}
                    {this.state.succes == true && <div className='success'>{lang('Image saved')}</div>}
                    <div className='folderConfigurations'>
                        <span>{lang('Configurations')}</span>
                        <select name='configuration' defaultValue={this.state.selectedConfiguration?.name} onChangeCapture={this.mediaConfigurationSelected.bind(this)}>
                            {this.renderConfigurations()}
                        </select>
                        <Icon icon={'plus'} onClick={() => this.setState({ newConfiguration: true })} />
                    </div>
                    {this.renderNewConfiguration()}

                    <div className='url'>
                        <label>{lang('Url of the image')}</label>
                        <input name='url' onChangeCapture={this.selectImageFromUrl.bind(this)} defaultValue={this.state.imageSource} />
                    </div>
                    <div className='gameImage' id='dropArea'>
                        <img className='previewImage' src={source} />
                    </div>
                    <div className='contentInformation'>
                        <div>
                            <label>{lang('Name')}</label>
                            <input type='text' name='content-name' id='content-name' defaultValue={this.props.game.name} />
                        </div>
                        <div className='description'>
                            <label>{lang('Description')}</label>
                            <input type='text' name='content-description' id='content-description' defaultValue={description} />
                        </div>
                    </div>
                    <div className='providerData'>
                        {this.renderProviderData()}
                    </div>
                </div>
                <div className='footer'>
                    <button type='button' onClick={this.save.bind(this)}>{lang('Save')}</button>
                </div>
                {this.state.updating && <LoadingIndicator busy={true} />}
            </div>
        </React.Fragment>;
    }
}

GameThumbsModal.defaultProps = {
    game: null,
    gameData: null,
    cdnSource: '',
    onSave: () => { }
};

GameThumbsModal.propTypes = {
    configuration: PropTypes.array.isRequired,
    game: PropTypes.object,
    gameData: PropTypes.string,
    cdnSource: PropTypes.string,
    onSave: PropTypes.func
};

