
import { listerInitial, defaultPending, defaultFulfilled, defaultRejected, RESET_STATE } from './ApplicationReducer';
import { 
    GET_NODE_BY_TYPE_PENDING,
    GET_NODE_BY_TYPE_REJECTED, 
    GET_NODE_BY_TYPE_FULFILLED,
    GET_NODE_BY_KEY_PENDING, 
    GET_NODE_BY_KEY_FULFILLED, 
    GET_NODE_BY_KEY_REJECTED, 
    SEARCH_NODE_RESTRICTIONS_PENDING,
    SEARCH_NODE_RESTRICTIONS_FULFILLED,
    SEARCH_NODE_RESTRICTIONS_REJECTED,
    GET_CRASH_NODES_PENDING,
    GET_CRASH_NODES_FULFILLED,
    GET_CRASH_NODES_REJECTED} from '../actions/CentrumNodeActions';



const initialState = {
    nodes: listerInitial,
    restrictions:listerInitial,
    node: {},
    crashNodes: listerInitial,
    nodeNotifications:{},
    loading: false,
    loaded: false,
    failed: false,
    error: {},
    errorDisplayed: false
};


// REDUCER
export const CentrumNodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STATE:
            var object = Object.assign({}, state);
            if (action.payload === '' ||
                action.payload === undefined ||
                action.payload === null) {
                //console.log('can not reset undefined or null state object');
                object.failed = false;
                return object;
            }
            object[action.payload].failed = false;
            return object;
        
        
        // Nodes
        case GET_NODE_BY_TYPE_PENDING:
            return defaultPending('nodes', state);
        case GET_NODE_BY_TYPE_FULFILLED:
            return defaultFulfilled('nodes', state, action);
        case GET_NODE_BY_TYPE_REJECTED:
            return defaultRejected('nodes', state, action);

        // Crash Nodes
        case GET_CRASH_NODES_PENDING:
            return defaultPending('crashNodes', state);
        case GET_CRASH_NODES_FULFILLED:
            return defaultFulfilled('crashNodes', state, action);
        case GET_CRASH_NODES_REJECTED:
            return defaultRejected('crashNodes', state, action);

        // By Key
        case GET_NODE_BY_KEY_PENDING:
            return defaultPending('node', state, action);
        case GET_NODE_BY_KEY_FULFILLED:
            return defaultFulfilled('node', state, action);
        case GET_NODE_BY_KEY_REJECTED:
            return defaultRejected('node', state, action);
            
        // Restrictions
        case SEARCH_NODE_RESTRICTIONS_PENDING:
            return defaultPending('restrictions', state, action);
        case SEARCH_NODE_RESTRICTIONS_FULFILLED:
            return defaultFulfilled('restrictions', state, action);
        case SEARCH_NODE_RESTRICTIONS_REJECTED:
            return defaultRejected('restrictions', state, action);
            
        default:
            return state;
    }
};