import React from 'react';
import { application, Endpoints } from '../../../Application/Application';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { lang } from '../../../Centrum/Localization/lang';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';


export const manualWin = (ticket, onComplete) => {
    var form = null;
    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        var data = form.submit();
        data.ticketId = ticket.ExternalTicketId;

        application().resolve(null, `${Endpoints.ManualTicketWin}/${ticket.OperatorId}`, data, 'please wait', 'unable to change status of bet').then(() => {
            application().snackbar.open('Bet Status Changed');
            if (onComplete) onComplete();
        });
    }}>
        <Form ref={(r) => form = r} data={{ Amount: ticket.PossibleWin }}>
            <div className='row'>
                <FormInput title='Win Amount' type={InputTypes.number} name='Amount' />
            </div>
        </Form>
    </ConfirmationModal>);
};





export const manualLost = (ticket, onComplete) => {
    var form = null;

    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        var data = form.submit();
        data.ticketId = ticket.ExternalTicketId;

        application().resolve(null, `${Endpoints.ManualTicketLost}/${ticket.OperatorId}`, data, 'please wait', 'unable to change status of bet').then(() => {
            application().snackbar.open('Bet Status Changed');
            if (onComplete) onComplete();
        });
    }}>
        <Form ref={(r) => form = r} data={{ Amount: 0 }}>
            <div className='row'>
                <span>{lang('Do you confirm settlement of this ticket as Lost?')}</span>
            </div>
        </Form>
    </ConfirmationModal>);
};


export const manualCancel = (ticket, onComplete) => {
    var form = null;

    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        var data = form.submit();
        data.ticketId = ticket.ExternalTicketId;

        application().resolve(null, `${Endpoints.ManualTicketCancel}/${ticket.OperatorId}`, data, 'please wait', 'unable to change status of bet').then(() => {
            application().snackbar.open('Bet Status Changed');
            if (onComplete) onComplete();
        });
    }}>
        <Form ref={(r) => form = r} >
            <div className='row'>
                <FormInput title='Notes' type={InputTypes.textArea} name='Notes' />
            </div>
        </Form>
    </ConfirmationModal>);
};

export const manualRollback = (ticket, onComplete) => {
    var form = null;

    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        var data = form.submit();
        data.ticketId = ticket.ExternalTicketId;

        application().resolve(null, `${Endpoints.ManualTicketRollback}/${ticket.OperatorId}`, data, 'please wait', 'unable to change status of bet').then(() => {
            application().snackbar.open('Ticket Rollbacked');
            if (onComplete) onComplete();
        });
    }}>
        <Form ref={(r) => form = r} >
            <div className='row'>
                <FormInput title='Notes' type={InputTypes.textArea} name='Notes' />
            </div>
        </Form>
    </ConfirmationModal>);
};


export const manualBookedTicketWin = (ticket, onComplete) => {
    var form = null;

    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        var data = form.submit();
        data.ticketId = ticket.ExternalTicketId;

        application().resolve(null, Endpoints.WinBookABet, data, 'please wait', 'unable to change status of bet').then(() => {
            application().snackbar.open('Bet Status Changed');
            if (onComplete) onComplete();
            ticket.Status = 1;
        });
    }}>
        <Form ref={(r) => form = r} data={{ Amount: ticket.PossibleWin }}>
            <div className='row'>
                <FormInput title='Win Amount' type={InputTypes.number} name='Amount' />
            </div>
        </Form>
    </ConfirmationModal>);
};



export const manualBookedTicketLost = (ticket, onComplete) => {
    var form = null;

    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        var data = form.submit();
        data.ticketId = ticket.ExternalTicketId;

        application().resolve(null, Endpoints.LostBookABet, data, 'please wait', 'unable to change status of bet').then(() => {
            application().snackbar.open('Bet Status Changed');
            if (onComplete) onComplete();
            ticket.Status = 2;
        });
    }}>
        <Form ref={(r) => form = r} data={{ Amount: ticket.PossibleWin }}>
            <span>{lang('Do you confirm settlement of this ticket as Lost?')}</span>
        </Form>
    </ConfirmationModal>);
};





export const bookBet = (player, existingTicket = { Stake: 1, Odds: 1, Win: 0, Prematch: false, Live: false, Racing: false }) => {
    var form = null;
    var dueDate = null;
    application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
        var data = form.submit();
        data.PlayerId = player.Id;
        if (dueDate) {
            data.DueDate = new Date(dueDate).toISOString();
        }
        if (!existingTicket.Id) data.Id = existingTicket.Id;
        application().resolve(null, Endpoints.BookABet, data, 'please wait', 'unable to book bet').then(() => {
            application.snackbar.open('Bet placed');
        });
    }}>
        <Form ref={(r) => form = r} data={existingTicket}>
            <div className='row big-textarea'>
                <FormInput title='Bets' type={InputTypes.textArea} name='Bets' />
            </div>
            <div className='row'>
                <div className='form-group'>
                    <label>{lang('Due Date')}</label>
                    <input type='datetime-local' name='DueDate' onChangeCapture={(e) => { dueDate = e.target.value; }} />
                </div>
            </div>
            <div className='row'>
                <FormInput title='Prematch' type={InputTypes.bool} name='Prematch' />
                <FormInput title='Live' type={InputTypes.bool} name='Live' />
            </div>
            <div className='row'>
                <FormInput title='Racing' type={InputTypes.bool} name='Racing' />
            </div>
            <div className='row'>
                <FormInput title='Stake' type={InputTypes.number} name='Stake' />
                <FormInput title='Odds' type={InputTypes.number} name='Odds' />
            </div>
        </Form>
    </ConfirmationModal>);
};



export const rejectPendingOffer = (offer, onComplete) => {
    var form = null;
    application().modal.open(<ConfirmationModal title='Reject pending ticket?' onConfirm={() => {
        var data = form.submit();
        offer.notes = data.Notes;
        application().resolve(null, `${Endpoints.RejectPendingTicket}`, offer, 'please wait', 'unable to update offer').then(() => {
            application().snackbar.open('Offer rejected');
            if (onComplete) onComplete();
        });
    }}>
        <span>{lang('Do you reject accepting limit over bet?')}</span>
        <Form ref={(r) => form = r}>
            <div className='row'>
                <FormInput title='Notes' type={InputTypes.textArea} name='Notes' />
            </div>
        </Form>

    </ConfirmationModal>);
};

export const confirmPendingOffer = (offer, onComplete, ask = false) => {
    application().modal.open(<ConfirmationModal title='Confirm pending ticket?' onConfirm={() => {
        application().resolve(null, `${Endpoints.ConfirmPendingTicket}/${ask}`, offer, 'please wait', 'unable to update offer').then(() => {
            application().snackbar.open(ask ? 'Offer asked for user confirmation' : 'Offer confirmed and bet accepted');
            if (onComplete) onComplete();
        });
    }}>
        <span>{lang('Do you confirm accepting limit over bet? This operation will not affect the event limits.')}</span>
    </ConfirmationModal>);
};

