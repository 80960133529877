import React from 'react';

import { getActions, ActionTypes } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { Page } from '../../Centrum/Page/Page';
import { ListRetails } from './ListRetails';
import { AddRetail } from './AddRetail';

const applicationActionType = ActionTypes.Retails;
const applicationActions = getActions(applicationActionType);



// COMPONENT
export class RetailsPage extends Page {
    constructor(props) {
        super(props);

        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.action = action;

        this.state = state;
        this.applicationContext = null;
    }



    render() {
        return (
            <CentrumApplication
                caption='Retails' icon='industry'
                type={applicationActionType}
                loading={this.props.loading}
            >
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <ListRetails />
                                    <AddRetail />
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

RetailsPage.defaultProps = {
};

RetailsPage.propTypes = {
};

