import './_manualBonus.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { BonusGeneralSettings, BonusPlayerSettings, GameSettings } from '../Components/BonusComponents';
import { Tab } from '../../../Centrum/Tab/Tab';
import { Card } from '../../../Centrum/Card/Card';

export class ManualBonus extends React.Component {
    constructor(props) {
        super(props);
        var state = Object.assign({}, props);
        state.multiplers = {};
        for (var i = 0; i < 20; i++) {
            state.multiplers[i] = 'x' + i;
        }

        this.state = state;
    }


    save() {
        var promotion = this.generalSettings.save();
        var playerSettings = this.playerSettings.save();
        var gameSettings = this.gameSettings.save();

        var result = Object.assign(promotion, {});
        result = Object.assign(playerSettings, result);
        result = Object.assign(gameSettings, result);
        return result;
    }

    render() {
        var tabButtons = [
            {
                title: 'General'
            },
            {
                title: 'Player Settings'
            },
            {
                title: 'Game Settings'
            }
        ];
        return <React.Fragment>
            <Tab buttons={tabButtons}>
                <Card key='general'>
                    <BonusGeneralSettings promotion={this.state.promotion} ref={(r) => this.generalSettings = r} />
                </Card>
                <Card key='player'>
                    <BonusPlayerSettings promotion={this.state.promotion} ref={(r) => this.playerSettings = r} />
                </Card>
                <Card key='gameSettings'>
                    <GameSettings promotion={this.state.promotion} ref={(r) => this.gameSettings = r} />
                </Card>
            </Tab>
        </React.Fragment >;
    }
}

ManualBonus.propTypes = {
    promotion: PropTypes.object
};

