import { InputTypes} from '../CentrumForm/Inputs/CentrumInput';
import { formatDistance } from 'date-fns/fp';
import { getUtcDate} from '../helpers';
import { NodeRestrictionTypes } from './ModelEnums';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';



export const CentrumHubModels = {
    serverInformation:{
        fields: [
            {
                name: 'NodeKey',
                type: InputTypes.text,
                hidden:true
            },            
            {
                name: 'Endpoint',
                display:'Server Endpoint',
                type: InputTypes.text,
                readonly:true
            },
            {
                name:'StartTime',
                display:'Start Time',
                type:InputTypes.text,
                formatter:(val)=>{
                    return formatDistance(getUtcDate(new Date(val)),new Date());
                },
                readonly:true
            },
            {
                name:'LastPingTime',
                display:'Last Ping Time',
                type:InputTypes.text,
                formatter:(val)=>{
                    return formatDistance(getUtcDate(new Date(val)),new Date());
                },
                readonly:true
            },
            {
                name: 'Log',
                display:'Log',
                type: InputTypes.code,
                multiLine:true,
                readonly:true
            }
        ]
    },
    hubActionWithMessage:{
        fields: [
            {
                name: 'Name',
                type: InputTypes.text,
                readonly:true
            },
            {
                name: 'Message',
                display:'Message',
                type: InputTypes.text,
                multiLine:true
            }
        ]
    },
    searchNodeRestrictions:{
        fields :[
            {
                name:'RestrictionType',
                display:'Type',
                type:InputTypes.select,
                values:NodeRestrictionTypes,
                acceptEmptyValues:true
            },
            {
                name: 'EntryDate',
                display:'Entry Date',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data:DateRanges['This Month']
                }
            },
            {
                name:'Player',
                display:'Restricted Player',
                type:InputTypes.text
            },
            {
                name:'Admin',
                display:'Restricted By',
                type:InputTypes.text
            },
            {
                name:'RestrictionRule',
                display:'Restriction Rule',
                type:InputTypes.text
            }
        ]
    },
    nodeRestrictionInformation:{
        fields :[
            {
                name: 'Id',
                type: InputTypes.number
            },
            {
                name: 'EntryDate',
                display:'Entry Date',
                type: InputTypes.dateTime
            },            
            {
                name:'RestrictionType',
                display:'Type',
                type:InputTypes.select,
                values:NodeRestrictionTypes
            },
            {
                name:'RestrictionRule',
                display:'Restriction Rule',
                type:InputTypes.text
            },
            {
                name:'Player',
                type:InputTypes.text
            },
            {
                name:'Admin',
                display:'Restricted by',
                type:InputTypes.text
            },
            {
                name:'Notes',
                display:'Notes',
                type:InputTypes.text
            }
        ]
    },
};