import { defaultPending, defaultFulfilled, defaultRejected } from './ApplicationReducer';

import { 
    SEARCH_PROVIDERS_PENDING, 
    SEARCH_PROVIDERS_FULFILLED, 
    SEARCH_PROVIDERS_REJECTED,
    SEARCH_PROVIDER_GAMES_PENDING,
    SEARCH_PROVIDER_GAMES_FULFILLED,
    SEARCH_PROVIDER_GAMES_REJECTED,
    SEARCH_OPERATOR_PROVIDER_CONFIGURATION_PENDING,
    SEARCH_OPERATOR_PROVIDER_CONFIGURATION_FULFILLED,
    SEARCH_OPERATOR_PROVIDER_CONFIGURATION_REJECTED,
    SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_PENDING,
    SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_FULFILLED,
    SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_REJECTED
} from '../actions/ProviderActions';


const initialState = {
    loading: false,
    loaded: false,
    failed: false,
    error: {},
    errorDisplayed: false
};



// REDUCER
export const ProvidersReducer = (state = initialState, action) => {
    switch (action.type) {

        // Providers
        case SEARCH_PROVIDERS_PENDING:
            return defaultPending('providers', state);
        case SEARCH_PROVIDERS_FULFILLED:
            return defaultFulfilled('providers', state, action);
        case SEARCH_PROVIDERS_REJECTED:
            return defaultRejected('providers', state, action);

        case SEARCH_PROVIDER_GAMES_PENDING:
            return defaultPending('providerGames', state);
        case SEARCH_PROVIDER_GAMES_FULFILLED:
            return defaultFulfilled('providerGames', state, action);
        case SEARCH_PROVIDER_GAMES_REJECTED:
            return defaultRejected('providerGames', state, action);

        case SEARCH_OPERATOR_PROVIDER_CONFIGURATION_PENDING:
            return defaultPending('operatorProviderConfiguration', state, action);            
        case SEARCH_OPERATOR_PROVIDER_CONFIGURATION_FULFILLED:
            return defaultFulfilled('operatorProviderConfiguration', state, action);            
        case SEARCH_OPERATOR_PROVIDER_CONFIGURATION_REJECTED:
            return defaultRejected('operatorProviderConfiguration', state, action);

        case SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_PENDING:
            return defaultPending('operatorProviderGameConfiguration', state, action);            
        case SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_FULFILLED:
            return defaultFulfilled('operatorProviderGameConfiguration', state, action);            
        case SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_REJECTED:
            return defaultRejected('operatorProviderGameConfiguration', state, action);

        default:
            return state;
    }
};