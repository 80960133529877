import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../Icon/Icon';
import { CheckBox } from '../../Inputs/CheckBox/CheckBox';


export const TableHead = (props) => {
    const [sortingField, setSortingField] = useState(props.sortingField);
    const [sortingDirection, setSortingDirection] = useState(props.sortingDirection);

    const renderSortable = (field) => {
        if (!props.sortable) return;
        return <div className='sorting vertical' key={field.name + sortingField}>
            <Icon icon='angle-up' size='xs' className={(sortingField == field.name && sortingDirection == 1) ? '' : 'passive'} onClick={() => {
                setSortingField(field.name);
                setSortingDirection(1);
                props.onSortingChanged(field.name, 1);
            }} />
            <Icon icon='angle-down' size='xs' className={(sortingField == field.name && sortingDirection == 0) ? '' : 'passive'} onClick={() => {
                setSortingField(field.name);
                setSortingDirection(0);

                props.onSortingChanged(field.name, 0);
            }} />
        </div>;
    };

    const toggleCheck = () => {
        const allSelected = props.data.filter(x => !x.__selected);
        var value = allSelected.length == 0;

        props.data.map(x => x.__selected = !value);
        props.data.key = Date.now();
        props.onUpdateData(props.data);
    };

    const data = props.data ? props.data : [];
    const allSelected = data.filter(x => !x.__selected);

    return <thead>
        <tr>
            {props.draggable && <th></th>}
            {props.selectable && <th><CheckBox key={allSelected} onClick={() => toggleCheck()} checked={allSelected.length == 0} /></th>}
            {props.model.fields.map(x => {
                var classList = [props.sortable && sortingField == x.name ? 'sorting-direction-' + (sortingDirection == 0 ? 'up' : 'down') : ''];
                if (x.className) classList.push(x.className);
                return <th key={x.name} className={classList.join(' ')}>
                    {props.sortable && renderSortable(x)}
                    {x.display}
                </th>;
            })}
            {props.buttons && <th></th>}
        </tr>
    </thead>;
};

TableHead.propTypes = {
    model: PropTypes.object,
    data: PropTypes.array,
    sortingField: PropTypes.string,
    sortingDirection: PropTypes.number,
    draggable: PropTypes.bool,
    sortable: PropTypes.bool,
    selectable: PropTypes.bool,
    buttons: PropTypes.func,
    onSortingChanged: PropTypes.func,
    onUpdateData: PropTypes.func
};
