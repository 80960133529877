/* eslint-disable react/display-name */

import './_configuration.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { FormInput } from '../../../Controls/Form/FormInput';



export const GeneralConfiguration = (props) => {
    const [retail, setRetail] = useState(props.configuration);

    useEffect(() => {
        props.onUpdate(retail);
    }, [retail]);

    return <div className='padding'>
        <div className='row'>
            <FormInput title='Enable Sub Retails' value={retail.configuration.subRetails == true} type={InputTypes.bool} name='enabled' onChange={(form, field, value) => {
                var copy = Object.assign({}, retail);
                copy.configuration.subRetails = value;
                setRetail(copy);
                props.onUpdate(copy);
            }} />
        </div>
        <div className='row'>
            <FormInput title='Enable Sub Users' value={retail.configuration.subUsers == true} type={InputTypes.bool} name='enabled' onChange={(form, field, value) => {
                var copy = Object.assign({}, retail);
                copy.configuration.subUsers = value;
                setRetail(copy);
                props.onUpdate(copy);
            }} />
        </div>
    </div>;
};

GeneralConfiguration.propTypes = {
    configuration: PropTypes.object,
    onUpdate: PropTypes.func
};