import './_sportsbooklimits.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../../Application/Application';
import { Card } from '../../../../Centrum/Card/Card';

import { Button } from '../../../../Controls/Buttons/Button';
import { Dialog } from '../../../../Modal/Modals/Dialog/Dialog';
import { lang } from '../../../../Centrum/Localization/lang';
import { EditableTable } from '../../../../Controls/Table/Editable/EditableTable';
import { ConfirmationModal } from '../../../../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../../../../Controls/Form/Form';
import { toSnakeCase } from '../../../../Centrum/helpers';
import { Icon } from '../../../../../v2/Components/Icon/Icon';



const eventLimitModel = {
    fields: [
        {
            name: 'id',
            display: 'Id',
            formatter: (row) => {
                if (row.id == -1) return 'New event';
                return row.id;
            }
        },
        {
            name: 'name',
            display: 'Event'
        },
        {
            name: 'matchDate',
            display: 'Date',
            formatter: (row) => {
                if (row.id == -1) return '';
                return new Date(row.date).toLocaleString('en-gb', { hours12: false });
            }
        },
        {
            name: 'sport',
            display: 'Sport',
            formatter: (row) => {
                return row.sport;
            }
        },
        {
            name: 'league',
            display: 'League',
            formatter: (row) => {
                return row.league;
            }
        },
        {
            name: 'marketCap',
            type: 'number',
            display: 'Market Cap',
            editable: true
        },
        {
            name: 'maximumStake',
            type: 'number',
            display: 'Stake Volume',
            editable: true
        },
        {
            name: 'maximumStakeLive',
            type: 'number',
            display: 'Stake Live Volume',
            editable: true
        },
        {
            name: 'maximumLiability',
            type: 'number',
            display: 'Liability',
            editable: true
        },
        {
            name: 'maximumLiabilityLive',
            type: 'number',
            display: 'Live Liability',
            editable: true
        },
        {
            name: 'offer',
            type: 'bool',
            display: 'Offer',
            editable: true
        }
    ]
};



const eventGroupsModel = {
    fields: [
        {
            name: 'name',
            display: 'Name',
        },
        {
            name: 'marketCap',
            type: 'number',
            display: 'Market Cap',
            editable: true
        },
        {
            name: 'maximumStake',
            type: 'number',
            display: 'Stake Volume',
            editable: true
        },
        {
            name: 'maximumStakeLive',
            type: 'number',
            display: 'Stake Live Volume',
            editable: true
        },
        {
            name: 'maximumLiability',
            type: 'number',
            display: 'Liability',
            editable: true
        },
        {
            name: 'maximumLiabilityLive',
            type: 'number',
            display: 'Live Liability',
            editable: true
        },
        {
            name: 'offer',
            type: 'bool',
            display: 'Offer',
            editable: true
        }
    ]
};


const EventSelector = (props) => {
    const [events, selectEvents] = useState([]);
    const [selectedEvent, selectEvent] = useState(null);
    var timeout = null;
    const findEvent = (name) => {
        if (timeout) clearTimeout(timeout);
        if (name.length < 4) return;
        timeout = setTimeout(() => {
            application().resolve(null, `${Endpoints.SearchSportEvents}/${name}`).then((r) => {
                selectEvents(r.result);
            });
        }, 500);
    };

    return <Form>
        <div className='row'>
            <div className='form-group'>
                <label>Event</label>
                <input onChangeCapture={(e) => findEvent(e.target.value)} />
            </div>
        </div>
        <div className='found-events centrum-flex'>
            <table className='centrum-table'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Event</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map(x => {
                        return <tr key={x.id} onClick={() => {
                            selectEvent(x);
                            props.onSelect(x);
                        }} className={x == selectedEvent ? ' selected' : ''}>
                            <td>{x.id}</td><td>{x.name}</td><td>{new Date(x.date + 'Z').toLocaleString('en-gb', { hours12: false })}</td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>
    </Form>;
};

EventSelector.propTypes = {
    onSelect: PropTypes.func
};




export const SportEventLimits = (props) => {
    const [configurationEnabled, setConfigurationEnabled] = useState(true);
    const [groups, setGroups] = useState(null);
    const [leagueLimits, setLeagueLimits] = useState(null);
    const [eventLimits, setEventLimits] = useState([]);
    const [selectedEvent, selectEvent] = useState(null);
    const [selectedEventGroup, selectEventGroup] = useState(null);
    const [newKey, setKey] = useState(Date.now());
    const [eventGroupKey, setEventGroupKey] = useState(Date.now());

    const context = {
        groups: groups,
        leagueLimits: leagueLimits
    };

    useEffect(() => {
        const riskType = window.user.features.configuration?.risk ? JSON.parse(window.user.features.configuration.risk).type : { type: 0 };
        if (riskType.type == 1) {
            setConfigurationEnabled(false);
            return;
        }

        application().resolve(null, Endpoints.GetPlayerGroups).then((playerGroups) => {
            setGroups(playerGroups.result);

            application().resolve(null, Endpoints.GetLeagueLimits).then((result) => {
                var leagueLimitsConverted = result.result;
                try {
                    leagueLimitsConverted = JSON.parse(leagueLimitsConverted);
                } catch (err) {
                    leagueLimitsConverted = null;
                }
                leagueLimitsConverted = leagueLimitsConverted ? leagueLimitsConverted : {};

                application().resolve(null, Endpoints.GetEventLimits).then((result) => {
                    try {
                        var limitsConverted = result.result;
                        try {
                            limitsConverted = JSON.parse(limitsConverted);
                        } catch (err) {
                            //
                            limitsConverted = null;
                        }
                        var limits = limitsConverted ? limitsConverted : {};
                        var sorted = [];

                        Object.keys(limits).map((eventId, index) => {
                            var event = limits[eventId];
                            event = toSnakeCase(event);
                            event.general = toSnakeCase(event.general);
                            var limit = {
                                id: parseFloat(eventId),
                                index: index,
                                name: event.name,
                                date: event.date,
                                sport: event.sport,
                                sportId: event.sportId,
                                region: event.region,
                                regionId: event.regionId,
                                leauge: event.league,
                                leaugeId: event.leagueId,
                                marketCap: event.general.marketCap,
                                maximumStake: event.general.stake.prematch,
                                maximumStakeLive: event.general.stake.live,
                                maximumLiability: event.general.liability.prematch,
                                maximumLiabilityLive: event.general.liability.live,
                                offer: event.general.offer,
                                general: event.general,
                                groups: event.groups
                            };
                            sorted.push(limit);

                            if (props.event && props.event.id.toString() == eventId.toString()) {
                                props.event = limit;
                            }
                        });

                        setEventLimits(sorted);
                        setLeagueLimits(leagueLimitsConverted);
                    } catch (err) {
                        console.log('event limits', err);
                    }
                });
            });
        });
    }, []);

    useEffect(() => {
        if (!selectedEvent) return;
        var eventGroup = selectedEvent.groups;
        if (!eventGroup) {
            eventGroup = [];
            groups.map(x => {
                eventGroup.push({
                    id: x.Id,
                    name: x.Name,
                    marketCap: 0,
                    maximumStake: 0,
                    maximumStakeLive: 0,
                    maximumLiability: 0,
                    maximumLiabilityLive: 0,
                    offer: false
                });
            });
        } else {
            var newList = [];
            groups.map(x => {
                if (eventGroup.hasOwnProperty(x.Id)) {
                    newList.push({
                        id: x.Id,
                        name: x.Name,
                        marketCap: eventGroup[x.Id].marketCap,
                        maximumStake: eventGroup[x.Id].stake.prematch,
                        maximumStakeLive: eventGroup[x.Id].stake.live,
                        maximumLiability: eventGroup[x.Id].liability.prematch,
                        maximumLiabilityLive: eventGroup[x.Id].liability.live,
                        offer: eventGroup[x.Id].offer
                    });
                } else {
                    newList.push({
                        id: x.Id,
                        name: x.Name,
                        marketCap: 0,
                        maximumStake: 0,
                        maximumStakeLive: 0,
                        maximumLiability: 0,
                        maximumLiabilityLive: 0,
                        offer: false
                    });
                }
            });

            eventGroup = newList;
        }

        setKey(Date.now());
        selectEventGroup(eventGroup);
    }, [selectedEvent]);


    useEffect(() => {
        if (!leagueLimits) return;
        if (props.event) {
            if (!props.event.general) {
                props.event = setDefaultLimitsFromLeague(props.event);
            }
            var copy = Object.assign([], eventLimits);
            copy = copy.filter(x => x.id.toString() !== props.event.id.toString());
            props.event.index = Date.now();
            copy.push(props.event);
            setEventLimits(copy);
            selectEvent(props.event);
        }
    }, [leagueLimits]);

    const onChange = () => {
        console.log(eventLimits);
    };

    const deleteLimit = (row) => {
        const copy = Object.assign([], eventLimits.filter(x => x.index != row.index));
        setKey(Date.now());
        setEventLimits(copy);
    };

    const addNew = () => {
        var selected = null;
        const eventSelected = (e) => {
            selected = e;
        };

        application().modal.open(<ConfirmationModal title='Select event' onConfirm={() => {
            setKey(Date.now());
            var empty = {
                index: Date.now(),
                id: selected.id,
                name: selected.name,
                date: selected.date,
                sport: selected.sport,
                sportId: selected.sportId,
                region: selected.region,
                regionId: selected.regionId,
                league: selected.league,
                leagueId: selected.leagueId,
                groups: null,
                players: null
            };

            empty = setDefaultLimitsFromLeague(empty);

            var copy = Object.assign([], eventLimits);
            var newRow = Object.assign({}, eventLimits.length > 1 ? eventLimits[eventLimits.length - 1] : empty);

            newRow.index = Date.now();
            copy.push(empty);
            setEventLimits(copy);
        }}>
            <EventSelector onSelect={(e) => eventSelected(e)} />
        </ConfirmationModal>);
    };

    const setDefaultLimitsFromLeague = (event) => {
        if (leagueLimits) {
            var leagueLimit = leagueLimits.hasOwnProperty(event.leagueId) ? leagueLimits[event.leagueId] : null;
            if (!leagueLimit) leagueLimit = leagueLimits.hasOwnProperty(0) ? leagueLimits[0] : null;
            if (leagueLimit) {
                event.general = leagueLimit.general;
                event.groups = leagueLimit.groups;
                event.marketCap = event.general.marketCap;
                event.maximumStake = event.general.stake.prematch;
                event.maximumStakeLive = event.general.stake.live;
                event.maximumLiability = event.general.liability.prematch;
                event.maximumLiabilityLive = event.general.liability.live;
                event.offer = event.general.offer;
            }
        }
        return event;
    };

    const saveGroup = () => {
        var eleminated = {};
        selectedEventGroup.map(x => {
            var marketCap = parseInt(x.marketCap);
            var maximumStake = parseInt(x.maximumStake);
            var maximumStakeLive = parseInt(x.maximumStakeLive);
            var maximumLiability = parseInt(x.maximumLiability);
            var maximumLiabilityLive = parseInt(x.maximumLiabilityLive);
            if (isNaN(marketCap)) marketCap = 0;
            if (isNaN(maximumStake)) maximumStake = 0;
            if (isNaN(maximumStakeLive)) maximumStakeLive = 0;
            if (isNaN(maximumLiability)) maximumLiability = 0;
            if (isNaN(maximumLiabilityLive)) maximumLiabilityLive = 0;
            if (maximumStake > 0 || maximumStakeLive > 0 || maximumLiability > 0 || maximumLiabilityLive > 0) {
                eleminated[x.id] = {
                    marketCap: marketCap,
                    stake: {
                        live: maximumStakeLive,
                        prematch: maximumStake
                    },
                    liability: {
                        live: maximumLiabilityLive,
                        prematch: maximumLiability
                    },
                    offer: x.offer
                };
            }
        });

        selectedEvent.groups = eleminated;
        if (Object.keys(selectedEvent.groups).length == 0) selectedEvent.groups = null;
    };

    const save = () => {
        var saveData = Object.assign([], eventLimits);
        var data = {};
        saveData.map(x => {
            if (x.id == 'undefined' || !x.id) {
                return;
            }

            delete x.index;
            data[x.id] = {
                id: parseInt(!x.id ? 0 : x.id),
                name: x.name,
                date: x.date,
                sport: x.sport,
                sportId: parseInt(!x.sportId ? 0 : x.sportId),
                region: x.region,
                regionId: parseInt(!x.regionId ? 0 : x.regionId),
                league: x.league,
                leagueId: parseInt(!x.leagueId ? 0 : x.leagueId),
                general: {
                    marketCap: x.marketCap,
                    stake: {
                        live: parseInt(!x.maximumStakeLive ? 0 : x.maximumStakeLive),
                        prematch: parseInt(!x.maximumStake ? 0 : x.maximumStake)
                    },
                    liability: {
                        live: parseInt(!x.maximumLiabilityLive ? 0 : x.maximumLiabilityLive),
                        prematch: parseInt(!x.maximumLiability ? 0 : x.maximumLiability)
                    },
                    offer: x.offer
                },
                groups: x.groups
            };
        });




        application().resolve(null, Endpoints.SaveEventLimits, data).then(() => {
            application().modal.open(<Dialog title={'Event Limits'}>
                {lang('Event limits saved')}
            </Dialog >);
        });
    };

    const onGroupChange = () => {
        selectedEvent.maximumStake = 0;
        selectedEvent.maximumStakeLive = 0;
        selectedEvent.maximumLiability = 0;
        selectedEvent.maximumLiabilityLive = 0;

        selectedEventGroup.map(x => {
            var stake = parseFloat(x.maximumStake);
            var stakeLive = parseFloat(x.maximumStakeLive);
            var liability = parseFloat(x.maximumLiability);
            var liabilityLive = parseFloat(x.maximumLiabilityLive);
            if (isNaN(stake)) stake = 0;
            if (isNaN(stakeLive)) stakeLive = 0;
            if (isNaN(liability)) liability = 0;
            if (isNaN(liabilityLive)) liabilityLive = 0;
            selectedEvent.maximumStake += stake;
            selectedEvent.maximumStakeLive += stakeLive;
            selectedEvent.maximumLiability += liability;
            selectedEvent.maximumLiabilityLive += liabilityLive;
        });

        selectedEvent.general.stake.prematch = selectedEvent.stake;
        selectedEvent.general.stake.live = selectedEvent.stakeLive;
        selectedEvent.general.liability.prematch = selectedEvent.maximumLiability;
        selectedEvent.general.liability.live = selectedEvent.maximumLiabilityLive;
        selectedEvent.general.offer = selectedEvent.offer;

        selectEvent(selectedEvent);
        setEventGroupKey(Date.now());
    };

    if (selectedEvent) {
        return <Card key={newKey} caption={parseInt(selectedEvent.id) > 0 ? selectedEvent.name : 'Default Player Group Configuration'} menu={
            <div className='centrum-flex'>
                <Button title='Close' onClick={() => {
                    if (props.onClose) {
                        props.onClose();
                        return;
                    }
                    selectEvent(null);
                }} />
                {props.event &&
                    <Button title='Save' onClick={() => {
                        saveGroup();
                        if (props.event) {
                            save();
                        }
                    }} />
                }
            </div>
        }>
            <Card caption='Event configuration'>
                <EditableTable model={eventLimitModel} data={[selectedEvent]} readonly={false} key={eventGroupKey} />
            </Card>
            <Card caption='Group configuration'>
                <EditableTable model={eventGroupsModel} data={selectedEventGroup}
                    onChange={() => onGroupChange()}
                    buttons={(row) => {
                        return <Button title='Clear' onClick={() => {
                            row.marketCap = 0;
                            row.maximumStake = 0;
                            row.maximumStakeLive = 0;
                            row.maximumLiabilityLive = 0;
                            row.maximumLiability = 0;
                            row.offer = false;
                            saveGroup();
                            selectEvent(selectedEvent);
                            setKey(Date.now());
                        }} />;
                    }} />
            </Card>
        </Card>;
    }


    if (!configurationEnabled) {
        return <Card caption='Event Limits' className='event-limits'>
            <div className='padding border radius margin flex gap-5'>
                <Icon icon='info-circle' />
                <p>{lang('Event Limits is not enabled for this risk management configuration, use Risk Levels')}</p>
            </div>
        </Card>;
    }

    return <Card caption='Event Limits' className='event-limits'>
        <div className={'centrum-flex stretch event-list'}>
            <Card caption='Events' menu={<Button icon={'plus'} title='Add' onClick={() => addNew()} />}>
                <div className='event-items'>
                    <EditableTable key={newKey} context={context} model={eventLimitModel} data={eventLimits} onChange={onChange.bind(this)} buttons={(row) => {
                        return <React.Fragment>
                            <Button title={'Edit Groups'} onClick={() => selectEvent(row)} />
                            {row.id != 0 && <Button title='Delete' onClick={() => deleteLimit(row)} />}
                        </React.Fragment>;
                    }} />
                </div>
            </Card>
        </div>
        <Card className='save-actions'>
            <div className='centrum-flex padding'><Button className='alignRight' icon={'save'} title='Save' onClick={() => save()} /></div>
        </Card>
    </Card >;
};

SportEventLimits.propTypes = {
    event: PropTypes.object,
    onClose: PropTypes.func
};
