import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../Icon/Icon';

export const TableHead = (props) => {

    const head = props.model.fields.map((field, index) => {
        return <th key={index} className={'column-'+field.display.toLowerCase()}>{field.display}</th>;
    });

    const allSelected = props.data.filter(x => !x.__selected).length == 0 && props.data.length > 0;
    return <thead>
        {props.options.draggable.enabled && <th className='table-option-draggable-header'></th>}
        {props.options.selectable && <th className='table-option-selectable-header'><Icon icon={allSelected ? 'check-square' : 'square'} type='far' onClick={() => props.onSelectAll()} /></th>}
        {head}
        {props.options.data && props.options.data.delete.enabled && <th className='table-option-delete-header'>
            {props.options.data.delete.header && <Icon icon='trash' onClick={() => props.onDeleteAll()} />}
        </th>}
        {props.buttons && <th className='table-option-buttons-header'></th>}
    </thead>;
};

TableHead.propTypes = {
    model: PropTypes.object,
    data: PropTypes.array,
    options: PropTypes.object,

    // rendering
    buttons: PropTypes.func,
    //

    onSelectAll: PropTypes.func,
    onDeleteAll: PropTypes.func
};