import './_search-events.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API, SportEndpoints } from '../../../../v2/Lib/Api/Api';
import { lang } from '../../../../v2/Lib/Common/language';
import { Button } from '../../../../v2/Components/Controls/Button/Button';


export const SearchEvent = (props) => {
    const [event, setEvent] = useState(null);
    const [events, setEvents] = useState([]);
    const [selectedEvent, selectEvent] = useState(null);
    const [searching, setSearching] = useState(false);


    const selectRef = React.createRef();

    useEffect(() => {
        props.onEventSelected(selectedEvent);
        if (selectedEvent) {
            selectRef.current.focus();
        }
    }, [selectedEvent]);

    return <div className='flex gap-10'>
        <div className='flex gap-5'>
            <label>{lang('Search Event')}</label>
            <div className='search-event'>
                <input type='text' className={searching ? 'disabled' : ''}
                    onChangeCapture={(e) => {
                        setEvent(e.target.value);
                    }} />
                <select ref={selectRef} onChangeCapture={(e) => {
                    var eS = events.find(x => x.id == parseFloat(e.target.value));
                    selectEvent(eS);
                }}>
                    {events?.map(x => <option value={x.id} key={x.id}>{new Date(x.date.indexOf('Z') > 0 ? x.date : x.date + 'Z').toLocaleString('en-Gb', { hours12: false })} {x.sport} {x.name}</option>)}
                </select>
            </div>
        </div>
        <Button title='Search' className={searching ? 'disabled' : ''} onClick={() => {
            if (!event) return;
            if (event.length < 3) return;
            if (searching) return;

            setSearching(true);
            var payload = {
                name: event,
                startDate: new Date()
            };

            if (props.sport) {
                payload.sportId = props.sport;
            }

            API.post(SportEndpoints.Events, payload).then((result) => {
                setSearching(false);
                if (result?.result && result.result.length > 0) selectEvent(result.result[0]);
                setEvents(result?.result);
            });
        }} />
    </div>;
};

SearchEvent.propTypes = {
    sport: PropTypes.number,
    onSearch: PropTypes.func,
    onEventSelected: PropTypes.func
};