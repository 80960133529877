/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../Controls/Buttons/Button';
import { API, CMSEndpoints } from '../../../v2/Lib/Api/Api';
import { lang } from '../../Centrum/Localization/lang';
import { Card } from '../../../v2/Components/Card/Card';
import { EditableTable } from '../../Controls/Table/Editable/EditableTable';
import { convertLSportIdToBetradarSportId } from './SetupMarketGroups';
import { application } from '../../Application/Application';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { Icon } from '../../../v2/Components/Icon/Icon';


export const defaultOverviews = {
    '1': {
        'name': 'soccer',
        'preMatch': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                },
                '7': {
                    'title': 'Double Chance',
                    'selectionTitle': '1X,12,X2',
                    'typeId': 7,
                    'weight': 2
                },
                '41': {
                    'title': '1st Half',
                    'selectionTitle': '1,x,2',
                    'typeId': 41,
                    'weight': 3
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2
                }
            },
            {
                '17': {
                    'title': 'Both Teams To Score',
                    'selectionTitle': 'Yes,No',
                    'weight': 1,
                    'typeId': 17
                },
                '5': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 5
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                },
                '7': {
                    'title': 'Double Chance',
                    'selectionTitle': '1X,12,X2',
                    'typeId': 7,
                    'weight': 2
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '17': {
                    'title': 'Both Teams To Score',
                    'selectionTitle': 'Yes,No',
                    'weight': 1,
                    'typeId': 17
                },
                '5': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 5
                }
            }
        ]
    },
    '2': {
        'name': 'basketball',
        'preMatch': [
            {
                '226': {
                    'title': 'Full Time',
                    'selectionTitle': '1,2',
                    'typeId': 226,
                    'weight': 1
                }
            },
            {
                '41': {
                    'title': '1st Half Winner',
                    'selectionTitle': '1,x,2',
                    'typeId': 41,
                    'weight': 3
                }
            },
            {
                '51': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 51
                }
            }
        ],
        'live': [
            {
                '226': {
                    'title': 'Moneyline',
                    'selectionTitle': 'Home Team,Away Team',
                    'displaySpecifierTitles': true,
                    'typeId': 226
                }
            },
            {
                '28': {
                    'title': 'Total Points (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 28,
                }
            },
            {
                '51': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 51
                }
            }
        ]
    },
    '3': {
        'name': 'baseball',
        'preMatch': [
            {
                '226': {
                    'title': 'Winner (Inc. Ovt)',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 226,
                    'weight': 1
                }
            },
            {
                '28': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 28,

                }
            },
            {
                '51': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 51
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Winner (Inc. Ovt)',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '256': {
                    'title': 'Handicap (Inc. Extra innings)',
                    'selectionTitle': 'Home Team,Away Team',
                    'displaySpecifierTitles': true,
                    'typeId': 256
                }
            }
        ]
    },
    '4': {
        'name': 'ice-hockey',
        'preMatch': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '17': {
                    'title': 'Both Teams to Score',
                    'selectionTitle': 'Yes,No',
                    'weight': 1,
                    'typeId': 17
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '51': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 51
                }
            }
        ]
    },
    '5': {
        'name': 'tennis',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player2',
                    'typeId': 52,
                    'weight': 1
                }
            },
            {
                '21': {
                    'title': 'Total 1st Set',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 21,

                }
            },
            {
                '166': {
                    'title': 'Total',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 166,

                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player2',
                    'typeId': 52,
                    'weight': 1
                }
            },
            {
                '166': {
                    'title': 'Total Points',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,ML',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 166,

                }
            },
            {
                '5': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 5
                }
            }
        ]
    },
    '6': {
        'name': 'handball',
        'preMatch': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '41': {
                    'title': '1st Set Winner',
                    'selectionTitle': '1,X,2',
                    'typeId': 1,
                    'weight': 1
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                },
                '7': {
                    'title': 'Double Chance',
                    'selectionTitle': '1X,12,X2',
                    'typeId': 7,
                    'weight': 2
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '41': {
                    'title': '1st Set Winner',
                    'selectionTitle': '1,X,2',
                    'typeId': 1,
                    'weight': 1
                }
            }
        ]
    },
    '7': {
        'name': 'floorball',
        'preMatch': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            }
        ]
    },
    '10': {
        'name': 'boxing',
        'preMatch': [
            {
                '52': {
                    'title': 'Full Time',
                    'selectionTitle': '1,2',
                    'typeId': 52
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Full Time',
                    'selectionTitle': '1,2',
                    'typeId': 52
                }
            }
        ]
    },
    '11': {
        'name': 'badminton',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player2',
                    'typeId': 52,
                    'weight': 1
                }
            },
            {
                '21': {
                    'title': 'Total 1st Set (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 21,

                }
            },
            {
                '166': {
                    'title': 'Total (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 166,

                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player2',
                    'typeId': 52,
                    'weight': 1
                }
            },
            {
                '166': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 166,

                }
            },
            {
                '5': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 5
                }
            }
        ]
    },
    '12': {
        'name': 'rugby',
        'preMatch': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '11': {
                    'title': 'Draw No Bet',
                    'selectionTitle': 'Home Team,Away Team',
                    'weight': 2,
                    'typeId': 11
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,2',
                    'typeId': 52
                }
            }
        ]
    },
    '15': {
        'name': 'bandy',
        'preMatch': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '5': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 5
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '5': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 5
                }
            }
        ]
    },
    '29': {
        'name': 'futsal',
        'preMatch': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '17': {
                    'title': 'Both Teams To Score',
                    'selectionTitle': 'Yes,No',
                    'weight': 1,
                    'typeId': 17
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '17': {
                    'title': 'Both Teams To Score',
                    'selectionTitle': 'Yes,No',
                    'weight': 1,
                    'typeId': 17
                }
            }
        ]
    },
    '34': {
        'name': 'beach volley',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 52,
                    'weight': 1
                },
                '300': {
                    'title': 'Set Winner',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 300,
                    'selections': [
                        '1',
                        '2'
                    ],
                    'weight': 2
                }
            },
            {
                '238': {
                    'title': 'Total Points',
                    'selectionTitle': 'Under,Over',
                    'mainGoalLine': true,
                    'typeId': 238,
                    'weight': 1
                },
                '41': {
                    'title': '1st Set Winner',
                    'selectionTitle': '1,X,2',
                    'typeId': 1,
                    'weight': 1
                },
                '196': {
                    'title': 'Exact Sets',
                    'selectionTitle': '5:34,5:35,5:36',
                    'typeId': 196,
                    'weight': 3
                }
            },
            {
                '41': {
                    'title': '1st Set Winner',
                    'selectionTitle': '1,X,2',
                    'typeId': 1,
                    'weight': 1
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 52
                }
            },
            {
                '238': {
                    'title': 'Total Points',
                    'selectionTitle': 'Under,Over',
                    'mainGoalLine': true,
                    'typeId': 238
                }
            },
            {
                '237': {
                    'title': 'Point Handicap',
                    'selectionTitle': 'Home Team,Away Team',
                    'mainGoalLine': true,
                    'typeId': 237
                }
            }
        ]
    },
    '389537': {
        'name': 'Australian Rules',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 52
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 52
                }
            }
        ]
    },
    '16': {
        'name': 'american football',
        'preMatch': [
            {
                '226': {
                    'title': 'Winner',
                    'selectionTitle': 'Home Away',
                    'typeId': 226
                }
            },
            {
                '28': {
                    'title': 'Total Including Over time',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 28,

                }
            },
            {
                '51': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 51
                }
            }
        ],
        'live': [
            {
                '226': {
                    'title': 'Full Time Score',
                    'selectionTitle': 'Home Away',
                    'typeId': 226
                }
            },
            {
                '28': {
                    'title': 'Total Including Over time',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 28,

                }
            },
            {
                '51': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 51
                }
            }
        ]
    },
    '17': {
        'name': 'cycling',
        'preMatch': [
            {
                '539': {
                    'title': 'Head To Head',
                    'selectionTitle': 'Competitor',
                    'displaySpecifierTitles': true,
                    'typeId': 52
                }
            }
        ],
        'live': [
            {
                '539': {
                    'title': 'Head To Head',
                    'selectionTitle': 'Competitor',
                    'displaySpecifierTitles': true,
                    'typeId': 52
                }
            }
        ]
    },
    '19': {
        'name': 'snooker',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player2',
                    'typeId': 52
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player2',
                    'typeId': 52
                }
            }
        ]
    },
    '20': {
        'name': 'table tennis',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player2',
                    'typeId': 52
                }
            },
            {
                '202': {
                    'title': '1st Set Winner',
                    'selectionTitle': '1,2',
                    'typeId': 202,
                    'weight': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player2',
                    'typeId': 52
                }
            },
            {
                '202': {
                    'title': '1st Set Winner',
                    'selectionTitle': '1,2',
                    'typeId': 202,
                    'weight': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            }
        ]
    },
    '21': {
        'name': 'cricket',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner (incl. super over)',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 52,
                    'weight': 1
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Winner (incl. super over)',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 1,
                    'weight': 1
                }
            }
        ]
    },
    '22': {
        'name': 'dart',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player 2',
                    'typeId': 52
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Player 1,Player 2',
                    'typeId': 52
                }
            }
        ]
    },
    '26': {
        'name': 'water-polo',
        'preMatch': [
            {
                '2': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 2,
                    'weight': 1
                }
            }
        ],
        'live': [
            {
                '2': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 2,
                    'weight': 1
                }
            }
        ]
    },
    '27': {
        'name': 'gaelic',
        'preMatch': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            }
        ],
        'live': [
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            }
        ]
    },
    '28': {
        'name': 'curling',
        'preMatch': [
            {
                '226': {
                    'title': 'Winner',
                    'selectionTitle': '1,2',
                    'typeId': 226
                }
            }
        ],
        'live': [
            {
                '226': {
                    'title': 'Winner',
                    'selectionTitle': '1,2',
                    'typeId': 226
                }
            }
        ]
    },
    '31': {
        'name': 'badminton',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': '1,2',
                    'typeId': 52
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': '1,2',
                    'typeId': 52
                }
            }
        ]
    },
    '40': {
        'name': 'formula 1',
        'preMatch': [
            {
                '52': {
                    'title': 'Head To Head',
                    'selectionTitle': 'Competitor',
                    'displaySpecifierTitles': true,
                    'typeId': 52
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Head To Head',
                    'selectionTitle': 'Competitor',
                    'displaySpecifierTitles': true,
                    'typeId': 52
                }
            }
        ]
    },
    '71': {
        'name': 'padel',
        'preMatch': [
            {
                '52': {
                    'title': 'Full Time',
                    'selectionTitle': '1,2',
                    'typeId': 52,
                    'weight': 1
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Full Time',
                    'selectionTitle': '1,2',
                    'typeId': 52,
                    'weight': 1
                }
            }
        ]
    },
    '190': {
        'name': 'motorcycle racing',
        'preMatch': [
            {
                '539': {
                    'title': 'Head To Head',
                    'selectionTitle': 'Competitor',
                    'displaySpecifierTitles': true,
                    'typeId': 539
                }
            }
        ],
        'live': [
            {
                '539': {
                    'title': 'Head To Head',
                    'selectionTitle': 'Competitor',
                    'displaySpecifierTitles': true,
                    'typeId': 539
                }
            }
        ]
    },
    '191': {
        'name': 'motor sport',
        'preMatch': [
            {
                '559': {
                    'title': 'Head To Head',
                    'selectionTitle': 'Competitor',
                    'displaySpecifierTitles': true,
                    'typeId': 559
                }
            }
        ],
        'live': [
            {
                '559': {
                    'title': 'Head To Head',
                    'selectionTitle': 'Competitor',
                    'displaySpecifierTitles': true,
                    'typeId': 559
                }
            }
        ]
    },
    '117': {
        'name': 'mma',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': '1,x,2',
                    'typeId': 52,
                    'weight': 1
                },
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                },
                '911': {
                    'title': 'Will the fight goto distance',
                    'selectionTitle': 'Yes,No',
                    'typeId': 911,
                    'weight': 3
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': '1,x,2',
                    'typeId': 52
                }
            }
        ]
    },
    '23': {
        'name': 'voleyball',
        'preMatch': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 52,
                    'weight': 1
                },
                '300': {
                    'title': 'Set Winner',
                    'selectionTitle': 'Home Team,AwayTeam',
                    'typeId': 300,
                    'selections': [
                        '1',
                        '2'
                    ],
                    'weight': 2
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '5': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 5
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Winner',
                    'selectionTitle': 'Home Team,Away Team',
                    'typeId': 52,
                    'weight': 1
                }
            },
            {
                '2': {
                    'title': 'Total Goals (ML)',
                    'mainGoalLine': true,
                    'selectionTitle': 'Under,Over,GL',
                    'selections': [
                        'Under',
                        'Over'
                    ],
                    'typeId': 2,

                }
            },
            {
                '5': {
                    'title': 'Odd / Even',
                    'selectionTitle': 'Odd,Even',
                    'weight': 3,
                    'typeId': 5
                }
            }
        ]
    },
    '107': {
        'name': 'e-games',
        'preMatch': [
            {
                '52': {
                    'title': 'Full Time Without Draw',
                    'selectionTitle': '1,2',
                    'typeId': 52,
                    'weight': 1
                }
            },
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            }
        ],
        'live': [
            {
                '52': {
                    'title': 'Full Time Without Draw',
                    'selectionTitle': '1,2',
                    'typeId': 52,
                    'weight': 1
                }
            },
            {
                '1': {
                    'title': 'Full Time',
                    'selectionTitle': '1,x,2',
                    'typeId': 1,
                    'weight': 1
                }
            }
        ]
    }
};


const MarketSelector = (props) => {
    const marketRef = React.createRef();
    const addMarket = () => {
        if (!marketRef.current) return;
        var value = parseInt(marketRef.current.value);
        props.onAdd(props.markets.find(x => x.id == value));
    };

    return <div className='flex gap-5 stretch'>
        <span>{lang('Market')}</span>
        <select ref={marketRef}>
            {props.markets.map(x => {
                return <option value={x.id} key={x.id}>{x.name} {x.id}</option>;
            })}
        </select>
        <Button onClick={() => addMarket()}><span className='no-wrap'>{lang('Add Market')}</span></Button>
    </div>;

};

MarketSelector.propTypes = {
    markets: PropTypes.array,
    onAdd: PropTypes.func
};

const ConfigureMarket = (props) => {
    const [data, setData] = useState(props.data);

    const change = (target, value) => {
        var copy = { ...data };
        copy[target] = value;
        setData(copy);
    };

    return <ConfirmationModal title='Configuration' onConfirm={() => {
        props.onSave(data);
    }}>
        <div className='padding flex vertical gap-10'>
            <div className='flex gap-5'>
                <label>{lang('Title')}</label>
                <input defaultValue={data.title} onChange={(e) => change('title', e.target.value)} />
            </div>
            <div className='flex gap-5'>
                <label>{lang('Selection Titles')}</label>
                <input defaultValue={data.selectionTitle} onChange={(e) => change('selectionTitle', e.target.value)} />
            </div>
            <div className='flex gap-5' onClick={() => {
                change('mainGoalLine', !data.mainGoalLine);
            }}>
                <label>{lang('Main Line')}</label>
                <Icon icon={data.mainGoalLine ? 'check-circle' : 'circle'} type='far'/>
            </div>
        </div>
    </ConfirmationModal>;
};

ConfigureMarket.propTypes = {
    data: PropTypes.object,
    onSave: PropTypes.func
};

export const SetupOverviewMarkets = (props) => {
    const [category, selectCategory] = useState(null);
    const [markets, setMarkets] = useState([]);
    const [selectedMarkets, setSelectedMarkets] = useState(null);
    const [overViewMarkets, setOverviewMarkets] = useState({});
    const [updateKey, setUpdateKey] = useState(0);

    useEffect(() => {
        API.post(CMSEndpoints.GetOverviewMarkets, {}).then((result) => {
            var converted = {};
            try {
                converted = JSON.parse(result.result);
            } catch (err) {
                converted = { ...defaultOverviews };
            }
            if (!converted) {
                converted = { ...defaultOverviews };
            }

            setOverviewMarkets(converted);

            API.post(`${CMSEndpoints.GetSportMarkets}/${window.user.features.feedType}`, {}).then((result) => {
                var csv = result.result;
                var data = csv.split('\n');
                var converted = data.map(x => {
                    var p = x.split(',');
                    if (p[8] != 'True') return null;
                    return {
                        sport: p[0],
                        sportName: p[1],
                        id: p[2],
                        name: p[3],
                        selections: p[4],
                        inPlaySettlements: p[9] == 'True'
                    };
                });
                converted = converted.filter(x => x != null);
                setMarkets(converted);
                var categories = props.categories.filter(x => x.enabled);
                selectCategory(categories[0]);
            });
        });
    }, []);

    useEffect(() => {
        if (!category) return;
        var categoryId = category.id.toString();
        var sportMarkets = markets.filter(x => {
            return x.sport == categoryId;
        });
        setSelectedMarkets(sportMarkets);
    }, [category]);


    const save = () => {

    };

    const configureMarket = (x, target, row) => {
        var market = selectedMarkets.find(x => x.id == row.typeId);
        console.log(market);
        application().modal.open(
            <ConfigureMarket data={row} market={market} onSave={(data) => {
                console.log(data);
            }} />
        );
    };

    const addMarket = (x, target, market) => {
        var copy = { ...overViewMarkets };
        var column = copy[selectedCategoryId][target][x];
        var weight = Object.keys(column).length + 1;
        column[market.id] = {
            title: market.name,
            mainGoalLine: false,
            selectionTitle: null,
            selections: null,
            typeId: market.id,
            specifierOptions: null,
            weight: weight
        };

        setOverviewMarkets(copy);
        setUpdateKey(Date.now());
    };

    const deleteMarket = (x, target, row) => {
        var copy = { ...overViewMarkets };
        var column = copy[selectedCategoryId][target][x];
        delete column[row.typeId];
        setOverviewMarkets(copy);
        setUpdateKey(Date.now());
    };


    const orderColumn = (x, data) => {
        var column = [];
        data.map((x, index) => {
            x.weight = index + 1;
            column.push(x);
        });

        var copy = { ...overViewMarkets };
        copy[selectedCategoryId].preMatch[x] = column;

        setOverviewMarkets(copy);
        setUpdateKey(Date.now());
    };


    if (!category) return <div></div>;
    if (!selectedMarkets) return <div></div>;
    const selectedCategoryId = convertLSportIdToBetradarSportId(parseInt(category.id));
    var data = overViewMarkets[convertLSportIdToBetradarSportId(selectedCategoryId)];

    var groupModel = {
        fields: [
            {
                name: 'id',
                display: 'Market',
                render: (row, context) => {
                    if (!context) return;
                    var market = selectedMarkets.find(x => x.id == row.typeId);
                    return <div>{market?.name}</div>;
                }
            },
            {
                name: 'title',
                display: 'Title',
                render: (row, context) => {
                    if (!context) return;
                    return row.title;
                }
            },
            {
                name: 'selectionTitle',
                display: 'Selection Titles',
                render: (row, context) => {
                    if (!context) return;
                    console.log(row);
                    return row.selectionTitle;
                }
            },
            {
                name: 'selections',
                display: 'Selections',
                render: (row, context) => {
                    if (!context) return;
                    return row.selections ? row.selections.join(',') : null;
                }
            },
            {
                name: 'specifiers',
                display: 'Line',
                render: (row, context) => {
                    if (!context) return;
                    return row.selections ? row.selections.join(',') : null;
                }
            },
            {
                name: 'mainLine',
                display: 'Main Line',
                render: (row, context) => {
                    if (!context) return;
                    return row.mainGoalLine ? 'yes' : null;
                }
            }
        ]
    };

    const columns = [0, 1, 2];

    return <Card title='Change Overview Markets' menu={<Button title='Save' onClick={() => save()} />}>
        <div className='flex vertical gap-10'>
            <Card>
                <div className='padding flex'>
                    <div className='flex vertical'>
                        <label>{lang('Sports')}</label>
                        <select defaultValue={category.id} onChangeCapture={(e) => {
                            selectCategory(props.categories.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {props.categories.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                    </div>
                </div>
            </Card>
            <div className='flex gap-10'>
                <Card title='Prematch' className={'flex align-start vertical'}>
                    <div className='flex vertical gap-5 stretch'>
                        {columns.map(x => {
                            return <Card title={`Column ${x + 1}`} key={x}>
                                <MarketSelector markets={selectedMarkets} onAdd={(market) => {
                                    addMarket(x, 'preMatch', market);
                                }} />
                                <EditableTable key={updateKey} model={groupModel} data={data.preMatch[x] ? Object.values(data.preMatch[x]).sort((a, b) => a.weight > b.weight ? 1 : -1) : []} context={{ column: x }} draggable={true} buttons={(row) => {
                                    return <div className='flex gap-5'>
                                        <Button icon='cogs' onClick={() => {
                                            configureMarket(x, 'preMatch', row);
                                        }} />
                                        <Button icon='times' onClick={() => {
                                            deleteMarket(x, 'preMatch', row);
                                        }} />
                                    </div>;
                                }} onDataChange={(data) => {
                                    orderColumn(x, data);
                                }} />
                            </Card>;
                        })}
                    </div>
                </Card>
                <Card title='Live' className={'flex align-start vertical'}>
                    <div className='flex vertical gap-5 stretch'>
                        {columns.map(x => {
                            return <Card title={`Column ${x + 1}`} key={x}>
                                <MarketSelector markets={selectedMarkets} onAdd={(market) => {
                                    addMarket(x, 'live', market);
                                }} />
                                <EditableTable key={updateKey} model={groupModel} data={data.live[x] ? Object.values(data.live[x]).sort((a, b) => a.weight > b.weight ? 1 : -1) : []} context={{ column: x }} draggable={true} buttons={(row) => {
                                    return <Button title='delete' onClick={() => {
                                        deleteMarket(x, 'live', row);
                                    }} />;
                                }} onDataChange={(data) => {
                                    orderColumn(x, data);
                                }} />
                            </Card>;
                        })}
                    </div>
                </Card>
            </div>
        </div>
    </Card >;
};


SetupOverviewMarkets.propTypes = {
    categories: PropTypes.array
};