import React from 'react';

import { application, Endpoints } from '../../../Application/Application';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';
import { ConfirmWithNote } from '../../Players/actions';


export const bulkRemovePromotion = (items, handler) => {
    var form = null;
    var players =[];
    items.map(x=>{
        players.push(x.PlayerId);
    });
    application().modal.open(<ConfirmationModal title={'Bulk remove promotion'} onConfirm={() => {
        var data = form.submit();

        application().resolve(null, Endpoints.BulkRemovePromotion, {
            players: players,
            notes: data.Notes
        }, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    }}>
        <ConfirmWithNote context={(c) => form = c} />
    </ConfirmationModal >);
};