import './_riskLevelConfiguration.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../../../v2/Components/Card/Card';
import { Icon } from '../../../../v2/Components/Icon/Icon';
import { lang } from '../../../Centrum/Localization/lang';
import { Tab } from '../../../Centrum/Tab/Tab';
import { RiskLevelLimitsConfiguration } from './RiskLevelLimitsConfiguration';
import { RiskLevelSports } from './RiskLevelsSports';
import { RiskLevelLeagues } from './RiskLevelLeagues';
import { API, SportEndpoints } from '../../../../v2/Lib/Api/Api';
import { RiskLevelMarkets } from './RiskLevelMarkets';



export const convertToLevels = (level) => {
    var levels = {

    };
    levels['00'] = 'Low';
    levels['01'] = 'Low2';
    levels['02'] = 'Medium';
    levels['03'] = 'Medium2';
    levels['04'] = 'High';
    levels['05'] = 'High2';
    levels['06'] = 'High3';
    levels['07'] = 'High4';

    var result = [];
    for (var i = 0; i < level.length ; i += 2) {
        result.push(levels[level.substr(i, 2)]);
    }

    return result;
};


export const SportRiskLevels = {
    'Low': 'Low',
    'Medium': 'Medium',
    'High': 'High',
};

export const LeagueLevels = {
    'Low': 'Low',
    'Low2': 'Low 2',
    'Medium': 'Medium',
    'Medium2': 'Medium2',
    'High': 'High',
    'High2': 'High2',
    'High3': 'High3',
    'High4': 'High4'
};

export const MarketLevels = {
    'Low': 'Low',
    'Medium': 'Medium',
    'High': 'High'
};

export const PlayerLevels = {
    'Low': 'Low',
    'Medium': 'Medium',
    'High': 'High',
};

export const RiskLevels = {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
};


export const RiskLevelsConfiguration = () => {
    const [sports, setSports] = useState(null);

    const user = useSelector((state) => state.user).loginInformation;
    const enabled = user.Configuration == undefined;
    const ref = React.createRef();
    useEffect(() => {
        try {
            var appContent = ref.current.ref.current.parentNode;
            appContent.style.display = 'flex';
            appContent.style.flexDirection = 'column';
            appContent.style.overflow = 'hidden';
            appContent.style.height = '100%';

            const tab = ref.current.ref.current;
            tab.style.overflow = 'hidden';

            const content = tab.getElementsByClassName('tab-content')[0];
            content.style.display = 'flex';
            content.style.flexDirection = 'column';
            content.style.flex = '1';
            content.style.height = '100%';
            content.style.alignSelf = 'stretch';

        } catch (err) {
            //
            console.log(err);
        }

        API.post(SportEndpoints.Categories).then((result) => {
            setSports(result.result);
        });
    }, []);


    if (!enabled) {
        return <Card title='Risk Levels'>
            <div className='flex padding gap-5'>
                <Icon icon='info-circle' /><p>{lang('Risk configuration is not enabled')}</p>
            </div>
        </Card>;
    }

    let tabButtons = [
        {
            name: 'limits',
            title: 'Limits'
        },
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'league',
            title: 'League'
        },
        {
            name: 'bet',
            title: 'Bet Types'
        }
    ];

    return <Tab buttons={tabButtons} ref={ref}>
        <RiskLevelLimitsConfiguration />
        {sports && <RiskLevelSports sports={sports} />}
        {sports && <RiskLevelLeagues sports={sports} />}
        {sports && <RiskLevelMarkets sports={sports} />}
    </Tab>;

};