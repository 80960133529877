import './_cashBonus.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { BonusGeneralSettings } from '../Components/BonusComponents';
import { application, Endpoints } from '../../../Application/Application';

export class CashBonus extends React.Component {
    constructor(props) {
        super(props);
        var state = Object.assign({}, props);
        state.multiplers = {};
        for (var i = 0; i < 100; i++) {
            state.multiplers[i] = 'x' + i;
        }

        this.state = state;
        this.state.promotion.Configuration = (typeof this.state.promotion.Configuration === 'object' && this.state.promotion.Configuration !== null) ? this.state.promotion.Configuration : JSON.parse(this.state.promotion.Configuration);


        if (!this.state.promotion.Configuration) {
            this.state.promotion.Configuration = {
                payout: null,
                turnover: null,
                deposit: {},
                sport: {
                    enabled: false,
                }
            };
        }
    }


    componentDidMount() {
        application().resolve(null, Endpoints.GetPromotionPlayerGroups + '/' + (this.state.promotion.Id), {}, 'fetching promotion player groups', 'unable to fetch promotion player groups').then((groups) => {
            var converted = [];
            converted.push({ value: null, display: 'All' });
            groups.result.map(x => {
                converted.push({ display: x.name, value: x.id });
            });

            this.setState({ loaded: true, playerGroups: converted });
        });

    }


    save() {
        var promotion = this.generalSettings.save();
        var result = Object.assign(promotion, {});

        var playerSettings = this.playerSettings ? this.playerSettings.save() : {};
        var codeSettings = this.codeSettings ? this.codeSettings.save() : {};


        result = Object.assign(playerSettings, result);
        result = Object.assign(codeSettings, result);
        result.Configuration.deposit = this.depositSettings ? this.depositSettings.save() : {};
        result.Configuration.stake = this.stakeSettings ? this.stakeSettings.save() : {};
        result.Configuration.sport = this.sportSettings ? this.sportSettings.save() : {};
        result.Configuration.turnover = this.turnoverSettings ? this.turnoverSettings.save() : {};
        result.Configuration.payout = this.payoutSettings ? this.payoutSettings.save() : {};

        result.Configuration = JSON.stringify(result.Configuration);

        return result;
    }

    render() {
        if (!this.state.loaded) return <div />;
        return <React.Fragment>
            <BonusGeneralSettings promotion={this.state.promotion} ref={(r) => this.generalSettings = r} cash={true} />
        </React.Fragment >;
    }
}

CashBonus.propTypes = {
    promotion: PropTypes.object
};

