import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { apiResult, application, Endpoints } from '../../Application/Application';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { currency, lang } from '../../Centrum/Localization/lang';
import { Form } from '../../Controls/Form/Form';
import { FormInput } from '../../Controls/Form/FormInput';
import { Icon } from '../../Controls/Icon/Icon';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';
import { PlayerBonusComplete } from './PlayerBonusComplete';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes } from '../../Centrum/_models/ModelEnums';
import { Search } from '../../Centrum/Search/Search';
import { HorseTicket } from '../../../v2/Components/Sports/Tickets/HorseTicket/HorseTicket';
import { ActionTypes, getAction } from '../../Centrum/Common/ActionManager';
import { SportTicketDetails } from '../../Sports/SportTicketDetails';
import { API, AffiliateEndpoints, DataEndpoints } from '../../../v2/Lib/Api/Api';


const searchBonusTickets = {
    fields: [
        {
            name: 'Type',
            display: 'Betting Type',
            acceptEmptyValues: true,
            type: InputTypes.select,
            values: SportsBetTypes,
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketStatus
        }
    ]
};



const betSlipResults = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id',
            formatter: (val, row) => {
                row.Product = 0;
                return openBetsOfTicket(val, row);
            }
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Updated',
            display: 'Update',
            type: InputTypes.dateTime
        },
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes

        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes

        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketStatus
        },
        {
            name: 'Selections',
            display: 'Bet / Win'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'Bet',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (val && Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency);
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Odds',
            display: 'Odds',
            type: InputTypes.number
        },
        {
            name: 'PossibleWin',
            display: 'PossibleWin',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (val && Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency);
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'TicketBonusPercentage',
            display: 'Bonus %',
            type: InputTypes.number
        },
        {
            name: 'TicketBonusAmount',
            display: 'Bonus Amount',
            type: InputTypes.number
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (val && Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency);
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Bonus',
            type: InputTypes.bool
        }
    ],
    onRenderRow: (rowObject) => {
        if (!rowObject.data) return rowObject;
        console.log(rowObject.data.Status);
        if (rowObject.data.Status == 2) {
            rowObject.className = 'row-lost';
        }
        if (rowObject.data.Status == 1) {
            rowObject.className = 'row-win';
        }
        if (rowObject.data.Status == 3) {
            rowObject.className = 'row-cashout';
        }

        return rowObject;
    }
};

const BonusTickets = (props) => {
    const [bonusTickets, setData] = useState({ result: [] });
    return <Search
        model={searchBonusTickets}
        resultModel={betSlipResults}
        exportToExcel={true}
        searchOnStart={true}
        data={bonusTickets}
        onSubmit={(data, qb) => {
            qb.fields.push({ name: 'ConsumedPromotionId', value: props.consumedPromotionId, exact: true, and: true });
            return apiResult('', Endpoints.SearchBonusTickets, qb).then((result) => {
                setData(result);
            });
        }}
    />;
};


BonusTickets.propTypes = {
    consumedPromotionId: PropTypes.number
};

const BonusDeposit = (props) => {
    const [promotions, setPromotions] = useState(null);
    const [selectedBonus, selectBonus] = useState(null);
    const [multiplier, setMultiplier] = useState(0);
    const [deductBonusAmount, setDeductBonusAmount] = useState(0);
    var form = null;

    useEffect(() => {
        application().resolve(null, Endpoints.ListPromotions, {}).then((result) => {
            var filtered = null;
            if (props.data.bonusType) {
                filtered = result.result.filter(x => x.OperatorId == props.data.operatorId && x.Type == props.data.bonusType);
                setPromotions(filtered);
            } else {
                filtered = result.result.filter(x => x.OperatorId == props.data.operatorId && x.Type != 7);
                setPromotions(filtered);
            }
        });
    }, []);

    useEffect(() => {
        if (!selectedBonus) return;
        const promotion = promotions.find(x => x.Id == selectedBonus);
        var configuration = JSON.parse(promotion.Configuration);

        if (configuration.turnover && configuration.turnover.multiplier) {
            try {
                const multiplier = parseFloat(configuration.turnover.multiplier);
                setMultiplier(multiplier);
            } catch (err) {
                setMultiplier(0);
            }
        }
        if (configuration.turnover && configuration.turnover.deductDepositAmount) {
            try {
                setDeductBonusAmount(configuration.turnover.deductDepositAmount);
            } catch (err) {
                setDeductBonusAmount(false);
            }
        }
    }, [selectedBonus]);

    if (!promotions) return <div>{lang('Please wait')}</div>;
    var availableBonus = {};

    promotions.map(x => {
        if (x.Type == 6 || x.Type == 99) {
            return;
        }

        if (x.OperatorId !== props.data.operatorId) return;
        availableBonus[x.Id] = x.Name;
    });


    props.context({
        submit: () => {
            var formData = form.submit();
            formData.TurnoverMultiplier = multiplier;
            formData.DeductBonusAmount = deductBonusAmount;
            return formData;
        }
    });

    return <Form ref={(r) => form = r} className='tab-next'>
        <div className='row'>
            <FormInput title='Amount' name='Amount' type={InputTypes.number} />
        </div>
        <div className='centrum-flex vertical margin-top'>
            <span className='medium'><b>{lang('Bonus transactions will not be deducted from Retails balance.')}</b></span>
            {
                selectedBonus && <div className='centrum-flex'>
                    <span className='medium'>{promotions.find(x => x.Id == selectedBonus).BonusWallet ? lang('Bonus wallet will be used') : ''}</span>
                </div>
            }
        </div>
        {
            <div className='row'>
                <FormInput type={InputTypes.select} values={availableBonus} name='Promotion' onChange={(data, field, value) => {
                    selectBonus(parseFloat(value));
                }} />
            </div>
        }
        {
            <div className='row'>
                <div className='form-group number'>
                    <label onClick={() => {
                        setDeductBonusAmount(!deductBonusAmount);
                    }} >{lang('Deduct Bonus Amount')} <Icon icon={deductBonusAmount ? 'check-circle' : 'circle'} type='fa-light' /></label>
                </div>
            </div>
        }
        {multiplier > 0 && <div className='row'>
            <div className='form-group number'>
                <label>{lang('Turnover Multiplier')}</label>
                <input type='number' defaultValue={multiplier} onChange={(e) => {
                    var converted = parseFloat(e.target.value);
                    if (isNaN(converted)) {
                        setMultiplier(0);
                        return;
                    }
                    setMultiplier(converted);
                }} />
            </div>
        </div>}
        {selectedBonus && <div className='row'>
            <span>{promotions.find(x => x.Id == selectedBonus).Description}</span>
        </div>}
        <div className='row margin-top'>
            <FormInput title='Notes' name='Notes' type={InputTypes.textArea} onKeyDown={(a, field, value, e) => {
                if (e.key === 'Enter' && props.onSubmit) {
                    props.onSubmit();
                }
            }} />
        </div>
    </Form>;
};

BonusDeposit.propTypes = {
    data: PropTypes.object,
    onSubmit: PropTypes.func,
    context: PropTypes.func
};

const DepositPlayer = (props) => {
    const bonus = props.data.bonus;
    var form = null;

    props.context({
        submit: () => {
            return form.submit();
        }
    });
    return <Form ref={(r) => form = r} className='tab-next'>
        <div className='row'>
            <FormInput title='Sources' name='Data' type={InputTypes.select} values={props.sources} />
        </div>
        <div className='row'>
            <FormInput title='Amount' name='Amount' type={InputTypes.number} />
        </div>
        <div className='centrum-flex vertical margin-top'>
            {bonus &&
                <span className='medium'><b>{lang('Bonus transactions will not be deducted from Retails balance.')}</b></span>
            }
        </div>
        <div className='row margin-top'>
            <FormInput title='Notes' name='Notes' type={InputTypes.textArea} onKeyDown={(a, field, value, e) => {
                if (e.key === 'Enter' && props.onSubmit) {
                    props.onSubmit();
                }
            }} />
        </div>
    </Form>;
};

DepositPlayer.propTypes = {
    data: PropTypes.object,
    sources: PropTypes.object,
    onSubmit: PropTypes.func,
    context: PropTypes.func
};


const WithdrawPlayer = (props) => {
    const bonus = props.data.bonus;
    var form = null;
    var a = null;


    props.context({
        submit: () => {
            return form.submit();
        }
    });
    return <Form ref={(r) => form = r} data={{
        Amount: 0
    }} className='tab-next'>
        <div className='row'>
            <FormInput title='Sources' name='Data' type={InputTypes.select} values={props.sources} />
        </div>
        <div className='row'>
            <FormInput title='Amount' name='Amount' type={InputTypes.number} value={props.data.balance} ref={(r) => a = r}><a className='small alignRight clickable' onClick={() => a.setValue(props.data.balance)}>{lang('Withdraw Max %1', [props.data.balance])}</a></FormInput>
        </div>
        <div className='centrum-flex vertical margin-top'>
            {bonus &&
                <span className='medium'><b>{lang('Bonus transactions will not be deducted from Retails balance.')}</b></span>
            }
        </div>
        <div className='row margin-top'>
            <FormInput title='Notes' name='Notes' type={InputTypes.textArea} onKeyDown={(a, field, value, e) => {
                if (e.key === 'Enter' && props.onSubmit) {
                    props.onSubmit();
                }
            }} />
        </div>
    </Form>;
};

WithdrawPlayer.propTypes = {
    data: PropTypes.object,
    sources: PropTypes.object,
    onSubmit: PropTypes.func,
    context: PropTypes.func
};




export const ConfirmWithNote = (props) => {
    var form = null;

    props.context({
        submit: () => {
            return form.submit();
        }
    });
    return <Form ref={(r) => form = r}>
        <div className='row margin-top'>
            <FormInput title='Notes' name='Notes' type={InputTypes.textArea} />
        </div>
    </Form>;
};

ConfirmWithNote.propTypes = {
    context: PropTypes.func
};


const PlayerNotification = (props) => {
    var form = null;

    props.context({
        submit: () => {
            return form.submit();
        }
    });
    return <Form ref={(r) => form = r}>
        <div className='row'>
            <FormInput title='Title' name='title' />
        </div>
        <div className='row margin-top'>
            <FormInput title='Message' name='message' type={InputTypes.textArea} />
        </div>
    </Form>;
};

PlayerNotification.propTypes = {
    data: PropTypes.object,
    context: PropTypes.func
};

export const sendMessage = (data, handler) => {
    var form = null;

    application().modal.open(<ConfirmationModal title={'Notify player'} onConfirm={() => {
        var notifyData = form.submit();
        notifyData.PlayerId = data.playerId;
        if (!notifyData.title) {
            application().modal.open(<Dialog title='Error'><span>{lang('Please write a title')}</span></Dialog>);
            return;
        }

        if (!notifyData.message) {
            application().modal.open(<Dialog title='Error'><span>{lang('Please write a message')}</span></Dialog>);
            return;
        }

        application().resolve(null, Endpoints.NotifyPlayer, notifyData, 'please wait', 'unable to notify player').then((result) => {
            if (handler) {
                handler(result.result);
            } else {
                application().snackbar('player notification sent');
            }
        });
    }}>
        <PlayerNotification data={data} context={(c) => form = c} />
    </ConfirmationModal >);
};


export const bonusPlayer = (data, handler) => {
    var form = null;
    const confirmed = () => {
        var transactionData = form.submit();
        transactionData.PlayerId = data.playerId;
        transactionData.TransactionType = data.transactionType;
        transactionData.TurnoverMultiplier = transactionData.TurnoverMultiplier ?? 0;
        transactionData.Currency = data.currency;
        transactionData.Promotion = parseInt(transactionData.Promotion);
        if (isNaN(transactionData.Promotion)) {
            application().modal.open(<Dialog title='Error'><span>{lang('Please select a promotion')}</span></Dialog>);
            return;
        }

        var payload = {
            BonusId: transactionData.Promotion,
            Notes: transactionData.Notes,
            Amount: transactionData.Amount,
            TurnoverMultiplier: transactionData.TurnoverMultiplier ?? 0,
            DeductBonusAmount: transactionData.DeductBonusAmount ?? false,
            PlayerId: data.playerId
        };

        application().resolve(null, Endpoints.CreateBonusTransaction, payload, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    };
    application().modal.open(<ConfirmationModal title={'Deposit'} onConfirm={confirmed}>
        <BonusDeposit data={data} context={(c) => form = c} onSubmit={() => {
            confirmed();
        }} />
    </ConfirmationModal >);
};




export const depositPlayer = (data, handler) => {
    var form = null;
    const confirmed = () => {
        var transactionData = form.submit();
        transactionData.PlayerId = data.playerId;
        transactionData.TransactionType = data.transactionType;
        transactionData.Currency = data.currency;
        transactionData.Bonus = data.bonus;

        application().resolve(null, Endpoints.CreateTransaction, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });

    };
    application().resolve(null, `${Endpoints.GetCustomData}/transactionSources`, null, 'please wait', 'unable to fetch sources').then((result) => {
        var sources = {};
        if (!result.result) result.result = '';
        result.result.split('|').map(x => { sources[x] = x; });
        application().modal.open(<ConfirmationModal title={data.transactionType == 0 ? 'Deposit' : 'Withdraw'} onConfirm={confirmed}>
            <DepositPlayer data={data} sources={sources} context={(c) => form = c} onSubmit={() => {
                confirmed();
            }} />
        </ConfirmationModal >);
    });
};


export const withdrawPlayer = (data, handler) => {
    var form = null;
    const confirmed = () => {
        var transactionData = form.submit();
        transactionData.PlayerId = data.playerId;
        transactionData.TransactionType = data.transactionType;
        transactionData.Currency = data.currency;
        transactionData.Bonus = data.bonus;

        application().resolve(null, Endpoints.CreateTransaction, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    };
    application().resolve(null, `${Endpoints.GetCustomData}/transactionSources`, null, 'please wait', 'unable to fetch sources').then((result) => {
        var sources = {};
        if (!result.result) result.result = '';
        result.result.split('|').map(x => { sources[x] = x; });
        application().modal.open(<ConfirmationModal title={'Withdraw'} onConfirm={confirmed}>
            <WithdrawPlayer data={data} sources={sources} context={(c) => form = c} onSubmit={() => {
                confirmed();
            }} />
        </ConfirmationModal >);
    });
};



export const cancelTransaction = (data, handler, onCancelHandler) => {
    var form = null;

    application().modal.open(<ConfirmationModal title='Cancel Transactions'
        onCancel={() => {
            if (onCancelHandler) onCancelHandler();
        }}
        onConfirm={() => {
            var transactionData = form.submit();
            transactionData.id = data.id;

            application().resolve(null, Endpoints.CancelTransction, transactionData, 'please wait', 'unable to create transaction').then((result) => {
                handler(result.result);
            });
        }}>
        <div className='padding'>
            <Form ref={(r) => form = r}>
                <div className='row'>
                    <FormInput title='Notes' name='Notes' type={InputTypes.textArea} />
                </div>
            </Form>
        </div>
    </ConfirmationModal>);
};



export const movePlayerToRetail = (player, handler) => {
    var form = null;

    var retails = {};
    application().resolve(null, Endpoints.MyRetails, {}, 'please wait', 'unable to fetch players').then((result) => {
        result.result.map(retail => {
            retails[retail.Id] = retail.Code + ' ' + retail.Name;
        });
        application().modal.open(<ConfirmationModal title='Move Player to Retail' onConfirm={() => {
            var data = form.submit();
            data.playerId = player.Id;

            application().resolve(null, `${Endpoints.MovePlayerToRetail}/${player.Id}/${data.RetailId}/true`, null, 'please wait', 'unable to move player').then((result) => {
                application().snackbar.open('Player moved');
                handler(result.result);
            });
        }}>
            <div className='padding'>
                {player.Balance > 0 && <div className='group'>
                    <div className='margin-top'>
                        <Icon icon='info-circle' size='1x' /><span>{lang('Player account is not empty, %1 will be withdrawn from player balance.', [currency(player.Balance, 10000, player.Currency)])}</span>
                    </div>
                </div>
                }
                <Form ref={(r) => form = r}>
                    <div className='row'>
                        <FormInput title='Retails' name='RetailId' type={InputTypes.select} values={retails} />
                    </div>
                </Form>
            </div>
        </ConfirmationModal>);
    });
};


export const removePlayerFromRetail = (player, handler) => {
    application().modal.open(<ConfirmationModal title='Move Player to Retail' onConfirm={() => {

        application().resolve(null, `${Endpoints.RemoveMovePlayerFromRetail}/${player.Id}`, null, 'please wait', 'unable to move player').then((result) => {
            application().snackbar.open('Player moved');
            handler(result.result);
        });
    }}>
        <div className='padding'>
            {player.Balance > 0 && <div className='group'>
                <div className='margin-top warning margin-bottom padding'>
                    <Icon icon='info-circle' size='1x' /><span>{lang('Player account is not empty, %1 will be withdrawn from player balance and will be transfered to Retail %2', [currency(player.Balance, 10000, player.Currency), player.RetailName])}</span>
                </div>
            </div>
            }
            <span>{lang('Please confirm removing player from retail %1', [player.RetailName])}</span>
        </div>
    </ConfirmationModal>);
};

export const removePlayerFromAffiliate = (player, handler) => {
    application().modal.open(<ConfirmationModal title='Move Player to Retail' onConfirm={() => {
        API.post(`${AffiliateEndpoints.RemovePlayer}/${player.Id}/${player.AffiliateId}`, null).then((result) => {
            application().snackbar.open('Player removed from affiliate');
            handler(result.result);
        });
    }}>
        <div className='padding'>
            {
                player.Balance > 0 && <div className='group'>
                    <div className='margin-top warning margin-bottom padding'>
                        <Icon icon='info-circle' size='1x' /><span>{lang('Player account is not empty, %1 will be withdrawn from player balance and will be transfered to Affiliate %2', [currency(player.Balance, 10000, player.Currency), player.Affiliate])}</span>
                    </div>
                </div>
            }
            <span>{lang('Please confirm removing player from retail %1', [player.Affiliate])}</span>
        </div>
    </ConfirmationModal>);
};



export const movePlayerToAffiliate = (player, handler) => {
    var form = null;

    var affiliates = {};
    API.post(DataEndpoints.MyAffiliates, {}).then((result) => {
        result.result.map(affiliate => {
            affiliates[affiliate.Id] = affiliate.Name;
        });
        application().modal.open(<ConfirmationModal title='Move Player to Affiliate' onConfirm={() => {
            var data = form.submit();
            data.playerId = player.Id;

            API.post(`${AffiliateEndpoints.MovePlayer}/${player.Id}/${data.AffiliateId}`, null).then((result) => {
                application().snackbar.open('Player moved');
                handler(result.result);
            });
        }}>
            <div className='padding'>
                {player.Balance > 0 && <div className='group'>
                    <div className='margin-top'>
                        <Icon icon='info-circle' size='1x' /><span>{lang('Player account is not empty, %1 will be withdrawn from player balance.', [currency(player.Balance, 10000, player.Currency)])}</span>
                    </div>
                </div>
                }
                <Form ref={(r) => form = r}>
                    <div className='row'>
                        <FormInput title='Affiliates' name='AffiliateId' type={InputTypes.select} values={affiliates} />
                    </div>
                </Form>
            </div>
        </ConfirmationModal>);
    });
};


export const resetBalance = (data, bonus, handler) => {
    var form = null;
    application().modal.open(<ConfirmationModal title={'Reset Balance'} onConfirm={() => {
        var transactionData = form.submit();
        transactionData.PlayerId = data.id;
        transactionData.Bonus = bonus;


        application().resolve(null, Endpoints.ResetBalance, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    }}>
        <span>{lang('Please confirm reset balance of player.')}</span>
        <ConfirmWithNote context={(c) => form = c} />
    </ConfirmationModal >);
};



export const removeBonus = (data, handler) => {
    var form = null;
    application().modal.open(<ConfirmationModal title={'Remove Bonus'} onConfirm={() => {
        var request = form.submit();
        request.PlayerId = data.id;

        application().resolve(null, `${Endpoints.RemoveBonus}/${data.id}`, request, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    }}>
        <span>{lang('Please confirm remove player bonus.')}</span>
        <ConfirmWithNote context={(c) => form = c} />
    </ConfirmationModal >);
};


export const completeBonus = (data, handler) => {
    var context = {
        amount: 0,
        currency: data.currency,
        notes: ''
    };
    application().modal.open(<ConfirmationModal title={'Complete Bonus'} onConfirm={() => {
        application().resolve(null, `${Endpoints.CompleteBonus}/${data.playerId}`, {
            amount: context.amount,
            notes: context.notes,
        }, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    }}>
        <span>{lang('Please confirm complete bonus.')}</span>
        <PlayerBonusComplete bonus={data.promotion} balance={data.balance} bonusBalance={data.bonusBalance} currency={data.currency} context={context} />
    </ConfirmationModal >);
};

const bonusTicketsData = {
    consumedPromotionId: ''
};

export const openBonusBets = (consumedPromotionId) => {
    bonusTicketsData.consumedPromotionId = consumedPromotionId;
    application().modal.open(<Dialog className='large' title={'Bonus tickets'}>
        <BonusTickets consumedPromotionId={consumedPromotionId} />
    </Dialog >);

};

const onActionClicked = (action) => {
    if (action) {
        openBonusBets(bonusTicketsData.consumedPromotionId);
    }
};



const openBetsOfTicket = (val, row) => {
    if (row.Product == 0) {
        return <span onClick={() => openBetModal(row.ExternalTicketId, row.TicketType == 2)}><a href='javascript:void()'>{row.ExternalTicketId}</a></span>;
    }
    if (row.Product == 1) {
        return <span onClick={() => application().modal.open(<HorseTicket ticket={row} onClose={() => application().modal.close()} />)}><a href='javascript:void()'>{row.ExternalTicketId}</a></span>;
    }
};

export const openBetModal = (id, system, manualSettlement = false, callback) => {
    getTicketDetails(id, system, (eventResults, slips, history) => {
        showSportDetails(eventResults, slips, history, system, manualSettlement, callback);
    });
};


export const getTicketDetails = (id, system, callback) => {
    application().resolve(null, `${Endpoints.TicketDetails}/${id}/${system}`, {}, !callback ? 'loading bets' : null, !callback ? 'unable to load bets' : null).then((result) => {

        var eventIds = {};
        var slips = result.result.details;
        var selections = result.result.selections;
        slips.map(x => {
            var bets = JSON.parse(x.bets);
            x.bets = bets;
            bets.map(bet => {
                if (selections) {
                    var selectionResult = selections.find(s => s.selectionId == bet.Id);
                    if (selectionResult) {
                        bet.SelectionStatus = selectionResult.status;
                    }
                }
            });
            x.bets.map(bet => {
                eventIds[bet.EventExternalId] = true;
            });
        });

        application().resolve(null, Endpoints.SportEventResults, Object.keys(eventIds), !callback ? 'loading event results' : null).then(eventResults => {
            callback(eventResults, slips, result.result.history.sort((a, b) => new Date(a.entryDate) - new Date(b.entryDate)), system);
        }).catch(() => {
            callback({ result: [] }, slips, result.result.history.sort((a, b) => new Date(a.entryDate) - new Date(b.entryDate)), system);
        });
    });
};

export const showSportDetails = (eventResults, slips, history, system, manualSettlement, callback) => {
    var results = {};

    Object.keys(eventResults.result).map(eventId => {
        var details = eventResults.result[eventId];
        results[eventId] = JSON.parse(details);
    });

    if (!callback) {
        callback = (component) => {
            application().modal.open(<Dialog invisibileCloseIcon={true} onActionClicked={(action) => onActionClicked(action)} actions={getAction(ActionTypes.BonusBetsBack).convertToItems()} title={'Bets'} className='large no-padding'>
                {component}
            </Dialog >);
        };
    }

    callback(<SportTicketDetails data={slips} scores={results} history={history} manualSettlement={manualSettlement} displayHistory={!system} />);
};


export const rejectPendingPromotion = (data, handler) => {
    var notes = null;
    const confirmed = () => {
        application().resolve(null, `${Endpoints.RejectPendingPromotion}/${data.playerId}/${data.id}`, {
            Message: notes
        }, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    };
    application().modal.open(<ConfirmationModal title={'Pending Promotion'} onConfirm={confirmed}>
        <p className='padding'>{lang('Do you want to reject pending promotion request')}</p>
        <div className='padding flex vertical'>
            <label>Notes</label>
            <textarea onChange={(e) => notes = e.target.value}></textarea>
        </div>
    </ConfirmationModal >);
};


export const rejectPendingPromotions = (players, handler) => {
    var notes = null;
    const confirmed = () => {
        application().resolve(null, `${Endpoints.RejectPendingPromotions}`, {
            Message: notes,
            Players: players
        }, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    };
    application().modal.open(<ConfirmationModal title={'Pending Promotion'} onConfirm={confirmed}>
        <p className='padding'>{lang('Do you want to reject pending promotion request')}</p>
        <div className='padding flex vertical'>
            <label>Notes</label>
            <textarea onChange={(e) => notes = e.target.value}></textarea>
        </div>
    </ConfirmationModal >);
};
