import './_page.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { lang } from '../../Lib/Common/language';
import { getStore } from '../../..';
import { Icon } from '../Icon/Icon';

const QuickLinks = (props) => {
    const items = props.route.parent?.items;
    const url = (props.route.route.split('/').reverse())[0];
    if (!items || items.length == 1) return <React.Fragment />;
    return <div className='link-group align-right separator underlined'>
        {items.map((link, index) => {
            return <NavLink to={link.route} key={index} className={link.route == url ? 'active' : null} onClick={(e) => {
                if (props.route.items) e.preventDefault();
                getStore().dispatch({ type: 'SELECT_MENU_ITEM', payload: link });
            }}>
                <Icon icon={link.icon} size='xs' /> <span>{lang(link.title)}</span>
            </NavLink>;
        })}
    </div>;
};

QuickLinks.propTypes = {
    route: PropTypes.object
};

export const Page = (props) => {
    var classList = ['page', props.className];


    return <div className={classList.join(' ')}>
        {props.children}
    </div>;
};

Page.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};


export const Home = () => {
    const url = new URL(window.location);
    history.pushState({}, '', url.pathname);
};

export const GetSearchParameters = (p) => {
    let params = (new URL(document.location)).searchParams;
    return params.get(p);
};