import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';

export const BonusModels = {
    bonusCampaign: {
        fields: [
            {
                name: 'Name'
            },
            {
                name: 'Description',
                type: InputTypes.text
            },
            {
                name: 'Rules'
            },
            {
                name: 'Enabled',
                types: InputTypes.bool
            }
        ]
    },

};