import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { apiResult, application, Endpoints } from '../../../Application/Application';
import { Panel } from '../../../Centrum/Card/Card';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Search } from '../../../Centrum/Search/Search';
import { endOfDay } from '../../../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';

import { Button } from '../../../Controls/Buttons/Button';
import { Tab } from '../../../Centrum/Tab/Tab';

const eventManagementModel = {
    fields: [
        {
            name: 'ScheduledDate',
            display: 'Event Date',
            type: InputTypes.dateTime,
            value: endOfDay(new Date())
        },
        {
            name: 'EventId',
            display: 'Event Id',
            type: InputTypes.number
        },
        {
            name: 'EventName',
            display: 'Event Name'
        },
        {
            name: 'Bookable',
            display: 'Bookable',
            type: InputTypes.bool,
        }
    ]
};

const managedEventsModel = {
    fields: [
        {
            name: 'externalEventId',
            display: 'Event Id'
        },
        {
            name: 'scheduled',
            display: 'Event Date',
            type: InputTypes.dateTime
        },
        {
            name: 'name',
            display: 'Name'
        },
        {
            name: 'sport',
            display: 'Sport'
        },
        {
            name: 'region',
            display: 'Region'
        },
        {
            name: 'league',
            display: 'League'
        },
        {
            name: 'bookingStatus',
            display: 'Status',
            formatter: (val) => {
                return val == 2 ? 'Booked' : 'Bookable';
            }
        },
        {
            name: 'adminId',
            display: 'User',
            // eslint-disable-next-line react/display-name
            formatter: (val, row, isFooter, context) => {
                var assigned = context.results.result.assigned[row.externalEventId];
                if (assigned) {
                    val = assigned.adminId;
                    row.adminBefore = val;
                }
                return <select key={row.externalEventId} defaultValue={val} onChangeCapture={(e) => {
                    var id = parseInt(e.target.value);
                    if (!isNaN(id)) {
                        row.adminId = id;
                    } else {
                        row.adminId = null;
                    }
                }}>
                    {context.admins.options}
                </select>;
            }
        }
    ]
};




const managedEvents = {
    fields: [
        {
            name: 'ExternalEventId',
            display: 'Event Id'
        },
        {
            name: 'Scheduled',
            display: 'Event Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Sport',
            display: 'Sport'
        },
        {
            name: 'Region',
            display: 'Region'
        },
        {
            name: 'League',
            display: 'League'
        },
        {
            name: 'AdminId',
            display: 'User',
            // eslint-disable-next-line react/display-name
            formatter: (val, row, isFooter, context) => {
                var admin = context.admins.admins[val];
                if (admin) return admin.Username;
            }
        }
    ]
};


const searchManagedEvents = {
    fields: [
        {
            name: 'EventId',
            display: 'Event Id',
            type: InputTypes.number
        },
        {
            name: 'AdminId',
            display: 'AdminId'
        }
    ]
};
const EventManagement = (props) => {
    const [admins, setAdmins] = useState([]);
    useEffect(() => {
        application().resolve(null, Endpoints.MyUsers).then((result) => {
            var rows = {};
            var options = [<option key={0} value={null}></option>];
            result.result.sort((a, b) => a.Username > b.Username ? 1 : -1).map(x => {
                rows[x.Id] = x;
                options.push(<option key={x.Id} value={x.Id}>{x.Username}</option>);
            });

            setAdmins({ admins: rows, options: options });
        });
    }, []);

    const save = () => {
        if (!props.managedLiveEvents.result.events) return;

        const updatedData = props.managedLiveEvents.result.events.result.filter(x => x.adminBefore != x.adminId);
        application().resolve(null, Endpoints.AssignEventToAdmin, updatedData, 'please wait', 'unable to update').then(() => {
            application().snackbar.open('Saved');
        });
    };

    const tabButtons = [
        {
            name: 'events',
            title: 'Manage Events'
        },
        {
            name: 'events-managed',
            title: 'Managed Events'
        }
    ];
    return <Tab buttons={tabButtons}>
        <Panel title=' ' buttons={<div className='centrum-flex alignRight padding-right'><Button title='Save' onClick={() => save()} /></div>}>
            <Search model={eventManagementModel}
                className='not-selectable'
                resultModel={managedEventsModel}
                data={props.managedLiveEvents.result.events}
                selectable={false}
                context={{
                    admins: admins,
                    results: props.managedLiveEvents
                }}
                onSubmit={(data, qb) => {
                    return apiResult('managedLiveEvents',
                        Endpoints.GetLiveEvents, qb, 'fetching events', 'unable to fetch evets');
                }}
            />
        </Panel>
        <Panel>
            <Search model={searchManagedEvents}
                className='not-selectable'
                resultModel={managedEvents}
                data={props.managedEvents}
                selectable={false}
                context={{
                    admins: admins,
                    results: props.managedLiveEvents
                }}
                onSubmit={(data, qb) => {
                    return apiResult('managedEvents',
                        Endpoints.ManagedEvents, qb, 'fetching events', 'unable to fetch evets');
                }}
            />
        </Panel>
    </Tab>;
};

EventManagement.propTypes = {
    managedLiveEvents: PropTypes.object,
    managedEvents: PropTypes.object
};

EventManagement.defaultProps = {
    managedLiveEvents: {
        result: {
            events: []
        }
    },
    managedEvents: {
        result: []
    }
};

const mapStateToProps = state => {
    return {
        managedLiveEvents: state.data.managedLiveEvents,
        managedEvents: state.data.managedEvents
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({

    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(EventManagement);
export { hoc as EventManagement };