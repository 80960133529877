import React from 'react';
import PropTypes from 'prop-types';
var QRCode = require('qrcode.react');

export const QR = (props) => {
    return <QRCode value={props.text} renderAs='svg' {...props} />;
};

QR.propTypes = {
    text: PropTypes.string
};
