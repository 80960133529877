// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { getActions, ActionTypes } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { UserGroupsConfiguration } from './UserGroupConfigurations';
import { Page } from '../../Centrum/Page/Page';
import { ExchangeRates } from './ExchangeRates';
import { BackofficeLanguagePage } from '../../../v2/Pages/BackofficeCMS/BackofficeLanguagePage';

const applicationActionType = ActionTypes.Configuration;
const applicationActions = getActions(applicationActionType);


// COMPONENT
class ConfigurationsPage extends Page {
    constructor(props) {
        super(props);

        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.modal = null;
        state.action = action;

        this.state = state;
        this.applicationContext = null;

        this.teamSelected = null;
    }

    render() {
        return (
            <CentrumApplication
                caption='Configure' icon='cogs'
                type={applicationActionType}
                loading={this.props.loading}
            >
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <ExchangeRates/>
                                    <UserGroupsConfiguration />
                                    <BackofficeLanguagePage/>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

ConfigurationsPage.defaultProps = {
    action: null,
    show: true
};

ConfigurationsPage.propTypes = {
    loading: PropTypes.bool
};


const mapStateToProps = state => {
    return {
        language: state.language.selected
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ConfigurationsPage);
export { hoc as ConfigurationsPage };