import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const SEARCH_CRASH_GAME_ROOMS            = 'SEARCH_CRASH_GAME_ROOMS';
export const SEARCH_CRASH_GAME_ROOMS_PENDING    = 'SEARCH_CRASH_GAME_ROOMS_PENDING';
export const SEARCH_CRASH_GAME_ROOMS_FULFILLED  = 'SEARCH_CRASH_GAME_ROOMS_FULFILLED';
export const SEARCH_CRASH_GAME_ROOMS_REJECTED   = 'SEARCH_CRASH_GAME_ROOMS_REJECTED';

export const SEARCH_CRASH_GAME_ROUND            = 'SEARCH_CRASH_GAME_ROUND';
export const SEARCH_CRASH_GAME_ROUND_PENDING    = 'SEARCH_CRASH_GAME_ROUND_PENDING';
export const SEARCH_CRASH_GAME_ROUND_FULFILLED  = 'SEARCH_CRASH_GAME_ROUND_FULFILLED';
export const SEARCH_CRASH_GAME_ROUND_REJECTED   = 'SEARCH_CRASH_GAME_ROUND_REJECTED';

export const SEARCH_CRASH_GAME_ROUND_TRANSACTIONS            = 'SEARCH_CRASH_GAME_ROUND_TRANSACTIONS';
export const SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_PENDING    = 'SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_PENDING';
export const SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_FULFILLED  = 'SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_FULFILLED';
export const SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_REJECTED   = 'SEARCH_CRASH_GAME_ROUND_TRANSACTIONS_REJECTED';

export const SEARCH_PASSIVE_CRASH_GAME_ROOMS            = 'SEARCH_PASSIVE_CRASH_GAME_ROOMS';
export const SEARCH_PASSIVE_CRASH_GAME_ROOMS_PENDING    = 'SEARCH_PASSIVE_CRASH_GAME_ROOMS_PENDING';
export const SEARCH_PASSIVE_CRASH_GAME_ROOMS_FULFILLED  = 'SEARCH_PASSIVE_CRASH_GAME_ROOMS_FULFILLED';
export const SEARCH_PASSIVE_CRASH_GAME_ROOMS_REJECTED   = 'SEARCH_PASSIVE_CRASH_GAME_ROOMS_REJECTED';


export const SEARCH_CRASH_GAME_NOTIFICATIONS            = 'SEARCH_CRASH_GAME_NOTIFICATIONS';
export const SEARCH_CRASH_GAME_NOTIFICATIONS_PENDING    = 'SEARCH_CRASH_GAME_NOTIFICATIONS_PENDING';
export const SEARCH_CRASH_GAME_NOTIFICATIONS_FULFILLED  = 'SEARCH_CRASH_GAME_NOTIFICATIONS_FULFILLED';
export const SEARCH_CRASH_GAME_NOTIFICATIONS_REJECTED   = 'SEARCH_CRASH_GAME_NOTIFICATIONS_REJECTED';


export const SEARCH_CRASH_GAME_BANKROLLS            = 'SEARCH_CRASH_GAME_BANKROLLS';
export const SEARCH_CRASH_GAME_BANKROLLS_PENDING    = 'SEARCH_CRASH_GAME_BANKROLLS_PENDING';
export const SEARCH_CRASH_GAME_BANKROLLS_FULFILLED  = 'SEARCH_CRASH_GAME_BANKROLLS_FULFILLED';
export const SEARCH_CRASH_GAME_BANKROLLS_REJECTED   = 'SEARCH_CRASH_GAME_BANKROLLS_REJECTED';


export const SEARCH_CRASH_GAME_LEADERBOARD            = 'SEARCH_CRASH_GAME_LEADERBOARD';
export const SEARCH_CRASH_GAME_LEADERBOARD_PENDING    = 'SEARCH_CRASH_GAME_LEADERBOARD_PENDING';
export const SEARCH_CRASH_GAME_LEADERBOARD_FULFILLED  = 'SEARCH_CRASH_GAME_LEADERBOARD_FULFILLED';
export const SEARCH_CRASH_GAME_LEADERBOARD_REJECTED   = 'SEARCH_CRASH_GAME_LEADERBOARD_REJECTED';



// ACTION CREATORS

export function searchCrashGameRooms(data) {
    return {
        type: SEARCH_CRASH_GAME_ROOMS,
        payload: postData(data,Endpoints.CrashGameSearchRooms)
    };
}
export function searchCrashGameRound(data) {
    return {
        type: SEARCH_CRASH_GAME_ROUND,
        payload: postData(data,Endpoints.CrashGameSearchGameRound)
    };
}

export function searchCrashGameRoundTransactions(data) {
    return {
        type:SEARCH_CRASH_GAME_ROUND_TRANSACTIONS,
        payload:postData(data,Endpoints.CrashGameSearchCrashGameRoundTransactions)
    };
}



export function searchPassiveCrashGameRooms(nodeKey) {
    return {
        type: SEARCH_PASSIVE_CRASH_GAME_ROOMS,
        payload: postData({},Endpoints.CrashGameSearchPassiveRooms + nodeKey)
    };
}

export function searchCrashServerNotifications(data) {
    return {
        type : SEARCH_CRASH_GAME_NOTIFICATIONS,
        payload:postData(data,Endpoints.CrashGameSearchCrashServerNotifications)
    };
}


export function searchCrashBankRolls(data) {
    return {
        type : SEARCH_CRASH_GAME_BANKROLLS,
        payload:postData(data,Endpoints.CrashGameSearchCrashGameBankrolls)
    };
}


export function searchCrashGameLeaderboard(data) {
    return {
        type : SEARCH_CRASH_GAME_LEADERBOARD,
        payload:postData(data,Endpoints.CrashGameSearchCrashGameLeaderboard)
    };
}
