import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';

export const MarketingModels = {
    deleteEmailTemplate: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text,
                readonly: true
            }
        ]
    },
    searchEmailTemplate: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.select,
                acceptEmptyValues:true,
                values: {
                    'Welcome':'Welcome',
                    'ResetPassword':'ResetPassword'
                }
            },
            {
                name: 'Title',
                type: InputTypes.text
            }
        ]
    },
    emailTemplateSearchResult: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Title',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Language',
                display: 'Language',
                type: InputTypes.text,
            }
        ]
    },
    emailTemplateEditModel: {
        name: 'emailTemplateEditModel',
        display: 'EditEmail Template',
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                readonly: true,
                identity: true
            },
            {
                name: 'OperatorId',
                display: 'Operators',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Language',
                display: 'Languages',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    'en-En': 'English',
                    'tr-Tr': 'Turkish'
                }
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',

            },
            {
                name: 'Title',
                type: InputTypes.text
            },
            {
                name: 'Removal',
                display: 'Disabled',
                type: InputTypes.bool,
            }
        ]
    },
    emailTemplateCreateModel: {
        name: 'emailTemplateCreateModel',
        display: 'EditEmail Template',
        fields: [
            {
                name: 'Language',
                display: 'Languages',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    'en-En': 'English',
                    'tr-Tr': 'Turkish'
                }
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',

            },
            {
                name: 'Title',
                type: InputTypes.text,
                validation: 'text',
            },
            {
                name: 'Removal',
                display: 'Disabled',
                type: InputTypes.bool,
            }
        ]
    },


    searchTemplatesEvents: {
        fields: [{
            name: 'OperatorId',
            display: 'Operators',
            type: InputTypes.select,
            required: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        }
        ]
    },

    templateEventsSearchResult: {
        fields: [
            {
                display: 'Operator Id',
                name: 'operatorId',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                display: 'Event Category',
                name: 'eventCategory',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                display: 'Event Type',
                name: 'eventType',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'emailTemplateName',
                display: 'Selected Mail Template',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            }

        ]
    },

    templateEventsEdit: {
        fields: [
            {
                display: 'Operator Id',
                name: 'operatorId',
                type: InputTypes.text,
                readonly: true,
                validation: 'text',
                max: 128
            },
            {
                display: 'Event Category',
                name: 'eventCategory',
                readonly: true,
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                display: 'Event Type',
                name: 'eventType',
                readonly: true,
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'emailTemplateName',
                display: 'Selected Mail Template',
                hidden: true,
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'emailTemplateId',
                type: InputTypes.text,
                hidden: true,
                validation: 'text',
                max: 128
            }

        ]
    },

};