/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../v2/Components/Card/Card';
import { Button } from '../../Controls/Buttons/Button';
import { lang } from '../../Centrum/Localization/lang';
import { application } from '../../Application/Application';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { Icon } from '../../../v2/Components/Icon/Icon';
import { API, CMSEndpoints } from '../../../v2/Lib/Api/Api';
import { EditableTable } from '../../Controls/Table/Editable/EditableTable';
import { ErrorModal } from '../../Modal/Modals/Error/ErrorModal';


export const convertLSportIdToBetradarSportId = (id) => {
    switch (id) {
        case 6046: return 1;
        case 48242: return 2;
        case 154914: return 3;
        case 35232: return 4;
        case 530129: return 4;
        case 54094: return 5;
        case 35709: return 6;
        case 35706: return 7;
        case 687894: return 8;
        case 687889: return 9;
        case 154919: return 10;
        case 1149093: return 11;
        case 274791: return 12;
        case 46957: return 15;
        case 131506: return 16;
        case 1149099: return 17;
        case 262622: return 19;
        case 265917: return 20;
        case 452674: return 21;
        case 154923: return 22;
        case 154830: return 23;
        case 388764: return 26;
        case 1149100: return 27;
        case 307126: return 28;
        case 687887: return 29;
        case 1149121: return 30;
        case 1149113: return 32;
        case 687897: return 33;
        case 621569: return 34;
        case 1149097: return 35;
        case 1149103: return 36;
        case 1149104: return 37;
        case 1149120: return 39;
        case 1149094: return 40;
        case 261354: return 43;
        case 1149101: return 44;
        case 1149118: return 48;
        case 1149122: return 54;
        case 687888: return 55;
        case 687893: return 58;
        case 274792: return 59;
        case 687896: return 67;
        case 1149095: return 70;
        case 1149125: return 71;
        case 1149115: return 81;
        case 1149098: return 90;
        case 1149107: return 99;
        case 1149108: return 1149108;
        case 687890: return 107;
        case 1149112: return 108;
        case 1149119: return 129;
        case 687895: return 131;
        case 1149117: return 136;
        case 1149123: return 138;
        case 1149105: return 155;
        case 1149102: return 190;
        case 291987: return 291987;
        case 389537: return 389537;
        case 1149096: return 1149096;
        case 1149109: return 1149109;
        case 1149110: return 1149110;
        case 1149111: return 1149111;
        case 1149114: return 1149114;
        case 1149124: return 1149124;
        case 1149126: return 1149126;
        default:
            return id;
    }
};



export const lsMarketGroups = {
    1: [
        { name: 'main', title: 'Main', markets: [1, 2, 3, 4, 5, 7] },
        {
            name: 'total & goals', title: 'Total & Goals', markets: [
                2, 5
            ]
        },
        {
            name: 'half', title: 'Halves', markets: [
                41, 42
            ]
        },
        {
            name: 'score', title: 'Score', markets: [
                6, 9, 16, 17, 22, 23,
                59,
                711, 712, 713,
                714, 715,
                1065, 1066,
                1067, 1068
            ]
        },
        {
            name: 'card & corners', title: 'Cards & Corners', markets: [
                19, 157
            ]
        }
    ],
    2: [
        { name: 'main', title: 'Main', markets: [1, 226, 51] },
        { name: 'total', title: 'Total', markets: [51, 12, 62, 77, 220, 221] },
        { name: 'handicap', title: 'Handicap', markets: [3, 13, 53, 61, 64, 65, 66, 67, 283, 342, 386, 468, 1691, 1692, 1693, 1694] },
        { name: 'period', title: 'Period', markets: [21, 41, 42, 43, 44, 45, 46, 47, 64, 65, 66, 67, 70, 72, 73, 74, 75, 153, 154, 155, 156, 202, 203, 204, 205, 222, 223, 224, 225, 242, 243, 287, 288, 289, 291, 292, 293, 317, 318, 319, 320, 329, 330, 331, 332, 456, 457, 1691, 1692, 1693, 1694] },
        { name: 'points', title: 'Points', markets: [1050, 1069, 1075, 1090, 1092, 1093] },
        { name: 'player', title: 'Player', markets: [1069, 1070, 1071, 1072, 1073, 1074, 1075, 1089, 1091, 1092, 1093] }
    ],
    3: [
        { name: 'main', title: 'Main', markets: [1, 2, 52, 226, 51] }
    ],
    5: [
        { name: 'main', title: 'Main', markets: [52, 2] },
        { name: 'sets', title: 'Sets', markets: [202, 202, 199, 199, 207, 196, 196, 192, 193] },
        { name: 'games', title: 'Games', markets: [189, 190, 191, 198] }
    ],
    23: [
        { name: 'main', title: 'Main', markets: [52, 2] },
        { name: 'sets', title: 'Sets', markets: [202, 202, 202, 202, 202, 310, 309] },
    ],
    default: [
        { name: 'main', title: 'Main', markets: [1, 2, 3, 4, 5, 6, 7, 58, 51, 52, 226] },
    ]
};


export const SetupMarketGroups = (props) => {
    const [groupName, setGroupName] = useState({
        name: null
    });
    const [selectedGroup, selectGroup] = useState(null);
    const [category, selectCategory] = useState(null);
    const [markets, setMarkets] = useState([]);
    const [selectedMarkets, setSelectedMarkets] = useState(null);
    const [tabButtons, setTabButtons] = useState([
        {
            name: 'all',
            title: 'All'
        },
        {
            name: 'favourites',
            title: 'Favourites'
        },
        {
            name: 'main',
            title: 'Main',
            markets: []
        }
    ]);

    const [marketGroups, setMarketGroups] = useState({ ...lsMarketGroups });

    const marketRef = React.createRef();

    useEffect(() => {
        API.post(CMSEndpoints.GetSportMarketGroups, {}).then((result) => {
            var converted = {};
            try {
                converted = JSON.parse(result.result);
            } catch (err) {
                converted = { ...lsMarketGroups };
            }
            if (!converted) {
                converted = { ...lsMarketGroups };
            }
            //converted = { ...lsMarketGroups };
            setMarketGroups(converted);
            API.post(`${CMSEndpoints.GetSportMarkets}/${window.user.features.feedType}`, {}).then((result) => {
                var csv = result.result;
                var data = csv.split('\n');
                var converted = data.map(x => {
                    var p = x.split(',');
                    if (p[8] != 'True') return null;
                    return {
                        sport: p[0],
                        sportName: p[1],
                        id: p[2],
                        name: p[3],
                        selections: p[4],
                        inPlaySettlements: p[9] == 'True'
                    };
                });
                converted = converted.filter(x => x != null);
                setMarkets(converted);
                var categories = props.categories.filter(x => x.enabled);
                selectCategory(categories[0]);
                selectGroup(tabButtons[0]);
            });
        });
    }, []);

    useEffect(() => {
        if (!category) return;
        var categoryId = category.id.toString();
        var sportMarkets = markets.filter(x => {
            return x.sport == categoryId;
        });
        if (categoryId == 'default') {
            sportMarkets = markets;
        }
        setSelectedMarkets(sportMarkets);
        var copy = [
            {
                name: 'all',
                title: 'All'
            },
            {
                name: 'favourites',
                title: 'Favourites'
            }
        ];
        if (categoryId != 'default') {
            var marketGroupNames = marketGroups[convertLSportIdToBetradarSportId(category.id)];
            if (marketGroupNames) {
                marketGroupNames.map(x => {
                    copy.push({ name: x.name, title: x.title });
                });
            }
        }

        setTabButtons(copy);
    }, [category]);

    const save = () => {
        application().resolve(null, CMSEndpoints.SaveSportMarketGroups, marketGroups).then(() => {
            application().snackbar.open('Market groups saved.');
        });
    };


    var newGroupName = null;
    var newGroupTitle = null;

    const addNew = (group) => {
        newGroupName = group?.name ?? null;
        newGroupTitle = group?.title ?? null;
        application().modal.open(<ConfirmationModal title={'Group Name'} onConfirm={() => {
            var errors = [];
            if (!group && newGroupName.toLowerCase() == 'all') {
                errors.push('Invalid group name, please do not use the following names, All, Favourites');
            }
            if (!group && newGroupName.toLowerCase() == 'favourites') {
                errors.push('Invalid group name, please do not use the following names, All, Favourites');
            }
            var copy = [...tabButtons];
            if (!group && copy.find(x => x.name.toLowerCase() == newGroupName)) {
                errors.push('Invalid group name, please do not use the following names, All, Favourites');
            }


            if (errors.length) {
                application().modal.open(<ErrorModal title='Invalid parameters'>
                    <div className='flex padding'>
                        {errors.map(y => {
                            return <div className='padding'>{y}</div>;
                        })}
                    </div>
                </ErrorModal>);
                return;
            }

            var sportMarketsCopy = { ...marketGroups };
            var convertedId = convertLSportIdToBetradarSportId(category.id);
            var sportMarkets = marketGroups[convertedId];
            var marketGroup = sportMarkets.find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase());

            if (group) {
                var e = copy.find(x => x.name.toLowerCase() == group.name);
                e.title = newGroupTitle;
                marketGroup.title = newGroupTitle;
            } else {
                copy.push({
                    name: newGroupName,
                    title: newGroupTitle
                });
                sportMarkets.push({
                    name: newGroupName.toLowerCase(),
                    title: newGroupTitle,
                    markets: []
                })
            }
            setTabButtons(copy);
            setMarketGroups(sportMarketsCopy);
        }}>
            {!group && <div className='flex vertical gap-10'>
                <div className='flex gap-5 fit'>
                    <label>{lang('Group Name')}</label>
                    <input onChange={(e) => {
                        var copy = { ...groupName };
                        copy.name = e.target.value;
                        newGroupName = e.target.value;
                        setGroupName(copy);
                    }} defaultValue={group?.name} />
                </div>
            </div>
            }
            <div className='flex vertical gap-10'>
                <div className='flex gap-5 fit'>
                    <label>{lang('Group Title')}</label>
                    <input onChange={(e) => {
                        var copy = { ...groupName };
                        copy.title = e.target.value;
                        newGroupTitle = e.target.value;
                        setGroupName(copy);
                    }} defaultValue={group?.title} />
                </div>
            </div>
        </ConfirmationModal>);
    };

    const deleteGroup = () => {
        application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
            var copy = [...tabButtons];
            copy = copy.filter(x => x.name != selectedGroup.name);
            var sportMarketsCopy = { ...marketGroups };
            var convertedId = convertLSportIdToBetradarSportId(category.id);
            sportMarketsCopy[convertedId] = sportMarketsCopy[convertedId].filter(x => x.name.toLowerCase() != selectedGroup.name.toLowerCase());
            selectGroup(copy[0]);
            setTabButtons(copy);
            setMarketGroups(sportMarketsCopy);
        }}>
            <p>{lang('Do you want to delete this group?')}</p>
        </ConfirmationModal>)
    };


    const renderGroup = () => {
        if (!marketGroups) return;
        if (!category) return;
        var convertedId = convertLSportIdToBetradarSportId(category.id);
        if (!marketGroups[convertedId]) return;
        var sportMarkets = marketGroups[convertedId];
        var data = sportMarkets.find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase())?.markets ?? [];

        if (selectedGroup.name == 'all') {
            sportMarkets.map(x => {
                if (x.markets && x.markets.length > 0) {
                    data = data.concat(x.markets);
                }
            });
        }

        if (data) {
            var groupModel = {
                fields: [
                    {
                        name: 'id',
                        display: 'Id',
                        render: (row) => {
                            return row;
                        }
                    },
                    {
                        name: 'name',
                        display: 'Name',
                        render: (row) => {
                            var market = selectedMarkets.find(x => x.id == row);
                            return <div>{market?.name}</div>;
                        }
                    },
                    {
                        name: 'selections',
                        display: 'Selections',
                        render: (row) => {
                            var market = selectedMarkets.find(x => x.id == row);
                            return <div>{market?.selections}</div>;
                        }
                    },
                    {
                        name: 'inplay',
                        display: 'In Play Settlements',
                        render: (row) => {
                            var market = selectedMarkets.find(x => x.id == row);
                            return <div>{market?.inPlaySettlements == true ? <Icon icon='check' /> : <Icon icon='times' />}</div>;
                        }
                    }
                ]
            };

            if (selectedGroup.name == 'all') {
                var copy = {};
                data.map(x => {
                    copy[x] = x;
                });

                data = Object.keys(copy);
            }
            return <div className='flex gap-20 vertical'>
                <div className='padding'>
                    <label>{lang('Markets')}</label>
                </div>
                <EditableTable model={groupModel} key={data} data={data} draggable={selectedGroup.name !== 'all'} buttons={(row) => {
                    return <Button title='delete' onClick={() => {
                        var copy = { ...marketGroups };
                        if (selectedGroup.name == 'all') {
                            copy[convertedId].map(x => {
                                x.markets = x.markets.filter(y => y != row);
                            });
                        } else {
                            copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets = copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets.filter(x => x != row);
                        }
                        setMarketGroups(copy);
                    }} />;
                }} onDataChange={(data) => {
                    var copy = { ...marketGroups };
                    copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets = data;
                    setMarketGroups(copy);
                }} />
            </div>;
        }

    };

    const addMarket = () => {
        if (!marketRef.current) return;
        var copy = { ...marketGroups };
        var convertedId = convertLSportIdToBetradarSportId(category.id);
        if (!copy.hasOwnProperty(convertedId)) {
            copy[convertedId] = {

            };
            tabButtons.map(x => {
                if (x.name == 'all') return;
                if (x.name == 'favourites') return;
                copy[convertedId].find(y => y.name.toLowerCase() == x.name.toLowerCase()).markets = [];
            });
        }
        var value = parseInt(marketRef.current.value);
        if (copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets.find(x => x == value)) {
            return;
        }

        copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets.push(value);
        setMarketGroups(copy);
    };


    const changeOrder = () => {
        application().modal.open(<ConfirmationModal title='Change orders'>
            <EditableTable model={{
                fields: [
                    {
                        name: 'name',
                        display: 'Name',
                        render: (row) => {
                            return row.title;
                        }
                    }
                ]
            }} key={tabButtons} data={tabButtons.filter(x => {
                if (x.name == 'all') return false;
                if (x.name == 'favourites') return false;
                return true;
            })} draggable={true} onDataChange={(data) => {
                var a = [{
                    name: 'all',
                    title: 'All'
                },
                {
                    name: 'favourites',
                    title: 'Favourites'
                }];
                a = a.concat(data);
                setTabButtons(a);
                var sportMarketGroupsCopy = { ...marketGroups };
                var convertedId = convertLSportIdToBetradarSportId(category.id);
                if (!sportMarketGroupsCopy[convertedId]) return;
                var copy = [];
                data.map(x => {
                    copy.push(sportMarketGroupsCopy[convertedId].find(y => y.name == x.name));
                });

                sportMarketGroupsCopy[convertedId] = copy;
                setMarketGroups(sportMarketGroupsCopy);
            }} />
        </ConfirmationModal>);
    };

    if (!props.categories) return <div></div>;
    if (!category) return <div />;
    if (!selectedGroup) return <div />;




    return <Card caption='Change Market Groups' menu={<Button title='Save' onClick={() => save()} />}>
        <Card>
            <div className='flex'>
                <div className='flex vertical'>
                    <label>{lang('Sports')}</label>
                    <select defaultValue={category.id} onChangeCapture={(e) => {
                        selectCategory(props.categories.find(x => x.id == parseInt(e.target.value)));
                    }}>

                        {props.categories.map(x => {
                            return <option key={x.id} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                </div>
                <div className='flex align-right hidden'>
                    <Button title='Change Default' onClick={() => {
                        selectCategory({ id: 'default', name: 'default' });
                    }} />
                </div>
            </div>
        </Card>
        <div className='tab'>
            <div className='tab-buttons'>
                {
                    tabButtons.map(x => {
                        return <Button key={x} title={x.title} className={`button${selectedGroup?.name == x.name ? ' selected' : ''}`} onClick={() => selectGroup(x)} />;
                    })
                }
                <Button title='Add New' onClick={() => addNew()} />
            </div>
        </div>
        <div className='flex border radius fit padding margin-top'>
            <div className='flex gap-20 fit'>
                <div className='flex gap-20 align-items-center'>
                    <div className='flex gap-5'>
                        {selectedGroup.name != 'all' && selectedGroup.name != 'favourites' && <Button onClick={() => {
                            setGroupName(selectedGroup);
                            addNew(selectedGroup);
                        }}>
                            <span className='no-wrap'>{lang('Change group title')}</span>
                        </Button>}
                        <Button onClick={() => {
                            changeOrder();
                        }}>
                            <span className='no-wrap'>{lang('Change order')}</span>
                        </Button>
                    </div>
                    {selectedGroup.name != 'all' && selectedGroup.name != 'favourites' &&
                        <div className='flex gap-5 stretch'>
                            <span>{lang('Market')}</span>
                            <select ref={marketRef}>
                                {selectedMarkets.map(x => {
                                    return <option value={x.id} key={x.id}>{x.name} {x.id}</option>;
                                })}
                            </select>
                            <Button onClick={() => addMarket()}><span className='no-wrap'>{lang('Add Market')}</span></Button>
                        </div>
                    }
                </div>

                {
                    selectedGroup.name != 'all' && selectedGroup.name != 'favourites' && <div className='flex gap-5 align-right'><Button className='align-right' onClick={() => deleteGroup()} >
                        <span className='no-wrap'>{lang('Delete Group')}</span>
                    </Button>
                    </div>
                }
            </div>
        </div>
        {renderGroup()}
    </Card >;
};


SetupMarketGroups.propTypes = {
    categories: PropTypes.array
};