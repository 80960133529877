import React from 'react';

import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';
import { currency } from '../Localization/lang';
import { gotoProfile } from './Transactions';

const renderBalls = (balls) => {
    if (!balls) return;
    balls = balls.split(',');
    return <ul className='lottery-numbers'>
        {balls.map((x, index) => {
            return <li key={index}>{x}</li>;
        })}
    </ul>;
};

const renderBonusBall = (ball) => {
    return <ul className='lottery-numbers'>
        <li className='bonus'>{ball}</li>
    </ul>;
};


const lotteryEventStatus = {
    0: 'New',
    1: 'Resulted',
    2: 'Closed',
    3: 'Removed'
};


const lotteryTicketStatus = {
    0: 'New',
    1: 'Win',
    2: 'Lost',
    3: 'Cancel'
};

export const lotteryGames = {
    1: 'UK 49',
    2: 'Russia Gosloto',
    3: 'Ireland Daily Millions',
    4: 'Spain Bonoloto',
    5: 'U.K. Thunderball'
};



export const LotteryModels = {
    searchLotteryTickets: {
        fields: [
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Week']
                }
            },
            {
                name: 'GameId',
                display: 'Game',
                type: InputTypes.select,
                values: lotteryGames,
                acceptEmptyValues: true
            },
            {
                name: 'Currency',
                display: 'Default Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                },
                acceptEmptyValues: true
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'PlayerId',
                display: 'PlayerId',
                type: InputTypes.number
            },
            {
                name: 'Code',
                display: 'Player Code',
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                },
                acceptEmptyValues: true
            },
            {
                name: 'Stake',
                type: InputTypes.number
            },
            {
                name: 'WinAmount',
                display: 'Win Amount',
                type: InputTypes.number
            },
            {
                name: 'Status',
                display: 'Ticket Status',
                type: InputTypes.select,
                values: lotteryTicketStatus,
                acceptEmptyValues: true
            }
        ]
    },
    resultLotteryTickets: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'EventId',
                display: 'Event Id',
                type: InputTypes.number
            },
            {
                name: 'GameId',
                display: 'Game',
                formatter: (val) => {
                    return lotteryGames[val];
                }
            },
            {
                name: 'PlayerId',
                display:'Player Id'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row) => {
                    return gotoProfile(row.PlayerId, row.Username);
                }
            },
            {
                name: 'PlayerGroup',
                display: 'Group'
            },
            {
                name: 'Code',
                display: 'Code'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Stake',
                type: InputTypes.number,
                max: 20,
                formatter: (val, data) => {
                    return currency(val, 2, data.Currency);
                }
            },
            {
                name: 'Balls',
                type: InputTypes.text,
                formatter: (val) => {
                    return renderBalls(val);
                }
            },
            {
                name: 'PickMatchUp',
                display: 'Pick Match Up',
                type: InputTypes.bool,
                formatter: (val, data) => {
                    return val ? 'yes ' + data.PickMatch : 'false';
                }
            },
            {
                name: 'IncludeBonusBall',
                display: 'Bonus Ball',
                type: InputTypes.bool
            },
            {
                name: 'WinAmount',
                display: 'Win Amount',
                type: InputTypes.number,
                max: 20,
                formatter: (val, data) => {
                    return currency(val, 2, data.Currency);
                }
            },
            {
                name: 'Status',
                type: InputTypes.select,
                value: lotteryTicketStatus
            }
        ]
    },
    searchLotteryEvents: {
        fields: [
            {
                name: 'Date',
                display: 'Date',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Week']
                }
            },
            {
                name: 'LotteryId',
                display: 'Game',
                type: InputTypes.select,
                values: lotteryGames,
                acceptEmptyValues: true
            },
            {
                name: 'Status',
                display: 'Event Status',
                type: InputTypes.select,
                values: lotteryEventStatus,
                acceptEmptyValues: true
            }
        ]
    },
    lotteryEvents: {
        fields: [
            {
                name: 'Date',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'LotteryId',
                display: 'Game',
                type: InputTypes.select,
                values: lotteryGames,
            },
            {
                name: 'Results',
                display: 'Results',
                formatter: (val) => {
                    if (val) {
                        return renderBalls(val);
                    }
                }
            },
            {
                name: 'BonusBall',
                display: 'Bonus Ball',
                formatter: (val) => {
                    if (val) {
                        return renderBonusBall(val);
                    }
                }
            }
        ]
    }
};