import React, { useEffect, useState } from 'react';
import { application, Endpoints } from '../../../Application/Application';
import { Card } from '../../../Centrum/Card/Card';
import { lotteryGames } from '../../../Centrum/_models/LotteryModels';
import { Currencies } from '../../../Centrum/_models/ModelEnums';
import { Button } from '../../../Controls/Buttons/Button';
import { CurrencyMinMaxConfiguration } from '../../Promotions/Components/CurrencyMinMaxConfiguration';

const createEmptyData = () => {
    var data = [

    ];

    Object.keys(lotteryGames).map(game => {
        var row = {
            gameId: game,
            minBet: 0,
            maxBet: 0,
            maxPayout: 0
        };
        data.push(row);
    });

    return data;
};

const prepareData = (limits) => {
    if (!limits) {
        limits = {};
    }

    Object.keys(Currencies).map(currency => {
        limits[currency] = createEmptyData();
    });


    return limits;
};


export const LotteryConfiguration = () => {
    var limitsComponent = null;
    const selectedCurrency = 'TRY';
    const [limits, setLimits] = useState(prepareData());
    const [limitsLoaded, setLimitsLoaded] = useState(false);
    const [update, setUpdate] = useState(0);
    var games = Object.keys(lotteryGames).map(x => {
        return { value: x, display: lotteryGames[x] };
    });


    useEffect(() => {
        if (!limitsLoaded) {
            setLimitsLoaded(true);
            application().resolve(null, Endpoints.LotteryEventLimits, {}, 'loading event limits', 'unable to load limits').then((result) => {
                if (result.result) {
                    setLimits(result.result);
                    setUpdate(Date.now());
                }
            });
        }
    });

    const save = () => {
        application().resolve(null, Endpoints.LotterySaveEventLimits, limitsComponent.save(), 'saving event limits', 'unable to save limits').then(() => {
            application().snackbar.open('Limits saved');
        });
    };

    return <React.Fragment>
        <Card caption='Limits'>
            <CurrencyMinMaxConfiguration
                ref={(r) => limitsComponent = r}
                selectedCurrency={selectedCurrency}
                data={limits}
                key={update}
                newRow={false}
                deleteEnabled={false}
                model={
                    [
                        {
                            name: 'gameId',
                            display: 'Game',
                            type: 'select',
                            values: games,
                            readOnly: true,
                            formatter: (value) => {
                                return lotteryGames[value];
                            }
                        },
                        {
                            name: 'minBet',
                            display: 'Minimum Bet',
                            type: 'number'
                        },
                        {
                            name: 'maxBet',
                            display: 'Maximum Bet',
                            type: 'number'
                        },
                        {
                            name: 'maxPayout',
                            display: 'Maximum Payout',
                            type: 'number'
                        },
                    ]
                }
            />
            <div className='centrum-flex margin-top'>
                <Button title='Save' type='submit' className='margin alignRight' onClick={() => {
                    save();
                }} />
            </div>
        </Card>
    </React.Fragment>;
};

