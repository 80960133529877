import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Icon extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        var classNames = ['icon'];
        classNames.push(this.props.type);
        classNames.push('fa-' + this.props.icon);
        classNames.push('fa-' + this.props.size);
        classNames.push(this.props.className);
        return <i className={classNames.join(' ')} onClick={this.props.onClick.bind(this)} />;
    }
}

Icon.defaultProps = {
    size: '1x',
    type: 'fas',
    onClick: () => { }
};

Icon.propTypes = {
    size: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
};
