import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const SEARCH_TRANSACTIONS            = 'SEARCH_TRANSACTIONS';
export const SEARCH_TRANSACTIONS_PENDING    = 'SEARCH_TRANSACTIONS_PENDING';
export const SEARCH_TRANSACTIONS_FULFILLED  = 'SEARCH_TRANSACTIONS_FULFILLED';
export const SEARCH_TRANSACTIONS_REJECTED   = 'SEARCH_TRANSACTIONS_REJECTED';


export const SEARCH_DEPOSITS            = 'SEARCH_DEPOSITS';
export const SEARCH_DEPOSITS_PENDING    = 'SEARCH_DEPOSITS_PENDING';
export const SEARCH_DEPOSITS_FULFILLED  = 'SEARCH_DEPOSITS_FULFILLED';
export const SEARCH_DEPOSITS_REJECTED   = 'SEARCH_DEPOSITS_REJECTED';

export const SEARCH_WITHDRAWS            = 'SEARCH_WITHDRAWS';
export const SEARCH_WITHDRAWS_PENDING    = 'SEARCH_WITHDRAWS_PENDING';
export const SEARCH_WITHDRAWS_FULFILLED  = 'SEARCH_WITHDRAWS_FULFILLED';
export const SEARCH_WITHDRAWS_REJECTED   = 'SEARCH_WITHDRAWS_REJECTED';

export const DEPOSIT_OPERATION =              'DEPOSIT_OPERATION';
export const DEPOSIT_OPERATION_PENDING =      'DEPOSIT_OPERATION_PENDING';
export const DEPOSIT_OPERATION_FULFILLED =    'DEPOSIT_OPERATION_FULFILLED';
export const DEPOSIT_OPERATION_REJECTED =     'DEPOSIT_OPERATION_REJECTED';


export const WITHDRAW_OPERATION =              'WITHDRAW_OPERATION';
export const WITHDRAW_OPERATION_PENDING =      'WITHDRAW_OPERATION_PENDING';
export const WITHDRAW_OPERATION_FULFILLED =    'WITHDRAW_OPERATION_FULFILLED';
export const WITHDRAW_OPERATION_REJECTED =     'WITHDRAW_OPERATION_REJECTED';



// ACTION CREATORS

export function searchTransactions(query,route) {
    return {
        type: SEARCH_TRANSACTIONS,
        payload: postData(query,Endpoints.SearchTransactions),
        route:route==undefined ? 'transactions' : ''
    };
}

export function searchDeposits(query) {
    return {
        type: SEARCH_DEPOSITS,
        payload: postData(query,Endpoints.SearchTransactions)
    };
}

export function searchWithdraws(query) {
    return {
        type: SEARCH_WITHDRAWS,
        payload: postData(query,Endpoints.SearchTransactions)
    };
}

export function confirmDeposits(transactionsList) {
    return {
        type: DEPOSIT_OPERATION,
        payload: postData({data:transactionsList},Endpoints.TransactionsConfirmDeposits)
    };
}

export function confirmWithdraws(transactionsList) {
    return {
        type: WITHDRAW_OPERATION,
        payload: postData({data:transactionsList},Endpoints.TransactionsConfirmWithdraws)
    };
}

export function rejectDeposits(transactionsList) {
    return {
        type: DEPOSIT_OPERATION,
        payload: postData({data:transactionsList},Endpoints.TransactionsRejectDeposits)
    };
}

export function rejectWithdraws(transactionsList) {
    return {
        type: WITHDRAW_OPERATION,
        payload: postData({data:transactionsList},Endpoints.TransactionsRejectWithdraws)
    };
}


