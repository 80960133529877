import React, { useState } from 'react';
import { Card } from '../../../../v2/Components/Card/Card';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { FormInput } from '../../../../v2/Components/Controls/Form/FormInput';
import { API, CMSEndpoints } from '../../../../v2/Lib/Api/Api';

export const LSportsSportIcons = () => {
    const [lsportsId, setLSportsId] = useState(8410466);
    const [betradarId, setBetradarId] = useState(32986719);

    const [lsportEvent, setLSportEvent] = useState(null);
    const [betRadarEvent, setBetRadarEvent] = useState(null);

    const [saveResult, setSaveResult] = useState(null);

    const showEvents = () => {
        setSaveResult(null);
        API.post(`${CMSEndpoints.GetLSportsEventParticipants}/${lsportsId}`, null).then((result) => {
            setLSportEvent(result.result);
        });

        API.post(`${CMSEndpoints.GetBetradarEventParticipants}/${betradarId}`, null).then((result) => {
            setBetRadarEvent(result.result);
        });
    };

    const renderBetradarEvent = () => {
        if (!betRadarEvent) return;
        var competitors = JSON.parse(betRadarEvent.competitors);

        const renderCompetitor = (competitor) => {
            return <div className='flex vertical gap-20'>
                <div className='flex-gap-5'>
                    <div className='flex gap-10'><label>Id</label><span>{competitor?.id?.id}</span></div>
                    <div className='flex gap-10'><label>Name</label><span>{competitor?.name}</span></div>
                </div>
                <img src={`//img.sportradar.com/ls/crest/big/${competitor?.id?.id}.png`} />
            </div>;
        };

        return <Card title='Betradar Event'>
            <div className='flex vertical gap-10'>
                <div className='flex gap-10 border radius padding'>
                    <label>Id</label><span>{betRadarEvent.id}</span>
                    <label>Sport</label><span>{betRadarEvent.sport}</span>
                    <label>Region</label><span>{betRadarEvent.region}</span>
                    <label>League</label><span>{betRadarEvent.league}</span>
                </div>
                <div className='flex gap-10 border radius padding'>
                    <label>Name</label><span>{betRadarEvent.name}</span>
                </div>
                <div className='flex gap-20 center border radius padding'>
                    {renderCompetitor(competitors[0])}
                    <div className='paddding center'>vs</div>
                    {renderCompetitor(competitors[1])}
                </div>
            </div>
        </Card>;
    };

    const renderLSportEvent = () => {
        if (!lsportEvent) return;
        var competitors = JSON.parse(lsportEvent.competitors);

        const renderCompetitor = (competitor) => {
            return <div className='flex vertical gap-20'>
                <div className='flex-gap-5'>
                    <div className='flex gap-10'><label>Id</label><span>{competitor?.Id}</span></div>
                    <div className='flex gap-10'><label>Name</label><span>{competitor?.Name}</span></div>
                </div>
                <img src={`//cdn-d.tentangle.com/jersey/big/${competitor?.Id}.png`} />
            </div>;
        };

        return <Card title='LSports Event'>
            <div className='flex vertical gap-10'>
                <div className='flex gap-10 border radius padding'>
                    <label>Id</label><span>{lsportEvent.id}</span>
                    <label>Sport</label><span>{lsportEvent.sport}</span>
                    <label>Region</label><span>{lsportEvent.region}</span>
                    <label>League</label><span>{lsportEvent.league}</span>
                </div>
                <div className='flex gap-10 border radius padding'>
                    <label>Name</label><span>{lsportEvent.name}</span>
                </div>
                <div className='flex gap-20 center border radius padding'>
                    {renderCompetitor(competitors[0])}
                    <div className='paddding center'>vs</div>
                    {renderCompetitor(competitors[1])}
                </div>
            </div>
        </Card>;
    };

    const uploadImages = () => {
        if (!betRadarEvent) return;
        var betradar = JSON.parse(betRadarEvent.competitors);

        if (!lsportEvent) return;
        var lsport = JSON.parse(lsportEvent.competitors);

        console.log(betradar);
        console.log(lsport);

        API.post(CMSEndpoints.UpdateLSportTeamImages, {
            lsportHomeId: lsport[0].Id,
            betradarHomeImage: `//img.sportradar.com/ls/crest/big/${betradar[0]?.id.id}.png`,
            lsportAwayId: lsport[1].Id,
            betradarAwayImage: `//img.sportradar.com/ls/crest/big/${betradar[1]?.id.id}.png`
        }).then((result) => {
            setSaveResult(result);
        });
    };

    return <Card title='LSport Team Icons'>
        <form>
            <div className='row'>
                <FormInput title='LSports Event Id' onChange={(value) => setLSportsId(value)} />
            </div>
            <div className='row'>
                <FormInput title='Betradar Event Id' onChange={(value) => setBetradarId(value)} />
            </div>
        </form>
        <Button title='Show' onClick={() => showEvents()} />
        <div className='flex vertical gap-20 padding'>
            {renderBetradarEvent()}
            {renderLSportEvent()}
        </div>

        <div className='flex vertical padding'>
            {saveResult && <p className='padding'>{saveResult.result}</p>}
            {lsportEvent && betRadarEvent && <Button title='Upload images' onClick={() => uploadImages()} />}
        </div>
    </Card>;
};