import './_pages.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


import { application, Endpoints } from '../../../Application/Application';
import { Button } from '../../../Controls/Buttons/Button';
import { AppHomePage } from './ApplicationTypes/AppHomePage';
import { AppGamingPage } from './ApplicationTypes/AppGamingPage';
import { NavLink } from 'react-router-dom';
import { AppSportsPage } from './ApplicationTypes/AppSportsPage';
import { Tab } from '../../../Centrum/Tab/Tab';
import { API, CMSEndpoints } from '../../../../v2/Lib/Api/Api';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';
import { lang } from '../../../Centrum/Localization/lang';
import { Icon } from '../../../../v2/Components/Icon/Icon';


const PageTypes = {
    homepage: 'Home Page',
    gaming: 'Game Page',
    sports: 'Sports Page',
    racing: 'Horse / Greyhound races',
    iframe: 'IFrame Page',
    redirect: 'Redirect Page',
    promotions: 'Promotions Page'
};

export const WebSitePages = (props) => {
    const [pages, setPages] = useState(null);
    const [page, selectPage] = useState(null);
    const [defaultLanguage, setDefaultLanguage] = useState('en');
    const [prefix, setPrefix] = useState(null);
    const [updateTime, setUpdateTime] = useState(null);

    useEffect(() => {
        API.post(`${CMSEndpoints.Contents}/true`, { type: 'language', key: 'DefaultLanguage' }).then((defaultLanguageResult) => {
            let defaultLanguage = defaultLanguageResult.result.find(x => x.key == 'DefaultLanguage').content;

            API.post(`${CMSEndpoints.Contents}/true`, { type: 'website' }).then((result) => {
                var contents = result.result;
                var content = JSON.parse(contents.find(x => x.key == 'Pages').content);
                var prefix = contents.find(x => x.key == 'Prefix').content;

                setPages(content);
                setDefaultLanguage(defaultLanguage);
                setPrefix(prefix);
            });

        });
    }, []);

    useEffect(() => {
        if (!pages) return;
        if (!page) selectPage(pages[0]);
    });

    useEffect(() => {
        setUpdateTime(Date.now());
    }, [page]);


    const publish = () => {
        application().resolve(null, Endpoints.CMSPublishPages, {}, 'publishing', 'unable to publish').then(() => {
            application().snackbar.open('published');
        });
    };

    const save = () => {
        try {
            API.post(Endpoints.CMSSaveContent, { type: 'website', key: 'pages', content: JSON.stringify(pages) }).then(() => {
                application().snackbar.open('Pages are saved, please dont forget to publish.');
            });
        } catch (err) {
            console.log(err);
        }
        //

    };

    const updatePage = (data) => {
        try {
            var copy = Object.assign([], pages);
            var index = copy.findIndex(x => x.key == page.key);
            if (page.app == 'homepage') {
                index = copy.findIndex(x => x.key == page.key);
            }
            copy[index] = data;
            setPages(copy);
        } catch (err) {
            console.log(err);
        }
        //

    };

    const deletePage = () => {
        application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
            var copy = pages.filter(x => x != page);
            setPages(copy);
            application().post(Endpoints.CMSSaveContent, { type: 'website', key: 'pages', content: JSON.stringify(copy) }).then(() => {
                application().snackbar.open('Page deleted');
                selectPage(copy[0]);
                setPages(copy);
            });
        }}>
            <p className='padding'>
                {lang('Do you want to remove %1 page', [page.title])}
            </p>
        </ConfirmationModal>);
    };

    const renderPage = () => {
        if (!page) return;
        var app = null;
        switch (page.app) {
            case 'homepage':
                app = <AppHomePage key={updateTime} page={page} prefix={prefix} defaultLanguage={defaultLanguage} onSave={updatePage.bind(this)} />;
                break;
            case 'gaming':
                app = <AppGamingPage key={updateTime} configuration={page} prefix={prefix} defaultLanguage={defaultLanguage} games={props.games} onSave={updatePage.bind(this)} />;
                break;
            case 'racing':
            case 'sports':
                app = <AppSportsPage key={updateTime} configuration={page} prefix={prefix} defaultLanguage={defaultLanguage} games={props.games} onSave={updatePage.bind(this)} />;
                break;
            case 'promotions':
                app = <div className='panel'>
                    <div className='padding'>
                        <NavLink to='/cms/cmspromotions'><Button title='Edit promotions' /></NavLink>
                    </div>
                </div>;
                break;
            default:
                app = <div>unknown</div>;
                break;
        }

        return app;
    };


    const renderDefaultConfig = () => {
        if (!page) return;
        return <div className='panel' key={updateTime}>
            <div className='flex padding gap-10'>
                <div className='flex gap-5'>
                    <label>{lang('Page title')}</label>
                    <input defaultValue={page.title} onChangeCapture={(e) => {
                        var copy = { ...page };
                        copy.title = e.target.value;
                        selectPage(page);
                    }} />
                </div>
                <div className='flex gap-5'>
                    <label>{lang('Display in navigation')}</label>
                    <Icon icon={page.menu ? 'check-square' : 'square'} type='far' />
                </div>
            </div>
        </div>;
    };

    const addNewPage = () => {
        var newPageData = {
            title: '',
            key: Date.now(),
            name: '',
            configuration: {}
        };

        application().modal.open(<ConfirmationModal title='Add new page' onConfirm={() => {
            var copy = [...pages];
            copy.push(newPageData);
            setPages(copy);
        }}>
            <div className='padding flex vertical gap-10'>
                <form className='form'>
                    <div className='row'>
                        <div className='form-group'>
                            <label>{lang('Page type')}</label>
                            <select onChange={(e) => {
                                newPageData.app = e.target.value;
                            }}>
                                {Object.keys(PageTypes).map(x => <option key={x} value={x}>{PageTypes[x]}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group'>
                            <label>{lang('Title')}</label>
                            <input onChangeCapture={(e) => {
                                newPageData.title = e.target.title;
                                newPageData.name = e.target.value;
                            }} />
                        </div>
                    </div>
                </form>
            </div>
        </ConfirmationModal>);
    };

    if (!pages) return <div />;

    let pageButtons = [];
    pages.map(x => {
        pageButtons.push({
            name: x.title,
            title: x.title,
            page: x
        });
    });

    return <div className='cms-page'>
        <div className='panel'>
            <div className='padding flex gap-10'>
                <label>Prefix</label>
                <span className='padding'>{prefix}</span>
                <label>Default Language</label>
                <span>{defaultLanguage}</span>
                <div className='flex gap-5 align-right'>
                    <Button title='Add new page' onClick={() => {
                        addNewPage();
                    }} />
                    <Button title='Publish' onClick={() => publish()} />
                </div>
            </div>
        </div>
        <Tab buttons={pageButtons} onTabSelected={(a, b, button) => selectPage(button.page)} />
        <div className=''>
            {renderDefaultConfig()}
        </div>
        <div className=''>
            {renderPage()}
        </div>
        <div className='panel padding'>
            <div className='flex gap-5'>
                <Button title='Save'  onClick={() => {
                    save();
                }} />
                <Button title='Delete Page' className='align-right' onClick={() => {
                    deletePage();
                }} />
            </div>
        </div>
    </div>;
};

WebSitePages.defaultProps = {
    pages: [],
    games: [],
    selectedPage: 0
};

WebSitePages.propTypes = {
    pages: PropTypes.array,
    games: PropTypes.array
};