import './_lotteryPage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { getActions, ActionTypes } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { Page } from '../../Centrum/Page/Page';
import { application, Endpoints } from '../../Application/Application';
import { OpenEvents } from './Pages/OpenEvents';
import { Search } from '../../Centrum/Search/Search';
import { Card } from '../../Centrum/Card/Card';
import { LotteryConfiguration } from './Pages/LotteryConfigurationPage';


const applicationActionType = ActionTypes.Lottery;
const applicationActions = getActions(applicationActionType);


// COMPONENT
class LotteryPage extends Page {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    componentDidMount() {
        application().resolve(null, Endpoints.LotteryEvents, {}, 'fetching events', 'unable to fetch events').then((result) => {
            const events = result.result;
            this.setState({ events: events });
        });

    }

    searchTickets(data) {
        application().resolve(null, Endpoints.LotterySearchTickets, data, 'searching tickets', 'unable to search tickets').then((result) => {
            this.setState({ tickets: result, updateDate: Date.now() });
        });
    }


    searchEvents(data) {
        application().resolve(null, Endpoints.LotterySearchEvents, data, 'searching events', 'unable to search events').then((result) => {
            this.setState({ searchEvents: result, updateDate: Date.now() });
        });
    }

    render() {
        if (!this.state.events) return <div />;
        return (
            <CentrumApplication
                caption='Lottery' icon='award'
                type={applicationActionType}
            >
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <OpenEvents events={this.state.events} />
                                    <Card caption='Coupons'>
                                        <Search key='search'
                                            model='searchLotteryTickets'
                                            resultModel='resultLotteryTickets'
                                            data={this.state.tickets}
                                            onSubmit={(data, qb) => { this.searchTickets(qb); }}
                                        />
                                    </Card>
                                    <Card caption='Events'>
                                        <Search key='search'
                                            model='searchLotteryEvents'
                                            resultModel='lotteryEvents'
                                            data={this.state.searchEvents}
                                            onSubmit={(data, qb) => { this.searchEvents(qb); }}

                                        />
                                    </Card>
                                    <LotteryConfiguration/>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

LotteryPage.defaultProps = {
    configuration: {}
};

LotteryPage.propTypes = {
    configuration: PropTypes.object,
    categories: PropTypes.object
};


const mapStateToProps = () => {
    return {
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(LotteryPage);
export { hoc as LotteryPage };