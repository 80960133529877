
import React from 'react';
import PropTypes from 'prop-types';


import { Page } from '../../Centrum/Page/Page';
import { application, Endpoints } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { LabelValue } from '../Dashboards/components/LabelValue/LabelValue';
import { Search } from '../../Centrum/Search/Search';
import { lang, currency } from '../../Centrum/Localization/lang';
import { Tab } from '../../Centrum/Tab/Tab';
import { AffiliateDashboard } from './AffiliateDashboard';
import { Button } from '../../Controls/Buttons/Button';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { FormInput } from '../../Controls/Form/FormInput';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Form } from '../../Controls/Form/Form';
import { AffiliateSettlement } from './AffiliateSettlement';
import { Dropdown } from '../../Controls/DropDown/Dropdown';
import { TransactionTypes } from '../../Centrum/_models/ModelEnums';
import { gotoProfile } from '../../Centrum/_models/Transactions';


const affiliateTransactionResult = {
    fields: [
        {
            name: 'id',
            type: InputTypes.number
        },
        {
            name: 'transactionDate',
            display: 'Created',
            type: InputTypes.dateTime
        },
        {
            name: 'type',
            display: 'Type',
            formatter: (val, row) => {
                if (row.Status == 2) {
                    return `${lang('Cancel')} ${TransactionTypes[row.type]}`;
                }

                return TransactionTypes[row.type];
            }
        },
        {
            name: 'userId',
            display: 'Player Id'
        },
        {
            name: 'username',
            display: 'Username',
            type: InputTypes.text,
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return row.Username;
                return gotoProfile(row.userId, row.username);
            }
        },
        {
            name: 'name',
            display: 'Name'
        },
        {
            name: 'surname',
            display: 'Surname'
        },
        {
            name: 'source',
            display: 'Source',
            type: InputTypes.text
        },
        {
            name: 'notes',
            display: 'Notes'
        },
        {
            name: 'operatorUser',
            display: 'Admin'
        },
        {
            name: 'provider',
            display: 'Provider',
            type: InputTypes.text
        },
        {
            name: 'bonus',
            display: 'Bonus',
            type: InputTypes.bool
        },
        {
            name: 'bonusWallet',
            display: 'Bonus Wallet',
            type: InputTypes.bool
        },
        {
            name: 'currency',
            display: 'Currency'
        },
        {
            name: 'amount',
            display: 'Amount',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.currency, false);
            }
        },
        {
            name: 'balanceAfter',
            display: 'Balance',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.currency, false);
            }
        }
    ]
};

class BonusSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }


    values() {
        return {
            amount: this.state.amount,
            notes: this.state.notes,
            bonusId: this.state.selectedBonus
        };
    }
    render() {
        var activeBonusList = {};
        this.props.activeBonuses.map(x => {
            activeBonusList[x.Id] = x.Name;
        });
        return <Form>
            <div className='row'>
                <FormInput type={InputTypes.select} name='Bonus' title='Bonus' values={activeBonusList} onChange={
                    (data, field, value) => {
                        this.setState({ selectedBonus: value });
                    }
                } />
            </div>
        </Form>;
    }
}

BonusSelection.defaultProps = {
    activeBonuses: []
};

BonusSelection.propTypes = {
    activeBonuses: PropTypes.array
};



// COMPONENT
export class AffiliateProfile extends Page {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);

    }

    componentDidMount() {
        this.loadAffiliates();
        this.loadGroups();
    }

    loadAffiliates() {
        return application().resolve(null, Endpoints.ListAffiliates, { recordsPerPage: 10000 }, 'retreiving affiliates', 'unable to fetch affiliates').then((result) => {
            var affiliate = result.result.find(x => x.Id == this.state.affiliate.Id);
            this.setState({ affiliates: result.result, affiliate: affiliate, updateDate: Date.now() });
        });
    }

    loadGroups() {
        application().resolve(null, Endpoints.ListAffiliateGroups, {}, 'retreiving affiliate groups', 'unable to fetch affiliate groups').then((result) => {
            this.setState({ groups: result.result, addNewData: null, updateDate: Date.now() });
        });
    }

    searchAffiliateUsers(data) {
        application().resolve(null, Endpoints.SearchAffiliateUsers + '/' + this.state.affiliate.Id, data, 'search users', 'unable to search users').then((result) => {
            this.setState({ affiliateUsers: result, updateDate: Date.now() });
        });
    }

    searchAffiliatePlayers(data) {
        application().resolve(null, Endpoints.ListAffiliatePlayers + '/' + this.state.affiliate.Id, data, 'search players', 'unable to search players').then((result) => {
            this.setState({ players: result, updateDate: Date.now() });
        });
    }

    searchAffiliateTransactions(data) {
        application().resolve(null, Endpoints.SearchAffiliateTransactions + '/' + this.state.affiliate.Id, data, 'search transactions', 'unable to search transactions').then((result) => {
            this.setState({ transactions: result, updateDate: Date.now() });
        });
    }

    searchAffiliateBonus(data) {
        application().resolve(null, Endpoints.SearchAffiliateBonus + '/' + this.state.affiliate.Id, data, 'search bonus', 'unable to search bonus').then((result) => {
            this.setState({ bonus: result, updateDate: Date.now() });
        });
    }

    searchAffiliatePlayerGameHistory(data) {
        application().resolve(null, Endpoints.SearchAffiliatePlayerGameHistory + '/' + this.state.affiliate.Id, data, 'search game history', 'unable to search game history').then((result) => {
            this.setState({ gameHistory: result, updateDate: Date.now() });
        });
    }


    editUser(action, items) {
        this.addAffiliateUser(items[0]);
    }

    onTabSelected(index, key) {
        console.log(key);
    }

    addAffiliateUser(data) {
        application().modal.open(<ConfirmationModal
            onConfirm={() => {
                var data = this.newOpeartorUserForm.submit();
                application().resolve(null, Endpoints.AddAffiliateUser + '/' + this.state.affiliate.Id, data, 'adding user', 'unable to add user').then(() => {
                    this.loadAffiliates().then(() => {
                        application().snackbar.open('User saved');
                    });
                });
            }}
            title='Please confirm'>
            <Form data={data} ref={(r) => this.newOpeartorUserForm = r}>
                <div className='row'>
                    <FormInput title='Email' name='Email' />
                </div>
                <div className='row'>
                    <FormInput title='Name' name='Name' />
                    <FormInput title='Surname' name='Surname' />
                </div>
                <div className='row'>
                    <FormInput title='Password' name='Password' />
                </div>
                <div className='row'>
                    <FormInput title='Enabled' name='Enabled' type={InputTypes.bool} />
                </div>
            </Form>
        </ConfirmationModal>);
    }

    togglePromotion() {
        if (this.state.affiliate.PromotionId > 0) {
            application().modal.open(<ConfirmationModal
                onConfirm={() => {
                    application().resolve(null, Endpoints.AffiliateRemoveActivePromotion + '/' + this.state.affiliate.Id, null, 'updating active promotion', 'unable to update active promotion').then(() => {
                        this.loadAffiliates().then(() => {
                            application().snackbar.open('Promotion removed');
                        });
                    });
                }}
                title='Please confirm'>{lang('Active promotion %1 will be removed', [this.state.affiliate.Promotion])}</ConfirmationModal>);
            return;
        }


        application().resolve(null, Endpoints.ListPromotions, {}, 'fetching bonus list', 'unable to fetch bonus list').then((bonusList) => {
            var activeBonuses = bonusList.result.filter(x => x.Enabled);
            application().modal.open(<ConfirmationModal title='Select Bonus'
                onConfirm={() => {
                    var data = this.bonusSelection.values();
                    application().resolve(null, Endpoints.AffiliateAddPromotion + '/' + this.state.affiliate.Id + '/' + data.bonusId, data, 'adding bonus', 'unable to add bonus').then(() => {
                        this.loadAffiliates().then(() => {
                            application().snackbar.open('Promotion added');
                        });

                    });
                }}>
                <BonusSelection activeBonuses={activeBonuses} ref={(r) => this.bonusSelection = r} />
            </ConfirmationModal>);
        });
    }

    render() {
        if (this.state.groups == null) return <div></div>;

        var group = this.state.groups.find(x => x.Id == this.state.affiliate.Group);

        let tabButtons = [
            {
                name: 'profile',
                title: 'Profile'
            },
            {
                name: 'users',
                title: 'Users'
            },
            {
                name: 'dashboard',
                title: 'Dashboard'
            },
            {
                name: 'players',
                title: 'Players'
            },
            {
                name: 'transactions',
                title: 'Transactions'
            },
            {
                name: 'bonus',
                title: 'Promotions'
            },
            {
                name: 'gameHistory',
                title: 'GameHistory'
            },
            {
                name: 'settlements',
                title: 'Settlements'
            }
        ];

        var link = this.state.affiliate.WebSite + '?&cid=' + this.state.affiliate.Link;
        var modelType = group.Type == 0 ? 'GGR' : 'Deposit';
        var promoButtonTitle = this.state.affiliate.PromotionId > 0 ? 'Remove Promotion' : 'Add Promotion';

        return <React.Fragment key={this.updateDate}>
            <Tab buttons={tabButtons} ref={(r) => this.tabs = r} onTabSelected={this.onTabSelected.bind(this)}>
                <div key='Profile'>
                    <Card caption={this.state.affiliate.Name} key='profile'>
                        <div className='margin'>
                            <LabelValue title='Description'>{this.state.affiliate.Description}</LabelValue>
                            <LabelValue title='Link'>{link}</LabelValue>
                            <LabelValue title='Group'>{group.Name}</LabelValue>
                            <div className='seperator'><span>{lang('Model')}</span></div>
                            <LabelValue>{modelType}</LabelValue>
                            <LabelValue title='Balance'>{currency(this.state.affiliate.Balance, group.currency)}</LabelValue>
                            <div className='seperator'><span>{lang('Active Promotion')}</span></div>
                            <LabelValue>{this.state.affiliate.Promotion}</LabelValue>
                            <Button title={promoButtonTitle} onClick={this.togglePromotion.bind(this)} />
                        </div>
                    </Card>
                </div>
                <React.Fragment key='users'>
                    <Card caption={'Users'} key='users' menu={
                        <Dropdown position='right' type='menu' title='Actions'>
                            <Button key='b' title='Add User' onClick={this.addAffiliateUser.bind(this)} />
                        </Dropdown>}>
                        <div className='margin'>
                            <Search
                                actions={{
                                    items: [
                                        {
                                            type: 'edit',
                                            multiple: false,
                                            caption: 'Edit',
                                            icon: 'edit'
                                        }
                                    ]
                                }}
                                onActionClicked={this.editUser.bind(this)}
                                model='searchAffiliateUsers'
                                resultModel='affiliateUser'
                                data={this.state.affiliateUsers}
                                onSubmit={(data, qb) => { this.searchAffiliateUsers(qb); }}
                            />
                        </div>
                    </Card>
                </React.Fragment>
                <AffiliateDashboard key='dashboard' affiliate={this.state.affiliate} />
                <React.Fragment key='players'>
                    <Search key={'search'}
                        model='searchAffiliatePlayer'
                        resultModel='affilliatePlayerSummary'
                        data={this.state.players}
                        onSubmit={(data, qb) => { this.searchAffiliatePlayers(qb); }}
                    />
                </React.Fragment>
                <React.Fragment key='transactions'>
                    <Card>
                        <Search key={'search'}
                            model='searchAffiliateTransactions'
                            resultModel={affiliateTransactionResult}
                            data={this.state.transactions}
                            onSubmit={(data, qb) => { this.searchAffiliateTransactions(qb); }}
                        />
                    </Card>
                </React.Fragment>
                <React.Fragment key='bonus'>
                    <Search key={'search'}
                        model='searchAffiliateBonusTransactions'
                        resultModel='affiliateBonusTransactions'
                        data={this.state.bonus}
                        onSubmit={(data, qb) => { this.searchAffiliateBonus(qb); }}
                    />
                </React.Fragment>
                <React.Fragment key='gameHistory'>
                    <Search key={'search'}
                        model='searchPlayerGameHistory'
                        resultModel='resultPlayerGameHistory'
                        data={this.state.gameHistory}
                        onSubmit={(data, qb) => { this.searchAffiliatePlayerGameHistory(qb); }}
                    />
                </React.Fragment>
                <React.Fragment key='settlements'>
                    <AffiliateSettlement affiliate={this.state.affiliate} group={group} />
                </React.Fragment>
            </Tab>
        </React.Fragment>;
    }
}

AffiliateProfile.defaultProps = {
    affiliate: null,
    groups: null,
    players: null
};

AffiliateProfile.propTypes = {
    affiliate: PropTypes.object,
    groups: PropTypes.array,
    players: PropTypes.object
};
