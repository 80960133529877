import React from 'react';
import { lang } from './Shared';


export const keys = {
    down : 'down',
    up : 'up',
    left:'left',
    right:'right',
    enter:'enter',
    escape:'escape'
};


function defaultContext() {

    window.centrumContext = {
        activeComponent: null,
        activeApplication:null,
        componentIndex: 0,
        onComponentClicked: function (component) {
            window.centrumContext.activeComponent = component;
        },
        onComponentRemoved: function (component) {
            if (window.centrumContext.activeComponent === component) window.centrumContext.activeComponent = null;
        },
        onUIKeysPressed:function(key) {
            if (window.centrumContext.activeComponent!==null) {
                window.centrumContext.activeComponent.onUIKeysPressed(key);
            }
        }
    };


    window.addEventListener('keydown', function (event) {
        if (event.defaultPrevented) {
            return; // Do nothing if the event was already processed
        }

        try {
            if (event.target.tagName.toUpperCase() == 'INPUT' || event.target.tagName.toUpperCase() == 'SELECT' || event.target.tagName.toUpperCase() == 'TEXTAREA') {
                return;
            }
        } catch(err) {
            //
            console.warn(err);
        }
        switch (event.key) {
            case 'Down': // IE specific value
            case 'ArrowDown':
                window.centrumContext.onUIKeysPressed(keys.down);
                break;
            case 'Up': // IE specific value
            case 'ArrowUp':
                window.centrumContext.onUIKeysPressed(keys.up);
                break;
            case 'Left': // IE specific value
            case 'ArrowLeft':
                window.centrumContext.onUIKeysPressed(keys.left);
                break;
            case 'Right': // IE specific value
            case 'ArrowRight':
                window.centrumContext.onUIKeysPressed(keys.right);
                break;
            case 'Enter':
                window.centrumContext.onUIKeysPressed(keys.enter);
                break;
            case 'Escape':
                window.centrumContext.onUIKeysPressed(keys.escape);
                break;
            default:
                return; // Quit when this doesn't handle the key event.
        }

        // Cancel the default action to avoid it being handled twice
        event.preventDefault();
    }, true);
    

    
    return window.centrumContext;
}

function getNextComponentIndex() {
    window.centrumContext.componentIndex++;
    return window.centrumContext.componentIndex;
}

export class CentrumComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({},props);
        this.centrumContext = window.centrumContext === undefined ? defaultContext() : window.centrumContext;
        this.componentIndex = getNextComponentIndex();
        this.className='show';
        this.lang = lang;
    }

    componentWillUnmount() {
        this.centrumContext.onComponentRemoved(this);
    }

    componentClicked() {
        if (window.centrumContext.activeComponent !== this) {
            window.centrumContext.activeComponent = this;
        }
    }


    /*key parameters removed to avaoid warning*/
    onUIKeysPressed(/*key*/) {
        
    }

    renderComponent() {
        return <div />;
    }

    render() {
        return (
            <div className={'centrum-component '+this.className} key={this.componentIndex} onClick={() => this.componentClicked()}>
                {this.renderComponent()}
            </div>
        );
    }
}
