// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from '../../../Controls/Table/Table';
import { Button } from '../../../Controls/Buttons/Button';
import { Endpoints, application } from '../../../Application/Application';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';
import { Tab } from '../../../Centrum/Tab/Tab';
import { Card } from '../../../Centrum/Card/Card';
import { Switch } from '../../../Controls/Inputs/Switch/Switch';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { lang } from '../../../Centrum/Localization/lang';



class ConfigurePaymentGateway extends React.Component {
    constructor(props) {
        super(props);
        this.data = Object.assign({}, this.props.configuration);
    }


    updateConfig(type, method, context, field, value) {
        var data = this.data;
        var configuration = (JSON.parse(data.configuration));
        var methodConfiguration = configuration[type].methods.find(x => x.name == method.name);
        methodConfiguration[field.name] = value;

        this.data.configuration = JSON.stringify(configuration);
        this.props.onChange(this.data);
    }

    renderOptions(option, type) {
        return <div className='centrum-flex vertical marginHalf'>
            <div className='centrum-flex alignItemsCenter'>
                <p className='margin'>{lang('Enable this method')}</p>
                <Switch name='enabled' checked={option.enabled} />
            </div>
            {
                option.methods && option.methods.map((method, index) => {
                    var data = method;
                    if (!data.tag) data.tag = '';
                    return <Card key={index} caption={method.display ? method.display : method.name}>
                        <Form className='singleLine' data={data}>
                            <div className='row'>
                                <FormInput title='Enabled' name='enabled' type={InputTypes.bool} onChange={this.updateConfig.bind(this, type, method)} />
                            </div>
                            <div className='row'>
                                <FormInput title='Enabled Players with tag' name='tagEnabled' type={InputTypes.bool} onChange={this.updateConfig.bind(this, type, method)} />
                                <FormInput name='tag' type={InputTypes.text} onChange={this.updateConfig.bind(this, type, method)} />
                            </div>
                        </Form>
                    </Card>;
                })
            }
            {
                !option.methods && option.iframe && <p className='marginHalf warning'>{lang('this method uses iframe')}</p>
            }
        </div>;
    }

    render() {
        var data = JSON.parse(this.props.configuration.configuration);
        let tabButtons = [
            {
                name: 'deposit',
                title: 'Deposit'
            },
            {
                name: 'withdraw',
                title: 'Withdraw'
            }
        ];

        if (!data.deposit) data.deposit = {};
        if (!data.withdraw) data.withdraw = {};

        return <div>
            <Tab ref={(r) => this.tab = r} buttons={tabButtons}>
                <React.Fragment>
                    {this.renderOptions(data.deposit, 'deposit')}
                </React.Fragment>
                <React.Fragment>
                    {this.renderOptions(data.withdraw, 'withdraw')}
                </React.Fragment>
            </Tab>
        </div>;
    }
}

ConfigurePaymentGateway.defaultProps = {
    onChange: () => { }
};


ConfigurePaymentGateway.propTypes = {
    configuration: PropTypes.object,
    onChange: PropTypes.func
};


// COMPONENT
class ConfigurePaymentGateways extends React.Component {
    constructor(props) {
        super(props);
        this.paymentGateway = null;
        this.paymentGateways = Object.assign([], props.configuration);
        this.state = Object.assign({}, props);
    }


    save() {
        application().resolve(null, Endpoints.SaveOperatorPaymentGateways + '/' + this.props.operatorId, this.paymentGateways, 'updating payment gateways please wait', 'Unable to update payment gateways').then(() => {
            application().snackbar.open('Operator Payment Gateways configured.');
        });
    }

    saveConfiguration(paymentGateway) {
        this.paymentGateways.find(x => x.name == paymentGateway.name).configure = false;
        this.paymentGateways.find(x => x.name == paymentGateway.name).configuration = this.paymentGateway.configuration;
        this.setState({ configurationOpen: false, changeTime: Date.now() });
    }

    cancelSaveConfiguration(paymentGateway) {
        this.paymentGateways.find(x => x.name == paymentGateway.name).configure = false;
        this.setState({ configurationOpen: false, changeTime: Date.now() });
    }

    onSelectionChange(row, index, value) {
        if (!row) {
            this.paymentGateways.map(x => {
                x.disabledByPlatform = !value;
            });
            return;
        }

        row.disabledByPlatform = !value;
        row.__selected = value;
        this.paymentGateways.find(x => x.providerId == row.providerId).__selected = value;
    }

    onConfigurationChange(paymentGateway) {
        this.paymentGateway = Object.assign({}, paymentGateway);
    }


    onConfigurePaymentGateway(field, paymentGateway) {
        this.paymentGateway = Object.assign({}, paymentGateway);
        application().modal.open(<ConfirmationModal title='Configure Payment Gateway'
            onConfirm={this.saveConfiguration.bind(this, paymentGateway)} onCancel={this.cancelSaveConfiguration.bind(this, paymentGateway)}><ConfigurePaymentGateway configuration={paymentGateway} onChange={this.onConfigurationChange.bind(this)} /></ConfirmationModal>);
    }

    render() {
        this.paymentGateways.map((config) => {
            config.__selected = !config.disabledByPlatform;
        });


        return <React.Fragment>
            <div className='padding'>
                <Table model={'paymentGatewayConfiguration'} key={this.state.changeTime}
                    data={this.paymentGateways.sort((a, b) => a.aggregatorId - b.aggregatorId)} selectable={true}
                    editable={true} editableFields={'configure'}
                    onSelectionChange={(row, index, value) => this.onSelectionChange(row, index, value)}
                    onDataChange={this.onConfigurePaymentGateway.bind(this)} />
            </div>
            <Button className='margin' onClick={() => this.save()} title='Save'></Button>
        </React.Fragment>;
    }

}

ConfigurePaymentGateways.defaultProps = {
    configuration: [],
    selected: []
};

ConfigurePaymentGateways.propTypes = {
    operatorId: PropTypes.number.isRequired,
    configuration: PropTypes.array,
    selected: PropTypes.array

};

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({

    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ConfigurePaymentGateways);
export { hoc as ConfigurePaymentGateways };