import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditableTable } from '../../Controls/Table/Editable/EditableTable';
import { Card } from '../../Centrum/Card/Card';
import { Button } from '../../Controls/Buttons/Button';
import { application, Endpoints } from '../../Application/Application';
import { lang } from '../../Centrum/Localization/lang';
import { Tab } from '../../Centrum/Tab/Tab';

export const SetupEvents = (props) => {
    const [category, selectCategory] = useState(null);
    const [regions, selectRegions] = useState(null);
    const [region, selectRegion] = useState(null);
    const [leagues, updateLeagues] = useState(null);
    const [league, selectLeague] = useState(null);
    const [events, updateEvents] = useState(null);
    const [weights, setWeights] = useState(null);
    const [searchResults, setSearchResults] = useState(null);

    var timeout = null;
    var search = null;

    useEffect(() => {
        var categories = props.categories.filter(x => x.enabled);
        selectCategory(categories[0]);
    }, []);

    useEffect(() => {
        if (!category) return;
        application().resolve(null, `${Endpoints.SportRegions}/${category.id}`).then((result) => {
            result.result.key = Date.now();
            selectRegions(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [category]);

    useEffect(() => {
        if (!regions) return;
        selectRegion(regions[0]);
    }, [regions]);


    useEffect(() => {
        if (!category) return;
        if (!region) return;
        application().resolve(null, `${Endpoints.SportLeagues}/${category.id}/${region.id}`).then((result) => {
            result.result.key = Date.now();
            updateLeagues(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [region]);


    useEffect(() => {
        if (!leagues) return;
        selectLeague(leagues[0]);
    }, [leagues]);



    useEffect(() => {
        if (!category) return;
        if (!region) return;
        if (!league) return;
        application().resolve(null, `${Endpoints.SportEvents}`, { regionId: region.id, leagueId: league.id }).then((result) => {
            result.result.key = Date.now();
            application().resolve(null, Endpoints.SetupSportEvents).then((result) => {
                try {
                    setWeights(JSON.parse(result.result));
                } catch (err) {
                    //
                }
            });
            updateEvents(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [league]);

    useEffect(() => {
        if (!weights) return;

        events.map(x => {
            if (weights.hasOwnProperty(x.id)) {
                x.weight = weights[x.id].weight;
                x.enabled = weights[x.id].enabled;
            }
        });


        var sorted = Object.assign([], events).sort((b, a) => { return a.weight - b.weight; });
        sorted.key = Date.now();
        updateEvents(sorted);
    }, [weights]);


    const save = () => {
        var data = Object.assign({}, weights);
        events.map((x) => {
            data[x.id] = {
                id: x.id,
                enabled: x.enabled,
                name: x.name,
                date: x.date,
                sport: x.sport,
                league: x.league,
                region: x.region,
                weight: x.weight
            };
        });

        if (searchResults) {
            searchResults.map((x) => {
                data[x.id] = {
                    id: x.id,
                    enabled: x.enabled,
                    name: x.name,
                    date: x.date,
                    sport: x.sport,
                    league: x.league,
                    region: x.region,
                    weight: x.weight
                };
            });
        }


        Object.keys(data).map(key => {
            if (data[key].enabled) delete data[key];
        });

        application().resolve(null, Endpoints.SaveSetupSportEvents, data).then(() => {
            application().snackbar.open('Event entities saved.');
        });
    };

    if (!props.categories) return <div></div>;
    if (!category) return <div />;
    if (!regions) return <div />;
    if (!leagues) return <div />;
    const tabButtons = [
        {
            name: 'sports',
            title: 'Tree'
        },
        {
            name: 'search',
            title: 'Search'
        },
        {
            name: 'disabled',
            title: 'Disabled Events'
        }
    ];

    const searchEvents = (value) => {
        search = value;
        if (value == '') {
            setSearchResults(null);
            return;
        }
        application().resolve(null, Endpoints.SearchSportEvents + '/' + value, {}, 'searching events', 'unable to fetch events').then((result) => {
            result.result.key = Date.now();
            setSearchResults(result.result);
        });
    };

    return <Card caption='Manage Events' menu={<Button title='Save' onClick={() => save()} />}>
        <Tab buttons={tabButtons}>
            <Card caption='Manage Events'>
                <div className='padding centrum-flex'>
                    <div className='centrum-flex vertical'>
                        <label>{lang('Sports')}</label>
                        <select defaultValue={category.id} onChangeCapture={(e) => {
                            selectCategory(props.categories.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {props.categories.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                    </div>
                    <div className='centrum-flex vertical'>
                        <label>{lang('Regions')}</label>
                        <select defaultValue={region ? region.id : 0} onChangeCapture={(e) => {
                            selectRegion(regions.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {regions.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                    </div>
                    <div className='centrum-flex vertical'>
                        <label>{lang('Leagues')}</label>
                        <select defaultValue={league ? league.id : 0} onChangeCapture={(e) => {
                            selectLeague(leagues.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {leagues.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                    </div>
                </div>
                {events && <EditableTable
                    key={events.key}
                    draggable={true}
                    model={{
                        fields: [
                            {
                                name: 'id',
                                display: 'Id'
                            },
                            {
                                name: 'name',
                                display: 'Name'
                            },
                            {
                                name: 'date',
                                display: 'Date',
                                formatter: (val) => {
                                    return new Date(val.date + 'Z').toLocaleString('en-Gb', { hours12: false });
                                }
                            },
                            {
                                name: 'enabled',
                                display: 'Enabled',
                                type: 'bool',
                                editable: true
                            }
                        ]
                    }} data={events} onDataChange={(data) => updateEvents(data)} />
                }
            </Card>
            <Card caption='Search Events'>
                <div className='padding centrum-flex'>
                    <div className='centrum-flex vertical'>
                        <label>{lang('Event name or id')}</label>
                        <input type='text' onChangeCapture={(e) => {
                            const value = e.target.value;
                            if (!value) return;
                            if (value.length < 3 || value == '') {
                                searchEvents('');
                                clearTimeout(timeout);
                                return;
                            }

                            if (search && search.indexOf(value) > 0) {
                                clearTimeout(timeout);
                                return;
                            }

                            search = value;
                            clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                searchEvents(value);
                            }, 500);
                        }} />
                    </div>
                </div>
                {searchResults && <EditableTable
                    key={searchResults.key}
                    model={{
                        fields: [
                            {
                                name: 'id',
                                display: 'Id'
                            },
                            {
                                name: 'date',
                                display: 'Date',
                                formatter: (val) => {
                                    return new Date(val.date + 'Z').toLocaleString('en-Gb', { hours12: false });
                                }
                            },
                            {
                                name: 'sport',
                                display: 'Sport'
                            },
                            {
                                name: 'region',
                                display: 'Region'
                            },
                            {
                                name: 'league',
                                display: 'League'
                            },
                            {
                                name: 'name',
                                display: 'Name'
                            },
                            {
                                name: 'enabled',
                                display: 'Enabled',
                                type: 'bool',
                                editable: true
                            }
                        ]
                    }} data={searchResults} onDataChange={(data) => setSearchResults(data)} />
                }
            </Card>
            <Card caption='Disabled Events'>
                {weights && <EditableTable
                    key={weights.key}
                    model={{
                        fields: [
                            {
                                name: 'id',
                                display: 'Id'
                            },
                            {
                                name: 'date',
                                display: 'Date',
                                formatter: (val) => {
                                    return new Date(val.date + 'Z').toLocaleString('en-Gb', { hours12: false });
                                }
                            },
                            {
                                name: 'sport',
                                display: 'Sport'
                            },
                            {
                                name: 'region',
                                display: 'Region'
                            },
                            {
                                name: 'league',
                                display: 'League'
                            },
                            {
                                name: 'name',
                                display: 'Name'
                            },
                            {
                                name: 'enabled',
                                display: 'Enabled',
                                type: 'bool',
                                editable: true
                            }
                        ]
                    }} data={Object.values(weights)} onDataChange={(data) => setWeights(data)} />
                }
            </Card>
        </Tab>
    </Card>;
};


SetupEvents.propTypes = {
    categories: PropTypes.array
};