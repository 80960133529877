import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Card } from '../../../Centrum/Card/Card';
import { Search } from '../../../Centrum/Search/Search';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { SportsTicketStatus, TestAccountFilter } from '../../../Centrum/_models/ModelEnums';
import { currency } from '../../../Centrum/Localization/lang';
import { apiResult, Endpoints } from '../../../Application/Application';
import { ActionTypes, getAction } from '../../../Centrum/Common/ActionManager';
import { getHistory } from '../../../routers/AppRouter';
import { bonusPlayer, rejectPendingPromotion } from '../../Players/actions';
import { openBetsOfTicket } from '../../RiskManagement/models';




const searchModel = {
    fields: [
        {
            name: 'Username',
            type: InputTypes.text,
            hidden: true
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'Status',
            display: 'Ticket Status',
            acceptEmptyValues: true,
            type: InputTypes.select,
            values: SportsTicketStatus
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};

const resultModel = {
    fields: [
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'PromotionName',
            display: 'Promotion'
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'Username'
        },
        {
            name: 'PlayerCode',
            display: 'Player Code'
        },
        {
            name: 'Group',
            display: 'Player Group'
        },
        {
            name: 'TicketId',
            display: 'Ticket Id',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return openBetsOfTicket(val, {
                    Product:0,
                    ExternalTicketId: val,
                    TicketType: 0
                });
            }
        },
        {
            name: 'Bet',
            display: 'Bet',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'Odds',
            display: 'Odds'
        },
        {
            name: 'PossibleWin',
            display: 'PossibleWin',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'Status',
            display: 'Status',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return SportsTicketStatus[val];
            }
        },
        {
            name: 'Balance',
            display: 'Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'BonusBalance',
            display: 'Bonus Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        }
    ]
};

export const SlipLotteryTicketsPage = () => {
    const data = useSelector((state) => state.data);
    const [lastQuery, setLastQuery] = useState(null);
    useEffect(() => {
    }, []);

    const onActionClicked = (action, items) => {
        var player = items[0];
        switch (action.type) {
            case ActionTypes.PlayerProfile:
                var location = `/Player/playerShortcuts/profile//${player.PlayerId}`;
                getHistory().push({
                    pathname: location,
                    state: { id: player.Id, profile: true }
                });
                break;
            case ActionTypes.DepositBonus:
                bonusPlayer({ playerId: player.PlayerId, operatorId: player.OperatorId, username: player.Username, currency: player.Currency, balance: player.Balance, transactionType: 0, selectedPromotion: player.PromotionId }, () => {
                    //
                    search(null, lastQuery);
                });
                break;
            case ActionTypes.RejectPendingPromotion:
                rejectPendingPromotion({ playerId: player.PlayerId, operatorId: player.OperatorId, id: player.PromotionId }, () => {
                    //
                    search(null, lastQuery);
                });
                break;
        }

    };

    const search = (data, qb) => {
        setLastQuery(qb);
        return apiResult('searchSlipLotteryTickets',
            Endpoints.SearchSlipLotteryTickets, qb, 'fetching reports', 'unable to fetch report');
    };

    return <Card caption='Pending promotions'>
        <Search
            multipleSelect={true}
            model={searchModel}
            resultModel={resultModel}
            onActionClicked={onActionClicked.bind(this)}
            actions={getAction(ActionTypes.SearchSlipLotteryTickets).convertToItems()}
            data={data.searchSlipLotteryTickets}
            exportToExcel={true}
            exportAllData={true}
            onSubmit={(data, qb) => {
                return search(data, qb);
            }}
        />
    </Card>;
};


