import { Endpoints }  from '../../components/Application/Application';

import { 
    getOperator,newOperator,
    updateOperator,
    getOperatorUser,newOperatorUser,
    updateOperatorUser} from '../services/OperatorService';
import { getData, postData } from '../services/ServiceHelpers';


export const NEW_OPERATOR             = 'NEW_OPERATOR';
export const NEW_OPERATOR_PENDING     = 'NEW_OPERATOR_PENDING';
export const NEW_OPERATOR_FULFILLED   = 'NEW_OPERATOR_FULFILLED';
export const NEW_OPERATOR_REJECTED    = 'NEW_OPERATOR_REJECTED';


export const GET_OPERATOR             = 'GET_OPERATOR';
export const GET_OPERATOR_PENDING     = 'GET_OPERATOR_PENDING';
export const GET_OPERATOR_FULFILLED   = 'GET_OPERATOR_FULFILLED';
export const GET_OPERATOR_REJECTED    = 'GET_OPERATOR_REJECTED';


export const UPDATE_OPERATOR             = 'UPDATE_OPERATOR';
export const UPDATE_OPERATOR_PENDING     = 'UPDATE_OPERATOR_PENDING';
export const UPDATE_OPERATOR_FULFILLED   = 'UPDATE_OPERATOR_FULFILLED';
export const UPDATE_OPERATOR_REJECTED    = 'UPDATE_OPERATOR_REJECTED';



export const SEARCH_OPERATOR_USERS               = 'SEARCH_OPERATOR_USERS';
export const SEARCH_OPERATOR_USERS_PENDING       = 'SEARCH_OPERATOR_USERS_PENDING';
export const SEARCH_OPERATOR_USERS_FULFILLED     = 'SEARCH_OPERATOR_USERS_FULFILLED';
export const SEARCH_OPERATOR_USERS_REJECTED      = 'SEARCH_OPERATOR_USERS_REJECTED';

export const SEARCH_OPERATOR_USER_ACTIVITIES               = 'SEARCH_OPERATOR_USER_ACTIVITIES';
export const SEARCH_OPERATOR_USER_ACTIVITIES_PENDING       = 'SEARCH_OPERATOR_USER_ACTIVITIES_PENDING';
export const SEARCH_OPERATOR_USER_ACTIVITIES_FULFILLED     = 'SEARCH_OPERATOR_USER_ACTIVITIES_FULFILLED';
export const SEARCH_OPERATOR_USER_ACTIVITIES_REJECTED      = 'SEARCH_OPERATOR_USER_ACTIVITIES_REJECTED';


export const NEW_OPERATOR_USER             = 'NEW_OPERATOR_USER';
export const NEW_OPERATOR_USER_PENDING     = 'NEW_OPERATOR_USER_PENDING';
export const NEW_OPERATOR_USER_FULFILLED   = 'NEW_OPERATOR_USER_FULFILLED';
export const NEW_OPERATOR_USER_REJECTED    = 'NEW_OPERATOR_USER_REJECTED';


export const GET_OPERATOR_USER             = 'GET_OPERATOR_USER';
export const GET_OPERATOR_USER_PENDING     = 'GET_OPERATOR_USER_PENDING';
export const GET_OPERATOR_USER_FULFILLED   = 'GET_OPERATOR_USER_FULFILLED';
export const GET_OPERATOR_USER_REJECTED    = 'GET_OPERATOR_USER_REJECTED';


export const UPDATE_OPERATOR_USER             = 'UPDATE_OPERATOR_USER';
export const UPDATE_OPERATOR_USER_PENDING     = 'UPDATE_OPERATOR_USER_PENDING';
export const UPDATE_OPERATOR_USER_FULFILLED   = 'UPDATE_OPERATOR_USER_FULFILLED';
export const UPDATE_OPERATOR_USER_REJECTED    = 'UPDATE_OPERATOR_USER_REJECTED';



export const GET_OPERATOR_USER_GROUPS               = 'GET_OPERATOR_USER_GROUPS';
export const GET_OPERATOR_USER_GROUPS_PENDING       = 'GET_OPERATOR_USER_GROUPS_PENDING';
export const GET_OPERATOR_USER_GROUPS_FULFILLED     = 'GET_OPERATOR_USER_GROUPS_FULFILLED';
export const GET_OPERATOR_USER_GROUPS_REJECTED      = 'GET_OPERATOR_USER_GROUPS_REJECTED';





// ACTION CREATORS

function newOperatorAction(query) {
    return {
        type: NEW_OPERATOR,
        payload:newOperator(query)
    };
}



function getOperatorAction(id) {
    return {
        type: GET_OPERATOR,
        payload: getOperator(id)
    };
}

function updateOperatorAction(id) {
    return {
        type: UPDATE_OPERATOR,
        payload: updateOperator(id)
    };
}




function newOperatorUserAction(query) {
    return {
        type: NEW_OPERATOR_USER,
        payload:newOperatorUser(query)
    };
}


export function searchOperatorUsers(data) {
    return {
        type: SEARCH_OPERATOR_USERS,
        payload: postData(data,Endpoints.OperatorUsersSearch)
    };
}

export function searchOperatorUserActivities(data) {
    return {
        type: SEARCH_OPERATOR_USER_ACTIVITIES,
        payload: postData(data,Endpoints.OperatorUsersActivity)
    };
}



function getOperatorUserAction(id) {
    return {
        type: GET_OPERATOR_USER,
        payload: getOperatorUser(id)
    };
}

function updateOperatorUserAction(id) {
    return {
        type: UPDATE_OPERATOR_USER,
        payload: updateOperatorUser(id)
    };
}





function getOperatorUserGroupsAction(){
    return {
        type: GET_OPERATOR_USER_GROUPS,
        payload:getData(Endpoints.OperatorGetUserGroups)
    };
}




// EXPORT ACTIONS
export { newOperatorAction as newOperator };
export { getOperatorAction as getOperator };
export { updateOperatorAction as updateOperator };

export { newOperatorUserAction as newOperatorUser };
export { updateOperatorUserAction as updateOperatorUser };
export { getOperatorUserAction as getOperatorUser };
export { getOperatorUserGroupsAction as getOperatorUserGroups };