// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Search } from '../Centrum/Search/Search';

import { Page } from '../Centrum/Page/Page';
import { searchProviders } from '../../state/actions/ProviderActions';

import { getActions, ActionTypes, getAction } from '../Centrum/Common/ActionManager';
import { CentrumApplication, ApplicationContext, CentrumApplicationContents, CentrumApplicationActions } from '../Centrum/Application/CentrumApplication';
import { lang } from '../Centrum/Localization/lang';

const applicationActionType = ActionTypes.Providers;
const applicationActions = getActions(applicationActionType);



// COMPONENT
class ProvidersPage extends Page {
    constructor(props) {
        super(props);
        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.modal = null;
        state.action = action;

        this.state = state;
        this.applicationContext = null;
    }

    onActionClicked(action, items) {
        var item = items[0];
        switch (action.type) {
            case ActionTypes.ActivateDeactivateProvider:
                this.applicationContext.confirmActionAndPost(item, 'providerInformation', '/api/provider/toggleProviderStatus/' + item.Id, null, 'col2', action.caption, 'Operation Completed', true);
                break;
        }
    }

    onRenderActions(action, items) {
        var item = items[0];
        if (item === undefined) return action;
        return item.Enabled ? { caption: 'Disable Provider', icon: 'disable' } : { caption: 'Enable Provider', icon: 'enable' };
    }

    render() {
        return (
            <CentrumApplication caption='Providers' icon='industry' type={applicationActionType} loading={this.props.loading}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <Page>
                                        <Search
                                            caption={lang('Select Providers')}
                                            model='searchProviders'
                                            resultModel='operatorProviderInformation'
                                            data={this.props.providers}
                                            onSubmit={(data, qb) => this.props.searchProviders(qb)}
                                            actions={getAction(ActionTypes.SearchProviders).convertToItems()}
                                            onRenderAction={this.onRenderActions.bind(this)}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                        />
                                    </Page>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

ProvidersPage.defaultProps = {
    error: null,
};

ProvidersPage.propTypes = {
    providers: PropTypes.object,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    failed: PropTypes.bool,
    error: PropTypes.object
};


const mapStateToProps = state => {
    return {
        providers: state.providers.providers,
        loading: state.providers.loading,
        loaded: state.providers.loaded,
        failed: state.providers.failed,
        error: state.providers.error
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        searchProviders: searchProviders
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ProvidersPage);
export { hoc as ProvidersPage };