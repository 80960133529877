import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { ShopTypes, ShopTransactionTypes } from './ModelEnums';
import { currency } from '../Localization/lang';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';

export const ShopsModel = {
    newShop: {
        fields: [
            {
                name:'Name',
                display:'Name',
                type: InputTypes.text
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ShopTypes
            },
            {
                name: 'Commission',
                display:'Commission',
                type: InputTypes.number
            },
            {
                name:'Description',
                display:'Description',
                type: InputTypes.text
            },
            {
                name:'Enabled',
                display:'Enabled',
                type: InputTypes.bool
            }
        ]
    },
    editShop: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly: true,
                identity: true,
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ShopTypes
            },
            {
                name: 'Commission',
                display:'Commission',
                type: InputTypes.number
            },
            {
                name: 'Description',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                max: 128
            }
        ]
    },
    searchShops: {
        fields: [
            {
                name: 'OperatorId',
                display: 'Operators',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },  
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ShopTypes,
                acceptEmptyValues:true
            },            
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true,
                max: 128
            }
        ]
    },
    shopSearchResult: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name:'EntryDate',
                display:'Date',
                type:InputTypes.DateTime
            },
            {
                name: 'Operator',
                display:'Operator',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ShopTypes
            },
            {
                name: 'Commission',
                display:'Commission',
                type: InputTypes.number
            },
            {
                name: 'Balance',
                display:'Balance',
                type: InputTypes.number
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                max: 128
            }
        ]
    },
    searchShopTransactions: {
        fields: [
            {
                name: 'ShopId',
                display: 'Shop',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'shops',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },  
            {
                name: 'EntryDate',
                display:'Transaction Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data:DateRanges['This Month']
                }
            },
            {
                name: 'OperatorUserId',
                display: 'Admin',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myOperatorUsers',
                    bindingKey: 'Id',
                    bindingText: ['Email', 'Name', 'Surname']
                }
            },
            {
                name: 'Username',
                display:'Player',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ShopTransactionTypes,
                acceptEmptyValues:true
            }
        ]
    },
    shopTransactionResult: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name:'EntryDate',
                display:'Date',
                type:InputTypes.DateTime
            },
            {
                name: 'OpeartorUser',
                display:'Admin',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Username',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ShopTypes
            },
            {
                name: 'Notes',
                display:'Notes',
                type: InputTypes.text
            },
            {
                name: 'Amount',
                display:'Amount',
                type: InputTypes.number
            },
            {
                name: 'Balance',
                display:'Balance',
                type: InputTypes.number
            }
        ]
    },
    shopSummary: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ShopTypes
            },
            {
                name: 'Commission',
                display:'Commission',
                type: InputTypes.number
            },
            {
                name: 'Description',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Manager',
                type: InputTypes.string,
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                max: 128
            }
        ]
    },
    shopFinancialSummary: {
        fields: [
            {
                name: 'balance',
                display: 'Balance',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'deposits',
                display: 'Pending Deposits',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'withdraws',
                display: 'Pending Deposits',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            }
        ]
    },
};

