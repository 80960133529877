import React from 'react';
import PropTypes from 'prop-types';
import { FormInput } from '../../Form/FormInput';


export const EditableRow = (props) => {
    const onChange = (context, field, value) => {
        props.row[props.field.name] = value;
        props.onChange();
    };

    if (props.readonly) {
        return props.row[props.field.name];
    }
    return <td>
        <FormInput type={props.field.type} name={props.field.name} value={props.row[props.field.name]} onChange={onChange.bind(this)} />
    </td>;
};

EditableRow.propTypes = {
    field: PropTypes.object,
    row: PropTypes.object,
    readonly: PropTypes.bool,
    onChange: PropTypes.func
};
