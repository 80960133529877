import './_bonusComponents.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API, PlayerEndpoints } from '../../../../v2/Lib/Api/Api';
import { lang } from '../../../../v2/Lib/Common/language';
import { Icon } from '../../../../v2/Components/Icon/Icon';
import { FormInput, InputTypes } from '../../../../v2/Components/Controls/Form/FormInput';
import { Card } from '../../../../v2/Components/Card/Card';

export const BonusPlayerSettings = (props) => {
    const [promotion, setPromotion] = useState(props.promotion);
    const [playerGroups, setPlayerGroups] = useState([]);

    const updatePromotion = (target, value) => {
        if (!target) {
            setPromotion(value);
            props.onChange(value);
            return;
        }
        var copy = { ...promotion };
        copy[target] = value;
        setPromotion(copy);
        props.onChange(copy);
    };


    useEffect(() => {
        API.post(PlayerEndpoints.GetPlayerGroups, null).then((result) => {
            var temp = [];
            result.result.filter(x => !x.Enabled).map(x => {
                temp.push({
                    name: x.Name,
                    id: x.Id,
                    enabled: true
                });
            });

            if (promotion.DisabledPlayerGroups) {
                temp.map(x => {
                    if (promotion.DisabledPlayerGroups.find(y => y.id == x.id)) {
                        x.enabled = false;
                    }
                });
            }
            setPlayerGroups(temp);
        });
    }, []);



    return <div className='flex vertical gap-10 bonus-general-settings'>
        <form className='centrum-form'>
            <div className='row'>
                <div className='form-group'>
                    <label className='no-wrap'>{lang('Only verified players can use the bonus')}</label>
                    <Icon icon={promotion.OnlyVerifiedUsers ? 'check-square' : 'square'} type='far' onClick={() => {
                        updatePromotion('OnlyVerifiedUsers', !promotion.OnlyVerifiedUsers);
                    }} />
                </div>
                <FormInput title='Total Players' value={promotion.TotalPlayers} onChange={(f, r, element) => {
                    updatePromotion('TotalPlayers', parseFloat(element.value));
                }} />
            </div>
            <div className='row'>
                <Card title='Only Allowed Player Groups' buttons={() => <FormInput type={InputTypes.Bool} value={promotion.OnlyAllowedPlayerGroups} onChange={(f, r, element) => {
                    updatePromotion('OnlyAllowedPlayerGroups', element.value);
                }} />}>
                    {
                        promotion.OnlyAllowedPlayerGroups && <div className='list'>
                            {
                                playerGroups.map(x => {
                                    return <div className={`flex gap-5 align-start padding surface low align-stretch${x.enabled ? ' active' : ''}`} key={x} onClick={() => {
                                        var copy = [...playerGroups];
                                        copy.find(y => y.name == x.name).enabled = !copy.find(y => y.name == x.name).enabled;
                                        setPlayerGroups(copy);

                                        updatePromotion('DisabledPlayerGroups', copy);
                                    }}>
                                        <span>{x.name}</span>
                                        <div className='flex align-right'>
                                            <Icon icon={x.enabled ? 'check-square' : 'square'} type='far' />
                                        </div>
                                    </div>;
                                })
                            }
                        </div>
                    }
                </Card>
            </div>
        </form>
    </div>;

};

BonusPlayerSettings.propTypes = {
    promotion: PropTypes.object,
    onChange: PropTypes.func
};