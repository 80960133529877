import './_datetimepicker.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { getDaysInMonth, addMonths, addHours } from 'date-fns';
import { icons } from '../../../Shared';
import { lang } from '../../../Localization/lang';
import { addDays, addMinutes, startOfWeek, startOfMonth, startOfYear } from 'date-fns/esm';
import { Icon } from '../../../../Controls/Icon/Icon';


const daysByName = [
    'Su',
    'Mo',
    'Tu',
    'We',
    'Th',
    'Fr',
    'Sa'
];

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


export const DateRanges = {
    'Today': 0,
    'Yesterday': 1,
    'This Week': 2,
    'Last Week': 3,
    'Two Weeks': 4,
    'This Month': 5,
    'Last Month': 6,
    'Last 3 Months': 7,
    'Last 6 Months': 8,
    'This Year': 9,
    'Last Hour': 10,
    'Min': 11,
    'Custom': 13,
};


export const today = () => {
    var dt = new Date();
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0);
};

export const endOfDay = (date) => {
    var dt = new Date(date);
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59);
};


export const formatDate = (date, hours = true) => {
    date = new Date(date);
    if (hours) {
        //timeZone: 'UTC'
        var res = date.toLocaleDateString('en-Gb');
        var time = date.toLocaleTimeString('en-Gb', { hour12: false, hour: '2-digit', minute: '2-digit' });
        time = time.replace('24:', '00:');
        return res + ' ' + time;
    }
    return date.toLocaleString();
};


export const getRange = (range) => {
    var min = new Date();
    var max = new Date();
    switch (range) {
        case DateRanges.Today:
            min = today();
            max = endOfDay(min);
            break;
        case DateRanges.Yesterday:
            min = addDays(today(), -1);
            max = endOfDay(min);
            break;
        case DateRanges['This Week']:
            min = startOfWeek(new Date());
            max = endOfDay(new Date());
            break;
        case DateRanges['Last Week']:
            min = startOfWeek(addDays(new Date(), -7));
            max = addDays(min, 7);
            break;
        case DateRanges['Two Weeks']:
            min = startOfWeek(addDays(new Date(), -14));
            max = addDays(min, 14);
            break;
        case DateRanges['This Month']:
            min = startOfMonth(new Date());
            max = endOfDay(new Date());
            break;
        case DateRanges['Last Month']:
            min = startOfMonth(addMonths(new Date(), -1));
            max = startOfMonth(new Date());
            break;
        case DateRanges['Last 3 Months']:
            min = startOfMonth(addMonths(new Date(), -3));
            max = startOfMonth(new Date());
            break;
        case DateRanges['Last 6 Months']:
            min = startOfMonth(addMonths(new Date(), -3));
            max = startOfMonth(new Date());
            break;
        case DateRanges['This Year']:
            min = startOfYear(new Date());
            max = endOfDay(new Date());
            break;
        case DateRanges['Min']:
            min = new Date(2018, 1, 1);
            max = endOfDay(new Date());
            break;
        case DateRanges['Last Hour']:
            min = addHours(new Date(), -1);
            max = new Date();
            break;
        default:
            min = today();
            max = endOfDay(min);
            break;
    }

    /*
    var offset = new Date().getTimezoneOffset() / 60 * -1;
    min = addHours(min, offset);
    max = addHours(max, offset);
    */
    return {
        min: min,
        max: max,
        formated: {
            min: formatDate(min),
            max: formatDate(max)
        }
    };
};



export class DateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        Object.assign(this.state, props);

        if (this.state.date === null) this.state.date = new Date();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.hours = 0;
        this.minutes = 0;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ show: false });
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    openPicker() {
        this.setState({ show: true });
    }


    renderDayHeaders() {
        return daysByName.map(day => {
            return <div className='day-header' key={'day_' + day}>{day}</div>;
        });
    }

    selectDate(date) {
        this.setState({ date: new Date(date.year, date.month, date.day, this.hours, this.minutes) });
        if (this.props.onPicked === null) return;
        this.props.onPicked(new Date(date.year, date.month, date.day, this.hours, this.minutes).toString());
    }

    goToDate(date) {
        if (date.hours !== undefined && !isNaN(date.hours) && (date.hours >= 0 && date.hours < 24)) {
            this.hours = date.hours;
        }

        if (date.minutes !== undefined && !isNaN(date.minutes) && (date.minutes >= 0 && date.minutes < 60)) {
            this.minutes = date.minutes;
        }

        this.setState({ date: new Date(date.year, date.month, date.day, this.hours, this.minutes), show: true });
    }

    renderDays() {
        let currentMonthIndex = this.state.date.getMonth();
        let year = this.state.date.getFullYear();
        let firstDayIndexOfMonth = (new Date(year, currentMonthIndex, 1)).getDay();
        let daysInSelectedMonth = getDaysInMonth(new Date(year, currentMonthIndex));
        let i = 0;



        // Render Previous Month
        let arr = [];
        // First day is not sunday, so push the array with previous month days until 1st of selected date
        // If day is first day then add 7 days from the previous month
        if (firstDayIndexOfMonth === 0) firstDayIndexOfMonth = 7;
        if (firstDayIndexOfMonth > 0) {
            let previousMonthIndex = currentMonthIndex - 1;
            let targetYear = year;
            if (previousMonthIndex < 0) {
                targetYear--;
            }
            let numberOfDaysInPreviousMonth = getDaysInMonth(new Date(targetYear, previousMonthIndex));
            let start = numberOfDaysInPreviousMonth - (firstDayIndexOfMonth);
            start++;
            for (i = start; i <= numberOfDaysInPreviousMonth; i++) {
                arr.push({ day: i, month: previousMonthIndex, year: targetYear });
            }
        }


        let currentDay = this.state.date.getDate();

        // Render current Month
        for (i = 1; i <= daysInSelectedMonth; i++) {
            let selected = false;
            if (i === currentDay) selected = true;
            arr.push({ day: i, month: currentMonthIndex, selected: selected, year: year });
        }

        let lastDayIndex = (new Date(year, currentMonthIndex, getDaysInMonth(new Date(year, currentMonthIndex)))).getDay();
        let addMoreDays = 6 - lastDayIndex;
        addMoreDays += 7;



        let nextMonthIndex = currentMonthIndex + 1;
        let targetYear = year;
        if (nextMonthIndex > 11) {
            nextMonthIndex = 0;
            targetYear++;
        }

        // Render Next Month
        for (i = 1; i <= addMoreDays; i++) {
            arr.push({ day: i, month: nextMonthIndex, year: targetYear });
        }

        lastDayIndex = i;


        return arr.map(day => {
            return <div key={(day.day + '_' + day.month + '_' + day.year)}
                onClick={() => this.selectDate(day)}
                className={day.selected ? 'selected' : (day.month === currentMonthIndex ? 'selected-month-days' : 'previous-next-month-days')}>{day.day}</div>;
        });
    }

    previousMonth() {
        let date = addMonths(this.state.date, -1);
        this.setState({ date: date });
    }

    nextMonth() {
        let date = addMonths(this.state.date, 1);
        this.setState({ date: date });
    }


    selectDay(range, e) {
        e.preventDefault();
        var dt = this.state.date;
        let newDate = dt;
        if (range === 0) {
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth(); //January is 0!
            var yyyy = today.getFullYear();

            newDate = new Date(yyyy, mm, dd, this.hours, this.minutes, 0);
            this.setState({ date: newDate, show: false });
            if (this.props.onPicked === null) return;
            this.props.onPicked(newDate);
        } else {
            newDate = addDays(dt, range);
            this.setState({ date: newDate, show: false });
            if (this.props.onPicked === null) return;
            this.props.onPicked(newDate);
        }
    }

    selectMonth(range, e) {
        e.preventDefault();
        let date = addMonths(this.state.date, range);
        this.setState({ date: date, show: false });
        this.props.onPicked(date);
    }

    selectTime(hours, minutes) {
        let date = this.state.date;
        date = addHours(date, hours);
        if (minutes !== undefined) {
            date = addMinutes(date, minutes);
        }

        //this.setState({ date: date, show: false });
        this.props.onPicked(date);
    }


    changeHours(e) {
        var value = parseInt(e.target.value);
        if (isNaN(value)) return;
        if (value < 0) return;
        if (value > 24) return;
        this.hours = value;




        var date = this.state.date;
        var dd = date.getDate();
        var mm = date.getMonth(); //January is 0!
        var yyyy = date.getFullYear();

        this.props.onPicked(new Date(yyyy, mm, dd, this.hours, this.minutes, 0));
    }

    changeMinutes(e) {
        var value = parseInt(e.target.value);
        if (isNaN(value)) return;
        if (value < 0) return;
        if (value > 59) return;
        this.minutes = value;

        var date = this.state.date;
        var dd = date.getDate();
        var mm = date.getMonth(); //January is 0!
        var yyyy = date.getFullYear();

        this.props.onPicked(new Date(yyyy, mm, dd, this.hours, this.minutes, 0));
    }

    renderTimeSelection() {
        return (
            <div className='time-selector'>
                <div className='hours'>
                    <label>{lang('Hours')}</label>
                    <input type='number' name='hours' min={0} max={24} size={2}
                        defaultValue={this.hours} onChange={this.changeHours.bind(this)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                return false;
                            }
                        }}
                    />
                </div>
                <span>:</span>
                <div className='minutes'>
                    <label>{lang('Minutes')}</label>
                    <input type='number' name='minutes' min={0} max={59} size={2}
                        defaultValue={this.minutes} onChange={this.changeMinutes.bind(this)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                return false;
                            }
                        }}
                    />
                </div>
            </div>
        );
    }


    render() {
        let month = months[this.state.date.getMonth()];
        return (
            <div ref={this.setWrapperRef} className='date-time-picker-container'>
                <div className='date-time-picker-icon' onClick={this.openPicker.bind(this)}>
                    <Icon icon={icons.calendar} />
                </div>
                <div className={'card date-time-picker ' + (this.state.show ? '' : 'hidden') + ' ' + (this.props.showTime ? ' show-time ' : '')}>
                    <h1><Icon icon={icons.arrowLeft} onClick={this.previousMonth.bind(this)} />{month + ' ' + this.state.date.getFullYear()}<Icon icon={icons.arrowRight} onClick={this.nextMonth.bind(this)} /></h1>
                    <div className='date-time-selections'>
                        <div className='date-time-picker-days'>
                            {this.renderDayHeaders()}
                            {this.renderDays()}
                        </div>
                        {this.props.showTime && this.renderTimeSelection()}
                    </div>
                    <div className='date-time-picker-shortcuts'>
                        <select name='select' ref={(r) => this.selectRef = r} onChangeCapture={() => {
                            var date = getRange(DateRanges[this.selectRef.value]).min;
                            var day = {
                                year: date.getFullYear(),
                                day: date.getDate(),
                                month: date.getMonth()
                            };
                            this.selectDate(day);
                        }}>
                            {
                                Object.keys(DateRanges).map(key => {
                                    return <option key={key} name={key}>{lang(key)}</option>;
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

DateTimePicker.defaultProps = {
    show: false,
    date: new Date(),
    showTime: true,
    onPicked: null
};

DateTimePicker.propTypes = {
    show: PropTypes.bool,
    date: PropTypes.instanceOf(Date),
    showTime: PropTypes.bool,
    onPicked: PropTypes.func
};