/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Panel } from '../../../Centrum/Card/Card';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Icon } from '../../../Controls/Icon/Icon';
import { Form } from '../../../Controls/Form/Form';
import { apiResult, application, Endpoints } from '../../../Application/Application';
import { currency, lang } from '../../../Centrum/Localization/lang';
import { Button } from '../../../Controls/Buttons/Button';
import { bonusPlayer, cancelTransaction, completeBonus, depositPlayer, openBonusBets, removeBonus, resetBalance, sendMessage, withdrawPlayer } from '../actions';
import { Table } from '../../../Controls/Table/Table';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Search } from '../../../Centrum/Search/Search';
import { TransactionTypes } from '../../../Centrum/_models/ModelEnums';
import { Tab } from '../../../Centrum/Tab/Tab';
import { getHistory } from '../../../routers/AppRouter';
import { toPascalCase } from '../../../Centrum/helpers';
import { PlayerProfilePage } from '../PlayerProfilePage';
import { Dialog } from '../../../Modal/Modals/Dialog/Dialog';



const renderTransactionId = (val, previousRow, context) => {
    return <a href='#' onClick={(e) => {
        e.preventDefault();
        context.showTransactions(previousRow, val);
    }}>{val}</a>;
};

const transactionModel = {
    fields: [
        {
            name: 'id', display: 'Id', formatter: (val, row, rows, context) => {
                var rowIndex = rows.findIndex(x => x == row);
                var previousRow = null;
                if (rowIndex > 0) {
                    previousRow = rows[rowIndex - 1];
                }
                return renderTransactionId(val, previousRow, context);
            }
        },
        { name: 'date', display: 'Date', type: InputTypes.dateTime },
        {
            name: 'bonus', display: 'Bonus',
            type: InputTypes.bool,
            formatter: (val, row) => {
                var bonus = row.bonus || row.source == 'Promotion';
                if (!bonus) return;
                return <div className='flex'><Icon icon='check-circle' size='1x' className='center' /></div>;
            }
        },
        {
            name: 'bonusWallet', display: 'Bonus Wallet',
            formatter: (val, row) => {
                if (!row.bonusWallet) return;
                return <div className='flex'><Icon icon='check-circle' size='1x' className='center' /></div>;
            }
        },
        { name: 'currency', display: 'Currency' },
        {
            name: 'amount', display: 'Amount', formatter: (val, row) => {
                return currency(val, 2, row.currency);
            }
        },
        { name: 'admin', display: 'Admin' }
    ]
};


const searchTransactions = {
    fields: [
        {
            name: 'Type',
            display: 'Type',
            type: InputTypes.select,
            values: TransactionTypes,
            acceptEmptyValues: true
        }
    ]
};


const PlayerShortcuts = (props) => {
    const [player, showPlayer] = useState(null);
    const [playerProfile, openProfile] = useState(null);
    const [activityAfterDeposit, setActivityAfterDeposit] = useState(null);

    const searchPlayer = (fields, loadProfile) => {
        if (fields.Code == '') return;
        if (fields.Id == '') return;

        application().resolve('playersForShortcuts', Endpoints.PlayerSummary, fields, 'please wait', 'unable to find player').then((result) => {
            if (Array.isArray(result.result)) {
                application().modal.open(<Dialog title={'Multiple players found'}>
                    <p>{lang('Please selet one of the players')}</p>
                    <table>
                        <tbody>
                            {
                                result.result.map(x => {
                                    return <tr key={x.id}>
                                        <td>{x.operator}</td>
                                        <td>{x.id}</td>
                                        <td>{x.username}</td>
                                        <td><Button title='Select' onClick={() => {
                                            searchPlayer({ Id: x.id });
                                        }} /></td>
                                    </tr>;
                                })}
                        </tbody>
                    </table>
                </Dialog>);
                return;
            }
            const profile = window.location.pathname.indexOf('profile') > 0 || loadProfile;
            getHistory().push(`/player/playerShortcuts/${profile ? 'profile/' : ''}${result.result.id}`);
            result.result.updateTime = Date.now();
            showPlayer(result.result);
        });
    };

    var tabRef = null;

    var history = null;

    useEffect(() => {
        var id = parseFloat(window.location.pathname.split('/').reverse()[0]);
        if (player == null && !isNaN(id)) {
            searchPlayer({ Id: id });
        }
        history = getHistory().listen((location) => {
            if (location.state) {
                if (player == null || location.state.id != player.id) {
                    searchPlayer({ Id: location.state.id }, location.state.profile);
                }
            }
        });
        () => history();
    }, []);

    useEffect(() => {
        if (!tabRef) return;
        if (player != null) {
            const loadPlayerProfile = location.pathname.indexOf('profile') > 0;
            if (loadPlayerProfile) loadProfile();
        }
    }, [player]);

    useEffect(() => {
        if (!tabRef) return;
        if (activityAfterDeposit != null) {
            tabRef.selectByKey('activity');
        }
    }, [activityAfterDeposit]);

    useEffect(() => {
        if (!tabRef) return;
        if (playerProfile != null) {
            tabRef.selectByKey('profile');
        }
    }, [playerProfile]);

    const context = {
        showTransactions: (previousRow, transactionId) => {
            var endTransactionId = Number.MAX_SAFE_INTEGER;
            if (previousRow != null) endTransactionId = previousRow.id;
            application().resolve(null, `${Endpoints.ShowActivityAfterDeposit}/${transactionId}/${endTransactionId}/${player.id}`, 'please wait', 'unable to fetch information').then((result) => {
                var data = result.result;
                data.transactionId = transactionId;
                data.endTransactionId = endTransactionId;
                setActivityAfterDeposit(data);
            });
        }
    };

    const loadProfile = () => {
        application().resolve(null, Endpoints.GetPlayer + player.id, {}, 'fetching player data', 'unable to fetch player data').then((data) => {
            getHistory().push(`/player/playerShortcuts/profile/${player.id}`);
            openProfile(toPascalCase(data.result));
        });
    };
    const playerSummary = () => {
        if (!player) return;


        var tabButtons = [
            {
                title: 'Transactions'
            },
            {
                title: 'Activities Between Deposits',
                hidden: activityAfterDeposit == null
            },
            {
                title: 'Profile',
                hidden: playerProfile == null
            }
        ];


        var perc = 0;
        if (player.consumedPromotionId > 0) {
            if (player.promotion.amount == 0) {
                perc = 0;
            } else {
                var turnoverMultiplier = player.promotion.turnoverMultiplier;
                var totalBet = player.promotion.totalBet;
                var max = turnoverMultiplier * player.promotion.amount;
                perc = (100 * totalBet) / max;
                if (totalBet > max) perc = 100;
                perc = parseFloat(perc.toFixed(2));

                if (isNaN(perc)) perc = 0;
            }
        }


        return <Card caption={'[' + player.operator + ']' + player.username} key={player.updateTime}>
            <div className='padding groups borders big'>
                <div className='group gap'>
                    <label>Id</label><span>{player.id}</span>
                    <label>Username</label><span>{player.username}</span>
                    <label>Name</label><span>{player.name} {player.surname}</span>
                    <label>Code</label><span>{player.code}</span>
                </div>
                <div className='group gap'>
                    <label>{lang('Bonus Balance')}</label><span>{currency(player.bonusBalance, 2, player.currency)}<Icon icon='times' className='marginLeft' size='xs' onClick={() => resetBalance(player, true, () => searchPlayer({ 'Id': player.id }))} /></span>
                    <label>{lang('Balance')}</label><span>{currency(player.balance, 2, player.currency)} <Icon icon='times' className='marginLeft' size='xs' onClick={() => resetBalance(player, false, () => searchPlayer({ 'Id': player.id }))} /></span>
                </div>
                {player.consumedPromotionId > 0 &&
                    <Card caption={player.activePromotion} menu={<div className='alignRight'>
                        {perc >= 100 && <Button title='Complete Bonus' onClick={() => completeBonus({ playerId: player.id, balance: player.balance, bonusBalance: player.bonusBalance, currency: player.currency, consumedPromotionId: player.consumedPromotionId, promotion: player.promotion }, () => searchPlayer({ 'Id': player.id }))} />}
                        <Button title='Remove Bonus' onClick={() => removeBonus(player, () => searchPlayer({ 'Id': player.id }))} />
                    </div>}>
                        <div className='group gap'>
                            <label>{lang('Bonus Amount')}</label><span>{currency(player.promotion.amount, 2, player.currency)}</span>
                            <label>{lang('Turnover Multiplier')}</label><span>{player.promotion.turnoverMultiplier}</span>
                            <label>{lang('Deduct Bonus')}</label><span>{player.promotion.deductBonusAmount}</span>
                        </div>
                        <div className='group gap'>
                            <label>{lang('Total Sport Bets')}</label><span><a href='#' onClick={() => openBonusBets(player.consumedPromotionId)}>{player.promotion.sportBets}</a></span>
                            <label>{lang('Total Stake')}</label><span>{currency(player.promotion.totalBet, 2, player.currency)}</span>
                            <label>{lang('Total Win')}</label><span>{currency(player.promotion.totalWin, 2, player.currency)}</span>
                        </div>
                        <div className='group'>
                            <label>{lang('Progress')}</label>
                            <span>%{perc}</span>
                        </div>
                        <div className='group'>
                            <div className='progress-bar good'>
                                <div className='progress' style={{ width: perc > 100 ? 100 : perc + '%' }} />
                            </div>
                        </div>
                    </Card>
                }
                {player.retailId > 0 &&
                    <div className='group gap warning'>
                        <label>Retail</label><span>{player.retailCode} {player.retailName}</span>
                        <label>{lang('Balance')}</label><span>{currency(player.retailBalance, 2, player.currency)}</span>
                    </div>
                }
                <div className='centrum-flex center'>
                    <div className='centrum-flex'>
                        <Button title='Deposit' onClick={() => {
                            depositPlayer({ playerId: player.id, username: player.username, currency: player.currency, balance: player.balance, transactionType: 0, bonus: false }, () => {
                                searchPlayer({ 'Id': player.id });
                            });
                        }} />
                        <Button title='Withdraw' onClick={() => {
                            withdrawPlayer({ playerId: player.id, username: player.username, currency: player.currency, balance: player.balance, transactionType: 1, bonus: false }, () => {
                                searchPlayer({ 'Id': player.id });
                            });
                        }} />
                        {
                            !player.activePromotion && <Button title='Give Bonus' onClick={() => {
                                bonusPlayer({ playerId: player.id, operatorId: player.operatorId, username: player.username, currency: player.currency, balance: player.balance, transactionType: 0 }, () => {
                                    searchPlayer({ 'Id': player.id });
                                });
                            }} />
                        }
                        {
                            <Button title='Cash Bonus' onClick={() => {
                                bonusPlayer({ playerId: player.id, operatorId: player.operatorId, username: player.username, currency: player.currency, balance: player.balance, transactionType: 0, bonusType: 8 }, () => {
                                    searchPlayer({ 'Id': player.id });
                                });
                            }} />
                        }
                        <Button title='Show Profile' onClick={() => {
                            loadProfile();
                        }} />
                        <Button title='Send Message' onClick={() => {
                            sendMessage({ playerId: player.id, username: player.username }, () => {

                            });
                        }} />
                        {
                            player.activePromotion && <Button title='Deposit Bonus' className='warning button' onClick={() => {
                                depositPlayer({ playerId: player.id, username: player.username, currency: player.currency, balance: player.balance, transactionType: 0, bonus: true }, () => {
                                    searchPlayer({ 'Id': player.id });
                                });
                            }} />
                        }
                        {
                            player.activePromotion && <Button title='Withdraw Bonus' className='warning button' onClick={() => {
                                withdrawPlayer({ playerId: player.id, username: player.username, currency: player.currency, balance: player.balance, transactionType: 1, bonus: true }, () => {
                                    searchPlayer({ 'Id': player.id });
                                });
                            }} />
                        }

                    </div>
                </div>
            </div>
            <Tab buttons={tabButtons} ref={(r) => tabRef = r}>
                <div className='centrum-flex padding auto-width'>
                    <Panel title='Last deposits' className='fit' buttons={
                        <div className='flex gap-10 align-right padding-right'>
                            <div className='flex gap-5 small'>
                                <label>{lang('Total')}</label>
                                <span>{player.depositCount}</span>
                            </div>
                            <div className='flex gap-5 small'>
                                <label>{lang('Amount')}</label>
                                <span>{currency(player.totalDeposit, 2, player.currency)}</span>
                            </div>
                        </div>
                    }>
                        {player.deposits && <Table key={player.deposits} data={player.deposits}
                            context={context}
                            model={transactionModel} buttons={(row) => {
                                if (row.status == 2) {
                                    return <span>{lang('Cancelled')}</span>;
                                }
                                return <Button title='Cancel' onClick={() => {
                                    cancelTransaction({ id: row.id }, () => {
                                        searchPlayer({ 'Id': player.id });
                                    });
                                }} />;
                            }} />}
                    </Panel>
                    <Panel title='Last withdraws' className='fit' buttons={
                        <div className='flex gap-10 align-right padding-right'>
                            <div className='flex gap-5 small'>
                                <label>{lang('Total')}</label>
                                <span>{player.withdrawCount}</span>
                            </div>
                            <div className='flex gap-5 small'>
                                <label>{lang('Amount')}</label>
                                <span>{currency(player.totalWithdraw, 2, player.currency)}</span>
                            </div>
                        </div>
                    }>
                        {player.withdraws && <Table context={context} key={player.withdraws} data={player.withdraws} model={transactionModel} buttons={(row) => {
                            if (row.status == 2) {
                                return <span>{lang('Cancelled')}</span>;
                            }
                            return <Button title='Cancel' onClick={() => {
                                cancelTransaction({ id: row.id }, () => {
                                    searchPlayer({ 'Id': player.id });
                                });
                            }} />;
                        }} />}
                    </Panel>
                </div>
                <React.Fragment key='activity'>
                    {activityAfterDeposit != null ? renderActivityAfterDeposit() : null}
                </React.Fragment>
                <React.Fragment key='profile'>
                    {playerProfile != null ? <PlayerProfilePage key='profile' id={player.id} profile={playerProfile} /> : null}
                </React.Fragment>
            </Tab>
        </Card>;
    };

    const renderActivityAfterDeposit = () => {
        if (!activityAfterDeposit) return;
        var summary = activityAfterDeposit.summary.length > 0 ? activityAfterDeposit.summary[0] : null;
        if (!summary) return;
        return <Card caption='Player activitiy after deposit' >
            <div className='centrum-flex padding center'>
                <Panel title='Sport' className='fit'>
                    <div className='group padding fit'>
                        <label>{lang('New Tickets')}</label><span>{summary.newSlips}</span>
                        <label>{lang('Winning Tickets')}</label><span>{summary.winSlips}</span>
                        <label>{lang('Stake')}</label><span>{currency(Math.abs(summary.sportStake), 2, player.currency)}</span>
                        <label>{lang('Win')}</label><span>{currency(Math.abs(summary.sportWin), 2, player.currency)}</span>
                        <label>{lang('Profit')}</label><span>{currency(Math.abs(summary.sportStake) - Math.abs(summary.sportWin), 2, player.currency)}</span>
                    </div>
                    <div className='padding'>
                        <Button title='Details' onClick={() => {

                        }} />
                    </div>
                </Panel>
                <Panel title='Horse' className='fit'>
                    <div className='group padding fit'>
                        <label>{lang('New Tickets')}</label><span>{summary.horseSlips}</span>
                        <label>{lang('Winning Tickets')}</label><span>{summary.horseWinSlip}</span>
                        <label>{lang('Stake')}</label><span>{currency(Math.abs(summary.horseStake), 2, player.currency)}</span>
                        <label>{lang('Win')}</label><span>{currency(Math.abs(summary.horseWin), 2, player.currency)}</span>
                        <label>{lang('Profit')}</label><span>{currency(Math.abs(summary.horseStake) - Math.abs(summary.horseWin), 2, player.currency)}</span>
                    </div>
                    <div className='padding'>
                        <Button title='Details' />
                    </div>
                </Panel>
                <Panel title='Casino' className='fit'>
                    <div className='group padding fit'>
                        <label>{lang('Hands')}</label><span>{summary.casinoHand}</span>
                        <label>{lang('Bet')}</label><span>{currency(Math.abs(summary.casinoBet), 2, player.currency)}</span>
                        <label>{lang('Cancel')}</label><span>{currency(Math.abs(summary.casinoCancel), 2, player.currency)}</span>
                        <label>{lang('Win')}</label><span>{currency(Math.abs(summary.casinoWin), 2, player.currency)}</span>
                        <label>{lang('Profit')}</label><span>{currency((Math.abs(summary.casinoBet) - Math.abs(summary.casinoCancel) - Math.abs(summary.casinoWin)), 2, player.currency)}</span>
                    </div>
                    <div className='padding'>
                        <Button title='Details' />
                    </div>
                </Panel>
            </div>
            <Card caption='Transactions'>
                <Search
                    key='transactions'
                    model={searchTransactions}
                    resultModel='transactionInformation'
                    exportToExcel={true}
                    searchOnStart={true}
                    data={props.playerLastTransactionsAfterDeposit}
                    onSubmit={(data, qb) => {
                        qb.fields.push({ name: 'Id', value: activityAfterDeposit.transactionId, exact: true, and: true, min: activityAfterDeposit.transactionId, max: activityAfterDeposit.endTransactionId, between: true });
                        qb.fields.push({ name: 'UserId', value: player.id, exact: true, and: true });
                        return apiResult('playerLastTransactionsAfterDeposit', Endpoints.SearchAllTransactions, qb);
                    }}
                />
            </Card>
        </Card>;
    };

    return <Card caption='Select Player'>
        <Form>
            <div className='row'>
                <FormInput title='Id' name='Id' onKeyUp={(a, b, value, e) => {
                    if (e.key == 'Enter') {
                        searchPlayer({ 'Id': parseFloat(value) });
                    }
                }} />
                <FormInput title='Code' name='Code' onKeyUp={(a, b, value, e) => {
                    if (e.key == 'Enter') {
                        searchPlayer({ 'Code': value });
                    }
                }} />
                <FormInput title='Username' name='Username' onKeyUp={(a, b, value, e) => {
                    if (e.key == 'Enter') {
                        searchPlayer({ 'Username': value });
                    }
                }} />
                <FormInput title='Email' name='Email' onKeyUp={(a, b, value, e) => {
                    if (e.key == 'Enter') {
                        searchPlayer({ 'Email': value });
                    }
                }} />
            </div>
            <div className='row'>
                <Button title='Clear' onClick={() => {
                    showPlayer(null);
                }} />
            </div>
        </Form>
        {playerSummary()}
    </Card>;
};


PlayerShortcuts.defaultProps = {
};

PlayerShortcuts.propTypes = {
    players: PropTypes.object,
    playerLastTransactionsAfterDeposit: PropTypes.object
};


const mapStateToProps = state => {
    return {
        players: state.data.playersForShortcuts,
        playerLastTransactionsAfterDeposit: state.data.playerLastTransactionsAfterDeposit
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(PlayerShortcuts);
export { hoc as PlayerShortcuts };