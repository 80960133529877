import React from 'react';
import PropTypes from 'prop-types';
import { icons, cacheDataService } from '../../Shared';
import { DateTime } from './DateTime';
import { Validator } from '../../Validation';
import { format, subDays } from 'date-fns';
import { lang } from '../../Localization/lang';
import zxcvbn from 'zxcvbn';
import { AutoComplete } from './AutoComplete';
import { Switch } from '../../../Controls/Inputs/Switch/Switch';
import { DateRanges, getRange } from './Pickers/DateTimePicker';
import { Dropdown } from '../../../Controls/DropDown/Dropdown';
import { Button } from '../../../Controls/Buttons/Button';
import { Icon } from '../../../Controls/Icon/Icon';
import { MultiSelect } from './MultiSelect';
import { toIsoLocal } from '../../../../v2/Lib/Common/dateHelpers';



export const InputTypes = {
    default: 'text',
    text: 'text',
    textArea: 'textArea',
    number: 'number',
    date: 'date',
    dateTime: 'dateTime',
    enum: 'enum',
    select: 'select',
    bool: 'bool',
    password: 'password',
    image: 'image',
    code: 'code',
    custom: 'custom',
    autoComplete: 'autoComplete',
    multipleSelect: 'multipleSelect'
};

export const FormatTypes = {
    default: 'text',
    currency: 'currency'
};


export const ValidationTypes = {
    default: 'text',
    number: 'number',
    email: 'email',
    password: 'password'
};


export class CentrumInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
        this.validationError = null;
        this.previousValue = null;
        this.mounted = false;
        this.loading = false;
        this.valueEndPointData = null;
        let options = (props.queryBuilderOptions && props.queryBuilderOptions[props.field.name]) ? props.queryBuilderOptions[props.field.name] : { data: 0 };
        this.state.selectedDate = getRange(options.data);
        this.state.multiSelectSelected = [];
    }

    componentDidMount() {
        this.mounted = true;
        let valueEndPoint = this.props.field.valueEndPoint;
        if (valueEndPoint !== undefined && valueEndPoint !== null) {
            if (!this.mounted) return;
            this.loadValues(valueEndPoint);
        }
    }

    loadValues(valueEndPoint) {
        let defaultValue = this.props.field.value === undefined ? '' : this.props.field.value;

        // endpoint defined but not loaded
        if ((this.state.data === null || this.state.data === undefined)) {
            // load it from cache data services
            var that = this;
            this.valueEndPointData = valueEndPoint.data;
            cacheDataService(valueEndPoint).then(result => {
                if (!this.mounted) return;
                // load success, set state
                try {
                    if (that.props.field.acceptEmptyValues === false || that.props.field.acceptEmptyValues === undefined) {
                        if (defaultValue === '' || defaultValue === null || defaultValue === undefined) {
                            if (Object.keys(result).length > 0) {
                                defaultValue = result[0].key;
                            }
                        }
                    }
                    if (that.props.onReady != null) that.props.onReady(that);
                    that.setState({ data: result });
                    if (that.selectRef) {
                        that.selectRef.value = defaultValue;
                    }
                } catch (err) {
                    console.error(err);
                }
            });
        } else {
            // already loaded
        }
    }



    componentWillUnmount() {
        this.mounted = false;
    }


    onQueryBuilderOpen() {
        if (this.props.onQueryBuilderOpen !== null) this.props.onQueryBuilderOpen(this.props.field);
    }

    onKeyDown(event) {
        if (event.keyCode === 114) {
            this.onQueryBuilderOpen();
            event.preventDefault();
        }
    }

    onKeyPress(event) {
        if (this.props.field.type === InputTypes.number) {
            let keycode = event.which;
            if (!(event.shiftKey == false && (keycode == 46 || keycode == 8 || keycode == 37 || keycode == 39 || (keycode >= 48 && keycode <= 57)))) {
                event.preventDefault();
            }
        }
    }

    onValueChanged(event) {
        this.validationError = null;
        let value = event.target.value;
        if (this.props.field.type === InputTypes.bool) {
            value = event.target.checked;
        }

        // Password Confirmation also access here
        // Forward password confirmations change event to main field
        if (this.props.field.type === InputTypes.password && this.input !== event.target) {
            // main input field's ref stored in input
            value = this.input.value;
        }


        if (this.props.field.type == InputTypes.custom) {
            value = event.target.value;
        }


        if (this.props.field.type === InputTypes.number) {
            if (!isNaN(parseFloat(value))) {
                value = parseFloat(value);
            } else {
                value = 0;
            }
        }

        if (this.props.onValueChanged !== null) this.props.onValueChanged(this.props.field, value);


        if (this.props.field.validation === ValidationTypes.email) {
            let result = Validator.isEmail(event.target.value);
            if (!result && value !== '') {
                this.validationError = 'Invalid e-mail';
            }
        }

        if (this.props.field.validation === ValidationTypes.password) {
            if (value === undefined || value === '' || value.trim() === '') {
                this.validationError = 'Password can not be empty';
            } else if (value.length < this.props.field.minimumLength) {
                this.validationError = 'Password is too short!';
            } else {
                var confirm = this.passwordConfirm.value;
                if (confirm !== value) {
                    this.validationError = 'Password mismatch!';
                } else {
                    var strength = zxcvbn(value);
                    switch (strength.score) {
                        case 0:
                            this.validationError = 'Password is too easy!';
                            break;
                        case 1:
                            this.validationError = 'Password is easy!';
                            break;
                        case 2:
                            this.validationError = 'Password is easy!';
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        this.setState({ value: value });
        this.previousValue = value;
    }


    multiSelectOnChange(event) {
        let value = event.map(item => item);
        this.setState({ multiSelectSelected: value });
        this.previousValue = value;
    }

    toggleCheckbox() {
        var checked = this.state.field.value;
        this.input.checked = !checked;
        var f = Object.assign({}, this.state.field);
        f.value = this.input.checked;
        this.setState({ field: f });
        if (this.props.onValueChanged !== null) this.props.onValueChanged(this.props.field, f.value);
    }


    // todo
    // postponed till later development
    renderShortCuts() {
        var selected = {
            like: true,
            equal: false,
            greaterThan: false,
            lessThan: false,
            between: false
        };

        if (this.props.queryBuilderOptions !== undefined && this.props.queryBuilderOptions !== null) {
            var values = this.props.queryBuilderOptions[this.props.field.name];
            if (values !== undefined) {
                selected.like = values.Exact === false || values.Exact === undefined;
                selected.equal = values.Exact === true;
                selected.greaterThan = values.BiggerThan === true;
                selected.lessThan = values.LessThan === true;
                selected.between = values.Between === true;
            }
        }

        let disabled = true;
        return !disabled && <div className='query-builder-shortcuts'>
            <div className={selected.like === true ? 'selected' : ''}><Icon icon={icons.percentage} onClick={() => this.onQueryBuilderOpen()}></Icon></div>
            <div className={selected.equal === true ? 'selected' : ''}><Icon icon={icons.equal} onClick={() => this.onQueryBuilderOpen()}></Icon></div>
            <div className={selected.greaterThan === true ? 'selected' : ''}><Icon icon={icons.greaterThan} onClick={() => this.onQueryBuilderOpen()}></Icon></div>
            <div className={selected.lessThan === true ? 'selected' : ''}><Icon icon={icons.lessThan} onClick={() => this.onQueryBuilderOpen()}></Icon></div>
            <div className={selected.between === true ? 'selected' : ''}><Icon icon={icons.search} onClick={() => this.onQueryBuilderOpen()}></Icon></div>
        </div>;
    }

    renderInput(type) {
        let field = this.props.field;
        let display = lang(this.props.field.display === undefined ? this.props.field.name : this.props.field.display);
        let defaultValue = this.props.field.value === undefined ? '' : this.props.field.value;
        let validation = this.props.field.validation === undefined ? type : this.props.field.validation;


        let range = false;
        if ((this.props.queryBuilderOptions !== undefined && this.props.queryBuilderOptions !== null) &&
            this.props.field.name in this.props.queryBuilderOptions) {
            range = this.props.queryBuilderOptions[this.props.field.name].Between;
        }

        if (range) {
            let rangeStartField = {};
            let rangeEndField = {};

            Object.assign({}, this.props.field);

            rangeStartField.name = this.props.field.name + '_min';
            rangeEndField.name = this.props.field.name + '_max';

            rangeStartField.display = this.props.field.display + ' Min';
            rangeEndField.display = this.props.field.display + ' Max';

            rangeStartField.value = this.props.queryBuilderOptions[this.props.field.name].Min ? this.props.queryBuilderOptions[this.props.field.name].Min : 0;
            rangeEndField.value = this.props.queryBuilderOptions[this.props.field.name].Max ? this.props.queryBuilderOptions[this.props.field.name].Max : 1;

            rangeStartField.showQueryBuilderOptions = false;
            rangeEndField.showQueryBuilderOptions = false;


            return (
                <div className={'form-element ' + this.props.className + ' range-element'}>
                    <CentrumInput field={rangeStartField} key='start' enabled={this.props.enabled}>
                        {field.showQueryBuilderOptions &&
                            <div className='query-builder-configure'>
                                <Icon icon={icons.configure} onClick={() => this.onQueryBuilderOpen()}></Icon>
                            </div>
                        }
                        {field.showQueryBuilderOptions && this.renderShortCuts()}
                    </CentrumInput>
                    <CentrumInput field={rangeEndField} key='end' enabled={this.props.enabled}>
                        {field.showQueryBuilderOptions &&
                            <div className='query-builder-configure'>
                                <Icon icon={icons.configure} onClick={() => this.onQueryBuilderOpen()}></Icon>
                            </div>
                        }
                        {field.showQueryBuilderOptions && this.renderShortCuts()}
                    </CentrumInput>
                </div>
            );
        }

        let multiLineStyle = {};
        if (this.props.multiLine) multiLineStyle = { gridColumnEnd: 'span 2' };



        if (field.hidden) {
            return <div className='form-element hidden'><input name={field.name} type={field.type === InputTypes.number ? 'number' : 'text'} style={{ display: 'none' }}
                defaultValue={defaultValue} /></div>;
        }

        return (
            <div className={'form-element' + (this.props.field.readonly ? ' readonly' : '') + ' ' + this.props.className + '' + (field.multiLine ? ' textarea' : '') + (field.layout ? ' ' + field.layout : '')} style={multiLineStyle}>
                {display != null && <span>{display}</span>}

                {field.multiLine &&
                    <textarea name={field.name}
                        disabled={!this.props.enabled}
                        defaultValue={(field.formatter !== undefined && field.formatter !== null) ? field.formatter(defaultValue, this.props.formData) : defaultValue}
                        onChangeCapture={this.onValueChanged.bind(this)}
                    ></textarea>
                }

                {!field.multiLine &&
                    <input name={field.name}
                        type={field.type === InputTypes.number ? 'number' : 'text'}
                        disabled={!this.props.enabled}
                        // eslint-disable-next-line
                        validation={validation}
                        readOnly={this.props.field.readonly}
                        defaultValue={(field.formatter !== undefined && field.formatter !== null) ? field.formatter(defaultValue, this.props.formData) : defaultValue}
                        pattern={field.type == InputTypes.number ? '[0-9]+([.,][0-9]+)?' : null}
                        step='any'
                        onKeyDown={this.onKeyDown.bind(this)}
                        onKeyPress={this.onKeyPress.bind(this)}
                        onChangeCapture={this.onValueChanged.bind(this)}
                    />
                }
                {field.showQueryBuilderOptions &&
                    <div className='query-builder-configure'>
                        <Icon icon={icons.configure} onClick={() => this.onQueryBuilderOpen()}></Icon>
                    </div>
                }
                {field.showQueryBuilderOptions && this.renderShortCuts()}
                {this.validationError !== null && <span className='validation-error'>{this.validationError}</span>}
                {this.props.children}
            </div>
        );
    }

    renderCode() {
        let display = lang(this.props.field.display === undefined ? this.props.field.name : this.props.field.display);
        let defaultValue = this.props.field.value === undefined ? '' : this.props.field.value;


        return (
            <React.Fragment>
                <div className='form-element' key='password' style={{ gridColumnEnd: 'span 2' }}>
                    <span>{display}</span>
                    <pre className={'pre ' + this.props.field.name}>{defaultValue}</pre>
                    {this.validationError !== null && <span className='validation-error'>{this.validationError}</span>}
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }

    renderCustom() {
        let display = lang(this.props.field.display === undefined ? this.props.field.name : this.props.field.display);
        let defaultValue = this.props.field.value === undefined ? '' : this.props.field.value;
        defaultValue = (this.props.field.formatter !== undefined && this.props.field.formatter !== null) ? this.props.field.formatter(this.state.value, this.props.formData, this.onValueChanged.bind(this), this.props.context) : defaultValue;

        return (
            <React.Fragment>
                <div className='form-element custom-element'>
                    <span>{display}</span>
                    <div className='custom-element-container'>
                        {defaultValue}
                    </div>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }


    renderImage() {
        let field = this.props.field;
        var display = this.props.field.display;
        var editField = Object.assign({}, field);
        editField.type = InputTypes.text;
        var val = editField.value.split('/');
        editField.value = val[val.length - 1];



        return (
            <React.Fragment>
                <div className='form-element' key='image' style={{ gridColumn: '1 / auto' }}>
                    <span style={{ display: 'block' }}>{display}</span>
                    <input name={editField.name}
                        disabled={!this.props.enabled}
                        defaultValue={editField.value}
                        onKeyDown={this.onKeyDown.bind(this)}
                        onKeyPress={this.onKeyPress.bind(this)}
                        onChangeCapture={this.onValueChanged.bind(this)}
                    />
                    <img src={field.value} style={{ paddingTop: '10px' }} />
                </div>
            </React.Fragment>
        );
    }


    renderPassword() {
        let field = this.props.field;
        let display = lang(this.props.field.display === undefined ? this.props.field.name : this.props.field.display);
        let displayConfirm = lang('Password Confirm');
        let defaultValue = this.props.field.value === undefined ? '' : this.props.field.value;
        let validation = ValidationTypes.password;


        if (field.readonly) {
            return (
                <div className='form-element readonly'>
                    <span>{display}</span>
                    <div className='value'>{(field.formatter !== undefined && field.formatter !== null) ? field.formattter(defaultValue) : defaultValue}</div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className='form-element' key='password' style={{ gridColumn: '1 / auto' }}>
                    <span>{display}</span>
                    <input name={field.name}
                        ref={(input) => this.input = input}
                        disabled={!this.props.enabled}
                        // eslint-disable-next-line
                        validation={validation}
                        defaultValue={defaultValue}
                        onChangeCapture={this.onValueChanged.bind(this)}
                    />
                    {this.validationError !== null && <span className='validation-error'>{this.validationError}</span>}
                    {this.props.children}
                </div>
                <div className='form-element' key='confirm'>
                    <span>{displayConfirm}</span>
                    <input name={field.name + '_confirm'}
                        ref={(confirmField) => this.passwordConfirm = confirmField}
                        disabled={!this.props.enabled}
                        defaultValue={defaultValue}
                        onChangeCapture={this.onValueChanged.bind(this)}
                    />
                </div>
            </React.Fragment>
        );
    }


    renderCheckbox() {
        let field = this.state.field;
        let display = lang(this.props.field.display === undefined ? this.props.field.name : this.props.field.display);
        this.checked = field.value;
        if (field.readonly) {
            return (
                <div className={'form-element readonly'}>
                    <span>{display}</span>
                    <input type='hidden' defaultValue={field.value} name={field.name} />
                    <input type='text' readOnly={true} defaultValue={field.value ? lang('true') : lang('false')} />
                </div>
            );
        }


        return (
            <div className='form-element switch'>
                <span>{display}</span>
                <input type='hidden'
                    name={field.name}
                    ref={(input) => this.input = input}
                    disabled={!this.props.enabled}
                    defaultValue={field.value == true} />

                <div className='full-width'>
                    <Switch checked={field.value} onChange={(value) => {
                        this.input.value = value;
                    }} />
                </div>
                {
                    field.showQueryBuilderOptions &&
                    <div className='query-builder-configure'>
                        <Icon icon={icons.configure} onClick={() => this.onQueryBuilderOpen()} />
                    </div>
                }
            </div>
        );
    }

    renderDate2(/*renderTime*/) {
        let field = this.props.field;
        let range = false;
        if ((this.props.queryBuilderOptions !== undefined && this.props.queryBuilderOptions !== null) &&
            this.props.field.name in this.props.queryBuilderOptions) {
            range = this.props.queryBuilderOptions[this.props.field.name].Between;
        }

        if (range) {
            let dateStartField = {};
            let dateEndField = {};

            Object.assign(dateStartField, this.props.field);
            Object.assign(dateEndField, this.props.field);

            dateStartField.name += '_min';
            dateEndField.name += '_max';

            dateStartField.display = lang('Start Date');
            dateEndField.display = lang('End Date');

            let options = this.props.queryBuilderOptions ? this.props.queryBuilderOptions[this.props.field.name] : {};


            //todo fix later
            let setDefaultDates = false;
            if (setDefaultDates) {
                dateStartField.value = format(subDays(new Date(), 7), 'MM / DD / YYYY', { awareOfUnicodeTokens: true });
                dateEndField.value = format(new Date(), 'MM / DD / YYYY', { awareOfUnicodeTokens: true });
            } else {
                if (options !== undefined && options !== null) {
                    if (options.Min !== undefined && options.Min !== null) {
                        dateStartField.value = options.Min;
                    }
                    if (options.Max !== undefined && options.Max !== null) {
                        dateEndField.value = options.Max;
                    }
                }
            }

            var selectedDate = this.state.selectedDate;

            return <div className='form-element' key={selectedDate}>
                <span>{field.display}</span>
                {!this.state.selectRange &&
                    <div className='centrum-flex'>
                        <input type='datetime-local' key={selectedDate.min} name={`${field.name}_min`} defaultValue={selectedDate.min.toISOString().split('.')[0]} onChangeCapture={(e) => {
                            selectedDate.min = new Date(e.target.value);
                            this.setState({ selectedDate: selectedDate });
                        }} />
                        <input type='datetime-local' key={selectedDate.max} name={`${field.name}_max`} defaultValue={selectedDate.max.toISOString().split('.')[0]} onChangeCapture={(e) => {
                            selectedDate.max = new Date(e.target.value);
                            this.setState({ selectedDate: selectedDate });
                        }} />
                        <select className='date-range-select' onChangeCapture={(e) => {
                            this.setState({
                                selectedRange: DateRanges[e.target.value],
                                selectedDate: getRange(DateRanges[e.target.value]),
                                minChange: Date.now(), maxChange: Date.now()
                            });
                        }}>
                            {
                                Object.keys(DateRanges).map(range => {
                                    return <option key={range} value={range}>{range}</option>;
                                })
                            }
                        </select>
                    </div>
                }
                {
                    this.state.selectRange &&
                    <select name={field.name + '-selector'} ref={(r) => this.selectRef = r}
                        onChangeCapture={() => {
                            this.setState({ selectedDate: false });
                        }
                        }>
                        {
                            Object.keys(DateRanges).map(key => {
                                return <option key={key} name={DateRanges[key]}>{lang(key)}</option>;
                            })
                        }
                    </select>
                }
            </div>;
        } else {
            return (
                <DateTime
                    field={this.props.field}
                    enabled={this.props.enabled}
                    onChange={this.onValueChanged.bind(this)} range={range} onQueryBuilderOpen={this.onQueryBuilderOpen.bind(this)}>
                    {field.showQueryBuilderOptions &&
                        <div className='query-builder-configure'>
                            <Icon icon={icons.configure} onClick={() => this.onQueryBuilderOpen()}></Icon>
                        </div>
                    }
                    {field.showQueryBuilderOptions && this.renderShortCuts()}
                </DateTime>
            );
        }
    }

    renderDate(/*renderTime*/) {
        let field = this.props.field;
        let range = false;
        if ((this.props.queryBuilderOptions !== undefined && this.props.queryBuilderOptions !== null) &&
            this.props.field.name in this.props.queryBuilderOptions) {
            range = this.props.queryBuilderOptions[this.props.field.name].Between;
        }

        if (range) {
            let dateStartField = {};
            let dateEndField = {};

            Object.assign(dateStartField, this.props.field);
            Object.assign(dateEndField, this.props.field);

            dateStartField.name += '_min';
            dateEndField.name += '_max';

            dateStartField.display = lang('Start Date');
            dateEndField.display = lang('End Date');

            let options = this.props.queryBuilderOptions ? this.props.queryBuilderOptions[this.props.field.name] : {};


            //todo fix later
            let setDefaultDates = false;
            if (setDefaultDates) {
                dateStartField.value = format(subDays(new Date(), 7), 'MM / DD / YYYY', { awareOfUnicodeTokens: true });
                dateEndField.value = format(new Date(), 'MM / DD / YYYY', { awareOfUnicodeTokens: true });
            } else {
                if (options !== undefined && options !== null) {
                    if (options.Min !== undefined && options.Min !== null) {
                        dateStartField.value = options.Min;
                    }
                    if (options.Max !== undefined && options.Max !== null) {
                        dateEndField.value = options.Max;
                    }
                }
            }

            var selectedDate = this.state.selectedDate;
            var formattedMin = toIsoLocal(this.state.selectedDate.min);
            var formattedMax = toIsoLocal(this.state.selectedDate.max);

            return <div className='form-element' key={this.state.updateTime}>
                <span>{field.display}</span>
                {!this.state.selectRange &&
                    <ul className='date-information'>
                        <li>
                            <input type='datetime-local'
                                name={field.name + '_min'}
                                defaultValue={formattedMin}
                                onChangeCapture={(e) => {
                                    let state = {};
                                    selectedDate.min = new Date(e.target.value);
                                    state = {
                                        selectedDate: selectedDate
                                    };
                                    this.setState(state, () => {
                                    });
                                }} />

                        </li>
                        <li>
                            <input type='datetime-local'
                                name={field.name + '_max'}
                                defaultValue={formattedMax}
                                onChangeCapture={(e) => {
                                    let state = {};
                                    selectedDate.max = new Date(e.target.value);
                                    state = {
                                        selectedDate: selectedDate
                                    };
                                    this.setState(state, () => {
                                    });
                                }} />

                        </li>
                        <li>
                            <Dropdown type='menu' position='right' icon={'angle-down'}>
                                {
                                    Object.keys(DateRanges).map(range => {
                                        return <Button key={range} title={range} onClick={() => {
                                            console.log(DateRanges[range]);
                                            this.setState({
                                                selectedRange: DateRanges[range],
                                                selectedDate: getRange(DateRanges[range]),
                                                updateTime: Date.now()
                                            });
                                        }} />;
                                    })
                                }
                            </Dropdown>
                        </li>
                    </ul>
                }
                {
                    this.state.selectRange &&
                    <select name={field.name + '-selector'} ref={(r) => this.selectRef = r}
                        onChangeCapture={() => {
                            this.setState({ selectedDate: false });
                        }
                        }>
                        {
                            Object.keys(DateRanges).map(key => {
                                return <option key={key} name={DateRanges[key]}>{lang(key)}</option>;
                            })
                        }
                    </select>
                }
            </div>;

        } else {
            return (
                <DateTime
                    field={this.props.field}
                    enabled={this.props.enabled}
                    onChange={this.onValueChanged.bind(this)} range={range} onQueryBuilderOpen={this.onQueryBuilderOpen.bind(this)}>
                    {field.showQueryBuilderOptions &&
                        <div className='query-builder-configure'>
                            <Icon icon={icons.configure} onClick={() => this.onQueryBuilderOpen()}></Icon>
                        </div>
                    }
                    {field.showQueryBuilderOptions && this.renderShortCuts()}
                </DateTime>
            );
        }
    }

    renderMultipleSelect() {
        let field = this.props.field;
        let display = lang(this.props.field.display === undefined ? this.props.field.name : this.props.field.display);
        let defaultValue = this.props.field.value === undefined ? '' : this.props.field.value;
        let options = field.values;
        var loading = false;
        if ((this.state.data === null || this.state.data === undefined)) {
            loading = true;
        } else {
            field.values = options = this.state.data;
        }

        if (field.values && Object.keys(field.values).length > 0) {
            loading = false;
        }
        if (field.values) {
            options = field.values.map(({ key, value }) => ({ value: key, label: value }));
        }


        return (
            <div className={'form-element' + (this.props.field.readonly ? ' readonly' : '') + (loading ? ' component-not-ready' : '') + (' ' + (field.layout ? field.layout : ''))}>
                <span>{display}</span>
                {options &&
                    <MultiSelect
                        name={field.name}
                        options={options}
                        value={this.state.multiSelectSelected}
                        defaultValue={defaultValue}
                        onChange={this.multiSelectOnChange.bind(this)} />}
            </div>
        );
    }

    renderSelect() {
        let field = this.props.field;
        let display = lang(this.props.field.display === undefined ? this.props.field.name : this.props.field.display);
        let defaultValue = this.props.field.value === undefined ? '' : this.props.field.value;

        let values = field.values;

        /// check for data value endpoint


        var loading = false;

        if ((this.state.data === null || this.state.data === undefined)) {
            loading = true;
        } else {
            field.values = values = this.state.data;
        }

        if (field.values && Object.keys(field.values).length > 0) {
            loading = false;
        }
        var isArray = values && typeof values === 'object' && values.constructor === Array;

        return (
            <div className={'form-element ' + (this.props.field.readonly ? ' readonly' : '') + (loading ? ' component-not-ready' : '') + (' ' + (field.layout ? field.layout : ''))}>
                <span>{display}</span>
                <select name={field.name}
                    defaultValue={defaultValue}
                    onKeyDown={this.onKeyDown.bind(this)}
                    onKeyPress={this.onKeyPress.bind(this)}
                    onChangeCapture={this.onValueChanged.bind(this)}
                    disabled={!this.props.enabled}
                    ref={(r) => this.selectRef = r}
                >
                    {this.props.field.acceptEmptyValues && <option key='empty' value=''>{lang('Please Select')}</option>}
                    {values !== undefined && values !== null && !isArray && Object.keys(values).map((key, index) => {
                        return (<option key={index} value={key}>{lang(values[key])}</option>);
                    })}
                    {values !== undefined && values !== null && isArray && values.map((val, index) => {
                        return (<option key={index} value={val.key}>{lang(val.value)}</option>);
                    })}
                </select>
            </div>
        );
    }

    renderAutoComplete() {
        let field = this.props.field;
        let display = lang(this.props.field.display === undefined ? this.props.field.name : this.props.field.display);
        let values = field.values;
        /// check for data value endpoint
        var loading = false;
        if ((this.state.data === null || this.state.data === undefined)) {
            loading = true;
        } else {
            field.values = values = this.state.data;
        }

        if (field.values && Object.keys(field.values).length > 0) {
            loading = false;
        }
        return (
            <div className={'form-element ' + (loading ? ' component-not-ready' : '')}>
                <span>{display}</span>
                <AutoComplete suggestions={values}
                    name={field.name}
                    ref={(r) => this.selectRef = r}>
                </AutoComplete>
            </div>
        );
    }

    render() {
        let type = this.props.field.type;
        if (type === undefined) type = InputTypes.text;

        if (type === InputTypes.text || type === InputTypes.number) {
            return this.renderInput(type);
        }

        if (this.props.field.type === InputTypes.date) {
            return this.renderDate(true);
        }

        if (this.props.field.type === InputTypes.dateTime) {
            return this.renderDate(false);
        }

        if (this.props.field.type === InputTypes.bool) {
            return this.renderCheckbox();
        }

        if (this.props.field.type === InputTypes.select) {
            return this.renderSelect();
        }

        if (this.props.field.type === InputTypes.password) {
            return this.renderPassword();
        }

        if (this.props.field.type === InputTypes.image) {
            return this.renderImage();
        }

        if (this.props.field.type === InputTypes.code) {
            return this.renderCode();
        }

        if (this.props.field.type === InputTypes.custom) {
            return this.renderCustom();
        }
        if (this.props.field.type === InputTypes.autoComplete) {
            return this.renderAutoComplete();
        }
        if (this.props.field.type === InputTypes.multipleSelect) {
            return this.renderMultipleSelect();
        }

        return (
            <div>error</div>
        );
    }

}


CentrumInput.defaultProps = {
    className: '',
    formData: null,
    enabled: true,
    readonly: false,
    onQueryBuilderOpen: null,
    onReady: null,
    onValueChanged: null,
    queryBuilderOptions: null,
    multiLine: false,
    children: null
};

CentrumInput.propTypes = {
    className: PropTypes.string,
    formData: PropTypes.object, // pased from form
    context: PropTypes.object,
    field: PropTypes.object,
    enabled: PropTypes.bool,
    readonly: PropTypes.bool,
    multiLine: PropTypes.bool,
    queryBuilderOptions: PropTypes.object,
    onQueryBuilderOpen: PropTypes.func,
    onValueChanged: PropTypes.func,
    onReady: PropTypes.func,
    children: PropTypes.node
};
