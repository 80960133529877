import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getActions, ActionTypes, getAction } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { Page } from '../../Centrum/Page/Page';
import { Card } from '../../Centrum/Card/Card';
import { Search } from '../../Centrum/Search/Search';
import { apiResult, application, Endpoints } from '../../Application/Application';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { currency, lang } from '../../Centrum/Localization/lang';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { SportsTicketStatus } from '../../Centrum/_models/ModelEnums';
import { gotoProfile } from '../../Centrum/_models/Transactions';
import { bookBet, manualBookedTicketLost, manualBookedTicketWin } from '../RiskManagement/actions/ticketActions';
import { ErrorModal } from '../../Modal/Modals/Error/ErrorModal';

const applicationActionType = ActionTypes.ManualBet;
const applicationActions = getActions(applicationActionType);


const bookedTickets = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id',
            type: InputTypes.number
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketStatus
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Username',
            display: 'Username',
            type: InputTypes.text
        }
    ]
};



export const bookedTicketResults = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id'
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'DueDate',
            display: 'Due Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Admin',
            display: 'Admin'
        },
        {
            name: 'Group',
            display: 'Group'
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'Username',
            display: 'Username',
            formatter: (val, row) => {
                return gotoProfile(row.PlayerId, row.Username);
            }
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketStatus
        },
        {
            name: 'Stake',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (val && Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency);
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'PossibleWin',
            display: 'PossibleWin',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (val && Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency);
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (val && Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency);
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (val && Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency);
                    }
                } else {
                    if (row.Status == 0) return;
                    var result = row.Bet - row.Win;
                    if (isExporting) return result;
                    return currency(result, 2, row.Currency);
                }
            }
        }
    ],
    onRenderRow: (rowObject) => {
        if (rowObject.data.Status == 1) {
            rowObject.className = 'player-win';
        }
        if (rowObject.data.Status == 2) {
            rowObject.className = 'player-lost';
        }
        return rowObject;
    }
};

// COMPONENT
class ManualBetsPage extends Page {
    constructor(props) {
        super(props);

        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.action = action;

        this.state = state;
        this.applicationContext = null;
    }


    selectPlayerAndOpenWebsite(player) {
        if (player.Username.indexOf('retail_player_') == 0) {
            application().modal.open(<ErrorModal title='Error'>
                <p>
                    {lang('Cashiers are not allowed to use manual bet, please use retail software')}
                </p>
            </ErrorModal>);
            return;
        }
        
        application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
            var website = null;
            switch (player.OperatorId) {
                case 16:
                    website = 'http://localhost:4999';
                    break;
                case 22:
                    website = 'https://sportarenabet.com';
                    break;
                case 24:
                    website = 'https://ttbetarena.com';
                    break;
                case 25:
                    website = 'https://efesbet.com';
                    break;
                case 27:
                    website = 'https://www.alpacabet.co';
                    break;
                case 29:
                    website = 'https://www.8xbetter.com';
                    break;
                case 35:
                    website = 'https://www.ethioarena.bet';
                    break;
                case 37:
                    website = 'https://www.45casinoturka.com';
                    break;
                case 43:
                    website = 'https://www.bordobet245.com';
                    break;
                case 44:
                    website = 'https://www.nacionbet.bet/';
                    break;
                case 47:
                    website = 'https://www.89wins90.com';
                    break;
            }
            if (website == null) {
                alert('Website is not enabled');
                return;
            }
            application().resolve(null, `${Endpoints.SigninWithPlayer}/${player.Id}`, null, 'please wait', 'unable to signin').then((result) => {
                var token = result.result;
                setTimeout(() => {
                    window.open(`${website}?&sso=${token}`, 'betwindow');
                }, 2000);

            });
        }}>
            <div>
                <span>{lang('Login webiste with %1 %2?', [player.Id, player.Username])}</span>
            </div>
        </ConfirmationModal>);
    }




    resolveBookedBet(action, items) {
        const item = items[0];

        switch (action.type) {
            case ActionTypes.WinBookABet:
                manualBookedTicketWin(item);
                break;
            case ActionTypes.LostBookABet:
                manualBookedTicketLost(item);
                break;
            case ActionTypes.ModifyBookedBet:
                bookBet({ Id: item.PlayerId }, { Id: item.Id, Stake: item.Stake, Odds: item.Odds, Bets: item.Bets, DueDate: item.DueDate });
                break;
        }
    }

    render() {
        return (
            <CentrumApplication
                caption='Manual Bet' icon='futbol'
                type={applicationActionType}
                loading={this.props.loading}
            >
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <Card caption='Select player'>
                                        <Search key='search'
                                            model='playerSearch'
                                            resultModel='playerSummary'
                                            data={this.props.selectedPlayers}
                                            exportToExcel={false}
                                            actions={getAction(ActionTypes.SelectPlayerForManualBet).convertToItems()}
                                            onActionClicked={(action, items) => this.selectPlayerAndOpenWebsite(items[0])}
                                            onSubmit={(data, qb) => { apiResult('selectedPlayers', Endpoints.PlayerSearch, qb, 'searching please wait', 'unable to find player'); }}
                                        />
                                    </Card>
                                    <Card caption='Select player'>
                                        <Search key='search'
                                            model='playerSearch'
                                            resultModel='playerSummary'
                                            exportToExcel={false}
                                            data={this.props.selectedPlayers}
                                            actions={getAction(ActionTypes.SelectPlayerForManualBet).convertToItems()}
                                            onActionClicked={(action, items) => bookBet(items[0])}
                                            onSubmit={(data, qb) => { apiResult('selectedPlayers', Endpoints.PlayerSearch, qb, 'searching please wait', 'unable to find player'); }}
                                        />
                                    </Card>
                                    <Card caption='Search Manual Bets'>
                                        <Search
                                            model={bookedTickets}
                                            resultModel={bookedTicketResults}
                                            data={this.props.bookedTickets}
                                            actions={getAction(ActionTypes.BookedTicketOptions).convertToItems()}
                                            onActionClicked={this.resolveBookedBet.bind(this)}
                                            onSubmit={(data, qb) => { apiResult('bookedTickets', Endpoints.SearchBookedTickets, qb, 'searching tickets', 'unable to fetch tickets'); }}
                                        />
                                    </Card>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}


ManualBetsPage.propTypes = {
    selectedPlayers: PropTypes.object
};


const mapStateToProps = state => {
    return {
        selectedPlayers: state.data.selectedPlayers,
        bookedTickets: state.data.bookedTickets
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({

    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ManualBetsPage);
export { hoc as ManualBetsPage };