import './_horse-ticket.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../Card/Card';
import { Button } from '../../../Controls/Button/Button';
import { BettingFlags, RacingTicketTypes } from '../BettingEnums';
import { API, TicketEndpoints } from '../../../../Lib/Api/Api';
import { Ticket } from '../Ticket/Ticket';
import { Table } from '../../../Controls/Table/Table';
import { formatDateWithMonth, getTime } from '../../../../Lib/Common/dateHelpers';
import { Country } from '../../../CountryIcons/Country';
import { ordinalNumbers } from '../../../../Lib/Common/numberHelpers';
import { ResultPrices } from '../../ResultPrices/ResultPrices';
import { lang } from '../../../../Lib/Common/language';


const renderBet = (row, context) => {
    if (row.Selections.length == 1) {
        var singleSelection = row.Selections[0];
        return <div className='flex gap-5'>
            <span className='light'>{singleSelection.Number}</span>
            <span>{singleSelection.Name}</span>
            <span className='align-right light'>{singleSelection.PriceDecimal.toFixed(2)}</span>
        </div>;
    } else {
        const result = context.results?.find(x => x.eventKey == row.EventKey);

        console.log(row);
        console.log(context.results);
        return row.Selections.map((r, index) => {
            if (row.ParentTicket.System && result && result.runners) {
                var found = result.runners.find(x => x.id == r.Id);
                if (!found) return;
            }

            var found = result?.runners.find(x => x.id == r.Id);
            if (found) {
                return <div key={index} className='flex gap-5'>
                    {r.Place && <label className='flex surface dark'>{ordinalNumbers[r.Place]}</label>}
                    <span className='flex surface dark'>{r.Number}</span>
                    <span>{r.Name}</span>
                </div>;
            }

        });
    }
};

const renderResult = (row, context) => {
    const result = context.results?.find(x => x.eventKey == row.EventKey);
    if (!result) return;
    if (!result.runners) return;
    return <ResultPrices event={result} small />;
};


const selections = {
    fields: [
        {
            name: 'Date',
            title: 'Match Date',
            // eslint-disable-next-line react/display-name
            renderRow: (row) => {
                return <div className='flex vertical align-start'>
                    <span>{formatDateWithMonth(new Date(row.Date))}</span>
                    <span>{getTime(new Date(row.Date))}</span>
                </div>;
            }
        },
        {
            name: 'OffTime',
            title: 'Off Time',
            // eslint-disable-next-line react/display-name
            renderRow: (row, field, context) => {
                const result = context.results?.find(x => x.eventKey == row.EventKey);
                if (!result) return;
                if (!result.runners) return;
                return <div className='flex gap-5'>
                    {result.offTime}
                </div>;
            }
        },
        {
            name: 'Venue',
            title: 'Venue',
            // eslint-disable-next-line react/display-name
            renderRow: (row, field, context) => {
                const result = context.results?.find(x => x.eventKey == row.EventKey);
                const nonRunners = result?.runners.filter(x => x.status == 'N') ?? [];
                return <div className='flex vertical align-start'>
                    <div className='flex gap-5'>
                        <Country name={row.CountryCode} /><span>{row.Venue}</span>
                        <span>{row.Distance}</span>
                    </div>
                    <div className='flex gap-5 vertical margin-top'>
                        <div className='flex gap-5'>
                            <label>{lang('Each Way')}</label><span>{result?.eachWayFraction}</span>
                            <label>{lang('Runners')}</label><span>{result?.runners.filter(x => x.status != 'N').length}</span>
                        </div>
                        {nonRunners.length > 0 && <div className='flex vertical align-start gap-5'>
                            {
                                nonRunners.map(x => { return <span key={x.id} className='secondary margin-right sm all'>{x.number}</span>; })
                            }
                        </div>}
                    </div>
                </div>;
            }
        },
        {
            name: 'Runners',
            title: 'Runners',
            // eslint-disable-next-line react/display-name
            renderRow: (row, field, context) => {
                const result = context.results?.find(x => x.eventKey == row.EventKey);
                if (!result) return;
                if (!result.runners) return;
                return <ul className='flex vertical align-start runners'>
                    {
                        result.runners.sort((b, a) => {
                            if (!a.result) return 1;
                            if (a.result && b.result) {
                                return a.result.place - b.result.place;
                            }
                            return -1;
                        }).reverse().map((runner, index) => {
                            return <li className='runner' key={index}>
                                <div className='flex gap-5'>
                                    <span className={`position ${runner.result?.place < 4 ? ' light' : ''}`}>{runner.result?.place ? ordinalNumbers[runner.result.place] : ''}</span>
                                    <span>{runner.name}</span>
                                </div>
                            </li>;
                        })
                    }
                </ul >;
            }
        },
        {
            name: 'Type',
            title: 'Type',
            // eslint-disable-next-line react/display-name
            renderRow: (row) => {
                if (row.ParentTicket.System) return row.ParentTicket.System;
                var single = row.Ticket.flags & BettingFlags.Single;
                var forecast = row.Ticket.flags & BettingFlags.Forecast;
                var tricast = row.Ticket.flags & BettingFlags.Tricast;
                var eachWay = row.Ticket.flags & BettingFlags.EachWay;


                if ((row.Ticket.system == '' || !row.Ticket.system) && row.Ticket.flags == 1) {
                    var result = RacingTicketTypes[row.Ticket.ticketType];
                    return <div className='gap-5'>
                        <span>{lang(result)}</span>
                    </div>;
                }
                if (single) {
                    return <div className='gap-5'>
                        <span>{lang('Single')}</span>
                        {eachWay > 0 && <span>{lang('Eachway')}</span>}
                    </div>;
                }
                if (forecast) {
                    return <div className='gap-5'>
                        <span>{lang('Forecast')}</span>
                    </div>;
                }
                if (tricast) {
                    return <div className='gap-5'>
                        <span>{lang('Tricast')}</span>
                    </div>;
                }
            }
        },
        {
            name: 'Bet',
            title: 'Bet',
            // eslint-disable-next-line react/display-name
            renderRow: (row, field, context) => {
                return renderBet(row, context);
            }
        },
        {
            name: 'Result',
            title: 'Result',
            // eslint-disable-next-line react/display-name
            renderRow: (row, field, context) => {
                return renderResult(row, context);
            }
        }
    ]
};






export const HorseTicket = (props) => {
    const [tickets, setTickets] = useState(null);
    const [results, setResults] = useState(null);
    useEffect(() => {
        const ticket = props.ticket;

        var bets = JSON.parse(ticket.Bets);
        var summary = bets.Summary;
        var events = summary.split('~');
        var eventIds = [];
        events.map(x => {
            var data = x.split('|');
            eventIds.push(data[0]);
        });
        var horse = ticket.Flags & BettingFlags.Horse;
        var type = horse ? 0 : 2;
        var system = ticket.System != '' && ticket.System != null;


        API.post(`${TicketEndpoints.HorseTicket}/${ticket.ExternalTicketId}/${system}`, null, 'please wait', 'unable get bet details').then((result) => {
            const data = result.result.details;
            setTickets(data);
            API.post(`${TicketEndpoints.HorseTicketDetails}/${type}`, eventIds, 'loading event results').then(eventResults => {
                setResults(eventResults.result);
            }).catch(() => {
                setResults([]);
            });
        });
    }, []);


    if (!results) return <></>;
    return <Card title='Ticket Details' className='horse-ticket'
        icon='list-alt' buttons={() => {
            return <Button title='Close' onClick={() => props.onClose ? props.onClose() : null} />;
        }}>
        {tickets?.map((ticket, index) => {
            const bets = JSON.parse(ticket.bets);
            const eventsData = bets.Summary.split('~');
            var events = [];
            eventsData.map(x => {
                const eventData = x.split('|');
                events.push({
                    EventKey: eventData[0],
                    CountryCode: eventData[1],
                    Venue: eventData[2],
                    Metres: eventData[3],
                    Distance: eventData[4],
                    Date: eventData[5],
                    Selections: bets.Selection,
                    Ticket: ticket,
                    ParentTicket: props.ticket
                });
            });

            return <Ticket ticket={ticket} key={index}>
                <Table model={selections} data={events} context={{ results: results }} />
            </Ticket>;
        })}
    </Card>;
};


HorseTicket.propTypes = {
    ticket: PropTypes.object,
    onClose: PropTypes.func
};


