import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { formatDistance } from 'date-fns/fp';
import { getUtcDate } from '../helpers';
import { NotificationTypes } from './ModelEnums';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';



export const CrashModels = {
    crashServerInformation: {
        fields: [
            {
                name: 'NodeKey',
                type: InputTypes.text,
                hidden: true
            },
            {
                name: 'Endpoint',
                display: 'Server Endpoint',
                type: InputTypes.text,
                readonly: true
            },
            {
                name: 'StartTime',
                display: 'Start Time',
                type: InputTypes.text,
                formatter: (val) => {
                    return formatDistance(getUtcDate(new Date(val)), new Date());
                },
                readonly: true
            },
            {
                name: 'LastPingTime',
                display: 'Last Ping Time',
                type: InputTypes.text,
                formatter: (val) => {
                    return formatDistance(getUtcDate(new Date(val)), new Date());
                },
                readonly: true
            },
            {
                name: 'ChatNode',
                display: 'Chat Node',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'centrumNodes',
                    bindingKey: 'NodeKey',
                    bindingText: ['NodeKey', 'Endpoint']
                },
                readonly: true
            },
            {
                name: 'Log',
                display: 'Log',
                type: InputTypes.code,
                multiLine: true,
                readonly: true
            }
        ]
    },
    searchCrashGameRoom: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Enabled',
                display: 'Enabled',
                type: InputTypes.bool
            }
        ]
    },
    crashRoomInformation: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text,
                readonly: true
            },
            {
                name: 'RoomId',
                display: 'Room Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'RoundId',
                display: 'Round Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'GameNumber',
                display: 'Game Number',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'TotalPlayers',
                display: 'Total Players',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'State',
                display: 'State',
                type: InputTypes.string,
                readonly: true
            }
        ]
    },
    crashRoomInformationDetailed: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Currency',
                type: InputTypes.text
            },
            {
                name: 'BetMin',
                display: 'Bet Min',
                type: InputTypes.number
            },
            {
                name: 'BetMax',
                display: 'Bet Max',
                type: InputTypes.number
            },
            {
                name: 'RiskManagementPerPlayerBankrollRatio',
                display: 'Risk Management Per Player Bankroll Ratio',
                type: InputTypes.number
            },
            {
                name: 'RiskManagementPerPlayerFixedAmount',
                display: 'Risk Management Per Player Fixed Amount',
                type: InputTypes.number
            },
            {
                name: 'RiskManagementPerRoundBankrollRatio',
                display: 'Risk Management Per Round Bankroll Ratio',
                type: InputTypes.number
            },
            {
                name: 'RiskManagementPerRoundFixedAmount',
                display: 'Risk Management Per Round FixedAmount',
                type: InputTypes.number
            },
            {
                name: 'RTP',
                type: InputTypes.number
            },
            {
                name: 'CrashConfigurationId',
                display: 'Configuration',
                type: InputTypes.number
            },
            {
                name: 'CentrumNode',
                display: 'Server',
                type: InputTypes.text
            }
        ]
    },
    crashRoomHistoryGraphValues: {
        fields: [
            {
                name: 'YAxisLeft',
                display: 'Y Axis Left',
                type: InputTypes.select,
                values: {
                    'Profit': 'Profit',
                    'Bets': 'Bets',
                    'Wins': 'Wins',
                    'TotalPlayers': 'Total Players',
                    'Multiplier': 'Multiplier'
                },
                value: 'Profit'
            },
            {
                name: 'YAxisRight',
                display: 'Y Axis Right',
                type: InputTypes.select,
                values: {
                    'Profit': 'Profit',
                    'Bets': 'Bets',
                    'Wins': 'Wins',
                    'TotalPlayers': 'Total Players',
                    'Multiplier': 'Multiplier'
                },
                value: 'Multiplier'
            }
        ]
    },
    closeCrashRoomForm: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text,
                readonly: true
            },
            {
                name: 'RoomId',
                display: 'Room Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'Message',
                display: 'Message',
                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    openCrashRoomForm: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text,
                readonly: true
            },
            {
                name: 'Id',
                display: 'Room Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'RoundsPlayed',
                display: 'Total Games Played',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'RTP',
                display: 'Configured RTP',
                type: InputTypes.number,
                readonly: true
            }
        ]
    },
    noticeCrashRoom: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text,
                readonly: true
            },
            {
                name: 'RoomId',
                display: 'Room Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'Message',
                display: 'Message',
                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    searchCrashServerNotifications: {
        fields: [
            {
                name: 'Level',
                type: InputTypes.select,
                values: NotificationTypes
            },
            {
                name: 'EntryDate',
                display: 'Event Date',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data:DateRanges['This Month']
                }
            },
            {
                name: 'Tag',
                display: 'Tag',
                type: InputTypes.text
            }
        ]
    },
    crashServerNotifications: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number
            },
            {
                name: 'EntryDate',
                display: 'Event Date',
                type: InputTypes.dateTime
            },
            {
                name: 'Level',
                type: InputTypes.select,
                values: NotificationTypes
            },
            {
                name: 'Message',
                type: InputTypes.text
            },
            {
                name: 'Data',
                type: InputTypes.text
            }
        ]
    },
    crashPassiveRoomInformation: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Id',
                display: 'Room Id',
                type: InputTypes.number
            },
            {
                name: 'RoundsPlayed',
                display: 'Total Games Played',
                type: InputTypes.number
            },
            {
                name: 'RTP',
                display: 'Configured RTP',
                type: InputTypes.number
            }
        ]
    },
    crashServerPlayerInformation: {
        fields: [
            {
                name: 'Username',
                type: InputTypes.Text
            },
            {
                name: 'ConnectionTime',
                display: 'Connection Time',
                type: InputTypes.text,
                formatter: (val) => {
                    return formatDistance(getUtcDate(new Date(val)), getUtcDate());
                }
            },
            {
                name: 'Balance',
                type: InputTypes.number
            }
        ]
    },
    kickCrashServerPlayerInformation: {
        fields: [
            {
                name: 'Id',
                display: 'Player Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'Username',
                type: InputTypes.Text,
                readonly: true
            },
            {
                name: 'ConnectionTime',
                display: 'Connection Time',
                type: InputTypes.text,
                formatter: (val) => {
                    return formatDistance(getUtcDate(new Date(val)), getUtcDate());
                },
                readonly: true
            },
            {
                name: 'Message',
                display: 'Message',
                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    banCrashServerPlayerInformation: {
        fields: [
            {
                name: 'Id',
                display: 'Player Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'Username',
                type: InputTypes.Text,
                readonly: true
            },
            {
                name: 'Message',
                display: 'Reason',
                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    noticeCrashServerPlayerInformation: {
        fields: [
            {
                name: 'Id',
                display: 'Player Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'Username',
                type: InputTypes.Text,
                readonly: true
            },
            {
                name: 'ConnectionTime',
                display: 'Connection Time',
                type: InputTypes.text,
                formatter: (val) => {
                    return formatDistance(getUtcDate(new Date(val)), getUtcDate());
                },
                readonly: true
            },
            {
                name: 'Message',
                display: 'Message',
                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    searchCrashGameRound: {
        fields: [
            {
                name: 'Id',
                display: 'Id',
                type: InputTypes.number
            },
            {
                name: 'Room',
                display: 'Room',
                type: InputTypes.string
            },
            {
                name: 'GameNumber',
                display: 'Game Number',
                type: InputTypes.number
            },
            {
                name: 'TimeStart',
                display: 'Time Start',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'Multiplier',
                display: 'Multiplier',
                type: InputTypes.number
            },
            {
                name: 'Hash',
                display: 'Hash',
                type: InputTypes.number
            },
            {
                name: 'PlayersTotal',
                display: 'Total Players',
                type: InputTypes.number
            },
            {
                name: 'BetsTotal',
                display: 'Total Bets',
                type: InputTypes.number
            },
            {
                name: 'WinTotal',
                display: 'Total Win',
                type: InputTypes.number
            }
        ]
    },
    crashGameRoundInformation: {
        fields: [
            {
                name: 'Id',
                display: 'Id',
                type: InputTypes.number
            },
            {
                name: 'Room',
                display: 'Room',
                type: InputTypes.string
            },
            {
                name: 'GameNumber',
                display: 'Game Number',
                type: InputTypes.number
            },
            {
                name: 'TimeStart',
                display: 'Time Start',
                type: InputTypes.dateTime
            },
            {
                name: 'TimeEnd',
                display: 'Time End',
                type: InputTypes.dateTime
            },
            {
                name: 'Multiplier',
                display: 'Multiplier',
                type: InputTypes.number,
                formatter: (val) => {
                    return parseFloat(val).toFixed(2) + 'x';
                }
            },
            {
                name: 'Hash',
                display: 'Hash',
                type: InputTypes.string
            },
            {
                name: 'PlayersTotal',
                display: 'Total Players',
                type: InputTypes.number
            },
            {
                name: 'BetsTotal',
                display: 'Total Bets',
                type: InputTypes.number
            },
            {
                name: 'WinTotal',
                display: 'Total Win',
                type: InputTypes.number
            }
        ]
    },
    searchCrashGameTransactions: {
        fields: [
            {
                name: 'Id',
                display: 'Id',
                type: InputTypes.number
            },
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'Username',
                display: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Type',
                display: 'Type',
                type: InputTypes.select,
                values: {
                    0: 'Win',
                    1: 'Bet'
                }
            },
            {
                name: 'Multiplier',
                display: 'Multiplier',
                type: InputTypes.number
            },
            {
                name: 'Amount',
                display: 'Amount',
                type: InputTypes.number
            }
        ]
    },
    roundTransactionInformation: {
        fields: [
            {
                name: 'Id',
                display: 'Id',
                type: InputTypes.number
            },
            {
                name: 'Username',
                display: 'Username',
                type: InputTypes.text
            },
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'RoundId',
                display: 'Round Id',
                type: InputTypes.number
            },
            {
                name: 'Type',
                display: 'Type',
                type: InputTypes.select,
                values: {
                    0: 'Win',
                    1: 'Bet'
                }
            },
            {
                name: 'Multiplier',
                display: 'Multiplier',
                type: InputTypes.number,
                formatter: (val) => {
                    return parseFloat(val).toFixed(2) + 'x';
                }
            },
            {
                name: 'Amount',
                display: 'Amount',
                type: InputTypes.number
            }
        ]
    },
    searchCrashGameLeaderboard: {
        fields: [
            {
                name: 'Username',
                type: InputTypes.Text
            }
        ]
    },
    searchCrashGameBankRoll: {
        fields: [
            {
                name: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'RoomName',
                display: 'Room',
                type: InputTypes.text
            },
            {
                name: 'Balance',
                display: 'Balance',
                type: InputTypes.number
            }
        ]
    },
    crashGameBankRollInformation: {
        fields: [
            {
                name: 'OperatorId',
                display: 'Operator Id',
                type: InputTypes.number,
            },
            {
                name: 'Operator',
                display: 'Operator',
                type: InputTypes.number,
            },
            {
                name: 'RoomName',
                display: 'Room',
                type: InputTypes.text
            },
            {
                name: 'Balance',
                display: 'Balance',
                type: InputTypes.number
            }
        ]
    },
    crashGameLeaderboard: {
        fields: [
            {
                name: 'Room',
                display: 'Room',
                type: InputTypes.text
            },
            {
                name: 'Username',
                display: 'Username',
                type: InputTypes.text
            },
            {
                name: 'TotalGamesPlayed',
                display: 'Total Games Played',
                type: InputTypes.number
            },
            {
                name: 'Bet',
                display: 'Total Bet',
                type: InputTypes.number
            },
            {
                name: 'Profit',
                display: 'Profit',
                type: InputTypes.number
            },
            {
                name: 'ProfitAth',
                display: 'Profit (ATH)',
                type: InputTypes.number
            },
            {
                name: 'ProfitAtl',
                display: 'Profit (ATL)',
                type: InputTypes.number
            }
        ]
    }
};