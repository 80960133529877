import './_tab.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ApplicationContext, ComponentTypes } from '../Application/CentrumApplication';
import { Button } from '../../Controls/Buttons/Button';


export class TabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    render() {
        return <div className='tab-content' style={{ display: !this.props.selected ? 'none' : 'unset' }}>{this.props.children}</div>;
    }
}

TabContent.propTypes = {
    children: PropTypes.node,
    selected: PropTypes.bool
};

export class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.selected = props.selected;
        this.keys = {};
        this.ref = React.createRef();
    }

    getName() {
        return this.props.name;
    }

    select(index) {
        this.selected = index;
        this.setState({ selected: this.selected });
        this.props.onTabSelected(this.selected, Object.keys(this.keys)[index], this.props.buttons[index]);
    }

    selectByKey(key) {
        var index = Object.keys(this.keys).findIndex(x => x == key);
        if (index >= 0) {
            this.select(index);
        }
    }

    renderButtons() {
        var buttons = this.props.buttons.map((button, index) => {
            var classList = ['tab'];
            if (this.selected == index) classList.push('selected');
            if (button.hidden) classList.push('hidden');
            return <Button key={index} className={classList.join(' ')} onClick={() => this.select(index)}>{button.title}</Button>;
        });

        if (this.props.buttons.length > 0) {
            return <div className='tab-buttons'>
                {buttons}
            </div>;
        }
    }

    renderChildren() {
        return React.Children.map(this.props.children, (child, index) => {
            if (!child) return;
            this.keys[child.key] = true;
            return <TabContent key={index} selected={this.selected == index}>{child}</TabContent>;
        });
    }

    render() {
        return (
            <ApplicationContext.Consumer>
                {
                    (appContext) => {
                        if (appContext) appContext.registerComponent(ComponentTypes.Tab, this);
                        return <div className={this.props.className} ref={this.ref}>
                            {this.renderButtons()}
                            {this.renderChildren()}
                        </div>;
                    }
                }
            </ApplicationContext.Consumer>
        );
    }
}

Tab.defaultProps = {
    className: 'tab',
    name: 'tab',
    buttons: [],
    children: null,

    selected: 0,
    renderAll: true,

    onTabSelected: () => { }
};

Tab.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    buttons: PropTypes.array,
    children: PropTypes.node,

    selected: PropTypes.number,
    renderAll: PropTypes.bool,

    onTabSelected: PropTypes.func
};