import './_labelValue.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormInput } from '../../../../Controls/Form/FormInput';
import { Icon } from '../../../../Controls/Icon/Icon';


// COMPONENT
export class LabelValue extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var classes = ['centrum-flex labelvalue'];
        if (this.props.className) classes.push(this.props.className);
        return <div className={classes.join(' ')}><label>{this.props.onClick ? <a href='' onClick={(e) => { e.preventDefault(); this.props.onClick ? this.props.onClick() : null; }}>{this.props.title}</a> : this.props.title}</label>{this.props.children}</div>;
    }
}

LabelValue.defaultProps = {
    title: '',
    className: null,
    onClick: null
};

LabelValue.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func
};



export class EditableValue extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    edit() {
        this.setState({ edit: true, changeDate: Date.now() });
    }

    save() {
        this.setState({ edit: false, changeDate: Date.now() }, () => {
            this.props.onChange(this.value);
        });
    }

    cancel() {
        this.value = this.props.value;
        this.setState({ edit: false, changeDate: Date.now() }, () => {
            this.props.onChange(this.value);
        });
    }

    render() {
        if (!this.props.editable) {
            return <LabelValue title={this.props.title}>{this.props.value}</LabelValue>;
        }
        return <LabelValue title={this.props.title}>{this.state.edit ? <FormInput key={this.state.changeDate} type='text' value={this.state.value} name={this.props.name} onChange={(data, field, value) => this.value = value} /> : this.state.value}
            {this.props.children}
            {
                this.state.edit ?
                    <React.Fragment>
                        <Icon icon={'check'} className='marginLeft icon-inline-edit icon' onClick={this.save.bind(this)} />
                        <Icon icon={'times'} className='marginLeft icon-inline-edit icon' onClick={this.cancel.bind(this)} />
                    </React.Fragment> :
                    <Icon icon={'edit'} className='marginLeft icon-inline-edit icon' onClick={this.edit.bind(this)} />
            }
        </LabelValue>;
    }
}


EditableValue.defaultProps = {
    title: '',
    value: '',
    name: 'value',
    editable: true,
    onChange: () => { }
};

EditableValue.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    editable: PropTypes.bool,
    children: PropTypes.node,
    onChange: PropTypes.func
};

