import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Panel } from '../../Centrum/Card/Card';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Tab } from '../../Centrum/Tab/Tab';
import { Button } from '../../Controls/Buttons/Button';
import { Form } from '../../Controls/Form/Form';
import { FormInput } from '../../Controls/Form/FormInput';
import { RetailTypes } from './common';
import { CasinoConfiguration } from './Components/CasinoConfiguration';
import { SportConfiguration } from './Components/SportConfiguration';
import { HorseConfiguration } from './Components/HorseConfiguration';
import { PaymentConfiguration } from './Components/PaymentConfiguration';

import { application, Endpoints } from '../../Application/Application';
import { ErrorModal } from '../../Modal/Modals/Error/ErrorModal';
import { lang } from '../../Centrum/Localization/lang';
import { getHistory } from '../../routers/AppRouter';
import { ActionTypes } from '../../Centrum/Common/ActionManager';
//import { Currencies } from '../../Centrum/_models/ModelEnums';
import { API, DataEndpoints } from '../../../v2/Lib/Api/Api';
import { GeneralConfiguration } from './Components/GeneralConfiguration';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';

const defaultRetail = {
    id: 0,
    name: 'Retail',
    code: '',
    type: 0,
    description: '',
    enabled: false,
    configuration: {
        sport: { enabled: false, revenueLimits: [], stakeLimits: [], revenueType: 'GGR' },
        horse: { enabled: false, revenueLimits: [], stakeLimits: [], revenueType: 'GGR' },
        casino: { enabled: false, revenueLimits: [], providerLimits: [], gameLimits: [], disabledProviders: [], disabledGames: [] },
        payment: { enabled: false },
    }
};

export const AddRetail = (props) => {
    const [retail, updateRetail] = useState(props.retail);
    const [parentConfiguration, setParentConfiguration] = useState(null);
    const [saveData, setSaveData] = useState(props.retail);
    const [updateTime, setUpdateTime] = useState(Date.now());
    const [currencies, setCurrencies] = useState([]);
    const tabButtons = [
        {
            name: 'general',
            title: 'General'
        },
        {
            name: 'sports',
            title: 'Sports'
        },
        {
            name: 'horse',
            title: 'Horse Greyhound'
        },
        {
            name: 'casino',
            title: 'Casino'
        },
        {
            name: 'payment',
            title: 'Payment'
        }
    ];

    var historyUnlisten = null;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        getHistory().listen(onHistoryChange);

        if (!isNaN(id)) {
            getRetail(id);
        } else {
            getMyConfig({});
        }
        return () => {
            historyUnlisten();
        };
    }, []);

    useEffect(() => {
        setUpdateTime(Date.now());
    }, [retail]);

    const getMyConfig = (data) => {
        API.post(DataEndpoints.MyConfiguration, {}, 'fetching configuration please wait', 'Unable to fetch configuration').then((result) => {
            var config = JSON.parse(result.result.currencies);
            var currencyConfig = {};
            config.map(x => {
                currencyConfig[x.CurrencyCode] = x.Name;
            });

            setCurrencies(currencyConfig);
            let copy = { ...data };
            copy.defaultCurrency = Object.keys(currencyConfig)[0];
            setSaveData(copy);
        });

    };

    const getRetail = (id) => {
        application().resolve(null, `${Endpoints.GetRetail}/${id}`, null, 'fetching please wait', 'unable to get retail').then((result) => {
            if (result.result.parentRetailId > 0) {
                application().resolve(null, `${Endpoints.GetRetailConfiguration}/${result.result.parentRetailId}`, null, 'fetching please wait', 'unable to get retail').then((result) => {
                    setParentConfiguration(JSON.parse(result.result));
                });
            }
            result.result.configuration = JSON.parse(result.result.configuration);
            if (!result.result?.configuration) result.result.configuration = {};
            if (!result.result?.configuration?.sport) result.result.configuration.sport = { enabled: true };
            if (!result.result?.configuration?.horse) result.result.configuration.horse = { enabled: true };
            if (!result.result?.configuration?.casino) result.result.configuration.casino = { enabled: true };
            if (!result.result?.configuration?.payment) result.result.configuration.payment = { enabled: true };

            updateRetail(result.result);
            setSaveData(result.result);
            getMyConfig(result.result);
        });
    };

    const onHistoryChange = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        if (!isNaN(id)) {
            getRetail(id);
        }
    };


    const calculateCasinoPercentages = (config) => {
        if (!config) return;
        if (!config.casino) return;
        // calculate casino percentages
        const casinoPercentage = config.casino.percentage;
        config.casino.revenueLimits.map(x => {
            if (!parentConfiguration) {
                x.calculatedPercentage = x.percentage;
            } else {
                const parentConfig = parentConfiguration.casino.revenueLimits.find(y => y.type == x.type);
                const percentage = parentConfig.calculatedPercentage ?? parentConfig.percentage;
                if (x.percentage == 0) {
                    x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                } else {
                    x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                    x.calculatedPercentage *= x.percentage / 100;
                }
            }
        });

        config.casino.providerLimits.map(x => {
            if (!parentConfiguration) {
                x.calculatedPercentage = x.percentage;
            } else {
                const parentConfig = parentConfiguration.casino.providerLimits.find(y => y.id == x.id);
                const percentage = parentConfig.calculatedPercentage ?? parentConfig.percentage;
                if (x.percentage == 0) {
                    x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                } else {
                    x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                    x.calculatedPercentage *= x.percentage / 100;
                }
            }
        });

        config.casino?.gameLimits?.map(x => {
            if (!parentConfiguration) {
                x.calculatedPercentage = x.percentage;
            } else {
                const parentConfig = parentConfiguration.casino.gameLimits.find(y => y.id == x.id);
                const percentage = parentConfig.calculatedPercentage ?? parentConfig.percentage;
                if (x.percentage == 0) {
                    x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                } else {
                    x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                    x.calculatedPercentage *= x.percentage / 100;
                }
            }
        });
    };

    const calculateSportAndHorseLimits = (target, config) => {
        if (!config) return;
        if (!config[target]) return;
        const targetPercentage = config[target].percentage;
        config[target].revenueLimits?.map(x => {
            if (!parentConfiguration) {
                x.calculatedPercentage = x.percentage;
            } else {
                const parentConfig = parentConfiguration[target].revenueLimits.find(y => y.type == x.type);
                const percentage = parentConfig.calculatedPercentage ?? parentConfig.percentage;
                if (x.percentage == 0) {
                    x.calculatedPercentage = (percentage * targetPercentage) / 100;
                } else {
                    x.calculatedPercentage = (percentage * targetPercentage) / 100;
                    x.calculatedPercentage *= x.percentage / 100;
                }
            }
        });
    };

    const save = () => {
        if (saveData.type === '') {
            application().modal.open(<ErrorModal title='Type can not be empty'>{lang('Please select one of the types.')}</ErrorModal>);
            return;
        }
        if (saveData.name === '' || !retail.name) {
            application().modal.open(<ErrorModal title='Name can not be empty'>{lang('Please enter a unique name for your retal.')}</ErrorModal>);
            return;
        }


        var copy = Object.assign({}, saveData);
        if (copy.configuration?.sport.enabled && copy.configuration?.sport.revenueLimits && copy.configuration.sport.revenueLimits.length > 0) {
            var hasAllSportLimits = copy.configuration.sport.revenueLimits.find(x => parseInt(x.type) == 3 || parseInt(x.ticketType) == 3);

            if (hasAllSportLimits) {
                if (copy.configuration.sport.revenueLimits.find(x => parseInt(x.type) == 3)) {
                    copy.configuration.sport.revenueLimits = copy.configuration.sport.revenueLimits.filter(x => parseInt(x.type) == 3);
                }
                if (copy.configuration.sport.revenueLimits.find(x => parseInt(x.ticketType) == 3)) {
                    copy.configuration.sport.revenueLimits = copy.configuration.sport.revenueLimits.filter(x => parseInt(x.ticketType) == 3);
                }
            }

            if (copy.configuration.sport.revenueLimits.length == 0) {
                application().modal.open(<Dialog title='Configuration error'>
                    <p className='padding'>Limits not set</p>
                    <p className='padding'>If you select All for Type or Bet Type, you can not add custom configuration against Type or Ticket Types.</p>
                </Dialog>);
                return;
            }
        }

        calculateSportAndHorseLimits('sport', copy.configuration);
        calculateSportAndHorseLimits('horse', copy.configuration);
        calculateCasinoPercentages(copy.configuration);

        if (!copy.configuration) copy.configuration = { sport: null, horse: null, casino: null };
        copy.configuration = JSON.stringify(copy.configuration);
        copy.id = saveData.id;

        application().resolve(null, Endpoints.AddModifyRetail, copy, 'saving please wait', 'unable to save retail').then((result) => {
            result.result.configuration = JSON.parse(result.result.configuration);
            getHistory().push(`/Retails/${ActionTypes.AddModifyRetail}?&id=${result.result.id}`);
            application().snackbar.open('Retail saved');
        });
    };


    const onUpdate = (data) => {
        setSaveData(data);
    };

    const onUpdateConfiguration = (data) => {
        const copy = Object.assign({}, saveData);
        copy.configuration = data.configuration;
        setSaveData(copy);
    };

    const clear = () => {
        setSaveData(defaultRetail);
        updateRetail(defaultRetail);
        setUpdateTime(Date.now());
        getHistory().push(`/Retails/${ActionTypes.AddModifyRetail}`);
    };

    if (!saveData) return <React.Fragment />;

    return <Card caption={saveData.id == 0 ? 'Add Retail' : 'Update Retail'} key={updateTime}>
        <Form data={saveData}>
            <div className='row'>
                <FormInput title='Type' type={InputTypes.select} name='type'
                    value={saveData.type}
                    values={RetailTypes} onChange={(form, field, value) => {
                        var copy = Object.assign({}, saveData);
                        copy.type = parseInt(value);
                        onUpdate(copy);
                    }} />
                <FormInput title='Name' name='name'
                    value={saveData.name}
                    onChange={(form, field, value) => {
                        var copy = Object.assign({}, saveData);
                        copy.name = value;
                        onUpdate(copy);
                    }} />
                <FormInput title='Code' name='code'
                    value={saveData.code}
                    onChange={(form, field, value) => {
                        var copy = Object.assign({}, saveData);
                        copy.code = value;
                        onUpdate(copy);
                    }} />
                <FormInput title='DefaultCurrency' name='defaultCurrency'
                    value={saveData.defaultCurrency}
                    type={InputTypes.select}
                    values={currencies}
                    onChange={(form, field, value) => {
                        var copy = Object.assign({}, saveData);
                        copy.defaultCurrency = value;
                        onUpdate(copy);
                    }} />
            </div>
            <div className='row'>
                <FormInput title='Description' name='description' type={InputTypes.textArea}
                    value={retail.description}
                    onChange={(form, field, value) => {
                        var copy = Object.assign({}, saveData);
                        copy.description = value;
                        onUpdate(copy);
                    }} />
            </div>
            <div className='row'>
                <FormInput title='Enabled' name='enabled' type={InputTypes.bool}
                    value={retail.enabled}
                    onChange={(form, field, value) => {
                        var copy = Object.assign({}, saveData);
                        copy.enabled = value;
                        onUpdate(copy);
                    }} />
            </div>
        </Form>
        <Tab buttons={tabButtons}>
            <GeneralConfiguration configuration={saveData} key={updateTime} onUpdate={(data) => onUpdateConfiguration(data)} />
            <SportConfiguration configuration={saveData} key={updateTime} onUpdate={(data) => onUpdateConfiguration(data)} />
            <HorseConfiguration configuration={saveData} key={updateTime} onUpdate={(data) => onUpdateConfiguration(data)} />
            <CasinoConfiguration configuration={saveData} key={updateTime} onUpdate={(data) => onUpdateConfiguration(data)} />
            <PaymentConfiguration configuration={saveData} key={updateTime} onUpdate={(data) => onUpdateConfiguration(data)} />
        </Tab>
        <Panel className='padding' >
            <div className='centrum-flex stretch'>
                <div className='flex'>
                    <Button className='' title={'Clear'} onClick={() => {
                        clear();
                    }} />
                    <Button className='alignRight' title={!saveData.id ? 'Save Retail' : 'Update Retail'} onClick={() => {
                        save();
                    }} />
                </div>
            </div>
        </Panel>
    </Card>;
};


AddRetail.defaultProps = {
    retail: Object.assign({}, defaultRetail)
};
AddRetail.propTypes = {
    retail: PropTypes.object
};