
import React from 'react';
import PropTypes from 'prop-types';
import { CasinoLimitsGeneral } from './CasinoLimitsGeneral';
import { application, Endpoints } from '../../../../Application/Application';
import { Card } from '../../../../Centrum/Card/Card';
import { Button } from '../../../../Controls/Buttons/Button';



export class CasinoLimits extends React.Component {
    constructor(props) {
        super(props);

        var state = Object.assign({}, props);
        this.state = state;
    }

    componentDidMount() {
        try {
            application().resolve(null, Endpoints.GetApplicationCasinoLimits, {}, 'fetching limits', 'unable to fetch limits').then((limits) => {
                if (!limits.result) {
                    limits.result = {
                        general: {},
                        groups: {}
                    };
                } else {
                    limits.result = JSON.parse(limits.result);
                }

                this.setState({ limits: limits.result, updateKey: Date.now() });
            });
        } catch (err) {
            console.log(err);
        }
    }

    save() {
        const limits = this.generalLimits.getData();
        try {
            application().resolve(null, Endpoints.SaveApplicationCasinoLimits, limits, 'saving application limits', 'unable to save limits').then(() => {
            });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return <React.Fragment>
            <CasinoLimitsGeneral key={this.state.updateKey} limits={this.state.limits} saveEnabled={false} ref={(r) => this.generalLimits = r} />
            <Card className='margin-top centrum-flex'>
                <Button title='Save' type='submit' className='margin alignRight' onClick={() => this.save()} />
            </Card>
        </React.Fragment >;
    }
}

CasinoLimits.defaultProps = {
    selectedCurrency: 'USD',
    limits: {}
};

CasinoLimits.propTypes = {
    selectedCurrency: PropTypes.string,
    limits: PropTypes.object
};

