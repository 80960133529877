import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../v2/Components/Card/Card';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { Form } from '../../../v2/Components/Controls/Form/Form';
import { API, SetupEndpoints } from '../../../v2/Lib/Api/Api';
import { Icon } from '../../../v2/Components/Icon/Icon';
import { lang } from '../../../v2/Lib/Common/language';
import { application } from '../../Application/Application';
import { FormInput } from '../../../v2/Components/Controls/Form/FormInput';


const parseTax = (v) => {
    const defaultValues = {
        Win: 0,
        Percentage: 0,
        Net: false
    };
    if (!v) return { ...defaultValues };

    if (typeof v === 'object' && v !== null) return v;

    try {
        if (!JSON.parse(v)) return { ...defaultValues };
    } catch (err) {
        //
    }

    return JSON.parse(v);
};


const SetupTax = (props) => {
    return <Card title={props.title} className='border'>
        <Form>
            <div className='row'>
                <FormInput title='Win Amount' name='Win' value={props.data.Win} onChange={(v, f, t) => props.onChange(v, f, t)} />
                <FormInput title='Tax %' name='Percentage' value={props.data.Percentage} onChange={(v, f, t) => props.onChange(v, f, t)} />
                <FormInput title='Calculate from net' name='Net' value={props.data.Net} type={'bool'} onChange={(v, f, t) => props.onChange(v, f, t)} />
            </div>
        </Form>
    </Card>;
};

SetupTax.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
    defaultCurrency: PropTypes.string,
    onChange: PropTypes.func
};


export const SetupSportTaxes = () => {
    const [configuration, setConfiguration] = useState(false);
    useEffect(() => {
        API.post(`${SetupEndpoints.SportTaxes}`, {}).then((result) => {
            let config = result.result;
            config.sport = parseTax(config.sport);
            config.horse = parseTax(config.horse);
            setConfiguration(config);
        });
    }, []);

    const save = () => {
        API.post(`${SetupEndpoints.SaveSportTaxes}`, configuration).then(() => {
            application().snackbar.open('Taxes are saved.');
        });
    };

    const change = (target, value, name) => {
        let copy = { ...configuration };
        copy[target][name] = name !== 'Net' ? parseFloat(value) : value;
        setConfiguration(copy);
    };

    if (!configuration) return <></>;

    return <Card title='Taxes'
        buttons={() => <Button title='Save' onClick={() => save()} />}>
        <div className='border radius padding flex gap-5 margin-bottom'>
            <Icon icon='info-circle' />
            <span>{lang('Win amount value is calculated as greater then input value. 0 value will tax all wins')}</span>
        </div>
        <SetupTax data={configuration?.sport} title='Sports' onChange={(v, field) => change('sport', v, field.name)} />
        <SetupTax data={configuration?.horse} title='Horse' onChange={(v, field) => change('horse', v, field.name)} />
    </Card>;
};