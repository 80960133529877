import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Page } from '../../Centrum/Page/Page';
import { AffiliateDashboard } from '../Affiliates/AffiliateDashboard';
import { MainDashboard } from './main/MainDashboard';
import { Endpoints, application } from '../../Application/Application';
import { toPascalCase } from '../../Centrum/helpers';
import { CentrumApplication, ApplicationContext } from '../../Centrum/Application/CentrumApplication';




// COMPONENT
class Dashboard extends Page {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.user.loginInformation.AffiliateId > 0) {
            application().resolve(null, Endpoints.GetAffiliate + '/' + this.props.user.loginInformation.AffiliateId, {}, 'retreiving affiliates', 'unable to fetch affiliates').then((result) => {
                this.setState({ affiliate: result.result, updateDate: Date.now() });
            });
        }
    }


    render() {
        if (this.props.user.loginInformation.AffiliateId) {
            if (!this.state.affiliate) {
                return <div />;
            }

            return <CentrumApplication caption='Dashboard' icon='dashboard' loading={this.props.loading}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;

                            return <div className='application-contents'><AffiliateDashboard affiliate={toPascalCase(this.state.affiliate)} /></div>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>;
        }

        return <MainDashboard />;
    }
}

Dashboard.defaultProps = {
    user: null,
    affiliate: null
};

Dashboard.propTypes = {
    user: PropTypes.object,
    affiliate: PropTypes.object
};


const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export { hoc as Dashboard };

