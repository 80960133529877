// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { application, Endpoints } from '../../../Application/Application';
import { Table } from '../../../Controls/Table/Table';
import { Button } from '../../../Controls/Buttons/Button';




// COMPONENT
class ConfigureOperatorCountries extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    componentDidMount() {
        application().resolve(null, Endpoints.EnabledCountries, {}, 'fetching countries please wait', 'Unable to fetch countries').then((result) => {


            var countries = JSON.parse(this.props.configuration.countries ? this.props.configuration.countries : '[]');

            result.result.map(country => {
                var found = countries.find(x => x.Id == country.Id);
                if (found) {
                    country.__selected = true;
                }
            });


            this.setState({ countries: result.result, loaded: true });
        });
    }


    save() {
        application().resolve(null, Endpoints.SaveOperatorCountries + '/' + this.props.operatorId, this.state.countries.filter(x => x.__selected), 'updating countries please wait', 'Unable to update countries').then(() => {
            application().snackbar.open('Operator countries saved.');
        });
    }

    onSelectionChange(row, index, value) {
        //this.setState({selected:selection});
        if (!row) {
            this.state.countries.map(x => {
                x.__selected = value;
            });
            return;
        }

        row.__selected = value;
    }

    render() {
        return <React.Fragment>
            {
                this.state.loaded &&
                <div className='padding'>
                    <Table model={'operatorEnabledCountries'} data={this.state.countries} selectable={true} onSelectionChange={(row, index, value) => this.onSelectionChange(row, index, value)} />
                </div>
            }
            <Button className='margin' onClick={() => this.save()} title='Save'></Button>
        </React.Fragment>;
    }
}

ConfigureOperatorCountries.defaultProps = {
    countries: [],
    selected: []
};

ConfigureOperatorCountries.propTypes = {
    operatorId: PropTypes.number.isRequired,
    selected: PropTypes.array,
    configuration: PropTypes.object,
    countries: PropTypes.array
};

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({

    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ConfigureOperatorCountries);
export { hoc as ConfigureOperatorCountries };