import './_slider.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Panel } from '../../../../Centrum/Card/Card';
import { application, Endpoints } from '../../../../Application/Application';
import { ConfirmationModal } from '../../../../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../../../../Controls/Form/Form';
import { FormInput } from '../../../../Controls/Form/FormInput';
import Table from '../../../../Controls/Table/SortableTable/SortableTable';
import { Button } from '../../../../Controls/Buttons/Button';
import { ImageDrop } from '../../../../Controls/ImageDrop/ImageDrop';
import { InputTypes } from '../../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Tab } from '../../../../Centrum/Tab/Tab';
import { lang } from '../../../../Centrum/Localization/lang';
import { Switch } from '../../../../Controls/Inputs/Switch/Switch';
import { API, DataEndpoints } from '../../../../../v2/Lib/Api/Api';

export const SliderConfigurator = (props) => {
    const [configure, toggleConfigure] = useState(false);
    const items = props.configuration ? (props.configuration.items ? props.configuration.items : []) : [];

    const renderConfiguration = () => {
        return <div className='flex border radius padding margin'>
            <AppSlider
                configuration={props.configuration}
                prefix={props.prefix} page={props.name} defaultLanguage={props.defaultLanguage}
                onSave={props.onSave.bind(this)} />
        </div>;
    };

    return <div className='flex vertical gap-5'>
        <div className='flex gap-5 padding'>
            <label>Slider</label>
            <span>{lang('%1 items', [items.length])}</span>
            <div className='align-right'>
                <Button title={configure ? 'Close' : 'Configure'} onClick={() => toggleConfigure(!configure)} />
            </div>
        </div>
        {configure && renderConfiguration()}
    </div>;
};

SliderConfigurator.propTypes = {
    name: PropTypes.string,
    configuration: PropTypes.object,
    prefix: PropTypes.string,
    defaultLanguage: PropTypes.string,

    onSave: PropTypes.func
};


export const openSlider = (props) => {
    var context = null;
    application().modal.open(<ConfirmationModal
        buttons={{
            ok: {
                title: 'Save',
                enableClose: false
            },
            cancel: {
                title: 'Close'
            }
        }}
        onConfirm={() => {
            context.save().then((data) => {
                props.onSave(data);
            }).catch(() => {
                return false;
            });
        }}
        title='Slider' className='large'>
        <AppSlider
            configuration={props.configuration}
            prefix={props.prefix} page={props.name} defaultLanguage={props.defaultLanguage} context={(value) => context = value}
            onSave={props.onSave.bind(this)} />
    </ConfirmationModal>);
};

const renderSliderImageThumbnail = (row) => {
    const imageFirstKey = Object.keys(row.img);
    const src = row.img[imageFirstKey];
    return <img src={src} style={{ display: 'none' }} />;
};

const sliderItemsModel = {
    fields: [
        {
            name: 'title',
            display: 'Title',
            formatter: (val, row) => {
                return renderSliderImageThumbnail(row);
            }
        }
    ]
};

const ConfigureImage = (props) => {
    const { items, item, selectedLanguage, mobile, cdn, newImages, setItems, setImages, setImageTypes, selectItem, busy } = props;
    const [newImage, setNewImage] = useState(false);
    const defaultItemValuesForLanguage = (values, item, language) => {
        values.map(x => {
            if (!item.hasOwnProperty(x)) {
                item[x] = {};
            }

            if (!item[x].hasOwnProperty(language)) {
                item[x][language] = '';
            }
        });
    };

    var imageDrop = null;
    if (!item) return <div />;
    defaultItemValuesForLanguage(['title', 'description'], item, selectedLanguage);

    if (item.transparent == undefined) item.transparent = false;


    var itemKey = item.key;
    if (mobile) itemKey = itemKey.toString().split('_')[0] + '_m';
    const itemData = {
        title: item.title[selectedLanguage],
        description: item.description[selectedLanguage],
        url: item.url,
        transparent: item.transparent,
        openNewWindow: item.openNewWindow ? item.openNewWindow : false
    };
    var source = `${cdn}/${itemKey}_${selectedLanguage}.${item.transparent ? 'png' : 'jpg'}`;
    var imageName = itemKey;

    if (newImages.hasOwnProperty(itemKey)) {
        source = newImages[itemKey][selectedLanguage];
    } else {
        if (mobile && !item.mobile) item.mobile = {};
        const image = !mobile ? item.img[selectedLanguage] : item.mobile[selectedLanguage];
        if (image) {
            var key = image.key ? image.key : itemKey;
            if (mobile) key = key.split('_')[0] + '_m';
            source = `${cdn}/${key}_${selectedLanguage}.${item.transparent ? 'png' : 'jpg'}?&${Date.now()}`;
            imageName = key;
        }
    }

    return <React.Fragment>
        <div className={'centrum-flex vertical stretch ' + (busy ? 'disabled' : '')}>
            <ImageDrop name={imageName} newKey={true} path={`${cdn}/`}
                imageSource={source} key={itemKey}
                onImageDrop={(imageData) => {
                    var newKey = Date.now();
                    if (mobile) {
                        newKey = itemKey.split('_')[0] + '_m';
                    }
                    var images = Object.assign({}, newImages);
                    if (!images.hasOwnProperty(itemKey)) {
                        images[itemKey] = {};
                    }
                    images[itemKey][selectedLanguage] = {
                        key: newKey,
                        image: imageData
                    };
                    var target = mobile ? item.mobile : item.img;
                    target[selectedLanguage] = {
                        key: newKey
                    };

                    setImages(images);

                    if (!target.hasOwnProperty(selectedLanguage)) {
                        target[selectedLanguage] = {
                            key: newKey
                        };
                    }

                    var imageTypes = Object.assign({}, imageTypes);
                    imageTypes[itemKey] = item.transparent ? 'png' : 'jpg';
                    setImageTypes(imageTypes);
                    setNewImage(true);
                }} ref={(r) => imageDrop = r} />
            <Button title='Remove Image' className='alignRight button marginRight' onClick={() => {
                imageDrop.clearImage();
                var images = Object.assign({}, newImages);
                if (images.hasOwnProperty(itemKey)) {
                    var image = images[itemKey][selectedLanguage];
                    if (image) {
                        delete images[itemKey][selectedLanguage];
                        setImages(images);
                    }
                } else {
                    if (mobile) delete item.mobile[selectedLanguage];
                    if (!mobile) delete item.img[selectedLanguage];
                    setItems(items);
                }
            }} />
        </div>
        <Panel className='margin'>
            <Form data={itemData} key={itemKey}>
                <div className='row'>
                    <FormInput name='title' title='Title' type={InputTypes.text} onChange={(form, field, value) => {
                        item.title[selectedLanguage] = value;
                    }} />
                    <FormInput name='transparent' title='Transparent' type={InputTypes.bool} onChange={(form, field, value) => {
                        item.transparent = value;
                        var imageTypes = Object.assign({}, imageTypes);
                        imageTypes[itemKey] = item.transparent ? 'png' : 'jpg';
                        setImageTypes(imageTypes);
                    }} />
                </div>
                <div className='row'>
                    <FormInput name='description' title='Description' type={InputTypes.textArea} onChange={(form, field, value) => {
                        item.description[selectedLanguage] = value;
                    }} />
                </div>
                <div className='row'>
                    <FormInput name='url' title='Url' type={InputTypes.text} onChange={(form, field, value) => {
                        item.url = value;
                    }} />
                    <FormInput type={InputTypes.bool} title='Open in new window' name='openNewWindow' onChange={(form, field, value) => {
                        item.openNewWindow = value;
                    }} />
                </div>
                <div className='row'>
                    <FormInput name='game' title='Open Game' type={InputTypes.text} onChange={(form, field, value) => {
                        item.game = value;
                    }} />
                    <FormInput name='event' title='Open Event' type={InputTypes.text} onChange={(form, field, value) => {
                        item.event = value;
                    }} />
                </div>
            </Form>
        </Panel>
        <div className='flex padding'>
            <Button title='Remove Slide' className='button' onClick={() => {
                var itemsCopy = Object.assign([], items);
                const index = itemsCopy.findIndex(x => x.key == itemKey);
                itemsCopy.splice(index, 1);
                setItems(itemsCopy);
                selectItem(null);
            }} />
            {
                newImage && <Button title='Update Image' className='align-right button' onClick={() => {
                    if (props.onImageDrop) {
                        props.onImageDrop();
                    }
                }} />
            }
        </div>
    </React.Fragment>;
};

ConfigureImage.propTypes = {
    cdn: PropTypes.string,
    item: PropTypes.object,
    items: PropTypes.array,
    newImages: PropTypes.object,
    setImages: PropTypes.func,
    setItems: PropTypes.func,
    setImageTypes: PropTypes.func,
    selectItem: PropTypes.func,
    selectedLanguage: PropTypes.string,
    mobile: PropTypes.bool,
    busy: PropTypes.bool,
    onImageDrop: PropTypes.func
};


export const AppSlider = (props) => {
    const oldConfiguration = JSON.parse(JSON.stringify(Object.assign({}, props.configuration)));
    const [items, setItems] = useState(oldConfiguration ? oldConfiguration.items : []);
    const [item, selectItem] = useState(null);
    const [selectedLanguage, selectLanguage] = useState(props.defaultLanguage);
    const [newImages, setImages] = useState({});
    const [imageTypes, setImageTypes] = useState({});
    const [saveStatus, setSaveStatus] = useState(null);
    const [busy, setBusy] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [loaded, setLoaded] = useState(false);


    const cdn = `//${props.prefix}.tentangle.com/content/pages/${props.page}/sliders`;
    var table = null;

    useEffect(() => {
        API.post(DataEndpoints.AvailableLanguages, {}).then((result) => {
            result.result.map(x => {
                x.key = x.key.toLowerCase();
            });
            setLanguages(result.result);
            setLoaded(true);
        });
    }, []);


    useEffect(() => {
        var copy = { ...props.configuration };
        copy.items = items;
        props.onSave(copy);
    }, [items]);

    const save = () => {
        setBusy(true);
        try {
            var copy = { ...props.configuration };
            copy.items = items;
            application().post(Endpoints.CMSSaveImage, {
                config: copy,
                images: newImages,
                imageTypes: imageTypes,
                path: 'content/pages/' + props.page + '/sliders/'
            }).then(() => {
                setSaveStatus({ success: true });
                setBusy(false);
                props.onSave(copy);
            }).catch(() => {
                setSaveStatus({ success: false });
                setBusy(false);
                props.onSave(copy);
            });
        } catch (err) {
            setBusy(false);
        }
    };

    const renderConfiguration = () => {
        if (!item) return;
        //const { items, item, selectedLanguage, mobile, cdn, newImages, setImages, setImageTypes, selectItem, busy } = props;
        return <ConfigureImage
            key={mobile}
            items={items}
            item={item}
            cdn={cdn}
            newImages={newImages}
            setImageTypes={setImageTypes}
            setImages={setImages}
            setItems={setItems}
            selectItem={selectItem}
            busy={busy}
            selectedLanguage={selectedLanguage}
            onImageDrop={() => {
                save();
            }}
            mobile={mobile} />;
    };


    const renderLanguages = () => {
        const sorted = Object.values(Object.assign({}, languages)).sort((a) => {
            if (a.key == props.defaultLanguage) return -1;
            return 0;
        });
        const copy = sorted.map(language => {
            return {
                title: language[Object.keys(language)[0]]
            };
        });

        return <Tab buttons={copy} onTabSelected={(index) => {
            selectLanguage(sorted[index].key);
            selectItem(null);
            table.highlighted.clear();
        }} />;
    };


    const renderSavestatus = () => {
        if (!saveStatus) return;

        return <div className={'marginBottom padding half ' + (saveStatus.success ? 'success' : 'error')}>
            <div className='centrum-flex'>
                {saveStatus.success && <span className='center'>{lang('Slider saved.')}</span>}
                {!saveStatus.success && <span className='center'>{lang('Unable to save slider.')}</span>}
            </div>
        </div>;
    };


    if (!loaded) return <></>;

    return <div className='slider-configuration vertical gap-5'>
        <div className='panel padding margin'>
            <label>{lang('Disable slider on mobile view')}</label><Switch checked={props.configuration.disableMobile} onChange={(v) => props.configuration.disableMobile = v} />
        </div>
        {renderSavestatus()}
        <div className='margin-top marginBottom'>
            {renderLanguages()}
        </div>
        <div className='centrum-flex margin-top nowrap stretch'>
            <Card className='slider-items' caption='Items' menu={<Button title='Add New' onClick={() => {
                const newItems = Object.assign([], items);
                var newItem = {
                    title: {},
                    description: {},
                    img: {},
                    mobile: {},
                    key: Date.now(),
                    url: '',
                    openNewWindow: false
                };

                newItem.title[props.defaultLanguage] = 'New Slide';
                newItem.description[props.defaultLanguage] = '';

                newItems.push(newItem);
                setItems(newItems);
            }} />}>
                <Table
                    model={sliderItemsModel}
                    data={items}
                    key={items}
                    context={(context) => {
                        table = context;
                    }}
                    options={{
                        header: {
                            show: false
                        },
                        draggable: {
                            enabled: true
                        },
                        selectable: false
                    }}
                    onRowClick={(tr, row) => {
                        selectItem(row);
                    }}
                    onRenderRow={(row, elements) => {
                        const image = row.img[selectedLanguage];
                        var src = '';
                        if (image) {
                            var key = image.key ? image.key : row.key;
                            src = `${cdn}/${key}_${selectedLanguage}.${row.transparent ? 'png' : 'jpg'}?&${Date.now()}`;
                        }
                        const title = row.title[selectedLanguage];
                        return <tr>
                            {elements}
                            <td>{title}</td>
                            <td>{image != null && <img src={src} />}</td>
                        </tr>;
                    }}
                    onDataChange={(row, index, value) => {
                        var total = value.length;
                        value.map(x => {
                            x.weight = total;
                            total--;
                        });
                        setItems(value);
                    }}
                />
            </Card>
            <Card key={item} className={item ? '' : 'disabled'}>
                <Tab buttons={
                    [
                        {
                            name: 'Desktop',
                            title: 'Desktop'
                        },
                        {
                            name: 'Mobile',
                            title: 'Mobile'
                        }
                    ]
                } onTabSelected={(selected) => setMobile(selected == 1)}>
                </Tab>
                {renderConfiguration()}
            </Card>
        </div>
    </div >;
};


AppSlider.defaultProps = {
    configuration: {
        items: []
    },
    languages: [
    ],
    defaultLanguage: 'tr',
    selectedItem: 0,
    selectedLanguage: 0,
    prefix: '',
    page: '',
};

AppSlider.propTypes = {
    destination: PropTypes.string,
    configuration: PropTypes.object,
    languages: PropTypes.array,
    defaultLanguage: PropTypes.string,
    selectedItem: PropTypes.number,
    selectedLanguage: PropTypes.number,
    prefix: PropTypes.string,
    page: PropTypes.string,
    onSave: PropTypes.func
};
