// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { application, Endpoints } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { lang } from '../../Centrum/Localization/lang';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';
import { Button } from '../../Controls/Buttons/Button';
import { Table } from '../../Controls/Table/Table';


// COMPONENT
class PlayerRiskManagementPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    componentDidMount() {
        application().resolve(null, Endpoints.GetProviderGameTypes, {}, 'building data please wait', 'unable to build data').then((gameTypes) => {
            application().resolve(null, Endpoints.GetPlayerRiskMangement + this.props.player.id, {}, 'building data please wait', 'unable to build data').then((result) => {
                this.setState({ gameTypes: gameTypes.result, configuration: result.result });
            });
        });
    }


    save(configuration, gameTypeLimits) {
        configuration.gameTypeLimits = gameTypeLimits;
        application().resolve(null, Endpoints.SavePlayerRiskMangement + this.props.player.id, configuration, 'saving configuration please wait', 'unable to save configuration').then(() => {
            application().modal.open(<Dialog title='Configuration saved'><p>{lang('Gaming Risk management configured')}</p></Dialog>);
        });
        this.setState({ update: true });
    }



    render() {
        if (this.state.configuration.length == 0) return <React.Fragment />;
        if (this.state.gameTypes.length == 0) return <React.Fragment />;

        return <React.Fragment>
            {
                this.state.configuration.map(config => {
                    var provider = this.state.gameTypes.find(x => x.providerId == config.providerId);
                    var configurationData = [];
                    var selected = [];
                    provider.types.map(type => {
                        var data = {
                            type: type,
                            enabled: false,
                            stop: false,
                            typeId: type,
                            betLimit: 0
                        };

                        var existingData = config.gameTypeLimits.find(d => d.type == type);
                        if (existingData) {
                            data.betLimit = existingData.betLimit;
                            data.enabled = existingData.enabled;
                            data.stop = existingData.stop;
                        }

                        selected.push(data.enabled ? data : undefined);

                        configurationData.push(data);
                    });

                    return <div key={provider.name} className='margin'><Card key={provider.name} caption={provider.name}>
                        <React.Fragment>
                            <Table model={'configureRiskManagementGameTypeLimit'}
                                data={configurationData} selected={selected}
                                selectable={true}
                                editable={true}
                                editableFields='betLimit,stop'
                                selectableFields='enabled' 
                                onRenderRow={(row) => {
                                    if (row.stop) return {
                                        className: 'stopbet'
                                    };

                                    return {
                                        className: ''
                                    };
                                }} />
                            <Button className='margin' onClick={() => this.save(config, configurationData)}>{lang('Save')}</Button>
                        </React.Fragment>
                    </Card></div>;
                })
            }
        </React.Fragment>;
    }
}

PlayerRiskManagementPage.defaultProps = {
    player: null,
    configuration: [],
    gameTypes: []
};

PlayerRiskManagementPage.propTypes = {
    player: PropTypes.object,
    configuration: PropTypes.array,
    gameTypes: PropTypes.array
};

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({

    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(PlayerRiskManagementPage);
export { hoc as PlayerRiskManagementPage };