import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export class AutoComplete extends React.Component {


    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            // The active selections index
            activeSuggestion: 0,
            // The suggestions that match the users input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInputKey: '',
            userInputValue: '',
            hidden: false
        };
    }

    onChange = e => {
        this.showSuggestionsAction(e);
    };

    showSuggestionsAction(e) {
        const { suggestions } = this.props;
        const userInputKey = e.currentTarget.value;

        // Filter our suggestions that dont contain the users input
        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.value.toLowerCase().indexOf(userInputKey.toLowerCase()) > -1
        );

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInputKey: e.currentTarget.value,
            userInputValue: e.currentTarget.id
        });
    }

    hideSuggestionsAction(e) {
        this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
            userInputKey: e.currentTarget.value
        });
    }

    onClick = e => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInputKey: e.currentTarget.innerText,
            userInputValue: e.currentTarget.id
        });
    };

    onFocus = e => {
        this.showSuggestionsAction(e);
    }

    onPointerLeave() {
        this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
        });
    }

    onKeyDown = e => {

        const { activeSuggestion, filteredSuggestions } = this.state;
        // User pressed the enter key
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInputKey: filteredSuggestions[activeSuggestion].value,
                userInputValue: filteredSuggestions[activeSuggestion].key
            });
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            this.scrollUl(e.keyCode, activeSuggestion);
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.scrollUl(e.keyCode, activeSuggestion);
            this.setState({ activeSuggestion: activeSuggestion + 1 });

        }
    };

    scrollUl(keyCode, activeSuggestion) {
        const node = this.myRef.current;
        if (node) {
            const height = node.firstChild.offsetHeight;
            if (keyCode == 40) {
                node.scrollTop = activeSuggestion * height;
            } else if (keyCode == 38) {
                node.scrollTop = (activeSuggestion-2) * height;
            }
        }
    }

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            onFocus,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInputKey,
                userInputValue
            }
        } = this;

        let suggestionsListComponent;
        if (showSuggestions) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul ref={this.myRef} className='suggestions' onPointerLeave={this.onPointerLeave.bind(this)}>
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;
                            if (index === activeSuggestion) {
                                className = 'suggestion-active';
                            }

                            return (
                                <li tabIndex={index} className={className} key={suggestion.key} id={suggestion.key} onClick={onClick} >
                                    {suggestion.value}
                                </li>
                            );
                        })}
                    </ul>

                );
            } else {
                suggestionsListComponent = (
                    <div className='no-suggestions'>
                        <em>No suggestions, you are on your own!</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    type='text'
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    value={userInputKey}
                    placeholder='Please Select'
                />
                <input
                    name={this.props.name}
                    type='text'
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    value={userInputValue}
                    hidden={true}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }

    static propTypes = {
        suggestions: PropTypes.instanceOf(Array),
        name: PropTypes.string,
        acceptEmptyValues: PropTypes.bool
    };

    static defaultProps = {
        suggestions: [],
        name: '',
        acceptEmptyValues: false
    };
}


