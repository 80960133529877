

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { Page } from '../../../Centrum/Page/Page';
import { Panel } from '../../../Centrum/Card/Card';
import { Form } from '../../../Controls/Form/Form';
import { Switch } from '../../../Controls/Inputs/Switch/Switch';
import { Button } from '../../../Controls/Buttons/Button';
import { application, Endpoints } from '../../../Application/Application';
import { Dialog } from '../../../Modal/Modals/Dialog/Dialog';
import { lang } from '../../../Centrum/Localization/lang';



// COMPONENT
class SportsConfiguration extends Page {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, props);
    }

    componentDidMount() {
        application().resolve(null, Endpoints.GetSportsConfiguration, {}, 'building data please wait', 'unable to build data').then((result) => {
            var configuration = { Cashout: true };
            try {
                configuration = JSON.parse(result.result);
            } catch (err) {
                //
                configuration = { Cashout: true };
            }
            this.setState({ configuration: configuration ?? { Cashout: true } });
        });

    }

    save() {
        application().resolve(null, Endpoints.SaveSportsConfiguration, this.state.configuration, 'saving configuration please wait', 'unable to save configuration').then(() => {
            application().modal.open(<Dialog title='Configuration saved'><p>{lang('Sports settings updated')}</p></Dialog>);
        });
    }

    render() {
        if (!this.state.configuration) return <div />;
        return <React.Fragment>
            <Panel title='Sports Configuration'>
                <Form data={this.state.configuration}>
                    <div className='row'>
                        <Switch name='Cashout' title='Cashout' checked={this.state.configuration.Cashout} onChange={(value) => {
                            this.state.configuration.Cashout = value;
                            this.setState(this.state.configuration);
                        }} />
                    </div>
                    <Button title='Save' onClick={() => this.save()} />
                </Form>
            </Panel>
        </React.Fragment>;
    }
}

SportsConfiguration.defaultProps = {
};

SportsConfiguration.propTypes = {
    limits: PropTypes.object
};


const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(SportsConfiguration);
export { hoc as SportsConfiguration };