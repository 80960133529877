import '../_table.scss';
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from './arrayMove';
import { TableHead } from './TableHead';


const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement(props => <TableRow {...props} />);


const createModel = (model) => {
    if (typeof (model) === 'object') {
        return model;
    }
    return null;
};


const createOptions = (options) => {
    if (!options) {
        options = {
            data: {
                delete: {
                    enabled: false,
                    header: false
                }
            },
            header: {
                show: true,
            },
            draggable: {
                enabled: false
            },
            selectable: false
        };
    }


    if (!options.header) {
        options.header = {
            show: true
        };
    }
    return options;
};





const Table = (props) => {
    const [model, setModel] = useState(null);
    const [data, setData] = useState(null);
    const [highlightedRow, setHighlighted] = useState(null);
    var table = null;
    const options = createOptions(props.options);

    const context = {
        highlighted: {
            row: highlightedRow ? highlightedRow.row : null,
            clear: () => {
                if (highlightedRow) {
                    highlightedRow.row.__highlighted = false;
                    highlightedRow.element.classList.remove('row-highlighted');
                }
                setData(data);
            }
        }
    };

    useEffect(() => {
        props.context(context);
    });

    const onSortStart = useCallback(({ node, index, collection, isKeySorting }) => {
        if (props.onSortStart) {
            props.onSortStart(node, index, collection, isKeySorting);
        }
        if (highlightedRow && highlightedRow.row) {
            highlightedRow.row.__highlighted = false;
        }
        node.classList.remove('row-highlighted');
    });

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        var items = oldItems => {
            var res = arrayMove(oldItems, oldIndex, newIndex);
            props.onDataChange(null, null, res);
            return res;
        };

        if (props.onSortEnd) {
            props.onSortEnd(oldIndex, newIndex);
        }

        if (highlightedRow) {
            if (highlightedRow.index == oldIndex) {
                data[oldIndex].__highlighted = false;
                data[newIndex].__highlighted = true;
                highlightedRow.row = data[newIndex];
                highlightedRow.element = table.getElementsByTagName('tbody')[0].children[newIndex];
                highlightedRow.element.classList.add('row-highlighted');
            }
        }
        setData(items);
    });

    const onSelect = (row) => {
        var foundRow = data.find(x => x == row);
        if (foundRow) {
            foundRow.__selected = !foundRow.__selected;
            var result = Object.assign([], data);
            props.onDataChange(null, null, result);
        }
    };

    const onSelectAll = () => {
        const allSelected = data.filter(x => !x.__selected).length == 0;
        data.map(x => {
            x.__selected = allSelected ? false : true;
        });
        props.onDataChange(null, null, data);
    };

    const onDelete = (row) => {
        data.splice(data.findIndex(x => x == row), 1);
        var result = Object.assign([], data);
        props.onDataChange(null, null, result);
    };

    const onDeleteAll = () => {
        props.onDataChange(null, null, []);
    };

    const handleTableClick = (e) => {
        const tr = e.target.parentElement;
        const tbody = tr.parentElement;
        const index = Array.prototype.indexOf.call(tbody.children, tr);
        switch (e.target.nodeName) {
            case 'TD':
                if (props.onRowClick) {
                    props.onRowClick(tr, data[index], index);
                }
                if (highlightedRow) {
                    highlightedRow.row.__highlighted = false;
                }
                var elements = table.getElementsByTagName('tbody')[0].getElementsByClassName('row-highlighted');
                if (elements.length > 0) {
                    elements[0].classList.remove('row-highlighted');
                }

                data[index].__highlighted = true;
                tr.classList.add('row-highlighted');
                setHighlighted({
                    element: tr,
                    index: index,
                    row: data[index],
                });
                break;
        }
    };

    useEffect(() => {
        if (!model) {
            setModel(createModel(props.model));
            if (options.selectable) {
                props.data.map(x => {
                    if (!x.hasOwnProperty('__selected')) {
                        x.__selected = true;
                    }
                });
            }
            setData(props.data);
            return;
        }
    });

    if (!model) return <div>loading</div>;

    return <table className='centrum-table' onClick={(e) => handleTableClick(e)} ref={(r) => table = r}>
        {options.header.show &&
            <TableHead
                model={model} data={props.data}
                options={options}
                buttons={props.buttons}
                onSelectAll={onSelectAll.bind(this)}
                onDeleteAll={onDeleteAll.bind(this)} />
        }
        <SortableCont
            onSortStart={onSortStart}
            onSortEnd={onSortEnd}
            axis='y'
            lockAxis='y'
            lockToContainerEdges={true}
            lockOffset={['30%', '50%']}
            helperClass='draggable-row'
            useDragHandle={true}
        >
            {
                data.map((row, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        model={model}
                        options={options}
                        row={row}
                        buttons={props.buttons}
                        onSelect={(row) => onSelect(row)}
                        onDelete={(row) => onDelete(row)}
                        onRenderRow={props.onRenderRow ? props.onRenderRow.bind(this) : null}
                    />
                ))
            }
        </SortableCont>
    </table>;
};

Table.defaultProps = {
    model: '',
    data: [],
    options: null,
    buttons: null,
    onDataChange: () => { },
    context: () => { }
};

Table.propTypes = {
    model: PropTypes.any,
    data: PropTypes.array,
    options: PropTypes.object,

    buttons: PropTypes.func,

    onSortStart: PropTypes.func,
    onSortEnd: PropTypes.func,

    onRenderRow: PropTypes.func,
    onDataChange: PropTypes.func,

    onRowClick: PropTypes.func,

    context: PropTypes.func
};


export default Table;
