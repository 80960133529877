import './_silk.scss';
import React from 'react';
import PropTypes from 'prop-types';

export const Silk = (props) => {
    const competitor = props.competitor;
    const raceType = props.raceType;

    if (!competitor.number) return <span>{competitor.number.toString().padStart(2, '0')}</span>;
    if (raceType == 2) {
        var silkUrl = '//cdn-d.tentangle.com/sis/uk/greyhound_silks_uk.png';
        return <div className='silk'>
            <i style={{ backgroundPositionX: -(26 * (competitor.number - 1)) + 'px', backgroundSize: 'auto 26px', width: '26px', height: '26px', backgroundImage: 'url(' + silkUrl + ')' }}></i>
        </div>;
    }

    if (!competitor.silkFile) return <div className='silk'>
        <span>{competitor.number.toString().padStart(2, '0')}</span>
    </div>;

    return <div className='silk'>
        <span>{competitor.number.toString().padStart(2, '0')}</span>
        <img src={'//cdn-d.tentangle.com/sis/' + competitor.silkFile + '.gif'} />
    </div>;
};

Silk.propTypes = {
    competitor: PropTypes.object,
    raceType: PropTypes.number
};
