// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '../../Controls/Form/Form';
import { FormInput } from '../../Controls/Form/FormInput';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { currency, lang } from '../../Centrum/Localization/lang';
import { BonusTypes } from '../../Centrum/_models/ModelEnums';
import { Card } from '../../Centrum/Card/Card';
import { Icon } from '../../Controls/Icon/Icon';

// COMPONENT
export class PlayerBonusSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }


    values() {
        return {
            amount: this.state.amount,
            notes: this.state.notes,
            bonusId: this.state.selectedBonus
        };
    }

    renderErrors(errors) {
        var elements = errors.map((x, index) => {
            return <p key={index}>
                {x}
            </p>;
        });
        return <React.Fragment>
            <Card icon={<Icon icon={'exclamation-circle'} size={'lg'} />} caption='Errors' className='marginBottom margin-top error'>
                <div className='padding'>
                    {elements}
                </div>
            </Card>
        </React.Fragment>;
    }

    renderWarnings(warnings) {
        var elements = warnings.map((x, index) => {
            return <p key={index}>
                {x}
            </p>;
        });
        return <React.Fragment>
            <Card icon={<Icon icon={'info-circle'} size={'lg'} />} caption='Warnings' className='marginBottom margin-top warning'>
                <div className='padding'>
                    {elements}
                </div>
            </Card>
        </React.Fragment>;
    }

    renderSelectedBonus() {

        if (!this.state.selectedBonus) {
            return;
        }

        var errors = [];
        var warnings = [];
        var amount = 0;

        var playerCurrency = this.props.profile.currency;


        var selectedBonus = this.props.activeBonuses.find(x => x.Id == this.state.selectedBonus);
        var lastDeposit = this.props.profile.lastDeposit;
        var depositLimits = {};
        var ggr = 0;
        console.log(this.props.profile);
        console.log(selectedBonus);
        var minimum = 0;
        var rangeMin = 0;
        var rangeMax = 0;
        var rangeFound = 0;
        var percentage = 0;

        // used for deposit bonus
        var maxAmount = 0;
        var i = 0;
        switch (selectedBonus.Type) {
            case BonusTypes.Discount:
                switch (selectedBonus.Product) {
                    case 0:
                        console.log('casino');
                        ggr = this.props.profile.ggrCasino24Hours;
                        var discountSettings = [];
                        try {
                            discountSettings = JSON.parse(selectedBonus.DiscountSettings)[playerCurrency];
                        } catch (err) {
                            console.log(err);
                        }

                        rangeFound = 0;

                        if (discountSettings) {
                            for (i = 0; i < discountSettings.length; i++) {
                                rangeMin = parseFloat(discountSettings[i].From);
                                rangeMax = parseFloat(discountSettings[i].To);
                                if (isNaN(rangeMax)) rangeMax = 999999999999999;
                                if (minimum == 0) minimum = rangeMin;

                                if (ggr >= rangeMin && ggr < rangeMax) {
                                    percentage = parseFloat(discountSettings[i].Amount);
                                    rangeFound = true;
                                    console.log(percentage);
                                    amount = Math.round((percentage * ggr) / 100);
                                }
                            }
                        }

                        if (!rangeFound) {
                            errors.push(lang('Players past 24 hour ggr is not enough.') + lang('minimum expected amount is %1', [currency(minimum, 2, playerCurrency)]));
                        }
                        break;
                    case 1:
                        console.log('sports');
                        ggr = this.props.profile.ggrSports24Hours;
                        break;
                }
                break;
            case BonusTypes.Deposit:
                try {
                    depositLimits = JSON.parse(selectedBonus.DepositLimits)[playerCurrency];
                } catch (err) {
                    console.log(err);
                }
                rangeFound = 0;

                for (i = 0; i < depositLimits.length; i++) {
                    rangeMin = parseFloat(depositLimits[i].From);
                    rangeMax = parseFloat(depositLimits[i].To);
                    if (isNaN(rangeMax)) rangeMax = 999999999999999;
                    if (minimum == 0) minimum = rangeMin;

                    if (!isNaN(parseFloat(depositLimits[i].Max))) {
                        maxAmount = depositLimits[i].Max;
                    }

                    if (lastDeposit >= rangeMin && ggr < rangeMax && !rangeFound) {
                        percentage = parseFloat(depositLimits[i].Percentage);
                        rangeFound = true;
                        console.log(percentage);
                        amount = Math.round((percentage * lastDeposit) / 100);
                        if (amount > maxAmount) {
                            amount = maxAmount;
                            warnings.push(lang('Max amount for deposit is %1', [currency(maxAmount, 2, playerCurrency)]));
                        }
                    }
                }

                if (!rangeFound) {
                    errors.push(lang('Players past 24 hour ggr is not enough.') + lang('minimum expected amount is %1', [currency(minimum, 2, playerCurrency)]));
                }

                break;
        }

        return <React.Fragment>
            <Form data={{
                amount: amount,
                lastDepositSource: this.props.profile.lastDepositSource,
                lastDeposit: lastDeposit
            }}>
                <div className='marginBottom'>
                    <div className='seperator'>
                        <span>{lang('Description')}</span>
                    </div>
                    {selectedBonus.Description}
                </div>
                {warnings.length > 0 && this.renderWarnings(warnings)}
                {errors.length > 0 && this.renderErrors(errors)}
                {
                    selectedBonus.Type == BonusTypes.Deposit &&
                    <React.Fragment>
                        <div className='row'>
                            <FormInput title='Provider' name='lastDepositSource' readOnly={true} />
                            <FormInput title='Deposit Amount' name='lastDeposit' formatter={(value) => { return currency(value, 2, playerCurrency); }} readOnly={true} />
                        </div>
                    </React.Fragment>
                }
                <div className='row'>
                    <FormInput type={InputTypes.number} title='Amount' name='amount' onChange={(data, field, value) => this.setState({ amount: value })} />
                </div>
                <div className='row'>
                    <FormInput type={InputTypes.textArea} title='Notes' onChange={(data, field, value) => this.setState({ notes: value })} />
                </div>
            </Form>
        </React.Fragment >;

    }
    render() {

        var activeBonusList = {};
        this.props.activeBonuses.map(x => {
            activeBonusList[x.Id] = x.Name;
        });

        return <React.Fragment>
            <Form>
                <div className='row'>
                    <FormInput type={InputTypes.select} name='Bonus' title='Bonus' values={activeBonusList} onChange={
                        (data, field, value) => {
                            this.setState({ selectedBonus: value });
                        }
                    } />
                </div>
            </Form>
            {this.renderSelectedBonus()}
        </React.Fragment>;
    }
}

PlayerBonusSelection.defaultProps = {
    activeBonuses: [],
    profile: {}
};

PlayerBonusSelection.propTypes = {
    activeBonuses: PropTypes.array,
    profile: PropTypes.object.isRequired
};

