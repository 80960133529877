// IMPORT PACKAGE REFERENCES

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Search } from '../Centrum/Search/Search';


import { Page } from '../Centrum/Page/Page';

import { ActionTypes, getActions, getAction } from '../Centrum/Common/ActionManager';
import { Card } from '../Centrum/Card/Card';
import { CentrumApplicationContents, CentrumApplicationActions, ApplicationContext, CentrumApplication } from '../Centrum/Application/CentrumApplication';
import { apiResult, application, Endpoints } from '../Application/Application';
import { CloseModalButton, ConfirmationModal } from '../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../Controls/Form/Form';
import { FormInput } from '../Controls/Form/FormInput';
import { Button } from '../Controls/Buttons/Button';
import { currency, lang } from '../Centrum/Localization/lang';
import { searchTransactionsModel, transactionsModel } from './Retails/Components/RetailOperations/RetailTransactions';
import { Dialog } from '../Modal/Modals/Dialog/Dialog';
import { API, PlayerEndpoints, TransactionEndpoints } from '../../v2/Lib/Api/Api';
import { Icon } from '../../v2/Components/Icon/Icon';
import { ErrorModal } from '../Modal/Modals/Error/ErrorModal';


const applicationActionType = ActionTypes.Transactions;
const applicationActions = getActions(applicationActionType);



export const ConfirmWithdraw = (props) => {
    const [transaction, setTransaction] = useState(props.transaction);
    const [player, setPlayer] = useState(null);
    const [deduct, setDeduct] = useState(false);

    useEffect(() => {
        API.post(PlayerEndpoints.Summary, { id: props.transaction.UserId }).then((result) => {
            setPlayer(result.result);
            setDeduct(true);
        });
    }, []);

    useEffect(() => {
        if (!transaction) return;
        if (!player) return;
        if (!player?.consumedPromotionId) return;
        var value = props.transaction.Amount;
        if (deduct) value = calculateDeduction();
        update('Amount', value);
    }, [deduct]);

    const calculateDeduction = () => {
        let amount = props.transaction.Amount;

        if (player.consumedPromotionId > 0) {
            if (player.promotion.amount == 0) {
                perc = 0;
            } else {
                var turnoverMultiplier = player.promotion.turnoverMultiplier;
                var totalBet = player.promotion.totalBet;
                var max = turnoverMultiplier * player.promotion.amount;
                perc = (100 * totalBet) / max;
                if (totalBet > max) perc = 100;
                perc = parseFloat(perc.toFixed(2));

                if (isNaN(perc)) perc = 0;
            }
            amount = deduct ? amount - player.promotion.amount : amount;
        }
        return amount;
    };

    const update = (field, value) => {
        var copy = { ...transaction };
        copy[field] = value;
        copy.Amount = Math.floor(parseFloat(copy.Amount));
        copy.DeductBonus = deduct;
        setTransaction(copy);
        if (props.onChange) {
            props.onChange(copy);
        }
    };


    if (!player) return <></>;

    var perc = 0;
    var amount = transaction.Amount;

    amount = calculateDeduction();
    amount = Math.floor(amount);

    return <div className='flex vertical' key={deduct}>
        <div className='flex col-2'>
            <div className='flex gap-5'>
                <label className='bold'>{lang('Transaction Id')}</label>
                <span>{transaction.Id}</span>
            </div>
            <div className='flex gap-5'>
                <label className='bold'>{lang('TransactionDate')}</label>
                <span>{new Date(transaction.TransactionDate).toLocaleString('en-Gb', { hours12: false })}</span>
            </div>
        </div>
        <div className='flex col-2'>
            <div className='flex gap-5'>
                <label className='bold'>{lang('External Transaction Id')}</label>
                <span>{transaction.ExternalTransactionId}</span>
            </div>
        </div>
        {player.consumedPromotionId > 0 &&
            <Card caption={player.activePromotion} menu={<div className='alignRight'>
                {perc >= 100 ? <label>{lang('Bonus Completed')}</label> : <label>{lang('Bonus not completed')}</label>}
            </div>}>
                <div className='padding flex vertical gap-10'>
                    <div className='flex gap-5'>
                        <label>{lang('Bonus Amount')}</label><span>{currency(player.promotion.amount, 2, player.currency)}</span>
                        <label>{lang('Turnover Multiplier')}</label><span>{player.promotion.turnoverMultiplier}</span>
                        <label>{lang('Deduct Bonus')}</label><span>{player.promotion.deductBonusAmount}</span>
                    </div>
                    <div className='flex gap-5'>
                        <label>{lang('Total Sport Bets')}</label><span>{player.promotion.sportBets}</span>
                        <label>{lang('Total Stake')}</label><span>{currency(player.promotion.totalBet, 2, player.currency)}</span>
                        <label>{lang('Total Win')}</label><span>{currency(player.promotion.totalWin, 2, player.currency)}</span>
                    </div>
                    <div className='flex gap-5'>
                        <label>{lang('Progress')}</label>
                        <span>%{perc}</span>
                    </div>
                </div>
            </Card>
        }
        {
            player.consumedPromotionId > 0 &&
            <div className='flex col-2'>
                <div className='flex gap-5' onClick={() => setDeduct(!deduct)}>
                    <Icon icon={deduct ? 'check-square' : 'square'} />
                    <label>{lang('Deduct Bonus Amount')}</label>
                </div>
            </div>
        }
        <div className='flex col-2'>
            <div className='flex gap-5'>
                <label className='bold'>{lang('Amount')}</label>
                <span>{currency(amount, 2, player.currency)}</span>
            </div>
        </div>

        <div className='flex col-2'>
            <div className='flex vertical gap-5'>
                <label className='bold'>{lang('Notes')}</label>
                <textarea name='notes' onChange={(e) => update('Notes', e.target.value)} />
            </div>
        </div>
    </div >;
};

ConfirmWithdraw.propTypes = {
    transaction: PropTypes.object,
    onChange: PropTypes.func
};



// COMPONENT
class TransactionsPage extends Page {
    constructor(props) {
        super(props);
        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.modal = null;
        state.action = action;

        this.pendingWithdrawsRef = React.createRef();
        this.state = state;
        this.applicationContext = null;
    }

    copyDataToClipboard(data, fields) {
        const el = document.createElement('textarea');
        var vals = [];
        Object.keys(data).filter(x => x !== '__paymentType').map(key => {
            var field = fields.find(x => x.name == key);
            if (field) {
                var name = lang(field.title);
                var value = data[key];
                if (field.type == 'select') {
                    value = field.values[value];
                }
                vals.push(name + ':' + value);
            }
        });
        el.value = vals.join(' \n');
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        application().snackbar.open('copied');
    }

    editTransactionData(transaction, readonly = false) {
        application().resolve(null, Endpoints.GetConfiguration + '/' + transaction.ProviderId, {}, 'fetching provider configuration', 'unable to fetch configuration').then((result) => {
            try {
                var configuration = JSON.parse(result.result);
                var data = JSON.parse(transaction.Data);

                var target = configuration;

                switch (transaction.Type) {
                    case 0:
                        target = configuration.deposit;
                        break;
                    case 1:
                        target = configuration.withdraw;
                        break;
                }

                if (data == null) return application().snackbar.open('transaction does not have a data');

                var paymentType = data.__paymentType;
                if (target.methods) target = target.methods.find(x => x.name == paymentType);
                if (target?.fields) {
                    var fields = target.fields;
                    var footer = readonly ? <div><Button title='Copy to clipboard' onClick={() => this.copyDataToClipboard(data, fields)} /><CloseModalButton /></div> : null;

                    application().modal.open(<ConfirmationModal title='Transaction Data'
                        onConfirm={() => {
                            var formData = this.transactionForm.post();
                            application().resolve(null, Endpoints.UpdateTransactionFormData + '/' + transaction.Id, formData, 'fetching provider configuration', 'unable to fetch configuration').then(() => {
                                application().snackbar.open('Transaction data updated');
                                transaction.Data = JSON.stringify(formData);
                            });
                        }}
                        footer={footer}
                    >
                        <Form data={data} ref={(r) => this.transactionForm = r}>
                            {
                                fields.map((field, index) => {
                                    return <FormInput key={index} type={field.type} name={field.name} title={field.title} required={true} values={field.values} readOnly={readonly} />;
                                })
                            }
                        </Form>
                    </ConfirmationModal>);
                }  else {
                    var footer = null;
                    readonly = true;
                    application().modal.open(<ConfirmationModal title='Transaction Data'
                        onConfirm={() => {
                            var formData = this.transactionForm.post();
                            application().resolve(null, Endpoints.UpdateTransactionFormData + '/' + transaction.Id, formData, 'fetching provider configuration', 'unable to fetch configuration').then(() => {
                                application().snackbar.open('Transaction data updated');
                                transaction.Data = JSON.stringify(formData);
                            });
                        }}
                        footer={footer}
                    >
                        <Form data={data} ref={(r) => this.transactionForm = r}>
                            {
                                fields.map((field, index) => {
                                    return <FormInput key={index} type={field.type} name={field.name} title={field.title} required={true} values={field.values} readOnly={readonly} />;
                                })
                            }
                        </Form>
                    </ConfirmationModal>);
                }
            } catch (err) {
                application().modal.open(<Dialog title='Transaction Data'>
                    <div className='flex vertical gap-10'>{Object.keys(data).map(x => {
                        return <div key={x} className='flex vertical gap-5 align-start'>
                            <label className='bold'>{x}</label>
                            <span>{data[x]}</span>
                        </div>;
                    })}</div>
                </Dialog>);
                console.error(err);
            }
        });
    }

    onActionClicked(action, items) {
        let item = items[0];
        item.ConfirmedAmount = item.Amount = Math.abs(item.Amount);
        switch (action.type) {
            case undefined:
                this.editTransactionData(item, true);
                break;
            case ActionTypes.EditTransactionData:
                this.editTransactionData(item);
                break;
            case ActionTypes.ConfirmTransaction:
                if (item.Type == 1) {
                    application().modal.open(<ConfirmationModal title='Confirm Withdraw' onConfirm={() => {

                        var payload = {
                            id: item.Id,
                            notes: item.Notes,
                            amount: item.Amount,
                            data: item.Data,
                            confirmedAmount: item.Amount,
                            deductBonus: item.DeductBonus
                        }

                        API.post(`${TransactionEndpoints.Confirm}/${item.Id}`, payload).then(() => {
                            application().snackbar.open('Transaction confirmed');
                        }).catch((e) => {
                            application().modal.open(<ErrorModal title='Error' description={'Transaction is not confirmed'}>
                                <p className='padding'>
                                    {e ? e.error?.message ?? 'Provider error' : 'Provider Error'}
                                </p>
                            </ErrorModal>)
                        });
                    }}>
                        <ConfirmWithdraw transaction={item} onChange={(value) => {
                            item = value;
                            if (item.Data) {
                                try {
                                    var converted = JSON.parse(item.Data);
                                    if (converted.amount) {
                                        converted.amount = value.Amount;
                                    }
                                    if (converted.Amount) {
                                        converted.Amount = value.Amount;
                                    }
                                    item.Data = JSON.stringify(converted);
                                } catch (err) {

                                }
                            }
                        }} />
                    </ConfirmationModal>);
                    return;
                }
                this.applicationContext.confirmActionAndPost(item, 'confirmTransaction', '/api/transactions/confirm/' + item.Id, null, 'col2', action.caption, 'Operation Completed', false);
                break;
            case ActionTypes.RejectTransaction:
                this.applicationContext.confirmActionAndPost(item, 'rejectTransaction', '/api/transactions/reject/' + item.Id, null, 'col2', action.caption, 'Operation Completed', false);
                break;
            case ActionTypes.ProcessingTransaction:
                this.applicationContext.confirmActionAndPost(item, 'processTransaction', '/api/transactions/process/' + item.Id, null, 'col2', action.caption, 'Operation Completed', false);
                break;
        }
    }

    onRenderAction(action, selection) {
        if (selection.length == 0) action;
        var item = selection[0];
        if (item === undefined) return action;
        if (!action.type && item.ProviderId == null) return Object.assign({ enabled: false }, action);
        if (!action.type) return action;
        if (item.Status === 1) return Object.assign({ enabled: false }, action);
        if (item.Status === 2) return Object.assign({ enabled: false }, action);
        if (action.type == ActionTypes.ProcessingTransaction && item.Type == 1 && item.Status != 0) {
            return Object.assign({ enabled: false }, action);
        }
        return action;
    }

    onRenderRow(rowObject) {
        if (rowObject.data.Status == 1) {
            rowObject.className = 'transaction-confirmed';
        }
        if (rowObject.data.Status == 2) {
            rowObject.className = 'transaction-cancel';
        }
        if (rowObject.data.Type == 4) {
            rowObject.className = 'transaction-rollback';
        }
        return rowObject;
    }

    render() {
        return (
            <CentrumApplication caption='Transactions' icon='money' type={applicationActionType} loading={this.props.loading}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <Card caption='Search'>
                                        <Search
                                            key='transactions'
                                            model='searchTransactions'
                                            resultModel='transactionInformation'
                                            exportToExcel={true}
                                            data={this.props.transactions}
                                            actions={getAction(ActionTypes.SearchTransactions).convertToItems()}
                                            onSubmit={(data, qb) => {
                                                return apiResult('transactions', Endpoints.SearchTransactions, qb, 'please wait', 'unable to fetch report');
                                            }}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            onRenderAction={this.onRenderAction.bind(this)}
                                            onRenderRow={this.onRenderRow.bind(this)}
                                        />
                                    </Card>
                                    <Card caption='Search'>
                                        <Search
                                            key='deposits'
                                            model='searchPendingDeposits'
                                            resultModel='transactionInformation'
                                            exportToExcel={true}
                                            data={this.props.pendingDeposits}
                                            actions={getAction(ActionTypes.SearchTransactions).convertToItems()}
                                            onSubmit={(data, qb) => {
                                                qb.fields = qb.fields.filter(x=>x.name=='Type');
                                                qb.fields = qb.fields.filter(x=>x.name=='Status');

                                                qb.fields.push({ name: 'Type', value: 0, exact: true, and: true });
                                                qb.fields.push({ name: 'Status', value: 0, exact: true, and: true });
                                                return apiResult('pendingDeposits', Endpoints.SearchTransactions, qb, 'please wait', 'unable to fetch report');
                                            }}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            onRenderAction={this.onRenderAction.bind(this)}
                                            onRenderRow={this.onRenderRow.bind(this)}
                                        />
                                    </Card>
                                    <Card caption='Search'>
                                        <Search
                                            key='withdraws'
                                            model='searchPendingWithdraws'
                                            resultModel='transactionInformation'
                                            exportToExcel={true}
                                            data={this.props.pendingWithdraws}
                                            actions={getAction(ActionTypes.SearchPendingWithdraws).convertToItems()}
                                            onSubmit={(data, qb) => {
                                                qb.fields = qb.fields.filter(x=>x.name=='Type');
                                                qb.fields = qb.fields.filter(x=>x.name=='Status');
                                                
                                                qb.fields.push({ name: 'Type', value: 1, exact: true, and: true });
                                                qb.fields.push({ name: 'Status', value: 0, exact: true, and: true });
                                                return apiResult('pendingWithdraws', Endpoints.SearchTransactions, qb, 'please wait', 'unable to fetch report');
                                            }}
                                            ref={this.pendingWithdrawsRef}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            onRenderAction={this.onRenderAction.bind(this)}
                                            onRenderRow={this.onRenderRow.bind(this)}
                                        />
                                    </Card>
                                    <Card caption='Search'>
                                        <Search
                                            key='retailTransactions'
                                            model={searchTransactionsModel}
                                            exportToExcel={true}
                                            resultModel={transactionsModel}
                                            data={this.props.retailTransactions}
                                            onSubmit={(data, qb) => {
                                                return apiResult('retailTransactions', `${Endpoints.ListRetailTransactions}/`,
                                                    qb, 'fetching transactions', 'unable to fetch transactions');
                                            }}
                                        />
                                    </Card>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

TransactionsPage.defaultProps = {
    bulkOperationDepositResults: null,
    bulkOperationWithdrawResults: null
};

TransactionsPage.propTypes = {
    action: PropTypes.object,
    transactions: PropTypes.object,
    pendingDeposits: PropTypes.object,
    pendingWithdraws: PropTypes.object,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    failed: PropTypes.bool,
    error: PropTypes.object
};


const mapStateToProps = state => {
    return {
        processingTransactions: state.data.processingTransactions,
        loading: state.transactions.loading,
        loaded: state.transactions.loaded,
        failed: state.transactions.failed,
        pendingDeposits: state.data.pendingDeposits,
        pendingWithdraws: state.data.pendingWithdraws,
        retailTransactions: state.data.retailTransactions,
        transactions: state.data.transactions,
        error: state.transactions.error
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
export { hoc as TransactionsPage };