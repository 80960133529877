import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditableTable } from '../../Controls/Table/Editable/EditableTable';
import { Card } from '../../Centrum/Card/Card';
import { Button } from '../../Controls/Buttons/Button';
import { application, Endpoints } from '../../Application/Application';

export const SetupRegions = (props) => {
    const [category, selectCategory] = useState(null);
    const [regions, selectRegions] = useState(null);
    const [weights, setWeights] = useState(null);
    const [updateKey, setUpdateKey] = useState(0);


    useEffect(() => {
        var categories = props.categories.filter(x => x.enabled);
        selectCategory(categories[0]);
    }, []);

    useEffect(() => {
        if (!category) return;
        application().resolve(null, `${Endpoints.SportRegions}/${category.id}`).then((result) => {
            application().resolve(null, Endpoints.SetupSportRegions).then((result) => {
                try {
                    var regionWeights = JSON.parse(result.result);


                    if (!regionWeights) regionWeights = {};
                    if (!regionWeights.hasOwnProperty(category.id)) {
                        regionWeights[category.id] = {};
                    }

                    setWeights(regionWeights);
                    setUpdateKey(Date.now());
                } catch (err) {
                    //
                }
            });

            selectRegions(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [category]);


    useEffect(() => {
        if (!weights) return;

        var copy = weights[category.id] ?? {};
        regions.map(x => {
            if (copy.hasOwnProperty(x.id)) {
                x.weight = copy[x.id].weight;
                x.enabled = copy[x.id].enabled === undefined ? true : copy[x.id].enabled;
            } else {
                x.enabled = true;
            }
        });

        var sorted = Object.assign([], regions).sort((b, a) => { return a.weight - b.weight; });
        sorted.key = Date.now();
        selectRegions(sorted);
    }, [weights]);

    const save = () => {
        var data = Object.assign({}, weights[category.id]);

        regions.map((x, index) => {
            x.weight = regions.length - index;
            data[x.id] = x;
        });

        var copy = { ...weights };
        copy[category.id] = data;

        application().resolve(null, `${Endpoints.SaveSetupSportRegions}`, copy).then(() => {
            setWeights(copy);
            application().snackbar.open('Region entities saved.');
        });
    };


    if (!props.categories) return <div></div>;
    if (!category) return <div />;
    if (!regions) return <div />;
    console.log(props.categories);
    return <Card caption='Manage Regions' menu={<Button title='Save' onClick={() => save()} key={updateKey} />}>
        <div className='padding'>
            <select defaultValue={category.id} onChangeCapture={(e) => {
                selectCategory(props.categories.find(x => x.id == parseInt(e.target.value)));
            }}>
                {props.categories.map(x => {
                    return <option key={x.id} value={x.id}>{x.name}</option>;
                })}
            </select>
        </div>
        <EditableTable
            key={regions.key}
            model={{
                fields: [
                    {
                        name: 'id',
                        display: 'Id'
                    },
                    {
                        name: 'name',
                        display: 'Name'
                    },
                    {
                        name: 'enabled',
                        display: 'Enabled',
                        type: 'bool',
                        editable: true
                    }
                ]
            }}
            draggable={true}
            data={regions}
            onDataChange={(data) => {
                selectRegions(data);
            }} />
    </Card>;
};


SetupRegions.propTypes = {
    categories: PropTypes.array
};