import React from 'react';
import { getStore } from '../../..';
import { Modal, Overlay } from '../../Applications/Centrum';
import { BusyModal } from '../../Components/Modals/BusyModal/BusyModal';
import { ErrorModal } from '../../Components/Modals/ErrorModal/ErrorModal';



export const TicketEndpoints = {
    Search: '/api/sports/tickets',
    Resolved: '/api/sports/tickets/resolved',
    TicketDetails: '/api/sports/ticket',
    SearchHorseTickets: '/api/horse/tickets/search',
    HorseTicket: '/api/horse/tickets',
    HorseTicketDetails: '/api/horse/ticket/details',
    OpenSportsTickets: '/api/betslip/sports/tickets/open',
    Cashout: '/api/betslip/cashout',
    SportTicketResult: '/api/betslip/sport/result',
    TicketResult: 'api/result/getticketresult',
    SportEventResults: '/api/sports/results/events'
};

export const DataEndpoints = {
    Operators: '/api/data/operators',
    EnabledCurrencies: '/api/data/EnabledCurrencies',
    OperatorNotifications: '/api/data/notifications/operator',
    MyFeatures: '/api/data/features',
    MyConfiguration: '/api/data/configuration',
    SaveOperatorCurrencies: '/api/operator/configuration/currencies/save',
    AvailableLanguages: '/api/data/languages/available',
    MyAffiliates: '/api/data/myaffiliates/',
    GetCustomData: '/api/data/get'
};

export const PlayerEndpoints = {
    Get: '/api/player',
    Search: '/api/player/search',
    Profile: '/api/player/profile',
    Create: '/api/player/new',
    Update: '/api/player/update',
    ToggleEnablePlayer: '/api/player/enabled/toggle',
    ChangePassword: '/api/player/changepassword',
    SearchGameHistory: '/api/player/history/games',
    SearcNotifications: '/api/player/notifications',
    SearchActivity: '/api/player/activity',
    SearchTransactions: '/api/player/transactions/search',
    Summary: '/api/player/summary',
    Verficiations: '/api/player/verifications/search',
    RemovePlayerNote: '/api/player/notes/delete',
    UpdatePlayerNote: '/api/player/notes/update',
    ChangeEmail: '/api/player/email/update',
    GetPlayerGroups: '/api/player/groups',
    SavePlayerGroup: '/api/player/group/save',
    Summary: '/api/player/summary',
    ShowActivityAfterDeposit: '/api/player/activity/after/deposit',
    NotifyPlayer: '/api/player/notify',
    ChangePlayerRisk: '/api/player/risk/change',
    SearchSameIpPlayers: '/api/compliance/sameIp'
};




export const AnnouncementEndpoints = {
    List: '/api/player/announcements',
    Save: '/api/player/announcement/save',
    Get: '/api/player/announcement',
    Publsish: '/api/player/announcement/publish',
};


export const AffiliateEndpoints = {
    RemovePlayer: '/api/affiliates/players/remove',
    MovePlayer: '/api/affiliates/players/add',
    SearchSettledTickets: '/api/affiliates/history/tickets/settled',
    SearchOpenTickets: '/api/affiliates/history/tickets/open'
};

export const ProviderEndpoints = {
    Gaming: '/api/data/providers/gaming',
    Games: '/api/data/provider/games',
    GameTypes: '/api/data/provider/gameTypes',
    Configuration: '/api/provider/configuration',
};

export const PaymentProviders = {
    Get: '/api/data/providers/payment',
    Enable: '/api/provider/payment/enable',
    Disable: '/api/provider/payment/disable',
    SaveConfiguration: '/api/provider/payment/save/configuration'
};

export const TransactionEndpoints = {
    Search: '/api/transactions/search/all',
    Confirm: '/api/transactions/confirm',
    Create: '/api/transactions/create',
    CreateBonusTransaction: '/api/transactions/bonus/create',
    RemoveBonus: '/api/transactions/bonus/remove',
    ResetBalance: '/api/transactions/balance/reset',
    CancelTransaction: '/api/transactions/cancel'
};


export const SportEndpoints = {
    GetEventById: '/api/sports/event',
    RecoverEvent: '/api/sports/recover/event',
    Categories: '/api/sports/categories',
    Regions: '/api/sports/regions',
    SaveCategories: '/api/sports/categories/save',
    Leagues: '/api/sports/leagues',
    Events: '/api/sports/events',
    Teams: '/api/sports/teams',
    SaveLeagues: '/api/sports/leagues/save',
    Markets: '/api/sports/markets',
    SaveMarkets: '/api/sports/categories/markets'
};

export const HorseEndpoints = {
    GetEventById: '/api/horse/event',
    ManualSettlement: '/api/horse/manual/settlement'
};


export const PromotionEndpoints = {
    List: '/api/promotions/list',
    ToggleEnabled: '/api/promotion/enabled',
    UpdateWeights: '/api/promotions/weights/update'
};

export const HistoryEndpoints = {
    PlayerGameHistory: '/api/player/history/games',
    PlayerSessionHistory: '/api/player/history/sessions',
    CasinoHandDetailsWithHistoryId: '/api/casino/hand/history',
};

export const CasinoLimitsEndpoints = {
    GetProviderLimits: '/api/risk/application/casino/limits/providers',
    SaveProviderLimits: '/api/risk/application/casino/limits/providers/save',
    GameLimits: '/api/risk/application/casino/limits/games',
    SaveGameLimits: '/api/risk/application/casino/limits/games/save',
};

export const CMSEndpoints = {
    GetDefaultLanguage: '/api/cms/language/web/default',
    GetGames: '/api/cms/games/list',
    SaveGame: '/api/cms/game/save',
    Contents: '/api/cms/contents',
    SaveContent: '/api/cms/save',
    PublishWebSiteLanguage: '/api/cms/language/publish/website',
    SaveLanguageKey: '/api/cms/language/key/save',
    DeleteLanguageKey: '/api/cms/language/key/delete',
    SaveAvailableLanguages: '/api/cms/languages/available/save',
    GetLSportsEventParticipants: '/api/cms/sport/participants/lsports',
    GetBetradarEventParticipants: '/api/cms/sport/participants/betradar',
    GetBetradarEventParticipants: '/api/cms/sport/participants/betradar',
    UpdateLSportTeamImages: '/api/cms/sport/participants/lsport/image/update',

    GetSportCategoriesLanguages: '/api/cms/sports/categories/languages',
    GetSportRegionsLanguages: '/api/cms/sports/regions/languages',
    GetSportMarketLanguages: '/api/cms/sports/markets/languages',
    GetSportSelectionLanguages: '/api/cms/sports/selections/languages',

    GetSportMarkets: '/api/cms/sports/markets',
    GetSportMarketGroups: '/api/cms/sports/markets/groups',
    SaveSportMarketGroups: '/api/cms/sports/markets/groups/save',
    GetOverviewMarkets: '/api/cms/sports/markets/overview',
    SaveOverviewMarkets: '/api/cms/sports/markets/overview/save',
};


export const RiskLevelEndpoints = {
    Get: '/api/risk/levels',
    Save: '/api/risk/levels/save',
    Sports: '/api/risk/levels/sports',
    SaveSports: '/api/risk/levels/sports/save',
    Leagues: '/api/risk/levels/leagues',
    SaveLeagues: '/api/risk/levels/leagues/save',
    Markets: '/api/risk/levels/markets',
    SaveMarkets: '/api/risk/levels/markets/save',
    Monitor: '/api/risk/levels/monitor',
    MonitorSports: '/api/risk/levels/monitor/sports',
    MonitorEventVolumes: '/api/risk/levels/monitor/volumes/event',
    MonitorMarketVolumes: '/api/risk/levels/monitor/volumes/event/market',
    MonitorEventBets: '/api/risk/levels/monitor',
    MonitorTickets: '/api/risk/bet/monitor/tickets',
    LastRemainingTickets: '/api/risk/levels/monitor/tickets/lastremaining',
};

export const SportMarginEndPoints = {
    Get: '/api/risk/sports/margins',
    Save: '/api/risk/sports/margins/save',
    Update: '/api/risk/sports/margins/update'
};

export const RiskLevelOffersEndpoints = {
    Accept: '/api/risk/levels/offer/accept',
    Reject: '/api/risk/levels/offer/reject'
};

export const OperatorEndpoints = {
    Search: '/api/operators/search'
};

export const OperatorUserEndpoints = {
    UpdateUser: '/api/operatorUsers/update',
    SaveUser: '/api/operatorUsers/new',
    ChangePassword: '/api/operatorUsers/password/update',
    ToggleEnableDisable: '/api/operatorUsers/toggleEnabled/'
};

export const OperatorConfigurationEndpoints = {
    Get: '/api/operator/configuration/',
    UserGroups: '/api/userGroups/search',
    SaveOperatorRiskType: '/api/operator/configuration/risk/save',
};

export const ConfigurationEndpoints = {
    UserGroups: '/api/userGroups/search',
    UserGroupBinding: '/api/userGroups/getGroupBindings/',
    UpdateUserGroupBindings: '/api/userGroups/update/',
};

export const SetupEndpoints = {
    SportTaxes: '/api/sports/configuration/taxes',
    SaveSportTaxes: '/api/sports/configuration/taxes/save',
    GetTopLeagues: '/api/sports/cms/topleagues/get',
    GetLeagueWeights: '/api/sports/cms/leagues/weights/get',
    SaveGetLeagueWeights: '/api/sports/cms/leagues/weights/save',
};

export const AuthenticationEndpoints = {
    Enable2FA: '/api/authentication/GenerateGoogle2faQR',
    Validate2FA: '/api/authentication/ValidateGoogle2fa',
    ChangePassword: '/api/myProfile/changeMyPassword'
};

export const DashboardEndponts = {
    TotalPlayers: '/api/dashboard/main/players/summary',
    GameSessions: '/api/dashboard/main/games/sessions',
    Liability: '/api/dashboard/main/liability/summary',
    MostPlayedGames: '/api/dashboard/main/games/summary',
    TopSportTicketBets: '/api/dashboard/main/tickets/top/stakes',
    TopSportWins: '/api/dashboard/main/tickets/top/win',
    NewTickets: '/api/dashboard/main/tickets/new',
    OpenTickets: '/api/dashboard/main/tickets/open',
    TopSportPlayers: '/api/dashboard/main/tickets/players/top',
};


export const KPIEndpoints = {
    Players: '/api/dashboard/main/players',
    BettingGGR: '/api/dashboard/main/ggr/betting',
    CasinoGGR: '/api/dashboard/main/ggr/casino',
    Deposits: '/api/dashboard/main/deposits',
    Withdraws: '/api/dashboard/main/withdraws'
};


export const ReportEndpoints = {
    // Revenues
    DailyRevenuesSummary: '/api/reports/revenues/daily/summary',
    CasinoRevenuesReport: '/api/reports/casino',
    CasinoProviderRevenuesReport: '/api/reports/casino/providers',
    GameRevenuesReports: '/api/reports/casino/games',
    PlayerCasinoRevenuesReport: '/api/reports/casino/player',
    PlayerGroupsGameRevenuesReport: '/api/reports/casino/groups',
    CasinoProviderCommissionReport: '/api/reports/casino/providers/commissions',
    ProviderRevenuesReport: '/api/reports/provider/revenues',
    // Sport Betting
    SportBettingRevenuesReport: '/api/reports/sports/revenues',
    SportBettingPlayerRevenuesReport: '/api/reports/sports/revenues/player',
    SportBettingPlayerGroupsRevenuesReport: '/api/reports/sports/revenues/groups',
    SportCategoryRevenueSummaryReport: '/api/reports/sports/categories',
    SportRegionRevenueSummaryReport: '/api/reports/sports/regions',
    SportLeagueRevenueSummaryReport: '/api/reports/sports/leagues',
    SportEventRevenueSummaryReport: '/api/reports/sports/events',
    SportMarketRevenueSummaryReport: '/api/reports/sports/markets',
    SportSelectionRevenueSummaryReport: '/api/reports/sports/selections',
    SportPlayerRevenueSummaryReport: '/api/reports/sports/players',
    SportTickets: '/api/sports/tickets',
    SportEventRevenueReport: '/api/reports/sports/eventRevenues',
    SearchPlayersWithoutSportBets: '/api/sports/players/withoutbets',
    // Horse / Greyhound
    HorseGreyHoundRegionsReport: '/api/reports/horse/regions',
    HorseGreyHoundVenuesReport: '/api/reports/horse/venues',
    HorseGreyHoundDistanceReport: '/api/reports/horse/distance',
    HorseGreyHoundPlayersReport: '/api/reports/horse/players',
    HorseGreyHoundTicketsReport: '/api/horse/tickets/search',
    // Player Summary
    RevenuesPlayerSummaryReport: '/api/reports/players/revenues',
    GamesPlayerSummaryReport: '/api/reports/players/games',
    // Transactions
    TransactionsAccountSummaryReport: '/api/reports/transactions/summary',
    TransactionsProviderReport: '/api/reports/provider/transactions',
    TransactionsPlayerReport: '/api/reports/provider/transactions/players/details',
    TransactionsFirstTransactionReport: '/api/reports/transactions/first',
    //Retails
    RetailsAccountSummaryReport: '/api/reports/retail/account/summary',
    RetailTransactionReport: '/api/reports/retail/transactions',
    RetailPlayerTransactionReport: '/api/reports/retail/players/transactions',
    //Bonus
    BonusTransactionsReport: '/api/transactions/bonus/search',
    BonusPlayersReport: '/api/promotions/player/bonus/progress',
    BonusPlayerSummaryReport: '/api/promotions/player/bonus/summary',
    BonusCompletedPayoutsReport: '/api/promotions/player/bonus/completed',
    BonusCompletedPayoutSummaryReport: '/api/promotions/player/bonus/completed/summary',
    BonusRemovedBonusesReport: '/api/promotions/player/bonus/removed',


}

export const AffiliateDashboardEndpoints = {
    GetDashboardStats: 'api/dashboard/affiliate/stats'
};

const prepareRequestParameters = (...props) => {
    const url = props[0];
    const data = props[1];
    const title = props[2];
    const error = props[3];
    const type = props[4];
    const dataName = props[5];

    return {
        url: url,
        data: data,
        title: title,
        error: error,
        type: type,
        dataName: dataName
    };
};


export const API = {
    get: (...props) => {
        var p = prepareRequestParameters(...props);
        return API.request(p.url, p.data, p.title, p.error, 'GET', false, p.dataName);
    },
    post: (...props) => {
        var p = prepareRequestParameters(...props);
        return API.request(p.url, p.data, p.title, p.error, 'POST', false, p.dataName);
    },
    raw: (...props) => {
        var p = prepareRequestParameters(...props);
        return API.request(p.url, p.data, p.title, p.error, 'GET', true);
    },
    request: (url, data, title, error, type, raw, dataName) => {
        Overlay.open(<BusyModal title={title} />);
        getStore().dispatch({
            type: 'REQUEST',
            payload: url
        });
        if (dataName) {
            getStore().dispatch({
                type: 'LOAD_DATA',
                payload: {
                    type: `${dataName}`,
                    title: title
                }
            });
        }
        return new Promise((resolve, reject) => {
            try {
                var options = {
                    method: type,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Timezone-Offset': new Date().getTimezoneOffset()
                    },
                    credentials: 'same-origin'
                };

                if (!raw) options.headers['Accept'] = 'application/json';
                if (data) options.body = JSON.stringify(data);

                fetch(url, options)
                    .then((res) => {
                        if (res.status == 204) {
                            return {
                                status: 0,
                                error: {
                                    message: 'Service is not responding / timeout.'
                                }
                            };
                        }
                        if (res.status == 400) {
                            return {
                                status: 0,
                                error: {
                                    message: 'Bad request.'
                                }
                            };
                        }
                        if (res.status == 415) {
                            return {
                                status: 0,
                                error: {
                                    message: 'Unsupported request.'
                                }
                            };
                        }
                        if (res.status == 500) {
                            return {
                                status: 0,
                                error: {
                                    message: 'API Error.'
                                }
                            };
                        }

                        if (res.status == 404) {
                            return {
                                status: 0,
                                error: {
                                    message: 'Service not found.'
                                }
                            };
                        }
                        try {
                            if (raw) return res.text();
                            var converted = res.json();
                            return converted;
                        } catch (err) {
                            return {
                                status: 0,
                                result: res,
                                error: {
                                    message: 'Corrupted data.'
                                }
                            };
                        }
                    })
                    .then(
                        (result) => {
                            Overlay.close();
                            try {
                                if (result.toString().indexOf('Unauthorized Player Access') >= 0) {
                                    window.location.href = '/';
                                    return;
                                }
                            } catch (err) {
                                //
                            }

                            if (result && !result.status) {
                                throw result;
                            }
                            if (!result || !result.status) {
                                throw result;
                            }
                            if (result.status != 0 && result.staus != 1) {
                                if (result.status == 204) {
                                    throw 'Service is not responding / timeout.';
                                }
                                if (result.status == 400) {
                                    throw 'Bad request.';
                                }
                                if (result.status == 415) {
                                    throw 'Unsupported request.';
                                }
                                if (result.status == 500) {
                                    throw 'API Error.';
                                }
                                if (result.status == 404) {
                                    throw 'Service not found.';
                                }
                            }

                            if (dataName) {
                                getStore().dispatch({
                                    type: 'DATA_LOADED',
                                    payload: {
                                        type: dataName,
                                        payload: result
                                    }
                                });
                            }
                            resolve(result);
                        }
                    ).catch(e => {
                        var errorMessage = null;
                        if (e.error) {
                            //
                            if (e.error.message) {
                                errorMessage = e.error.message;
                            }
                        } else if (typeof e === 'object' && e !== null) {
                            errorMessage = e.toString();
                        }

                        if (dataName) {
                            getStore().dispatch({
                                type: 'LOAD_DATA',
                                payload: {
                                    type: `${dataName}_loading`,
                                    payload: false
                                }
                            });
                        }
                        if (error) {
                            Overlay.close();
                            Modal.open(<ErrorModal title={error} description={errorMessage ?? 'Service error please try later'} />);
                            return;
                        }
                        reject(e);
                    });
            } catch (err) {
                reject('error');
            }
        });
    }
};

