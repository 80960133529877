import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { emptyMargin, model } from './SportsbookMargins';
import { API, SportEndpoints } from '../../../../v2/Lib/Api/Api';
import { lang } from '../../../Centrum/Localization/lang';


export const LeagueMargins = (props) => {
    const [sport, selectSport] = useState(null);
    const [regions, setRegions] = useState([]);
    const [leagues, setLeagues] = useState([]);

    const [selectedRegion, selectRegion] = useState(null);

    const masterData = props.masterData;
    const [data, setData] = useState([]);
    const [updatedRows, setUpdatedRows] = useState({ leagues: {} });

    const [showAll, setShowAll] = useState(null);

    const regionRef = React.createRef();
    const leagueRef = React.createRef();

    useEffect(() => {
        selectSport(props.sports);
        selectSport(props.sports[0]);
        setShowAll(true);
        setData(Object.values(props.margins.leagues));
    }, []);

    useEffect(() => {
        if (showAll == null) return;
        var copy = { ...props.margins };
        var filtered = Object.values(copy.leagues).filter(x => {
            if (showAll) return true;
            if (x.sport.id == sport.id) return true;
            return false;
        });

        setData(filtered);
    }, [showAll]);

    useEffect(() => {
        if (!sport) return;
        API.post(`${SportEndpoints.Regions}/${sport.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.name > b.name ? 1 : -1);
            result.result = result.result.filter((x) => x.name !== '');
            setRegions(result.result);
            selectRegion(result.result[0]);
        }).catch((e) => {
            console.log(e);
        });

    }, [sport]);


    useEffect(() => {
        if (!selectedRegion) return;
        API.post(`${SportEndpoints.Leagues}/${sport.id}/${selectedRegion.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.name > b.name ? 1 : -1);
            result.result = result.result.filter((x) => x.name !== '');
            setLeagues(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [selectedRegion]);

    const context = {
        renderId: (row) => {
            return row.id;
        },
        renderName: (row) => {
            if (showAll) return <div className='flex vertical'>
                <span>{row.name}</span>
                <span>{row.sport.name} / {row.region.name}</span>
            </div>;
            return row.name;
        },
        isChanged: (row) => {
            var key = row.id;
            if (updatedRows.leagues[key]) {
                return true;
            }
            return false;
        },
        addToChanges: (copy, key, payload, type = 'update') => {
            var copyOfUpdatedRows = { ...updatedRows };
            if (JSON.stringify(masterData.leagues[key]) == JSON.stringify(copy.leagues[key])) {
                delete copyOfUpdatedRows.leagues[key];
            } else {
                copyOfUpdatedRows.leagues[key] = { ...payload };
                copyOfUpdatedRows.leagues[key]._type = type;
            }
            setUpdatedRows(copyOfUpdatedRows);
        },
        update: (row, value, field) => {
            var key = row.id;
            const target = field.name;
            var copy = { ...props.margins };
            if (!copy.leagues[key]) {
                copy.leagues[key] = {
                    preMargin: 0,
                    preMin: 0,
                    preMax: 0,
                    liveMargin: 0,
                    liveMin: 0,
                    liveMax: 0
                };
            }
            copy.leagues[key][target] = (value == true || value == false) ? value : parseFloat(value);
            props.setMargins(copy);

            context.addToChanges(copy, key, copy.leagues[key]);
        }
    };

    const add = () => {
        var region = regions.find(x => x.id == parseInt(regionRef.current.value));
        var league = leagues.find(x => x.id == parseInt(leagueRef.current.value));
        var copy = { ...props.margins };
        var key = league.id;
        if (!copy.leagues[key]) {
            copy.leagues[key] = emptyMargin();
            copy.leagues[key].sport = {
                id: sport.id,
                name: sport.name
            };
            copy.leagues[key].region = {
                id: region.id,
                name: region.name
            };
            copy.leagues[key].id = league.id;
            copy.leagues[key].name = league.name;
        }

        setData(Object.values(copy.leagues));
        props.setMargins(copy);
    };

    const update = (row, empty) => {
        var payload = {
            leagues: {

            }
        };

        payload.leagues[row.id] = empty ? emptyMargin() : row;
        return payload.leagues[row.id];
    };

    const remove = (row) => {
        var copy = { ...props.margins };
        var key = row.id;
        delete copy.leagues[key];
        props.setMargins(copy);
        setData(Object.values(copy.leagues));
        context.addToChanges(copy, key, update(row, true), 'delete');
    };



    return <div className='sport-margins'>
        <div className='content'>
            <div className='panel'>
                <div className='padding flex gap-5'>
                    <div className='flex gap-10'>
                        <select defaultValue={sport?.id} onChangeCapture={(e) => {
                            selectSport(props.sports.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {props.sports.sort((a, b) => a.weight > b.weight ? 1 : -1).map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        <select ref={regionRef} onChangeCapture={(e) => {
                            selectRegion(regions.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {regions.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        <select ref={leagueRef}>
                            {leagues.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        <Button title='Add' onClick={() => add()} />
                    </div>
                    <div className='flex align-right'>
                        <Button title={showAll ? lang('Show %1 Only', [sport.name]) : 'Show All'} onClick={() => setShowAll(!showAll)} />
                    </div>
                </div>
            </div>
            <Table model={model}
                data={data}
                context={context}
                onRenderRow={(row, elements, className, context) => {
                    var c = context.isChanged(row) ? 'changed' : className;
                    return <tr className={c}>
                        {elements}
                    </tr>;
                }}
                buttons={(row) => {
                    return <div className='flex gap-10'>
                        <Button title='Remove' onClick={() => remove(row)} />
                    </div>;
                }}
            />
        </div>
        <div className='panel padding'>
            {Object.keys(updatedRows.leagues).length > 0 ? <Button title={lang('Save %1 changes', [Object.keys(updatedRows.leagues).length])} onClick={() => {
                props.save(updatedRows);
            }} /> : <Button title='Save' className='disabled' />}
        </div>
    </div>;
};

LeagueMargins.propTypes = {
    sports: PropTypes.array,
    margins: PropTypes.object,
    masterData: PropTypes.object,
    setMargins: PropTypes.func,
    save: PropTypes.func
};