// IMPORT PACKAGE REFERENCES

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { application, Endpoints } from '../../Application/Application';
import { CentrumForm, SaveButtons } from '../../Centrum/CentrumForm/CentrumForm';
import { Card } from '../../Centrum/Card/Card';
import { Button } from '../../Controls/Buttons/Button';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../../Controls/Form/Form';
import { FormInput } from '../../Controls/Form/FormInput';



// COMPONENT
class ExchangeRates extends Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, props);
    }

    componentDidMount() {
        application().resolve(null, Endpoints.ExchangeRates, {}, 'retreiving exchange rates', 'unable to fetch exchange rates').then((result) => {
            this.setState({ rates: result.result });
        });

    }

    saveRate(data) {
        data.EntryDate = new Date();
        application().resolve(null, Endpoints.SaveExchangeRate, data, 'saving exchange rate', 'unable to save exchang rate').then(() => {

        });

    }

    newCurrency() {
        application().modal.open(<ConfirmationModal
            onConfirm={() => {
                var data = this.newCurrencyForm.submit();
                data.currencyCode = data.currencyCode.toUpperCase();
                data.base = data.base.toUpperCase();
                data.buy = 0;
                data.sell = 0;
                var rates = this.state.rates;
                rates.push(data);
                this.setState({ rates: rates });
            }}
            title='New Pair'>
            <Form ref={(r) => this.newCurrencyForm = r}>
                <div className='row'>
                    <FormInput title='Currency' name='currencyCode' />
                    <FormInput title='Base' name='base' />
                </div>
            </Form>
        </ConfirmationModal>);
    }

    renderRates() {
        return this.state.rates.map(rate => {
            return <Card caption={`${rate.currencyCode}/${rate.base}`} key={rate.currencyCode} className='auto-height'>
                <CentrumForm model='exchangeRate' data={rate} onSubmit={(data) => this.saveRate(data)} buttons={SaveButtons} />
            </Card>;
        });
    }

    render() {
        return <div className='centrum-flex vertical auto-height wrap fit'>
            <div className='centrum-flex alignRight auto-width'>
                <Button title='New Currency' className='alignRight' onClick={() => this.newCurrency()} />
            </div>
            {this.renderRates()}
        </div>;
    }
}

ExchangeRates.defaultProps = {
    rates: []
};

ExchangeRates.propTypes = {
    rates: PropTypes.array
};


const mapStateToProps = state => {
    return {
        settings: state.settings
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ExchangeRates);
export { hoc as ExchangeRates };