// IMPORT PACKAGES
import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { ContextMenuManager } from './ContextMenu/ContextMenu';

// IMPORT STORE


// IMPORT COMPONENTS

import { AppRouter } from './routers/AppRouter';
//import { AppRouterFlux } from './routers/AppRouterFlux';

const AppRouterFlux = null;

export const App = ({ history, store }) => {
    const flux = false;
    return <Provider store={store}>
        <div className="container">
            {flux ? <AppRouterFlux history={history} /> : <AppRouter history={history} />}
        </div>
    </Provider>;
};

new ContextMenuManager();

App.propTypes = {
    history: PropTypes.object,
    store: PropTypes.object
};

