import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { InputTypes } from '../../../../v2/Components/Controls/Form/FormInput';

import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { API, DataEndpoints } from '../../../../v2/Lib/Api/Api';
import { lang } from '../../../Centrum/Localization/lang';



const notificationModel = {
    fields: [
        {
            name: 'entryDate',
            title: 'Date',
            type:InputTypes.DateTime
        },
        {
            name: 'operator',
            title: 'Operator'
        },
        {
            name: 'playerId',
            title: 'Player Id'
        },
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'message',
            title: 'Message'
        },
        {
            name: 'sport',
            title: 'Sport',
            renderRow:(row)=>{
                const data = JSON.parse(row.data);
                return data.Sport;
            }
        },
        {
            name: 'region',
            title: 'Region',
            renderRow:(row)=>{
                const data = JSON.parse(row.data);
                return data.Region;
            }
        },
        {
            name: 'league',
            title: 'League',
            renderRow:(row)=>{
                const data = JSON.parse(row.data);
                return data.League;
            }
        },
        {
            name: 'event',
            title: 'Event',
            renderRow:(row)=>{
                const data = JSON.parse(row.data);
                return data.Event;
            }
        },
        {
            name: 'amount',
            title: 'Amount',
            renderRow:(row)=>{
                const data = JSON.parse(row.data);
                return data.Amount;
            }
        },
        {
            name: 'limit',
            title: 'Limit',
            renderRow:(row)=>{
                const data = JSON.parse(row.data);
                return data.Limit;
            }
        },
        {
            name: 'volume',
            title: 'Volume',
            renderRow:(row)=>{
                const data = JSON.parse(row.data);
                return data.Volume;
            }
        }
    ]
};




export const RiskNotifications = () => {
    const notificationCount = useSelector((state) => state.volumes).volumes?.NotificationCount;
    const notifications = useSelector((state) => state.volumes?.notifications);
    const dispatch = useDispatch();

    const loadNotifications = () => {
        API.post(DataEndpoints.OperatorNotifications, { source: 'risk' }).then((result) => {
            dispatch({ type: 'VOLUME_NOTIFICATIONS', payload: result.result });
        });
    };

    return <div>
        <p className='flex gap-5 padding border radius information'>
            {lang('Total %1 notifications', [notificationCount])} <Button title='Load' onClick={() => loadNotifications()} />
        </p>
        <Table model={notificationModel} data={notifications} key={notifications.length} />
    </div>;
};
