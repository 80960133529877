import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../../Centrum/Localization/lang';
import { Icon } from '../../Icon/Icon';

export const Pagination = (props) => {
    const [currentPage, setCurrentPage] = useState(props.currentPage);

    const totalPages = Math.ceil(props.total / props.recordsPerPage);
    var startPage = currentPage - 5;
    if (startPage < 0) startPage = 0;

    let max = startPage + props.recordsPerPage;
    if (max > totalPages) max = totalPages;
    let pages = [];
    for (var i = startPage; i < max; i++) {
        pages.push({ index: i + 1 });
    }

    const onPageClicked = (current) => {
        setCurrentPage(current);
        props.onPageClicked(current);
    };
    

    return <div key='pagination' className='table-pagination'>
        <div className={currentPage == 1 ? 'page-previous disabled' : 'page-previous'} onClick={() => onPageClicked(currentPage)}><button>{lang('Previous')}</button></div>
        <div className='pages'>
            <button key='first' onClick={() => onPageClicked(1)} className={currentPage == 0 ? 'btn-first-page disabled' : 'btn-first-page'}><Icon icon={'step-backward'} /></button>
            {
                pages.map(page => {
                    return <button key={page.index} onClick={() => onPageClicked(page.index)} className={(currentPage) === page.index ? 'table-pagination-current' : ''}>{page.index}</button>;
                })
            }
            <button key='last' onClick={() => onPageClicked(totalPages)} className={currentPage == totalPages - 1 || totalPages <= 1 ? 'btn-last-page disabled' : 'btn-last-page'}><Icon icon={'step-forward'} /></button>
        </div>
        <div className={currentPage == totalPages  || totalPages <= 1 ? 'page-next disabled' : 'page-next'} onClick={() => onPageClicked(currentPage + 2)}><button>{lang('Next')}</button></div>
    </div>;
};

Pagination.propTypes = {
    total: PropTypes.number,
    currentPage: PropTypes.number,
    recordsPerPage: PropTypes.number,
    onPageClicked: PropTypes.func
};