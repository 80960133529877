import './_languages.scss';
import React, { useEffect, useState } from 'react';
import { Card } from '../../../../v2/Components/Card/Card';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { API, CMSEndpoints, DataEndpoints } from '../../../../v2/Lib/Api/Api';
import { Icon } from '../../../../v2/Components/Icon/Icon';
import { lang } from '../../../../v2/Lib/Common/language';
import { application } from '../../../Application/Application';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';
import { ErrorModal } from '../../../Modal/Modals/Error/ErrorModal';
import { SportsTree } from '../../../Controls/SportsTree/SportsTree';



const CMSKeys = {
    'website': 'Web',
    'sports': 'Sports',
    'regions': 'Regions',
    'leagues': 'Leagues',
    'teams': 'Teams',
    'markets': 'Markets',
    'selections': 'Selections',
    'marketGroups': 'Market Groups',
    'overviewMarkets': 'Overview Markets'
};



export const CMSLanguages = () => {
    const [data, setData] = useState({ website: {}, marketGroups: {}, overviewMarkets: {}, captions: {}, copy: {} });
    const [filter, setFilter] = useState('a');
    const [edit, setEdit] = useState(null);
    const [search, setSearch] = useState(null);
    const [languages, setAvailableLanguages] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState({});
    const [targetKeys, setTargetKeys] = useState('website');
    const searchRef = React.createRef();

    useEffect(() => {
        API.post(`${CMSEndpoints.GetSportCategoriesLanguages}/${window.user.features.feedType}`, {}).then((result) => {
            var categories = {};

            (result.result ? JSON.parse(result.result) : []).map(x => {
                categories[x.name] = x.languages;
            });


            API.post(`${CMSEndpoints.GetSportRegionsLanguages}/${window.user.features.feedType}`, {}).then((result) => {
                var regions = {};

                (result.result ? JSON.parse(result.result) : []).map(x => {
                    regions[x.name] = x.languages;
                });

                API.post(`${CMSEndpoints.GetSportMarketLanguages}/${window.user.features.feedType}`, {}).then((result) => {
                    var markets = {};

                    (result.result ? JSON.parse(result.result) : []).map(x => {
                        markets[x.Name] = x.Languages;
                    });


                    API.post(`${CMSEndpoints.GetSportSelectionLanguages}/${window.user.features.feedType}`, {}).then((result) => {
                        var selectionsRaw = result.result ? JSON.parse(result.result) : {};
                        var selections = {};
                        Object.keys(selectionsRaw).map(x => {
                            selections[x] = selectionsRaw[x].Languages;
                        });


                        selections['Player 1'] = {
                            Tr: '1. Oyuncu',
                            Es: 'Jugador 1'
                        };

                        selections['Player 2'] = {
                            Tr: '2. Oyuncu',
                            Es: 'Jugador 2'
                        };

                        selections['Home Team'] = {
                            Tr: 'Ev sahibi',
                            Es: 'Equipo Local'
                        };

                        selections['Away Team'] = {
                            Tr: 'Rakip takım',
                            Es: 'Equipo Visitante'
                        };

                        API.post(`${CMSEndpoints.GetDefaultLanguage}`).then((result) => {
                            var converted = JSON.parse(result.result);
                            loadKeys(converted, categories, regions, markets, selections);
                        });
                    });
                });
            });
        });

    }, []);

    useEffect(() => {
        if (!targetKeys) return;
        if (!data) return;
        if (!data.captions[targetKeys]) return;
        setFilter(data.captions[targetKeys][0]);
    }, [targetKeys]);

    const loadKeys = (defaultLanguageKeys, defaultSportNames, defaultRegionNames, defaultMarketNames, defaultSelectionNames) => {
        API.post(`${CMSEndpoints.Contents}/true`, { type: 'language' }, 'please wait', 'unable to fetch language').then((result) => {

            // Website is the saved language for the website
            var website = JSON.parse(result.result.find(x => x.key == 'Content').content);

            //  Sport categories
            var sports = JSON.parse(result.result.find(x => x.key == 'Sports')?.content ?? '{}');

            //  Regions 
            var regions = JSON.parse(result.result.find(x => x.key == 'Regions')?.content ?? '{}');

            // Leagues
            var leagues = JSON.parse(result.result.find(x => x.key == 'Leagues')?.content ?? '{}');

            // Teams
            var teams = JSON.parse(result.result.find(x => x.key == 'Teams')?.content ?? '{}');

            //  Markets are the sport markets
            var markets = JSON.parse(result.result.find(x => x.key == 'Markets')?.content ?? '{}');

            //  Selections of sport markets
            var selections = JSON.parse(result.result.find(x => x.key == 'Selections')?.content ?? '{}');

            // Market Groups are saved from sport setup
            var marketGroups = JSON.parse(result.result.find(x => x.key == 'MarketGroups')?.content ?? '{}');

            // Overview Markets are saved from sport setup
            var overviewMarkets = JSON.parse(result.result.find(x => x.key == 'OverviewMarkets')?.content ?? '{}');

            var defaultLanguage = result.result.find(x => x.key == 'DefaultLanguage').content;
            var version = result.result.find(x => x.key == 'Version').content;


            API.post(DataEndpoints.AvailableLanguages, {}).then((result) => {
                var availableLanguages = result.result;
                var languageKeys = {};
                availableLanguages.map(x => {
                    languageKeys[x.key] = true;
                });

                setAvailableLanguages(availableLanguages);

                setData({
                    website: prepareKeys(website, defaultLanguageKeys, languageKeys),
                    marketGroups: prepareKeys(marketGroups, marketGroups, languageKeys),
                    sports: prepareKeys(sports, defaultSportNames, languageKeys),
                    regions: prepareKeys(regions, defaultRegionNames, languageKeys),
                    markets: prepareKeys(markets, defaultMarketNames, languageKeys),
                    leagues: prepareKeys(leagues, {}, languageKeys),
                    teams: prepareKeys(teams, {}, languageKeys),
                    selections: prepareKeys(selections, defaultSelectionNames, languageKeys),
                    overviewMarkets: prepareKeys(overviewMarkets, overviewMarkets, languageKeys, website),
                    captions: {
                        sports: prepareCaptions(defaultSportNames),
                        regions: prepareCaptions(defaultRegionNames),
                        leagues: prepareCaptions(leagues, true),
                        teams: prepareCaptions(teams, true),
                        markets: prepareCaptions(defaultMarketNames),
                        selections: prepareCaptions(defaultSelectionNames),
                        website: prepareCaptions(defaultLanguageKeys),
                        marketGroups: prepareCaptions(marketGroups),
                        overviewMarkets: prepareCaptions(overviewMarkets),
                    },
                    copy: structuredClone(website),
                    version: version,
                    defaultLanguage: defaultLanguage
                });
                setLoaded(true);
            });
        });
    };

    const prepareKeys = (source, defaults, languages) => {
        Object.keys(defaults).map(key => {
            if (!source[key]) {
                source[key] = defaults[key];
            }

            Object.keys(defaults[key]).map(l => {
                if (!languages[l]) {
                    delete source[key][l];
                } else {
                    if (!source[key][l] || source[key][l] == '') {
                        source[key][l] = defaults[key][l] ?? defaults[key].En;
                    }
                }
            });
        });


        return source;
    };

    const prepareCaptions = (defaultLanguageKeys, metaId) => {
        var captions = {};
        Object.keys(defaultLanguageKeys).map(x => {
            if (metaId) {
                captions[x.toLocaleLowerCase().split('|')[1][0]] = 0;
            } else {
                captions[x[0].toLocaleLowerCase()] = 0;
            }
        });
        captions = Object.keys(captions).sort((a, b) => a < b ? -1 : 1);
        return captions;
    };

    const save = () => {
        data.markets["Total Corners Away Team"] = data.markets["Total Corners - Away Team"];
        delete data.markets["Total Corners - Away Team"];
        data.markets["Total Corners Home Team"] = data.markets["Total Corners - Home Team"];
        delete data.markets["Total Corners - Home Team"];
        setSaving(true);


        API.post(CMSEndpoints.SaveContent, {
            Type: 'Language',
            Key: 'Content',
            Content: JSON.stringify(data.website)
        }, 'saving language', 'save failed').then(
            () => {

                API.post(CMSEndpoints.SaveContent, {
                    Type: 'Language',
                    Key: 'Sports',

                    Content: JSON.stringify(data.sports)
                }, 'saving language', 'save failed').then(
                    () => {
                        API.post(CMSEndpoints.SaveContent, {
                            Type: 'Language',
                            Key: 'Regions',

                            Content: JSON.stringify(data.regions)
                        }, 'saving language', 'save failed').then(
                            () => {

                                API.post(CMSEndpoints.SaveContent, {
                                    Type: 'Language',
                                    Key: 'Leagues',

                                    Content: JSON.stringify(data.leagues)
                                }, 'saving language', 'save failed').then(
                                    () => {

                                        API.post(CMSEndpoints.SaveContent, {
                                            Type: 'Language',
                                            Key: 'Teams',

                                            Content: JSON.stringify(data.teams)
                                        }, 'saving language', 'save failed').then(
                                            () => {
                                                API.post(CMSEndpoints.SaveContent, {
                                                    Type: 'Language',
                                                    Key: 'Markets',

                                                    Content: JSON.stringify(data.markets)
                                                }, 'saving language', 'save failed').then(
                                                    () => {
                                                        API.post(CMSEndpoints.SaveContent, {
                                                            Type: 'Language',
                                                            Key: 'Selections',

                                                            Content: JSON.stringify(data.selections)
                                                        }, 'saving language', 'save failed').then(
                                                            () => {
                                                                API.post(CMSEndpoints.SaveContent, {
                                                                    Type: 'Language',
                                                                    Key: 'MarketGroups',

                                                                    Content: JSON.stringify(data.marketGroups)
                                                                }, 'saving language', 'save failed').then(
                                                                    () => {
                                                                        API.post(CMSEndpoints.SaveContent, {
                                                                            Type: 'Language',
                                                                            Key: 'OverviewMarkets',

                                                                            Content: JSON.stringify(data.overviewMarkets)
                                                                        }, 'saving language', 'save failed').then(
                                                                            () => {
                                                                                application().snackbar.open('Language saved.');
                                                                                setSaving(false);
                                                                            }
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        );
                                                    }
                                                );
                                            }
                                        );
                                    }
                                );
                            }
                        );
                    }
                );
            }
        );
    };

    const publish = () => {
        setSaving(true);
        var languageKeys = {};
        languages.map(x => {
            languageKeys[x.key] = x.key;
        });

        API.post(CMSEndpoints.PublishWebSiteLanguage, languageKeys, 'publishing language', 'publish failed').then(
            (result) => {
                application().snackbar.open('Language published.');
                var copy = data;
                copy.version = result.result.split(':')[1];
                setData(copy);
                setSaving(false);
            }
        );
    };

    const remove = (key) => {
        var copy = { ...data };
        delete copy[targetKeys][key];


        copy.captions = {
            language: prepareCaptions(copy.website),
            marketGroups: prepareCaptions(copy.marketGroups),
        };

        setData(copy);
        /*
        setSaving(true);
        API.post(`${CMSEndpoints.DeleteLanguageKey}`, { key: key }).then(() => {
            setSaving(false);
        }).catch((err) => {
            console.log(err);
            setSaving(false);
        });
        */
    };


    const removeSelected = () => {
        setSaving(true);
        API.post(`${CMSEndpoints.DeleteLanguageKey}`, { keys: Object.keys(selectedKeys) }, 'please wait', 'unable to update key').then(() => {
            setSaving(false);
            var copy = { ...data };

            Object.keys(selectedKeys).map(key => {
                delete copy[targetKeys][key]
            });

            setSelectedKeys({});
            setData(copy);
        });
    };

    const update = (value, key) => {
        var copy = { ...data };
        if (value == '') {
            delete copy[targetKeys][edit][key];
        } else {
            copy[targetKeys][edit][key] = value;
        }
        setData(copy);
    };

    const addNew = () => {
        const newKeyRef = React.createRef();
        application().modal.open(<ConfirmationModal title='New Key'
            onConfirm={
                () => {
                    var copy = { ...data };
                    if (newKeyRef.current.value == '') return;
                    if (copy.website.hasOwnProperty(newKeyRef.current.value) || copy.website.hasOwnProperty(newKeyRef.current.value.toLocaleLowerCase())) {
                        application().modal.open(<ErrorModal title='Error'>
                            <p>{lang('Can not add an existing key')}</p>
                        </ErrorModal>);
                        return;
                    }

                    copy[targetKeys][newKeyRef.current.value] = {};
                    copy.captions[targetKeys] = prepareCaptions(copy[targetKeys]);

                    setData(copy);
                    setFilter(newKeyRef.current.value[0]);
                    setEdit(newKeyRef.current.value);
                    setSearch(newKeyRef.current.value);
                    searchRef.current.value = newKeyRef.current.value;
                }
            }>
            <input type='text' style={{ width: '100%' }} ref={newKeyRef} />
        </ConfirmationModal>);
    };


    if (!loaded) return <></>;

    return <div className={`language-configuration${saving ? ' disabled' : ''}`}>
        <div className='panel padding'>
            <div className='flex gap-5'>
                {saving ? <div className='flex gap-5'><label>{lang('Saving')}</label></div> : <div className='flex gap-5'>
                    <label>Version</label>
                    <span>{data?.version}</span>
                </div>
                }
                <div className='align-right flex gap-5'>
                    <Button title='Save' onClick={() => save()} />
                    <Button title='Publish' onClick={() => publish()} />
                </div>
            </div>
        </div>
        <div className='panel padding flex vertical gap-10'>
            <div className='flex gap-10'>
                <Button title='Add new' onClick={() => addNew()} className='hidden' />
                {
                    Object.keys(CMSKeys).map(x => {
                        return <Button title={CMSKeys[x]} className={x == targetKeys ? ' active' : ''} onClick={() => setTargetKeys(x)} key={x} />
                    })
                }
            </div>
            <div className='flex letters padding'>
                {
                    (data.captions[targetKeys]).map(x => {
                        return <div key={x} onClick={() => setFilter(x.toLocaleLowerCase())} className={`border radius shadow${filter == x.toLocaleLowerCase() ? ' selected' : ''}`}>
                            <label>{x}</label>
                        </div>;
                    })}
            </div>
        </div>
        {
            Object.keys(selectedKeys).length > 0 &&
            <div className='panel padding'>
                <div className='flex letters gap-10'>
                    <label>Total selected {Object.keys(selectedKeys).length}</label>
                    <Button title='Delete selected' onClick={() => removeSelected()} />
                </div>
            </div>
        }
        <Card title={CMSKeys[targetKeys]} className='keys' buttons={() => {
            return <div className='flex gap-5'>
                <label>{lang('Search')}</label><input type='text' ref={searchRef} />
                <Button title={search ? 'Clear' : 'Search'} onClick={() => {
                    if (search) {
                        setSearch(null);
                        searchRef.current.value = '';
                    } else {
                        setSearch(searchRef.current.value.toLocaleLowerCase());
                    }
                }} />
            </div>
        }}>
            <div className='flex gap-5 fit'>
                {targetKeys == 'leagues' && <SportsTree regions leagues onRenderLeagueOptions={(league) => {
                    const exists = data.leagues[`${league.id}|${league.name}`];

                    if (exists) {
                        return <div className='flex add-league small' onClick={() => {
                            setFilter(league.name[0].toLowerCase());
                            setEdit(`${league.id}|${league.name}`);
                        }
                        }>{lang('edit')}</div>
                    }
                    return <div className='flex add-league small' onClick={() => {
                        var copy = { ...data };
                        copy.leagues[`${league.id}|${league.name}`] = {
                            En: league.name,
                            _metaId: league.id,
                            _metaName: `${league.sportName}/${league.regionName}/${league.name}`
                        };
                        copy.captions.leagues = prepareCaptions(copy.leagues, true);
                        setData(copy);
                        setFilter(league.name[0].toLowerCase());
                        setEdit(`${league.id}|${league.name}`);
                    }
                    }>{lang('translate')}</div>
                }} />}
                {targetKeys == 'teams' && <SportsTree regions leagues teams onRenderTeamOptions={(team) => {
                    const exists = data.teams[`${team.id}|${team.name}`];

                    if (exists) {
                        return <div className='flex add-league small' onClick={() => {
                            setFilter(team.name[0].toLowerCase());
                            setEdit(`${team.id}|${team.name}`);
                        }
                        }>{lang('edit')}</div>
                    }
                    return <div className='flex add-league small' onClick={() => {
                        var copy = { ...data };
                        copy.teams[`${team.id}|${team.name}`] = {
                            En: team.name,
                            _metaId: team.id,
                            _metaName: `${team.sportName}/${team.name}`
                        };
                        copy.captions.teams = prepareCaptions(copy.teams, true);
                        setData(copy);
                        setFilter(team.name[0].toLowerCase());
                        setEdit(`${team.id}|${team.name}`);
                    }
                    }>{lang('translate')}</div>
                }} />}
                <div className='flex vertical gap-10 align-start'>
                    {
                        Object.keys(data[targetKeys]).sort((a, b) => a[0].toLocaleLowerCase() < b[0].toLocaleLowerCase() ? -1 : 1).map(x => {
                            if (!edit && search && x.toLowerCase().indexOf(search) == -1) {
                                return;
                            }
                            var hasMeta = x.indexOf('|') > 0;
                            if (!hasMeta && !edit && !search && (filter && x && x[0].toLowerCase() !== filter)) return;
                            if (hasMeta && !edit && !search && (filter && x && x.split('|')[1][0].toLowerCase() !== filter)) return;
                            if (edit && edit != x) return;
                            const values = data[targetKeys][x];
                            return <div className='flex vertical border radius key' type='fa-light' key={x}>
                                <header>
                                    {
                                /*
                                <Icon icon={selectedKeys.hasOwnProperty(x) ? 'check-square' : 'square'} onClick={() => {
                                    var copy = { ...selectedKeys };
                                    if (copy.hasOwnProperty(x)) {
                                        delete copy[x];
                                    } else {
                                        copy[x] = x;
                                    }
                                    setSelectedKeys(copy);
                                }} />
                            */}
                                    {!hasMeta && <span>{x}</span>}
                                    {hasMeta && <span>{data[targetKeys][x]._metaName}</span>}
                                    <div className='align-right flex gap-5'>
                                        <Icon icon='edit' onClick={() => setEdit(x)} />
                                        {/*!edit && <Icon icon='times' onClick={() => remove(x)} />*/}
                                        {edit && <Icon icon='check' onClick={() => setEdit(null)} />}
                                    </div>
                                </header>
                                {languages.map(l => {
                                    const value = values[l.key];
                                    return <div className='flex vertical gap-5 border-top padding' key={l}>
                                        <div className='flex vertical gap-5' onClick={() => {
                                            setEdit(x);
                                        }}>
                                            <label>{l.name}</label>
                                            <div className='flex gap-5'>
                                                {edit == x ? <input type='text' defaultValue={value ?? ''} onChangeCapture={(e) => update(e.target.value, l.key)} /> : <span className='key-value'>{value}</span>}
                                                {
                                                    edit == x && <Icon icon='times' className='align-right' onClick={(e) => {
                                                        e.target.parentNode.getElementsByTagName('input')[0].value = '';
                                                        update('', l.key);
                                                    }} />
                                                }
                                            </div>
                                        </div>
                                    </div>;
                                })}

                                {edit == x && <div className='flex gap-5 padding surface-med'>
                                    <Button title='Update' onClick={() => {
                                        var keys = [];
                                        Object.keys(data[targetKeys][edit]).map(x => {
                                            keys.push({
                                                language: x,
                                                key: edit,
                                                value: data[targetKeys][edit][x]
                                            })
                                        });
                                        setSaving(true);
                                        API.post(CMSEndpoints.SaveLanguageKey, keys, 'please wait', 'unable to update key').then(() => {
                                            setEdit(null);
                                            setSaving(false);
                                        });
                                    }} />
                                    <Button title='Close' onClick={() => {
                                        setEdit(null);
                                    }} />
                                    <Button title='Undo' onClick={() => {
                                        const copy = data.copy[edit];
                                        if (copy) {
                                            Object.keys(copy).map(x => {
                                                update(copy[x], x);
                                            })
                                        } else {
                                            remove(edit);
                                        }
                                        setEdit(null);
                                    }} />
                                </div>}
                            </div>;
                        })
                    }
                </div>
            </div>
        </Card>
    </div>;
};