import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../Application/Application';
import { Card } from '../../../Centrum/Card/Card';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { lang } from '../../../Centrum/Localization/lang';
import { Tab } from '../../../Centrum/Tab/Tab';
import { Button } from '../../../Controls/Buttons/Button';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import Table from '../../../Controls/Table/SortableTable/SortableTable';
import { Dialog } from '../../../Modal/Modals/Dialog/Dialog';


const topEventsModel = {
    fields: [
        {
            name: 'id',
            display: 'Id'
        },
        {
            name: 'name',
            display: 'Name'
        },
        {
            name: 'date',
            display: 'Date',
            type: InputTypes.dateTime,
            formatter: (field, row) => {
                var d = row.date;
                if (d.indexOf('Z') == -1) d += 'Z';
                return new Date(d).toLocaleString('en-Gb', { hour12: false });
            }
        },
        {
            name: 'sport',
            display: 'Sport'
        },
        {
            name: 'region',
            display: 'Region'
        },
        {
            name: 'league',
            display: 'League'
        }
    ]
};


const eventsModel = {
    fields: [
        {
            name: 'id',
            display: 'Id'
        },
        {
            name: 'name',
            display: 'Name'
        },
        {
            name: 'date',
            display: 'Date',
            type: InputTypes.dateTime,
        }
    ]
};



export const TopEvents = (props) => {
    const categories = props.categories;
    if (!categories) return <React.Fragment />;

    const [category, selectCategory] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [league, setLeague] = useState(null);
    const [regions, setRegions] = useState(null);
    const [events, setEvents] = useState(null);
    const [topEvents, setTopEvents] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsKey, setResultsKey] = useState(Date.now());

    var timeout = null;
    var search = null;

    useEffect(() => {
        if (!topEvents) {
            application().resolve(null, Endpoints.GetTopEvents, {}, 'fetching top events', 'unable to fetch top events').then((result) => {
                try {
                    var data = JSON.parse(result.result);
                    if (!data) data = [];
                    setTopEvents(data);
                } catch (err) {
                    setTopEvents([]);
                }
            });
        }
    });

    const onCategoryChanged = (cat) => {
        application().resolve(null, Endpoints.SportRegions + '/' + cat.id, {}, 'fetching regions', 'unable to fetch regions').then((result) => {
            selectCategory(cat);
            setRegions(result.result);
        });
    };

    const onRegionChanged = (regionId) => {
        application().resolve(null, Endpoints.SportLeagues + '/' + category.id + '/' + regionId, {}, 'fetching leagues', 'unable to fetch leagues').then((result) => {
            const sorted = result.result.filter(x => x.name !== '').sort((a, b) => a.name.localeCompare(b.name));
            setLeagues(sorted);
        });
    };

    const onLeagueChanged = (leagueId) => {
        var leagueInformation = leagues.find(x => x.id == leagueId);

        application().resolve(null, Endpoints.SportEvents, { leagueId: leagueInformation.id }, 'fetching leagues', 'unable to fetch leagues').then((result) => {
            setLeague(leagueInformation);
            setEvents(result.result);
            setResultsKey(Date.now());
        });
    };


    const searchEvents = (value) => {
        search = value;
        if (value == '') {
            setSearchResults([]);
            return;
        }
        application().resolve(null, Endpoints.SearchSportEvents + '/' + value, {}, 'searching events', 'unable to fetch events').then((result) => {
            setSearchResults(result.result);
            setResultsKey(Date.now());
        });
    };

    const addEvent = (event) => {
        if (!topEvents.find(x => x.id == event.id)) {
            topEvents.push(event);
            setTopEvents(Object.assign([], topEvents));
        }
    };



    const removeEvent = (event) => {
        topEvents.splice(topEvents.findIndex(x => x.id == event.id), 1);
        setTopEvents(Object.assign([], topEvents));
    };

    const save = () => {
        var saveData = Object.assign([], topEvents);
        saveData.map(x => {
            delete x.__selected;
            delete x.__highlighted;
        });

        application().resolve(null, Endpoints.SaveTopEvents, saveData, 'fetching events', 'unable to fetch events').then(() => {
            application().modal.open(<Dialog title={'Top Events'}>
                {lang('Top events saved')}
            </Dialog >);
        });

    };


    var sports = { 0: 'Please Select' };
    var countries = { 0: 'Please Select' };
    if (categories) categories.sort((a, b) => { return b.weight - a.weight; }).map(category => {
        sports[category.name] = category.name;
    });

    if (regions) regions.sort((a, b) => { return b.weight - a.weight; }).map(region => {
        countries[region.id] = region.name;
    });




    if (!topEvents) return <div />;
    const tabButtons = [
        {
            name: 'search',
            title: 'Search'
        },
        {
            name: 'select',
            title: 'Select'
        }
    ];
    return <div className='surface'>
        <Card>
            <Tab buttons={tabButtons}>
                <Form>
                    <div className='centrum-flex vertical'>
                        <div className='centrum-flex'>
                            <FormInput title='Search Event' type={InputTypes.text} onChange={(form, field, value) => {
                                if (!value) return;
                                if (value.length < 3 || value == '') {
                                    searchEvents('');
                                    clearTimeout(timeout);
                                    return;
                                }

                                if (search && search.indexOf(value) > 0) {
                                    clearTimeout(timeout);
                                    return;
                                }

                                search = value;
                                clearTimeout(timeout);
                                timeout = setTimeout(() => {
                                    searchEvents(value);
                                }, 500);
                            }} />
                        </div>
                        {
                            searchResults && searchResults.length > 0 && search != '' && <Card caption='Search Results' className='margin-top'>
                                <Table
                                    model={topEventsModel}
                                    data={searchResults.sort((a, b) => new Date(a.date) - new Date(b.date))}
                                    key={searchResultsKey}
                                    buttons={(row) => {
                                        if (topEvents.find(x => x.id == row.id)) {
                                            return <Button title='Remove' onClick={() => {
                                                removeEvent(row);
                                            }} />;
                                        }

                                        return <Button title='Add' onClick={() => {
                                            addEvent(row);
                                        }} />;
                                    }}
                                    onRenderRow={(row, elements) => {
                                        if (topEvents.find(x => x.id == row.id)) {
                                            return <tr className='success'>{elements}</tr>;
                                        }
                                        return <tr>{elements}</tr>;
                                    }}
                                    onDataChange={(row, index, value) => {
                                        setTopEvents(Object.assign([], value));
                                    }} />
                            </Card>
                        }
                    </div>
                </Form>
                <Form>
                    <div className='centrum-flex vertical'>
                        <div className='row margin-top'>
                            <FormInput title='Sports' type={InputTypes.select} values={sports} onChange={(form, field, value) => {
                                const selectedCategory = categories.find(x => x.name == value);
                                if (selectedCategory) {
                                    onCategoryChanged(selectedCategory);
                                }
                            }} />
                        </div>
                        {
                            category &&
                            <div className='row margin-top'>
                                <FormInput title='Regions' type={InputTypes.select} values={countries} onChange={(form, field, value) => {
                                    value = parseFloat(value);
                                    if (value) {
                                        onRegionChanged(value);
                                    }
                                }} />
                            </div>
                        }
                        {
                            leagues && <div className='row margin-top'>
                                <div className='form-group'>
                                    <label>Leagues</label>
                                    <select name='leagues' onChangeCapture={(e) => onLeagueChanged(parseFloat(e.target.value))}>
                                        {leagues.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                    </select>
                                </div>

                            </div>
                        }
                        {
                            league && events && <Card caption='League Events' className='margin-top'>
                                <Table
                                    model={eventsModel}
                                    data={events.sort((a, b) => new Date(a.date) - new Date(b.date))}
                                    key={searchResultsKey}
                                    buttons={(row) => {
                                        if (topEvents.find(x => x.id == row.id)) {
                                            return <Button title='Remove' onClick={() => {
                                                removeEvent(row);
                                            }} />;
                                        }

                                        return <Button title='Add' onClick={() => {
                                            addEvent(row);
                                        }} />;
                                    }}
                                    onRenderRow={(row, elements) => {
                                        if (topEvents.find(x => x.id == row.id)) {
                                            return <tr className='success'>{elements}</tr>;
                                        }
                                        return <tr>{elements}</tr>;
                                    }}
                                    onDataChange={(row, index, value) => {
                                        setTopEvents(Object.assign([], value));
                                    }} />
                            </Card>
                        }

                    </div>
                </Form>
            </Tab>
        </Card>
        <Card caption='Top Events' className='margin-top'>
            <Table
                model={topEventsModel}
                data={topEvents}
                key={topEvents}
                options={{
                    data: {
                        delete: {
                            enabled: true,
                            header: true
                        }
                    },
                    draggable: {
                        enabled: true
                    },
                    selectable: true
                }}
                onDataChange={(row, index, value) => {
                    setTopEvents(Object.assign([], value));
                }}
            />

            <div className='centrum-flex padding'>
                <Button title='Save' onClick={() => save()} className='button alignRight' />
            </div>
        </Card>
    </div>;
};


TopEvents.propTypes = {
    categories: PropTypes.object
};
