// INITIALIZE STATE

const initialState = {
    busy: false,
    menuNotifications: null
};




// REDUCER
export const DataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOAD_DATA':
            var l = { ...state };
            l[`${action.payload.type}_loading`] = true;
            l.busy = action.payload.title;
            return l;
        case 'DATA_LOADED':
            var n = { ...state };
            n[action.payload.type] = action.payload.payload;
            n[`${action.payload.type}_loading`] = false;
            n.busy = false;
            return n;
        case 'MENU_NOTIFICATIONS':
            var m = { ...state };
            m.menuNotifications = action.payload;
            return m;
    }

    if (action.type.indexOf('_') == 0) {
        var newState = Object.assign({}, state);
        var property = '';

        if (action.type.indexOf('_PENDING') >= 0) {
            property = action.type.replace('_PENDING', '');
            newState[property + '_loading'] = true;

            return newState;
        }

        if (action.type.indexOf('_FULFILLED') >= 0) {
            property = action.type.replace('_FULFILLED', '');
            property = property.substring(1, property.length);
            newState[property] = action.payload;
            newState[property + '_loading'] = false;
            return newState;
        }

        if (action.type.indexOf('_FAILED') >= 0) {
            property = action.type.replace('_FAILED', '');
            property = property.substring(1, property.length);
            newState[property + '_loading'] = false;
            return newState;
        }
    }
    return state;
};