import './_modal.scss';

import React from 'react';
import PropTypes from 'prop-types';


const Modal = (props) => {
    if (!props.modal) return <React.Fragment />;
    return <div className='modal-container'>
        <div className='modal'>
            {props.modal}
        </div>
    </div>;
};

Modal.propTypes = {
    modal: PropTypes.node
};
export default Modal;
