
import React, { useEffect, useState } from 'react';
import { Card } from '../../../../../v2/Components/Card/Card';
import { Button } from '../../../../../v2/Components/Controls/Button/Button';
import { Form } from '../../../../../v2/Components/Controls/Form/Form';
import { API, CasinoLimitsEndpoints, ProviderEndpoints } from '../../../../../v2/Lib/Api/Api';
import { application } from '../../../../Application/Application';
import { ProviderGameTypes } from '../../../../Centrum/_models/ModelEnums';
import { ErrorModal } from '../../../../Modal/Modals/Error/ErrorModal';
import { CasinoLimitsGames } from './CasinoLimitsGames';


export const CasinoGameLimits = () => {
    const [limits, setLimits] = useState({});
    const [providers, setProviders] = useState(null);
    const [selectedProvider, selectProvider] = useState(null);
    const [updateKey, setUpdateKey] = useState(null);
    const [games, setGames] = useState(null);

    const providersSelectionInputRef = React.createRef();
    const selectedProviderRef = React.createRef();

    useEffect(() => {
        API.post(ProviderEndpoints.Gaming).then((result) => {
            setProviders(result.result);
        });
    }, []);

    useEffect(() => {
        if (!selectedProvider) return;
        API.post(`${CasinoLimitsEndpoints.GameLimits}/${selectedProvider}`).then((result) => {
            var converted = null;
            try {
                converted = JSON.parse(result.result);
            } catch (err) {
                //
            }
            API.post(`${ProviderEndpoints.Games}/${selectedProvider}`).then((gameList) => {
                setGames(gameList.result);
                setLimits(converted);

                setUpdateKey(Date.now());

            });
        });
    }, [selectedProvider]);


    const save = () => {
        var data = selectedProviderRef.current.getData();
        setLimits(data);

        API.post(`${CasinoLimitsEndpoints.SaveGameLimits}/${selectedProvider}`, data).then(() => {
            application().snackbar.open('Limits updated');
        }).catch(() => {
            application().modal.open(<ErrorModal title='Error'>Unable to save limits</ErrorModal>);
        });
    };



    return <React.Fragment>
        <div className='padding flex surface'>
            <Form>
                <div className='row'>
                    {
                        providers &&
                        <div className='form-group'>
                            <select ref={providersSelectionInputRef} onChangeCapture={(e) => {
                                var id = parseInt(e.target.value);
                                selectProvider(id);
                            }}>
                                {!selectedProvider && <option value='null'>Please select</option>}
                                {
                                    providers.map(provider => {
                                        return <option value={provider.id} key={provider.id}>{provider.display}</option>;
                                    })
                                }
                            </select>
                        </div>
                    }
                </div>
            </Form>
        </div>
        {selectedProvider && <CasinoLimitsGames key={updateKey}
            model={[
                {
                    name: 'Id',
                    display: 'Id',
                    type: 'number',
                    readOnly: true
                },
                {
                    name: 'Name',
                    display: 'Name',
                    type: 'text',
                    readOnly: true

                },
                {
                    name: 'GameType',
                    display: 'Game Type',
                    type: 'select',
                    readOnly: true,
                    values: games,
                    formatter: (value) => {
                        return ProviderGameTypes[value];
                    }
                },
                {
                    name: 'MinBet',
                    display: 'Min Stake',
                    type: 'number'
                },
                {
                    name: 'MaxBet',
                    display: 'Max Stake',
                    type: 'number'
                },
                {
                    name: 'MaxPayout',
                    display: 'Max Payout',
                    type: 'number'
                },
                {
                    name: 'BetStop',
                    display: 'Bet Stop',
                    type: 'bool'
                }
            ]}
            limits={limits ?? { general: {}, groups: {} }}
            games={games ?? []}
            saveEnabled={false} ref={selectedProviderRef} />}

        {selectedProvider &&
            <Card className='margin-top'>
                <div className='centrum-flex'>
                    <Button title='Save' className='margin alignRight' onClick={() => save()} />
                </div>
            </Card>
        }
    </React.Fragment >;
};
