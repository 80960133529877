import './_games.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../Centrum/Localization/lang';







export class Games extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
        this.selectedProvider = null;
    }

    renderGames() {
        var games = this.props.games;
        var filter = this.props.filter.toLowerCase();


        if (filter.length > 1) {
            games = games.filter(x => x.name.toLowerCase().indexOf(filter) >= 0);
        }

        var sorted = Object.assign([], games);
        sorted = sorted.sort((a, b) => (a.weight < b.weight) ? 1 : -1);

        if (this.props.selectedProvider > 0) {
            sorted = sorted.filter(x => x.providerId == this.props.selectedProvider);
            this.selectedProvider = this.props.selectedProvider;
        }

        if (this.props.providerEnabledFilter !== 'All') {
            const providerEnabled = this.props.providerEnabledFilter === 'Enabled';
            sorted = sorted.filter(x => x.providerEnabled == providerEnabled);
        }



        if (this.props.gameTypeFilter !== 'All') {
            const liveDealer = this.props.gameTypeFilter === 'livedealer';
            sorted = sorted.filter(x => x.liveDealer == liveDealer);
        }

        if (this.props.imageFilter !== 'All') {
            sorted = sorted.filter(x => x.imageName == null);
        }

        if (this.props.newGames) {
            sorted = sorted.filter(x => x.new == this.props.newGames);
        }

        if (this.props.favourites) {
            sorted = sorted.filter(x => x.featured == this.props.favourites);
        }

        if (this.props.enabled) {
            sorted = sorted.filter(x => x.enabled == this.props.enabled);
        }


        return sorted.map((game) => {
            var cdnSource = 'http://d.tentangle.com/game/';

            var imageName = cdnSource + game.imageName + '?&' + Date.now();
            return <li key={game.gameId}>
                <img onClick={() => this.props.onGameSelected(game)} src={imageName} />
                <span>{game.name}</span>
                <span>{game.providerName}</span>
            </li>;
        });
    }

    render() {
        return <div className='games-component'>
            <div className='title-bar'>
                <h3>{lang(this.props.title)}</h3>
            </div>
            <ul>
                {this.renderGames()}
            </ul>
        </div>;
    }
}


Games.defaultProps = {
    title: '',
    filter: '',
    sorting: 'weights',
    games: [],
    providerEnabledFilter: 'All',
    gameTypeFilter: 'All',
    imageFilter: 'All',
    selectedProvider: 0,
    enabled: true,
    favourites: false,
    newGames: false,
    onGameSelected: () => { },
};

Games.propTypes = {
    title: PropTypes.string,
    filter: PropTypes.string,
    sorting: PropTypes.string, // weights, alpha
    games: PropTypes.array,
    selectedProvider: PropTypes.number,
    providerEnabledFilter: PropTypes.string,
    gameTypeFilter: PropTypes.string,
    imageFilter: PropTypes.string,
    enabled: PropTypes.bool,
    favourites: PropTypes.bool,
    newGames: PropTypes.bool,
    onGameSelected: PropTypes.func
};