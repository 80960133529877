import React from 'react';
import PropTypes from 'prop-types';
import { SliderConfigurator } from '../AppFeatures/AppSlider';


export class AppHomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, props);
        if (!this.state.page.configuration) {
            this.state.page.configuration = {

            };
        }

        if (!this.state.page.configuration.slider) {
            this.state.page.configuration.slider = {
                animate: false,
                duration: 0,
                renderDescriptions: false,
                width: 1600,
                items: []
            };
        }
        if (!this.state.page.configuration.banner) {
            this.state.page.configuration.banner = {
                items: []
            };
        }
    }



    render() {
        var page = this.state.page;
        return <div className='surface high border'>
            <SliderConfigurator name='home' configuration={page.configuration.slider} prefix={this.state.prefix} defaultLanguage={this.state.defaultLanguage} onSave={(data) => {
                page.configuration.slider = data;
                this.props.onSave(page);
            }} />
        </div>;
    }
}

AppHomePage.defaultProps = {
    page: {},
    defaultLanguage: 'en',
    prefix: '',
    onSave: () => { }
};

AppHomePage.propTypes = {
    page: PropTypes.object,
    defaultLanguage: PropTypes.string,
    prefix: PropTypes.string,
    onSave: PropTypes.func
};