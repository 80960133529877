

// INITIALIZE STATE

const initialState = () => {
    return {
        loginInformation: window.user,
        loggedin: window.user !== null && window.user !== undefined && Object.keys(window.user).length > 0,
        logout: {}
    };
};



// REDUCER
export const UserReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'TWO_FA_AUTHENTICATED':
            var twofaauthenticated = { ...state };
            state.loginInformation.TwoFactorAuthenticated = true;
            return twofaauthenticated;
        case 'ENABLE_2FA':
            var copyEnabled = { ...state };
            copyEnabled.loginInformation.AuthType = 1;
            return copyEnabled;
        case 'DISABLE_2FA':
            var copyDisabled = { ...state };
            copyDisabled.loginInformation.AuthType = 0;
            return copyDisabled;
        case 'LOGIN_PENDING':
            return {
                ...state,
                loggedin: false,
                loading: true
            };
        case 'USER_LOGIN':
        case 'LOGIN_FULFILLED':
            window.user = action.payload;

            return {
                ...state,
                loginInformation: action.payload,
                loggedin: true,
                loading: false
            };
        case 'LOGIN_REJECTED':
            return {
                ...state,
                loginInformation: action.payload,
                loggedin: false,
                loading: false
            };
        case 'LOGOUT':
        case 'LOGOUT_FULFILLED':
        case 'LOGOUT_REJECTED':
            return {
                ...state,
                loginInformation: action.payload
            };
        default:
            return state;
    }
};