import './_loading.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';


export class Loading extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <div className='modal-window modal-loading'>
                <React.Fragment><span className='loading-text'>{this.props.title}</span></React.Fragment>
            </div>
        </React.Fragment>;
    }
}

Loading.defaultProps = {
    title:''
};

Loading.propTypes = {
    title: PropTypes.string
};
