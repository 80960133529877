import { loadWidget } from '../services/WidgetService';

export const LOAD_WIDGET = 'LOAD_WIDGET';
export const LOAD_WIDGET_PENDING = 'LOAD_WIDGET_PENDING';
export const LOAD_WIDGET_FULFILLED = 'LOAD_WIDGET_FULFILLED';
export const LOAD_WIDGET_REJECTED = 'LOAD_WIDGET_REJECTED';



// ACTION CREATORS

function loadWidgetAction(endpoint) {
    return {
        type: LOAD_WIDGET,
        payload: loadWidget(endpoint)
    };
}



// EXPORT ACTIONS
export { loadWidgetAction as loadWidget };
