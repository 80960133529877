import React from 'react';
import PropTypes from 'prop-types';
import { icons } from '../../Shared';
import { Icon } from '../../../Controls/Icon/Icon';

export const Notification = (props) => {
    return (
        <div className={'alert' + (props.type !== undefined ? ' ' + props.type : '')}>
            {props.message}{props.children}
            {props.canClose && <div className='close-notification' onClick={props.onClose.bind(this)}><Icon icon={icons.close} /></div>}
        </div>
    );
};

Notification.defaultProps = {
    canClose: false
};

Notification.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node,
    message: PropTypes.string,
    onClose: PropTypes.func,
    canClose: PropTypes.bool
};