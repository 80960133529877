
// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { icons } from '../Centrum/Shared';
import { Page } from '../Centrum/Page/Page';
import { CentrumForm } from '../Centrum/CentrumForm/CentrumForm';
import { postData } from '../../state/services/ServiceHelpers';
import { lang } from '../Centrum/Localization/lang';
import { Icon } from '../Controls/Icon/Icon';


const EditButtons = [
    {
        type: 'submit',
        name: 'Update',
        caption: 'Update'
    }
];

// COMPONENT
export class TemplatesEvents extends React.Component {
    constructor(props) {
        super(props);
        let state = Object.assign({}, props);
        this.id = 0;
        this.state = state;
    }

    getSelectedTemplates(operatorId) {
        if (operatorId) {
            postData({}, '/data/emailTemplatesByOperatorId?id=' + operatorId).then(result => {
                result.result.map((item) => {
                    if (item) {
                        if (this.state.options.length > 0) {
                            if (!this.state.options.some(o => o.Id === item.Id)) {
                                this.state.options.push({ 'Id': item.Id, 'Value': item.Name });
                            }
                        } else {
                            this.state.options.push({ 'Id': item.Id, 'Value': item.Name });
                        }
                    }
                });
            });
        }

    }

    inputChangedHandler(event) {
        if (event) {
            const tempdata = this.state.selectedData;
            tempdata.emailTemplateId = event.target.value;
            this.setState({ selectedData: tempdata });
            this.setState({ defaultValue: event.target.value });
        }
    }

    renderSelectedEmailTeplate() {
        return (
            <div className='form-element'>
                <span>Email Templates</span>
                <select id='selectTemplate'
                    key='selectTemplate'
                    value={this.state.defaultValue}
                    disabled={false}
                    onChange={(event) => this.inputChangedHandler(event)}
                >
                    {<option key='empty' value=''>{lang('Please Select')}</option>}
                    {this.state.options !== undefined && this.state.options !== null && this.state.options.map(key => {
                        return (<option key={key.Id} value={key.Id}>{lang(key.Value)}</option>);
                    })}
                </select>
                <div className='col'></div>
            </div>
            
        );


    }

    componentDidUpdate() {
        if (this.props.selectedData !== null) {
            if (this.props.selectedData.Id !== this.id) {
                this.getSelectedTemplates(this.props.selectedData.operatorId);
                this.setState({ defaultValue: this.props.selectedData.emailTemplateId });
                this.id = this.props.selectedData.Id;
                this.setState({ selectedData: null });
                setTimeout(() => {
                    this.setState({ selectedData: this.props.selectedData });
                }, 10);
            }


        }
    }

    onFieldValueChanged(field, value) {
        field.value = value;
    }

    onClosePage() {
        if (this.props.onClosePage === null) return;
        this.props.onClosePage();
    }

    onSubmit(button) {
        this.props.onSubmit(button, this.state.selectedData);
    }

    refreshGame() {
        console.log('refreshing');
    }

    render() {
        let data = this.state.selectedData;
        let enabled = data != null;
        return (
            <Page show={true} key='TemlatesEventsEditPage'>
                <Icon icon={icons.close} className='close-page' onClick={() => this.onClosePage()} />
                {enabled &&
                    <CentrumForm
                        model='templateEventsEdit'
                        enabled={enabled}
                        buttons={EditButtons}
                        data={data}
                        className='default col2'
                        onButtonClicked={this.onSubmit.bind(this)}
                        onFieldValueChanged={this.onFieldValueChanged.bind(this)}
                    >{
                            this.renderSelectedEmailTeplate()
                        }</CentrumForm>
                }
            </Page>
        );
    }
}

TemplatesEvents.defaultProps = {
    show: true,
    selectedData: {},
    onClosePage: null,
    onSubmit: null,
    id: 0,
    options: [],
    defaultValue: 0
};

TemplatesEvents.propTypes = {
    show: PropTypes.bool,
    selectedData: PropTypes.object,
    id: PropTypes.number,
    onSubmit: PropTypes.func,
    onClosePage: PropTypes.func,
    options: PropTypes.array,
    defaultValue: PropTypes.number
};
