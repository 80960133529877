import { listerInitial, defaultPending, defaultFulfilled, defaultRejected } from './ApplicationReducer';
import {
    GET_MARKETING_EMAIL_TEMPLATE_PENDING,
    GET_MARKETING_EMAIL_TEMPLATE_FULFILLED,
    GET_MARKETING_EMAIL_TEMPLATE_REJECTED,
    SEARCH_MARKETING_EMAIL_TEMPLATE_PENDING,
    SEARCH_MARKETING_EMAIL_TEMPLATE_FULFILLED,
    SEARCH_MARKETING_EMAIL_TEMPLATE_REJECTED,
    ADD_MARKETING_EMAIL_TEMPLATE_FULFILLED,
    ADD_MARKETING_EMAIL_TEMPLATE_PENDING,
    ADD_MARKETING_EMAIL_TEMPLATE_REJECTED,
    UPDATE_MARKETING_EMAIL_TEMPLATE_PENDING,
    UPDATE_MARKETING_EMAIL_TEMPLATE_FULFILLED,
    UPDATE_MARKETING_EMAIL_TEMPLATE_REJECTED,
    DELETE_MARKETING_EMAIL_TEMPLATE_PENDING,
    DELETE_MARKETING_EMAIL_TEMPLATE_FULFILLED,
    DELETE_MARKETING_EMAIL_TEMPLATE_REJECTED,
    ADD_MARKETING_TEMPLATES_EVENTS_PENDING,
    ADD_MARKETING_TEMPLATES_EVENTS_FULFILLED,
    ADD_MARKETING_TEMPLATES_EVENTS_REJECTED,
    SEARCH_MARKETING_TEMPLATES_EVENTS_PENDING,
    SEARCH_MARKETING_TEMPLATES_EVENTS_FULFILLED,
    SEARCH_MARKETING_TEMPLATES_EVENTS_REJECTED
   
} from '../actions/MarketingActions';



const initialState = {
    templates: listerInitial,
    template: {},
    templateList: listerInitial,
    templatesEvents: {},
    templatesEventsList :{},
    loading: false,
    loaded: false,
    failed: false,
    error: {},
    errorDisplayed: false,
    showModal: false,
};




const RESET_STATE = 'RESET_STATE';
export function resetState(state) {
    return {
        type: RESET_STATE,
        payload: state
    };
}


// REDUCER
export const MarketingReducer = (state = initialState, action) => {
    switch (action.type) {

        // EDIT
        case GET_MARKETING_EMAIL_TEMPLATE_PENDING:
            return defaultPending('template', state, action);
        case GET_MARKETING_EMAIL_TEMPLATE_FULFILLED:
            return defaultFulfilled('template', state, action);
        case GET_MARKETING_EMAIL_TEMPLATE_REJECTED:
            return defaultRejected('template', state, action);

        // SEARCH
        case SEARCH_MARKETING_EMAIL_TEMPLATE_PENDING:
            return defaultPending('templateList', state, action);
        case SEARCH_MARKETING_EMAIL_TEMPLATE_FULFILLED:
            return defaultFulfilled('templateList', state, action);
        case SEARCH_MARKETING_EMAIL_TEMPLATE_REJECTED:
            return defaultRejected('templateList', state, action);

        // ADD
        case ADD_MARKETING_EMAIL_TEMPLATE_PENDING:
            return defaultPending('templates', state, action);
        case ADD_MARKETING_EMAIL_TEMPLATE_FULFILLED:
            return defaultFulfilled('templates', state, action);
        case ADD_MARKETING_EMAIL_TEMPLATE_REJECTED:
            return defaultRejected('templates', state, action);

        //Update
        case UPDATE_MARKETING_EMAIL_TEMPLATE_PENDING:
            return defaultPending('templates', state, action);
        case UPDATE_MARKETING_EMAIL_TEMPLATE_FULFILLED:
            return defaultFulfilled('templates', state, action);
        case UPDATE_MARKETING_EMAIL_TEMPLATE_REJECTED:
            return defaultRejected('templates', state, action);

        //Delete
        case DELETE_MARKETING_EMAIL_TEMPLATE_PENDING:
            return defaultPending('templates', state, action);
        case DELETE_MARKETING_EMAIL_TEMPLATE_FULFILLED:
            return defaultFulfilled('templates', state, action);
        case DELETE_MARKETING_EMAIL_TEMPLATE_REJECTED:
            return defaultRejected('templates', state, action);


        // Add templatesevents
        case ADD_MARKETING_TEMPLATES_EVENTS_PENDING:
            return defaultPending('templatesEvents', state, action);
        case ADD_MARKETING_TEMPLATES_EVENTS_FULFILLED:
            return defaultFulfilled('templatesEvents', state, action);
        case ADD_MARKETING_TEMPLATES_EVENTS_REJECTED:
            return defaultRejected('templatesEvents', state, action);


            // search templates Events
        case SEARCH_MARKETING_TEMPLATES_EVENTS_PENDING:
            return defaultPending('templatesEventsList', state, action);
        case SEARCH_MARKETING_TEMPLATES_EVENTS_FULFILLED:
            return defaultFulfilled('templatesEventsList', state, action);
        case SEARCH_MARKETING_TEMPLATES_EVENTS_REJECTED:
            return defaultRejected('templatesEventsList', state, action);


        default:
            return state;
    }
};