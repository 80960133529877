import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { getActions, ActionTypes, getAction } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { Page } from '../../Centrum/Page/Page';
import { Card } from '../../Centrum/Card/Card';
import { Search } from '../../Centrum/Search/Search';
import { apiResult, application, Endpoints } from '../../Application/Application';
import { getHistory } from '../../routers/AppRouter';
import { AffilatePlayerProfile } from './AffiliatePlayerProfile';
import { BreadCrumbs } from '../../Controls/BreadCrumbs/BreadCrumbs';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { currency } from '../../../v2/Lib/Common/currency';
import { DateRanges } from '../../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';
import { AffiliateEndpoints } from '../../../v2/Lib/Api/Api';
import { openBetsOfTicket } from '../RiskManagement/models';
import { toPascalCase } from '../../Centrum/helpers';
import { gotoProfile } from '../../Centrum/_models/Transactions';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes } from '../../Centrum/_models/ModelEnums';
import { lang } from '../../../v2/Lib/Common/language';

const applicationActionType = ActionTypes.AffiliatePlayers;
const applicationActions = getActions(applicationActionType);

const renderBetSlipStatus = (row, isExporting) => {
    var manual = row.Flags & 0x02000000;
    if (isExporting) {
        return manual > 0 ? 'manual' : SportsTicketStatus[row.Status];
    }
    return <div className='centrum-flex vertical'>
        {manual > 0 && <label>{lang('Manual')}</label>}<span>{SportsTicketStatus[row.Status]}</span>
    </div>;
};


export const betSlipResults = {
    fields: [
        {
            name: 'externalTicketId',
            display: 'Ticket Id',
            formatter: (val, row) => {
                row.Product = 0;
                return openBetsOfTicket(val, toPascalCase(row));
            }
        },
        {
            name: 'entryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'closeDate',
            display: 'Close Date',
            type: InputTypes.dateTime
        },
        {
            name: 'playerId',
            display: 'Player Id'
        },
        {
            name: 'username',
            display: 'Username',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                var converted = toPascalCase(row);
                return gotoProfile(converted.PlayerId, converted.Username);
            }
        },
        {
            name: 'type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'ticketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            formatter: (val, row) => {
                var type = SportsTicketTypes[row.ticketType];
                if (row.ticketType == 2) {
                    type += ' ' + row.System;
                }
                return type;
            }
        },
        {
            name: 'status',
            display: 'Status',
            formatter: (val, row, isFooter, isExporting) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(toPascalCase(row), isExporting);
            }
        },
        {
            name: 'bet',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val.toString();
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'odds',
            display: 'Odds',
            type: InputTypes.number
        },
        {
            name: 'possibleWin',
            display: 'Possible Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val.toString();
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter || isExporting) {
                    // this is an object
                    if (val && typeof val === 'object') {
                        return Object.keys(val).map(key => {
                            return isExporting ? `${val[key]} ${key}\n` : <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        return val?.toString();
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        }
    ]
};


const searchSettledTickets = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id'
        },
        {
            name: 'EntryDate',
            display: 'Settlement Date',
            type: InputTypes.dateTime,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'Username',
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        }
    ]
};


const searchOpenTickets = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id'
        },
        {
            name: 'Username',
        },
        {
            name: 'PlayerId',
            display: 'Player Id'
        }
    ]
};


const affilliatePlayerSummary = {
    fields: [
        {
            name: 'Id',
            max: 5,
            type: InputTypes.number,
            identity: true,
        },
        {
            name: 'Created',
            display: 'Register Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Username',
            type: InputTypes.text,
        },
        {
            name: 'Country',
            type: InputTypes.text
        },
        {
            name: 'City',
            type: InputTypes.text,
            max: 32
        },
        {
            name: 'Tag',
            display: 'Tags',
            type: InputTypes.text
        },
        {
            name: 'PlayerGroup',
            display: 'Player Group'
        },
        {
            name: 'Balance',
            type: InputTypes.number,
            max: 20,
            formatter: (val, data) => {
                return currency(val, data.Currency, 2);
            }
        },
        {
            name: 'Enabled',
            type: InputTypes.bool,
            value: true
        }
    ]
};

// COMPONENT
class AffiliatePlayersPage extends Page {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);

    }

    openProfile(player) {
        var path = window.location.pathname;
        if (path.indexOf('profile') == -1) {
            getHistory().push(path + '/profile/' + player.Id);
        }
        this.selectedItem = player;
        this.setState({ selectedPlayer: player });
    }

    closeProfile() {
        var path = window.location.pathname;
        path = path.split('/');
        path.splice(path.findIndex(x => x == 'profile'), 2);
        // console.log(this.state.selectedPlayer.Username);
        // this.playersBreadCrumbs.push(this.state.selectedPlayer.Username);
        getHistory().push(path.join('/'));
        this.setState({ selectedPlayer: null, riskManagement: null });
    }


    searchAffiliatePlayers(data) {
        application().resolve(null, Endpoints.ListAffiliatePlayers + '/0', data, 'search players', 'unable to search players').then((result) => {
            this.setState({ players: result, updateDate: Date.now() });
        });
    }

    searchAffiliateTransactions(data) {
        application().resolve(null, Endpoints.SearchAffiliateTransactions + '/0', data, 'search transactions', 'unable to search transactions').then((result) => {
            this.setState({ transactions: result, updateDate: Date.now() });
        });
    }

    searchAffiliateTransactionsSummary(data) {
        application().resolve(null, Endpoints.SearchAffiliateTransactionsSummary + '/0', data, 'search transactions summaries', 'unable to search transactions summaries').then((result) => {
            this.setState({ transactions: result, updateDate: Date.now() });
        });
    }

    searchAffiliateBonus(data) {
        application().resolve(null, Endpoints.SearchAffiliateBonus + '/0', data, 'search bonus', 'unable to search bonus').then((result) => {
            this.setState({ bonus: result, updateDate: Date.now() });
        });
    }

    searchAffiliatePlayerGameHistory(data) {
        application().resolve(null, Endpoints.SearchAffiliatePlayerGameHistory + '/0', data, 'searching', 'unable to search bonus').then((result) => {
            this.setState({ gameHistory: result, updateDate: Date.now() });
        });
    }

    searchSettledTickets(data) {
        application().resolve(null, AffiliateEndpoints.SearchSettledTickets + '/0', data, 'searching', 'unable to search bonus').then((result) => {
            this.setState({ searchSettledTickets: result, updateDate: Date.now() });
        });
    }

    searchOpenTickets(data) {
        application().resolve(null, AffiliateEndpoints.SearchOpenTickets + '/0', data, 'searching', 'unable to search bonus').then((result) => {
            this.setState({ searchOpenTickets: result, updateDate: Date.now() });
        });
    }
    onActionClicked(action, items) {
        var player = items[0];
        this.setState({ selectedPlayer: null, riskManagement: null });
        switch (action.type) {
            case ActionTypes.PlayerProfile:
                this.openProfile(player);
                break;
            default:
                console.log(action.type);
                break;
        }
    }

    isAffiliateUser() {
        return (window.user.UserGroupId == 2 || window.user.UserGroupId == 3) ? false : true;
    }

    render() {
        return (
            <CentrumApplication
                caption='Affiliates' icon='money'
                type={applicationActionType}
            >
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    {
                                        this.state.selectedPlayer != null ?
                                            <Card caption="Player Profiles">
                                                <BreadCrumbs root='Player Profiles' ref={(r) => this.playersBreadCrumbs = r} onClick={() => { this.playersTab.selectByKey('search'); this.setState({ selectedPlayer: null, riskManagement: null }); }} />
                                                <React.Fragment key='profile'>
                                                    {
                                                        this.state.selectedPlayer != null &&
                                                        <AffilatePlayerProfile
                                                            key={this.state.selectedPlayer.Id + this.state.updateDate}
                                                            application={this.applicationContext}
                                                            id={this.state.selectedPlayer.Id}
                                                            currency={this.state.currency}
                                                            onClose={() => { this.closeProfile(); }}
                                                        />
                                                    }
                                                </React.Fragment> :
                                            </Card> :
                                            <Card caption='Players'>
                                                <Search key={'search'}
                                                    model='searchAffiliatePlayer'
                                                    resultModel={!this.isAffiliateUser() ? 'affilliatePlayerSummary' : affilliatePlayerSummary}
                                                    exportToExcel={true}
                                                    // data={this.state.players}
                                                    actions={getAction(ActionTypes.SearchAffiliatePlayer).convertToItems()}
                                                    onActionClicked={this.onActionClicked.bind(this)}
                                                    // onSubmit={(data, qb) => { this.searchAffiliatePlayers(qb); }}
                                                    data={this.props.players}
                                                    onSubmit={(data, qb) => { return apiResult('players', Endpoints.ListAffiliatePlayers + '/0', qb, 'fetching report please wait', 'unable to fetch report'); }}
                                                />
                                            </Card>
                                    }
                                    <Card caption='Transactions Summary'>
                                        <Search key={'search'}
                                            model='searchAffiliateTransactionsSummary'
                                            resultModel='affiliateTransactionSummaryResult'
                                            data={this.state.transactions}
                                            onSubmit={(data, qb) => { this.searchAffiliateTransactionsSummary(qb); }}
                                        />
                                    </Card>
                                    {/* <Card caption='Transactions'>
                                        <Search key={'search'}
                                            model='searchAffiliateTransactions'
                                            resultModel='affiliateTransactionResult'
                                            data={this.state.transactions}
                                            onSubmit={(data, qb) => { this.searchAffiliateTransactions(qb); }}
                                        />
                                    </Card> */}
                                    <Card caption='Bonus Transactions'>
                                        <Search key={'search'}
                                            model='searchAffiliateBonusTransactions'
                                            resultModel='affiliateBonusTransactions'
                                            data={this.state.bonus}
                                            exportToExcel={false}
                                            onSubmit={(data, qb) => { this.searchAffiliateBonus(qb); }}
                                        />
                                    </Card>
                                    <Card caption='Game History'>
                                        <Search key={'search'}
                                            model='searchPlayerGameHistory'
                                            resultModel='resultPlayerGameHistory'
                                            data={this.state.gameHistory}
                                            onSubmit={(data, qb) => { this.searchAffiliatePlayerGameHistory(qb); }}
                                        />
                                    </Card>
                                    <Card caption='Settled Tickets'>
                                        <Search key={'search'}
                                            model={searchSettledTickets}
                                            resultModel={betSlipResults}
                                            data={this.state.searchSettledTickets}
                                            exportToExcel={false}
                                            onSubmit={(data, qb) => { this.searchSettledTickets(qb); }}
                                        />
                                    </Card>
                                    <Card caption='Open Tickets'>
                                        <Search key={'search'}
                                            model={searchOpenTickets}
                                            resultModel={betSlipResults}
                                            data={this.state.searchOpenTickets}
                                            exportToExcel={false}
                                            onSubmit={(data, qb) => { this.searchOpenTickets(qb); }}
                                        />
                                    </Card>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

AffiliatePlayersPage.defaultProps = {
    affiliates: []
};

AffiliatePlayersPage.propTypes = {
    affiliates: PropTypes.array
};


const mapStateToProps = state => {
    return {
        language: state.language.selected,
        players: state.data.players
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(AffiliatePlayersPage);
export { hoc as AffiliatePlayersPage };