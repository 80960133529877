import '../RiskLevels/_riskLevelConfiguration.scss';

import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { currency } from '../../../../v2/Lib/Common/currency';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { API, RiskLevelEndpoints } from '../../../../v2/Lib/Api/Api';
import { convertToLevels } from '../RiskLevels/RiskLevelsConfiguration';


const eventVolumesModel = {
    fields: [
        {
            name: 'id',
            title: 'Type',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Risk Level</span>
                    <span className='border-top'>Bet / Player</span>
                </div>;
            },
            renderRow: (row) => {
                const result = convertToLevels(row.id).map((x, index) => {
                    if (index < 2) return;
                    return <div key={index} className={x}>{x}</div>;
                });

                return <div className='risk-levels flex gap-5'>
                    {result}
                </div>;
            }
        },
        /*
        {
            name: 'liability',
            title: 'Liability Volume',
            renderRow: (row) => {
                return currency(row.liability, 'EUR', 2, false);
            }
        },
        {
            name: 'limit',
            title: 'Liability Limit',
            renderRow: (row, field, context) => {
                const converted = convertToLevels(row.id).join('');
                const level = context.levels[converted];
                if (level) {
                    return currency(level.liability, 'EUR', 2, false);
                }
            }
        },
        {
            name: 'volume',
            title: 'Volume',
            renderRow: (row, field, context) => {
                const converted = convertToLevels(row.id).join('');
                const level = context.levels[converted];
                if (!level.liability) return <span>no limit</span>
                var className = 'Low';
                if (level) {
                    const perc = parseFloat(((row.liability * 100) / level.liability).toFixed(2));
                    if (perc > 12.5) {
                        className = 'Low2';
                    }
                    if (perc > 25) {
                        className = 'Medium';
                    }
                    if (perc > 37.5) {
                        className = 'Medium2';
                    }
                    if (perc > 50) {
                        className = 'Medium2';
                    }
                    if (perc > 62.5) {
                        className = 'High';
                    }
                    if (perc > 75) {
                        className = 'High2';
                    }
                    if (perc > 87.5) {
                        className = 'High3';
                    }
                    if (perc > 95) {
                        className = 'High4';
                    }
                    return <div className='risk-volume-progress'>
                        <div className='bar-holder risk-levels'>
                            <div className={'bar ' + className} style={{ maxWidth: perc + '%' }}>&nbsp;</div>
                            <span>{`${perc}%`}</span>
                        </div>
                    </div>;
                }
            }
        },
        */
        {
            name: 'tickets',
            title: 'Betslips'
        }, 
        {
            name: 'stake',
            title: 'Stake Volume',
            renderRow: (row) => {
                return currency(row.stake, 'EUR', 2, false);
            }
        }
    ]
};

export const MonitorEventLevels = (props) => {
    const event = props.event;
    const riskLevels = props.riskLevels;
    const [eventVolumes, setEventVolumes] = useState(null);

    useEffect(() => {

        API.post(`${RiskLevelEndpoints.MonitorEventVolumes}/${window.user.UserPrivileges.PointOfView}/${event.id}`).then((result) => {
            setEventVolumes(result.result);
        });

    }, []);



    return <Table data={eventVolumes?.volumes ?? []} model={eventVolumesModel} context={{ levels: riskLevels }} />
};

MonitorEventLevels.propTypes = {
    event: PropTypes.object,
    volumes: PropTypes.object,
    riskLevels: PropTypes.object,
    onClose: PropTypes.func
};