import React from 'react';
import { application, Endpoints } from '../../Application/Application';
import { lang } from '../../Centrum/Localization/lang';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';

export const updateSelectionsOfTickets = (data, eventId, group, onComplete) => {
    var affectedSelections = data.filter(x => {
        if (x.cancel || x.win || x.lost) return true;
        return false;
    });
    if (affectedSelections.length == 0) {
        application().modal.open(<Dialog title='Information'>
            <span>{lang('No selection updates made')}</span>
        </Dialog>);
        return;
    }


    application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
        application().resolve(null, `${Endpoints.UpdateSelectionsOfEvent}/${eventId}/${group}`, affectedSelections, 'please wait', 'unable to flag tickets').then(() => {
            application().snackbar.open('Ticket status updated');
            if (onComplete) onComplete();
        });
    }}>
        <span className='padding'>{lang('Selection status of the event will be updated.')}</span>
    </ConfirmationModal>);
};


export const changeStatusOfAllOpenSelections = (status, eventId, group, onComplete) => {
    var statusName = '';

    switch (status) {
        case 3:
            statusName = 'lost';
            break;
        case 4:
            statusName = 'win';
            break;
        case 9:
            statusName = 'cancel';
            break;
        case 2:
            statusName = 'refund';
            break;
    }

    application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
        application().resolve(null, `${Endpoints.ChangeStatusOfAllOpenSelections}/${eventId}/${group}/${status}`, null, 'please wait', 'unable to update status').then(() => {
            application().snackbar.open('Ticket status updated');
            if (onComplete) onComplete();
        });
    }}>
        <span className='padding'>{lang('All status of the open selections will be changed to %1.', [statusName])}</span>
    </ConfirmationModal>);
};



