import './_confirmation.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../Controls/Buttons/Button';
import { application } from '../../../Application/Application';
import { Icon } from '../../../Controls/Icon/Icon';


export const CloseModalButton = (props) => {
    return <Button className='btn' type='reset' onClick={() => {
        if (props.action) props.action();
        application().modal.close();
    }} title={props.title}><Icon icon={'times'} size='lg' /></Button>;
};

CloseModalButton.propTypes = {
    action: PropTypes.func,
    title: PropTypes.string
};

const AcceptModalButton = (props) => {
    return <Button className='btn alignRight' onClick={() => {
        if (props.enableClose) {
            application().modal.close();
        }
        if (props.action) props.action();
    }} title={props.title}><Icon icon={'check'} size='lg' /></Button>;
};

AcceptModalButton.defaultProps = {
    title: 'Ok',
    enableClose: true
};

AcceptModalButton.propTypes = {
    title: PropTypes.text,
    enableClose: PropTypes.bool,
    action: PropTypes.func,

};

export const ConfirmationModal = (props) => {


    var footer = props.footer;
    if (footer == null) footer = <div className='centrum-flex'><AcceptModalButton
        title={props.buttons.ok.title}
        action={() => props.onConfirm()}
        enableClose={props.buttons.ok.enableClose} /><CloseModalButton action={() => props.onCancel()} title={props.buttons.cancel.title} /></div>;
    return <React.Fragment>
        <div className={'modal-window confirmation ' + props.className}>
            <div className='title'><h1>{props.title}</h1><Icon icon={'times'} size='lg' onClick={() => { application().modal.close(); props.onCancel(); }} /></div>
            <div className='body'>{props.children}</div>
            <div className='footer'>{footer}</div>
        </div>
    </React.Fragment>;
};

ConfirmationModal.defaultProps = {
    className: '',
    children: null,
    footer: null,
    buttons: {
        ok: {
            title: 'Ok',
            enableClose: true
        },
        cancel: {
            title: 'Cancel'
        }
    },
    onConfirm: () => { },
    onCancel: () => { }
};

ConfirmationModal.propTypes = {
    className: PropTypes.string,

    buttons: PropTypes.object,

    title: PropTypes.string,
    children: PropTypes.node,
    footer: PropTypes.node,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};
