import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';






export const PaymentConfiguration = (props) => {
    const [retail, setRetail] = useState(props.configuration);

  
    useEffect(() => {
        props.onUpdate(retail);
    }, [retail]);




    return <div>
        <Form data={retail.configuration.payment} key={retail}>
            <div className='row'>
                <FormInput title='Enabled' value={retail.configuration?.payment.enabled} type={InputTypes.bool} name='enabled' onChange={(form, field, value) => {
                    var copy = Object.assign({}, retail);
                    copy.configuration.payment.enabled = value;
                    setRetail(copy);
                    props.onUpdate(copy);
                }} />
            </div>
        </Form>
    </div>;
};

PaymentConfiguration.propTypes = {
    configuration: PropTypes.object,
    onUpdate: PropTypes.func
};