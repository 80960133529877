import React from 'react';
import { NavLink } from 'react-router-dom';
import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';
import { currency } from '../Localization/lang';
import { ProviderGameTypes, SportsBetTypes, SportsTicketTypes, SportsTicketStatus, TestAccountFilter } from './ModelEnums';
import { gotoProfile } from './Transactions';

const openTicketsOfEvent = (val, row) => {
    var pathname = window.location.pathname;
    return <NavLink to={pathname + window.location.search + '&eventName=' + row.eventName}> {row.eventName}</NavLink >;
};


export const RiskManagementModels = {
    riskGroupSummary: {
        fields: [
            {
                name: 'pendingDeposits',
                display: 'Pending Deposits',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(Math.abs(val), 2, row.currency, false);
                }
            },
            {
                name: 'pendingWithdraws',
                display: 'Pending Withdraws',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(Math.abs(val), 2, row.currency, false);
                }
            },
            {
                name: 'bets',
                display: 'Bets',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(Math.abs(val), 2, row.currency, false);
                }
            },
            {
                name: 'wins',
                display: 'Wins',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(Math.abs(val), 2, row.currency, false);

                }
            },
            {
                name: 'ggr',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    var result = (Math.abs(row.bets) - Math.abs(row.wins));
                    if (isExporting) return result;
                    return currency(result, 2, row.currency, false);
                }
            },
            {
                name: 'balance',
                display: 'Liability',
                type: InputTypes.text,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(Math.abs(val), 2, row.currency, false);
                }
            }
        ]
    },
    'configureRiskManagementGameTypeLimit': {
        fields: [
            {
                name: 'type',
                display: 'Type',
                type: InputTypes.text,
                readonly: true,
                formatter: (val) => {
                    return ProviderGameTypes[parseInt(val)];
                }
            },
            {
                name: 'typeId',
                hidden: true
            },
            {
                name: 'betLimit',
                display: 'Bet Limit',
                type: InputTypes.number
            },
            {
                name: 'enabled',
                display: 'Active',
                selectable: 'true',
                type: InputTypes.bool
            },
            {
                name: 'stop',
                display: 'Stop Bet',
                type: InputTypes.bool
            }
        ]
    },
    searchBetSlips: {
        fields: [
            {
                name: 'EntryDate',
                display: 'Ticket Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'ExternalTicketId',
                display: 'Ticket Id',
                type: InputTypes.number
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'Status',
                display: 'Status',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketStatus
            },
            {
                name: 'EventName',
                display: 'Event Name'
            },
            {
                name: 'GroupId',
                display: 'Player Groups',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Bet',
                display: 'Stake',
                type: InputTypes.number
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    searchOpenBetSlips: {
        fields: [
            {
                name: 'OperatorId',
                display: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'ExternalTicketId',
                display: 'Ticket Id',
                type: InputTypes.number
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'PromotionId',
                display: 'Bet Bonus',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPromotions',
                    bindingKey: 'Id',
                    bindingText: ['OperatorId', 'Name']
                }
            },
            {
                name: 'TicketBonusId',
                display: 'Ticket Bonus',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPromotions',
                    bindingKey: 'Id',
                    bindingText: ['OperatorId', 'Name']
                }
            },
            {
                name: 'EventName',
                display: 'Event Name'
            },
            {
                name: 'EventId',
                display: 'Event Id'
            },
            {
                name: 'GroupId',
                display: 'Player Groups',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Bet',
                display: 'Stake',
                type: InputTypes.number
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    dailyTickets: {
        fields: [
            {
                name: 'EntryDate',
                display: 'Ticket Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['Today']
                }
            },
            {
                name: 'OperatorId',
                display: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EventId',
                display: 'Event Id'
            },
            {
                name: 'ExternalTicketId',
                display: 'Ticket Id',
                type: InputTypes.number
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'Status',
                display: 'Status',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketStatus
            },
            {
                name: 'PromotionId',
                display: 'Bet Bonus',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPromotions',
                    bindingKey: 'Id',
                    bindingText: ['OperatorId', 'Name']
                }
            },
            {
                name: 'TicketBonusId',
                display: 'Ticket Bonus',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPromotions',
                    bindingKey: 'Id',
                    bindingText: ['OperatorId', 'Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'GroupId',
                display: 'Player Groups',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'Username',
                display: 'Username',
                type: InputTypes.text
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    resultedTickets: {
        fields: [
            {
                name: 'Updated',
                display: 'Resolve Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['Today']
                }
            },
            {
                name: 'ExternalTicketId',
                display: 'Ticket Id',
                type: InputTypes.number
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'PromotionId',
                display: 'Bet Bonus',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPromotions',
                    bindingKey: 'Id',
                    bindingText: ['OperatorId', 'Name']
                }
            },
            {
                name: 'TicketBonusId',
                display: 'Ticket Bonus',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPromotions',
                    bindingKey: 'Id',
                    bindingText: ['OperatorId', 'Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'GroupId',
                display: 'Player Groups',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'Username',
                display: 'Username',
                type: InputTypes.text
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    openTickets: {
        fields: [
            {
                name: 'ExternalTicketId',
                display: 'Ticket Id',
                type: InputTypes.number
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'GroupId',
                display: 'Player Groups',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    searchLastRemainingBetSlips: {
        fields: [
            {
                name: 'OperatorId',
                display: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'ExternalTicketId',
                display: 'Ticket Id',
                type: InputTypes.number
            },
            {
                name: 'PromotionId',
                display: 'Bet Bonus',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPromotions',
                    bindingKey: 'Id',
                    bindingText: ['OperatorId', 'Name']
                }
            },
            {
                name: 'TicketBonusId',
                display: 'Ticket Bonus',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPromotions',
                    bindingKey: 'Id',
                    bindingText: ['OperatorId', 'Name']
                }
            },
            {
                name: 'EventId',
                display: 'Event Id'
            },
            {
                name: 'EventName',
                display: 'Event Name'
            },
            {
                name: 'GroupId',
                display: 'Player Groups',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Bet',
                display: 'Stake',
                type: InputTypes.number
            },
            {
                name: 'PossibleWin',
                display: 'Liability',
                type: InputTypes.number
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            }
        ]
    },
    lastRemainingResults: {
        fields: [
            {
                name: 'EntryDate',
                display: 'Ticket Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['Min']
                }
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'GroupId',
                display: 'Player Groups',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'Bonus',
                type: InputTypes.bool
            }
        ]
    },
    openTicketsResult: {
        fields: [
            {
                name: 'Id',
                display: 'Id',
                identity: true
            },
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'Group',
                display: 'Group'
            },
            {
                name: 'Username',
                display: 'Username',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return gotoProfile(row.PlayerId, row.Username);
                }
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.currency, false);

                }
            },
            {
                name: 'PossibleWin',
                display: 'Possible Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.currency, false);

                }
            },
            {
                name: 'Bonus',
                type: InputTypes.bool
            }
        ],
        onRenderRow: (rowObject) => {
            return rowObject;
        }
    },
    betMonitor: {
        fields: [
            {
                name: 'EntryDate',
                display: 'Ticket Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['Today']
                }
            },
            {
                name: 'Type',
                display: 'Betting Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsBetTypes
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketTypes
            },
            {
                name: 'Status',
                display: 'Status',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: SportsTicketStatus
            },
            {
                name: 'GroupId',
                display: 'Player Groups',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'MyPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                display: 'Player Id',
                type: InputTypes.number
            },
            {
                name: 'Username',
                display: 'Username',
                type: InputTypes.text
            },
            {
                name: 'EventName',
                display: 'Event',
            },
            {
                name: 'MarketName',
                display: 'Market',
            },
            {
                name: 'Live',
                display: 'Live',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    1: 'Yes'
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Bet',
                display: 'Stake',
                type: InputTypes.number,
                queryBuilderOptions: {
                    Between: true,
                    Min: 0,
                    Max: Number.MAX_SAFE_INTEGER
                }
            }
        ],
        onRenderRow: (rowObject) => {
            // if liabilty = 0 then this means all bets are resulted
            var liability = rowObject.data.totalPending - rowObject.data.totalWin;
            if (liability < 0) liability = 0;
            var profit = (rowObject.data.totalLost - rowObject.data.totalWin);

            if (liability == 0 && profit > 0) {
                rowObject.className = 'player-win';
            }
            if (liability == 0 && profit < 0) {
                rowObject.className = 'player-lost';
            }
            return rowObject;
        }
    },
    betMonitorResults: {
        fields: [
            {
                name: 'eventName',
                display: 'Event',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return openTicketsOfEvent(val, row);
                }
            },
            {
                name: 'marketName',
                display: 'Market'
            },
            {
                name: 'totalBets',
                display: '#Tickets'
            },
            {
                name: 'totalStake',
                display: 'Stake',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.currency, false);

                }
            },
            {
                name: 'totalLost',
                display: 'Lost',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.currency, false);

                }
            },
            {
                name: 'totalWin',
                display: 'Win',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.currency, false);

                }
            },
            {
                name: 'totalPending',
                display: 'Pending',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.currency, false);

                }
            },
            {
                name: 'totalPossiblePayout',
                display: 'Liability',
                formatter: (val, row, isFooter, isExporting) => {
                    var liability = row.totalPending - row.totalWin;
                    if (isExporting) return liability;

                    return currency(liability < 0 ? 0 : liability, 2, row.currency, false);
                }
            },
            {
                name: 'avarageBet',
                display: 'Avarage Bet',
                formatter: (val, row, isFooter, isExporting) => {
                    var result = (row.totalStake / row.totalBets).toFixed(2);
                    if (isExporting) return result;

                    return currency(result, 2, row.currency);
                }
            },
            {
                name: 'profit',
                display: 'Profit',
                formatter: (val, row, isFooter, isExporting) => {
                    var result = (row.totalLost - row.totalWin).toFixed(2);
                    if (isExporting) return result;
                    return currency(result, 2, row.currency);
                }
            },
            {
                name: 'margin',
                display: 'Margin',
                formatter: (val, row, isFooter, isExporting) => {
                    var profit = (row.totalLost - row.totalWin);
                    var result = '%' + ((profit * 100) / row.totalStake);
                    if (isExporting) return result;
                    return result;
                }
            }
        ],
        onRenderRow: (rowObject) => {
            // if liabilty = 0 then this means all bets are resulted
            var liability = rowObject.data.totalPending - rowObject.data.totalWin;
            if (liability < 0) liability = 0;
            var profit = (rowObject.data.totalLost - rowObject.data.totalWin);

            if (liability == 0 && profit > 0) {
                rowObject.className = 'player-win';
            }
            if (liability == 0 && profit < 0) {
                rowObject.className = 'player-lost';
            }
            return rowObject;
        }
    },
    riskReportModel: {
        fields: [
            {
                name: 'eventId',
                display: 'Id'
            },
            {
                name: 'eventDate',
                display: 'Kickoff',
                type: InputTypes.dateTime
            },
            {
                name: 'selectionName',
                display: 'Selection'
            },
            {
                name: 'price',
                display: 'Rate'
            },
            {
                name: 'marketName',
                display: 'Market'
            },
            {
                name: 'eventName',
                display: 'Event'
            },
            {
                name: 'leagueName',
                display: 'League'
            },
            {
                name: 'sportName',
                display: 'Sport'
            },
            {
                name: 'openBets',
                display: 'Open'
            },
            {
                name: 'openStack',
                display: 'Open Stack',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'openWin',
                display: 'Liability',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'sllBets',
                display: 'S&LL Bets'
            },
            {
                name: 'sllStake',
                display: 'Stake',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'sllRStakes',
                display: 'R Stake',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'sllWin',
                display: 'Win',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'runOnBets',
                display: 'Run on Bets'
            },
            {
                name: 'runOnStakes',
                display: 'Stakes',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'runOnRStakes',
                display: 'Stakes',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'runOnWin',
                display: 'Win',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'stuckBets',
                display: 'Stuck Bets',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'stuckStack',
                display: 'Struck Bets',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'stuckWin',
                display: 'Liability',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return Math.abs(val);
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
        ]
    }
};