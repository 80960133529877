
import React from 'react';
import PropTypes from 'prop-types';


import { Page } from '../../Centrum/Page/Page';
import { application, Endpoints } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { Search } from '../../Centrum/Search/Search';
import { currency } from '../../Centrum/Localization/lang';

import { Button } from '../../Controls/Buttons/Button';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { FormInput } from '../../Controls/Form/FormInput';
import { Form } from '../../Controls/Form/Form';
import { lang } from '../../../v2/Lib/Common/language';



// COMPONENT
export class AffiliateSettlement extends Page {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);

    }

    componentDidMount() {
        this.loadCommissions();
    }

    loadAffiliates() {
        return application().resolve(null, Endpoints.ListAffiliates, {}, 'retreiving affiliates', 'unable to fetch affiliates').then((result) => {
            var affiliate = result.result.find(x => x.Id == this.state.affiliate.Id);
            this.setState({ affiliate, updateDate: Date.now() });
        });
    }

    loadCommissions() {
        return application().resolve(null, Endpoints.GetAffilliateDebt + '/' + this.state.affiliate.Id, {}, 'retreiving affiliates', 'unable to fetch affiliates').then((result) => {
            this.setState({ commissions: result.result });
        });
    }

    searchAffiliateSettlements(data) {
        application().resolve(null, Endpoints.SearchAffiliateSettlements, data, 'search settlements', 'unable to search settlements').then((result) => {
            this.setState({ settlements: result, updateDate: Date.now() });
        });
    }

    createSettlement() {
        var amount = this.state.commissions.toFixed(2);
        var data = { Amount: amount };
        application().modal.open(<ConfirmationModal
            onConfirm={() => {
                var data = this.settlementForm.submit();
                application().resolve(null, Endpoints.CreateAffiliateSettlement + '/' + this.state.affiliate.Id, data, 'creating settlement', 'unable to create settlement').then(() => {
                    this.loadAffiliates().then(() => {
                        this.loadCommissions().then(() => {
                            application().snackbar.open('Settlement created');
                        });
                    });
                });
            }}
            title='Please confirm'>
            <Form data={data} ref={(r) => this.settlementForm = r}>
                <div className='row'>
                    <FormInput type='number' title='Amount' name='Amount' value={amount} />
                </div>
                <div className='row'>
                    <FormInput type='textArea' title='Notes' name='Notes' />
                </div>
            </Form>
        </ConfirmationModal>);
    }

    render() {

        var net = (this.state.commissions + (this.state.affiliate.Balance < 0 ? this.state.affiliate.Balance : 0)).toFixed(2);
        return <React.Fragment key='settlements'>
            <div className='flex gap-10 fit padding'>
                <Card caption='Last Settlement' className='flex align-start'>
                    <div className='flex vertical gap-5 align-start padding fit-width'>
                        <div className='flex gap-5'>
                            <label>{lang('Date')}</label>
                            <span className='align-right'>{this.state.affiliate.LastSettlementDate ? this.state.affiliate.LastSettlementDate.toLocaleString('en-GB', { hours12: false }) : '-'}</span>
                        </div>
                        <div className='flex gap-5'>
                            <label>{lang('Amount')}</label>
                            <span className='align-right'>{this.state.affiliate.LastSettlementAmount ? currency(this.state.affiliate.LastSettlementAmount, this.state.group.Currency)  : '-'}</span>
                        </div>

                        <div className='flex padding'>
                            <Button title='Create Settlement' onClick={this.createSettlement.bind(this)} />
                        </div>
                    </div>
                </Card>
                <Card caption='Commission' className='flex align-start fit-width'>
                    <div className='flex align-start padding fit-width'>
                        <div className='flex gap-5 fit-width'>
                            <label>{lang('Commission')}</label>
                            <span className='align-right'>{currency(this.state.commissions, 2, this.state.group.Currency)}</span>
                        </div>
                    </div>
                </Card>
                <Card caption='Summary' className='flex align-start'>
                    <div className='flex vertical gap-5 align-start padding fit-width'>
                        <div className='flex gap-5 fit-width'>
                            <label>{lang('Commission')}</label>
                            <span className='align-right'>{currency(this.state.commissions, 2, this.state.group.Currency)}</span>
                        </div>
                        <div className='flex gap-5'>
                            <label>{lang('Balance')}</label>
                            <span className='align-right'>{currency(this.state.affiliate.Balance, 2, this.state.group.Currency)}</span>
                        </div>
                        <div className='flex gap-5'>
                            <label>{lang('Net')}</label>
                            <span className='align-right'>{currency(net, 2, this.state.group.Currency)}</span>
                        </div>
                    </div>
                </Card>
            </div>
            <Search key={'search'}
                model='searchAffiliateSettlements'
                resultModel='affiliateSettlements'
                data={this.state.settlements}
                onSubmit={(data, qb) => { this.searchAffiliateSettlements(qb); }}
            />
        </React.Fragment>;
    }
}

AffiliateSettlement.defaultProps = {
    affiliate: null,
    commissions: 0,
    group: null
};

AffiliateSettlement.propTypes = {
    affiliate: PropTypes.object,
    commissions: PropTypes.number,
    group: PropTypes.object
};
