import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { EditableTable } from '../../../Controls/Table/Editable/EditableTable';
import { Button } from '../../../Controls/Buttons/Button';
import { currency, lang } from '../../../Centrum/Localization/lang';
import { SportsBetTypes, SportsTicketTypes } from '../../../Centrum/_models/ModelEnums';
import { application, Endpoints } from '../../../Application/Application';
import { Panel } from '../../../Centrum/Card/Card';
import { confirmPendingOffer, rejectPendingOffer } from '../actions/ticketActions';



const pendingTicketsModel = {
    fields: [
        {
            name: 'id',
            display: 'Id'
        },
        {
            name: 'entryDate',
            display: 'Date',
            formatter: (row) => {
                return new Date(row.entryDate).toLocaleString('en-Gb', { hours12: false });
            }
        },
        {
            name: 'playerId',
            display: 'Player Id'
        },
        {
            name: 'username',
            display: 'Username'
        },
        {
            name: 'code',
            display: 'Code'
        },
        {
            name: 'groupName',
            display: 'Player Group'
        },
        {
            name: 'type',
            display: 'Type',
            formatter: (row) => {
                return SportsTicketTypes[row.type];
            }
        },
        {
            name: 'bonusWallet',
            display: 'Bonus Wallet',
            formatter: (row) => {
                return row.bonusWallet ? 'true' : 'false';
            }
        },
        {
            name: 'selections',
            display: 'Selections'
        },
        {
            name: 'odds',
            display: 'Odds',
            formatter: (row) => {
                if (!row.odds) return;
                return parseFloat(row.odds).toFixed(2);
            }
        },
        {
            name: 'amount',
            display: 'Amount',
            formatter: (row) => {
                return currency(row.amount, 2, row.currency);
            }
        },
        {
            name: 'possibleWin',
            display: 'Possible Win',
            formatter: (row) => {
                return currency(row.possibleWin, 2, row.currency);
            }
        },
        {
            name: 'ticketBonusAmount',
            display: 'Bonus',
            formatter: (row) => {
                if (!row.ticketBonus) return;
                return currency(row.ticketBonusLimit > 0 && row.ticketBonusAmount < row.ticketBonusLimit ? row.ticketBonusLimit : row.ticketBonusAmount, 2, row.currency);
            }
        }
    ]
};




const editBetModel = {
    fields: [
        {
            name: 'EventId',
            display: 'Event Id'
        },
        {
            name: 'Date',
            display: 'Date',
            formatter: (row) => {
                return new Date(row.Date).toLocaleString('en-Gb', { hours12: false });
            }
        },
        {
            name: 'Sport',
            display: 'Sport'
        },
        {
            name: 'Region',
            display: 'Region'
        },
        {
            name: 'League',
            display: 'League'
        },
        {
            name: 'EventName',
            display: 'Event'
        },
        {
            name: 'MarketName',
            display: 'Market'
        },
        {
            name: 'SelectionName',
            display: 'Selection'
        },
        {
            name: 'Price',
            display: 'Price',
            editable: true
        },
        {
            name: 'Price',
            display: 'Price',
            formatter: (row, context) => {
                if (!context.prices) return;
                var selection = context.prices.find(x => x.Id == row.Id);
                if (!selection) return;
                return selection.Price;
            }
        },
        {
            name: 'Status',
            display: 'Status'
        }
    ]
};




const summaryRow = {
    fields: [
        {
            name: 'odds',
            display: 'Odds'
        },
        {
            name: 'amount',
            display: 'Amount',
            editable: true
        },
        {
            name: 'ticketBonusPercentage',
            display: 'Bonus Percentage',
            editable: true
        }
    ]
};




export const PendingTickets = (props) => {
    const [editingRow, selectForEdit] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [editBetKey, setEditBetKey] = useState(Date.now());
    const [summaryKey, setSummaryKey] = useState(Date.now());
    const [pendingTicketsKey, setPendingTicketsKey] = useState(Date.now());
    const [prices, setPrices] = useState(null);

    var interval = null;
    useEffect(() => {
        return () => {
            clearInterval(interval);
        };
    });
    useEffect(() => {
        if (!editingRow) return;
        application().resolve(null, `${Endpoints.GetPendingTicket}/${editingRow.id}`, null, 'please wait', 'unable to fetch pending ticket').then((result) => {
            var bets = JSON.parse(result.result.Bets);
            setRowData(bets);
        });
    }, [editingRow]);

    useEffect(() => {
        interval = setInterval(() => {
            if (!rowData) return;
            var p = [];
            rowData.map(x => {
                p.push(x.Id);
            });
            application().resolve(null, `${Endpoints.SportSelectionPrices}`, p).then((result) => {
                setPrices(result.result);
            });

        }, 1000);

    }, [rowData]);


    const context = {
        prices: prices
    };

    const remove = (row) => {
        delete props.volumes.PendingTickets[row.id];
        selectForEdit(null);
        setPendingTicketsKey(Date.now());
        props.onRemove();
    };



    if (editingRow && rowData) {
        var odds = 1;
        rowData.map(x => {
            if (x.Price) {
                odds *= x.Price;
            }
        });

        editingRow.odds = odds ? odds.toFixed(2) : 0;
        editingRow.possibleWin = (odds * editingRow.amount).toFixed(2);
        editingRow.ticketBonusAmount = (editingRow.possibleWin * (editingRow.ticketBonusPercentage / 100)).toFixed(2);

        return <div>
            <EditableTable model={editBetModel} data={rowData} key={editBetKey}
                context={context}
                onChange={() => {
                    editingRow.bets = JSON.stringify(rowData);
                    setSummaryKey(Date.now());
                }}
                buttons={(row) => {
                    return <div className='centrum-flex'>
                        <Button title='Delete' onClick={() => {
                            row.Price = 0;
                            setEditBetKey(Date.now());
                        }} />
                    </div>;
                }} />
            <Panel title='Summary' key={editBetKey + 1}>
                <div className='groups padding big' >
                    <div className='group'>
                        <label>{lang('Player')}</label>
                        <span>{editingRow.username}</span>
                        <label>{lang('Type')}</label>
                        <span>{SportsBetTypes[editingRow.type]}</span>
                        <label>{lang('Ticket')}</label>
                        <span>{SportsTicketTypes[editingRow.ticketType]}</span>
                        <label>{lang('Amount')}</label>
                        <span>{editingRow.amount}</span>
                        <label>{lang('PossibleWin')}</label>
                        <span>{editingRow.possibleWin}</span>
                        <label>{lang('Bonus Percentage')}</label>
                        <span>%{editingRow.ticketBonusPercentage}</span>
                        <label>{lang('Bonus')}</label>
                        <span>{editingRow.ticketBonusAmount}</span>
                    </div>
                </div>
            </Panel>
            <Panel>
                <EditableTable model={summaryRow}
                    data={[editingRow]} key={summaryKey}
                    onChange={() => {
                        setEditBetKey(Date.now());
                    }}
                    buttons={() => {
                    }}
                />
            </Panel>

            <Panel>
                <div className='padding'>
                    <Button title='Ask User' onClick={() => confirmPendingOffer(editingRow, null, true)} />
                    <Button title='Confirm' onClick={() => confirmPendingOffer(editingRow, () => remove(editingRow))} />
                    <Button title='Reject' onClick={() => rejectPendingOffer(editingRow, () => remove(editingRow))} />
                    <Button title='Close' onClick={() => selectForEdit(null)} />
                </div>
            </Panel>
        </div>;
    }
    return <div key={pendingTicketsKey}>
        <EditableTable model={pendingTicketsModel} data={Object.values(props.volumes.PendingTickets)}
            readonly={true}
            onRowClick={(row) => selectForEdit(row)}
            buttons={(row) => {
                return <div className='centrum-flex'>
                    <Button title='Confirm' onClick={() => {
                        confirmPendingOffer(row, () => remove(row));
                    }} />
                    <Button title='Reject' onClick={() => {
                        rejectPendingOffer(row, () => remove(row));
                    }} />
                    <Button title='Edit' onClick={() => {
                        selectForEdit(row);
                    }} />
                </div>;
            }} />
    </div>;
};

PendingTickets.propTypes = {
    defaultCurrency: PropTypes.string,
    volumes: PropTypes.object,

    onClose: PropTypes.func,
    onRemove: PropTypes.func
};