
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';



import { Tab } from '../../../Centrum/Tab/Tab';
import { Card } from '../../../Centrum/Card/Card';
import { Search } from '../../../Centrum/Search/Search';
import { apiResult, Endpoints } from '../../../Application/Application';
import { getHistory } from '../../../routers/AppRouter';
import { Volumes } from '../Volumes/Volumes';
import { betSlipResults } from '../models';




// COMPONENT
class BetMonitor extends React.Component {
    constructor(props) {
        super(props);

        let state = Object.assign({}, props);
        this.state = state;
    }

    componentDidMount() {
        this.prepare();
    }

    componentDidUpdate() {
        this.prepare();
    }

    prepare() {
        var search = window.location.search;
        var url = new URLSearchParams(search);
        var eventName = url.get('eventName');
        if (eventName) {
            if (!this.state.eventName) {
                this.setState({ searchBets: true, eventName: eventName, updateTime: Date.now() }, () => {
                    this.betsTab.select(1);
                    this.searchTickets.submit();
                });
            }
        }
    }

    onBetSearchModelSet(model) {
        var url = new URLSearchParams(window.location.search);
        var qb = JSON.parse(url.get('qb'));
        model = Object.assign({}, model);
        model.fields.find(x => x.name == 'EventName').value = this.state.eventName;

        if (!qb) return model;
        qb.fields.map(x => {
            var field = model.fields.find(y => y.name == x.name);
            if (x.between) {
                if (field.queryBuilderOptions) {
                    delete field.queryBuilderOptions.data;
                } else {
                    field.queryBuilderOptions = {
                        Between: true
                    };
                }
                field.queryBuilderOptions.Min = x.min;
                field.queryBuilderOptions.Max = x.max;
            } else {
                field.value = x.value;
            }
        });

        return model;
    }


    render() {
        var betMonitorButtons = [
            {
                title: 'Volumes'
            },
            {
                title: 'Summary'
            },
            {
                title: 'Tickets'
            }
        ];


        return <Tab buttons={betMonitorButtons} selected={this.state.eventName ? 1 : 0} key={this.state.updateTime} ref={(r) => this.betsTab = r}
            onTabSelected={(index) => {
                if (index == 0) {
                    var pathname = window.location.pathname;
                    var url = new URLSearchParams(window.location.search);
                    url.delete('eventName');
                    getHistory().push(pathname + '?&' + url.toString());
                    this.setState({ eventName: null });
                }
            }}>
            <Card>
                <Volumes />
            </Card>
            <Card>
                <Search
                    model='betMonitor'
                    resultModel='betMonitorResults'
                    changeBrowserHistory={true}
                    data={this.props.betMonitorResults}
                    onColumnClicked={(row, field) => {
                        if (field === 'eventName') {
                            this.setState({ searchBets: true, evetName: row.eventName, updateTime: Date.now() }, () => {
                            });
                        }
                    }}
                    onSubmit={(data, qb) => {
                        return apiResult('betMonitorResults', Endpoints.BetMonitor, qb, 'loading bet monitor', 'unable to load monitor');
                    }}
                />
            </Card>
            <Card key={this.state.updateTime}>
                <Search
                    ref={(r) => this.searchTickets = r}
                    model='searchBetSlips'
                    resultModel={betSlipResults}
                    onModelSet={this.onBetSearchModelSet.bind(this)}
                    data={this.props.betSlips}
                    onSubmit={(data, qb) => { apiResult('betSlips', Endpoints.SearchSportTickets, qb, 'loading bet monitor', 'unable to load monitor'); }}
                />
            </Card>
        </Tab>;
    }
}

BetMonitor.defaultProps = {
};

BetMonitor.propTypes = {
    betMonitorResults: PropTypes.object,
    betSlips: PropTypes.object
};


const mapStateToProps = state => {
    return {
        betMonitorResults: state.data.betMonitorResults,
        betSlips: state.data.betSlips
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(BetMonitor);
export { hoc as BetMonitor };