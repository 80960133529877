import './_margins.scss';
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Tab } from '../../../Centrum/Tab/Tab';
import { API, SportEndpoints, SportMarginEndPoints } from '../../../../v2/Lib/Api/Api';
import { useSelector } from 'react-redux';
import { Card } from '../../../../v2/Components/Card/Card';
import { Icon } from '../../../../v2/Components/Icon/Icon';
import { lang } from '../../../../v2/Lib/Common/language';
import { SportMargins } from './SportMargins';
import { RegionMargins } from './RegionMargins';
import { LeagueMargins } from './LeagueMargins';
import { EventMargins } from './EventMargins';
import { EventMarketMargins } from './EventMarketMargins';
import { SportMarketMargins } from './SportMarketMargins';
import { application } from '../../../Application/Application';
import { ErrorModal } from '../../../Modal/Modals/Error/ErrorModal';


export const emptyMargin = () => {
    return {
        preMargin: 0,
        preMin: 0,
        preMax: 0,
        liveMargin: 0,
        liveMin: 0,
        liveMax: 0
    };
};


export const model = {
    fields: [
        {
            name: 'id',
            title: 'Id',
            renderRow: (row, field, context) => {
                return context.renderId(row);
            }
        },
        {
            name: 'name',
            title: 'Name',
            renderRow: (row, field, context) => {
                return context.renderName(row);
            }
        },
        {
            name: 'preMargin',
            title: 'Margin',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Prematch</span>
                    <div className='border-top flex'>
                        <span className='center'>% Margin</span>
                    </div>
                </div>;
            },
            renderRow: (row, field, context) => {
                return <input type='number' key={`${row.sport}_preMarign`} defaultValue={row.preMargin} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'preMin',
            title: 'Min',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Prematch</span>
                    <div className='border-top flex'>
                        <span className='center'>Min Price</span>
                    </div>
                </div>;
            },
            renderRow: (row, field, context) => {
                return <input type='number' key={`${row.sport}_preMin`} defaultValue={row.preMin} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'preMax',
            title: 'Max',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Prematch</span>
                    <div className='border-top flex'>
                        <span className='center'>Max Price</span>
                    </div>
                </div>;
            },
            renderRow: (row, field, context) => {
                return <input type='number' key={`${row.sport}_preMax`} defaultValue={row.preMax} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'liveMargin',
            title: 'Margin',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Live</span>
                    <div className='border-top flex'>
                        <span className='center'>% Margin</span>
                    </div>
                </div>;
            },
            renderRow: (row, field, context) => {
                return <input type='number' key={`${row.sport}_liveMargin`} defaultValue={row.liveMargin} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'liveMin',
            title: 'Min',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Live</span>
                    <div className='border-top flex'>
                        <span className='center'>Min Price</span>
                    </div>
                </div>;
            },
            renderRow: (row, field, context) => {
                return <input type='number' key={`${row.sport}_liveMin`} defaultValue={row.liveMin} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'liveMax',
            title: 'Max',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Live</span>
                    <div className='border-top flex'>
                        <span className='center'>Max Price</span>
                    </div>
                </div>;
            },
            renderRow: (row, field, context) => {
                return <input type='number' key={`${row.sport}_liveMax`} defaultValue={row.liveMax} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        }
    ]
};


export const SportsbookMargins = () => {
    const [saving, setSaving] = useState(false);

    const [sports, setSports] = useState(null);
    const [tab, selectTab] = useState(0);
    const [masterData, setMasterData] = useState(null);
    const [margins, setMargins] = useState(null);

    const user = useSelector((state) => state.user).loginInformation;
    const enabled = user.Configuration == undefined;

    const ref = React.createRef();
    useEffect(() => {

        API.post(SportMarginEndPoints.Get).then((r) => {

            var copy = r.result != null ? JSON.parse(r.result) : {
                categories: {

                },
                categoryMarkets: {

                },
                regions: {

                },
                leagues: {

                },
                events: {

                },
                markets: {

                }
            };


            if (!copy.categoryMarkets) {
                copy.categoryMarkets = {};
            }
            setMasterData(JSON.parse(JSON.stringify(copy)));
            setMargins(copy);
        });
    }, []);

    useEffect(() => {
        if (!margins) return;
        API.post(SportEndpoints.Categories).then((result) => {
            setSports(result.result.sort((a, b) => b.weight > a.weight ? 1 : -1));
        });

    }, [margins]);

    useEffect(() => {
        if (!sports) return;
        try {
            var appContent = ref.current.ref.current.parentNode;
            appContent.style.display = 'flex';
            appContent.style.flexDirection = 'column';
            appContent.style.overflow = 'hidden';
            appContent.style.height = '100%';

            const tab = ref.current.ref.current;
            tab.style.overflow = 'hidden';

            const content = tab.getElementsByClassName('tab-content')[0];
            content.style.display = 'flex';
            content.style.flexDirection = 'column';
            content.style.flex = '1';
            content.style.height = '100%';
            content.style.alignSelf = 'stretch';

        } catch (err) {
            //
            console.log(err);
        }
    }, [sports]);

    const save = (updatedRows) => {
        setSaving(true);
        API.post(SportMarginEndPoints.Save, margins).then(() => {
            setSaving(false);
            try {
                API.post(SportMarginEndPoints.Update, updatedRows).then(() => {
                    application().snackbar.open('published');
                });
            } catch (err) {
                application().modal.open(<ErrorModal title='Error publishing'>
                    <p>{lang('Error publishing margin updates, please retry')}</p>
                </ErrorModal>);
                //
            }
        });
    };

    let tabButtons = [
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'sportMarkets',
            title: 'Sport Markets'
        },
        {
            name: 'region',
            title: 'Region'
        },
        {
            name: 'league',
            title: 'League'
        },
        {
            name: 'event',
            title: 'Event'
        },
        {
            name: 'eventMarkets',
            title: 'Event Markets'
        }
    ];

    if (!enabled) {
        return <Card title='Margins'>
            <div className='flex padding gap-5'>
                <Icon icon='info-circle' /><p>{lang('Margin configuration is not enabled')}</p>
            </div>
        </Card>;
    }

    if (!sports) return <></>;
    if (!margins) return <></>;

    return <Tab className={`${saving ? 'disabled tab' : 'tab'}`} buttons={tabButtons} ref={ref} onTabSelected={(s) => {
        selectTab(s);
    }}>
        {tab == 0 && <SportMargins sports={sports} margins={margins} masterData={masterData} setMargins={(m) => setMargins(m)} save={(u, c) => save(u, c)} />}
        {tab == 1 && <SportMarketMargins sports={sports} margins={margins} masterData={masterData} setMargins={(m) => setMargins(m)} save={(u, c) => save(u, c)} />}
        {tab == 2 && <RegionMargins sports={sports} margins={margins} masterData={masterData} setMargins={(m) => setMargins(m)} save={(u, c) => save(u, c)} />}
        {tab == 3 && <LeagueMargins sports={sports} margins={margins} masterData={masterData} setMargins={(m) => setMargins(m)} save={(u, c) => save(u, c)} />}
        {tab == 4 && <EventMargins sports={sports} margins={margins} masterData={masterData} setMargins={(m) => setMargins(m)} save={(u, c) => save(u, c)} />}
        {tab == 5 && <EventMarketMargins sports={sports} margins={margins} masterData={masterData} setMargins={(m) => setMargins(m)} save={(u, c) => save(u, c)} />}
    </Tab>;

};