import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Search } from '../../../../Centrum/Search/Search';
import { apiResult, Endpoints } from '../../../../Application/Application';
import { InputTypes } from '../../../../Centrum/CentrumForm/Inputs/CentrumInput';



const searchSettlementModel = {
    fields: [
        {
            name: 'Type',
            display: 'Type',
            type: InputTypes.select,
            acceptEmptyValues: true
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Code',
            display: 'Code'
        }
    ]
};

const settlementModel = {
    fields: [
        {
            name: 'Type',
            display: 'Type',
            type: InputTypes.select,
            acceptEmptyValues: true
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Code',
            display: 'Code'
        }
    ]
};



const RetailSettlements = (props) => {
    return <div>
        <Search
            model={searchSettlementModel}
            resultModel={settlementModel}
            data={props.settlements}
            onSubmit={(data, qb) => {
                apiResult('retailSettlements', `${Endpoints.RetailSettlements}/${props.retail.id}`,
                    qb, 'fetching settlements', 'unable to fetch settlements');
            }}
        />
    </div>;
};

RetailSettlements.propTypes = {
    retail: PropTypes.object,
    settlements: PropTypes.objcet
};



const mapStateToProps = state => {
    return {
        settlements: state.retailSettlements
    };
};
const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(RetailSettlements);
export { hoc as RetailSettlements };