// IMPORT PACKAGE REFERENCES
import './_loginPage.scss';


import React, { useState } from 'react';
import { Form } from '../../Controls/Form/Form';
import { FormInput } from '../../Controls/Form/FormInput';
import { Button } from '../../Controls/Buttons/Button';
import { application, Endpoints } from '../../Application/Application';
import { Icon } from '../../Controls/Icon/Icon';
import { toPascalCase } from '../../Centrum/helpers';

export const LoginPage = () => {
    const [busy, setBusy] = useState(false);
    const handleSubmit = (data) => {
        setBusy(true);
        application().resolve(null, Endpoints.Login, data, 'authenticating, please wait...', 'unable to login').then((result) => {
            let loginInformation = {
                token: result.result.token,
                lastIp: result.result.lastIp,
                lastLogin: result.result.lastLogin
            };
            document.cookie = 'backend-portal-auth=' + loginInformation.token + ';expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/';
            var data = toPascalCase(result.result);
            data.UserPrivileges = toPascalCase(data.UserPrivileges);
            window.location.href='/';
            //dispatch({ type: 'USER_LOGIN', payload: data });

            //console.log(loginInformation);
        }).catch(() => {
            setBusy(false);
        });
    };

    return <div className={'login-page' + (busy ? ' busy' : '')}>
        <div className='login'>
            <div className='login-container'>
                <img className='brand' src={''} />
                <Form onSubmit={handleSubmit.bind(this)}>
                    <div className='row'>
                        <FormInput type='text' name='email' title='Username' placeholder='email'>
                            <Icon icon={'envelope'} />
                        </FormInput>
                    </div>
                    <div className='row'>
                        <FormInput type='password' name='password' title='Password' placeholder='password'>
                            <Icon icon={'lock'} />
                        </FormInput>
                    </div>
                    <Button type='submit' title='Login' />
                </Form>
            </div>
        </div>
    </div>;
};

