
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Panel } from '../../../Centrum/Card/Card';
import { LabelValue } from '../../Dashboards/components/LabelValue/LabelValue';
import { Button } from '../../../Controls/Buttons/Button';
import { marketBetStart, marketBetStop } from '../actions/eventActions';
import { lang } from '../../../Centrum/Localization/lang';
import { Switch } from '../../../Controls/Inputs/Switch/Switch';
import { displayBets } from './SelectionVolumes';

export const EventSelectionVolumes = (props) => {
    const [onlyLiveEvents, setShowOnlyLiveEvents] = useState(false);
    const prices = props.context.prices;
    var markets = Object.values(props.context.event.Markets);
    var data = {};
    markets.sort((a, b) => {
        if (!onlyLiveEvents) return b.Live - a.Live;
        const aa = prices[a.Id];
        if (!aa) return 1;
        const bb = prices[b.Id];
        if (!bb) return 1;
        return Object.values(aa)[0].TypeId - Object.values(bb)[0].TypeId;
    }).map(x => {
        var marketData = prices[x.Id];
        if (!marketData) return;

        if (!data.hasOwnProperty(x.Name)) {
            data[x.Name] = {
                PrematchId: 0,
                LiveId: 0,
                MarketName: x.Name,
                Stakes: {
                    Live: 0,
                    Prematch: 0
                },
                Bets: {
                    Live: 0,
                    Prematch: 0
                },
                Selections: {}
            };
        }

        if (x.Live) data[x.Name].LiveId = x.Id;
        if (!x.Live) data[x.Name].PrematchId = x.Id;

        const selections = marketData.Selections;
        Object.values(selections).map(s => {
            const selection = x.Selections[s.SelectionId];
            if (!data[x.Name].Selections.hasOwnProperty(s.Selection)) {
                data[x.Name].Selections[s.Selection] = {
                    SelectionId: s.SelectionId,
                    Selection: s.Selection,
                    Live: s.Live,
                };
            }

            if (selection) {
                var slips = selection.Slips;
                var stakes = selection.Stakes;
                if (x.Live) {
                    data[x.Name].Stakes.Live += stakes.Live;
                    data[x.Name].Bets.Live += slips.Live;
                    data[x.Name].Selections[s.Selection].Price = s.Price;
                } else {
                    data[x.Name].Stakes.Prematch += stakes.Prematch;
                    data[x.Name].Bets.Prematch += slips.Prematch;
                    data[x.Name].Selections[s.Selection].Price = s.Price;
                }
            }
        });
    });


    return <div className='centrum-flex vertical'>
        <Panel title=' ' className='padding centrum-flex fit' buttons={<div className='centrum-flex alignRight padding-right'>
            <div className='centrum-flex center'>
                <span className='medium no-wrap'>{lang('Only Live')}</span>
                <Switch checked={onlyLiveEvents} onChange={(v) => setShowOnlyLiveEvents(v)} />
            </div>
        </div>}>
            {
                Object.values(data).map(market => {

                    if (onlyLiveEvents && market.Bets.Live == 0) return;

                    const selections = market.Selections;

                    var prematchStakes = market.Stakes.Prematch;
                    var liveStakes = market.Stakes.Live;

                    var liveSlips = market.Bets.Live;
                    var prematchSlips = market.Bets.Prematch;

                    if (onlyLiveEvents) prematchStakes = 0;
                    if (onlyLiveEvents) prematchSlips = 0;

                    const marketId = market.LiveId ? market.LiveId : market.PrematchId;
                    const marketStop = props.context.volumes.MarketStops.hasOwnProperty(marketId);

                    return <div className='centrum-flex vertical margin-top' key={market.MarketName}>
                        <Panel title={market.MarketName} className='small' buttons={
                            <div className='marginRight alignRight centrum-flex stretch justify-center'>
                                <div className='group'>
                                    <label className='medium stretch'>{lang('Bets')}</label><span className='medium'>{(liveSlips + prematchSlips)}</span>
                                </div>
                                <div className='group'>
                                    <label className='medium stretch'>{lang('Stakes')}</label><span className='medium'>{(liveStakes + prematchStakes).toFixed(2)}</span>
                                </div>
                                <Button title={marketStop ? 'Start' : 'Stop'} onClick={() => {
                                    marketStop ? marketBetStart(props.context.event, market) : marketBetStop(props.context.event, market);
                                }} />
                            </div>}>
                            <div className='centrum-flex stretch padding col-3'>
                                {
                                    Object.values(selections).map(s => {
                                        const volumes = props.context.volumes.TicketVolumes.SelectionVolumes[s.SelectionId];
                                        const bets = volumes ? volumes.OpenBets : 0;
                                        const stakes = volumes ? volumes.OpenStakes : 0;
                                        const openStakes = volumes ? volumes.OpenWins : 0;
                                        return <Panel className='fit small' title={s.Selection} key={s.SelectionId} buttons={<span className='marginRight alignRight medium'>{s.Price}</span>}>
                                            <div className='centrum-flex stretch padding half'>
                                                <div className='fit'>
                                                    <LabelValue title='Bets'>{bets ? <a href='/api/risk/bets/event' style={{ textAlign: 'right' }} onClick={(e) => {
                                                        e.preventDefault();
                                                        displayBets(e.target.href, {
                                                            fields: [
                                                                { name: 'SelectionId', and: true, exact: true, value: s.SelectionId },
                                                                { name: 'Status', and: true, exact: true, value: 0 }
                                                            ]
                                                        });
                                                    }}>{bets}</a> : 0}</LabelValue>
                                                    <LabelValue title='Stakes'>{stakes.toFixed(2)}</LabelValue>
                                                </div>
                                                <div className='fit'>
                                                    <LabelValue>{(stakes - openStakes).toFixed(2)}</LabelValue>
                                                    <LabelValue>{openStakes.toFixed(2)}</LabelValue>
                                                </div>
                                            </div>
                                        </Panel>;
                                    })
                                }
                            </div>
                        </Panel>
                    </div>;
                })
            }
        </Panel>
    </div>;
};


EventSelectionVolumes.propTypes = {
    context: PropTypes.object
};
