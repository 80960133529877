import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EditableTable } from '../../../Controls/Table/Editable/EditableTable';
import { currency } from '../../../Centrum/Localization/lang';
import { Button } from '../../../Controls/Buttons/Button';
import { SportLeagueLimits } from '../Configurations/LeagueLimits/SportLeagueLimits';

const leagueVolumes = {
    fields: [
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Sport',
            display: 'Sport',
            formatter: (row, context) => {
                var volumes = context.volumes;
                var league = volumes.Leagues[row.Id];
                if (!league) {
                    //console.log(volumes.Leagues,row.Id,'not found');
                    return;
                }
                return league.sport;
            }
        },
        {
            name: 'Slips',
            display: 'Slips / Live',
            formatter: (row, context) => {
                var volumes = context.volumes;
                var league = volumes.Leagues[row.Id];
                if (!league) {
                    //console.log(volumes.Leagues,row.Id,'not found');
                    return;
                }

                return league.Slips.Prematch + '/' + league.Slips.Live;
            }
        },
        {
            name: 'Stake',
            display: 'Stake',
            formatter: (row, context) => {
                var volumes = context.volumes;
                var league = volumes.Leagues[row.Id];
                if (!league) {
                    //console.log(volumes.Leagues,row.Id,'not found');
                    return;
                }

                return currency(league.Volumes.Stake.Prematch, 2, context.defaultCurrency);
            }
        },
        {
            name: 'Stake',
            display: 'Stake Live',
            formatter: (row, context) => {
                var volumes = context.volumes;
                var league = volumes.Leagues[row.Id];
                if (!league) {
                    //console.log(volumes.Leagues,row.Id,'not found');
                    return;
                }

                return currency(league.Volumes.Stake.Live, 2, context.defaultCurrency);
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            formatter: (row, context) => {
                var volumes = context.volumes;
                var league = volumes.Leagues[row.Id];
                if (!league) {
                    //console.log(volumes.Leagues,row.Id,'not found');
                    return;
                }

                return currency(league.Volumes.Liability.Prematch, 2, context.defaultCurrency);
            }
        },
        {
            name: 'LiabilityLive',
            display: 'Liability Live',
            formatter: (row, context) => {
                var volumes = context.volumes;
                var league = volumes.Leagues[row.Id];
                if (!league) {
                    //console.log(volumes.Leagues,row.Id,'not found');
                    return;
                }

                return currency(league.Volumes.Liability.Live, 2, context.defaultCurrency);
            }
        }
    ]
};

export const LeagueVolumes = (props) => {
    const context = {
        volumes: props.volumes,
        defaultCurrency: props.defaultCurrency
    };
    const [leagueLimitEditing, editLeagueLimit] = useState(null);



    if (leagueLimitEditing) {
        return <SportLeagueLimits league={leagueLimitEditing} onClose={() => {
            editLeagueLimit(null);
        }} />;
    }

    return <div>
        <EditableTable model={leagueVolumes} data={Object.values(props.volumes.Leagues)} context={context} buttons={(row) => {
            return <Button title='Limits' onClick={() => {
                editLeagueLimit({
                    id: row.Id,
                    name: row.Name,
                    sport: row.Sport,
                    sportId: row.SportId,
                    region: row.Region,
                    regionId: row.RegionId
                });
            }} />;
        }} />
    </div>;
};

LeagueVolumes.propTypes = {
    defaultCurrency: PropTypes.string,
    volumes: PropTypes.object
};