import './_sportTicketDetails.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Controls/Icon/Icon';
import { currency, lang } from '../Centrum/Localization/lang';
import { SportsBetTypes, SportsTicketStatus } from '../Centrum/_models/ModelEnums';
import { Button } from '../Controls/Buttons/Button';
import { application, Endpoints } from '../Application/Application';
import { ConfirmationModal } from '../Modal/Modals/Confirmation/ConfirmationModal';
import { toCamelCase } from '../Centrum/helpers';
import { FormInput } from '../Controls/Form/FormInput';
import { Dialog } from '../Modal/Modals/Dialog/Dialog';
import { cancelTransaction } from '../pages/Players/actions';
import { openBetModal } from '../pages/RiskManagement/models';
import { Card } from '../../v2/Components/Card/Card';


const renderHistory = (history, tickets, manualSettlement) => {
    if (history.length == 1) return;
    return <Card title='History' className='no-padding border radius margin'>
        <table>
            <thead>
                <tr>
                    <th>{lang('Date')}</th>
                    <th>{lang('Transaction')}</th>
                    <th>{lang('Operation')}</th>
                    <th>{lang('Notes')}</th>
                    <th>{lang('Admin')}</th>
                    <th>{lang('Amount')}</th>
                    <th>{lang('Action')}</th>
                </tr>
            </thead>
            <tbody>
                {history.map((h, index) => {
                    const date = new Date(h.entryDate + 'Z').toLocaleString('en-GB', { hours12: false });
                    var cancelEnabled = index == history.length - 1;

                    if (h.transactionStatus == 2) {
                        cancelEnabled = false;
                    } else {
                        var next = history[index + 1];
                        if (next && next.transactionStatus == 2) cancelEnabled = true;
                    }

                    if (!h.transactionId) cancelEnabled = false;


                    const openModal = () => {
                        openBetModal(tickets[0].externalTicketId, tickets[0].system == true, manualSettlement == true);
                    };

                    return <tr key={index}>
                        <td><span className='medium'>{date}</span></td>
                        <td>{h.transactionId}</td>
                        <td><span className='medium'>{SportsTicketStatus[h.typeBefore]}</span><Icon icon='arrow-right' size='xs' className='marginLeft marginRight' /><span className='medium'>{SportsTicketStatus[h.type]}</span></td>
                        <td>{h.notes}</td>
                        <td>{h.operatorUserId > 0 && <span>{h.admin}</span>}</td>
                        <td>{h.amount}</td>
                        <td>
                            {
                                cancelEnabled && <Button title='Cancel' onClick={() => {
                                    cancelTransaction({ id: h.transactionId }, () => {
                                        openModal();
                                    }, () => {
                                        openModal();
                                    });
                                }} />}
                            {h.transactionStatus == 2 && lang('Canceled')}
                        </td>
                    </tr>;
                })}
            </tbody>
        </table>
    </Card>;
};

export const SportTicketDetails = (props) => {
    return props.data.map((x, index) => {
        var ticketType = '';
        var entryDate = new Date(x.entryDate + 'Z').toLocaleString('en-GB', { hours12: false });
        var update = x.updated == '0001-01-01T00:00:00' ? '' : new Date(x.updated + 'Z').toLocaleString('en-GB', { hours12: false });

        if (x.ticketType == 0) {
            ticketType = 'Singles';
        }
        if (x.ticketType == 1) {
            ticketType = 'Multi';

            if (x.selections == 3) {
                ticketType = 'Trebles';
            }

            if (x.selections == 4) {
                ticketType = '4-Fold';
            }

            if (x.selections == 5) {
                ticketType = '5-Fold';
            }

            if (x.selections == 6) {
                ticketType = '6-Fold';
            }
        }
        var bets = x.bets;
        var status = renderStatus(x.status, props.manualSettlement);

        var bettingType = SportsBetTypes[x.type];


        var statusClass = '';
        switch (x.status) {
            case 0:
                statusClass = 'open';
                break;
            case 1:
                statusClass = 'win';
                break;
            case 2:
                statusClass = 'lost';
                break;
            case 3:
                statusClass = 'cashout';
                break;
            case 4:
                statusClass = 'cancel';
                break;
            case 5:
                statusClass = 'cashback';
                break;
            case 9:
                statusClass = 'bet cancel';
                break;
        }

        const renderLastUpdate = props.history.length > 1;
        var lastUpdateAction = SportsTicketStatus[Object.assign([], props.history)[0]?.type];

        const manualSettlementOptions = () => {
            return <div className='flex border surface'>
                <Button title='Win' onClick={() => {
                    application().modal.open(<ConfirmationModal
                        onConfirm={() => {
                            x.Status = 1;
                            application().resolve(null, Endpoints.ManualTicketUpdate, x).then((result) => {
                                if (result.result) {
                                    application().modal.open(<Dialog title={'Ticket Update'}>
                                        {lang('Ticket & selections updated.')}
                                    </Dialog >);
                                }
                            });
                        }}
                        title='Confirm'>
                        <span>{lang('Ticket will be set as win and all selections will be updated')}</span>
                        <form>
                            <div className='row'>
                                <FormInput type='number' name='Change' title='Change win amount' value={x.possibleWin} onChange={(a, b, value) => {
                                    x.win = parseFloat(value);
                                }} />
                            </div>
                        </form>
                    </ConfirmationModal>);
                }} />
                <Button title='Lost' onClick={() => {
                    application().modal.open(<ConfirmationModal
                        onConfirm={() => {
                            x.Status = 1;
                            application().resolve(null, Endpoints.ManualTicketUpdate, x).then((result) => {
                                if (result.result) {
                                    application().modal.open(<Dialog title={'Ticket Update'}>
                                        {lang('Ticket & selections updated.')}
                                    </Dialog >);
                                }
                            });
                        }}
                        title='Confirm'>
                        <span>{lang('Ticket will be set as lost and all selections will be updated')}</span>
                    </ConfirmationModal>);
                }} />
            </div>;
        };

        return <Card key={index} className='no-padding'>
            <div className='system-betslips'>
                <div className='surface border flex align-items-center gap-10'>
                    <span className='index'>{index + 1}</span>
                    <div className='flex vertical start'>
                        <div className='flex gap-20'>
                            <div className='flex gap-5'>
                                <label>ID</label><span>{x.externalTicketId}</span>
                            </div>
                            <div className='flex gap-5'>
                                <label>{lang('Date')}</label><span>{entryDate}</span>
                            </div>
                            {renderLastUpdate && <div className='flex gap-2'>
                                <label>{lang(lastUpdateAction)}</label><span>{update}</span>
                            </div>}
                            <div className='flex gap-5'><label>{lang('Betting Type')}</label><span>{bettingType}</span></div>
                            <div className='flex gap-5'><label>{lang('Ticket Type')}</label><span>{ticketType}</span></div>
                            <div className='flex gap-5'><label>{lang('Selections')}</label><span>{x.selections}</span></div>
                            <div className='flex gap-5'>
                                <label>{lang('Unresolved')}</label><span>{x.unresolved < 0 ? 0 : x.unresolved}</span>
                            </div>
                        </div>
                        <div className='flex gap-20'>
                            <div className='flex gap-5'>
                                <label>{lang('Stake')}</label><span>{currency(x.bet, 2, x.currency)}</span>
                            </div>
                            <div className='flex gap-5'>
                                <label>{lang('Odds')}</label><span>{x.odds.toFixed(2)}</span>
                            </div>

                            <div className='flex gap-5'>
                                <label>{lang('Possible Win')}</label><span>{currency(x.possibleWin, 2, x.currency)}</span>
                            </div>
                            {
                                x.ticketBonus && <div className='flex gap-5'>
                                    <label>{lang('Bonus')}</label><span>%{x.ticketBonusPercentage}</span>
                                    <label>{lang('Bonus Amount')}</label><span>+ {currency((x.ticketBonusLimit > 0 && x.ticketBonusLimit < x.ticketBonusAmount) ? x.ticketBonusLimit : x.ticketBonusAmount, 2, x.currency)}</span>
                                </div>
                            }

                        </div>
                        {
                            props.manualSettlement && manualSettlementOptions()
                        }
                    </div>
                    <div className={'flex alignRight align-items-center bet-status auto-width ' + statusClass}>
                        {x.status == 0 &&
                            <React.Fragment>
                                {x.cashoutAmount}
                            </React.Fragment>
                        }
                        <span className='medium light-high padding-right'>{status}</span>
                    </div>
                </div>
                {props.displayHistory && renderHistory(props.history, props.data, props.manualSettlement)}
                <table className='bet-slips border radius margin'>
                    <thead>
                        <tr>
                            <th className='th-event'>{lang('Match Date')}</th>
                            <th className='th-event'>{lang('Type')}</th>
                            <th className='th-event'>{lang('Event')}</th>
                            <th className='th-event'>{lang('Event Id')}</th>
                            <th><div className='flex justify-content-center'>{lang('Goals')}</div></th>
                            <th><div className='flex justify-content-center'>{lang('Score')}</div></th>
                            <th><div className='flex justify-content-center'>{lang('Market')}</div></th>
                            <th><div className='flex justify-content-center'>{lang('Selection')}</div></th>
                            <th><div className='flex justify-content-center'>{lang('Price')}</div></th>
                            <th><div className='flex justify-content-center'>{lang('Status')}</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        {bets.map((bet, index) => {
                            const date = new Date(bet.Date.indexOf('Z') == -1 ? bet.Date + 'Z' : bet.Date).toLocaleString('en-Gb');
                            var rowClass = '';
                            if (bet.SelectionStatus == 4) rowClass = 'win';
                            if (bet.SelectionStatus == 2) rowClass = 'refund';
                            if (bet.SelectionStatus == 3) rowClass = 'lost';
                            if (bet.SelectionStatus == 8) rowClass = 'cashout';
                            var selectionName = bet.SelectionName;
                            if (window.user.features.feedType && bet.Specifiers !== '' && bet.Specifiers) {
                                if (selectionName.indexOf(bet.Specifiers) == -1) {
                                    selectionName = selectionName + ' ' +bet.Specifiers;
                                }
                            }
                            return <tr key={index} className={rowClass}>
                                <td><span>{date}</span></td>
                                <td><span>{bet.Live ? 'Live' : 'Prematch'}</span></td>
                                <td>
                                    <div className='flex vertical start'>
                                        <div className='flex gap-5'>
                                            <label>{bet.EventName}</label>
                                        </div>
                                        <span>{bet.Sport}/ {bet.Region} / {bet.League}</span>
                                    </div>
                                </td>
                                <td>
                                    {bet.EventExternalId}
                                </td>
                                {parseScores(bet, props.scores)}
                                <td><div className='flex justify-content-center'>{bet.MarketName}</div></td>
                                <td><div className='flex justify-content-center'>{selectionName}</div></td>
                                <td><div className='flex justify-content-center'>{bet.Price}</div></td>
                                <td><div className='flex'>{renderSelectionStatus(bet, props.manualSettlement, (bet, value) => {
                                    bet.SelectionStatus = value;
                                    bet.Status = value;
                                })}</div></td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            </div>
        </Card>;
    });
};

SportTicketDetails.defaultProps = {
    displayHistory: true
};

SportTicketDetails.propTypes = {
    data: PropTypes.array,
    scores: PropTypes.object,
    displayHistory: PropTypes.bool,
    manualSettlement: PropTypes.bool
};

const renderSelectionStatus = (bet, manualSettlement, onChange) => {
    var status = null;

    //NotApproved = -1,
    //NotResulted = 0,
    //Refund = 2,
    //Lost = 3,
    //Won = 4,
    //WinReturn = 5,
    //LooseReturn = 6,
    //Resolved = 7,    
    if (manualSettlement) {
        return <select defaultValue={bet.SelectionStatus}
            onChangeCapture={(e) => {
                if (!onChange) return;
                onChange(bet, parseInt(e.target.value));
            }}
            style={{ minWidth: '150px' }}>
            <option value={0}>{lang('Open')}</option>
            <option value={2}>{lang('Refund')}</option>
            <option value={3}>{lang('Lost')}</option>
            <option value={4}>{lang('Win')}</option>
            <option value={9}>{lang('Cancel')}</option>
        </select>;
    }

    switch (bet.SelectionStatus) {
        case 0:
            status = <React.Fragment><Icon icon='clock' className='margin-right' />{lang('Open')}</React.Fragment>;
            break;
        case 2:
            status = <React.Fragment><Icon icon='times-circle' className='margin-right' />{lang('Refund')}</React.Fragment>;
            break;
        case 3:
            status = <React.Fragment><Icon icon='times-circle' className='margin-right' />{lang('Lost')}</React.Fragment>;
            break;
        case 4:
            status = <React.Fragment><Icon icon='check-circle' className='margin-right' />{lang('Win')}</React.Fragment>;
            break;
        case 8:
            status = <React.Fragment><Icon icon='check-circle' className='margin-right' />{lang('Cashout')}</React.Fragment>;
            break;
        case 9:
            status = <React.Fragment><Icon icon='info-circle' className='margin-right' />{lang('Cancel')}</React.Fragment>;
            break;
    }
    return <div className='flex align-items-center bet-status'>{status}</div>;
};



const renderStatus = (status) => {
    switch (status) {
        case 0:
            status = <React.Fragment><Icon icon='clock' className='margin-right' />{lang('Open')}</React.Fragment>;
            break;
        case 1:
            status = <React.Fragment><Icon icon='check-circle' className='margin-right' />{lang('Win')}</React.Fragment>;
            break;
        case 2:
            status = <React.Fragment><Icon icon='times-circle' className='margin-right' />{lang('Lost')}</React.Fragment>;
            break;
        case 3:
            status = <React.Fragment><Icon icon='check-circle' className='margin-right' />{lang('Cashout')}</React.Fragment>;
            break;
        case 4:
            status = <React.Fragment><Icon icon='times-circle' className='margin-right' />{lang('Cancel')}</React.Fragment>;
            break;
        case 5:
            status = <React.Fragment><Icon icon='check-circle' className='margin-right' />{lang('Cashback')}</React.Fragment>;
            break;
        case 6:
            status = <React.Fragment><Icon icon='check-circle' className='margin-right' />{lang('Refund')}</React.Fragment>;
            break;
        case 9:
            status = <React.Fragment><Icon icon='check-circle' className='margin-right' />{lang('Bet Cancel')}</React.Fragment>;
            break;
        case 10:
            status = <React.Fragment><Icon icon='check-circle' className='margin-right' />{lang('Waiting for manual settlement')}</React.Fragment>;
            break;
    }
    return status;
};



const getLSportResult = (result, target) => {
    if (result) {
        return result.find(x => x.Position == target).Value;
    }

    return '';
};

const getLsportSportPeriod = (sportId, period) => {
    var periodTitle = '';
    switch (sportId) {
        case 6046: // football
        case 46957://bandy
        case 274792://rugby
        case 35709://handball
        case 687887://futsal
            switch (period.Type) {
                case 10:
                    periodTitle = <span>1st Half</span>;
                    break;
                case 20:
                    periodTitle = <span>2nd Half</span>;
                    break;
                case 50:
                    periodTitle = <div className='flex vertical'><span>Pen.</span></div>;
                    break;
            }
            break;
        case 131506: // american football
        case 389537: // ausie rules
        case 530129://field hocket
            switch (period.Type) {
                case 1:
                    periodTitle = <span>1st Qt</span>;
                    break;
                case 2:
                    periodTitle = <span>2nd Qt</span>;
                    break;
                case 3:
                    periodTitle = <span>3rd Qt</span>;
                    break;
                case 4:
                    periodTitle = <span>4th Qt</span>;
                    break;
            }
            break;
        case 48242: // baseketball
        case 35232://ice-hockey
            switch (period.Type) {
                case 1:
                    periodTitle = <span>1st P.</span>;
                    break;
                case 2:
                    periodTitle = <span>2nd P.</span>;
                    break;
                case 3:
                    periodTitle = <span>3rd P.</span>;
                    break;
                case 4:
                    periodTitle = <span>4th P.</span>;
                    break;
            }
            break;
        case 621569:// beach volley
        case 154830://voleyball
        case 54094://tennis
        case 265917://table tennis
            switch (period.Type) {
                case 1:
                    periodTitle = <span>1st Set</span>;
                    break;
                case 2:
                    periodTitle = <span>2nd Set</span>;
                    break;
                case 3:
                    periodTitle = <span>3rd Set</span>;
                    break;
                case 4:
                    periodTitle = <span>4th Set</span>;
                    break;
                case 5:
                    periodTitle = <span>5th Set</span>;
                    break;
                case 6:
                    periodTitle = <span>6th Set</span>;
                    break;
                case 7:
                    periodTitle = <span>7th Set</span>;
                    break;
                case 8:
                    periodTitle = <span>8th Set</span>;
                    break;
                case 9:
                    periodTitle = <span>9th Set</span>;
                    break;
            }
            break;
        case 154914://baseball
            switch (period.Type) {
                case 1:
                    periodTitle = <span>1st In.</span>;
                    break;
                case 2:
                    periodTitle = <span>2nd In</span>;
                    break;
                case 3:
                    periodTitle = <span>3rd In</span>;
                    break;
                case 4:
                    periodTitle = <span>4th In.</span>;
                    break;
                case 5:
                    periodTitle = <span>5th In.</span>;
                    break;
                case 6:
                    periodTitle = <span>6th In.</span>;
                    break;
                case 7:
                    periodTitle = <span>7th In.</span>;
                    break;
                case 8:
                    periodTitle = <span>8th In.</span>;
                    break;
                case 9:
                    periodTitle = <span>9th In.</span>;
                    break;

            }
            break;
        case 452674://cricket
            switch (period.Type) {
                case 10:
                    periodTitle = <span>1st In.</span>;
                    break;
                case 20:
                    periodTitle = <span>2nd In</span>;
                    break;
                case 70:
                    periodTitle = <span>Over</span>;
                    break;
                case 71:
                    periodTitle = <span>Delivery</span>;
                    break;
            }
            break;
        case 1149093: // badminton
            switch (period.Type) {
                case 1:
                    periodTitle = <span>1st Gm</span>;
                    break;
                case 2:
                    periodTitle = <span>2nd Gm</span>;
                    break;
                case 3:
                    periodTitle = <span>3rd Gm</span>;
                    break;
                case 4:
                    periodTitle = <span>4th Gm</span>;
                    break;
            }
            break;
        case 154919: // boxing
        case 154923://dart
            switch (period.Type) {
                case 65:
                    periodTitle = <span>Round</span>;
                    break;
                case 67:
                    periodTitle = <span>Leg</span>;
                    break;
            }
        case 687890://e-games
            switch (period.Type) {
                case 1:
                    periodTitle = <span>1st M</span>;
                    break;
                case 2:
                    periodTitle = <span>2nd M</span>;
                    break;
                case 3:
                    periodTitle = <span>3rd M</span>;
                    break;
                case 4:
                    periodTitle = <span>4th M</span>;
                    break;
                case 5:
                    periodTitle = <span>5th M</span>;
                    break;
            }

    }

    return periodTitle;
};

const renderLSportsPeriods = (sportId, score) => {
    var sportFilters = {
        6046: x => x.Type == 10 || x.Type == 20,
        154919: x => x.Type == 65,
        0: x => x.Type == 1 || x.Type == 2 || x.Type == 3 || x.Type == 4 || x.Type == 5 || x.Type == 6 || x.Type == 7 || x.Type == 8 || x.Type == 9 || x.Type == 70 || x.Type == 71
    };

    var filter = sportFilters[sportId] ?? sportFilters[0];

    return score.Periods?.filter(x => filter(x)).sort((a, b) => a.Type > b.Type ? 1 : -1).map((period, index) => {
        var periodTitle = getLsportSportPeriod(sportId, period);


        return <div className='flex vertical align-start period' key={index}>
            <label className='center light'>{periodTitle}</label>
            <div className='flex gap-5'>
                <div className='flex vertical gap-5'>
                    <span className='center'>{getLSportResult(period.Results, '1')}</span>
                </div>
                <div className='flex vertical gap-5'>
                    <span className='center'>{getLSportResult(period.Results, '2')}</span>
                </div>
            </div>
        </div>;
    });
};

const getScoreLS = (bet, score) => {
    var homeScore = getLSportResult(score.Scoreboard.Results, '1');
    var awayScore = getLSportResult(score.Scoreboard.Results, '2');

    if (!score) {
        return <React.Fragment>
            <td></td>
            <td></td>
        </React.Fragment>;
    }

    return <React.Fragment>
        <td>
            <div className='flex gap-5'>
                {renderLSportsPeriods(bet.SportId, score)}
            </div>
        </td>
        <td>
            <div className='flex vertical padding half'>
                <div className='flex center'>
                    <span className='light-med medium'>{homeScore}</span>
                    <span className='light-med small'> - </span>
                    <span className='light-med medium'>{awayScore}</span>
                </div>
            </div>
        </td>
    </React.Fragment>;
};


const getScore = (bet, score) => {

    if (score && score.Scoreboard) {
        return getScoreLS(bet, score);
    }
    var period1HomeScore = '';
    var period1AwayScore = '';

    var period2HomeScore = '';
    var period2AwayScore = '';

    var homeScore = '';
    var awayScore = '';
    if (score) {
        score = toCamelCase(score);
        if (score.properties) {
            score.properties = toCamelCase(score.properties);

            period1HomeScore = score.properties.periodScore1_HomeScore;
            period1AwayScore = score.properties.periodScore1_AwayScore;

            period2HomeScore = score.properties.periodScore2_HomeScore;
            period2AwayScore = score.properties.periodScore2_AwayScore;

            homeScore = score.properties.result1_HomeScore;
            awayScore = score.properties.result1_AwayScore;
        }
    }

    return <React.Fragment>
        <td>
            <div className='flex space-around'>
                <div className='flex vertical padding half'>
                    <span className='light-high medium center'>{lang('1st Half')}</span>
                    <div className='flex center'>
                        <span className='light-med medium'>{period1HomeScore ? period1HomeScore : ''}</span>
                        <span className='light-med small'> - </span>
                        <span className='light-med medium'>{period1AwayScore ? period1AwayScore : ''}</span>
                    </div>
                </div>

                <div className='flex vertical padding half'>
                    <span className='light-high medium center'>{lang('2nd Half')}</span>
                    <div className='flex center'>
                        <span className='light-med medium'>{period2HomeScore ? period2HomeScore : ''}</span>
                        <span className='light-med small'> - </span>
                        <span className='light-med medium'>{period2AwayScore ? period2AwayScore : ''}</span>
                    </div>
                </div>
            </div>
        </td>
        <td>
            <div className='flex vertical padding half'>
                <div className='flex center'>
                    <span className='light-med medium'>{homeScore}</span>
                    <span className='light-med small'> - </span>
                    <span className='light-med medium'>{awayScore}</span>
                </div>
            </div>
        </td>
    </React.Fragment>;
};


export const parseScores = (bet, scores) => {
    if (!scores) return;
    var score = scores[bet.EventExternalId];
    return getScore(bet, score);
};