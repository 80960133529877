// IMPORT PACKAGE REFERENCES
import './_gameThumbs.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../Centrum/Card/Card';
import { application, Endpoints } from '../../../Application/Application';
import { Games } from '../../../Casino/Games/Games';
import { GameFilters } from '../../../Casino/Games/GameFilters/GameFilters';
import { GameThumbsModal } from './Modal/GameThumbsModal';

export const parseConfiguration = (configs) => {
    configs = JSON.parse(configs);

    return Object.keys(configs).map(key => {
        var config = configs[key];
        return <div key={key} className='config-row'>
            <div>{config.name} {config.width}px / {config.height}px</div>
            <div>{config.description}</div>
        </div>;
    });
};

// COMPONENT
export class GameThumbs extends Component {
    constructor(props) {
        super(props);

        this.providers = {};
        this.state = Object.assign({ selected: null }, props);
    }

    componentDidMount() {
        this.loadGames();
    }

    loadGames() {
        application().resolve(null, Endpoints.GetCMSGames, {}, 'fetching games please wait', 'unable to fetch games').then((result) => {
            result.result.map(x => {
                this.providers[x.providerId] = x.providerName + ' ' + (!x.providerEnabled ? ' (disabled)' : '');
            });

            this.setState({ games: result.result, loaded: true });
        });
    }

    onGameImageSaved(gameId) {
        var games = this.state.games;
        var game = games.find(x => x.gameId == gameId);
        game.filename = gameId + '.jpg' + '?&rnd=' + (Date.now());
        this.setState({ games: games });
    }

    onGameSelected(game) {
        application().resolve(null, Endpoints.GetCMSGameData + game.gameId, {}, 'fetching game detail', 'unable to fetch game detail').then((result) => {
            application().modal.open(<GameThumbsModal configuration={this.props.configuration.configuration?.images?.games} game={game} gameData={result.result} onSave={this.onGameImageSaved.bind(this)} />);
        });
    }

    onProviderSelected(value) {
        this.setState(value);
    }

    onProviderEnabledDisabled(value) {
        this.setState(value);
    }

    onGameEnabledDisabled(value) {
        this.setState(value);
    }

    onLiveDealerOrRng(value) {
        this.setState(value);
    }

    onImageOrNoImage(value) {
        this.setState(value);
    }


    onFilterGameName(value) {
        if (value.filterGameName.length > 2) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.setState(value);
            }, 200);
        }
    }

    renderGames() {
        return <Card caption='Games'>
            <Games
                games={this.state.filtered}
                selectedProvider={this.state.selectedProvider}
                providerEnabledFilter={this.state.providerEnabledFilter}
                gameEnabledFilter={this.state.gameEnabledFilter}
                gameTypeFilter={this.state.gameTypeFilter}
                imageFilter={this.state.imageFilter}
                filter={this.state.filterGameName}
                onGameSelected={this.onGameSelected.bind(this)} ref={(r) => this.searchGames = r} />
        </Card>;
    }

    render() {
        if (!this.state.games) return <div />;
        if (!this.state.loaded) return <div />;
        return <React.Fragment>
            <GameFilters
                providers={this.providers}
                games={this.state.games}
                onFilterChanged={(games) => {
                    this.setState({ filtered: games });
                }} />
            {this.renderGames()}
        </React.Fragment>;
    }
}

GameThumbs.defaultProps = {
    configuration: {},
    games: null,
    filtered: null
};

GameThumbs.propTypes = {
    configuration: PropTypes.object,
    games: PropTypes.array
};

