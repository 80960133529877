import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Card, Panel } from '../../Centrum/Card/Card';
import { EditableTable } from '../../Controls/Table/Editable/EditableTable';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Button } from '../../Controls/Buttons/Button';
import { Switch } from '../../Controls/Inputs/Switch/Switch';
import { Tab } from '../../Centrum/Tab/Tab';
import { Search } from '../../Centrum/Search/Search';
import { betSlipResults } from '../RiskManagement/models';
import { apiResult, application, Endpoints } from '../../Application/Application';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';
import { lang } from '../../Centrum/Localization/lang';
import { SportsBetTypes, SportsTicketTypes } from '../../Centrum/_models/ModelEnums';
import { changeStatusOfAllOpenSelections, updateSelectionsOfTickets } from './actions';
import { ActionTypes, getAction } from '../../Centrum/Common/ActionManager';
import { FormInput } from '../../Controls/Form/FormInput';


const model = {
    fields: [
        {
            name: 'id',
            display: 'Id'
        },
        {
            name: 'market',
            display: 'Market'
        },
        {
            name: 'selection',
            display: 'Selection'
        },
        {
            name: 'status',
            display: 'Selection Status',
            formatter: (field, value) => {
                var res = 'open';
                if (value == 4) res = 'win';
                if (value == 2) res = 'refund';
                if (value == 3) res = 'lost';
                if (value == 8) res = 'cashout';
                return res;
            }
        },
        {
            name: 'totalSlips',
            display: 'Total Slips'
        },
        {
            name: 'status',
            display: 'Status',
            // eslint-disable-next-line react/display-name
            formatter: (row) => {

                return <FormInput type={InputTypes.select} values={{ 0: 'Please select', 1: 'Win', 2: 'Lost', 3: 'Refund' }} onChange={(form, f, v) => {
                    switch (v) {
                        case '1':
                            row.win = true;
                            row.lost = false;
                            row.cancel = false;
                            break;
                        case '2':
                            row.win = false;
                            row.lost = true;
                            row.cancel = false;
                            break;
                        case '3':
                            row.win = false;
                            row.lost = false;
                            row.cancel = true;
                            break;
                        default:
                            row.win = false;
                            row.lost = false;
                            row.cancel = false;
                            break;

                    }
                }} />;
            }
        }
    ]
};

const TicketMarkets = (props) => {
    const [selections, setSelections] = useState(null);
    const [results, setResults] = useState(null);
    const [refundSelections, setRefundSelections] = useState({});
    const [ticketStatuses, setTicketStatuses] = useState(null);
    const [data, setData] = useState([]);
    var searchFields = Object.assign([], props.filter);

    useEffect(() => {
        application().resolve(null, Endpoints.SportEventResults, [props.event.EventId]).then(eventResults => {
            try {
                var result = JSON.parse(eventResults.result[props.event.EventId]);
                setResults(result);
            } catch (err) {
                //
            }
        });

        loadSelections();
    }, []);

    const loadSelections = () => {
        application().resolve(null, Endpoints.GetTicketSelections, { fields: props.filter }, 'pleae wait', 'unable to fetch ticket selections').then((result) => {
            setSelections(result.result);
        });
    };


    const tabButtons = [
        {
            title: 'Selections'
        },
        {
            title: 'Tickets'
        }
    ];

    const previewSelectionUpdates = () => {
        var affectedSelections = data;

        if (affectedSelections.length > 0) {
            var eventId = props.filter.find(x => x.name == 'EventId').value;
            var group = props.filter.find(x => x.name == 'Group');
            application().resolve(null, `${Endpoints.GetAffectedTicketsFromSelectionUpdates}/${eventId}/${group ? group.value : 0}`, affectedSelections, 'calculating', 'Unable to fetch data.').then(result => {
                application().modal.open(<Dialog title='Preview'>
                    <div className='padding'>
                        <EditableTable data={result.result} model={{
                            fields: [
                                {
                                    name: 'ticketType', display: 'Ticket', formatter: (val) => {
                                        return SportsBetTypes[val.ticketType];
                                    }
                                },
                                {
                                    name: 'type', display: 'Type', formatter: (val) => {
                                        return SportsTicketTypes[val.type];
                                    }
                                },
                                { name: 'currency', display: 'Currency' },
                                { name: 'stake', display: 'Stake' },
                                { name: 'liability', display: 'Liability' },
                                { name: 'payout', display: 'Payout' },
                            ]
                        }} />
                    </div>
                </Dialog>);
            });
        } else {
            application().modal.open(<Dialog title='Information'>
                <span>{lang('No selection updates made')}</span>
            </Dialog>);
        }
    };

    const updateRefundSelections = (target, value) => {
        var r = Object.assign({}, refundSelections);
        r[target] = value;
        setRefundSelections(r);
    };


    useEffect(() => {
        if (!selections) return;
        Object.keys(selections).map(x => {
            var market = selections[x];
            return Object.values(market.selections).map(selection => {
                data.push({
                    id: selection.id,
                    market: market.name,
                    selection: selection.name,
                    totalSlips: selection.totalSlips
                });
            });
        });

        setData(data.sort((a, b) => a.market - b.market));
    }, [selections]);

    if (!selections) {
        return <div />;
    }

    if (Object.keys(selections).length == 0) {
        return <Card caption={props.event.Event} menu={<Button title='Close' onClick={() => props.onClose ? props.onClose() : null} />}>
            <span>{lang('No open tickets found')}</span>
        </Card>;
    }


    var result = '';
    if (results) {
        result = ' Match score:' + parseInt(results.homeScore) + ':' + parseInt(results.awayScore);
    }

    console.log(props.event);

    return <Card caption={`[${props.event.EventId}] ${props.event.Event} ${new Date(props.event.EventDate + 'Z').toLocaleString('en-gb', { hours12: false })} ${result}`} menu={<Button title='Close' onClick={() => props.onClose ? props.onClose() : null} />}>
        <div className='centrum-flex vertical stretch'>
            <div className='centrum-flex stretch padding'>
                <Card caption='Bulk Selection Settlement'>
                    <div className='padding'>
                        <label>{lang('Set all open selections of this event to')}</label>
                        <select className='marginLeft' onChangeCapture={(e) => {
                            var val = e.target.value != '' ? parseInt(e.target.value) : null;
                            setTicketStatuses(val);
                        }}>
                            <option value=''>Please select</option>
                            <option value='3'>Lost</option>
                            <option value='4'>Win</option>
                            <option value='2'>Refund</option>
                        </select>
                    </div>
                    <div className='padding'>
                        <Button title='Preview' className='hidden' />
                        <Button title='Save' className={ticketStatuses ? '' : 'disabled'} onClick={() => {
                            var eventId = props.filter.find(x => x.name == 'EventId').value;
                            var group = props.filter.find(x => x.name == 'Group');

                            changeStatusOfAllOpenSelections(ticketStatuses, eventId, group ? group : 0, () => {
                                loadSelections();
                            });
                        }} />
                    </div>
                </Card>
                <Card caption='Bulk Ticket Operations'>
                    <div className='padding'>
                        <label>{lang('Refund tickets')}</label>
                        <div className='form-group centrum-flex group padding'>
                            <Switch title='All' onChange={(value) => updateRefundSelections('all', value)} />
                        </div>
                        <div className='form-group centrum-flex group padding'>
                            <Switch title='Prematch' onChange={(value) => updateRefundSelections('pre', value)} />
                        </div>
                        <div className='form-group centrum-flex group padding'>
                            <Switch title='Live' onChange={(value) => updateRefundSelections('live', value)} />
                        </div>
                    </div>
                    <div className='padding'>
                        <Button title='Save' />
                    </div>
                </Card>
            </div>
        </div>
        <Tab buttons={tabButtons}>
            <div className='centrum-flex vertical'>
                <EditableTable model={model} data={data} buttons={() => <React.Fragment />} />
                <Panel className='padding'>
                    <div className='centrum-flex'>
                        <Button title='Preview' className='hidden' onClick={() => previewSelectionUpdates()} />
                        <Button title='Save' onClick={() => {
                            var eventId = props.filter.find(x => x.name == 'EventId').value;
                            var group = props.filter.find(x => x.name == 'Group');

                            updateSelectionsOfTickets(data, eventId, group ? group : 0, () => {
                                loadSelections();
                            });
                        }} />
                    </div>
                </Panel>
            </div>
            <Search
                model={{ fields: [] }}
                showQuery={false}
                searchOnStart={true}
                resultModel={betSlipResults}
                data={props.manualTicketOps}
                actions={getAction(ActionTypes.ManualTicketOptions).convertToItems()}
                onSubmit={(qb, searchData) => {
                    searchData.fields = searchFields;
                    console.log(searchData.fields);
                    return apiResult('manualTicketOps', Endpoints.SearchSportEventTickets, searchData, 'fetching sport tickets', 'unable to fetch ticekts');
                }}
            />

        </Tab>
    </Card>;
};

TicketMarkets.propTypes = {
    filter: PropTypes.object,
    event: PropTypes.object,
    manualTicketOps: PropTypes.object,
    onClose: PropTypes.func
};


const mapStateToProps = state => {
    return {
        manualTicketOps: state.data.manualTicketOps
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(TicketMarkets);
export { hoc as TicketMarkets };