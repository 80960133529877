import './_bet-booster.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Panel } from '../../../../Centrum/Card/Card';
import { Form } from '../../../../Controls/Form/Form';
import { FormInput } from '../../../../Controls/Form/FormInput';
import { CurrencyMinMaxConfiguration } from '../../Components/CurrencyMinMaxConfiguration';
import { Tab } from '../../../../Centrum/Tab/Tab';
import { lang } from '../../../../Centrum/Localization/lang';
import { API, SportEndpoints } from '../../../../../v2/Lib/Api/Api';
import { Button } from '../../../../../v2/Components/Controls/Button/Button';
import { SearchEvent } from '../../../Sports/Components/SearchEvent';


export const MarketSelection = (props) => {
    const [sports, setSports] = useState(null);
    const [regions, setRegions] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [selectedSport, selectSport] = useState(null);
    const [selectedRegion, selectRegion] = useState(null);
    const [selectedLeague, selectLeague] = useState(null);
    const [events, setEvents] = useState(null);
    const [selectedMarket, selectMarket] = useState(null);
    const [selectedEvent, selectEvent] = useState(null);
    const [markets, setMarkets] = useState(null);


    const [configuration, setConfiguration] = useState(props.configuration ?? {});

    const selectionRef = React.createRef();
    const lineRef = React.createRef();
    const marginRef = React.createRef();


    useEffect(() => {
        API.post(SportEndpoints.Categories, null).then((result) => {
            setSports(result.result);
            selectSport(result.result[0])
        });
    }, []);

    useEffect(() => {
        if (!selectedSport) return;
        lineRef.current.value = '';
        selectionRef.current.value = '';


        API.post(`${SportEndpoints.Markets}/${selectedSport.id}`).then((result) => {
            if (result.result.length) {
                selectMarket(result.result[0]);
            }

            API.post(`${SportEndpoints.Regions}/${selectedSport.id}`).then((result) => {
                setRegions(result.result);
                selectRegion(result.result[0]);
            });

            setMarkets(result.result);
        });
    }, [selectedSport]);


    useEffect(() => {
        if (!selectedRegion) return;
        API.post(`${SportEndpoints.Leagues}/${selectedSport.id}/${selectedRegion.id}`).then((result) => {
            if (result.result.length > 0) {
                selectLeague(result.result[0]);
                setLeagues(result.result.sort((a, b) => a.name > b.name ? 1 : -1));
            } else {
                selectLeague(null);
                setLeagues([]);
            }
        });
    }, [selectedRegion]);




    useEffect(() => {
        if (!selectedLeague) return;
        API.post(SportEndpoints.Events, {
            sportId: selectedSport.id,
            regionId: selectedRegion.id,
            leagueId: selectedLeague.id,
            startDate: new Date()
        }).then((result) => {
            if (result.result.length > 0) {
                selectEvent(result.result[0]);
                setEvents(result.result.sort((a, b) => a.name > b.name ? 1 : -1));
            } else {
                selectEvent(null);
                setEvents([]);
            }
        });
    }, [selectedLeague]);


    useEffect(() => {
        if (!selectedSport) return;
        lineRef.current.value = '';
        selectionRef.current.value = '';
    }, [selectedMarket]);

    useEffect(() => {
        props.onChange(configuration);
    }, [configuration]);


    return <div className='flex vertical'>
        <div className='flex padding gap-10'>
            <div className='flex gap-5'>
                <label>{lang('Sport')}</label>
                <select onChangeCapture={(e) => selectSport(sports.find(x => x.id == parseInt(e.target.value)))}>
                    {sports?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            <div className='flex gap-5'>
                <label>{lang('Region')}</label>

                <select onChangeCapture={(e) => selectRegion(regions.find(x => x.id == parseInt(e.target.value)))}>
                    {regions?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            <div className='flex gap-5'>
                <label>{lang('League')}</label>
                <select onChangeCapture={(e) => selectLeague(leagues.find(x => x.id == parseInt(e.target.value)))}>
                    {leagues?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            {events?.length > 0 && <div className='flex gap-5'>
                <label>{lang('Events')}</label>

                <select onChangeCapture={(e) => selectEvent(events.find(x => x.id == parseFloat(e.target.value)))}>
                    {events?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>

            </div>
            }
            {events && events.length == 0 && <span>{lang('No events scheduled.')}</span>}
        </div>
        <div className='padding'>
            <SearchEvent onEventSelected={(e) => {
                selectEvent(e);
            }} />
        </div>
        <div className={`flex padding gap-10 ${!selectedEvent && (!events || (events && events.length == 0) ? 'disabled' : '')}`}>
            <div className='flex gap-5'>
                <label>{lang('Markets')}</label>
                <select onChangeCapture={(e) => selectMarket(markets.find(x => x.id == parseInt(e.target.value)))}>
                    {markets?.map(x => <option value={x.id}>[{x.id}] {x.name}</option>)}
                </select>
                <span>{lang('Selection')}</span>
                <input ref={selectionRef} type='text' name='selection' />
                <span>{lang('Line')}</span>
                <input ref={lineRef} type='text' name='line' />
                {props.margin && <span>{lang('Margin')}</span>}
                {props.margin && <input ref={marginRef} type='text' name='margi ' />}
            </div>
            {
                selectedEvent && <Button title='Add' onClick={() => {
                    var copy = { ...configuration };
                    var payload = {
                        event: selectedEvent.id,
                        eventName: selectedEvent.name,
                        date: selectedEvent.date,
                        sport: selectedEvent.sport,
                        marketId: selectedMarket.id,
                        market: selectedMarket.name,
                        selection: selectionRef.current.value,
                        line: lineRef.current.value
                    };

                    var margin = marginRef.current.value;;
                    margin = parseFloat(margin);
                    if (isNaN(margin)) return;
                    if (margin <= 0) return;
                    payload.margin = margin;


                    copy[selectedEvent.id + ':' + selectedMarket.id + ':' + selectionRef.current.value + ':' + lineRef.current.value] = payload;
                    setConfiguration(copy);
                }} />
            }
        </div>
        <table className='boosted-bets-table'>
            <tr>
                <th>Sport</th>
                <th>Date</th>
                <th>Event Id</th>
                <th>Event</th>
                <th>Market Id</th>
                <th>Market Name</th>
                <th>Selection</th>
                <th>Line</th>
                <th>Margin</th>
                <th></th>
            </tr>
            {
                Object.keys(configuration).map(k => {
                    var x = configuration[k];
                    return <tr className={`${x.enabled ? ' active' : ''}`} key={x}>
                        <td>{x.sport}</td>
                        <td>{x.date.indexOf('Z') ? new Date(x.date).toLocaleString('en-Gb', { hours12: false }) : new Date(x.date + 'Z').toLocaleString('en-Gb', { hours12: false })}</td>
                        <td>{x.event}</td>
                        <td>{x.eventName}</td>
                        <td>{x.marketId}</td>
                        <td>{x.market}</td>
                        <td>{x.selection}</td>
                        <td>{x.line && <span>{x.line}</span>}</td>
                        <td>{x.margin}</td>
                        <td><Button title='Remove' onClick={() => {
                            var copy = { ...configuration };
                            delete copy[k];
                            setConfiguration(copy);
                        }} /></td>
                    </tr>;
                })
            }
            {
                Object.values(configuration).length == 0 && <div className='flex padding'><span>{lang('Add leagues using filters')}</span></div>
            }
        </table>
    </div>;
};

MarketSelection.propTypes = {
    configuration: PropTypes.object,
    margin: PropTypes.bool,
    onChange: PropTypes.func
};


export const BetBoosterConfiguration = (props) => {
    const [configuration, saveConfiguration] = useState(props.promotion.Configuration ? (typeof props.promotion.Configuration === 'object' ? props.promotion.Configuration : JSON.parse(props.promotion.Configuration)) : {
        sportsBettingRules: {
            prematch: false,
            live: false,
            betSlip: {

            }
        }
    });

    const [selectedSlipConfigurationTab, selectSlipConfigurationTab] = useState(0);
    const [rules, setRules] = useState(configuration.sportsBettingRules);


    const payoutConfiguration = rules.payout ? rules.payout : {};
    const stakeConfiguration = rules.stake ? rules.stake : {};
    const [marketConfiguration, setMarketConfiguration] = useState(rules.markets ? rules.markets : {});

    const [context, setContext] = useState(null);

    const updateContext = () => {
        setContext({
            save: () => {
                var rulesCopy = {
                    slip: rules.slip,
                    stake: rules.stake,
                    minOdds: rules.minOdds ?? 0,
                    maxOdds: rules.maxOdds ?? 0,
                    payout: rules.payout,
                    prematch: rules.prematch,
                    live: rules.live,
                    markets: marketConfiguration
                };

                var copy = Object.assign({}, configuration);
                configuration.sportsBettingRules = rulesCopy;
                saveConfiguration(copy);
                return rulesCopy;
            }
        });
    };

    useEffect(() => {
    }, []);


    useEffect(() => {
        props.context(context);
    }, [context]);

    useEffect(() => {
        updateContext();
    }, [marketConfiguration]);

    useEffect(() => {
        updateContext();
    }, [rules]);



    const update = (target, value) => {
        var copy = { ...rules };
        copy[target] = value;
        setRules(copy);
    };


    const getAvailableBetslipConfiguration = () => {
        var buttons = [
            {
                name: 'bets',
                title: 'Odds'
            },
            {
                name: 'markets',
                title: 'Markets'
            }
        ];

        return buttons;
    };



    return <div className='padding bet-booster'>
        <Panel title='Ticket Types'>
            <Form data={rules}>
                <div className='row'>
                    <FormInput title='Prematch' name='prematch' type={'bool'} value={rules.prematch}
                        onChange={(f, r, value) => {
                            update('prematch', value);
                        }} />
                    <FormInput title='Live' name='live' type={'bool'} onChange={(f, r, value) => {
                        update('live', value);
                    }} />
                </div>
            </Form>
        </Panel>
        <Card caption='Bet Slip Configuration' className='margin-top'>
            <Tab buttons={getAvailableBetslipConfiguration()} onTabSelected={(index) => selectSlipConfigurationTab(index)}>
                {
                    selectedSlipConfigurationTab == 0 && <div className='flex gap-20 padding'>
                        <div className='form-group flex gap-5'>
                            <label>{lang('Min Odds')}</label>
                            <input onChange={(e) => update('minOdds', parseFloat(e.target.value))} />
                        </div>
                        <div className='form-group flex gap-5'>
                            <label>{lang('Max Odds')}</label>
                            <input onChange={(e) => update('maxOdds', parseFloat(e.target.value))} />
                        </div>

                    </div>
                }
                {
                    selectedSlipConfigurationTab == 1 && <MarketSelection configuration={marketConfiguration} onChange={(data) => setMarketConfiguration(data)} margin={props.margin} />
                }
            </Tab>
        </Card>
        <Card caption='Stake Configuration' className='margin-top'>
            <CurrencyMinMaxConfiguration
                key={stakeConfiguration}
                data={stakeConfiguration}
                fieldTitles={{ From: 'Min', To: 'Max' }}
                onUpdate={(data) => update('stake', data)} />
        </Card>
        <Card caption='Cashout' className='margin-top'>
            <Form data={rules}>
                <div className='row'>
                    <FormInput title='Allow Cashout' name='cashout' type={'bool'} value={rules.cashout}
                        onChange={(f, r, value) => {
                            update('cashout', value);
                        }} />
                </div>
            </Form>
        </Card>
        <Card caption='Payout Configuration' className='margin-top'>
            <CurrencyMinMaxConfiguration
                key={payoutConfiguration}
                model={[
                    { name: 'Max', display: 'Max', type: 'number' }
                ]}
                data={payoutConfiguration}
                fieldTitles={{ Max: 'Max' }} onUpdate={(data) => update('payout', data)} />
        </Card>
    </div>;
};

BetBoosterConfiguration.propTypes = {
    promotion: PropTypes.object,

    context: PropTypes.func
};

