import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MonitorEvent } from '../MontorEvent';
import { InputTypes } from '../../../../../v2/Components/Controls/Form/FormInput';
import { Table } from '../../../../../v2/Components/Controls/Table/Table';
import { currency } from '../../../../../v2/Lib/Common/currency';
import { API, RiskLevelEndpoints } from '../../../../../v2/Lib/Api/Api';
import { Icon } from '../../../../../v2/Components/Icon/Icon';
import { Button } from '../../../../../v2/Components/Controls/Button/Button';
import { openBetModal } from '../../models';
import { lang } from '../../../../../v2/Lib/Common/language';
import { application } from '../../../../Application/Application';
import { ErrorModal } from '../../../../Modal/Modals/Error/ErrorModal';



const renderMarketTitles = (context, onMarketLineChange) => {
    var titles = null;
    var uoOptions = [];
    for (var i = 0; i < 10; i++) {
        uoOptions.push(i + 0.5);
    }

    var defaultMarketsData = defaultMarkets[context.sport.id] ?? defaultMarkets[0];

    titles = <div className='flex gap-10 market-titles'>
        {
            defaultMarketsData.map(x => {
                var lines = x.lines ? x.lines.split('|').map(y => <option key={y} value={y}>{y}</option>) : null;
                var className = `market-${marketWays[x.id] ?? 2}way`;
                if (x.lines) {
                    className = 'market-3way';
                }
                var labels = <div className={className}>
                    {x.labels.split(',').map(y => <label key={y}>{y}</label>)}
                    {lines && <select defaultValue={context.markets.find(y => y.id == x.id)?.line} onChangeCapture={(e) => onMarketLineChange(x.id, e.target.value)}>{lines}</select>}
                </div>;

                if (x.title) {
                    return <div className={'flex vertical'} key={x.id}>
                        <span>{x.title}</span>
                        {labels}
                    </div>;
                }

                return labels;
            })
        }
    </div>;
    return titles;
};

const renderMarketSummary = (row, context) => {
    var markets = null;
    const renderMarkets = (marketData, markets, size) => {
        var elements = [];
        var defaultMarketData = context.markets.find(x => x.id == marketData.id);
        var line = defaultMarketData?.line;
        var liabilities = markets?.liablities;
        if (line) {
            if (markets?.lines && markets.lines[line] && line) {
                liabilities = markets.lines[line];
            } else {
                liabilities = [];
            }
        }
        for (var i = 0; i < size; i++) {
            var liability = markets != null ? liabilities[i] : 0;
            let className = liability < 0 ? 'warning' : ((liability == 0 || !liability) ? '' : 'success');
            elements.push(<span className={className}>{currency(liability, 'EUR', 2, false)}</span>);
        }

        var className = `market-${marketWays[marketData.id] ?? 2}way`;
        if (marketData.lines) {
            className = 'market-3way';
        }

        return <div className={className}>{elements}</div>;
    };
    //sportId
    var defaultMarketsData = defaultMarkets[context.sport.id] ?? defaultMarkets[6046];
    markets = <div className='flex gap-10 markets'>
        {defaultMarketsData.map((x, index) => {
            return renderMarkets(x, row.markets ? row.markets[index] : null, marketWays[x.id] ?? 2);
        })}
    </div>;
    return <div className='flex vertical'>
        {markets}
    </div>;
};

const sportVolumesModel = {
    fields: [
        {
            name: 'id',
            title: 'Id',
            renderRow: (row) => {
                return <div className='flex vertical gap-2'>
                    <span>{row.id}</span>
                    <span className='small'>{row.level}</span>
                </div>;
            }
        },
        {
            name: 'date',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'name',
            title: 'Name',
            renderRow: (row) => {
                return <div className='flex vertical'>
                    <span>{row.name}</span>
                    <div className='flex gap-5'>
                        <span>{row.region?.name}</span>
                        <span>{row.league?.name}</span>
                    </div>
                </div>;
            }
        },
        {
            name: 'eventLiability',
            title: 'Event Liability',
            renderRow: (row) => {
                return currency(row.liability, 'EUR', 2, false);
            }
        },
        {
            name: 'liability',
            title: 'Volumes',
            renderHeader: (field, context) => {
                return renderMarketTitles(context, (marketId, line) => {
                    var copy = [...context.markets];
                    copy.find(x => x.id == marketId).line = line === 'ALL' ? null : line;
                    context.onMarketLineChanged(copy);
                });
            },
            renderRow: (row, field, context) => {
                return renderMarketSummary(row, context);
            }
        },
        /*
        {
            name: 'sllCount',
            title: 'SLL',
            renderRow: (row) => {
                return row.tickets.sll;
            }
        },
        */
        {
            name: 'uniquePlayers',
            title: 'Unique Players',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Unique</span>
                    <span>Players</span>
                </div>;
            },
            renderRow: (row) => {
                return row.players.count;
            }
        },
    ]
};



export const eventVolumesModel = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'eventDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'name',
            title: 'Name'
        },
        {
            name: 'sport',
            title: 'Sport',
            renderRow: (row, field, context) => {
                return context.sports.find(x => x.id == row.sportId)?.name;
            }
        },
        {
            name: 'region',
            title: 'Region',
            renderRow: (row, field, context) => {
                return context.regions.find(x => x.id == row.regionId)?.name;
            }
        },
        {
            name: 'league',
            title: 'League',
            renderRow: (row, field, context) => {
                return context.leagues.find(x => x.id == row.leagueId)?.name;
            }
        },
        {
            name: 'stake',
            title: 'Total Stake',
            renderRow: (row) => {
                return currency(row.volume.stake, 'EUR', 2, false);
            }
        },
        {
            name: 'tickets',
            title: 'Total Tickets',
            renderRow: (row) => {
                return row.volume.tickets;
            }
        },
        /*
        {
            name: 'sllCount',
            title: 'Single & Last Legs'
        },
        */
        {
            name: 'liability',
            title: 'Total Liability',
            renderRow: (row) => {
                return currency(row.volume.liability, 'EUR', 2, false);
            }
        },
        {
            name: 'Profit',
            title: 'Profit',
            renderRow: (row) => {
                return currency(row.volume.profit, 'EUR', 2, false);
            }
        }
    ]
};


const betModel = {
    fields: [
        {
            name: 'username',
            title: 'Username',
            renderRow: (row) => {
                return <div className='flex gap-5'>
                    <label>{row.level}</label>
                    <span>{row.username}</span>
                </div>;
            },
        },
        {
            name: 'stake',
            title: 'Stake',
            renderRow: (row) => {
                return row.stake.toFixed(2);
            }
        },
        {
            name: 'selection',
            title: 'Selection',
            renderRow: (row) => {
                return <div className='flex vertical'>
                    <span>{row.market}</span>
                    <span>{row.selection}</span>
                </div>;
            }
        },
        {
            name: 'price',
            title: 'Odds'
        },
        {
            name: 'liability',
            title: 'Liab./sel',
            renderRow: (row) => {
                return (-row.stake * (row.price - 1)).toFixed(2);
            }
        }
    ]
};

const marketWays = {
    1: 3,
    2: 2,
    28: 2,
    52: 2
};

const defaultMarkets = {
    6046: [
        {
            id: 1,
            title: 'Match Result',
            labels: '1,X,2'
        },
        {
            id: 2,
            labels: 'Under,Over',
            lines: 'ALL|0.5|1|1.5|2|2.5|3|3.5|4|4.5|5|5.5|6|6.5|7|7.5|8|8.5|9|9.5',
            line: '2.5'
        },
        {
            id: 17,
            title: 'Both Team To Score',
            labels: 'Yes,No'
        }
    ],
    48242: [
        {
            id: 52,
            title: 'Match Result',
            labels: '1,2'
        },
        {
            id: 28,
            title: 'Under/Over',
            labels: 'Under,Over'
        }
    ],
    0: [
        {
            id: 52,
            title: 'Match Result',
            labels: '1,2'
        }
    ]
};




export const EventBets = (props) => {
    const [bets, setBets] = useState([]);
    useEffect(() => {
        API.post(`${RiskLevelEndpoints.MonitorEventBets}/${window.user.UserPrivileges.PointOfView}/event/${props.event.id}/bets`).then((result) => {
            setBets(result.result);
        });
    }, []);
    return <div className='flex padding'>
        <Table model={betModel} data={bets}
            buttons={(row) => {
                return <div className='flex gap-5'>
                    <Button title='Ticket' onClick={() => {
                        openBetModal(row.ticketId, false);
                    }} />
                </div>;
            }}
        />
    </div>;
};

EventBets.propTypes = {
    event: PropTypes.object
};


export const LiabilityMonitor = () => {
    const baseUrl = '/RiskManagement/RiskManagementBetMonitor';
    const [volumes, setVolumes] = useState(null);
    const [selectedView, selectView] = useState(undefined);
    const [sports, setSports] = useState([]);
    const [selectedSport, selectSport] = useState(null);
    const [selectedEvent, selectEvent] = useState(null);
    const [tableSorting, setSorting] = useState({ field: 'eventLiability', direction: 0 });
    const [sportMarkets, setSportMarkets] = useState(null);
    const [filters, setFilters] = useState({ sll: true, sport: null });

    useEffect(() => {
        API.post(`${RiskLevelEndpoints.MonitorSports}/${window.user.UserPrivileges.PointOfView}/all`).then((result) => {
            var copy = result.result.filter(x => x.id != 1);
            setSports(copy);
            selectSport(copy[0]);
        });
    }, []);



    useEffect(() => {
        if (!selectedSport) return;
        changeFilters('sportId', selectedSport.id);

        var defaultMarketsData = defaultMarkets[selectedSport.id] ?? defaultMarkets[0];
        setSportMarkets(defaultMarketsData);
        if (filters.liability && !isNaN(parseFloat(filters.liability))) {
            fetchSportSnapshot(selectedSport, defaultMarketsData);
        }
    }, [selectedSport]);


    useEffect(() => {
        if (selectedView === undefined) return;
        if (selectedView == null) {
            appHistory.push(baseUrl);
            return;
        }
        appHistory.push(`${baseUrl}?&view=${selectedView.view}&eventId=${selectedView.event.id}`);
    }, [selectedView]);

    const changeFilters = (target, value) => {
        var copy = { ...filters };
        copy[target] = value;
        setFilters(copy);
    };

    const fetchSportSnapshot = (sport, defaultMarketsData) => {
        if (!filters.liability || isNaN(parseFloat(filters.liability))) {
            application().modal.open(<ErrorModal title='Please complete the filters'>
                <p className='flex padding'>
                    {lang('Liability amount is invalid.')}
                </p>
            </ErrorModal>);
            return;
        };

        API.post(`${RiskLevelEndpoints.MonitorSports}/${window.user.UserPrivileges.PointOfView}/${sport.id}/${filters.liability}/${filters.sll}`, defaultMarketsData).then((result) => {
            var copy = [...result.result];
        
            setVolumes(copy);

            var query = queryString.parse(window.location.search);
            if (!query || !query.view || !query.eventId) return;
            var queryView = query.view;
            var eventId = parseFloat(query.eventId);
            var event = result.result.events.find(x => x.id == eventId);

            if (event) {
                selectView({ view: queryView, event: event });
            } else {
                appHistory.push(baseUrl);
            }
        });
    };

    //console.log(volumes);


    if (selectedView && selectedView.view == views.ShowLevels) {
        return <div>
            <MonitorEvent event={selectedView.event} volumes={volumes} onClose={() => selectView(null)} />
        </div>;
    }

    return <div className='content'>
        <div className='panel padding'>
            <div className='flex gap-10'>
                <div className='flex vertical gap-5'>
                    <div className='flex gap-5'>
                        <label>Sport</label>
                        <div className='flex gap-5'>
                            {
                                sports.sort((a, b) => a.liability > b.liability ? 1 : -1).map(x => {
                                    return <div className={`flex gap-5 padding clickable border${selectedSport?.id == x.id ? ' warning' : ''}`} onClick={() => {
                                        selectSport(x);
                                    }}><label>{x.name}</label><span>{currency(x.liability, 'EUR', 2, false)}</span></div>;
                                })
                            }
                        </div>
                    </div>
                    <div className='flex gap-5'>
                        <label>{lang('Liability')}</label>
                        <input className={!filters.liability || isNaN(filters.liability) ? 'error' : ''} onChange={(e) => {
                            changeFilters('liability', parseFloat(e.target.value));
                        }} />
                    </div>
                </div>
                <div className='flex gap-5 hidden'>
                    <label>{lang('Single Last Legs')}</label>
                    <Icon icon={filters.sll ? 'check-circle' : 'circle'} type='far' onClick={() => changeFilters('sll', !filters.sll)} />
                </div>
                <div className='flex align-right'>
                    <Button title='refresh' onClick={() => {
                        fetchSportSnapshot(selectedSport, sportMarkets);
                    }} />
                </div>
            </div>
        </div>
        <div className='betmonitor-risk-levels'>
            <Table
                data={volumes ? [...volumes].sort((a, b) => {
                    switch (tableSorting.field) {
                        case 'id':
                            return tableSorting.direction == 1 ? (parseFloat(a.name) > parseFloat(b.name) ? -1 : 1) : (parseFloat(a.name) > parseFloat(b.name) ? 1 : -1);
                        case 'name':
                            return tableSorting.direction == 1 ? (a.name > b.name ? -1 : 1) : (a.name > b.name ? 1 : -1);
                        case 'eventLiability':
                            return tableSorting.direction == 1 ? (a.liability > b.liability ? -1 : 1) : (a.liability > b.liability ? 1 : -1);
                        default:
                            return tableSorting.direction == 1 ? (new Date(a.date) - new Date(b.date)) : (new Date(b.date) - new Date(a.date));
                    }
                }) : []}
                model={sportVolumesModel} context={{
                    sport: selectedSport,
                    volumes: volumes,
                    markets: sportMarkets,
                    onMarketLineChanged: (marketData) => {
                        setSportMarkets(marketData);
                    }
                }}
                className='sticky-head'
                options={
                    {
                        pagination: {
                            rows: 150,
                            showPages: true
                        },
                        sorting: {
                            enabled: true,
                            field: {
                                column: tableSorting.field,
                                direction: tableSorting.direction
                            },
                            onSortingChange: (sorting) => {
                                if (sorting.column == 'buttons') return;
                                setSorting({ field: sorting.column, direction: sorting.direction });
                            }
                        }
                    }
                }
                buttons={(row) => {
                    return <div className='flex gap-5'>
                        <div className='flex padding half surface low border radius' onClick={() => {
                            if (selectedEvent == row) {
                                selectEvent(null);
                            } else {
                                selectEvent(row);
                            }
                        }} >
                            <Icon icon={selectedEvent == row ? 'times' : 'search'} />
                        </div>
                        <div className='flex padding half surface low border radius hidden' onClick={() => {
                        }} >
                            <Icon icon={row.betStop ? 'play' : 'pause'} />
                        </div>
                        <div className='flex padding half surface low border radius hidden' onClick={() => {
                        }} >
                            <Icon icon={'wrench'} />
                        </div>
                    </div>;
                }}
                onRenderRow={(row, element, className) => {
                    return <React.Fragment>
                        <tr className={selectedEvent == row ? 'expanded' : className}>
                            {element}
                        </tr>
                        {selectedEvent == row && <tr className={className}>
                            <td colSpan='5'>
                                <EventBets event={row} />
                            </td>
                            <td>

                            </td>
                        </tr>
                        }
                    </React.Fragment>;
                }}
            />
        </div>
    </div>;
};