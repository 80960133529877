import './_finance-report.scss';

import React, { useEffect, useState } from 'react';
import { Card } from '../../Centrum/Card/Card';
import { postData } from '../../../state/services/ServiceHelpers';
import { apiResult, Endpoints } from '../../Application/Application';
import { Search } from '../../Centrum/Search/Search';
import { DateRanges } from '../../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { readFromFile, saveXLSX } from '../../Centrum/Common/Excel/ExcelHelpers';
import { Button } from '../../Controls/Buttons/Button';
import { useSelector } from 'react-redux';


const model = {
    fields: [
        {
            name: 'OperatorId',
            display: 'Operator',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.multipleSelect,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Date',
            display: 'Date',
            type: InputTypes.date,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        }]
};



const resultModel = {
    fields: [
        {
            name: 'ProviderId',
            display: 'Provider',
            formatter: (val, row, isFooter, context) => {
                return context.providers.find(x => x.id == row.ProviderId).name;
            }
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'Bet',
            display: 'Bet',
            formatter: (val) => {
                return Math.abs(val);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            formatter: (val) => {
                return Math.abs(val);
            }
        }
    ],
    onParseExcel: (data, context) => {
        readFromFile('/report-files/finance-provider-report.xlsx').then((workbook) => {
            var reportSheet = workbook.getWorksheet('Sheet1');


            const min = new Date(context.data.request.fields.find(x => x.name == 'Date').min);
            const max = new Date(context.data.request.fields.find(x => x.name == 'Date').max);
            const group = context.data.request.fields.find(x => x.name == 'Group');
            var groupNames = '';

            if (group) {
                groupNames = group?.value?.split(',').map(id => {
                    return context.groups.find(x => x.Id == id).Name;
                });
            }

            var operator = context.data.request.fields.find(x => x.name == 'OperatorId').value;
            var currency = context.data.request.fields.find(x => x.name == 'Currency')?.value;
            var operatorCode = '-';
            switch (operator) {
                case '22':
                    operator = 'Sport Arena';
                    operatorCode = '2101';
                    break;
                case '24':
                    operator = 'TTBet';
                    operatorCode = '3001';
                    break;
                default:
                    operator = 'All';
                    break;
            }
            reportSheet.getCell('D1').value = 'Finance Report-' + new Date();
            reportSheet.getCell('D2').value = 'Operator:';
            reportSheet.getCell('E2').value = operator;
            reportSheet.getCell('D3').value = 'Player Group:';
            reportSheet.getCell('E3').value = groupNames ? groupNames.join(',') : 'Not Selected';
            reportSheet.getCell('D4').value = 'Currency:';
            reportSheet.getCell('E4').value = currency ?? 'Not Selected';
            reportSheet.getCell('D5').value = 'Date Min:';
            reportSheet.getCell('E5').value = min.toLocaleString('en-Gb', { hours12: false });
            reportSheet.getCell('D6').value = 'Data Max:';
            reportSheet.getCell('E6').value = max.toLocaleString('en-Gb', { hours12: false });

            var totals = {

            };
            context.data.result.map(x => {
                if (!totals.hasOwnProperty(x.Currency)) {
                    totals[x.Currency] = {
                        Currency: x.Currency,
                        Bet: 0,
                        Win: 0
                    };
                }

                totals[x.Currency].Bet += Math.abs(x.Bet);
                totals[x.Currency].Win += Math.abs(x.Win);
            });

            var i = 8;
            Object.values(totals).map((x, i2) => {
                if (i2 == 0) {
                    reportSheet.getCell(`E${i}`).value = `Total Bet ${x.Currency}`;
                    reportSheet.getCell(`I${i}`).value = Math.abs(x.Bet);
                    reportSheet.getCell(`I${i}`).numFmt = '#,##0.00;[Red]-#,##0.00';

                    reportSheet.getCell(`N${i}`).value = `Total Win ${x.Currency}`;
                    reportSheet.getCell(`R${i}`).value = x.Win;
                    reportSheet.getCell(`R${i}`).numFmt = '#,##0.00;[Red]-#,##0.00';
                } else {
                    var row = [];
                    row.push('');
                    row.push('');
                    row.push('');
                    row.push('');
                    row.push(`Total Bet ${x.Currency}`);
                    row.push('');
                    row.push('');
                    row.push('');
                    row.push(Math.abs(x.Bet));
                    row.push('');
                    row.push('');
                    row.push('');
                    row.push('');
                    row.push(`Total Win ${x.Currency}`);
                    row.push('');
                    row.push('');
                    row.push('');
                    row.push(x.Win);
                    reportSheet.insertRow(i, row, 'o');
                    reportSheet.getCell(`I${i}`).numFmt = '#,##0.00;[Red]-#,##0.00';
                    reportSheet.getCell(`R${i}`).numFmt = '#,##0.00;[Red]-#,##0.00';
                }
                i++;
            });


            var currencies = context.data.data.rates;
            var defaultCurrency = context.data.data.defaultCurrency;
            currencies.map((x) => {
                if (x.base == defaultCurrency) {
                    var row = [];
                    row.push('');
                    row.push('');
                    row.push('');
                    row.push('');
                    row.push(1);
                    row.push(x.currencyCode);
                    row.push(x.buy);
                    row.push(defaultCurrency);
                    reportSheet.insertRow(i, row, 'o');
                    i++;
                }
            });


            i += 4;
            var c = 1;
            var dates = (min.getMonth() + 1).toString().padStart(2, '0') + '/' + min.getDate().toString().padStart(2, '0') + ' - ' + (max.getMonth() + 1).toString().padStart(2, '0') + '/' + max.getDate().toString().padStart(2, '0');
            context.data.result.map((x, iii) => {
                const provider = context.providers.find(y => y.id == x.ProviderId);
                if (iii == 0) {
                    reportSheet.getCell(`D${i}`).value = c;
                    reportSheet.getCell(`E${i}`).value = provider.card != null ? provider.card : ' ';
                    reportSheet.getCell(`F${i}`).value = operator;
                    reportSheet.getCell(`G${i}`).value = dates;
                    reportSheet.getCell(`H${i}`).value = `${provider.name} (${x.Currency})`;
                    reportSheet.getCell(`I${i}`).value = 1;
                    reportSheet.getCell(`J${i}`).value = Math.abs(x.Bet);
                    reportSheet.getCell(`J${i}`).numFmt = '#,##0.00;[Red]-#,##0.00';
                    reportSheet.getCell(`K${i}`).value = operatorCode;


                    reportSheet.getCell(`M${i}`).value = 'Hizmet';
                    reportSheet.getCell(`N${i}`).value = provider.card != null ? provider.card : ' ';
                    reportSheet.getCell(`O${i}`).value = operator;
                    reportSheet.getCell(`P${i}`).value = dates;
                    reportSheet.getCell(`Q${i}`).value = `${provider.name} (${x.Currency})`;
                    reportSheet.getCell(`R${i}`).value = 1;
                    reportSheet.getCell(`S${i}`).value = Math.abs(x.Win);
                    reportSheet.getCell(`S${i}`).numFmt = '#,##0.00;[Red]-#,##0.00';
                    reportSheet.getCell(`T${i}`).value = operatorCode;
                } else {
                    var row = [];

                    row.push('');
                    row.push('');
                    row.push('');

                    row.push(c);
                    row.push(provider.card != null ? provider.card : ' ');
                    row.push(operator);
                    row.push(dates);
                    row.push(`${provider.name} (${x.Currency})`);
                    row.push(1);
                    row.push(Math.abs(x.Bet));
                    reportSheet.getCell(`J${i}`).numFmt = '#,##0.00;[Red]-#,##0.00';
                    row.push(operatorCode);
                    row.push('');

                    row.push('Hizmet');
                    row.push(provider.card != null ? provider.card : ' ');
                    row.push(operator);
                    row.push(dates);
                    row.push(`${provider.name} (${x.Currency})`);
                    row.push(1);
                    row.push(Math.abs(x.Win));
                    reportSheet.getCell(`S${i}`).numFmt = '#,##0.00;[Red]-#,##0.00';
                    row.push(operatorCode);
                    reportSheet.insertRow(i + 1, row);
                    i++;
                }
            });
            saveXLSX(workbook, 'finance-summary.xlsx');
        });
    }
};


export const FinanceReport = () => {
    const [providers, setProviders] = useState([]);
    const [data, setData] = useState([]);
    const [groups, setGroups] = useState(null);
    const user = useSelector((state) => state.user);

    const allowed = user.loginInformation.OperatorId == 22 || user.loginInformation.OperatorId == 24;

    useEffect(() => {
        if (!allowed) return;
        postData(null, Endpoints.MyPlayerGroups, {}).then((groups) => {
            setGroups(groups.result);
        });

        postData(null, Endpoints.MyProviders).then((result) => {
            const finalize = (cards) => {
                if (!cards) {
                    cards = [];
                } else {
                    cards = JSON.parse(cards);
                }
                var sorted = result.result.sort((a, b) => a.name > b.name ? 1 : -1);

                sorted = [{
                    id: 42000,
                    name: 'Sports'
                },
                {
                    id: 9999,
                    name: 'Horse & Greyhound'
                }, ...sorted];

                sorted.map(x => {
                    var c = cards.find(y => y.id == x.id);
                    if (c) {
                        x.card = c.card;
                    }
                });
                setProviders(sorted);

            };
            postData(null, Endpoints.ProviderCards).then((cards) => {
                finalize(cards.result);
            }).catch(() => {
                finalize(null);
            });
        });
    }, []);

    const context = {
        providers: providers,
        data: data,
        groups: groups
    };

    const renderProviders = () => {
        return providers.map(provider => {
            return <div className='provider-config' key={provider.id}>
                <label>{provider.name}</label>
                <input type='text' defaultValue={provider.card}
                    onChange={(e) => {
                        var copy = [...providers];
                        var p = copy.find(x => x.id == provider.id);
                        p.card = e.target.value;
                        setProviders(copy);
                    }} />
            </div>;
        });
    };
    if (!providers) return <div></div>;
    if (!groups) return <div></div>;
    return <Card caption='Finance Report'>
        <div className='finance-report'>
            <Card caption='Provider Config' menu={<Button title='Save' onClick={() => {
                apiResult('savedprovidercards',
                    Endpoints.SaveProviderCards, providers, 'saving', 'unable to save');
            }} />}>
                <div className='provider-configs'>
                    {renderProviders()}
                </div>
            </Card>
            <Card caption='Report'>
                <Search model={model}
                    context={context}
                    resultModel={resultModel}
                    data={data}
                    onSubmit={(data, qb) => {
                        var copy = [];
                        providers.map(x => copy.push(x.id));
                        qb.fields.filter(x => x.name == 'Providers');
                        qb.fields.push({
                            name: 'Providers',
                            value: copy.join(',')
                        });
                        return postData(qb, Endpoints.PrepareFinanceReport, qb, 'fetching reports', 'unable to fetch report').then((result) => {
                            setData(result);
                        });
                    }} />
            </Card>
        </div>
    </Card>;
};