// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Search } from '../../Centrum/Search/Search';

import { Endpoints, apiResult } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { openBonusBets } from './actions';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { DateRanges } from '../../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';
import { BonusStatus, BonusTypes } from '../../Centrum/_models/ModelEnums';


const bonusType = (type) => {
    if (!window.__bTypes) {
        window.__bTypes = {};
        Object.keys(BonusTypes).map(x => {
            var id = parseInt(BonusTypes[x]);
            window.__bTypes[id] = x;
        });

        return window.__bTypes[type];
    }
};

const searchConsumedBonus = {
    fields: [
        {
            name: 'ConsumedDate',
            display: 'Date',
            max: 19,
            type: InputTypes.dateTime,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'OperatorUserId',
            display: 'Admin',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myOperatorUsers',
                bindingKey: 'Id',
                bindingText: ['Email', 'Name', 'Surname']
            }
        },
        {
            name: 'Username',
            type: InputTypes.text,
            hidden: true
        },
        {
            name: 'UserId',
            type: InputTypes.text,
            hidden: true
        },
        {
            name: 'PromotionId',
            display: 'Promotion',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'promotion',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: BonusStatus
        }
    ]
};

const consumedBonusInformation = {
    fields: [
        {
            name: 'Id'
        },
        {
            name: 'ConsumedDate',
            display: 'Consumed',
            type: InputTypes.dateTime
        },
        {
            name: 'CompleteDate',
            display: 'Complete',
            type: InputTypes.dateTime
        },
        {
            name: 'RemovedDate',
            display: 'Removed',
            type: InputTypes.dateTime
        },
        {
            name: 'Type',
            display: 'Type',
            formatter: (val) => {
                return bonusType(val);
            }
        },
        {
            name: 'Name'
        },
        {
            name: 'BonusWallet',
            display: 'Bonus Wallet',
            type: InputTypes.bool
        },
        {
            name: 'TurnoverMultiplier',
            display: 'Turnover Multiplier',
            type: InputTypes.number
        },
        {
            name: 'DepositAmount',
            display: 'Deposit Amount',
            type: InputTypes.number
        },
        {
            name: 'Amount',
            display: 'Bonus Amount',
            type: InputTypes.number
        },
        {
            name: 'PlayerBalance',
            display: 'Starting Balance',
            type: InputTypes.number
        },
        {
            name: 'SportsBets',
            display: 'Tıckets',
            type: InputTypes.number
        },
        {
            name: 'TotalBet',
            display: 'Stake',
            type: InputTypes.number
        },
        {
            name: 'TotalWin',
            display: 'Win',
            type: InputTypes.number
        },
        {
            name: 'CompletedPayout',
            display: 'Completed Payout',
            type: InputTypes.number
        },
        {
            name: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: BonusStatus
        }
    ]
};

// COMPONENT
class PlayerBonusPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    componentDidMount() {

    }
    render() {

        return (
            <React.Fragment>

                <React.Fragment key='credit'>
                    <div className='centrum-flex paddingHalf'>
                        <Card caption='Bonus History'>
                            <Search
                                model={searchConsumedBonus}
                                resultModel={consumedBonusInformation}
                                onDoubleClick={(data) => {
                                    openBonusBets(data.Id);
                                }}
                                onSubmit={(data, qb) => {
                                    var searchData = Object.assign({}, qb);
                                    searchData.fields.push({ and: true, exact: true, name: 'PlayerId', value: this.props.profile.id });
                                    apiResult('consumedPromotions', Endpoints.SearchConsumedPromotions, searchData, 'loading player transactions please wait', 'Unable to fetch transactions');
                                }}
                                data={this.props.consumedPromotions}
                            />
                        </Card>
                    </div>
                </React.Fragment>
            </React.Fragment >
        );
    }
}

PlayerBonusPage.defaultProps = {
    profile: null,
    onCreateBonusTransaction: null
};

PlayerBonusPage.propTypes = {
    profile: PropTypes.object,
    consumedPromotions: PropTypes.object,
    onCreateBonusTransaction: PropTypes.func
};

const mapStateToProps = state => {
    return {
        consumedPromotions: state.data.consumedPromotions
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(PlayerBonusPage);
export { hoc as PlayerBonusPage };