import './_risk-tickets.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { Card } from '../../../../../../v2/Components/Card/Card';
import { Button } from '../../../../../../v2/Components/Controls/Button/Button';
import { openBetModal } from '../../../models';
import { useState } from 'react';
import { Table } from '../../../../../../v2/Components/Controls/Table/Table';
import { InputTypes } from '../../../../../../v2/Components/Controls/Form/FormInput';
import { application } from '../../../../../Application/Application';
import { Pagination } from '../../../../../Controls/Table/Editable/Pagination';
import { SportsTicketStatus, SportsTicketTypes } from '../../../../../../v2/Components/Sports/Tickets/BettingEnums';
import { SportsBetTypes } from '../../../../../Centrum/_models/ModelEnums';

const betSlipResults = {
    fields: [
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Updated',
            title: 'Update',
            type: InputTypes.DateTime
        },
        {
            name: 'Operator',
            title: 'Operator'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'Group',
            title: 'Group'
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Username',
            title: 'Username'
        },
        {
            name: 'Type',
            title: 'Betting Type',
            type: InputTypes.Select,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            title: 'Status',
            type: InputTypes.Select,
            values: SportsTicketStatus
        },
        {
            name: 'Selections',
            title: 'Bet / Win'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Bet',
            title: 'Stake',
            type: InputTypes.Number
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number
        },
        {
            name: 'PossibleWin',
            title: 'PossibleWin',
            type: InputTypes.Number
        },
        {
            name: 'TicketBonusPercentage',
            title: 'Bonus %',
            type: InputTypes.Number
        },
        {
            name: 'TicketBonusAmount',
            title: 'Bonus Amount',
            type: InputTypes.Number
        },
        {
            name: 'Win',
            title: 'Win',
            type: InputTypes.Number
        },
        {
            name: 'Bonus',
            title: 'Bonus',
            type: InputTypes.Bool
        }
    ],
    onRenderRow: (rowObject) => {
        if (!rowObject.data) return rowObject;
        if (rowObject.data.Status == 2) {
            rowObject.className = 'row-lost';
        }
        if (rowObject.data.Status == 1) {
            rowObject.className = 'row-win';
        }
        if (rowObject.data.Status == 3) {
            rowObject.className = 'row-cashout';
        }

        return rowObject;
    }
};


export const RiskTickets = (props) => {
    const data = props.data;
    const [ticketDetails, setTicketDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const renderFooter = () => {
        if (ticketDetails) return;
        return <Pagination recordsPerPage={20}
            currentPage={!data.request.currentPage ? 1 : data.request.currentPage}
            total={data.total}
            onPageClicked={(index) => {
                var r = Object.assign({}, data.request);
                r.currentPage = index;
                props.search(r);
            }} />;
    };

    return <Card title={ticketDetails ? 'Ticket Details' : 'Tickets'} className={'modal-risk-tickets border shadow no-padding' + (loading ? ' disabled' : '')}
        buttons={() => <Button title={ticketDetails ? 'Close Details' : 'Close'} className='confirm' onClick={() => ticketDetails ? setTicketDetails(null) : application().modal.close()} />}
        footer={renderFooter()}>
        <Table
            className={ticketDetails ? 'hidden' : ''}
            onDoubleClick={(tr, row) => {
                setLoading(true);
                openBetModal(row.ExternalTicketId, row.TicketType == 2, false, (component) => {
                    setLoading(false);
                    setTicketDetails(component);
                });
            }}
            model={betSlipResults} data={props.data.result} />

        {ticketDetails != null && ticketDetails}
    </Card>;
};

RiskTickets.propTypes = {
    request: PropTypes.object,
    data: PropTypes.object,
    search: PropTypes.func
};
