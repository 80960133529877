import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { getToday } from '../helpers';


export const ServerOperationModels = {
    kickUser: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly:true,
            },
            {
                name: 'Username',
                type: InputTypes.text,
                readonly:true
            },
            {
                name: 'LastLogin',
                display:'Login',
                type: InputTypes.text,
                readonly:true
            },
            {
                name: 'LastLoginIp',
                display:'Ip',
                type: InputTypes.text,
                readonly:true
            },
            {
                name: 'LastLoginDevice',
                display:'Device',
                type: InputTypes.text,
                multiLine:true,
                readonly:true
            },
            {
                name: 'Options',
                display:'Kick Options',
                type: InputTypes.select,
                values:{
                    0:'Now',
                    1:'After Game Finish'
                }
            },
            {
                name: 'Expires',
                display:'Expires',
                type: InputTypes.dateTime
            },
            {
                name:'Message',
                display:'Message',
                type:InputTypes.text,
                multiLine:true
            }
        ]
    },
    banUser: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly:true,
            },
            {
                name: 'Username',
                type: InputTypes.text,
                readonly:true
            },
            {
                name: 'LastLogin',
                display:'Login',
                type: InputTypes.text,
                readonly:true
            },
            {
                name: 'LastLoginIp',
                display:'Ip',
                type: InputTypes.text,
                readonly:true
            },
            {
                name: 'LastLoginDevice',
                display:'Device',
                type: InputTypes.text,
                multiLine:true,
                readonly:true
            },
            {
                name: 'Options',
                display:'Ban Options',
                type: InputTypes.select,
                values:{
                    0:'Now',
                    1:'After Game Finish'
                }
            },
            {
                name: 'Expires',
                display:'Expires',
                type: InputTypes.dateTime,
                value:getToday()
            },
            {
                name:'Message',
                display:'Message',
                type:InputTypes.text,
                multiLine:true
            }
        ]
    }
};