
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


import { Page } from '../../Centrum/Page/Page';
import { KPIRangeSelector, KPIRangeContext } from '../Dashboards/components/KPIRangeSelector/KPIRangeSelector';
import { KPICard } from '../Dashboards/components/KPICard/KPICard';
import { Money } from '../../Controls/Money/Money';
import { LabelValue } from '../Dashboards/components/LabelValue/LabelValue';
import { application, Endpoints } from '../../Application/Application';
import { Icon } from '../../Controls/Icon/Icon';
import { getHistory } from '../../routers/AppRouter';
import { lang } from '../../Centrum/Localization/lang';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { Card } from '../../../v2/Components/Card/Card';
import { currency } from '../../../v2/Lib/Common/currency';
import { AffiliateDashboardEndpoints, API } from '../../../v2/Lib/Api/Api';
import { addDays, addHours, addMonths } from 'date-fns';
// COMPONENT


const convertDate = (d) => {
    var date = new Date(d);

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};


const today = (d) => {
    var date = !d ? new Date() : d;

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} 00:00`;
};



const tonight = (d) => {
    var date = !d ? new Date() : d;
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} 23:59`;
};

const thisMonth = () => {
    var date = new Date();
    date = addDays(date, -date.getDate());
    return new Date(today(date));
};

const lastMonth = () => {
    var date = new Date();
    date = addDays(date, -date.getDate());
    date = addMonths(date, -1);
    return new Date(today(date));
};

export const AffiliateDashboard = (props) => {
    const [startDate, setStartDate] = useState(today());
    const [endDate, setEndDate] = useState(tonight());

    const [dashboard, setDashboardData] = useState(null);

    useEffect(() => {
        fetchDashboard();
    }, []);


    const fetchDashboard = () => {
        var convertedStartDate = new Date(startDate);
        var convertedEndDate = new Date(endDate);

        convertedStartDate = addHours(convertedStartDate, 0).toISOString();
        convertedEndDate = addHours(convertedEndDate, 0).toISOString();

        API.post(`${AffiliateDashboardEndpoints.GetDashboardStats}/${props.affiliate.Id}`, { dateStart: convertedStartDate, dateEnd: convertedEndDate }).then((result) => {
            setDashboardData(result.result);
        });
    };

    if (!dashboard) return <></>;

    return <div className='application-content dashboard flex vertical gap-10'>
        <div className='flex gap-5 fit surface low border shadow padding'>
            <div className='flex gap-5 align-start'>
                <label>{lang('Start Date')}</label>
                <input type='datetime-local' value={convertDate(startDate)} onChange={(e) => {
                    setStartDate(new Date(e.target.value));
                }} />
                <label>{lang('End Date')}</label>
                <input type='datetime-local' value={convertDate(endDate)} onChange={(e) => {
                    setEndDate(new Date(e.target.value));
                }} />
                <Button title='Today' onClick={() => {
                    setStartDate(today());
                    setEndDate(tonight());
                }} />
                <Button title='Last Month' onClick={() => {
                    setStartDate(addDays(lastMonth(), 1));
                    setEndDate(addDays(thisMonth(), 1));
                }} />
                <Button title='This Month' onClick={() => {
                    setStartDate(thisMonth());
                    setEndDate(tonight());
                }} />
            </div>
            <Button title='List' onClick={() => {
                fetchDashboard();
            }} />
        </div>
        <div className='flex gap-5 align-items-start'>
            <Card title='Players'>
                <div className='flex vertical gap-10 fit'>
                    <div className='flex gap-5 fit'>
                        <label>{lang('All')}</label>
                        <span>{dashboard.totalPlayers}</span>
                    </div>
                    <div className='flex gap-5 fit border-top padding-top'>
                        <label>{lang('New')}</label>
                        <span>{dashboard.registerdPlayers}</span>
                    </div>
                </div>
            </Card>

            <Card title='Deposit' buttons={() => <div>{currency(dashboard.deposits + dashboard.manualDeposits, props.affiliate.Currency, 2)}</div>}>
                <div className='flex vertical gap-10 fit'>
                    <div className='flex gap-5 fit'>
                        <label>{lang('Provider Deposit')}</label>
                        <span>{currency(dashboard.deposits, props.affiliate.Currency, 2)}</span>
                    </div>
                    <div className='flex gap-5 fit padding-top border-top'>
                        <label>{lang('Manual Deposit')}</label>
                        <span>{currency(dashboard.manualDeposits, props.affiliate.Currency, 2)}</span>
                    </div>
                </div>
            </Card>
            <Card title='Withdraw' buttons={() => <div>{currency(dashboard.withdraws + dashboard.manualWithdraws, props.affiliate.Currency, 2)}</div>}>
                <div className='flex vertical gap-10 fit'>
                    <div className='flex gap-5 fit'>
                        <label>{lang('Provider Withdraw')}</label>
                        <span>{currency(dashboard.withdraws, props.affiliate.Currency, 2)}</span>
                    </div>
                    <div className='flex gap-5 fit padding-top border-top'>
                        <label>{lang('Manual Withdraw')}</label>
                        <span>{currency(dashboard.manualWithdraws, props.affiliate.Currency, 2)}</span>
                    </div>
                </div>
            </Card>
            <Card title='Casino'>
                <div className='flex vertical gap-10 fit'>
                    <div className='flex gap-5 fit'>
                        <label>{lang('GGR')}</label>
                        <span>{currency(dashboard.games.ggr, props.affiliate.Currency, 2)}</span>
                    </div>
                    <div className='flex gap-5 fit padding-top border-top'>
                        <label>{lang('Hands')}</label>
                        <span>{dashboard.games.totalHands}</span>
                    </div>
                    <div className='flex gap-5 fit padding-top border-top'>
                        <label>{lang('Bet')}</label>
                        <span>{currency(dashboard.games.bet, props.affiliate.Currency, 2)}</span>
                    </div>
                    <div className='flex gap-5 fit padding-top border-top'>
                        <label>{lang('Win')}</label>
                        <span>{currency(dashboard.games.win, props.affiliate.Currency, 2)}</span>
                    </div>
                </div>
            </Card>
            <Card title='Sports'>
                <div className='flex vertical gap-10 fit'>
                    <div className='flex gap-5 fit'>
                        <label>{lang('GGR')}</label>
                        <span>{currency(dashboard.sports.paid.ggr, props.affiliate.Currency, 2)}</span>
                    </div>
                    <div className='flex gap-5 fit padding-top border-top'>
                        <label>{lang('Total Paid')}</label>
                        <span>{dashboard.sports.paid.total}</span>
                        <label>{lang('Open')}</label>
                        <span>{dashboard.sports.open.total}</span>
                    </div>
                    <div className='flex gap-5 fit padding-top border-top'>
                        <label>{lang('Open Bet')}</label>
                        <span>{currency(dashboard.sports.open.bet, props.affiliate.Currency, 2)}</span>
                    </div>
                    <div className='flex gap-5 fit padding-top border-top'>
                        <label>{lang('Open Possible Win')}</label>
                        <span>{currency(dashboard.sports.open.possibleWin, props.affiliate.Currency, 2)}</span>
                    </div>
                </div>
            </Card>
        </div>
    </div>;
};


AffiliateDashboard.defaultProps = {
    groups: [],
    commission: 0,
    affiliate: null
};

AffiliateDashboard.propTypes = {
    groups: PropTypes.array,
    commission: PropTypes.number,
    affiliate: PropTypes.object,
    relatedPlayer: PropTypes.array
};


export class AffiliateDashboard2 extends Page {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
        this.kpiRangeContext = KPIRangeContext('summary');
    }

    componentDidMount = () => {
        this.loadCommissions();
    }
    loadCommissions() {
        return application().resolve(null, Endpoints.GetAffilliateDebt + '/' + this.props.affiliate.Id, {}, 'retreiving affiliates commissions', 'unable to fetch affiliates commissions').then((result) => {
            this.setState({ commissions: result.result });
        });
    }

    copyLink() {

        var link = this.state.affiliate.WebSite + '?&cid=' + this.state.affiliate.Link;
        const el = document.createElement('textarea');
        el.value = link;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        application().snackbar.open('copied');
    }

    handleNewPlayerClick() {
        console.log('KPIRangeContext', this.kpiRangeContext);
        var path = window.location.host;
        if (path.indexOf('ListAffiliatePlayers') == -1) {
            getHistory().push('/AffiliatePlayers/ListAffiliatePlayers/profile/43175');
        }
    }

    render() {
        var affiliateId = this.state.affiliate.Id;
        var currency = this.state.affiliate.Currency;
        var link = this.state.affiliate.WebSite + '?&cid=' + this.state.affiliate.Link;

        return <React.Fragment>
            <div className='application-content dashboard centrum-flex vertical'>
                <div className='centrum-flex stretch'>
                    <Card caption='Link'>
                        <div className='margin' onClick={() => this.copyLink()} style={{ cursor: 'pointer' }}>{link}</div>
                    </Card>
                    <Card caption='Commissions'>
                        <div className='margin'>
                            <LabelValue title='Balance'><Money value={this.props.affiliate.Balance} currency={currency} /></LabelValue>
                            <LabelValue title='Commission '><Money value={this.state.commissions} currency={currency} /></LabelValue>
                        </div>
                    </Card>
                </div>
                <div className='centrum-flex stretch'>
                    <Card>
                        <div className='margin'>
                            <KPIRangeSelector context={this.kpiRangeContext} title='Filter' />
                        </div>
                        <div className='centrum-flex stretch'>
                            <Card caption="Summary">
                                <div className='centrum-flex stretch'>
                                    <div className='centrum-flex vertical'>
                                        <div style={{ cursor: 'pointer' }}>
                                            <KPICard context={this.kpiRangeContext} title='New Players' indicators={['Players']} url={'/api/dashboard/affiliate/players/' + affiliateId} icon={<Icon icon={'user-alt'} />} />
                                        </div>
                                        <KPICard context={this.kpiRangeContext} title='Deposit' indicators={['Deposits']} url={'/api/dashboard/affiliate/deposits/' + affiliateId} type='money' icon={<Icon icon={'hand-holding-usd'} />} />
                                    </div>
                                    <div className='centrum-flex vertical'>
                                        <KPICard context={this.kpiRangeContext} title='GGR' indicators={['Bets', 'Wins']} url={'/api/dashboard/affiliate/ggr/' + affiliateId} type='money' icon={<Icon icon={'coins'} />} />
                                        <KPICard context={this.kpiRangeContext} title='Withdraw' indicators={['Withdraws']} url={'/api/dashboard/affiliate/withdraws/' + affiliateId} type='money' icon={<Icon icon={'money-check-alt'} />} />
                                    </div>
                                </div>
                                {/* <div className='centrum-flex stretch marginTop'>
                                    <Card caption='Player Bet/Win/GGR' menu={<RangeDropdown onSelected={(range) => this.setState({ revenueChart: range })} selected={this.state.revenueChart} />}>
                                        <AffiliateRevenueChart key={this.state.revenueChart} range={this.state.revenueChart} id={affiliateId} />
                                    </Card>
                                    <Card caption='Player Deposit/Withdraw' menu={<RangeDropdown onSelected={(range) => this.setState({ transactionsChart: range })} selected={this.state.transactionsChart} />}>
                                        <AffiliateTransactionChart key={this.state.transactionsChart} range={this.state.transactionsChart} id={affiliateId} />
                                    </Card>
                                </div> */}
                            </Card>
                        </div>
                    </Card>
                </div>


            </div>
        </React.Fragment>;
    }
}
