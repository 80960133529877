import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Search } from '../../../../../Centrum/Search/Search';
import { apiResult, application, Endpoints } from '../../../../../Application/Application';
import { lang } from '../../../../../Centrum/Localization/lang';
import { Tab } from '../../../../../Centrum/Tab/Tab';
import { CentrumForm, SaveButtons } from '../../../../../Centrum/CentrumForm/CentrumForm';
import { toPascalCase } from '../../../../../Centrum/helpers';
import { ActionTypes, getAction } from '../../../../../Centrum/Common/ActionManager';
import { depositPlayer, playerTags, withdrawPlayer } from '../../Actions/PlayerActions';
import { newPlayer, playerModel, searchPlayerModel } from './models';
import { getStore } from '../../../../../..';


const tabButtons = [
    {
        name: 'search',
        title: 'Search Players'
    },
    {
        name: 'add',
        title: 'Create / Modify Player'
    },
    {
        name: 'profile',
        title: 'Player Profile'
    }
];


const toggleEnableDisablePlayer = (player) => {
    console.log(player);
};


const RetailPlayers = (props) => {
    var tabs = null;
    const [player, setPlayer] = useState(null);
    const savePlayer = (data) => {
        data.retailId = props.retail.Id;
        application().resolve(null, data.Id ? Endpoints.UpdateRetailPlayer : Endpoints.CreateRetailPlayer, data, 'saving please wait', 'unable to save player').then((result) => {
            setPlayer(toPascalCase(result.result));
            application().snackbar.open('Player saved');
        });
    };

    const getPlayer = (id) => {
        return application().resolve(null, `${Endpoints.GetRetailPlayer}/${id}`, null, 'please wait', 'unable to get player');
    };

    const addModifyPlayer = () => {
        return <CentrumForm model={newPlayer}
            data={player ? player : {}}
            className='vertical' buttons={SaveButtons} onSubmit={(qb) => {
                if (player) qb.Id = player.Id;
                if (qb.Birthday) {
                    qb.Birthday = qb.Birthday.split(' ')[0];
                }
                savePlayer(qb);
            }} />;
    };

    const onActionClicked = (action, items) => {
        const item = items[0];
        switch (action.type) {
            case ActionTypes.ModifyRetailPlayer:
                getPlayer(item.Id).then((result) => {
                    tabs.selectByKey('add');
                    setPlayer(toPascalCase(result.result));
                });
                break;
            case ActionTypes.DisableEnableRetailPlayer:
                toggleEnableDisablePlayer(item);
                break;
            case ActionTypes.DepositRetailPlayer:
                item.retailId = props.retail.Id;
                depositPlayer(item, (result) => {
                    item.Balance = result.balance;
                    var copy = Object.assign({}, props.retail);
                    copy.Balance = result.retailBalance;
                    getStore().dispatch({
                        type: '_retail_FULFILLED',
                        payload: copy
                    });

                });
                break;
            case ActionTypes.WithdrawRetailPlayer:
                item.retailId = props.retail.Id;
                withdrawPlayer(item, (result) => {
                    item.Balance = result.balance;
                    var copy = Object.assign({}, props.retail);
                    copy.Balance = result.retailBalance;
                    getStore().dispatch({
                        type: '_retail_FULFILLED',
                        payload: copy
                    });
                });
                break;
            case ActionTypes.PlayerTags:
                item.retailId = props.retail.Id;
                playerTags({ Id: item.Id, Tag: item.Tag }, () => {
                });
                break;
        }
    };

    const onRenderAction = (action, selection) => {
        if (selection.length == 0) action;
        var item = selection[0];
        if (item === undefined) return action;
        switch (action.type) {
            case ActionTypes.DisableEnableRetailPlayer:
                return item.Enabled ? { caption: lang('Disable'), icon: 'disable', enabled: true } : { caption: lang('Enable'), icon: 'enable', enabled: true };
        }
        return action;

    };


    return <Tab buttons={tabButtons} ref={(r) => tabs = r}>
        <Search
            model={searchPlayerModel}
            resultModel={playerModel}
            exportToExcel={true}
            actions={getAction(ActionTypes.ListRetailPlayers).convertToItems()}
            onActionClicked={(action, items) => onActionClicked(action, items)}
            onRenderAction={(action, selection) => onRenderAction(action, selection)}
            data={props.players}
            onSubmit={(data, qb) => {
                return apiResult('retailPlayers', `${Endpoints.ListRetailPlayers}/${props.retail.Id}`,
                    qb, 'fetching players', 'unable to fetch players');
            }}
        />
        <div key='add'>
            {addModifyPlayer()}
        </div>
    </Tab>;
};

RetailPlayers.propTypes = {
    retail: PropTypes.object,
    players: PropTypes.objcet
};



const mapStateToProps = state => {
    return {
        retail: toPascalCase(state.data.retail ? state.data.retail : {}),
        players: state.data.retailPlayers
    };
};
const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(RetailPlayers);
export { hoc as RetailPlayers };