

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../Centrum/Card/Card';
import { CurrencyMinMaxConfiguration } from '../../../Promotions/Components/CurrencyMinMaxConfiguration';
import { Currencies, ProviderGameTypes } from '../../../../Centrum/_models/ModelEnums';
import { Button } from '../../../../Controls/Buttons/Button';
import { Form } from '../../../../Controls/Form/Form';
import { application, Endpoints } from '../../../../Application/Application';
import { Icon } from '../../../../Controls/Icon/Icon';

// COMPONENT
export class CasinoLimitsGameTypes extends React.Component {
    constructor(props) {
        super(props);

        var state = Object.assign({}, props);
        state.selectedCurrency = window.user.DefaultCurrency ?? 'USD';
        state.limits = props.limits && Object.keys(props.limits).length > 0 ? { ...props.limits } : {
            general: {},
            groups: {}
        };

        this.state = state;
    }

    componentDidMount() {
        application().resolve(null, Endpoints.GetPlayerGroups, {}, 'loading player groups', 'unable to load player groups').then((result) => {
            var groups = {};
            groups[0] = 'Select a group for configuration';
            result.result.map(x => {
                groups[x.Id] = x.Name;
            });

            var types = [-1];
            var state = this.state;
            state.games = [
                { value: -1, display: 'All' }
            ];

            this.props.gameTypes.map(gameType => {
                types.push(gameType);
                state.games.push({
                    value: gameType,
                    display: ProviderGameTypes[gameType]
                });
            });

            Object.keys(Currencies).map(currency => {
                state.limits.general[currency] = this.createEmptyData(state.limits.general, currency, types);
            });

            state.types = types;
            const copy = Object.assign(state, { groups: groups });
            this.setState(copy);
        });
    }

    createEmptyData(target, currency, types) {
        if (!target.hasOwnProperty(currency)) {
            target[currency] = [];
        }


        var data = [...target[currency]];

        var row = {
            GameType: -1,
            MinBet: 0,
            MaxBet: 0,
            MaxPayout: 0,
            BetStop: false
        };

        if (!data.find(x => x.GameType == -1)) {
            data.push(row);
        }

        types.map(x => {
            if (!data.find(y => y.GameType == x)) {
                data.push({
                    GameType: x,
                    MinBet: 0,
                    MaxBet: 0,
                    MaxPayout: 0,
                    BetStop: false
                });
            }
        });

        data.sort((a, b) => a.GameType - b.GameType);

        return data;
    }


    selectGroup(group) {
        var limits = Object.assign({}, this.state.limits);

        Object.keys(Currencies).map(currency => {
            if (!limits.groups.hasOwnProperty(group)) limits.groups[group] = {};
            limits.groups[group][currency] = this.createEmptyData(limits.groups[group], currency, this.state.types);
        });
        this.setState({ limits: limits, selectedGroup: group });
    }

    defaultGroupLimitsData() {
        var data = [

        ];

        var row = {
            GameType: -1,
            MinBet: 0,
            MaxBet: 0,
            MaxPayout: 0,
            BetStop: false
        };
        data.push(row);
        return data;
    }

    clearData(target) {
        Object.keys(target).map(currency => {
            var data = [];
            target[currency].map(x => {
                if (x.MinBet || x.MaxBet || x.MaxPayout || x.BetStop) {
                    data.push(x);
                }
            });
            if (data.length == 0) {
                delete target[currency];
            } else {
                target[currency] = data;
            }
        });
    }

    getData() {
        var copy = structuredClone(this.state.limits);
        if (this.state.selectedGroup) {
            this.clearData(copy.groups[this.state.selectedGroup]);
            if (Object.keys(copy.groups[this.state.selectedGroup]).length == 0) {
                delete copy.groups[this.state.selectedGroup];
            }
        }
        this.clearData(copy.general);
        return copy;
    }

    clearSelectedGroup() {
        var limits = this.state.limits;
        if (this.groupsSelect) {
            this.groupsSelect.selectedIndex = 0;
        }
        delete limits.groups[this.state.selectedGroup];
        this.setState({ selectedGroup: null });
    }

    render() {
        if (!this.state.limits || !this.state.games) return <></>;
        return <React.Fragment>
            <Card>
                <CurrencyMinMaxConfiguration
                    ref={(r) => this.betLimitsSettings = r}
                    selectedCurrency={this.state.selectedCurrency}
                    data={this.state.limits.general}
                    newRow={false}
                    deleteEnabled={false}
                    model={
                        [
                            {
                                name: 'GameType',
                                display: 'Game Type',
                                type: 'select',
                                readOnly: true,
                                values: this.state.games,
                                formatter: (value) => {
                                    return this.state.games.find(x => x.value == value)?.display ?? 'All';
                                }
                            },
                            {
                                name: 'MinBet',
                                display: 'Min Stake',
                                type: 'number'
                            },
                            {
                                name: 'MaxBet',
                                display: 'Max Stake',
                                type: 'number'
                            },
                            {
                                name: 'MaxPayout',
                                display: 'Max Payout',
                                type: 'number'
                            },
                            {
                                name: 'BetStop',
                                display: 'Bet Stop',
                                type: 'bool'
                            }
                        ]
                    }

                />
            </Card>
            <Card caption={'Player groups'}
                menu={this.state.selectedGroup && <Icon icon='trash' className='margin-top' onClick={() => this.clearSelectedGroup()} />}
            >
                <Form>
                    <div className='row'>
                        {
                            this.state.groups &&
                            <div className='form-group'>
                                <select ref={(r) => this.groupsSelect = r} onChangeCapture={(e) => {
                                    var id = parseInt(e.target.value);
                                    this.selectGroup(id);
                                }}>
                                    {
                                        Object.keys(this.state.groups).map(groupId => {
                                            return <option value={groupId} key={groupId}>{this.state.groups[groupId]}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        }
                    </div>
                </Form>
            </Card>
            {this.state.selectedGroup && <React.Fragment>
                <Card>
                    <CurrencyMinMaxConfiguration
                        key={this.state.selectedGroup}
                        data={this.state.limits.groups[this.state.selectedGroup]}
                        newRow={false}
                        deleteEnabled={false}
                        model={
                            [
                                {
                                    name: 'GameType',
                                    display: 'Game Type',
                                    type: 'select',
                                    readOnly: true,
                                    values: this.state.games,
                                    formatter: (value) => {
                                        return this.state.games.find(x => x.value == value)?.display ?? 'All';
                                    }
                                },
                                {
                                    name: 'MinBet',
                                    display: 'Min Stake',
                                    type: 'number'
                                },
                                {
                                    name: 'MaxBet',
                                    display: 'Max Stake',
                                    type: 'number'
                                },
                                {
                                    name: 'MaxPayout',
                                    display: 'Max Payout',
                                    type: 'number'
                                },
                                {
                                    name: 'BetStop',
                                    display: 'Bet Stop',
                                    type: 'bool'
                                }
                            ]
                        }
                        ref={(r) => this.payoutRules = r} />
                </Card>
            </React.Fragment>
            }
            {this.props.saveEnabled &&
                <Card className='margin-top'>
                    <div className='centrum-flex'>
                        <Button title='Save' type='submit' className='margin alignRight' />
                    </div>
                </Card>
            }
        </React.Fragment >;
    }
}

CasinoLimitsGameTypes.defaultProps = {
    title: '',
    saveEnabled: true,
    gameTypes: [],
    selectedCurrency: 'USD',
    limits: {}
};

CasinoLimitsGameTypes.propTypes = {
    title: PropTypes.string,
    gameTypes: PropTypes.object,
    saveEnabled: PropTypes.bool,

    selectedCurrency: PropTypes.string,
    limits: PropTypes.object
};

