import React from 'react';
import PropTypes from 'prop-types';
import { InputTypes } from './CentrumInput';
import { DateTimePicker } from './Pickers/DateTimePicker';
import { lang } from '../../Localization/lang';




export class DateTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    now() {
        let now = new Date();
        var day = now.getDate(now);
        var month = now.getMonth(now) + 1;
        var year = now.getFullYear(now);
        return {
            day: day,
            month: month,
            year: year
        };
    }


    onKeyDown(event) {
        if (event.keyCode === 114 && this.props.onQueryBuilderOpen !== null) {
            this.props.onQueryBuilderOpen();
            event.preventDefault();
        }
    }


    onInput(e) {
        var dateTimeSplit = e.target.value.split(' ');
        var year = new Date().getFullYear();
        var month = new Date().getMonth();
        var day = new Date().getDate();

        var hours = new Date().getHours();
        var minutes = new Date().getMinutes();

        var dateOnly = day + '/' + month + '/' + year;

        if (dateTimeSplit.length == 2) {
            var hoursOnly = dateTimeSplit[1].split(':');

            if (hoursOnly.length > 0) {
                hours = hoursOnly[0];

                if (hoursOnly.length > 1) {
                    minutes = hoursOnly[1];
                }
            }
        } else {
            // only datetime
            dateOnly = dateTimeSplit[0].split('/');

            if (dateOnly.length > 0) {
                day = dateOnly[0];
                month = dateOnly[1];
                year = dateOnly[2];
            }
        }

        var dateString = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
        var dateConverted = new Date(dateString);
        if (dateConverted != 'Invalid Date') {
            this.datetimePickerRef.goToDate({
                year: year,
                month: month,
                day: day,
                hours: hours,
                minutes: minutes
            });
            this.props.onChange({
                target: {
                    value: dateString
                }
            });
        }
    }

    onBlur(e) {
        let element = e.target;
        element.type = 'text';
        var input = element.value;
        var values = [];
        var source = input;
        var time = '';
        var hasTime = false;
        if (this.state.field.type !== InputTypes.date) {
            var valuesWithTime = input.split(' ');
            source = valuesWithTime[0];
            hasTime = true;
            time = valuesWithTime[1];
            if (time !== undefined) {
                if ((time.split(':')) < 2) {
                    hasTime = false;
                    time = '';
                }
            } else {
                hasTime = false;
            }
        }

        values = source.split('/').map(function (v) {
            return v.replace(/\D/g, '');
        });

        var output = '';

        if (values.length == 3) {
            var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
            var day = parseInt(values[0]);
            var month = parseInt(values[1]) - 1;
            var d = new Date(year, month, day);
            if (!isNaN(d)) {
                var dates = [d.getDate(), d.getMonth() + 1, d.getFullYear()];
                output = dates.map(function (v) {
                    v = v.toString();
                    return v.length == 1 ? '0' + v : v;
                }).join('/');
            }
        }

        if (hasTime) {
            output += ' ' + time;
        }

        element.value = output;
    }


    onDateTimePicked(value) {
        let field = this.state.field;
        field.value = value;
        this.input.value = this.formatValue(value);
        this.setState({ field: field });
    }

    formatValue(value) {
        let field = this.state.field;
        //let acceptEmpty = field.acceptEmptyValues;
        let hasHours = field.type === InputTypes.dateTime;

        if (value === '') return;

        var dt = new Date(value);
        value = (dt.getDate()).toString().padStart(2, '0') + '/' + ((dt.getMonth() + 1).toString().padStart(2, '0')) + '/' + dt.getFullYear() + (hasHours ? (' ' + (dt.getHours().toString().padStart(2, '0')) + ':' + (dt.getMinutes().toString().padStart(2, '0'))) : '');

        return value;
    }

    dateFromValue(value) {
        if (value === '' || value === undefined || value === null) {
            return null;
        }
        try {
            var dt = new Date(value);
            if (dt === 'Invalid Date') {
                console.log('invalid date');
            }
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    openDateTimePicker() {
        this.datetimePickerRef.openPicker();
    }

    render() {
        let field = this.state.field;
        let display = this.props.field.display === undefined ? this.props.field.name : this.props.field.display;
        let value = field.value;

        //todo fix later
        value = this.formatValue(value);
        let date = this.dateFromValue(value);

        if (field.readonly) {
            return (
                <div className='form-element readonly'>
                    <span>{lang(display)}</span>
                    <input
                        name={field.name}
                        ref={(input) => this.input = input}
                        disabled={true}
                        defaultValue={value}
                        readOnly={true}
                    />
                </div>

            );
        }






        return (
            <div className='form-element'>
                <span>{lang(display)}</span>
                <DateTimePicker date={date} onPicked={this.onDateTimePicked.bind(this)} ref={(r) => this.datetimePickerRef = r} showTime={this.props.field.type === InputTypes.dateTime} />
                <input
                    name={field.name}
                    ref={(input) => this.input = input}
                    disabled={!this.props.enabled}
                    onInput={this.onInput.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    onKeyDown={this.onKeyDown.bind(this)}
                    onClick={this.openDateTimePicker.bind(this)}
                    defaultValue={value}
                />
                {this.props.children}
            </div>
        );
    }
}

DateTime.defaultProps = {
    enabled: true,
    onChange: null,
    onQueryBuilderOpen: null,
    style: {}
};

DateTime.propTypes = {
    field: PropTypes.object,
    enabled: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object,
    onQueryBuilderOpen: PropTypes.func,
    onChange: PropTypes.func
};