import './_dateTime.scss';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';


export class DateTime extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    render() {
        var classNames = ['dateTime', this.props.size];
        classNames.push(this.props.className);
        var date = this.props.date;
        var converted = null;
        if (!date || date == '0001-01-01T00:00:00') {
            converted='-';
        } else {
            //1/1/1, 24:00:00
            converted = new Date(this.props.date);
            if (converted.getFullYear() == 1) {
                converted = '-';
            } else {
                converted = converted.toLocaleString('en-Gb', { hour12: false });
            }
        }
        return <div className={classNames.join(' ')}>{converted}</div>;
    }
}

DateTime.defaultProps = {
    className: null,
    size: '',
    date: new Date()
};

DateTime.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    date: PropTypes.string.isRequired
};
