// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';


export const ChartRanges = {
    'Today': 0,
    'WTD': 1,
    'MTD': 2,
    'QTD': 3,
    'YTD': 4,
    'Past24Hours': 5,
    'Past7Days': 6,
    'Past15Days': 7,
    'Past30Days': 8,
    'Custom': 9
};


export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];


export class CentrumLineChart extends React.Component {
    constructor(props) {
        super(props);

    }

   
    render() {
        return <div/>;
    }
}


CentrumLineChart.defaultProps = {
};

CentrumLineChart.propTypes = {
    className: PropTypes.string
};

