import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Panel } from '../../../../Centrum/Card/Card';
import { Form } from '../../../../Controls/Form/Form';
import { FormInput } from '../../../../Controls/Form/FormInput';
import { CurrencyMinMaxConfiguration } from '../../Components/CurrencyMinMaxConfiguration';
import { Button } from '../../../../Controls/Buttons/Button';
import { EditableTable } from '../../../../Controls/Table/Editable/EditableTable';
import { Tab } from '../../../../Centrum/Tab/Tab';
import { API, SportEndpoints } from '../../../../../v2/Lib/Api/Api';
import { Icon } from '../../../../../v2/Components/Icon/Icon';
import { lang } from '../../../../Centrum/Localization/lang';




const rangeModel = {
    fields: [
        {
            name: 'minimumSelection',
            display: 'Minimum Bets',
            type: 'number',
            editable: true
        },
        {
            name: 'from',
            display: 'Odds Min',
            type: 'number',
            editable: true
        },
        {
            name: 'to',
            display: 'Odds Max',
            type: 'number',
            editable: true
        },
        {
            name: 'bonus',
            display: 'Bonus',
            type: 'number',
            editable: true
        }
    ]
};

const OddsPayoutConfiguration = (props) => {
    const [configuration, setConfiguration] = useState(props.configuration);

    useEffect(() => {
        var copy = Object.assign([], props.configuration);
        copy.map((x, index) => {
            x.__id = index;
        });
        setConfiguration(copy);
    }, []);


    const addNew = () => {
        var copy = [...configuration];
        copy.push({ __id: Date.now(), minimumSelection: 0, from: 0, to: 0, bonus: 0 });
        setConfiguration(copy);
        props.onUpdate(copy);
    };

    const remove = (data) => {
        var copy = [...configuration];
        copy = copy.filter(x => x.__id != data.__id);
        setConfiguration(copy);
        props.onUpdate(copy);
    };


    if (!configuration) return <></>;

    return <React.Fragment>
        <EditableTable key={configuration} data={configuration} model={rangeModel} buttons={(row) => {
            return <Button title='Delete' onClick={() => remove(row)} />;
        }} />
        <div className='centrum-flex margin-top marginBottom padding'>
            <Button title='Add New' className='button alignRight' onClick={() => addNew()} />
        </div>
    </React.Fragment>;
};

OddsPayoutConfiguration.propTypes = {
    configuration: PropTypes.array,
    onUpdate: PropTypes.func
};



export const SportSelection = (props) => {
    const [sports, setSports] = useState(null);
    const [configuration, setConfiguration] = useState(props.configuration ?? {});

    useEffect(() => {
        API.post(SportEndpoints.Categories, null).then((result) => {
            result.result.map(x => {
                if (configuration.hasOwnProperty(x.id)) {
                    x.enabled = true;
                } else {
                    x.enabled = false;
                }
            });
            setSports(result.result);
        });
    }, []);


    useEffect(() => {
        props.onChange(configuration);
    }, [configuration]);

    const selectAll = () => {
        var copy = { ...configuration };
        sports.map(x => {
            copy[x.id] = true;
        });
        setConfiguration(copy);
    };

    const unselectAll = () => {
        setConfiguration({});
    };

    return <div className='flex vertical'>
        <div className='flex padding align-right'>
            <Button title='Select All' onClick={() => {
                selectAll();
            }} />
            <Button title='Remove All' onClick={() => {
                unselectAll();
            }} />
        </div>
        <div className='list border-top'>
            {sports?.map(x => {
                return <div className={`flex gap-5 align-start padding surface low align-stretch${x.enabled ? ' active' : ''}`} key={x} onClick={() => {
                    var copy = { ...configuration };
                    if (copy.hasOwnProperty(x.id)) {
                        delete copy[x.id];
                    } else {
                        copy[x.id] = x;
                    }
                    setConfiguration(copy);
                }}>
                    <span>{x.name}</span>
                    <div className='flex align-right'>
                        <Icon icon={configuration.hasOwnProperty(x.id) ? 'check-square' : 'square'} type='far' />
                    </div>
                </div>;
            })}
        </div>
    </div>;
};

SportSelection.propTypes = {
    configuration: PropTypes.object,
    onChange: PropTypes.func
};



export const LeagueSelection = (props) => {
    const [sports, setSports] = useState(null);
    const [regions, setRegions] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [selectedSport, selectSport] = useState(null);
    const [selectedRegion, selectRegion] = useState(null);
    const [selectedLeague, selectLeague] = useState(null);

    const [configuration, setConfiguration] = useState(props.configuration ?? {});

    useEffect(() => {
        API.post(SportEndpoints.Categories, null).then((result) => {
            setSports(result.result);
            selectSport(result.result[0])
        });
    }, []);

    useEffect(() => {
        if (!selectedSport) return;
        API.post(`${SportEndpoints.Regions}/${selectedSport.id}`).then((result) => {
            setRegions(result.result);
            selectRegion(result.result[0]);
        });
    }, [selectedSport]);

    useEffect(() => {
        if (!selectedRegion) return;
        API.post(`${SportEndpoints.Leagues}/${selectedSport.id}/${selectedRegion.id}`).then((result) => {
            if (result.result.length > 0) {
                selectLeague(result.result[0]);
                setLeagues(result.result.sort((a, b) => a.name > b.name ? 1 : -1));
            } else {
                selectLeague(null);
                setLeagues([]);
            }
        });
    }, [selectedRegion]);

    useEffect(() => {
        props.onChange(configuration);
    }, [configuration]);


    return <div className='flex vertical'>
        <div className='flex padding gap-10'>
            <div className='flex gap-5'>
                <label>{lang('Sport')}</label>
                <select onChangeCapture={(e) => selectSport(sports.find(x => x.id == parseInt(e.target.value)))}>
                    {sports?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            <div className='flex gap-5'>
                <label>{lang('Region')}</label>

                <select onChangeCapture={(e) => selectRegion(regions.find(x => x.id == parseInt(e.target.value)))}>
                    {regions?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            <div className='flex gap-5'>
                <label>{lang('League')}</label>
                <select onChangeCapture={(e) => selectLeague(leagues.find(x => x.id == parseInt(e.target.value)))}>
                    {leagues?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            {
                selectedLeague && <Button title='Add' onClick={() => {
                    var copy = { ...configuration };
                    copy[selectedLeague.id] = {
                        id: selectedLeague.id,
                        name: selectedLeague.name,
                        region: selectedRegion,
                        sport: selectedSport
                    };
                    setConfiguration(copy);
                }} />
            }
        </div>
        <div className='list border-top'>
            {Object.values(configuration).map(x => {
                return <div className={`flex gap-5 align-start padding surface low align-stretch${x.enabled ? ' active' : ''}`} key={x} onClick={() => {
                    var copy = { ...configuration };
                    if (copy.hasOwnProperty(x.id)) {
                        delete copy[x.id];
                    } else {
                        copy[x.id] = x;
                    }
                    setConfiguration(copy);
                }}>
                    <div className='flex gap-5'><span>{x.sport.name}</span> / <span>{x.region.name}</span> / <span>{x.name}</span></div>
                    <div className='flex align-right'>
                        <Icon icon={configuration.hasOwnProperty(x.id) ? 'check-square' : 'square'} type='far' />
                    </div>
                </div>;
            })}
            {Object.values(configuration).length == 0 && <div className='flex padding'><span>{lang('Add leagues using filters')}</span></div>}
        </div>
    </div>;
};

LeagueSelection.propTypes = {
    configuration: PropTypes.object,
    onChange: PropTypes.func
};




export const EventSelection = (props) => {
    const [sports, setSports] = useState(null);
    const [regions, setRegions] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [events, setEvents] = useState(null);
    const [selectedSport, selectSport] = useState(null);
    const [selectedRegion, selectRegion] = useState(null);
    const [selectedLeague, selectLeague] = useState(null);
    const [selectedEvent, selectEvent] = useState(null);

    const [configuration, setConfiguration] = useState(props.configuration ?? {});

    useEffect(() => {
        API.post(SportEndpoints.Categories, null).then((result) => {
            setSports(result.result);
            selectSport(result.result[0])
        });
    }, []);

    useEffect(() => {
        if (!selectedSport) return;
        API.post(`${SportEndpoints.Regions}/${selectedSport.id}`).then((result) => {
            setRegions(result.result);
            selectRegion(result.result[0]);
        });
    }, [selectedSport]);

    useEffect(() => {
        if (!selectedRegion) return;
        API.post(`${SportEndpoints.Leagues}/${selectedSport.id}/${selectedRegion.id}`).then((result) => {
            if (result.result.length > 0) {
                selectLeague(result.result[0]);
                setLeagues(result.result.sort((a, b) => a.name > b.name ? 1 : -1));
            } else {
                selectLeague(null);
                setLeagues([]);
            }
        });
    }, [selectedRegion]);


    useEffect(() => {
        if (!selectedLeague) return;
        API.post(SportEndpoints.Events, {
            sportId: selectedSport.id,
            regionId: selectedRegion.id,
            leagueId: selectedLeague.id,
            startDate: new Date()
        }).then((result) => {
            if (result.result.length > 0) {
                selectEvent(result.result[0]);
                setEvents(result.result.sort((a, b) => a.name > b.name ? 1 : -1));
            } else {
                selectEvent(null);
                setEvents([]);
            }
        });
    }, [selectedLeague]);

    useEffect(() => {
        props.onChange(configuration);
    }, [configuration]);


    return <div className='flex vertical'>
        <div className='flex padding gap-10'>
            <div className='flex gap-5'>
                <label>{lang('Sport')}</label>
                <select onChangeCapture={(e) => selectSport(sports.find(x => x.id == parseInt(e.target.value)))}>
                    {sports?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            <div className='flex gap-5'>
                <label>{lang('Region')}</label>

                <select onChangeCapture={(e) => selectRegion(regions.find(x => x.id == parseInt(e.target.value)))}>
                    {regions?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            <div className='flex gap-5'>
                <label>{lang('League')}</label>
                <select onChangeCapture={(e) => selectLeague(leagues.find(x => x.id == parseInt(e.target.value)))}>
                    {leagues?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            <div className='flex gap-5'>
                <label>{lang('Events')}</label>
                <select onChangeCapture={(e) => selectEvent(events.find(x => x.id == parseFloat(e.target.value)))}>
                    {events?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            {
                selectedEvent && <Button title='Add' onClick={() => {
                    var copy = { ...configuration };
                    copy[selectedEvent.id] = {
                        id: selectedEvent.id,
                        date: selectedEvent.date.indexOf('Z') > 0 ? selectedEvent.date : selectedEvent.data + 'Z',
                        name: selectedEvent.name,
                        league: selectedLeague,
                        region: selectedRegion,
                        sport: selectedSport,
                    };
                    setConfiguration(copy);
                }} />
            }
        </div>
        <div className='list border-top'>
            {Object.values(configuration).map(x => {
                return <div className={`flex gap-5 align-start padding surface low align-stretch${x.enabled ? ' active' : ''}`} key={x} onClick={() => {
                    var copy = { ...configuration };
                    if (copy.hasOwnProperty(x.id)) {
                        delete copy[x.id];
                    } else {
                        copy[x.id] = x;
                    }
                    setConfiguration(copy);
                }}>
                    <div className='flex gap-5'>
                        <span>{new Date(x.date).toLocaleString('en-Gb', { hours12: false })}</span>
                        <span>{x.sport.name}</span> / <span>{x.region.name}</span> / <span>{x.league.name}</span> / <span>{x.name}</span></div>
                    <div className='flex align-right'>
                        <Icon icon={configuration.hasOwnProperty(x.id) ? 'check-square' : 'square'} type='far' />
                    </div>
                </div>;
            })}
            {Object.values(configuration).length == 0 && <div className='flex padding'><span>{lang('Add leagues using filters')}</span></div>}
        </div>
    </div>;
};

EventSelection.propTypes = {
    configuration: PropTypes.object,
    onChange: PropTypes.func
};




export const MarketSelection = (props) => {
    const [sports, setSports] = useState(null);
    const [selectedSport, selectSport] = useState(null);
    const [selectedMarket, selectMarket] = useState(null);
    const [markets, setMarkets] = useState(null);


    const [configuration, setConfiguration] = useState(props.configuration ?? {});

    const selectionRef = React.createRef();
    const lineRef = React.createRef();
    const marginRef = React.createRef();

    useEffect(() => {
        API.post(SportEndpoints.Categories, null).then((result) => {
            setSports(result.result);
            selectSport(result.result[0])
        });
    }, []);

    useEffect(() => {
        if (!selectedSport) return;
        lineRef.current.value = '';
        selectionRef.current.value = '';

        API.post(`${SportEndpoints.Markets}/${selectedSport.id}`).then((result) => {
            if (result.result.length) {
                selectMarket(result.result[0]);
            }

            setMarkets(result.result);
        });
    }, [selectedSport]);


    useEffect(() => {
        if (!selectedSport) return;
        lineRef.current.value = '';
        selectionRef.current.value = '';
    }, [selectedMarket]);

    useEffect(() => {
        props.onChange(configuration);
    }, [configuration]);


    return <div className='flex vertical'>
        <div className='flex padding gap-10'>
            <div className='flex gap-5'>
                <label>{lang('Sport')}</label>
                <select onChangeCapture={(e) => selectSport(sports.find(x => x.id == parseInt(e.target.value)))}>
                    {sports?.map(x => <option value={x.id}>{x.name}</option>)}
                </select>
            </div>
            <div className='flex gap-5'>
                <label>{lang('Markets')}</label>
                <select onChangeCapture={(e) => selectMarket(markets.find(x => x.id == parseInt(e.target.value)))}>
                    {markets?.map(x => <option value={x.id}>[{x.id}] {x.name}</option>)}
                </select>
                <span>{lang('Selection')}</span>
                <input ref={selectionRef} type='text' name='selection' />
                <span>{lang('Line')}</span>
                <input ref={lineRef} type='text' name='line' />
                {props.margin && <span>{lang('Margin')}</span>}
                {props.margin && <input ref={marginRef} type='text' name='margi ' />}
            </div>
            {
                selectedMarket && <Button title='Add' onClick={() => {
                    var copy = { ...configuration };
                    var payload = {
                        id: selectedMarket.id,
                        name: selectedMarket.name,
                        sport: selectedSport,
                        selection: selectionRef.current.value,
                        line: lineRef.current.value
                    };

                    if (props.margin) {
                        var margin = marginRef.current.value;;
                        margin = parseFloat(margin);
                        if (isNaN(margin)) return;
                        if (margin <= 0) return;
                        payload.margin = margin;
                    }

                    if (props.margin && !payload.margin) {
                    }

                    copy[selectedMarket.id + ':' + selectionRef.current.value + ':' + lineRef.current.value] = payload;
                    setConfiguration(copy);
                }} />
            }
        </div>
        <table>
            <tr>
                <th>Sport</th>
                <th>Market Id</th>
                <th>Market Name</th>
                <th>Selection</th>
                <th>Line</th>
                {props.margin && <th>Margin</th>}
                <th></th>
            </tr>
            {
                Object.values(configuration).map(x => {
                    return <tr className={`${x.enabled ? ' active' : ''}`} key={x}>
                        <td>{x.sport.name}</td>
                        <td>{x.id}</td>
                        <td>{x.name}</td>
                        <td>{x.selection && <span>{x.selection}</span>}</td>
                        <td>{x.line && <span>{x.line}</span>}</td>
                        {x.margin && <td>{x.margin}</td>}
                        <td><Button title='Remove' onClick={() => {
                            var copy = { ...configuration };
                            if (copy.hasOwnProperty(x.id + ':' + x.selection + ':' + x.line)) {
                                delete copy[x.id + ':' + x.selection + ':' + x.line];
                            } else {
                                copy[x.id + ':' + x.selection + ':' + x.line] = x;
                            }
                            setConfiguration(copy);
                        }} /></td>
                    </tr>;
                })
            }
            {
                Object.values(configuration).length == 0 && <div className='flex padding'><span>{lang('Add leagues using filters')}</span></div>
            }
        </table>
    </div>;
};

MarketSelection.propTypes = {
    configuration: PropTypes.object,
    margin: PropTypes.bool,
    onChange: PropTypes.func
};



export const SportsBettingRules = (props) => {
    const [configuration, saveConfiguration] = useState(props.promotion.Configuration ? (typeof props.promotion.Configuration === 'object' ? props.promotion.Configuration : JSON.parse(props.promotion.Configuration)) : {
        sportsBettingRules: {
            prematch: false,
            live: false,
            betSlip: {

            }
        }
    });

    const [selectedSlipConfigurationTab, selectSlipConfigurationTab] = useState(0);
    const [rules, setRules] = useState(configuration.sportsBettingRules);


    const payoutConfiguration = rules.payout ? rules.payout : {};
    const stakeConfiguration = rules.stake ? rules.stake : {};
    const slipConfiguration = rules.slip ? rules.slip : [];
    const [sportsConfiguration, setSportsConfiguration] = useState(rules.enabledSports ? rules.enabledSports : {});
    const [leagueConfiguration, setLeagueConfiguration] = useState(rules.enabledLeagues ? rules.enabledLeagues : {});
    const [eventConfiguration, setEventConfiguration] = useState(rules.enabledEvents ? rules.enabledEvents : {});
    const [marketConfiguration, setMarketConfiguration] = useState(rules.enabledMarkets ? rules.enabledMarkets : {});

    const [context, setContext] = useState(null);

    const updateContext = () => {
        setContext({
            save: () => {
                var rulesCopy = {
                    slip: rules.slip,
                    stake: rules.stake,
                    payout: rules.payout,
                    prematch: rules.prematch,
                    live: rules.live,
                    maxSelections: rules.maxSelections,
                    enabledSports: sportsConfiguration,
                    enabledLeagues: leagueConfiguration,
                    enabledEvents: eventConfiguration,
                    enabledMarkets: marketConfiguration
                };

                var copy = Object.assign({}, configuration);
                configuration.sportsBettingRules = rulesCopy;
                saveConfiguration(copy);
                return rulesCopy;
            }
        });
    };

    useEffect(() => {
    }, []);


    useEffect(() => {
        props.context(context);
    }, [context]);

    useEffect(() => {
        updateContext();
    }, [sportsConfiguration]);

    useEffect(() => {
        updateContext();
    }, [leagueConfiguration]);

    useEffect(() => {
        updateContext();
    }, [eventConfiguration]);

    useEffect(() => {
        updateContext();
    }, [marketConfiguration]);

    useEffect(() => {
        updateContext();
    }, [rules]);



    const update = (target, value) => {
        var copy = { ...rules };
        copy[target] = value;
        setRules(copy);
    };


    const getAvailableBetslipConfiguration = () => {
        var buttons = [
            {
                name: 'bets',
                title: 'Odds'
            },
            {
                name: 'sports',
                title: 'Sports'
            },
            {
                name: 'leagues',
                title: 'Leagues'
            },
            {
                name: 'events',
                title: 'Events'
            },
            {
                name: 'markets',
                title: 'Markets'
            }
        ];

        return buttons;
    };


    return <div className='padding'>
        <Panel title='Ticket Types'>
            <Form data={rules}>
                <div className='row'>
                    <FormInput title='Prematch' name='prematch' type={'bool'} value={rules.prematch}
                        onChange={(f, r, value) => {
                            update('prematch', value);
                        }} />
                    <FormInput title='Live' name='live' type={'bool'} onChange={(f, r, value) => {
                        update('live', value);
                    }} />
                </div>
            </Form>
        </Panel>
        <Card caption='Bet Slip Configuration' className='margin-top'>
            <Tab buttons={getAvailableBetslipConfiguration()} onTabSelected={(index) => selectSlipConfigurationTab(index)}>
                {selectedSlipConfigurationTab == 0 && <div className='flex vertical gap-10'>
                    <div className='flex gap-5 padding'><label>{lang('Max Selections')}</label><input type='number' defaultValue={configuration?.sportsBettingRules?.maxSelections} onChange={(e) => {
                        var value = parseInt(e.target.value);
                        update('maxSelections', value);
                    }} /></div>
                    <OddsPayoutConfiguration configuration={slipConfiguration} onUpdate={(data) => {
                        update('slip', data);
                    }} />
                </div>}
                {selectedSlipConfigurationTab == 1 && <SportSelection configuration={sportsConfiguration} onChange={(data) => setSportsConfiguration(data)} />}
                {selectedSlipConfigurationTab == 2 && <LeagueSelection configuration={leagueConfiguration} onChange={(data) => setLeagueConfiguration(data)} />}
                {selectedSlipConfigurationTab == 3 && <EventSelection configuration={eventConfiguration} onChange={(data) => setEventConfiguration(data)} />}
                {selectedSlipConfigurationTab == 4 && <MarketSelection configuration={marketConfiguration} onChange={(data) => setMarketConfiguration(data)} margin={props.margin} />}
            </Tab>
        </Card>
        <Card caption='Stake Configuration' className='margin-top'>
            <CurrencyMinMaxConfiguration
                key={stakeConfiguration}
                data={stakeConfiguration}
                fieldTitles={{ From: 'Min', To: 'Max' }}
                onUpdate={(data) => update('stake', data)} />
        </Card>
        <Card caption='Cashout' className='margin-top'>
            <Form data={rules}>
                <div className='row'>
                    <FormInput title='Allow Cashout' name='cashout' type={'bool'} value={rules.cashout}
                        onChange={(f, r, value) => {
                            update('cashout', value);
                        }} />
                </div>
            </Form>
        </Card>
        <Card caption='Payout Configuration' className='margin-top'>
            <CurrencyMinMaxConfiguration
                key={payoutConfiguration}
                model={[
                    { name: 'Max', display: 'Max', type: 'number' }
                ]}
                data={payoutConfiguration}
                fieldTitles={{ Max: 'Max' }} onUpdate={(data) => update('payout', data)} />
        </Card>
    </div>;
};

SportsBettingRules.propTypes = {
    promotion: PropTypes.object,

    context: PropTypes.func
};

