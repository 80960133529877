import { InputTypes } from '../../../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { currency } from '../../../../../Centrum/Localization/lang';
import { GenderSources, Languages, PlayerFlagsToClass, resolveFlags } from '../../../../../Centrum/_models/ModelEnums';

export const newPlayer = {
    fields: [
        {
            name: 'Code',
            type: InputTypes.text
        },
        {
            name: 'Name',
            type: InputTypes.text
        },
        {
            name: 'Surname',
            type: InputTypes.text
        },
        {
            name: 'Email',
            type: InputTypes.text
        },
        {
            name: 'Username',
            type: InputTypes.text,
        },
        {
            name: 'IdNumber',
            display: 'Id Number',
            type: InputTypes.text,
        },
        {
            name: 'Birthday',
            display: 'Date of birth',
            type: InputTypes.dateTime,
        },
        {
            name: 'Password',
            type: InputTypes.text,
        },
        {
            name: 'Gender',
            type: InputTypes.select,
            values: GenderSources
        },
        {
            name: 'Country',
            type: InputTypes.select,
            valueEndPoint: {
                source: 'countryCodes',
                bindingKey: 'CountryCode',
                bindingText: ['Name']
            }
        },
        {
            name: 'City',
            type: InputTypes.text
        },
        {
            name: 'Address',
            type: InputTypes.text
        },
        {
            name: 'Phone',
            type: InputTypes.text
        },
        {
            name: 'Language',
            type: InputTypes.select,
            values: Languages
        }
    ]
};

export const searchPlayerModel = {
    fields: [
        {
            name: 'Id',
            max: 5,
            type: InputTypes.number,
            identity: true,
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'Username',
            type: InputTypes.text,
            queryBuilderOptions: {
                Exact: false
            }
        },
        {
            name: 'Name',
            type: InputTypes.text
        },
        {
            name: 'Surname',
            type: InputTypes.text
        },
        {
            name: 'IdNumber',
            display: 'Id / Passport',
            type: InputTypes.text,
        },
        {
            name: 'Email',
            display: 'Email',
            type: InputTypes.text,
        },
        {
            name: 'Tag',
            display: 'Tags',
            type: InputTypes.text,
        },
        {
            name: 'Balance',
            type: InputTypes.number,
            max: 32
        },
        {
            name: 'LastLoginIp',
            display: 'Last Login Ip',
            type: InputTypes.text
        }
    ]
};

export const playerModel = {
    fields: [
        {
            name: 'Id',
            max: 5,
            type: InputTypes.number,
            identity: true,
        },
        {
            name: 'Created',
            display: 'Register Date',
            type: InputTypes.date
        },
        {
            name:'Code',
        },
        {
            name: 'Username',
            type: InputTypes.text
        },
        {
            name: 'Name',
            type: InputTypes.text
        },
        {
            name: 'Surname',
            type: InputTypes.text
        },
        {
            name: 'Country',
            type: InputTypes.text
        },
        {
            name: 'City',
            type: InputTypes.text,
            max: 32
        },
        {
            name: 'PlayerFlags',
            display: 'Flags',
            formatter: (val) => {
                return PlayerFlagsToClass(resolveFlags(val));
            },
            type: InputTypes.text
        },
        {
            name: 'Tag',
            display: 'Tags',
            type: InputTypes.text
        },
        {
            name: 'PlayerGroup',
            display: 'Player Group'
        },
        {
            name: 'LastLoginIp',
            display: 'Last Login Ip',
            type: InputTypes.text
        },
        {

            name: 'Balance',
            type: InputTypes.number,
            max: 20,
            formatter: (val, data) => {
                return currency(val, 2, data.Currency);
            },
            rowId: (val, row) => {
                return 'player_balance_' + row.Id + '_' + row.Currency;
            }
        },
        {
            name: 'Enabled',
            type: InputTypes.bool,
            value: true
        }
    ]
};
