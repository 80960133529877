/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../../v2/Components/Controls/Table/Table';
import { API, RiskLevelEndpoints, RiskLevelOffersEndpoints } from '../../../../../v2/Lib/Api/Api';
import { currency } from '../../../../../v2/Lib/Common/currency';
import { lang } from '../../../../Centrum/Localization/lang';
import { SportsTicketTypes } from '../../../../Centrum/_models/ModelEnums';
import { getLookupKey } from '../../RiskLevels/RiskLevelLimitsConfiguration';
import { BetMonitorSocket } from './BetmonitorSocket';

const renderSelection = (selection) => {
    return <div className='flex gap-20'>
        <span className='warning padding-half padding-left padding-right'>{selection.live ? 'inplay' : 'prematch'}</span>
        <div className='flex vertical gap-2'>
            <div className='flex gap-5'>
                <span className='bold'>{selection.selectionName} {selection.specifiers}</span>
                <span>{selection.marketName}</span>
                <span>{selection.eventName}</span>
            </div>
            <div className='flex gap-5'>
                <span>{selection.league}</span>
                -
                <span>{selection.region}</span>
                -
                <span>{selection.sport}</span>
            </div>
        </div>
    </div>;
};

const pendingTickets = {
    fields: [
        {
            name: 'entryDate',
            title: 'Date',
            renderRow: (row) => {
                return new Date(row.entryDate).toLocaleTimeString('en-Gb', { hours12: false });
            }
        },
        {
            name: 'username',
            title: 'Username',
            renderRow: (row) => {
                return row.ticket.username;
            }
        },
        {
            name: 'group',
            title: 'Group',
            renderRow: (row) => {
                return row.ticket.playerGroupName;
            }
        },
        {
            name: 'level',
            title: 'Level',
            renderRow: (row) => {
                var converted = getLookupKey(row.selection.riskLevel);
                const result = converted.map((x, index) => {
                    return <div key={index} className={x}>{x}</div>;
                });

                return <div className='risk-levels flex gap-5'>
                    {result}
                </div>;
            }
        },
        {
            name: 'stake',
            title: 'Stake',
            renderRow: (row) => {
                return currency(row.ticket.amount, row.ticket.currency, 2);
            }
        },
        {
            name: 'possibleWin',
            title: 'Possible Win',
            renderRow: (row) => {
                return currency(row.ticket.maxWinAmount, row.ticket.currency, 2);
            }
        },
        {
            name: 'betType',
            title: 'Bet Type',
            renderRow: (row) => {
                return SportsTicketTypes[row.ticket.ticketType];
            }
        },
        {
            name: 'liability',
            title: 'Event Liability',
            renderRow: (row) => {
                return currency(row.event?.liability, 'EUR', 2, 0);
            }
        },
        {
            name: 'companyLiability',
            title: 'Company Event Liability',
            renderRow: (row) => {
                return currency(row.configuration.liability, 'EUR', 2, 0);
            }
        },
        {
            name: 'selection',
            title: 'Selection',
            renderRow: (row) => {
                return renderSelection(row.selection);
            }
        },
        {
            name: 'score',
            title: 'Score'
        },
        {
            name: 'price',
            title: 'Price',
            renderRow: (row) => {
                return row.selection.price;
            }
        }
    ]
};


const selectionsModel = {
    fields: [
        {
            name: 'leg',
            title: 'Leg',
            renderRow: (row, value, context) => {
                return <div>{context.data.findIndex(x => x.id == row.id) + 1}</div>;
            }
        },
        {
            name: 'type',
            title: 'Type',
            renderRow: (row) => {
                return <span className='warning padding-half padding-left paddin-right'>{row.live ? 'inplay' : 'prematch'}</span>;
            }
        },
        {
            name: 'price',
            title: 'Price',
            renderRow: (row, value, context) => {

                return <input defaultValue={row.price} className='disabled' onKeyUp={(e) => {
                    if (e.key == 'Enter') {
                        var converted = parseFloat(e.target.value);
                        if (!isNaN(converted)) {
                            context.updatePrice(row, converted);
                            e.preventDefault();
                        }
                    }
                }} />;
            }
        },
        {
            name: 'currentPrice',
            title: 'Current Price'
        },
        {
            name: 'name',
            title: 'Name',
            renderRow: (row) => {
                return <span className='bold'>{row.selectionName}{row.specifiers}</span>;
            }
        },
        {
            name: 'market',
            title: 'Market',
            renderRow: (row) => {
                return <span>{row.marketName}</span>;
            }
        },
        {
            name: 'eventName',
            title: 'Event'
        },
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'region',
            title: 'Region'
        },
        {
            name: 'league',
            title: 'League'
        }
    ]
};



export const MonitorTickets = () => {
    const user = window.user;
    const [tickets, setTickets] = useState([]);
    const [selectedTicket, selectTicket] = useState(null);
    const operatorId = user.UserPrivileges.PointOfView;
    const [connected, setConnected] = useState(false);
    const [socket, setSocket] = useState(null);
    const [traderSettings, setTraderSettings] = useState(null);

    const userId = user.Id;

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (!connected) return;
    }, [connected]);

    useEffect(() => {
        if (!socket) return;
        setTraderSettings({
            TicketLifeSpan: 40
        });
        fetchTickets();
    }, [socket]);

    useEffect(() => {
        if (!selectedTicket) return;
        socket.send({ type: 'watch', payload: selectedTicket.id });
    }, [selectedTicket]);

    useEffect(() => {
        if (!traderSettings) return;
        socket.send({ type: 'settings', payload: JSON.stringify(traderSettings) });
    }, [traderSettings]);

    const fetchTickets = () => {
        API.post(`${RiskLevelEndpoints.MonitorTickets}/${operatorId}/${userId}`, {}).then((result) => {
            setTickets(result.result);
        });
    };

    const accept = (ticket) => {
        selectTicket(null);
        API.post(`${RiskLevelOffersEndpoints.Accept}/${operatorId}/${userId}/${ticket.id}`, {}).then((result) => {
            console.log(result.result);
        });
    };

    const reject = (ticket) => {
        selectTicket(null);
        API.post(`${RiskLevelOffersEndpoints.Reject}/${operatorId}/${userId}/${ticket.id}`, {}).then((result) => {
            var copy = [...tickets];
            copy = copy.filter(x => x.id !== ticket.id);
            setTickets(copy);
            console.log(result.result);
        });
    };


    const updateTraderSettings = (target, value) => {
        var copy = { ...traderSettings };
        copy[target] = value;
        setTraderSettings(copy);
    };

    const modify = (ticket) => {
        console.log(ticket);
    };


    const addTicket = (ticket) => {
        var copy = [...tickets];
        copy.push(ticket);
        setTickets(copy);
    };

    const removeTicket = (ticket) => {
        var copy = [...tickets];
        copy = copy.filter(x => x.id !== ticket.id);
        setTickets(copy);
    };

    const recalculate = (copy) => {
        var odds = 1;
        var selections = {};
        Object.values(copy.ticket.selections).map(x => {
            odds *= x.price;
            // json bug
            selections[x.id.toString()] = x;
        });

        copy.ticket.selections = selections;
        copy.ticket.maxOdds = odds;
        copy.ticket.maxWinAmount = odds * copy.ticket.amount;
    };

    const updateStakeAmount = (amount) => {
        var copy = [...tickets];
        copy.find(x => x.id == selectedTicket.id).amount = amount;
        recalculate(copy);
        setTickets(copy);
    };

    const onConnected = (socketContext) => {
        setConnected(true);
        setSocket(socketContext);
    };

    return <div className={`content${connected ? '' : ' disabled'}`}>
        <BetMonitorSocket onConnected={(sc) => onConnected(sc)} onNewTicket={(ticket) => addTicket(ticket)} onRemoveTicket={(ticket) => removeTicket(ticket)} />
        <div className='flex padding surface high border'>
            <div className='flex vertical gap-10'>
                <label>{lang('Settings')}</label>
                <div className='flex gap-10'>
                    <div className='flex gap-5'>
                        <label>{lang('Ticket life span')}</label>
                        <input defaultValue={traderSettings?.TicketLifeSpan ?? 40} onKeyUp={(e) => {
                            if (e.key == 'Enter') {
                                var converted = parseFloat(e.target.value);
                                if (!isNaN(converted)) {
                                    updateTraderSettings('TicketLifeSpan', converted);
                                    e.preventDefault();
                                }
                            }
                        }} />
                    </div>
                </div>
            </div>
        </div>
        {!connected && <div className='padding border'>
            <span>{lang('Please wait connecting')}</span>
        </div>}
        {connected && tickets && tickets.length > 0 && <Table model={pendingTickets} data={tickets}
            onRowClick={(element, row) => {
                if (!row) return;
                selectTicket(row);
            }}
            onRenderRow={(row, elements, className) => {
                var selected = null;
                if (row.id == selectedTicket?.id) {
                    const legs = Object.values(selectedTicket.ticket.selections);
                    selected = <tr>
                        <td colSpan='12'>
                            <div className='padding'>
                                <Table data={legs} model={selectionsModel} context={{
                                    data: legs,
                                    updatePrice: (leg, price) => {
                                        try {
                                            var copy = [...tickets];
                                            var selected = copy.find(x => x.id == selectedTicket.id);
                                            Object.values(selected.ticket.selections).map(x => {
                                                if (x.id == leg.id) {
                                                    x.price = price;
                                                }
                                            });
                                            recalculate(selected);
                                            setTickets(copy);
                                        } catch (err) {
                                            console.log(err);
                                        }
                                    }
                                }} />
                            </div>
                        </td>
                        <td>
                            <div className='flex vertical gap-10 hidden'>
                                <div className='flex gap-5 vertical'>
                                    <label>{lang('Stake')}</label>
                                    <input defaultValue={selectedTicket.ticket.amount} onKeyUp={(e) => {
                                        if (e.key == 'Enter') {
                                            var converted = parseFloat(e.target.value);
                                            if (!isNaN(converted)) {
                                                updateStakeAmount(converted);
                                                e.preventDefault();
                                            }
                                        }
                                    }} />;
                                </div>
                                <div className='center'>
                                    <Button title='Modify' onClick={() => modify(selectedTicket)} />
                                </div>
                            </div>
                        </td>
                    </tr>;
                }
                return <React.Fragment>
                    <tr className={className}>
                        {elements}
                    </tr>
                    {selected}
                </React.Fragment>;
            }}
            buttons={(row) => {
                return <div className='flex gap-5'>
                    <Button title='Accept' onClick={() => accept(row)} />
                    <Button title='Reject' onClick={() => reject(row)} />
                </div>;
            }} />
        }
    </div>;
};