import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from '../../../../../Centrum/Search/Search';
import { apiResult, application, Endpoints } from '../../../../../Application/Application';
import { Tab } from '../../../../../Centrum/Tab/Tab';
import { searchUserModel, searchVirtualRetails, userModel } from './models';
import { toPascalCase } from '../../../../../Centrum/helpers';
import { ActionTypes, getAction } from '../../../../../Centrum/Common/ActionManager';
import { ConfirmationModal } from '../../../../../Modal/Modals/Confirmation/ConfirmationModal';
import { Table } from '../../../../../Controls/Table/Table';
import { Form } from '../../../../../../v2/Components/Controls/Form/Form';
import { FormInput, InputTypes } from '../../../../../../v2/Components/Controls/Form/FormInput';
import { PhysicalRetailUserTypes, VirtualRetailUserTypes } from '../../../common';
import { Button } from '../../../../../../v2/Components/Controls/Button/Button';
import { useSelector } from 'react-redux';


const tabButtons = [
    {
        name: 'search',
        title: 'Search Users'
    },
    {
        name: 'add',
        title: 'Create / Modify User'
    }
];



export const RetailUsers = (props) => {
    const [user, setUser] = useState(null);
    const tabRef = React.createRef();

    const users = useSelector((state)=>state.data?.retailUsers);

    const saveUser = (data) => {
        data.RetailId = props.retail.Id;
        data.Id = user?.Id ?? 0;
        console.log(user);
        application().resolve(null, user?.Id ? Endpoints.UpdateRetailUser : Endpoints.CreateRetailUser, data, 'saving please wait', 'unable to save user').then((result) => {
            console.log(result.result);
            setUser(toPascalCase(result.result));
            application().snackbar.open('User saved');
        });
    };


    const onActionClicked = (action, items) => {
        var item = items[0];
        item.Password = null;
        var data = [];
        if (action.type == ActionTypes.EditOperatorUser) {

            tabRef.current.select(1);
            setUser(item);
            return;
        }

        application().resolve(null, `${Endpoints.GetUsersGroupBinding}/${item.Id}`, null, 'fetching user group bindings', 'unable to fetch user group bindings').then((result) => {
            result.result.groupBindings.map(binding => {
                var selected = true;
                if (result.result.userActions.length > 0) {
                    selected = result.result.userActions.find(x => x == binding);
                }

                data.push({
                    action: binding,
                    __selected: selected
                });
            });
            application().modal.open(<ConfirmationModal title='Change Rights' onConfirm={() => {
                var bindings = data.filter(x => x.__selected).map(x => {
                    return x.action;
                }).join(',');
                application().resolve(null, `${Endpoints.SaveUsersGroupBinding}/${item.Id}`, { bindings: bindings }, 'please wait', 'unable to save').then(() => {
                    application().snackbar.open('bindings updated');
                });
            }}>
                <div className='padding'>
                    <Table model={{ fields: [{ name: 'action', display: 'Action' }] }} data={data} selectable={true} onSelectionChange={(selection, index, value) => {
                        if (index == -1) {
                            data.map(x => x.__selected = value);
                        } else {
                            data[index].__selected = value;
                        }
                    }} />
                </div>
            </ConfirmationModal>);
        });
    };


    return <Tab buttons={tabButtons} ref={tabRef}>
        <Search
            model={props.retail.Type == 0 ? searchVirtualRetails : searchUserModel}
            resultModel={userModel}
            data={users}
            actions={getAction(ActionTypes.ListRetailUsers).convertToItems()}
            onActionClicked={onActionClicked.bind(this)}
            onSubmit={(data, qb) => {
                apiResult('retailUsers', `${Endpoints.ListRetailUsers}/${props.retail.Id}`,
                    qb, 'fetching users', 'unable to fetch users');
            }}
        />
        <div className='padding card border radius'>
            <Form key={user} data={user ?? { UserGroupId: props.retail.Type == 0 ? 9 : 12 }} onSubmit={(data) => {
                if (user) data.Id = user.Id;
                saveUser(data);
            }}>
                <div className='row'>
                    <FormInput type={InputTypes.Select} name='UserGroupId' title='Type' values={props.retail.Type == 0 ? VirtualRetailUserTypes : PhysicalRetailUserTypes} />
                </div>
                <div className='row'>
                    <FormInput type={InputTypes.Text} name='Name' title='Name' />
                    <FormInput type={InputTypes.Text} name='Surname' title='Surname' />
                </div>
                <div className='row'>
                    <FormInput type={InputTypes.Text} name='Username' title='Username' />
                    <FormInput type={InputTypes.Text} name='Email' title='Email' />
                </div>
                <div className='row'>
                    <FormInput type={InputTypes.Text} name='Password' title='Password' />
                </div>
                <div className='row'>
                    <Button title='Save' type='submit' />
                    <Button title='Clear' type='reset' onClick={() => {
                        setUser(null);
                    }} />
                </div>
            </Form>
        </div>
    </Tab>;
};

RetailUsers.propTypes = {
    retail: PropTypes.object
};

