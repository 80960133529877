import React from 'react';
import { application, Endpoints } from '../../../Application/Application';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { lang } from '../../../Centrum/Localization/lang';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';

export const betStop = (event, onComplete) => {
    const eventId = event.Id;
    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        application().resolve(null, `${Endpoints.BetStopEvent}/${event.SportId}/${event.RegionId}/${event.LeagueId}/${eventId}`, null, 'please wait', 'unable to stop bet').then(() => {
            application().snackbar.open('Bet Stop');
            if (onComplete) onComplete();
        });
    }}>
        {lang('Do you want to stop bets on this event?')}
    </ConfirmationModal>);
};

export const betStart = (event, onComplete) => {
    const eventId = event.Id;

    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        application().resolve(null, `${Endpoints.BetStartEvent}/${event.SportId}/${event.RegionId}/${event.LeagueId}/${eventId}`, null, 'please wait', 'unable to start bet').then(() => {
            application().snackbar.open('Bet Start');
            if (onComplete) onComplete();
        });
    }}>
        {lang('Do you want to start bets on this event?')}
    </ConfirmationModal>);
};


export const changeStakeLimit = (event, live, onComplete) => {
    var form = null;

    application().modal.open(<ConfirmationModal title={lang('%1 stake limits', [live ? 'Live' : 'Prematch'])} onConfirm={() => {
        var data = form.submit();
        application().resolve(null, `${Endpoints.EventStakeLimit}/${event.Id}/${data.Amount}/${live}`, null, 'please wait', 'unable to set stake limit').then(() => {
            application().snackbar.open('Event Stake Limit Changed');
            if (onComplete) onComplete();
        });
    }}>
        <Form ref={(r) => form = r}>
            <div className='row'>
                <FormInput title='Limit' type={InputTypes.number} name='Amount' />
            </div>
        </Form>
    </ConfirmationModal>);
};

export const changeLiabilityLimit = (event, live, onComplete) => {
    var form = null;

    application().modal.open(<ConfirmationModal title={lang('%1 liability limits', [live ? 'Live' : 'Prematch'])} onConfirm={() => {
        var data = form.submit();
        application().resolve(null, `${Endpoints.EventLiabilityLimit}/${event.Id}/${data.Amount}/${live}`, null, 'please wait', 'unable to set liability limit').then(() => {
            application().snackbar.open('Event Liability Limit Changed');
            if (onComplete) onComplete();
        });
    }}>
        <Form ref={(r) => form = r}>
            <div className='row'>
                <FormInput title='Limit' type={InputTypes.number} name='Amount' />
            </div>
        </Form>
    </ConfirmationModal>);
};



export const marketBetStop = (event, market, onComplete) => {
    const eventId = event.Id;
    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        application().resolve(null, `${Endpoints.BetStopMarket}/${event.SportId}/${event.RegionId}/${event.LeagueId}/${eventId}/${market.MarketId}`, null, 'please wait', 'unable to stop bet').then(() => {
            application().snackbar.open('Bet Stop');
            if (onComplete) onComplete();
        });
    }}>
        {lang('Do you want to stop bets on %1 market %2?', [event.Name, market.MarketName])}
    </ConfirmationModal>);
};

export const marketBetStart = (event, market, onComplete) => {
    const eventId = event.Id;

    application().modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
        application().resolve(null, `${Endpoints.BetStartMarket}/${event.SportId}/${event.RegionId}/${event.LeagueId}/${eventId}/${market.MarketId}`, null, 'please wait', 'unable to start bet').then(() => {
            application().snackbar.open('Bet Start');
            if (onComplete) onComplete();
        });
    }}>
        {lang('Do you want to start bets on %1 market %2?', [event.Name, market.MarketName])}
    </ConfirmationModal>);
};
