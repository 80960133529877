import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const SEARCH_PROVIDERS              = 'SEARCH_PROVIDERS';
export const SEARCH_PROVIDERS_PENDING      = 'SEARCH_PROVIDERS_PENDING';
export const SEARCH_PROVIDERS_FULFILLED    = 'SEARCH_PROVIDERS_FULFILLED';
export const SEARCH_PROVIDERS_REJECTED     = 'SEARCH_PROVIDERS_REJECTED';

export const SEARCH_PROVIDER_GAMES              = 'SEARCH_PROVIDER_GAMES';
export const SEARCH_PROVIDER_GAMES_PENDING      = 'SEARCH_PROVIDER_GAMES_PENDING';
export const SEARCH_PROVIDER_GAMES_FULFILLED    = 'SEARCH_PROVIDER_GAMES_FULFILLED';
export const SEARCH_PROVIDER_GAMES_REJECTED     = 'SEARCH_PROVIDER_GAMES_REJECTED';

export const TOGGLE_PROVIDER_STATUS              = 'TOGGLE_PROVIDER_STATUS';
export const TOGGLE_PROVIDER_STATUS_PENDING      = 'TOGGLE_PROVIDER_STATUS_PENDING';
export const TOGGLE_PROVIDER_STATUS_FULFILLED    = 'TOGGLE_PROVIDER_STATUS_FULFILLED';
export const TOGGLE_PROVIDER_STATUS_REJECTED     = 'TOGGLE_PROVIDER_STATUS_REJECTED';


export const TOGGLE_PROVIDER_STATUS_FOR_OPERATOR              = 'TOGGLE_PROVIDER_STATUS_FOR_OPERATOR';
export const TOGGLE_PROVIDER_STATUS_FOR_OPERATOR_PENDING      = 'TOGGLE_PROVIDER_STATUS_FOR_OPERATOR_PENDING';
export const TOGGLE_PROVIDER_STATUS_FOR_OPERATOR_FULFILLED    = 'TOGGLE_PROVIDER_STATUS_FOR_OPERATOR_FULFILLED';
export const TOGGLE_PROVIDER_STATUS_FOR_OPERATOR_REJECTED     = 'TOGGLE_PROVIDER_STATUS_FOR_OPERATOR_REJECTED';


export const SEARCH_OPERATOR_PROVIDER_CONFIGURATION              = 'SEARCH_OPERATOR_PROVIDER_CONFIGURATION';
export const SEARCH_OPERATOR_PROVIDER_CONFIGURATION_PENDING      = 'SEARCH_OPERATOR_PROVIDER_CONFIGURATION_PENDING';
export const SEARCH_OPERATOR_PROVIDER_CONFIGURATION_FULFILLED    = 'SEARCH_OPERATOR_PROVIDER_CONFIGURATION_FULFILLED';
export const SEARCH_OPERATOR_PROVIDER_CONFIGURATION_REJECTED     = 'SEARCH_OPERATOR_PROVIDER_CONFIGURATION_REJECTED';


export const TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR              = 'TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR';
export const TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR_PENDING      = 'TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR_PENDING';
export const TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR_FULFILLED    = 'TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR_FULFILLED';
export const TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR_REJECTED     = 'TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR_REJECTED';


export const TOGGLE_PROVIDER_GAME_STATUS              = 'TOGGLE_PROVIDER_GAME_STATUS';
export const TOGGLE_PROVIDER_GAME_STATUS_PENDING      = 'TOGGLE_PROVIDER_GAME_STATUS_PENDING';
export const TOGGLE_PROVIDER_GAME_STATUS_FULFILLED    = 'TOGGLE_PROVIDER_GAME_STATUS_FULFILLED';
export const TOGGLE_PROVIDER_GAME_STATUS_REJECTED     = 'TOGGLE_PROVIDER_GAME_STATUS_REJECTED';

export const UPDATE_PROVIDER_GAME              = 'UPDATE_PROVIDER_GAME';
export const UPDATE_PROVIDER_GAME_PENDING      = 'UPDATE_PROVIDER_GAME_PENDING';
export const UPDATE_PROVIDER_GAME_FULFILLED    = 'UPDATE_PROVIDER_GAME_FULFILLED';
export const UPDATE_PROVIDER_GAME_REJECTED     = 'UPDATE_PROVIDER_GAME_REJECTED';


export const SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION              = 'SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION';
export const SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_PENDING      = 'SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_PENDING';
export const SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_FULFILLED    = 'SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_FULFILLED';
export const SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_REJECTED     = 'SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION_REJECTED';


// ACTION CREATORS


function searchProvidersAction(query) {
    return {
        type:SEARCH_PROVIDERS,
        payload: postData(query,Endpoints.ProviderSearch)
    };
}

function searchProviderGamesAction(query) {
    return {
        type:SEARCH_PROVIDER_GAMES,
        payload: postData(query,Endpoints.ProviderSearchGames)
    };
}

function toggleProviderStatusAction(id) {
    return {
        type:TOGGLE_PROVIDER_STATUS,
        payload: postData(null,Endpoints.ProviderToggleProviderStatus + id)
    };
}

function searchOperatorProviderConfigurationAction(data) {
    return {
        type:SEARCH_OPERATOR_PROVIDER_CONFIGURATION,
        payload: postData(data,Endpoints.ProviderSearchOperatorProviderConfiguration)
    };
}

function toggleProviderStatusForOperatorAction(id,providerId) {
    return {
        type:TOGGLE_PROVIDER_STATUS_FOR_OPERATOR,
        payload: postData(null,Endpoints.ProviderToggleProviderStatusForOperator+id+'&providerId='+providerId)
    };
}

function searchOperatorProviderGameConfigurationAction(data) {
    return {
        type:SEARCH_OPERATOR_PROVIDER_GAME_CONFIGURATION,
        payload: postData(data,Endpoints.ProviderSearchOperatorProviderGameConfiguration)
    };
}


function toggleProviderGameStatusAction(gameId) {
    return {
        type:TOGGLE_PROVIDER_GAME_STATUS,
        payload: postData(null,Endpoints.ProviderToggleProviderGameStatus + gameId)
    };
}

function toggleProviderGameStatusForOperatorAction(operatorId,gameId) {
    return {
        type:TOGGLE_PROVIDER_GAME_STATUS_FOR_OPERATOR,
        payload: postData(null,Endpoints.ProviderToggleProviderGameStatusForOperator + operatorId + '&gameId=' + gameId)
    };
}

function updateProviderGameAction(data) {
    return {
        type:UPDATE_PROVIDER_GAME,
        payload: postData(data,Endpoints.ProviderUpdateGame)
    };
}



export {searchProvidersAction as searchProviders};
export {searchProviderGamesAction as searchProviderGames};
export {toggleProviderStatusAction as toggleProviderStatus};
export {searchOperatorProviderConfigurationAction as searchOperatorProviderConfiguration};
export {toggleProviderStatusForOperatorAction as toggleProviderStatusForOperator};
export {searchOperatorProviderGameConfigurationAction as searchOperatorProviderGameConfiguration};
export {toggleProviderGameStatusForOperatorAction as toggleProviderGameStatusForOperator};
export {toggleProviderGameStatusAction as toggleProviderGameStatus};
export {updateProviderGameAction as updateProviderGame};