import '../RiskLevels/_riskLevelConfiguration.scss';

import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { currency } from '../../../../v2/Lib/Common/currency';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { API, RiskLevelEndpoints } from '../../../../v2/Lib/Api/Api';
import { getLookupKey } from '../RiskLevels/RiskLevelLimitsConfiguration';
import { openBetModal } from '../models';


const marketVolumesModel = {
    fields: [
        {
            name: 'ticketId',
            title: 'Ticket Id',
            renderRow: (row) => {
                return <a onClick={() => openBetModal(row.ticketId, false)}>
                    {row.ticketId}
                </a>;
            }
        },
        {
            name: 'level',
            title: 'Type',
            renderRow: (row) => {
                if (!row.level) return;
                const result = getLookupKey(row.level).map((x, index) => {
                    return <div key={index} className={x}>{x}</div>;
                });

                return <div className='risk-levels flex gap-5'>
                    {result}
                </div>;
            }
        },
        {
            name: 'username',
            title: 'Player'
        },
        {
            name: 'market',
            title: 'Market'
        },
        {
            name: 'selectionName',
            title: 'Selection'
        },
        {
            name: 'price',
            title: 'Price'
        },
        {
            name: 'stake',
            title: 'Stake',
            renderRow: (row) => {
                return currency(row.stake, 'EUR', 2, false);
            }
        },
        {
            name: 'liability',
            title: 'Possible Win',
            renderRow: (row) => {
                return currency(row.liability, 'EUR', 2, false);
            }
        }
    ]
};

export const MonitorMarketLevels = (props) => {
    const event = props.event;
    const riskLevels = props.riskLevels;
    const [marketVolumes, setMarketVolumes] = useState(null);

    useEffect(() => {
        API.post(`${RiskLevelEndpoints.MonitorMarketVolumes}/${window.user.UserPrivileges.PointOfView}/${event.id}`).then((result) => {
            setMarketVolumes(result.result);
        });

    }, []);



    return <Table data={marketVolumes?.markets ?? []} model={marketVolumesModel} context={{ levels: riskLevels }} />
};

MonitorMarketLevels.propTypes = {
    event: PropTypes.object,
    volumes: PropTypes.object,
    riskLevels: PropTypes.object,
    onClose: PropTypes.func
};