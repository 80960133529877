/* eslint-disable react/display-name */
import React from 'react';

import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { currency, lang } from '../Localization/lang';
import { TransactionTypes, BonusTransactionTypes, TestAccountFilter } from './ModelEnums';
import { NavLink } from 'react-router-dom';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';
import { currencyAndFooter } from './ReportsModel';
import { API, PromotionEndpoints } from '../../../v2/Lib/Api/Api';
import PropTypes from 'prop-types';
import { application } from '../../Application/Application';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';

export const TransactionSources = {
    'Website': 'Web Site',
    'Game': 'Game'
};




export const TransactionStatus = {
    0: 'Pending',
    1: 'Confirmed',
    2: 'Cancel'
};


export const gotoProfile = (id, username) => {
    if (window.user.AffiliateId > 0) {
        return <NavLink to={'/AffiliatePlayers/ListAffiliatePlayers/profile/' + id}>{username}</NavLink>;
    } else {
        return <NavLink to={`/player/playerShortcuts/profile/${id}`}>{username}</NavLink>;
    }
};

export const PromotionInformation = (props) => {
    return <a href='#' onClick={(e) => {
        e.preventDefault();
        API.post(PromotionEndpoints.List).then((result) => {
            var promotionData = result.result.find(x => x.Id == props.id);
            application().modal.open(<Dialog title={props.name}>
                <p className='padding'>
                    {promotionData.Description}
                </p>
            </Dialog>);
        });
    }}>
        <span>{props.name}</span>
    </a>;
};
PromotionInformation.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string
};

export const TransactionModels = {
    searchTransactions: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'TransactionDate',
                display: 'Transaction Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'UserId',
                type: InputTypes.number
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Code',
                type: InputTypes.text
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: TransactionTypes,
                acceptEmptyValues: true
            },
            {
                name: 'OperatorUserId',
                display: 'Admin',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myOperatorUsers',
                    bindingKey: 'Id',
                    bindingText: ['Email', 'Name', 'Surname']
                }
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/payment',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'Status',
                type: InputTypes.select,
                values: TransactionStatus,
                acceptEmptyValues: true
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Amount',
                max: 10,
                type: InputTypes.number
            }

        ]
    },
    searchPendingDeposits: {
        fields: [

            {
                name: 'UserId',
                type: InputTypes.number
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/payment',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'Amount',
                max: 10,
                type: InputTypes.number
            }

        ]
    },
    searchPendingWithdraws: {
        fields: [
            {
                name: 'UserId',
                type: InputTypes.number
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/payment',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'Amount',
                max: 10,
                type: InputTypes.number
            }

        ]
    },
    searchProcessingTransactions: {
        fields: [
            {
                name: 'TransactionDate',
                display: 'Transaction Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.TodayF
                }
            },
            {
                name: 'UserId',
                type: InputTypes.number
            },
            {
                name: 'PromotionName',
                display: 'Bonus Name',
                type: InputTypes.string,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return <PromotionInformation id={parseFloat(row.PromotionId)} name={val} />;
                }
            },

            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/payment',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'Amount',
                max: 10,
                type: InputTypes.number
            }

        ]
    },
    searchPlayerLastTransactions: {
        fields: [
            {
                name: 'Operator',
                hidden: true
            },
            {
                name: 'TransactionDate',
                display: 'Transaction Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'Username',
                type: InputTypes.text,
                hidden: true
            },
            {
                name: 'UserId',
                type: InputTypes.text,
                hidden: true
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: TransactionTypes,
                acceptEmptyValues: true
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Status',
                type: InputTypes.select,
                values: TransactionStatus,
                acceptEmptyValues: true
            },
            {
                name: 'Amount',
                max: 10,
                type: InputTypes.number
            }
        ]
    },
    transactionInformation: {
        fields: [
            {
                name: 'Operator',
                type: InputTypes.text,
                identity: true,
            },
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'TransactionDate',
                display: 'Created',
                type: InputTypes.dateTime
            },
            {
                name: 'UpdateDate',
                display: 'Updated',
                type: InputTypes.dateTime
            },

            {
                name: 'Type',
                display: 'Type',
                formatter: (val, row) => {
                    if (row.Status == 2) {
                        return `${lang('Cancel')} ${TransactionTypes[row.Type]}`;
                    }

                    return TransactionTypes[row.Type];
                }
            },
            {
                name: 'RetailCode',
                display: 'Retail Code',
                formatter: (val, row) => {
                    const data = (row.RetailCode ? `[${row.RetailCode}]` : '') + (row.retailName ? row.RetailName : '');
                    return data;
                }
            },
            {
                name: 'Affiliate',
                display: 'Affiliate'
            },
            {
                name: 'UserId',
                display: 'Player Id'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return row.Username;
                    return gotoProfile(row.UserId, row.Username);
                }
            },
            {
                name: 'Code'
            },
            {
                name: 'Name',
                display: 'Name'
            },
            {
                name: 'Surname',
                display: 'Surname'
            },
            {
                name: 'Source',
                display: 'Source',
                type: InputTypes.text
            },
            {
                name: 'Notes',
                display: 'Notes'
            },
            {
                name: 'OperatorUser',
                display: 'Admin'
            },
            {
                name: 'Data',
                display: 'Data',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    if (isFooter) return val;
                    return <div className='' style={{ overflow: 'hidden', maxWidth: '100px', textOverflow: 'ellipsis' }}>
                        {val}
                    </div>;
                }
            },
            {
                name: 'Provider',
                display: 'Provider',
                type: InputTypes.text
            },
            {
                name: 'Bonus',
                display: 'Bonus',
                type: InputTypes.bool
            },
            {
                name: 'BonusWallet',
                display: 'Bonus Wallet',
                type: InputTypes.bool
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Amount',
                display: 'Amount',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'BalanceAfter',
                display: 'Balance',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'Status',
                type: InputTypes.select,
                value: 0,
                values: TransactionStatus
            }
        ]
    },
    playerLastTransactions: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'TransactionDate',
                display: 'Created',
                type: InputTypes.dateTime
            },
            {
                name: 'ExternalTransactionId',
                display: 'External Transaction Id',
                type: InputTypes.number
            },
            {
                name: 'Type',
                display: 'Type',
                type: InputTypes.enum,
                values: TransactionTypes
            },
            {
                name: 'Display',
                display: 'Provider',
                type: InputTypes.text
            },
            {
                name: 'Notes',
                display: 'Notes'
            },
            {
                name: 'BonusWallet',
                type: InputTypes.bool,
            },
            {
                name: 'PromotionName',
                display: 'Bonus Name',
                type: InputTypes.string,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return <PromotionInformation id={parseFloat(row.PromotionId)} name={val} />;
                }
            },
            {
                name: 'Amount',
                display: 'Amount',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currencyAndFooter(val, row, isFooter, isExporting, true);
                }
            },
            {
                name: 'BalanceBefore',
                display: 'Balance Before',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'BalanceAfter',
                display: 'Balance After',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'Status',
                type: InputTypes.select,
                value: 0,
                values: TransactionStatus
            }
        ]
    },
    searchPlayerBonusTransactions: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'TransactionDate',
                display: 'Transaction Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'OperatorUserId',
                display: 'Admin',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myOperatorUsers',
                    bindingKey: 'Id',
                    bindingText: ['Email', 'Name', 'Surname']
                }
            },
            {
                name: 'Username',
                type: InputTypes.text,
                hidden: true
            },
            {
                name: 'UserId',
                display: 'Player Id',
                type: InputTypes.text
            },
            {
                name: 'PromotionId',
                display: 'Promotion',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'promotion',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: {
                    0: 'Deposit',
                    1: 'Withdraw'
                },
                acceptEmptyValues: true
            }
        ]
    },
    playerBonusTransactions: {
        fields: [
            {
                name: 'Id',
                display: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'TransactionDate',
                display: 'Created',
                type: InputTypes.dateTime
            },
            {
                name: 'Type',
                display: 'Type',
                type: InputTypes.enum,
                values: BonusTransactionTypes
            },
            {
                name: 'Operator',
                display: 'Operator'
            },
            {
                name: 'UserId',
                display: 'Player Id'
            },
            {
                name: 'Username'
            },
            {
                name: 'Code'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code',
            },
            {
                name: 'RetailName',
                display: 'Retail Name',
            },
            {
                name: 'OperatorUser',
                display: 'Admin'
            },
            {
                name: 'PromotionName',
                display: 'Bonus Name'
            },
            {
                name: 'Notes',
                display: 'Notes'
            },
            {
                name: 'Status',
                display: 'Status',
                type: InputTypes.select,
                values: TransactionStatus
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Amount',
                display: 'Amount',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'BalanceBefore',
                display: 'Balance Before',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'BalanceAfter',
                display: 'Balance After',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            }
        ]
    },
    createTransaction: {
        fields: [
            {
                name: 'id',
                hidden: true,
                readonly: true
            },
            {
                name: 'transactionType',
                display: 'Type',
                type: InputTypes.select,
                values: {
                    0: 'Deposit',
                    1: 'Withdraw'
                },
                readonly: true
            },
            {
                name: 'source',
                display: 'Source'
            },
            {
                name: 'username',
                display: 'Username',
                readonly: true,
            },
            {
                name: 'currency',
                display: 'Currency',
                readonly: true
            },
            {
                name: 'balance',
                display: 'Balance',
                readonly: true
            },
            {
                name: 'amount',
                display: 'Amount',
                type: InputTypes.number,
                layout: 'single-row',
            },
            {
                name: 'notes',
                display: 'Reason',
                type: InputTypes.text,
                layout: 'single-row',
                multiLine: true
            }
        ]
    },
    createBonus: {
        fields: [
            {
                name: 'username',
                display: 'Username',
                readonly: true,
            },
            {
                name: 'currency',
                display: 'Currency',
                readonly: true
            },
            {
                name: 'balance',
                display: 'Balance',
                readonly: true
            },
            {
                name: 'amount',
                display: 'Amount',
                type: InputTypes.number,
                layout: 'single-row',
            },
            {
                name: 'notes',
                display: 'Reason',
                type: InputTypes.text,
                layout: 'single-row',
                multiLine: true
            }
        ]
    },
    confirmTransaction: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly: true,
            },
            {
                name: 'ExternalTransactionId',
                display: 'External Transaction Id',
                type: InputTypes.text,
                readonly: true,
            },
            {
                name: 'TransactionDate',
                display: 'Created',
                type: InputTypes.dateTime,
                readonly: true,
            },
            {
                name: 'Type',
                display: 'Type',
                type: InputTypes.select,
                values: {
                    0: 'Deposit',
                    1: 'Withdraw'
                },
                readonly: true
            },
            {
                name: 'Username',
                type: InputTypes.text,
                readonly: true,
            },
            {
                name: 'Amount',
                display: 'Amount',
                readonly: true,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'ConfirmedAmount',
                display: 'Confirmed Amount',
                type: InputTypes.number
            },
            {
                name: 'Notes',

                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    rejectTransaction: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly: true,
            },
            {
                name: 'ExternalTransactionId',
                display: 'External Transaction Id',
                type: InputTypes.text,
                readonly: true,
            },
            {
                name: 'TransactionDate',
                display: 'Created',
                type: InputTypes.dateTime,
                readonly: true,
            },
            {
                name: 'Type',
                display: 'Type',
                type: InputTypes.select,
                values: {
                    0: 'Deposit',
                    1: 'Withdraw'
                },
                readonly: true
            },
            {
                name: 'Username',
                type: InputTypes.text,
                readonly: true,
            },
            {
                name: 'Amount',
                display: 'Amount',
                readonly: true,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'Notes',

                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    processTransaction: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly: true,
            },
            {
                name: 'Username',
                type: InputTypes.text,
                readonly: true,
            },
            {
                name: 'Amount',
                display: 'Amount',
                readonly: true,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'Notes',

                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    searchAffiliateTransactions: {
        fields: [
            {
                name: 'TransactionDate',
                display: 'Transaction Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Week']
                }
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'UserId',
                type: InputTypes.number
            },
            {
                name: 'Type',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    0: 'Deposit',
                    1: 'Withdraw'
                }
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/payment',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            }
        ]
    },
    affiliateTransactionResult: {
        fields: [
            {
                name: 'Id',
                display:'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'TransactionDate',
                display: 'Created',
                type: InputTypes.dateTime
            },
            {
                name: 'UpdateDate',
                display: 'Updated',
                type: InputTypes.dateTime
            },
            {
                name: 'Type',
                display: 'Type',
                formatter: (val, row) => {
                    if (row.Status == 2) {
                        return `${lang('Cancel')} ${TransactionTypes[row.Type]}`;
                    }

                    return TransactionTypes[row.Type];
                }
            },
            {
                name: 'UserId',
                display: 'Player Id'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return row.Username;
                    return gotoProfile(row.UserId, row.Username);
                }
            },
            {
                name: 'Name',
                display: 'Name'
            },
            {
                name: 'Surname',
                display: 'Surname'
            },
            {
                name: 'Source',
                display: 'Source',
                type: InputTypes.text
            },
            {
                name: 'OperatorUser',
                display: 'Admin'
            },
            {
                name: 'Provider',
                display: 'Provider',
                type: InputTypes.text
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Amount',
                display: 'Amount',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'BalanceAfter',
                display: 'Balance',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'Status',
                type: InputTypes.select,
                value: 0,
                values: TransactionStatus
            }
        ]
    },
    searchAffiliateTransactionsSummary: {
        fields: [
            {
                name: 'TransactionDate',
                display: 'Transaction Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Week']
                }
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'UserId',
                type: InputTypes.number
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: {
                    0: 'Deposit',
                    1: 'Withdraw'
                },
                acceptEmptyValues: true
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/payment',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'Status',
                type: InputTypes.select,
                values: TransactionStatus,
                acceptEmptyValues: true
            },
            {
                name: 'Amount',
                max: 10,
                type: InputTypes.number
            }

        ]
    },
    affiliateTransactionSummaryResult: {
        fields: [
            {
                name: 'UserId',
                display: 'Player Id'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return row.Username;
                    return gotoProfile(row.UserId, row.Username);
                }
            },
            {
                name: 'Type',
                display: 'Type',
                formatter: (val, row) => {
                    if (row.Status == 2) {
                        return `${lang('Cancel')} ${TransactionTypes[row.Type]}`;
                    }

                    return TransactionTypes[row.Type];
                }
            },
            {
                name: 'ProviderName',
                display: 'Provider'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'TotalAmount',
                display: 'Total Amount',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, 2, row.Currency);
                }
            }
        ]
    },
    searchAffiliateBonusTransactions: {
        fields: [
            {
                name: 'TransactionDate',
                display: 'Transaction Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Week']
                }
            },
            {
                name: 'Username',
                type: InputTypes.text,
                hidden: true
            },
            {
                name: 'UserId',
                type: InputTypes.text,
                hidden: true
            },
            {
                name: 'PromotionId',
                display: 'Promotion',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'promotion',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: BonusTransactionTypes,
                acceptEmptyValues: true
            },
            {
                name: 'Amount',
                max: 10,
                type: InputTypes.number
            }
        ]
    },
    affiliateBonusTransactions: {
        fields: [
            {
                name: 'UserId',
                display: 'Player Id'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return row.Username;
                    return gotoProfile(row.UserId, row.Username);
                }
            },
            {
                name: 'Type',
                display: 'Type',
                formatter: (val, row) => {
                    if (row.Status == 2) {
                        return `${lang('Cancel')} ${TransactionTypes[row.Type]}`;
                    }

                    return TransactionTypes[row.Type];
                }
            },
            {
                name: 'PromotionName',
                display: 'Promotion'
            },
            {
                name: 'Source',
                display: 'Source'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'TotalAmount',
                display: 'Total Amount',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(val, 2, row.Currency);
                }
            }
        ]
    },
    searchAffiliateSettlements: {
        fields: [
            {
                name: 'EntryDate',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['Two Weeks']
                }
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: TransactionTypes,
                acceptEmptyValues: true
            },
            {
                name: 'Amount',
                max: 10,
                type: InputTypes.number
            }

        ]
    },
    affiliateSettlements: {
        fields: [
            {
                name: 'EntryDate',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['Two Weeks']
                }
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: TransactionTypes,
                acceptEmptyValues: true
            },
            {
                name: 'OperatorUser',
                display: 'User'
            },
            {
                name: 'Amount',
                display: 'Amount',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'BalanceBefore',
                display: 'Balance Before',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'BalanceAfter',
                display: 'Balance After',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            }
        ]
    },
    searchPlayerBonusReports: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Username'
            },
            {
                name: 'Code'
            },
            {
                name: 'PlayerId',
                display: 'Player Id'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            },
            {
                name: 'PromotionId',
                display: 'Promotion',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'promotion',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            }
        ]
    },
    playerBonusReports: {
        fields: [
            {
                name: 'ConsumedDate',
                display: 'Start Date',
                type: InputTypes.dateTime
            },
            {
                name: 'Operator',
                display: 'Operator'
            },
            {
                name: 'PlayerId',
                display: 'Player Id'
            },
            {
                name: 'Code',
                display: 'Code'
            },
            {
                name: 'Username'
            },
            {
                name: 'PlayerCode',
                display: 'Player Code'
            },
            {
                name: 'Group',
                display: 'Player Group'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'PromotionName',
                display: 'Promotion'
            },
            {
                name: 'TurnoverMultiplier',
                display: 'Turnover Multiplier'
            },
            {
                name: 'Amount',
                display: 'Bonus Amount',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'SportsBets',
                display: 'Bets'
            },
            {
                name: 'TotalBet',
                display: 'Total Stake',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'TotalWin',
                display: 'Win',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'PlayerBalance',
                display: 'Starting Balance',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'Balance',
                display: 'Balance',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'BonusBalance',
                display: 'Bonus Balance',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            }
        ]
    },
    searchCompletedBonusPayouts: {
        fields: [
            {
                name: 'OperatorId',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'CompleteDate',
                display: 'Complete Date',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Week']
                }
            },
            {
                name: 'Username'
            },
            {
                name: 'Code'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            },
            {
                name: 'PromotionId',
                display: 'Promotion',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'promotion',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            }
        ]
    },
    completedBonusPayouts: {
        fields: [
            {
                name: 'CompleteDate',
                display: 'Complete Date',
                type: InputTypes.dateTime
            },
            {
                name: 'PlayerId',
                display: 'Player Id'
            },
            {
                name: 'Code',
                display: 'Code'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Username'
            },
            {
                name: 'PlayerCode',
                display: 'Player Code'
            },
            {
                name: 'Group',
                display: 'Player Group'
            },
            {
                name: 'Promotion',
                display: 'Promotion'
            },
            {
                name: 'Total',
                display: 'Total'
            },
            {
                name: 'TotalBonus',
                display: 'Total Bonus',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'TotalPayout',
                display: 'Total Payout',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            }
        ]
    },
    searchCompletedBonusPayoutsSummary: {
        fields: [
            {
                name: 'CompleteDate',
                display: 'Complete Day',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Week']
                }
            },
            {
                name: 'Username'
            },
            {
                name: 'Code'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            },
            {
                name: 'PromotionId',
                display: 'Promotion',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'promotion',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            }
        ]
    },
    searchRemovedBonusPayoutsSummary: {
        fields: [
            {
                name: 'RemovedDate',
                display: 'Removed Day',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Week']
                }
            },
            {
                name: 'Username'
            },
            {
                name: 'Code'
            },
            {
                name: 'RetailCode',
                display: 'Retail Code'
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'EnabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Retails',
                display: 'Only Retails',
                type: InputTypes.bool
            },
            {
                name: 'TestAccounts',
                display: 'Test Accounts',
                type: InputTypes.select,
                value: 0,
                values: TestAccountFilter
            },
            {
                name: 'PromotionId',
                display: 'Promotion',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'promotion',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            }
        ]
    },
    completedBonusPayoutsSummary: {
        fields: [
            {
                name: 'Promotion',
                display: 'Promotion'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Total',
                display: 'Total'
            },
            {
                name: 'TotalBonus',
                display: 'Total Bonus',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'TotalPayout',
                display: 'Total Payout',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            }
        ]
    },
    removedBonusSummary: {
        fields: [
            {
                name: 'ConsumedDate',
                display: 'Consumed Date'
            },
            {
                name: 'PlayerId',
                display: 'Player Id'
            },
            {
                name: 'Code',
                display: 'Code'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Username'
            },
            {
                name: 'PlayerCode',
                display: 'Player Code'
            },
            {
                name: 'Group',
                display: 'Player Group'
            },
            {
                name: 'Promotion',
                display: 'Promotion'
            },
            {
                name: 'TotalBonus',
                display: 'Total Bonus',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'TotalBet',
                display: 'Total Bet',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'TotalWin',
                display: 'Total Win',
                formatter: (val, row, isFooter, isExporting) => {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            },
            {
                name: 'SportsBets',
                display: 'Tickets'
            },
            {
                name: 'GivenBy',
                display: 'Given By'
            },
            {
                name: 'Notes',
                display: 'Notes'
            },
            {
                name: 'RemovedBy',
                display: 'Removed By'
            },
            {
                name: 'RemovedDate',
                display: 'Removed Date'
            },
            {
                name: 'RemoveNotes',
                display: 'Remove Notes'
            }
        ]
    },
    playerBonusSummary: {
        fields: [
            {
                name: 'Operator'
            },
            {
                name: 'PlayerId',
                display: 'Player Id'
            },
            {
                name: 'Code',
                display: 'Code'
            },
            {
                name: 'Currency',
                display: 'Currency'
            },
            {
                name: 'Username'
            },
            {
                name: 'PlayerCode',
                display: 'Player Code'
            },
            {
                name: 'Group',
                display: 'Player Group'
            },
            {
                name: 'Promotion',
                display: 'Promotion'
            },
            {
                name: 'Total',
                display: 'Total'
            },
            {
                name: 'TotalBonus',
                display: 'Total Bonus',
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            }
        ]
    }
};