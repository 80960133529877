import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { apiResult, application, Endpoints } from '../../../Application/Application';
import { Card, Panel } from '../../../Centrum/Card/Card';
import { currency, lang } from '../../../Centrum/Localization/lang';
import { Button } from '../../../Controls/Buttons/Button';
import { Table } from '../../../Controls/Table/Table';
import { Search } from '../../../Centrum/Search/Search';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes } from '../../../Centrum/_models/ModelEnums';
import { Tab } from '../../../Centrum/Tab/Tab';
import { renderVolumeInformation } from './VolumeInformation';
import { betStart, betStop, marketBetStart, marketBetStop } from '../actions/eventActions';
import { betSlipResults } from '../models';
import { SportEventLimits } from '../Configurations/SportsbookLimits/SportEventLimits';
import { EditableTable } from '../../../Controls/Table/Editable/EditableTable';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Form } from '../../../Controls/Form/Form';
import { Dialog } from '../../../Modal/Modals/Dialog/Dialog';
import { EventSelectionVolumes } from './EventSelectionVolumes';
import { Tracker } from '../Tracker/Tracker';


export const searchBetSlips = {
    fields: [
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            value: 0,
            values: SportsTicketStatus
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Bet',
            display: 'Stake',
            type: InputTypes.number
        }
    ]
};

const volumeSummaryModel = {
    fields: [
        {
            name: 'Currency'
        },
        {
            name: 'TotalStake',
            display: 'Total Stake',
            formatter: (val, row) => {
                return currency(Math.abs(val), 2, row.Currency);
            }
        },
        {
            name: 'LiveStake',
            display: 'Live Stake',
            formatter: (val, row) => {
                return currency(Math.abs(val), 2, row.Currency);
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            formatter: (val, row) => {
                return currency(Math.abs(val), 2, row.Currency);
            }
        },
        {
            name: 'LiveLiability',
            display: 'Live Liability',
            formatter: (val, row) => {
                return currency(Math.abs(val), 2, row.Currency);
            }
        }
    ]
};



const volumeMarketsModel = {
    fields: [
        {
            name: 'MarketId',
            display: 'Market Id'
        },
        {
            name: 'MarketName',
            display: 'Market'
        },
        {
            name: 'SelectionName',
            display: 'Selection'
        },
        {
            name: 'Slips',
            display: 'Slips'
        },
        {
            name: 'Price',
            display: 'Price',
            formatter: (row, context) => {
                var market = context.prices[row.MarketId];
                if (market) {
                    var selection = market[row.SelectionId];
                    if (!selection) return;
                    return selection.Price;
                }
            }
        },
        {
            name: 'Stake',
            display: 'Stake',
            formatter: (row) => {
                return `${currency(Math.abs(row.Stake), 2, row.Currency)}`;
            }
        },
        {
            name: 'Stake%',
            display: 'Stake %',
            formatter: (row, context) => {
                var total = context.event.Volumes.Stake.Prematch + context.event.Volumes.Stake.Live;
                var percentage = (row.Stake * 100) / total;
                return `%${percentage.toFixed(2)}`;
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            formatter: (row) => {
                return currency(Math.abs(row.Liability), 2, row.Currency);
            }
        },
        {
            name: 'Liability%',
            display: 'Liability %',
            formatter: (row, context) => {
                var total = context.event.Volumes.Liability.Prematch + context.event.Volumes.Liability.Live;
                var percentage = (row.Liability * 100) / total;
                return `%${percentage.toFixed(2)}`;
            }
        },
    ]
};


const playerLimitsModel = {
    fields: [
        {
            name: 'username',
            display: 'Username',
        },
        {
            name: 'code',
            display: 'Code',
        },
        {
            name: 'minimumEvent',
            type: 'string',
            display: 'Minimum Event',
            editable: true
        },
        {
            name: 'marketCap',
            type: 'string',
            display: 'Market Cap',
            editable: true
        },
        {
            name: 'maximumStake',
            type: 'number',
            display: 'Stake Volume',
            editable: true
        },
        {
            name: 'maximumStakeLive',
            type: 'number',
            display: 'Stake Live Volume',
            editable: true
        },
        {
            name: 'maximumLiability',
            type: 'number',
            display: 'Liability',
            editable: true
        },
        {
            name: 'maximumLiabilityLive',
            type: 'number',
            display: 'Live Liability',
            editable: true
        },
        {
            name: 'offer',
            type: 'bool',
            display: 'Offer',
            editable: true
        }
    ]
};


var refreshMarkets = false;

export const EventSummary = (props) => {
    const event = props.event;
    const [cancellationToken, setCancellationToken] = useState(null);
    const [slips, setSlips] = useState({});
    const [eventLimitEditing, editEventLimit] = useState(null);
    const [player, showPlayer] = useState(null);
    const [playerLimits, setPlayerLimits] = useState([]);
    const [prices, setPrices] = useState({});

    const loadPrices = () => {
        const refresh = () => {
            var markets = Object.keys(props.event.Markets);
            application().resolve(null, Endpoints.GetMarketPrices, markets).then((result) => {
                setPrices(result.result);
            });
        };
        refresh();
        if (!refreshMarkets) return;
        setCancellationToken(setInterval(() => refresh(), 5000));
    };

    useEffect(() => {
        refreshMarkets = true;
        var limits = [];
        var eventLimit = props.volumes.EventLimits && props.volumes.EventLimits.hasOwnProperty(props.event.Id) ? props.volumes.EventLimits[props.event.Id] : null;
        if (eventLimit) {
            if (eventLimit.Players) {
                Object.keys(eventLimit.Players).map((id, index) => {
                    var playerLimit = eventLimit.Players[id];
                    var limit = {
                        id: id,
                        index: index,
                        username: playerLimit.Username,
                        minimumEvent: playerLimit.MinimumEvent,
                        marketCap: playerLimit.MarketCap,
                        maximumStake: playerLimit.Stake.Prematch,
                        maximumStakeLive: playerLimit.Stake.Live,
                        maximumLiability: playerLimit.Liability.Prematch,
                        maximumLiabilityLive: playerLimit.Liability.Live,
                        offer: playerLimit.Offer
                    };

                    limits.push(limit);
                });
            }
        }
        setPlayerLimits(limits);

        loadPrices();
        return () => {
            refreshMarkets = false;
            clearInterval(cancellationToken);
        };
    }, []);


    var vSummary = [];
    var context = {
        volumes: props.volumes,
        event: event,
        prices: prices
    };

    Object.keys(event.Stakes).map(c => {
        vSummary.push({
            Currency: c,
            TotalStake: event.Stakes[c].Prematch + event.Stakes[c].Live,
            LiveStake: event.Stakes[c].Live,
            Liability: event.Liabilities[c].Prematch + event.Liabilities[c].Live,
            LiveLiability: event.Liabilities[c].Live
        });
    });

    var markets = [];

    Object.values(event.Markets).map(market => {
        const selections = Object.values(market.Selections);
        selections.map(selection => {
            markets.push({
                MarketId: market.Id,
                MarketName: market.Name,
                SelectionId: selection.Id,
                SelectionName: selection.Name,
                Live: selection.Live,
                Currency: event.DefaultCurrency,
                Stake: selection.Stakes.Prematch + selection.Stakes.Live,
                Liability: selection.Liabilities.Prematch + selection.Liabilities.Live,
                Slips: selection.Slips.Prematch + selection.Slips.Live
            });
        });

    });


    const showVolumes = () => {
        return <Panel title='Volumes'>
            <EditableTable model={volumeMarketsModel} data={markets} key={markets} readonly={true} context={context} buttons={(row) => {
                const stop = props.volumes.MarketStops.hasOwnProperty(row.MarketId);
                return <Button title={stop ? 'Bet Start' : 'Bet Stop'} onClick={() => {
                    stop ? marketBetStart(props.event, row) : marketBetStop(props.event, row);
                }} />;
            }} />
        </Panel>;
    };



    const search = (fields) => {
        application().resolve('playersForShortcuts', Endpoints.PlayerSummary, fields).then((result) => {
            if (fields.Code == '') return;
            if (fields.Id == '') return;
            showPlayer(result.result);
        });
    };


    const setLimitFrom = (limit) => {
        return {
            marketCap: limit.marketCap,
            offer: limit.offer,
            stake: {
                live: limit.stake.live,
                prematch: limit.stake.prematch
            },
            liability: {
                live: limit.liability.live,
                prematch: limit.liability.prematch
            }
        };
    };

    const addPlayerLimit = () => {
        var defaultLimit = null;


        var eventLimit = props.volumes.EventLimits && props.volumes.EventLimits.hasOwnProperty(props.event.Id) ? props.volumes.EventLimits[props.event.Id] : null;
        var groupLimit = null;
        // check event limit
        if (eventLimit) {
            groupLimit = eventLimit.Groups && eventLimit.Groups[player.group];
            if (groupLimit) {
                defaultLimit = setLimitFrom(groupLimit);
            } else {
                defaultLimit = setLimitFrom(eventLimit.General);
            }
        }

        if (!eventLimit) {
            var leagueLimit = props.volumes.LeagueLimits && props.volumes.LeagueLimits[props.event.LeagueId];
            if (leagueLimit) {
                groupLimit = leagueLimit.Groups && leagueLimit.Groups[player.group];
                if (groupLimit) {
                    defaultLimit = setLimitFrom(groupLimit);
                } else {
                    defaultLimit = setLimitFrom(leagueLimit.General);
                }
            }

            if (!leagueLimit) {
                var defaultLeagueLimit = props.volumes.LeagueLimits && props.volumes.LeagueLimits[0];
                if (defaultLeagueLimit) {
                    groupLimit = defaultLeagueLimit.Groups && defaultLeagueLimit.Groups[player.group];
                    if (groupLimit) {
                        defaultLimit = setLimitFrom(groupLimit);
                    } else {
                        defaultLimit = setLimitFrom(defaultLeagueLimit.General);
                    }
                }
            }
        }

        if (!defaultLimit) {
            defaultLimit = {
                stake: {
                    live: 0,
                    prematch: 0
                },
                liability: {
                    live: 0,
                    prematch: 0
                }
            };
        }

        var limit = {
            id: player.id,
            index: Date.now(),
            username: player.username,
            code: player.code,
            minimumEvent: defaultLimit.minimumEvent,
            marketCap: defaultLimit.marketCap,
            maximumStake: defaultLimit.stake.prematch,
            maximumStakeLive: defaultLimit.stake.live,
            maximumLiability: defaultLimit.liability.prematch,
            maximumLiabilityLive: defaultLimit.liability.live,
            offer: defaultLimit.offer
        };

        var copy = Object.assign([], playerLimits);
        copy.push(limit);
        setPlayerLimits(copy);
    };

    const playerSummary = () => {
        if (!player) return;
        if (!player.id) return;
        return <div className='padding groups borders big'>
            <div className='group gap'>
                <label>Id</label><span>{player.id}</span>
                <label>Username</label><span>{player.username}</span>
                <label>Name</label><span>{player.name} {player.surname}</span>
                <label>Code</label><span>{player.code}</span>
                <label>{lang('Balance')}</label><span>{currency(player.balance, 2, player.currency)}</span>
                <label>{lang('Bonus Balance')}</label><span>{currency(player.bonusBalance, 2, player.currency)}</span>
            </div>
            {player.retailId > 0 &&
                <div className='group gap'>
                    <label>Retail</label><span>{player.retailCode} {player.retailName}</span>
                    <label>{lang('Balance')}</label><span>{currency(player.retailBalance, 2, player.retailCurrency)}</span>
                </div>
            }
            <div className='row'>
                <Button title='Add' onClick={() => {
                    addPlayerLimit(player);
                }} />
            </div>
        </div>;
    };

    const saveEventLimits = () => {
        var saveData = Object.assign([], playerLimits);
        var data = {};
        saveData.map(x => {
            const minimumEvent = parseInt(!x.minimumEvent ? 0 : x.minimumEvent);
            const marketCap = parseInt(!x.marketCap ? 0 : x.marketCap);
            const maximumStakeLive = parseInt(!x.maximumStakeLive ? 0 : x.maximumStakeLive);
            const maximumStake = parseInt(!x.maximumStake ? 0 : x.maximumStake);
            const maximumLiabilityLive = parseInt(!x.maximumLiabilityLive ? 0 : x.maximumLiabilityLive);
            const maximumLiability = parseInt(!x.maximumLiability ? 0 : x.maximumLiability);
            data[x.id] = {
                Id: parseInt(x.id),
                Username: x.username,
                Code: x.code,
                MinimumEvent: isNaN(minimumEvent) ? 0 : minimumEvent,
                MarketCap: isNaN(marketCap) ? 0 : marketCap,
                Stake: {
                    Live: isNaN(maximumStakeLive) ? 0 : maximumStakeLive,
                    Prematch: isNaN(maximumStake) ? 0 : maximumStake
                },
                Liability: {
                    Live: isNaN(maximumLiabilityLive) ? 0 : maximumLiabilityLive,
                    Prematch: isNaN(maximumLiability) ? 0 : maximumLiability
                },
                Offer: x.offer == true
            };
        });

        var eventLimits = props.volumes.EventLimits;
        if (!eventLimits) {
            eventLimits = {};
        }
        if (!eventLimits.hasOwnProperty(props.event.Id)) {
            eventLimits[props.event.Id] = {
                General: {
                    Stake: {
                        Live: 0,
                        Prematch: 0
                    },
                    Liability: {
                        Live: 0,
                        Prematch: 0
                    },
                    MarketCap: 0
                },
                Groups: {},
                Players: {}
            };
        }

        eventLimits[props.event.Id].Players = data;
        eventLimits[props.event.Id].Name = event.Name;
        eventLimits[props.event.Id].Sport = event.Sport;
        eventLimits[props.event.Id].SportId = event.SportId;
        eventLimits[props.event.Id].Region = event.Region;
        eventLimits[props.event.Id].RegionId = event.RegionId;
        eventLimits[props.event.Id].League = event.League;
        eventLimits[props.event.Id].LeagueId = event.LeagueId;
        eventLimits[props.event.Id].Date = event.EventDate;

        application().resolve(null, Endpoints.SaveEventLimits, eventLimits).then(() => {
            application().modal.open(<Dialog title={'Limits'}>
                {lang('Player limits saved')}
            </Dialog >);
        });
    };

    const tabButtons = [
        {
            name: 'selections',
            title: 'Selections'
        },
        {
            name: 'volumes',
            title: 'Market Volumes'
        },
        {
            name: 'tickets',
            title: 'Tickets'
        },
        {
            name: 'players',
            title: 'Player Limits'
        }
    ];

    if (eventLimitEditing) {
        return <SportEventLimits event={eventLimitEditing} onClose={() => {
            editEventLimit(null);
        }} />;
    }

    return <div className='centrum-flex vertical padding'>
        <div className='centrum-flex'>
            <div className='centrum-flex vertical'>
                <div className='group'>
                    <label>{lang('Slips')}</label>
                </div>
                <div className='group'>
                    <label>{lang('Total')}</label><span>{event.Slips.Prematch + event.Slips.Live}</span>
                    <label>{lang('Live')}</label><span>{event.Slips.Live}</span>
                </div>
                <div className='centrum-flex'>
                    <div className='centrum-flex vertical'>
                        <div className='group'>
                            <label>{lang('Max Stakes')}</label>
                            {renderVolumeInformation(event.MaxStakeLimit, event.Volumes.Stake.Prematch)}
                        </div>
                    </div>
                    <div className='centrum-flex vertical'>
                        <div className='group'>
                            <label>{lang('Max Liability')}</label>
                            {renderVolumeInformation(event.MaxLiabilityLimit, event.Volumes.Liability.Prematch)}
                        </div>
                    </div>
                </div>
                {vSummary.length > 0 && <Table model={volumeSummaryModel} data={vSummary} key={vSummary} />}
                <div className='group'>
                    <label>{lang('Event Limit')}</label><span>{event.Limit}</span>
                </div>
                <div className='centrum-flex auto-width'>
                    <Button title='Change Limits' onClick={() => {
                        var row = event;
                        const e = {
                            id: row.Id,
                            date: row.EventDate,
                            sport: row.Sport,
                            sportId: row.SportId,
                            region: row.Region,
                            regionId: row.RegionId,
                            leagueId: row.LeagueId,
                            league: row.League,
                            name: row.Name
                        };
                        editEventLimit(e);
                    }} />
                    <Button title={event.Stop ? 'Bet Start' : 'Bet Stop'} onClick={() => {
                        if (event.Stop) {
                            betStart(event);
                        } else {
                            betStop(event);
                        }

                    }} />
                </div>
            </div>
            <Tracker id={props.event.Id} />
        </div>
        <Tab buttons={tabButtons}>
            <EventSelectionVolumes context={context} />
            <div key='volumes'>
                {showVolumes()}
            </div>
            <div key='tickets'>
                <Search
                    model={searchBetSlips}
                    resultModel={betSlipResults}
                    data={slips}
                    onSubmit={(data, qb) => {
                        qb.fields = qb.fields.filter(x => x.name != 'EventId');
                        qb.fields.push({
                            exact: true,
                            and: true,
                            name: 'EventId',
                            value: props.event.Id
                        });
                        apiResult(null, Endpoints.SearchSportEventTickets, qb, 'loading bet slips', 'unable to load slips').then((result) => {
                            setSlips(result);
                        });
                    }}
                />
            </div>
            <Card caption='Player' >
                <React.Fragment>
                    <Form>
                        <div className='row'>
                            <FormInput title='Id' name='Id' onKeyUp={(a, b, value, e) => {
                                if (e.key == 'Enter') {
                                    search({ 'Id': parseFloat(value) });
                                }
                            }} />
                            <FormInput title='Code' name='Code' onKeyUp={(a, b, value, e) => {
                                if (e.key == 'Enter') {
                                    search({ 'Code': value });
                                }
                            }} />
                            <FormInput title='Username' name='Username' onKeyUp={(a, b, value, e) => {
                                if (e.key == 'Enter') {
                                    search({ 'Username': value });
                                }
                            }} />
                            <FormInput title='Email' name='Email' onKeyUp={(a, b, value, e) => {
                                if (e.key == 'Enter') {
                                    search({ 'Email': value });
                                }
                            }} />
                        </div>
                    </Form>
                    {playerSummary()}
                </React.Fragment>
                <EditableTable data={playerLimits} model={playerLimitsModel} key={playerLimits} />
                <Card>
                    <div className='centrum-flex padding'><Button className='alignRight' icon={'save'} title='Save' onClick={() => saveEventLimits()} /></div>
                </Card>
            </Card>
        </Tab>

    </div >;
};

EventSummary.propTypes = {
    event: PropTypes.object,
    volumes: PropTypes.object
};

