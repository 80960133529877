import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditableTable } from '../../Controls/Table/Editable/EditableTable';
import { Card } from '../../Centrum/Card/Card';
import { Button } from '../../Controls/Buttons/Button';
import { application, Endpoints } from '../../Application/Application';
import { lang } from '../../Centrum/Localization/lang';

export const SetupLeagues = (props) => {
    const [category, selectCategory] = useState(null);
    const [regions, selectRegions] = useState(null);
    const [region, selectRegion] = useState(null);
    const [leagues, updateLeagues] = useState(null);
    const [weights, setWeights] = useState(null);


    useEffect(() => {
        application().resolve(null, `${Endpoints.SetupSportLeagues}`).then((result) => {
            try {
                const converted = JSON.parse(result.result);
                setWeights(converted);
            } catch (err) {
                //
            }

            var categories = props.categories.filter(x => x.enabled);
            selectCategory(categories[0]);
        });

    }, []);

    useEffect(() => {
        if (!category) return;
        application().resolve(null, `${Endpoints.SportRegions}/${category.id}`).then((result) => {
            result.result.key = Date.now();
            selectRegions(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [category]);

    useEffect(() => {
        if (!regions) return;
        selectRegion(regions[0]);
    }, [regions]);

    useEffect(() => {
        if (!category) return;
        if (!region) return;
        application().resolve(null, `${Endpoints.SportLeagues}/${category.id}/${region.id}`).then((result) => {
            result.result.key = Date.now();
            const key = `${category.id}_${region.id}`;
            const leagueWeights = weights != null ? (weights[key] ? weights[key] : {}) : {};
            result.result.map(x => {
                if (leagueWeights[x.id]) {
                    x.weight = leagueWeights[x.id].weight;
                    x.enabled = leagueWeights[x.id].enabled;
                } else {
                    x.enabled = true;
                }
            });

            result.result = result.result.sort((a, b) => b.weight > a.weight ? 1 : -1);
            updateLeagues(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [region]);


    const save = () => {
        const key = `${category.id}_${region.id}`;
        var data = Object.assign({}, weights);
        data[key] = {};

        leagues.map((x, index) => {
            var league = { ...x };
            league.weight = leagues.length - index;
            data[key][x.id] = league;
        });

        setWeights(data);
        application().resolve(null, Endpoints.SaveSetupSportLeagues, data).then(() => {
            application().snackbar.open('League entities saved.');
        });
    };

    if (!props.categories) return <div></div>;
    if (!category) return <div />;
    if (!regions) return <div />;
    return <Card caption='Manage Leagues' menu={<Button title='Save' onClick={() => save()} />}>
        <div className='padding centrum-flex'>
            <div className='centrum-flex vertical'>
                <label>{lang('Sports')}</label>
                <select defaultValue={category.id} onChangeCapture={(e) => {
                    selectCategory(props.categories.find(x => x.id == parseInt(e.target.value)));
                }}>

                    {props.categories.map(x => {
                        return <option key={x.id} value={x.id}>{x.name}</option>;
                    })}
                </select>
            </div>
            <div className='centrum-flex vertical'>
                <label>{lang('Regions')}</label>
                <select defaultValue={region ? region.id : 0} onChangeCapture={(e) => {
                    selectRegion(regions.find(x => x.id == parseInt(e.target.value)));
                }}>

                    {regions.map(x => {
                        return <option key={x.id} value={x.id}>{x.name}</option>;
                    })}
                </select>
            </div>
        </div>
        {
            leagues && <EditableTable
                key={leagues.key}
                draggable={true}
                model={{
                    fields: [
                        {
                            name: 'id',
                            display: 'Id'
                        },
                        {
                            name: 'name',
                            display: 'Name'
                        },
                        {
                            name: 'type',
                            display: 'Type'
                        },
                        {
                            name: 'enabled',
                            display: 'Enabled',
                            type: 'bool',
                            editable: true
                        }
                    ]
                }} data={leagues} onDataChange={(data) => updateLeagues(data)} />
        }
    </Card>;
};


SetupLeagues.propTypes = {
    categories: PropTypes.array
};