// IMPORT PACKAGE REFERENCES
import './_modals.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Overlay extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({ open: false }, props);
    }

    close() {
        this.setState({ open: false, overlay: null });
    }

    open(overlay) {
        this.setState({ open: true, overlay: overlay });
    }

    render() {
        if (!this.state.open) return <React.Fragment />;
        return <div className='overlay'>
            {this.state.overlay}
        </div>;
    }
}

Overlay.defaultProps = {

};

Overlay.propTypes = {

};


export const PageOverlay = (props) => {
    return <div className='overlay-content'>
        {props.children}
    </div>;
};

PageOverlay.propTypes = {
    children: PropTypes.node
};