/* eslint-disable react/display-name */
import React from 'react';

import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { currency } from '../Localization/lang';
import { ProviderGameTypes, Languages, GenderSources, PlayerFlagsToClass, resolveFlags, SportsBetTypes, SportsTicketTypes, SportsTicketStatus, SportsTicketResolvedStatus } from './ModelEnums';
import { gotoProfile } from './Transactions';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';
import { currencyAndFooter } from './ReportsModel';

function getDevice(agent) {
    if (agent === null || agent === undefined) return '';
    if (agent.match(/Android/i)) return 'Android';
    if (agent.match(/webOS/i)) return 'Smart TV';
    if (agent.match(/iPhone/i)) return 'Iphone';
    if (agent.match(/iPad/i)) return 'Ipad';
    if (agent.match(/iPod/i)) return 'Ipod';
    if (agent.match(/BlackBerry/i)) return 'BlackBerry';
    if (agent.match(/Windows Phone/i)) return 'Windows Phone';
    if (agent.match(/Windows/i)) return 'Windows';
    return 'Other';
}

export const isMobile = (agent) => {
    const device = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(agent) == true ? 'Mobile' : 'Desktop';

    const iphone = /iPhone|iPad|iPod/i.test(agent) == true ? 'Apple' : 'Android';
    return device + (device == 'Mobile' ? ` ${iphone}` : '');
};

const renderSmallText = (value) => {
    return <div className='wrapped-text'>
        <a title={value}>{value}</a>
    </div>;
};

const deviceInformation = (val) => {
    var device = getDevice(val);
    return (
        <div><b>{device}</b><br />{val}</div>
    );
};



export const PlayerModels = {
    playerSummary: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Operator'
            },
            {
                name: 'Code',
                type: InputTypes.text,
            },
            {
                name: 'Created',
                display: 'Register Date',
                type: InputTypes.dateTime
            },
            {
                name: 'RetailName',
                display: 'Retail Name'
            },
            {
                name: 'Affiliate',
                display: 'Affiliate'
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'Country',
                type: InputTypes.text
            },
            {
                name: 'City',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'PlayerFlags',
                display: 'Flags',
                formatter: (val) => {
                    return PlayerFlagsToClass(resolveFlags(val));
                },
                type: InputTypes.text
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text
            },
            {
                name: 'PlayerGroup',
                display: 'Player Group'
            },
            {
                name: 'LastLoginIp',
                display: 'Last Login Ip',
                type: InputTypes.text
            },
            {
                name: 'LastLogin',
                display: 'Last Login Date',
                type: InputTypes.dateTime
            },
            {

                name: 'Balance',
                type: InputTypes.number,
                max: 20,
                formatter: (val, data) => {
                    return currency(val, 2, data.Currency);
                },
                rowId: (val, row) => {
                    return 'player_balance_' + row.Id + '_' + row.Currency;
                }
            },
            {
                name: 'Status',
                formatter: (val, row, isFooter, isExporting) => {
                    var enabled = row.Enabled;
                    var testAccount = row.TestAccount;
                    if (isExporting) return `${enabled ? 'Enabled' : 'Disabled'}${testAccount ? ' Test Account' : ''}`;
                    return <div className='flex gap-2'>
                        <span>{enabled ? 'Enabled' : 'Disabled'}</span>
                        {testAccount && <span>Test</span>}
                    </div>;
                }
            }
        ]
    },
    newPlayersSummary: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Operator'
            },
            {
                name: 'Code',
                type: InputTypes.text,
            },
            {
                name: 'Created',
                display: 'Register Date',
                type: InputTypes.dateTime
            },
            {
                name: 'RetailName',
                display: 'Retail Name'
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'Country',
                type: InputTypes.text
            },
            {
                name: 'City',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'PlayerFlags',
                display: 'Flags',
                formatter: (val) => {
                    return PlayerFlagsToClass(resolveFlags(val));
                },
                type: InputTypes.text
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text
            },
            {
                name: 'PlayerGroup',
                display: 'Player Group'
            },
            {
                name: 'TestAccount',
                display: 'Test Account'
            },
            {
                name: 'LastLoginIp',
                display: 'Last Login Ip',
                type: InputTypes.text
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true
            }
        ]
    },
    editPlayer: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.hidden,
                identity: true,
                readonly: true,
                hidden: true
            },
            {
                name: 'Created',
                display: 'Register Date',
                type: InputTypes.hidden,
                readonly: true,
                hidden: true
            },
            {
                name: 'Code'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                max: 32,
                readonly: true
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                readonly: true
            },
            {
                name: 'Name',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'IdNumber',
                type: InputTypes.text
            },
            {
                name: 'BirthDay',
                display: 'Birthday',
                type: InputTypes.date,
                layout: 'single-row',
            },
            {
                name: 'Gender',
                type: InputTypes.select,
                values: GenderSources,
                max: 32
            },
            {
                name: 'Country',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'countryCodes',
                    bindingKey: 'CountryCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'City',
                type: InputTypes.text,
            },
            {
                name: 'Phone',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Language',
                type: InputTypes.select,
                values: Languages
            }
        ]
    },
    editPlayerWithoutPhone: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.hidden,
                identity: true,
                readonly: true,
                hidden: true
            },
            {
                name: 'Created',
                display: 'Register Date',
                type: InputTypes.hidden,
                readonly: true,
                hidden: true
            },
            {
                name: 'Code'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                max: 32,
                readonly: true
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                readonly: true
            },
            {
                name: 'Name',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'IdNumber',
                type: InputTypes.text
            },
            {
                name: 'BirthDay',
                display: 'Birthday',
                type: InputTypes.date,
                layout: 'single-row',
            },
            {
                name: 'Gender',
                type: InputTypes.select,
                values: GenderSources,
                max: 32
            },
            {
                name: 'Country',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'countryCodes',
                    bindingKey: 'CountryCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'City',
                type: InputTypes.text,
            },
            {
                name: 'Language',
                type: InputTypes.select,
                values: Languages
            }
        ]
    },
    editPlayerGroup: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.hidden,
                identity: true,
                readonly: true,
                hidden: true
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            }
        ]
    },
    editPlayerTag: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.hidden,
                identity: true,
                readonly: true,
                hidden: true
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    enableDisablePlayer: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                readonly: true
            },
            {
                name: 'Operator',
                display: 'Operator',
                readonly: true
            },
            {
                name: 'Created',
                display: 'Register Date',
                readonly: true
            },
            {
                name: 'Username',
                type: InputTypes.text,
                readonly: true
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                readonly: true
            },
            {
                name: 'Reason',
                display: 'Reason',
                multiLine: true
            }
        ]
    },
    playerSearch: {
        fields: [
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Code',
                type: InputTypes.text,
                queryBuilderOptions: {
                    Exact: true
                }
            },
            {
                name: 'Username',
                type: InputTypes.text,
                queryBuilderOptions: {
                    Exact: false
                }
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                queryBuilderOptions: {
                    exact: false
                }
            },
            {
                name: 'IdNumber',
                display: 'Id / Passport',
                type: InputTypes.text,
            },
            {
                name: 'Email',
                display: 'Email',
                type: InputTypes.text,
            },
            {
                name: 'Phone',
                display: 'Phone',
                type: InputTypes.text,
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text,
            },
            {
                name: 'Balance',
                type: InputTypes.number,
                max: 32
            },
            {
                name: 'TestAccount',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    1: 'Test Account'
                }
            },
            {
                name: 'LastLoginIp',
                display: 'Last Login Ip',
                type: InputTypes.text
            },
            {
                name: 'Enabled',
                display: 'Enabled',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    1: 'Enabled',
                    0: 'Disabled'
                }
            },
        ]
    },
    newPlayersSearch: {
        fields: [
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Created',
                display: 'Register Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Month']
                }
            },
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Code',
                type: InputTypes.text,
                queryBuilderOptions: {
                    Exact: true
                }
            },
            {
                name: 'Username',
                type: InputTypes.text,
                queryBuilderOptions: {
                    Exact: false
                }
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'IdNumber',
                display: 'Id / Passport',
                type: InputTypes.text,
            },
            {
                name: 'Email',
                display: 'Email',
                type: InputTypes.text,
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text,
            },
            {
                name: 'TestAccount',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    1: 'Test Account'
                }
            },
            {
                name: 'LastLoginIp',
                display: 'Last Login Ip',
                type: InputTypes.text
            },
            {
                name: 'Enabled',
                display: 'Enabled',
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    1: 'Enabled',
                    0: 'Disabled'
                }
            },
        ]
    },
    newPlayerGroup: {
        fields: [
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Description',
                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    newPlayer: {
        fields: [
            {
                name: 'OperatorId',
                display: 'Operator',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Group',
                display: 'Player Group',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'myPlayerGroups',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Surname',
                type: InputTypes.text
            },
            {
                name: 'Email',
                type: InputTypes.text
            },
            {
                name: 'Username',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Password',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Language',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'languages/available',
                    bindingKey: 'key',
                    bindingText: ['name']
                }
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true
            }
        ]
    },
    searchPlayerGameHistory: {
        queries: {
        },
        fields: [
            {
                name: 'RoundId',
                display: 'Round Id'
            },
            {
                name: 'PlayerId',
                type: InputTypes.number,
                hidden: true
            },
            {
                name: 'Currency',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'enabledCurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['Name']
                }
            },
            {
                name: 'EventTime',
                display: 'Start Time',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/gaming',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'GameName',
                display: 'Game',
                type: InputTypes.text
            }

        ]
    },
    resultPlayerGameHistory: {
        fields: [
            {
                name: 'id',
                display: 'Id'
            },
            {
                name: 'eventTime',
                display: 'Time',
                type: InputTypes.dateTime
            },
            {
                name: 'sessionId',
                display: 'Session Id'
            },
            {
                name: 'provider',
                display: 'Provider'
            },
            {
                name: 'gameType',
                display: 'Game Type',
                type: InputTypes.select,
                values: ProviderGameTypes
            },
            {
                name: 'gameName',
                display: 'Game'
            },
            {
                name: 'roundId',
                display: 'Round'
            },
            {
                name: 'bet',
                display: 'Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'ggr',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'rtp',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    if (isFooter) return;
                    if (isExporting) return;
                    var bets = row.bet;
                    var g = bets - row.win;
                    var perc = 100 - ((g * 100) / bets);
                    if (perc < 0) perc = 0;

                    return perc.toFixed(2) + '%';
                }
            },
        ],
        onRenderRow: (rowObject) => {
            if (rowObject.data.win > Math.abs(rowObject.data.bet)) {
                rowObject.className = 'player-win';
            } else {
                if (rowObject.data.Type == 1) {
                    rowObject.className = 'player-lost';
                }
            }
            return rowObject;
        }
    },
    searchPlayerGameSessions: {
        queries: {
        },
        fields: [
            {
                name: 'SessionId',
                display: 'Sesion Id'
            },
            {
                name: 'EntryDate',
                display: 'Start Time',
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'ProviderId',
                display: 'Provider',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'providers/gaming',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'GameName',
                display: 'Game',
                type: InputTypes.text
            }
        ]
    },
    resultPlayerGameSession: {
        fields: [
            {
                name: 'id',
                max: 5
            },
            {
                name: 'entryDate',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'provider',
                display: 'Provider'
            },
            {
                name: 'gameType',
                display: 'Game Type',
                type: InputTypes.select,
                values: ProviderGameTypes
            },
            {
                name: 'gameName',
                display: 'Game'
            },
            {
                name: 'totalHands',
                display: 'Hands'
            },
            {
                name: 'totalBet',
                display: 'Total Bet',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'totalWin',
                display: 'Total Win',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            },
            {
                name: 'ggr',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, row, isFooter, isExporting) => {
                    return currencyAndFooter(val, row, isFooter, isExporting);
                }
            }
        ],
        onRenderRow: (rowObject) => {
            if (rowObject.data.totalWin > Math.abs(rowObject.data.totalBet)) {
                rowObject.className = 'player-win';
            } else {
                if (rowObject.data.Type == 1) {
                    rowObject.className = 'player-lost';
                }
            }
            return rowObject;
        }
    },
    searchPlayerBetHistory: {
        queries: {
        },
        fields: [
            {
                name: 'EntryDate',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'ExternalTicketId',
                display: 'Ticket No',
                type: InputTypes.number
            },
            {
                name: 'PlayerId',
                type: InputTypes.number,
                hidden: true
            },
            {
                name: 'Type',
                display: 'Type',
                type: InputTypes.select,
                values: SportsBetTypes,
                acceptEmptyValues: true
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                values: SportsTicketTypes,
                acceptEmptyValues: true
            },
            {
                name: 'Status',
                display: 'Ticket Status',
                type: InputTypes.select,
                values: SportsTicketStatus,
                acceptEmptyValues: true
            },
            {
                name: 'PromotionId',
                display: 'Promotion',
                type: InputTypes.multipleSelect,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'promotion',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            }
        ]
    },
    searchResolvedTickets: {
        queries: {
        },
        fields: [
            {
                name: 'Updated',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'ExternalTicketId',
                display: 'Ticket No',
                type: InputTypes.number
            },
            {
                name: 'PlayerId',
                type: InputTypes.number,
                hidden: true
            },
            {
                name: 'Type',
                display: 'Type',
                type: InputTypes.select,
                values: SportsBetTypes,
                acceptEmptyValues: true
            },
            {
                name: 'TicketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                values: SportsTicketTypes,
                acceptEmptyValues: true
            },
            {
                name: 'Status',
                display: 'Ticket Status',
                type: InputTypes.select,
                values: SportsTicketResolvedStatus,
                acceptEmptyValues: true
            }
        ]
    },
    resultPlayerBetHistory: {
        fields: [
            {
                name: 'id',
                display: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'entryDate',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'type',
                display: 'Type',
                type: InputTypes.select,
                values: SportsBetTypes

            },
            {
                name: 'ticketType',
                display: 'Ticket Type',
                type: InputTypes.select,
                values: SportsTicketTypes
            },
            {
                name: 'status',
                display: 'Status',
                type: InputTypes.select,
                values: SportsTicketStatus
            },
            {
                name: 'odds',
                display: 'Odds',
                type: InputTypes.number
            },
            {
                name: 'selections',
                display: 'Bets',
                type: InputTypes.number
            },
            {
                name: 'winSelection',
                display: 'Resolved',
                type: InputTypes.number
            },
            {
                name: 'bet',
                display: 'Bet',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(Math.abs(val), 2, row.currency);
                }
            },
            {
                name: 'win',
                display: 'Win',
                type: InputTypes.number,
                formatter: (val, row) => {
                    return currency(Math.abs(val), 2, row.currency);
                }
            }
        ],
        onRenderRow: (rowObject) => {
            if (rowObject.data.win > Math.abs(rowObject.data.bet)) {
                rowObject.className = 'player-win';
            } else {
                if (rowObject.data.Type == 1) {
                    rowObject.className = 'player-lost';
                }
            }
            return rowObject;
        }
    },
    playerBetDetails: {
        fields: [
            {
                name: 'EventName',
                display: 'Event',
                type: InputTypes.string
            },
            {
                name: 'Live',
                display: 'Live',
                type: InputTypes.bool
            },
            {
                name: 'MarketName',
                display: 'Market',
                type: InputTypes.text
            },
            {
                name: 'Selection',
                display: 'Bet',
                type: InputTypes.text
            },
            {
                name: 'Win',
                display: 'Win',
                type: InputTypes.bool
            },
            {
                name: 'WinAmount',
                display: 'Amount',
                type: InputTypes.number
            }
        ],
        onRenderRow: (rowObject) => {
            if (rowObject.data.Win > 0) {
                rowObject.className = 'player-win';
            }
            return rowObject;
        }
    },
    changePlayerPassword: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                readonly: true,
                hidden: true
            },
            {
                name: 'Password',
                type: InputTypes.text,
                validation: 'text',
                max: 32
            },
            {
                name: 'PasswordConfirm',
                type: InputTypes.text,
                max: 32
            }
        ]
    },
    playerActivitySearch: {
        fields: [
            {
                name: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'PlayerId',
                hidden: true
            },
            {
                name: 'Created',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Month']
                }
            },
            {
                name: 'Activity',
                display: 'Activity',
                max: 255,
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: {
                    'Login': 'Login',
                    'Open Game': 'Open Game',
                    'Open Crash Game': 'Open Crash Game',
                    'Game': 'Game',
                    'Sign out & login': 'Signed out due to new login',
                    'Bet': 'External Withdraw',
                    'Favourite Game': 'Toggle Favourite Game',
                    'Logout': 'Logout',
                    'Win': 'Bet',
                    'Cancel Bet': 'Cancel Bet',
                    'Launch Game External Api': 'Launch Game External Api',
                    'RemoteGamingApi Game Launch': 'RemoteGamingApi Game Launch'
                }
            },
            {
                name: 'Ip',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Token',
                max: 255,
                type: InputTypes.string
            }
        ]
    },
    playerActivitySearchResult: {
        fields: [
            {
                name: 'Username',
                max: 32,
                type: InputTypes.text
            },
            {
                name: 'Created',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'Activity',
                display: 'Activity',
                max: 255,
                type: InputTypes.text
            },
            {
                name: 'Ip',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Agent',
                display: 'Device',
                max: 255,
                type: InputTypes.string,
                formatter: (val) => {
                    console.log(val);
                    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(val)) {
                        return 'tablet';
                    }
                    if (
                        /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(val)
                    ) {
                        return 'mobile';
                    }
                    return 'desktop';
                }
            },
            {
                name: 'Data',
                max: 255,
                type: InputTypes.string
            }
        ]
    },
    playerActivitySummary: {
        fields: [
            {
                name: 'created',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'activity',
                display: 'Activity',
                max: 255,
                type: InputTypes.text
            },
            {
                name: 'data',
                display: 'Data',
                max: 255,
                type: InputTypes.text
            }
        ]
    },
    playerNotifications: {
        fields: [
            {
                name: 'PlayerId',
                hidden: true
            },
            {
                name: 'EntryDate',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Month']
                }
            }
        ]
    },
    playerNotification: {
        fields: [
            {
                name: 'EntryDate',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'Title',
                display: 'Activity',
            },
            {
                name: 'Message',
                display: 'Message',
                formatter: (val, row) => {
                    return renderSmallText(row.Message);
                }
            },
            {
                name: 'Admin',
                display: 'Admin'
            },
            {
                name: 'Read',
                display: 'Read',
                type: InputTypes.bool
            }
        ]
    },
    playersUsingSameIp: {
        fields: [
            {
                name: 'Username',
                max: 32,
                type: InputTypes.text
            },
            {
                name: 'Name',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Created',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Month']
                }
            },
            {
                name: 'Ip',
                display: 'IP',
                max: 255,
                type: InputTypes.string
            }
        ]
    },
    sameIpSearchResult: {
        fields: [
            {
                name: 'Id',
                display: 'User Id'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                formatter: (val, row) => {
                    return gotoProfile(row.Id, row.Username);
                }
            },
            {
                name: 'Name',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'PlayerFlags',
                display: 'Flags',
                formatter: (val) => {
                    return PlayerFlagsToClass(resolveFlags(val));
                },
                type: InputTypes.text
            },
            {
                name: 'Created',
                display: 'Register Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'Ip',
                display: 'Same IP',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'LastLogin',
                display: 'Last Login',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'LastLoginIp',
                display: 'Last Login IP',
                max: 19
            },
        ]
    },
    searchPlayerPurse: {
        fields: [
            {
                name: 'CreationDate',
                display: 'Date',
                type: InputTypes.dateTime,
                acceptEmptyValues: true,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges.Today
                }
            },
            {
                name: 'OperatorId',
                display: 'Operator',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Username',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Amount',
                display: 'Purse Balance',
                type: InputTypes.number
            },
            {
                name: 'Profit',
                type: InputTypes.number,
                max: 128
            },
            {
                name: 'Room',
                display: 'Room',
                type: InputTypes.select,
                acceptEmptyValues: true,
                valueEndPoint: {
                    source: 'rooms',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Status',
                type: InputTypes.select,
                values: { 0: 'Closed', 1: 'Active' }
            }
        ]
    },
    playerPurseResult: {
        fields: [
            {
                name: 'Id',
                display: 'Id',
                type: InputTypes.number
            },
            {
                name: 'CreationDate',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'Operator',
                type: InputTypes.text,
                validation: 'text'
            },
            {
                name: 'Username',
                type: InputTypes.text,
                validation: 'text',
                link: {
                    action: 'gotoProfile'
                }
            },
            {
                name: 'Room',
                display: 'Room',
                type: InputTypes.text
            },
            {
                name: 'Amount',
                display: 'Purse Balance',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val);
                },
            },
            {
                name: 'BuyIn',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val);
                },
            },
            {
                name: 'Profit',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val);
                },
            },
            {
                name: 'Status',
                type: InputTypes.select,
                values: { 0: 'Closed', 1: 'Active' }
            }
        ]
    },
    playerSummarySystemData: {
        fields: [
            {
                name: 'operator',
                display: 'Operator'
            },
            {
                name: 'PlayerGroup',
                display: 'Group'
            },
            {
                name: 'Tag',
                display: 'Tag'
            },
            {
                name: 'created',
                display: 'Registration Date',
                type: InputTypes.dateTime
            },
            {
                name: 'lastLogin',
                display: 'Last Login',
                type: InputTypes.dateTime
            },
            {
                name: 'lastLoginIp',
                display: 'Last Login Ip'
            },
            {
                name: 'lastLoginDevice',
                display: 'Device',
                type: InputTypes.custom,
                formatter: (val) => {
                    return deviceInformation(val);
                }
            },
            {
                name: 'enabled',
                display: 'Enabled'
            }
        ]
    },
    playerSummaryFinancialInformation: {
        fields: [
            {
                name: 'currency',
                display: 'Currency',
                hidden: true
            },
            {
                name: 'balance',
                display: 'Balance',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'totalDepositCredit',
                display: 'Total Deposit Credit',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'totalWithdrawCredit',
                display: 'Total Credit Withdraw',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'pendingDepositAmount',
                display: 'Pending Deposits',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'lockedWithdrawalAmount',
                display: 'Locked Withdrawals',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'lastDeposit',
                display: 'Last Deposit',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'lastWithdraw',
                display: 'Last Withdraw',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'totalBetAmount',
                display: 'Total Bet',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'totalWinAmount',
                display: 'Total Win',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            },
            {
                name: 'ggr',
                display: 'GGR',
                formatter: (val, data) => {
                    return currency(val, 2, data.currency);
                }
            }
        ]
    },
    playerRegistrationInformation: {
        fields: [
            {
                name: 'name',
                display: 'Name'
            },
            {
                name: 'surname',
                display: 'Surname'
            },
            {
                name: 'email',
                display: 'Email'
            },
            {
                name: 'birthDay',
                display: 'Date of Birth'
            },
            {
                name: 'idNumber',
                display: 'Id Number'
            },
            {
                name: 'gender',
                display: 'Gender'
            },
            {
                name: 'country',
                display: 'Country'
            },
            {
                name: 'city',
                display: 'City'
            },
            {
                name: 'address',
                display: 'Address'
            },
            {
                name: 'phone',
                display: 'Phone'
            },
            {
                name: 'isEmailVerified',
                display: 'Email Verified'
            },
            {
                name: 'messageNotification',
                display: 'Internal Messages'
            },
            {
                name: 'callNotification',
                display: 'Phone Calls'
            },
            {
                name: 'pushNotification',
                display: 'Push Notification'
            },
            {
                name: 'emailNotification',
                display: 'Email Notification'
            }
        ]
    },
    playerAccounts: {
        fields: [
            {
                name: 'id',
                display: 'Id'
            },
            {
                name: 'currency',
                display: 'Currency'
            },
            {
                name: 'balance',
                display: 'Balance'
            },
            {
                name: 'default',
                display: 'Default',
                type: InputTypes.bool
            },
            {
                name: 'enabled',
                display: 'Enabled',
                type: InputTypes.bool
            }
        ]
    },
    playerNotes: {
        fields: [
            {
                id: 'id',
                display: 'Id'
            },
            {
                name: 'entryDate',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'notes',
                display: 'Notes'
            },
            {
                name: 'notedBy',
                display: 'Admin'
            }
        ]
    },
    addNote: {
        fields: [
            {
                name: 'notes',
                display: 'Notes',
                type: InputTypes.text,
                multiLine: true
            }
        ]
    },
    searchAffiliatePlayer: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Username',
                type: InputTypes.text,
                queryBuilderOptions: {
                    Exact: false
                }
            },
            {
                name: 'IdNumber',
                display: 'Id / Passport',
                type: InputTypes.text,
            },
            {
                name: 'Email',
                display: 'Email',
                type: InputTypes.text,
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text,
            },
            {
                name: 'Balance',
                type: InputTypes.number,
                max: 32
            }
        ]
    },
    affilliatePlayerSummary: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name: 'Created',
                display: 'Register Date',
                type: InputTypes.dateTime
            },
            {
                name: 'Username',
                type: InputTypes.text,
            },
            {
                name: 'Email',
                type: InputTypes.text
            },
            {
                name: 'Phone',
                type: InputTypes.text
            },
            {
                name: 'Country',
                type: InputTypes.text
            },
            {
                name: 'City',
                type: InputTypes.text,
                max: 32
            },
            {
                name: 'PlayerFlags',
                display: 'Flags',
                formatter: (val) => {
                    return PlayerFlagsToClass(resolveFlags(val));
                },
                type: InputTypes.text
            },
            {
                name: 'Tag',
                display: 'Tags',
                type: InputTypes.text
            },
            {
                name: 'PlayerGroup',
                display: 'Player Group'
            },
            {
                name: 'Balance',
                type: InputTypes.number,
                max: 20,
                formatter: (val, data) => {
                    return currency(val, 2, data.Currency);
                }
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true
            }
        ]
    },
};