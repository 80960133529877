import './_event-codes.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../../Application/Application';
import { Card } from '../../../../Centrum/Card/Card';
import { InputTypes } from '../../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Button } from '../../../../Controls/Buttons/Button';
import { Form } from '../../../../Controls/Form/Form';
import { FormInput } from '../../../../Controls/Form/FormInput';
import { ConfirmationModal } from '../../../../Modal/Modals/Confirmation/ConfirmationModal';
import { addDays } from 'date-fns';
import { lang } from '../../../../Centrum/Localization/lang';
import { LeagueCard } from './LeagueCard';


const Bulletin = (props) => {
    var form = null;
    if (!props.bulletin) {
        return <div>{lang('No bulletin found')}</div>;
    }
    return <Form data={props.bulletin} ref={(r) => form = r}>
        <div className='row'>
            <FormInput title='Start Date' type={InputTypes.date} name='dateStart' />
            <FormInput title='End Date' type={InputTypes.date} name='dateEnd' />
        </div>
        <div className='row'>
            <Button title='Update' className='alignRight' onClick={() => {
                var formData = form.submit();
                formData.data = JSON.stringify(props.bulletin.data);
                console.log(formData.data);
                update(formData, (updatedData) => {
                    if (props.onUpdated) {
                        props.onUpdated(updatedData);
                    }
                });
            }} />
        </div>
    </Form>;
};

Bulletin.propTypes = {
    bulletin: PropTypes.object,
    onUpdated: PropTypes.func
};


const addNew = (onSave) => {
    var form = null;
    const now = new Date();
    const startDate = now.toISOString();
    const endDate = addDays(now, 7).toISOString();
    application().modal.open(<ConfirmationModal title='Add new' onConfirm={() => {
        var data = form.submit();
        application().resolve(null, Endpoints.SaveBulletin, data, 'saving please wait', 'unable to save bulletin').then((result) => {
            result.result.data = {
                leagues: {},
                events: {}
            };
            onSave(result.result);
        });
    }}>
        <Form ref={(r) => form = r} data={{ DateStart: startDate, DateEnd: endDate, Active: false }}>
            <div className='row'>
                <FormInput title='Start Date' type={InputTypes.date} name='DateStart' />
                <FormInput title='End Date' type={InputTypes.date} name='DateEnd' />
            </div>
        </Form>
    </ConfirmationModal>);
};


const update = (data, onUpdated) => {
    application().resolve(null, Endpoints.SaveBulletin, data, 'saving please wait', 'unable to save bulletin').then((result) => {
        onUpdated(result.result);
    });
};




export const EventCodes = (props) => {
    const categories = props.categories;
    if (!categories) return <React.Fragment />;

    const [bulletin, setBulletin] = useState(null);
    const [category, selectCategory] = useState(null);
    const [leagues, selectLeagues] = useState([]);

    const [updateKey, setUpdateKey] = useState(0);
    const [topLeagues, setTopLeagues] = useState(null);
    const [showOnlySelected, toggleShowSelected] = useState(false);
    const sortLeagues = (a, b) => {
        if (a.sport.id < b.sport.id) return -1;
        if (a.sport.id > b.sport.id) return 1;
        if (b.weight < a.weight) return -1;
        if (b.weight > a.weight) return 1;
        if (b.totalEvents == a.totalEvents) {
            return b.name > a.name ? -1 : 1;
        }
        return b.totalEvents - a.totalEvents;
    };

    useEffect(() => {
        application().resolve(null, Endpoints.GetTopLeagues, {}, 'fetching top leagues', 'unable to fetch top leagues').then((result) => {
            try {
                var data = JSON.parse(result.result);
                if (!data) data = [];
                setTopLeagues(data);
            } catch (err) {
                setTopLeagues([]);
            }
        });
    }, []);

    useEffect(() => {
        application().resolve(null, Endpoints.GetActiveBulletin, {}, 'fetching active bulletin', 'unable to fetch active bulletin').then((result) => {
            var data = result.result;
            try {
                data.data = JSON.parse(data.data);
                if (!data.data.leagues) data.data.leagues = {};
                if (!data.data.events) data.data.events = {};

            } catch (err) {
                //
                data.data = {
                    events: {},
                    leagues: {}
                };
            }

            setBulletin(data);
        });
    }, [topLeagues]);

    useEffect(() => {
        if (!topLeagues) return;
    });

    useEffect(() => {
        if (!bulletin) return;
        if (!category) return;
        application().resolve(null, Endpoints.SportLeaguesByDates, { sportId: category.id, startDate: bulletin.dateStart, endDate: bulletin.dateEnd }, 'fetching leagues', 'unable to fetch leagues').then((result) => {

            try {
                result.result.map(x => {
                    const index = topLeagues.findIndex(y => y.leagueId == x.id);
                    x.weight = index;
                    if (index >= 0) {
                        x.fav = true;
                    }
                    x.sport = {
                        id: category.id,
                        name: category.name
                    };
                    x.region = {
                        id: x.region,
                        name: x.regionName
                    };
                    delete x.regionName;
                    delete x.enabled;
                    delete x.sportName;
                });
                selectLeagues(result.result.sort(sortLeagues));
                setUpdateKey(Date.now());
            } catch (err) {
                //
                console.log(err);
            }
        });
    }, [category]);



    const renderBulletin = () => {
        if (!bulletin) return;
        return <Card caption='Categories' className='margin-top'>
            <form>
                <div className='row margin-top'>
                    <div className='form-group padding'>
                        <label>{lang('Sports')}</label>
                        <select value={category ? category.id : '-1'}
                            onChangeCapture={(e) => {
                                const value = parseInt(e.target.value);
                                if (isNaN(value)) {
                                    selectCategory(null);
                                    return;
                                }
                                const selectedCategory = categories.find(x => x.id == value);
                                selectCategory(selectedCategory);
                            }}>
                            {category == null && <option value='-1'>Please select</option>}
                            {categories.sort((a, b) => { return b.weight - a.weight; }).map(category => {
                                return <option value={category.id} key={category.id}>{category.name}</option>;
                            })}
                        </select>
                    </div>
                </div>
            </form>
        </Card>;
    };


    const changeLeagueCode = (league, startingCode, data, onComplete) => {
        const dateStart = bulletin.dateStart;
        const dateEnd = bulletin.dateEnd;

        const payload = {
            sportId: league.sport.id,
            leagueId: league.id,
            startDate: dateStart,
            endDate: dateEnd
        };

        application().resolve(null, Endpoints.SportEvents, payload, 'fetching events', 'unable to fetch events').then((result) => {
            var copy = data ? { ...data } : { ...bulletin };

            if (!copy.data.leagues) {
                copy.data.leagues = {};
            }

            if (!copy.data.events) {
                copy.data.events = {};
            }

            copy.data.leagues[league.id] = {
                name: league.name,
                id: league.id,
                code: startingCode,
                selected: true,
                totalEvents: league.totalEvents,
                weight: league.weight,
                sport: { ...league.sport },
                region: { ...league.region }
            };



            result.result.map((x, index) => {
                var eventCopy = { ...x };
                delete eventCopy.competitors;
                delete eventCopy.sport;
                delete eventCopy.sportId;
                delete eventCopy.region;
                delete eventCopy.regionId;
                delete eventCopy.league;
                delete eventCopy.languageData;
                delete eventCopy.status;
                delete eventCopy.weight;
                delete eventCopy.liveSchedule;
                delete eventCopy.enabled;
                delete eventCopy.bookingStatus;
                delete eventCopy.outright;
                eventCopy.code = startingCode + index;
                copy.data.events[x.id] = eventCopy;
            });

            if (onComplete) {
                onComplete(copy);
            } else {
                setBulletin(copy);
            }
        });
    };


    const toggleLeagueSelection = (league) => {
        var copy = { ...bulletin };

        if (!copy.data.leagues) {
            copy.data.leagues = {};
        }

        if (!copy.data.leagues.hasOwnProperty(league.id)) {
            copy.data.leagues[league.id] = league;
            league.selected = true;
        } else {
            copy.data.leagues[league.id].selected = !copy.data.leagues[league.id].selected;
        }

        setBulletin(copy);
    };


    const deleteLeague = (league, element) => {
        application().modal.open(<ConfirmationModal title={lang('Please confirm')}
            onConfirm={
                () => {
                    var copy = { ...bulletin };
                    if (!copy.data.leagues) return;
                    delete copy.data.leagues[league.id];
                    league.update = Date.now();
                    var newEvents = {};
                    Object.keys(copy.data.events).map(x => {
                        const event = copy.data.events[x];
                        if (x.leagueId == league.id) return;
                        newEvents[x.id] = event;
                    });
                    copy.data.events = newEvents;
                    element.current.value = '';
                    setBulletin(copy);
                    setUpdateKey(Date.now());
                }
            }>
            {lang('Do you want to delete event codes for this league?')}
        </ConfirmationModal>);
    };


    const update = (copy) => {
        setBulletin(copy);
    };


    const autoAssign = () => {
        var start = 1;
        var copy = { ...bulletin };
        const assignCode = (temp) => {
            if (temp.length == 0) {
                console.log('finished');
                setUpdateKey(Date.now());
                return;
            }
            var league = temp[0];
            // loop
            changeLeagueCode(league, league.code, copy, () => {
                // remove selected league from stack
                temp.splice(0, 1);
                assignCode(temp);
            });
        };
        var temp = Object.values(copy.data.leagues).filter(x => x.selected).sort(sortLeagues);
        temp.map(league => {
            league.code = start;

            // get next number close to 10th
            start += league.totalEvents;
            start = 10 - (start % 10) + start;
        });

        assignCode(temp);
    };

    const renderLeagues = () => {
        if (!bulletin) return;
        if (!leagues) return;
        var totalSelected = 0;
        if (bulletin.data.leagues) totalSelected = Object.values(bulletin.data.leagues).filter(x => x.selected).length;
        return <div className='leagues' key={updateKey + '_' + showOnlySelected}>
            <div className='panel'>
                <div className='league-options'>
                    <label>{lang('Selected')}</label>
                    <span>{totalSelected}</span>
                    <div className='button-group'>
                        <Button title={showOnlySelected ? 'Show all' : 'Show only selected'} className={totalSelected > 0 ? ' alignRight' : 'disabled alignRight'} onClick={() => {
                            toggleShowSelected(!showOnlySelected);
                        }} />
                        <Button title='Auto Assign Selected' className={totalSelected > 0 ? ' alignRight' : 'disabled alignRight'} onClick={() => {
                            autoAssign();
                        }} />
                        <Button title='Clear All' onClick={() => {
                            var copy = { ...bulletin };
                            copy.data.leagues = {};
                            copy.data.events = {};
                            toggleShowSelected(false);
                            setBulletin(copy);
                            setUpdateKey(Date.now());
                        }} />
                    </div>
                </div>
            </div>
            {
                leagues.map(x => {
                    var selected = false;
                    if (bulletin.data.leagues && bulletin.data.leagues.hasOwnProperty(x.id)) {
                        selected = bulletin.data.leagues[x.id].selected == true;
                    }
                    if (!selected && showOnlySelected) return;
                    return <LeagueCard key={x.id} selected={selected} league={x} bulletin={bulletin} onChangeLeagueCode={changeLeagueCode.bind(this)} onDelete={deleteLeague.bind(this)} onUpdate={update.bind(this)} toggleSelection={toggleLeagueSelection.bind(this)} />;
                })
            }
        </div>;
    };


    return <div className='event-codes'>
        <Card caption='Bulletin' className='centrum-flex' menu={<Button title='Add New' icon='plus' onClick={() => addNew((result) => {
            setBulletin(result);
        })} />}>
            <div className='padding'>
                <Bulletin bulletin={bulletin} key={bulletin} />
            </div>
        </Card>
        {renderBulletin()}
        {renderLeagues()}
    </div >;
};

EventCodes.propTypes = {
    categories: PropTypes.object
};

