import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Panel } from '../../../Centrum/Card/Card';
import { Table } from '../../../Controls/Table/Table';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';

const slipTypes = [
    { value: 0, display: 'Prematch' },
    { value: 1, display: 'Live' },
    { value: 2, display: 'Combined (Prematch&Live)' }
];

const ticketTypes = [
    { value: 0, display: 'Single' },
    { value: 1, display: 'Multi' },
    { value: 2, display: 'System' }
];



const rangeModel = {
    fields: [
        {
            name: 'slipType',
            display: 'Type',
            type: 'select',
            readOnly: true,
            values: slipTypes,
            formatter: (value) => {
                return slipTypes.find(x => x.value == value).display;
            }
        },
        {
            name: 'ticketType',
            display: 'Ticket Type',
            type: 'select',
            readOnly: true,
            values: ticketTypes,
            formatter: (value) => {
                return ticketTypes.find(x => x.value == value).display;
            }
        },
        {
            name: 'selection',
            display: 'Minimum Selection',
            type: 'number'
        },
        {
            name: 'minOdds',
            display: 'Min Odds',
            type: 'number'
        },
        {
            name: 'odds',
            display: 'Max Odds',
            type: 'number'
        },
        {
            name: 'enabled',
            display: 'Enabled',
            type: 'bool'
        }
    ]
};

class OddsPayoutConfiguration extends React.Component {
    constructor(props) {
        super(props);
        var configuration = Object.assign([], props.configuration);
        this.state = { configuration: configuration };
    }

    save() {
        return this.state.configuration;
    }

    render() {
        return <React.Fragment>
            <Table model={rangeModel} data={this.state.configuration}
                key={this.state.selectedCurrency}
                inlineEdit={true}
                inlineNewRow={false}
                deleteEnabled={false}
                editableFields={'enabled'}
                editable={true} />
        </React.Fragment>;
    }
}

OddsPayoutConfiguration.defaultProps = {
    configuration: [
        {
            slipType: 0,
            ticketType: 0,
            selection: 1,
            minOdds: 0,
            odds: 0,
            enabled: false
        },
        {
            slipType: 0,
            ticketType: 1,
            selection: 2,
            minOdds: 0,
            odds: 0,
            enabled: false
        },
        {
            slipType: 1,
            ticketType: 0,
            selection: 1,
            minOdds: 0,
            odds: 0,
            enabled: false
        },
        {
            slipType: 1,
            ticketType: 1,
            selection: 2,
            minOdds: 0,
            odds: 0,
            enabled: false
        },
        {
            slipType: 2,
            ticketType: 1,
            selection: 2,
            minOdds: 0,
            odds: 0,
            enabled: false
        }
    ]
};

OddsPayoutConfiguration.propTypes = {
    configuration: PropTypes.array
};


export const WelcomeBonusSportRules = (props) => {
    const [configuration, saveConfiguration] = useState(props.promotion.Configuration ? props.promotion.Configuration : {
        sports: {
            bet: null,
            enabled: false
        }
    });

    const [sports, saveSports] = useState(Object.assign({}, configuration.sports));
    var betsTable = null;
    const context = {
        save: () => {
            var sportsCopy = {
                bet: betsTable.save(),
                enabled: sports.enabled
            };


            var copy = Object.assign({}, configuration);
            configuration.sports = sportsCopy;
            saveConfiguration(copy);
            saveSports(sportsCopy);
            return sportsCopy;
        }
    };

    useEffect(() => {
        props.context(context);
    });



    return <div className='padding'>
        <Form data={sports}>
            <div className='row'>
                <FormInput name='enabled' title='Enable Sports' type='bool' onChange={(f, r, value) => {
                    saveSports({
                        enabled: value,
                        bet: sports.bet
                    });
                }} />
            </div>
        </Form>
        <Panel title='Bet Configuration' className={'margin-top ' + (sports.enabled ? '' : 'disabled')}>
            <OddsPayoutConfiguration ref={(r) => betsTable = r} configuration={sports.bet} />
        </Panel>
    </div>;
};

WelcomeBonusSportRules.propTypes = {
    promotion: PropTypes.object,

    context: PropTypes.func
};

