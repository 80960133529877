import './_menu.scss';

import React from 'react';
import PropTypes from 'prop-types';


import { icons } from '../Shared';
import { CentrumComponent } from '../CentrumComponent';
import { lang } from '../Localization/lang';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { applicationMenu, hasAccess } from '../Common/ActionManager';
import { Endpoints, apiResult } from '../../Application/Application';
import { Icon } from '../../Controls/Icon/Icon';


window.widgetIntervals = {};

export const widgetRefreshInterval = 5000;


export const refreshWidget = (seconds, targetProperty, handler) => {
    handler();
    if (targetProperty in window.widgetIntervals) {
        return;
    }


    let interval = setInterval(function () {
        handler();
    }, seconds);

    window.widgetIntervals[targetProperty] = interval;
    return interval;
};

export const clearRefresh = (interval) => {
    clearInterval(interval);
};



export class MenuItem extends CentrumComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
        this.open = false;
    }


    renderChildren() {
        if (this.props.item.children === undefined) return;
        if (this.props.item.children === null) return;
        if (this.props.item.children.length === 0) return;
        if (!this.open) return;

        return this.props.item.children.map(item => {
            return (
                hasAccess(item.type) && (item.visible || item.visible === undefined) && <MenuItem key={item.title} item={item} parent={this} menu={this.props.menu}></MenuItem>
            );
        });
    }

    hasChildren() {
        return this.props.item.children !== undefined && this.props.item.children !== null && this.props.item.children.length > 0;
    }

    handleClicked() {
        this.toggleHide();
        this.props.menu.onSelected(this);
    }

    toggleHide() {
        if (!this.menuItemDiv.classList.contains('open')) {
            this.menuItemDiv.classList.add('open');
        } else {
            this.menuItemDiv.classList.remove('open');
        }
        this.open = !this.open;
        this.setState({ open: this.open });
    }

    renderIcon() {
        if (this.props.item.icon === undefined) return;
        var icon = icons[this.props.item.icon];
        if (icon === undefined) {
            return <Icon icon={this.props.item.icon} />;
        }
        return (<i><Icon icon={icon} /></i>);
    }

    renderToggleButton() {
        var notifications = this.props.menu.props.adminNotifications;
        var notification = 0;
        if (notifications !== undefined && notifications !== null && notifications.result !== undefined && notifications.result !== null && Object.keys(notifications.result).keys) {
            if (this.props.item.type in notifications.result) {
                notification += notifications.result[this.props.item.type];
            }

            if (this.hasChildren()) this.props.item.children.map(item => {
                var action = item.type;
                if (action in notifications.result) {
                    notification += notifications.result[action];
                }
            });
        }
        if (this.props.item.children !== null && this.props.item.children !== undefined) {
            return (<i>{this.open ? <Icon icon={'angle-down'} className='menu-arrow' /> : <Icon icon={'angle-left'} className='menu-arrow' />}{notification > 0 && <div className='menu-notification'>{notification}</div>}</i>);
        } else if (notification > 0) {
            return (<i>{notification > 0 && <div className='menu-notification'>{notification}</div>}</i>);
        }
    }

    render() {
        var open = this.props.menu.checkSelected(this);
        let selected = this.props.menu.selected === this;
        let hasChildren = false;

        if (this.props.menu.openMenuItem === null && open) {
            this.open = true;
            this.props.menu.openMenuItem = this;
        }

        if (this.props.menu.collapsed) {
            if (this.props.menu.selected !== this && this.open) {
                this.toggleHide();
            }
        }

        if (!this.hasChildren()) {
            this.open = false;
        }

        if (selected && this.props.item.children !== undefined && this.props.item.children.length > 0) {
            hasChildren = true;
        }

        return (
            <div className={'menu-item' + (this.open ? ' open' : '') + (selected ? ' selected' : '') + (hasChildren ? ' hasChildren' : '')} ref={(r) => this.menuItemDiv = r}>
                <a onClick={() => this.handleClicked()}>{this.renderIcon()}<span>{lang(this.props.item.title)}</span>{this.renderToggleButton()}</a>
                <div className='menu-item-children'>
                    {this.props.item.children !== undefined && this.props.item.children !== null && this.props.item.children.length > 0 && <h4>{lang(this.props.item.title)}</h4>}
                    {this.renderChildren()}
                </div>
            </div>
        );
    }
}


MenuItem.defaultProps = {
    item: {},
    parent: {},
};

MenuItem.propTypes = {
    item: PropTypes.object.isRequired,
    parent: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired
};

class Menu extends CentrumComponent {
    constructor(props) {
        super(props);
        this.selected = {};
        refreshWidget(5000, 'menu', this.updateAdminNotifications.bind(this));
        this.openMenuItem = null;
        this.path = this.props.history.location.pathname;
    }


    componentDidMount() {
        this.props.history.listen((location) => {
            if (location.pathname !== this.path) {
                this.path = location.path;
                this.setState({ historyChanged: true });
            }
        });
    }


    checkSelected(menuItem) {
        var path = this.props.history.location.pathname.toLocaleLowerCase().split('/');
        var selected = path.find(x => x == menuItem.props.item.type.toLocaleLowerCase());

        if (selected) {
            this.selected = menuItem;
        }


        return selected;
    }


    updateAdminNotifications() {
        apiResult('adminNotifications', Endpoints.AdminNotifications, {}, null, null);
    }


    onToggleCollapse() {
        if (this.menu.classList.contains('collapse')) {
            this.menu.classList.remove('collapse');
        } else {
            this.menu.classList.add('collapse');
        }

        try {
            const app = document.getElementsByClassName('application');
            if (app) {
                app[0].classList.toggle('menu-collapsed');
            }
            this.setState({ collapsed: this.collapsed });
        } catch (err) {
            //
        }
    }

    onUIKeysPressed(key) {
        console.log(key, 'menu');

        /*


         try {
            const app = document.getElementsByClassName('application');
            if (app) {
                app[0].classList.toggle('menu-collapsed');
            }
            this.setState({ collapsed: this.collapsed });
        } catch (err) {
            //
        }
        */
    }

    onSelected(clickedItem) {
        var previousSelected = this.state.selected;
        if (previousSelected != undefined) {
            if (this.collapsed) {
                previousSelected.toggleHide();
            }
        }

        this.setState({ selected: clickedItem });
        this.selected = clickedItem;
        this.props.onSelected(clickedItem);
    }


    renderMenu() {
        return applicationMenu.children.map(item => {
            if (item.affiliate && !window.user.AffiliateId) return;
            return (
                hasAccess(item.type) && (item.visible || item.visible === undefined) && <MenuItem key={item.title} item={item} parent={this} menu={this}></MenuItem>
            );
        });
    }

    renderComponent() {
        return (
            <div className={'application-menu' + (this.props.settings.menu.minimized ? ' collapse' : '')} ref={menu => { this.menu = menu; }}>
                <div className='menu-content'>
                    {this.renderMenu()}
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}


Menu.defaultProps = {
    onToggleHandler: null,
    history: null,
    languageData: null
};

Menu.propTypes = {
    history: PropTypes.object,
    updateAdminNotifications: PropTypes.func,
    onSelected: PropTypes.func,
    selected: PropTypes.object,
    user: PropTypes.object,
    language: PropTypes.string,
    languageData: PropTypes.object,
    onToggleHandler: PropTypes.func
};



const mapStateToProps = state => {
    return {
        settings: state.settings,
        user: state.user,
        languageData: state.data.languageData,
        language: state.language.selected,
        adminNotifications: state.data.adminNotifications
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(Menu);
export { hoc as Menu };