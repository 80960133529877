// IMPORT PACKAGE REFERENCES

import React, { useEffect, useState } from 'react';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { API, OperatorConfigurationEndpoints } from '../../../../v2/Lib/Api/Api';
import { application } from '../../../Application/Application';
import PropTypes from 'prop-types';
import { Card } from '../../../../v2/Components/Card/Card';
import { lang } from '../../../../v2/Lib/Common/language';


export const ConfigureOperatorRisk = (props) => {
    const [risk, setRisk] = useState(props.configuration ? JSON.parse(props.configuration).type : { type: 0 });

    useEffect(() => {

    }, []);

    const save = () => {
        API.post(OperatorConfigurationEndpoints.SaveOperatorRiskType + '/' + props.operatorId, { type: risk }, 'updating operator configuration please wait', 'Unable to update configuration').then(() => {
            application().snackbar.open('Operator risk configuration saved.');
        });
    };



    return <React.Fragment>
        <div className='flex vertical gap-10 padding' key={risk.type}>
            <div className='padding border'>
                <div className='flex gap-5'>
                    <label>{lang('Selected Risk Type')}</label>
                    <span>{risk.type == 0 ? 'Risk Volumes' : 'Risk Levels'}</span>
                </div>
            </div>
            <Card title='Risk Volumes' icon={risk.type == 0 ? 'circle-check' : ''} buttons={() => <Button title='Select Risk Volumes' onClick={() => setRisk({ type: 0 })} />}>
                <p>{lang('Default Risk management for managing Volumes over Sports, Leagues and Events including Player Groups separatly')}</p>
            </Card>
            <Card title='Risk Levels' icon={risk.type == 1 ? 'circle-check' : ''} buttons={() => <Button title='Select Risk Levels' onClick={() => setRisk({ type: 1 })} />}>
                <p>{lang('Risk managment using 8 Risk Levels such as Low - Medium - High')}</p>
            </Card>
        </div>
        <Button className='margin' onClick={() => save()} title='Save' />
    </React.Fragment >;
};


ConfigureOperatorRisk.propTypes = {
    operatorId: PropTypes.number,
    configuration: PropTypes.object
};