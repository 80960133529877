// IMPORT PACKAGE REFERENCES
import './_snackbars.scss';

import React, { Component } from 'react';


export class Snackbars extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({ open: false }, props);
    }

    close() {
        this.setState({ open: false, snackbar: null });
    }

    open(modal) {
        this.setState({ open: true, snackbar: modal });
    }

    render() {
        if (!this.state.open) return <React.Fragment />;
        return <div className='snackbars'>{this.state.snackbar}</div>;
    }
}

Snackbars.defaultProps = {

};

Snackbars.propTypes = {

};
