import { addDays, addHours } from 'date-fns';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


export const formatDateWithMonth = (date, displayDay) => {
    /*
    var monthName = months[date.getMonth()]; // 'July' (or current month)

    return `${displayDay ? `${getDay(date)} ` : ''}${date.getUTCDate()} ${monthName}, ${date.getUTCFullYear()}`;
    */

    return displayDay ? Intl.DateTimeFormat(window.dateFormat ?? 'en-Gb', { day: 'numeric', month: 'short', weekday: 'short', year: 'numeric' }).format(date) :
        Intl.DateTimeFormat(window.dateFormat ?? 'en-Gb', { day: 'numeric', month: 'short', year: 'numeric' }).format(date);
};

export const getFormatedDateWithMonth = (date) => {
    var monthName = months[date.getMonth()]; // 'July' (or current month)

    return {
        date: date,
        formatted: `${date.getDate()} ${monthName}, ${date.getUTCFullYear()}`,
        day: getDay(date)
    };
};

export const getDay = (date) => {
    return days[date.getDay()];
};

export const today = () => {
    var now = new Date();
    var offset = (now.getTimezoneOffset() / 60) * -1;
    return addHours(new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()), offset);
};

export const tomorrow = () => {
    var now = new Date();
    var offset = (now.getTimezoneOffset() / 60) * -1;
    return addHours(addDays(new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()), 1), offset);
};

export const yesterday = () => {
    var now = new Date();
    var offset = (now.getTimezoneOffset() / 60) * -1;
    return addHours(addDays(new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()), -1), offset);
};

export const tonight = () => {
    var now = new Date();
    var offset = (now.getTimezoneOffset() / 60) * -1;
    return addHours(new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59), offset);
};


export const getDate = (date) => {
    return date.toLocaleDateString('en-GB');
};


export const getTime = (date) => {
    if (!date) return;
    return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
};


export const toIsoLocal = (formatted) => {
    var year = formatted.getFullYear();
    var month = (formatted.getMonth() + 1).toString().padStart(2, '0');
    var day = formatted.getDate().toString().padStart(2, '0');
    var hour = formatted.getHours().toString().padStart(2, '0');
    var minute = formatted.getMinutes().toString().padStart(2, '0');
    formatted = `${year}-${month}-${day}T${hour}:${minute}`;

    return formatted;
};

// super bad date convert
export const convertTZ = (date, tzString) => {
    var res = date.toLocaleString('en-US', { timeZone: tzString, hour12: false });
    if (res.indexOf(' 24:') >= 0) {
        res = res.replace(' 24:', '00:');
    }
    return res;
};

export const convertTimeZoneToTimeDiff = (zone)=>{
    if (!zone) return 0;
    var z = zone.split(' ')[1].split(':')[0];
    return parseInt(z);
};

export const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
};