// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { Search } from '../../Centrum/Search/Search';


import { Page } from '../../Centrum/Page/Page';
import { searchOperatorUsers, searchOperatorUserActivities } from '../../../state/actions/OperatorActions';

import { ActionTypes, getActions, getAction } from '../../Centrum/Common/ActionManager';
import { CentrumApplicationActions, CentrumApplicationContents, CentrumApplication, ApplicationContext, ActionClose } from '../../Centrum/Application/CentrumApplication';
import { Card } from '../../Centrum/Card/Card';
import { lang } from '../../Centrum/Localization/lang';
import { CentrumForm, SaveButtons } from '../../Centrum/CentrumForm/CentrumForm';
import { OperatorConfiguration } from './OperatorConfiguration';
import { postData } from '../../../state/services/ServiceHelpers';
import { Table } from '../../Centrum/Table/Table';
import { Tab } from '../../Centrum/Tab/Tab';
import { BreadCrumbs } from '../../Controls/BreadCrumbs/BreadCrumbs';
import { apiResult, Endpoints } from '../../Application/Application';

import { appHistory } from '../../../index';
import { API, OperatorEndpoints } from '../../../v2/Lib/Api/Api';

const applicationActionType = ActionTypes.Operators;
const applicationActions = getActions(applicationActionType);



// COMPONENT
class OperatorPage extends Page {
    constructor(props) {
        super(props);
        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.modal = null;
        state.action = action;

        this.state = state;
        this.applicationContext = null;
    }

    componentDidMount() {
        var query = queryString.parse(window.location.search);
        if (!query) return;
        if (query.configure) {
            let id = parseFloat(query.configure);
            API.post(OperatorEndpoints.Search, {
                fields: [
                    {
                        'name': 'Id',
                        'value': query.configure,
                        'exact': true
                    },
                    {
                        'name': 'Enabled',
                        'value': 'true'
                    }
                ]
            }).then((result) => {
                var o = result.result.find(x => x.Id == id);
                this.applicationContext.navigate(ActionTypes.SearchOperators);
                this.tab.selectByKey('configuration');
                this.breadCrumbs.push(lang('Configure Operator %1', [o.Name]));
                this.setState({ selectedOperator: o });
            });
        }

    }

    saveOperator(qb) {
        this.applicationContext.confirmActionAndPost(qb, 'newOperator', Endpoints.NewOperator, (data) => {
            var operatorInformation = data.result;
            this.breadCrumbs.push(lang('Configure Operator %1', [data.result.name]));
            this.tab.selectByKey('configuration');
            this.setState({ selectedOperator: { Id: operatorInformation.id } });
            this.applicationContext.navigate(ActionTypes.SearchOperators);
        }, 'vertical', 'Save Operator', 'Operator Saved,please progress with the operator features configurations.', true);
    }

    saveOperatorUser(qb) {
        this.applicationContext.confirmActionAndPost(qb, 'newOperatorUser', '/api/operatorUsers/new', (data) => {
            console.log(data);
        }, 'vertical', 'Save Operator', 'Operator user saved.', true);
    }

    onActionClicked(action, items) {
        var item = items[0];
        switch (action.type) {
            case ActionTypes.EditOperatorConfiguration:
                this.applicationContext.navigate(ActionTypes.SearchOperators);
                this.tab.selectByKey('configuration');
                this.breadCrumbs.push(lang('Configure Operator %1', [item.Name]));
                this.setState({ selectedOperator: item });
                appHistory.push('/Operators/SearchOperators?&configure=' + item.Id);
                break;
            case ActionTypes.EditOperator:
                this.applicationContext.edit(item, 'Edit Operator', 'editOperator', '/api/operator/update', 'large');
                break;
            case ActionTypes.EditOperatorUser:
                this.applicationContext.edit(item, 'Edit Operator User', 'editOperatorUser', '/api/operatorUsers/update', 'large');
                break;

            case ActionTypes.ToggleOperatorUserEnabled:
                this.applicationContext.confirmActionAndPost(item, 'operatorUserSearchResult', '/api/operatorUsers/toggleEnabled/' + item.Id,
                    () => {
                        item.Enabled = !item.Enabled;
                    }, 'col2', item.Enabled ? 'Disable User' : 'Enable User', item.Enabled ? 'User disabled' : 'User enabled', true);
                break;

            case ActionTypes.ShowOperatorUserActivityDetails:
                var model = 'activityDetails';
                postData({}, '/operatorUsers/activityDetail/' + item.Token).then((res) => {
                    this.applicationContext.openModal({
                        title: lang('Token movements'),
                        data: null,
                        actions: ActionClose,
                        content: (
                            <React.Fragment>
                                <CentrumForm model='activitySummary' data={item} readonly={true} className='vertical' buttons={[]} />
                                <Table data={{ model: model, result: res.result }} model={model} selectable={false}
                                    showResultsSummary={false} showActions={false} showPagination={false} sortingEnabled={false} />
                            </React.Fragment>
                        ),
                        onActionClicked: () => { this.closeModal(); }
                    });
                    //this.applicationContext.showModalTable(res.result,'activityDetails','Activity Details');
                }).catch((err) => {
                    console.warn(err);
                    this.openErrorModal(<p>{lang('Unable load activity data')}</p>);
                });
                break;

            default:
                console.log(action);
                break;
        }
    }


    onRenderOperatorActions(action, selection) {
        if (selection.length == 0) return action;
        var item = selection[0];
        if (item === undefined) return action;
        switch (action.type) {
            case ActionTypes.ActivateDeactivateOperator:
                return item.Enabled ? { caption: lang('Deactivate Operator'), icon: 'disable', enabled: true } : { caption: lang('Activate Operator'), icon: 'enable', enabled: true };
        }
        return action;
    }

    onRenderOperatorUserActions(action, selection) {
        if (selection.length == 0) return action;
        var item = selection[0];
        switch (action.type) {
            case ActionTypes.ToggleOperatorUserEnabled:
                return item.Enabled ? { caption: lang('Deactivate User'), icon: 'disable', enabled: true } : { caption: lang('Activate User'), icon: 'enable', enabled: true };
        }
        return Object.assign({ enabled: true }, action);
    }


    onSaveFeatures(data) {
        this.applicationContext.confirmActionAndPost(data, 'operatorFeatures', Endpoints.SaveOperatorFeatures, () => {
        }, 'col2', 'Save Operator Features', 'Operator features saved.', true);
    }

    render() {
        return (
            <CentrumApplication caption='Operators' icon='industry' type={applicationActionType} loading={this.props.loading}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <Card caption='New Operator'>
                                        <CentrumForm model='newOperator' className='vertical' buttons={SaveButtons} onSubmit={this.saveOperator.bind(this)} />
                                    </Card>
                                    <Card>
                                        <BreadCrumbs root='Search Operators' ref={(r) => this.breadCrumbs = r} onClick={() => { this.tab.selectByKey('searchOperators'); this.setState({ selectedOperator: null }); }} />
                                        <Tab ref={(r) => this.tab = r}>
                                            <Search
                                                key='searchOperators'
                                                model='searchOperators'
                                                resultModel='operatorSearchResult'
                                                data={this.props.operators}
                                                actions={getAction(ActionTypes.SearchOperators).convertToItems()}
                                                onRenderAction={this.onRenderOperatorActions.bind(this)}
                                                onActionClicked={this.onActionClicked.bind(this)}
                                                onSubmit={(data, qb) => apiResult('operators', Endpoints.SearchOperators, qb, 'searching operators please wait', 'Unable to fetch data.')} />
                                            <React.Fragment key='configuration'>
                                                {
                                                    this.state.selectedOperator != null &&
                                                    <OperatorConfiguration key={this.state.selectedOperator.Id} id={this.state.selectedOperator.Id} onSaveFeatures={this.onSaveFeatures.bind(this)} />
                                                }
                                            </React.Fragment>
                                        </Tab>
                                    </Card>
                                    <Card caption='New Operator User'>
                                        <CentrumForm model='newOperatorUser' className='vertical' buttons={SaveButtons} onSubmit={this.saveOperatorUser.bind(this)} />
                                    </Card>
                                    <Card caption='Search'>
                                        <Search
                                            model='searchOperatorUsers'
                                            resultModel='operatorUserSearchResult'
                                            data={this.props.operatorUsers}
                                            actions={getAction(ActionTypes.SearchOperatorUsers).convertToItems()}
                                            onRenderAction={this.onRenderOperatorUserActions.bind(this)}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            onSubmit={(data, qb) => this.props.searchOperatorUsers(qb)} />
                                    </Card>
                                    <Card caption='Search'>
                                        <Search
                                            model='searchOperatorUserActivity'
                                            resultModel='operatorUserActivityInformation'
                                            data={this.props.operatorUserActivities}
                                            actions={getAction(ActionTypes.SearchOperatorUserActivity).convertToItems()}
                                            onSubmit={(data, qb) => { this.props.searchOperatorUserActivities(qb); }}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                        />

                                    </Card>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

OperatorPage.defaultProps = {
    action: null
};

OperatorPage.propTypes = {
    action: PropTypes.object,
    show: PropTypes.bool,
    user: PropTypes.object,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    failed: PropTypes.bool,
    adminActivity: PropTypes.object
};


const mapStateToProps = state => {
    return {
        loading: state.application.loading,
        loaded: state.application.loaded,
        failed: state.application.failed,
        operators: state.data.operators,
        operatorUsers: state.application.operatorUsers,
        operatorUserActivities: state.application.operatorUserActivities,
        error: state.application.error
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        searchOperatorUsers: searchOperatorUsers,
        searchOperatorUserActivities: searchOperatorUserActivities
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(OperatorPage);
export { hoc as OperatorPage };