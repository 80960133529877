/* eslint-disable react/display-name */
import React from 'react';
import { application } from '../../Application/Application';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { DateRanges } from '../../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';
import { currency, lang } from '../../Centrum/Localization/lang';
import { SportsBetTypes, SportsTicketTypes, TestAccountFilter } from '../../Centrum/_models/ModelEnums';
import { currencyAndFooter } from '../../Centrum/_models/ReportsModel';
import { SportsTree } from '../../Controls/SportsTree/SportsTree';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';



export const RetailTransactionTypes = {
    0: 'Deposit',
    1: 'Withdraw',
    2: 'Deposit to Player',
    3: 'Withdraw from Player',
    4: 'Cancel'
};

const RetailPlayerTransactions = {
    2: 'Deposit',
    3: 'Withdraw'
};



export const retailAccountSummary = {
    fields: [
        {
            name: 'Code',
            display: 'Code',
            type: InputTypes.text
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'Type',
            type: InputTypes.select,
            values: RetailTransactionTypes,
            acceptEmptyValues: true
        }
    ]
};

export const retailAccountSummaryReportResult = {
    fields: [
        {
            name: 'RetailCode',
            display: 'Code'
        },
        {
            name: 'RetailName'
        },
        {
            name: 'Currency'
        },
        {
            name: 'TotalDepositToPlayer',
            display: 'Deposit to Player',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'TotalWithdrawFromPlayer',
            display: 'Withdraw from Player',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'TotalDepositToRetail',
            display: 'Deposit to Retail',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'TotalWithdrawFromRetail',
            display: 'Withdraw from Retail',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Balance',
            display: 'Balance',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        }
    ]
};

export const retailTransactionsReport = {
    fields: [
        {
            name: 'Code',
            display: 'Code',
            type: InputTypes.text
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'Type',
            type: InputTypes.select,
            values: RetailTransactionTypes,
            acceptEmptyValues: true
        },
        {
            name: 'AdminId',
            display: 'Operator User',
            type: InputTypes.multipleSelect,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myOperatorUsers',
                bindingKey: 'Id',
                bindingText: ['Email', 'Name', 'Surname']
            }
        },
    ]
};


export const retailTransactionsReportResult = {
    fields: [
        {
            name: 'Id',
            display: 'Id'
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'OperatorUser',
            display: 'Operator User'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'Type',
            type: InputTypes.select,
            values: RetailTransactionTypes,
            formatter: (val) => {
                return RetailTransactionTypes[val];
            }
        },
        {
            name: 'Username'
        },
        {
            name: 'PlayerCode',
            display: 'Player Code'
        },
        {
            name: 'Amount',
            display: 'Amount',
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    if (isExporting) {
                        return Object.keys(val).map(key => {
                            return val[key] + key;
                        });
                    }
                    // this is an object
                    if (Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'BalanceBefore',
            display: 'Balance Before',
            formatter: (val, row) => {
                return currency(val, 2, row.Currency);
            }
        },
        {
            name: 'Balance',
            display: 'Balance',
            formatter: (val, row) => {
                return currency(val, 2, row.Currency);
            }
        }
    ]
};




export const retailPlayerTransactionsReport = {
    fields: [
        {
            name: 'Code',
            display: 'Code',
            type: InputTypes.text
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,
            acceptEmptyValues: true,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'Type',
            type: InputTypes.select,
            values: RetailPlayerTransactions,
            acceptEmptyValues: true
        },
        {
            name: 'PlayerCode',
            display: 'Player Code',
            type: InputTypes.text
        }
    ]
};


export const retailPlayerTransactionsReportResult = {
    fields: [
        {
            name: 'Id',
            display: 'Id'
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Code',
            display: 'Retail Code'
        },
        {
            name: 'RetailName',
            display: 'Retail'
        },
        {
            name: 'PlayerCode',
            display: 'Player Code'
        },
        {
            name: 'Username',
            display: 'Usernme'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'Type',
            type: InputTypes.select,
            values: RetailPlayerTransactions,
            formatter: (val) => {
                return RetailPlayerTransactions[val];
            }
        },
        {
            name: 'Amount',
            display: 'Amount',
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency);
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency);
                }
            }
        },
        {
            name: 'BalanceBefore',
            display: 'Retail Balance Before',
            formatter: (val, row) => {
                return currency(val, 2, row.Currency);
            }
        },
        {
            name: 'Balance',
            display: 'Retail Balance',
            formatter: (val, row) => {
                return currency(val, 2, row.Currency);
            }
        }
    ]
};






export const sportEventRevenueReport = {
    fields: [
        {
            name: 'EventId',
            display: 'Event Id',
            type: InputTypes.number,
            required: true
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};

export const eventRevenueReportResult = {
    fields: [
        {
            name: 'Market'
        },
        {
            name: 'Currency'
        },
        {
            name: 'Bets'
        },
        {
            name: 'BonusTickets',
            display: 'Bonus Bets'
        },
        {
            name: 'Stakes',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            },
            currency: true
        },
        {
            name: 'BonusStakes',
            display: 'Bonus Stakes',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            },
            currency: true
        },
        {
            name: 'TicketBonusAmount',
            display: 'Ticket Bonus',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            },
            currency: true
        },
        {
            name: 'Win',
            display: 'Win',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            },
            currency: true
        },
        {
            name: 'BonusWin',
            display: 'Bonus Win',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            },
            currency: true
        },
        {
            name: 'Profit',
            display: 'Profit',
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            },
            currency: true
        }
    ]
};


export const sportLeagueReports = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'EntryDate',
            display: 'Settlement Date',
            type: InputTypes.dateTime,
            and: true,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today,
                TimeZone: 'UTC'
            }
        },
        {
            name: 'SportId',
            display: 'Sport',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'sportCategories',
                bindingKey: 'id',
                bindingText: ['name']
            }
        },
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes
        },
        {
            name: 'LeagueId',
            display: 'League',
            type: InputTypes.custom,
            children: <div>test</div>,
            formatter: (val, data, onChange) => {
                return <input name='LeagueId' defaultValue={val} placeholder={lang('Select League')} onClick={(e) => {
                    application().modal.open(<ConfirmationModal title='Select League' className={'league-tree'}>
                        <SportsTree regions leagues onLeagueSelected={(league) => {
                            application().modal.close();
                            e.value = league.name;
                            onChange({
                                target: {
                                    value: league.id
                                }
                            });
                        }} />
                    </ConfirmationModal>);
                }} />;
            }
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            valueEndPoint: {
                source: 'enabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['Name']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};