/* eslint-disable react/display-name */

import './_configuration.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Panel } from '../../../Centrum/Card/Card';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Button } from '../../../Controls/Buttons/Button';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Icon } from '../../../Controls/Icon/Icon';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { Switch } from '../../../Controls/Inputs/Switch/Switch';
import { application } from '../../../Application/Application';
import { lang } from '../../../../v2/Lib/Common/language';
import { API, CMSEndpoints, ProviderEndpoints } from '../../../../v2/Lib/Api/Api';



const focus = (e) => {
    if (e.target.value == '0') {
        e.target.value = '';
    }
};

const blur = (e) => {
    if (e.target.value == '') {
        e.target.value = '0';
    }
};

const limitRangeModel = {
    fields: [
        {
            name: 'type',
            title: 'Configuration Type',
            renderRow: (row) => {
                const values = {
                    0: 'Slot',
                    1: 'Live Games'
                };
                return values[parseInt(row.type)];
            }
        },
        {
            name: 'minBet',
            title: 'Bet Min',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.minBet} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxBet',
            title: 'Bet Max',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.maxBet} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'percentage',
            title: 'Percentage %',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.percentage} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'enabled',
            title: 'Enabled',
            renderRow: (row, field, context) => {
                return <Switch checked={row.enabled} onChange={(value) => context.update(row, value, field)} />;
            }
        }
    ]
};

const providerLimitModel = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'name',
            title: 'Name'
        },
        {
            name: 'minBet',
            title: 'Bet Min',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.minBet} onFocus={(e) => focus(e)} onChange={(e) => context.updateProviderLimits(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxBet',
            title: 'Bet Max',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.maxBet} onFocus={(e) => focus(e)} onChange={(e) => context.updateProviderLimits(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'percentage',
            title: 'Percentage %',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.percentage} onFocus={(e) => focus(e)} onChange={(e) => context.updateProviderLimits(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        }
    ]
};


const gameLimitModel = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'name',
            title: 'Name'
        },
        {
            name: 'providerId',
            title: 'Provider Id'
        },
        {
            name: 'providerName',
            title: 'Provider'
        },
        {
            name: 'minBet',
            title: 'Bet Min',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.minBet} onFocus={(e) => focus(e)} onChange={(e) => context.updateGameLimit(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxBet',
            title: 'Bet Max',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.maxBet} onFocus={(e) => focus(e)} onChange={(e) => context.updateGameLimit(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'percentage',
            title: 'Percentage %',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.percentage} onFocus={(e) => focus(e)} onChange={(e) => context.updateGameLimit(row, parseFloat(e.target.value), field)} onBlur={(e) => blur(e)} />;
            }
        }
    ]
};


const disabledGamesModel = {
    fields: [
        {
            name: 'id',
            title: 'Game Id'
        },
        {
            name: 'name',
            title: 'Game Name'
        },
        {
            name: 'providerId',
            title: 'Provider Id'
        },
        {
            name: 'providerName',
            title: 'Provider'
        }
    ]
};

const disabledProviders = {
    fields: [
        {
            name: 'id',
            title: 'Provider Id'
        },
        {
            name: 'name',
            title: 'Provider Name'
        }
    ]
};

export const SelectProvider = (props) => {
    const { onSelect } = props;
    const [providers, setProviders] = useState(null);
    const [options, setOptions] = useState(null);
    const [provider, selectProvider] = useState(null);
    useEffect(() => {
        API.post(ProviderEndpoints.Gaming, {}).then((result) => {
            var p = [];
            result.result.map(x => {
                p.push(<option key={x.id} value={x.id}>{x.display}</option>);
            });
            setProviders(result.result);
            setOptions(p);
            if (result.result.length) selectProvider(result.result[0]);
        });
    }, []);

    if (!options) return <></>;
    return <div className={'modal-window dialog'}>
        <div className='title'>
            <h1>{lang('Select Provider')}</h1>
        </div>
        <div className='body'>
            <form>
                <div className='row'>
                    <div className='form-group'>
                        <label>{lang('Provider')}</label>
                        <select onChangeCapture={(e) => selectProvider(providers.find(x => x.id == parseInt(e.target.value)))}>{options}</select>
                    </div>
                </div>
            </form>
        </div>
        <div className='footer flex gap-5'>
            <Button title='Select' onClick={() => {
                onSelect(provider);
                application().modal.close();
            }} />
            <Button title='Cancel' className='align-right' onClick={() => application().modal.close()} />
        </div>
    </div>;
};


export const SelectGame = (props) => {
    const { onSelect } = props;
    const [gameFilter, setGameFilter] = useState(null);
    const [games, setGames] = useState(null);
    const [options, setOptions] = useState(null);
    const [providerOptions, setProviderOptions] = useState(null);
    const [providers, setProviders] = useState(null);
    const [provider, selectProvider] = useState(null);
    const [game, selectGame] = useState(null);
    useEffect(() => {
        API.post(CMSEndpoints.GetGames, {}).then((result) => {
            console.log(result.result);
            var g = [];
            var p = {};
            result.result.map(x => {
                g.push(<option key={x.id} value={x.gameId}>{x.name}</option>);
                p[x.providerId] = {
                    id: x.providerId,
                    name: x.providerName
                };
            });
            var providerList = [];
            providerList.push(<option value={0}>All</option>);

            Object.values(p).map(x => {
                providerList.push(<option key={x.id} value={x.id}>{x.name}</option>);
            });

            setProviderOptions(providerList);
            setProviders(Object.values(p));
            setGames(result.result);
            setOptions(g);
        });
    }, []);

    if (!options) return <></>;
    const filtered = games.filter(x => {
        if (gameFilter && gameFilter.length > 3) {
            return x.providerId == provider ? provider.id : x.providerId &&
                x.name.toLowerCase().indexOf(gameFilter) >= 0;
        }
        if (provider) return x.providerId == provider.id;
        return true;
    });

    return <div className={'modal-window dialog'}>
        <div className='title'>
            <h1>{lang('Select Game')}</h1>
        </div>
        <div className='body'>
            <div className='flex vertical gap-10'>
                <form>
                    <div className='row'>
                        <div className='form-group'>
                            <label>{lang('Provider')}</label>
                            <select onChangeCapture={(e) => selectProvider(providers.find(x => x.id == parseInt(e.target.value)))}>
                                {providerOptions}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group'>
                            <label>{lang('Game')}</label>
                            <input type='text' name='game' onKeyUpCapture={(e) => {
                                if (e.key == 'Enter') {
                                    setGameFilter(e.target.value);
                                }
                            }} />
                        </div>
                    </div>
                </form>
                <div className='padding border small'>{lang('only 100 items will be displayed')}</div>
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Provider Id</th>
                            <th>Provider</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtered.slice(0, 100).map(x => {
                                return <tr key={x.gameId}
                                    className={x.gameId == game?.gameId ? 'selected' : ''}
                                    onClick={() => selectGame(x)}>
                                    <td className='table-col'>{x.gameId}</td>
                                    <td className='table-col'>{x.name}</td>
                                    <td className='table-col'>{x.providerId}</td>
                                    <td className='table-col'>{x.providerName}</td>
                                </tr>;
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
        <div className='footer flex gap-5'>
            <Button title='Add Selected' className={!game ? 'disabled' : ''} onClick={() => {
                onSelect(game);
                application().modal.close();
            }} />
            <Button title='Cancel' className='align-right' onClick={() => application().modal.close()} />
        </div>
    </div>;
};


export const CasinoConfiguration = (props) => {
    const [retail, setRetail] = useState(props.configuration);

    useEffect(() => {
        var copy = Object.assign({}, retail);

        if (!copy.configuration.casino.revenueLimits || copy.configuration.casino.revenueLimits.length < 2) {
            copy.configuration.casino.revenueLimits = [];
            copy.configuration.casino.revenueLimits.push({
                __id: 1,
                type: 0,
                minBet: 0,
                maxBet: 0,
                percentage: 0,
                enabled: true
            });

            copy.configuration.casino.revenueLimits.push({
                __id: 2,
                type: 1,
                minBet: 0,
                maxBet: 0,
                percentage: 0,
                enabled: true
            });
        }

        if (!copy.configuration.casino.providerLimits) {
            copy.configuration.casino.providerLimits = [];
        }
    }, []);


    const addDisabledGame = () => {
        application().modal.open(<SelectGame onSelect={(g) => {
            var copy = Object.assign({}, retail);
            if (!copy.configuration.casino.disabledGames) copy.configuration.casino.disabledGames = [];
            copy.configuration.casino.disabledGames.push({
                __id: Date.now(),
                id: g.gameId,
                name: g.name,
                providerId: g.providerId,
                providerName: g.providerName
            });
            setRetail(copy);
        }} />);

    };


    const addProviderLimit = () => {
        application().modal.open(<SelectProvider onSelect={(p) => {
            var copy = Object.assign({}, retail);
            if (!copy.configuration.casino.providerLimits) copy.configuration.casino.providerLimits = [];
            copy.configuration.casino.providerLimits.push({
                __id: Date.now(),
                id: p.id,
                name: p.name,
                minBet: 0,
                maxBet: 0,
                percentage: 0
            });
            setRetail(copy);

        }} />);
    };


    const removeProviderLimit = (row) => {
        var copy = Object.assign({}, retail);
        copy.configuration.casino.providerLimits = copy.configuration.casino.providerLimits.filter(x => x.__id != row.__id);
        setRetail(copy);
    };


    const addGameLimit = () => {
        application().modal.open(<SelectGame onSelect={(g) => {
            var copy = Object.assign({}, retail);
            if (!copy.configuration.casino.gameLimits) copy.configuration.casino.gameLimits = [];
            copy.configuration.casino.gameLimits.push({
                __id: Date.now(),
                id: g.gameId,
                name: g.name,
                providerId: g.providerId,
                providerName: g.providerName,
                minBet: 0,
                maxBet: 0,
                percentage: 0
            });
            setRetail(copy);

        }} />);
    };


    const removeGameLimit = (row) => {
        var copy = Object.assign({}, retail);
        copy.configuration.casino.gameLimits = copy.configuration.casino.gameLimits.filter(x => x.__id != row.__id);
        setRetail(copy);
    };



    const addDisabledProvider = () => {
        application().modal.open(<SelectProvider onSelect={(p) => {
            var copy = Object.assign({}, retail);
            if (!copy.configuration.casino.disabledProviders) copy.configuration.casino.disabledProviders = [];
            copy.configuration.casino.disabledProviders.push({
                __id: Date.now(),
                id: p.id,
                name: p.display
            });
            setRetail(copy);

        }} />);
    };

    const removeDisabledGame = (row) => {
        var copy = Object.assign({}, retail);
        copy.configuration.casino.disabledGames = copy.configuration.casino.disabledGames.filter(x => x.__id != row.__id);
        setRetail(copy);
    };

    const removeDisabledProvider = (row) => {
        var copy = Object.assign({}, retail);
        copy.configuration.casino.disabledProviders = copy.configuration.casino.disabledProviders.filter(x => x.__id != row.__id);
        setRetail(copy);
    };


    useEffect(() => {
        props.onUpdate(retail);
    }, [retail]);


    const context = {
        update: (row, value, field) => {
            if (isNaN(value)) return;

            var copy = { ...retail };
            copy.configuration.casino.revenueLimits.find(x => x.__id == row.__id)[field.name] = value;
            setRetail(copy);
        },
        updateProviderLimits: (row, value, field) => {
            if (isNaN(value)) return;
            var copy = { ...retail };
            copy.configuration.casino.providerLimits.find(x => x.__id == row.__id)[field.name] = value;
            setRetail(copy);
        },
        updateGameLimit: (row, value, field) => {
            if (isNaN(value)) return;

            var copy = { ...retail };
            copy.configuration.casino.gameLimits.find(x => x.__id == row.__id)[field.name] = value;
            setRetail(copy);
        }
    };

    if (!retail) return <div />;


    return <div>
        <Form data={retail.configuration.casino} key={retail}>
            <div className='row'>
                <FormInput title='Enabled' value={retail.configuration.casino.enabled} type={InputTypes.bool} name='enabled' onChange={(form, field, value) => {
                    var copy = Object.assign({}, retail);
                    copy.configuration.casino.enabled = value;
                    setRetail(copy);
                    props.onUpdate(copy);
                }} />
                <FormInput title='Deduct Bonus' value={retail.configuration.casino.deductBonus} type={InputTypes.bool} name='deductBonus' onChange={(form, field, value) => {
                    var copy = Object.assign({}, retail);
                    copy.configuration.casino.deductBonus = value;
                    setRetail(copy);
                    props.onUpdate(copy);
                }} />
            </div>
        </Form>
        <Panel title='Limits' className='margin'>
            <Table model={limitRangeModel}
                data={retail.configuration.casino.revenueLimits}
                className='casino-configuration'
                context={context}
                onRenderRow={(row, elements) => {
                    var hasError = false;
                    return <tr className={hasError ? 'error' : ''}>{elements}</tr>;
                }}
            />
        </Panel>
        <Panel title='Provider Limits' className='margin'>
            <Table model={providerLimitModel}
                data={retail.configuration.casino.providerLimits ?? []}
                className='casino-configuration'
                context={context}
                onRenderRow={(row, elements) => {
                    var hasError = false;
                    return <tr className={hasError ? 'error' : ''}>{elements}</tr>;
                }}
                buttons={(row) => {
                    return <Button onClick={() => removeProviderLimit(row)} className='alignRight button' title='Delete'><Icon icon={'times'} className='marginRight' /></Button>;
                }} />
            <div className='centrum-flex margin-top marginBottom padding'>
                <Button title='Add Provider Limit' className={'button'} onClick={() => addProviderLimit()} />
            </div>
        </Panel>
        <Panel title='Game Limits' className='margin'>
            <Table model={gameLimitModel}
                data={retail.configuration.casino.gameLimits ?? []}
                className='casino-configuration'
                context={context}
                onRenderRow={(row, elements) => {
                    var hasError = false;
                    return <tr className={hasError ? 'error' : ''}>{elements}</tr>;
                }}
                buttons={(row) => {
                    return <Button onClick={() => removeGameLimit(row)} className='alignRight button' title='Delete'><Icon icon={'times'} className='marginRight' /></Button>;
                }} />
            <div className='centrum-flex margin-top marginBottom padding'>
                <Button title='Add Game Limit' className={'button'} onClick={() => addGameLimit()} />
            </div>
        </Panel>
        <Panel title='Disabled Providers' className='margin'>
            <Table model={disabledProviders}
                data={retail.configuration.casino.disabledProviders ?? []}
                className='casino-configuration'
                context={context}
                onRenderRow={(row, elements) => {
                    var hasError = false;
                    return <tr className={hasError ? 'error' : ''}>{elements}</tr>;
                }}
                buttons={(row) => {
                    return <Button onClick={() => removeDisabledProvider(row)} className='alignRight button' title='Delete'><Icon icon={'times'} className='marginRight' /></Button>;
                }} />
            <div className='centrum-flex margin-top marginBottom padding'>
                <Button title='Add Provider' className={'button'} onClick={() => addDisabledProvider()} />
            </div>
        </Panel>
        <Panel title='Disabled Games' className='margin'>
            <Table model={disabledGamesModel}
                data={retail.configuration.casino.disabledGames ?? []}
                className='casino-configuration'
                context={context}
                onRenderRow={(row, elements) => {
                    var hasError = false;
                    return <tr className={hasError ? 'error' : ''}>{elements}</tr>;
                }}
                buttons={(row) => {
                    return <Button onClick={() => removeDisabledGame(row)} className='alignRight button' title='Delete'><Icon icon={'times'} className='marginRight' /></Button>;
                }} />
            <div className='centrum-flex margin-top marginBottom padding'>
                <Button title='Add Game' className={'button'} onClick={() => addDisabledGame()} />
            </div>
        </Panel>
    </div>;
};

CasinoConfiguration.propTypes = {
    configuration: PropTypes.object,
    onUpdate: PropTypes.func
};