// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '../../Controls/Form/Form';
import { FormInput } from '../../Controls/Form/FormInput';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Icon } from '../../../v2/Components/Icon/Icon';

// COMPONENT
export class PlayerBonusComplete extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var bonus = (this.props.bonus);

        var bet = 0;
        var discountSettings = null;
        var payout = 0;
        var balance = this.props.bonus.bonusWallet ? this.props.bonusBalance : this.props.balance;
        var configurationNotes = [];
        try {
            var configuration = JSON.parse(bonus.configuration);

            if (configuration.sport && configuration.sport.enabled) {
                bet = bonus.totalBet;

                if (configuration.turnover) {
                    var multipler = parseInt(configuration.turnover.BonusAmountMultiplier);
                    if (!isNaN(multipler)) {
                        if (this.props.bonus.totalBet > multipler * this.props.bonus.amount) {
                            payout = balance;
                            configurationNotes.push({ type: 'check', payload: `Player must have placed bet amount of ${multipler}x${this.props.bonus.amount}. Total bets placed is ${this.props.bonus.totalBet}.` });
                            if (configuration.turnover.DeductBonusAmountWithdrawMultiplier) {
                                console.log(balance);
                                payout = balance - this.props.bonus.amount;
                                configurationNotes.push({ type: 'check', payload: `Given bonus amount ${this.props.bonus.amount} is deducted. ` });
                            }

                            payout = payout.toFixed(2);
                        }
                    } else {
                        configurationNotes.push({ type: 'times', payload: `Player must have placed bet amount of ${multipler}x${this.props.bonus.amount}. Total bets placed is ${this.props.bonus.totalBet}.` });
                    }
                }
            }

            if (configuration.discountSettings && !configuration.discountSettings.useFixedAmount) {
                discountSettings = configuration.discountSettings && configuration.discountSettings.settings ? configuration.discountSettings.settings[this.props.currency] : null;


                if (discountSettings) {
                    discountSettings = discountSettings.reverse().find(x => bet >= x.From);
                    var maxPayoutCap = discountSettings.MaxPayoutCap;
                    if (maxPayoutCap && balance > maxPayoutCap) {
                        balance = maxPayoutCap;
                        configurationNotes.push({ type: 'check', payload: `Discount settings max payout cap is ${maxPayoutCap}, players balance is ${balance} so I can only pay ${maxPayoutCap}.` });
                    }
                }
            }
        } catch (e) {
            //
        }

        console.log(payout);
        return <Form data={{ amount: payout }
        }>
            <FormInput title='Amount' name='amount' type={InputTypes.number} onChange={(a, b, value) => {
                this.props.context.amount = value;
            }} />
            <FormInput title='Notes' type={InputTypes.textArea} onChange={(a, b, value) => {
                this.props.context.notes = value;
            }} />
            <div className='padding border radius flex vertical gap-5'>
                <span className='bold'>Rules</span>
                {
                    configurationNotes.map((x, index) => {
                        return <div className='flex gap-5' key={index}>
                            <Icon icon={x.type} />
                            <span>{x.payload}</span>
                        </div>;
                    })
                }
            </div>
        </Form >;
    }
}

PlayerBonusComplete.defaultProps = {
};

PlayerBonusComplete.propTypes = {
    bonus: PropTypes.object,
    balance: PropTypes.number,
    bonusBalance: PropTypes.number,
    currency: PropTypes.string,
    context: PropTypes.object
};

