import React, { useState } from 'react';
import { Card } from '../../../v2/Components/Card/Card';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { Form } from '../../../v2/Components/Controls/Form/Form';
import { FormInput, InputTypes } from '../../../v2/Components/Controls/Form/FormInput';
import { Table } from '../../../v2/Components/Controls/Table/Table';
import { getHorseEvent, saveHorseManualSettlementData } from './Actions/HorseActions';

const settlementDescriptions = {
    0: 'Less result places received than expected',
    1: 'Expected result places are complete but no Starting Prices have been issued',
    2: 'Expected result and each way places are complete, with Starting Prices',
    3: 'Starting Prices (SPs) and Dividends Complete - Expected result and each way places are complete, with Starting Prices and Dividends (e.g. Forecast / Tricast) published',
    4: 'Hold Result (Objection, Stewards, etc.) - The result may be subject to amendment',
    5: 'Result Fully Complete (Greyhound Racing) - The final confirmation of a result, which customers are advised to use to fully settle bets. Result Official ("Weighed In") (Horses Racing) - For SIS UK and Irish Greyhound Racing, this status is issued when the full order of finish has been published.',
    6: 'Result Void (Race Abandoned, Result Invalid etc.)- The event has been voided. Customers are advised to refund all bets.'
};

const betPlacesModel = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'amount',
            title: 'Amount'
        },
        {
            name: 'position',
            title: 'Position',
            // eslint-disable-next-line react/display-name
            renderRow: (r) => {
                return <FormInput type={InputTypes.Number} value={r.position} onChange={(v) => r.position = parseFloat(v)} />;
            }
        },
        {
            name: 'selectionId',
            title: 'Selection Id',
            // eslint-disable-next-line react/display-name
            renderRow: (r, f, context) => {
                var selections = {};
                context.event.selections.map(x => {
                    selections[x.id] = x.id + ' ' + x.name;
                });
                return <FormInput type={InputTypes.Select} values={selections} value={r.selectionId} onChange={(v) => r.selectionId = parseFloat(v)} />;
            }
        }
    ]
};


const resultPositions = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'name',
            title: 'Name',
            // eslint-disable-next-line react/display-name
            renderRow: (r, f, context) => {
                var selections = {};
                context.event.selections.map(x => {
                    selections[x.name] = x.name;
                });
                return <FormInput type={InputTypes.Select} values={selections} value={r.name} onChange={(v) => r.name = v} />;
            }
        },
        {
            name: 'num',
            title: 'Num',
            // eslint-disable-next-line react/display-name
            renderRow: (r) => {
                return <FormInput type={InputTypes.Number} value={r.num} onChange={(v) => r.num = parseFloat(v)} />;
            }

        },
        {
            name: 'photo',
            title: 'Photo',
            // eslint-disable-next-line react/display-name
            renderRow: (r) => {
                return <FormInput type={InputTypes.Text} value={r.photo} onChange={(v) => r.photo = v} />;
            }

        },
        {
            name: 'position',
            title: 'Position',
            // eslint-disable-next-line react/display-name
            renderRow: (r) => {
                return <FormInput type={InputTypes.Number} value={r.position} onChange={(v) => r.position = parseFloat(v)} />;
            }

        },
        {
            name: 'selectionId',
            title: 'Selection Id',
            // eslint-disable-next-line react/display-name
            renderRow: (r, f, context) => {
                var selections = {};
                context.event.selections.map(x => {
                    selections[x.id] = x.id + ' ' + x.name;
                });
                return <FormInput type={InputTypes.Select} values={selections} value={r.selectionId} onChange={(v) => r.selectionId = parseFloat(v)} />;
            }
        },
        {
            name: 'sp',
            title: 'Sp',
            // eslint-disable-next-line react/display-name
            renderRow: (r) => {
                return <FormInput type={InputTypes.Text} value={r.sp} onChange={(v) => r.sp = v} />;
            }

        },
        {
            name: 'winnersTime',
            title: 'Winners Time',
            // eslint-disable-next-line react/display-name
            renderRow: (r) => {
                return <FormInput type={InputTypes.Text} value={r.winnersTime} onChange={(v) => r.winnersTime = v} />;
            }

        },
        {
            name: 'winningDistance',
            title: 'Winner Distance',
            // eslint-disable-next-line react/display-name
            renderRow: (r) => {
                return <FormInput type={InputTypes.Text} value={r.winningDistance} onChange={(v) => r.winningDistance = v} />;
            }

        },
        {
            name: 'deadheat',
            title: 'Deadheat',
            // eslint-disable-next-line react/display-name
            renderRow: (r) => {
                return <FormInput type={InputTypes.Text} value={r.deadheat} onChange={(v) => r.deadheat = v} />;
            }

        },
    ]
};

export const HorseManualSettlement = () => {
    const [eventKey, setEventKey] = useState(2888254); // horse
    //const [eventKey, setEventKey] = useState(2888425); // dog

    const [event, setEvent] = useState(null);

    const updateData = (data) => {
        data.u = Date.now();
        setEvent({ ...data });
    };

    const context = {
        event: event,
        update: () => {
            updateData(event);
        }
    };

    const renderRaceBets = () => {
        return event.raceBets?.map((raceBet) => {
            if (!raceBet.betPlaces) {
                raceBet.betPlaces = [];
            }
            raceBet.betPlaces.map((x, i) => x.ii = i);
            return <Card key={raceBet.id}>
                <Form>
                    <div className='row'>
                        <FormInput title='Id' value={raceBet.id} />
                        <FormInput title='Amount' value={raceBet.amount} />
                        <FormInput title='Bet Type' value={raceBet.betType} />
                        <FormInput title='Instance' value={raceBet.instance} />
                    </div>
                    <Card title='Bet Places' className='border margin-top' buttons={() => <Button title='Add' onClick={() => {
                        raceBet.betPlaces.push({ id: Date.now(), amount: 0, position: 0, selectionId: 0 });
                        updateData(event);
                    }} />}>
                        <Table model={betPlacesModel} data={raceBet.betPlaces ?? []}
                            context={context}
                            buttons={(row) => {
                                return <div className='flex gap-5'>
                                    <Button title='Delete' onClick={() => {
                                        raceBet.betPlaces = raceBet.betPlaces.filter(x => x.ii !== row.ii);
                                        updateData(event);
                                    }} />
                                    <Button title='Save' onClick={() => {
                                        console.log(row);
                                        updateData(event);
                                    }} />

                                </div>;
                            }} />
                    </Card>
                </Form>
            </Card>;
        });
    };

    const renderTitle = () => {
        switch (event.categoryCode) {
            case 'DG':
                return `Dog ${event.name} ${eventKey} ${event.meetingCode}`;
            default:
                return `${event.title} ${event.name} ${eventKey}`;
        }
    };

    const renderRaceCardTitle = () => {
        switch (event.categoryCode) {
            case 'DG':
                return `Race card ${event.progressCode}`;
            default:
                return `Race card ${event.progressMessage}`;
        }
    };

    const renderCard = () => {
        if (!event) return;
        if (!event.raceBets) event.raceBets = [];
        console.log(event);
        event.result?.positions?.map((x, i) => x.ii = i);
        return <Card title={renderTitle()} className='border margin-top' key={event.u}>
            <div className='flex vertical gap-5'>
                <Card title={renderRaceCardTitle()} className='border'>
                    <Form>
                        <div className='row'>
                            <FormInput title='Date' readonly value={event.date} />
                            <FormInput title='Time' readonly value={event.time} />
                            <FormInput title='Offtime' value={event.offTime} onChange={(v) => {
                                event.offTime = v;
                                updateData(event);
                            }} />
                            <FormInput title='Status' value={event.status}
                                type={InputTypes.Select}
                                values={{
                                    0: '[O] On',
                                    1: '[A] Abondoned',
                                    2: '[V] Void',
                                    3: '[D] Delayed',
                                    4: '[S] StandBy'
                                }}
                                onChange={(v) => {
                                    event.status = parseInt(v);
                                    updateData(event);
                                }} />
                        </div>
                        <div className='row'>
                            <FormInput title='Handicap' readonly value={event.handicap} />
                            <FormInput title='Distance' readonly value={event.distance} />
                            <FormInput title='Country' readonly value={event.country} />
                            <FormInput title='Venue' readonly value={event.venue} />
                        </div>
                    </Form>
                </Card>
                <Card title={`Runners ${event.numOfRunners} Non Runners: ${event.result?.nonRunners}`} className='border'>
                    <Form>
                        <div className='row'>
                            <FormInput title='Eachway Places' value={event.eachWayPlaces} onChange={(v) => {
                                event.eachWayPlaces = parseInt(v);
                                updateData(event);
                            }} />
                        </div>
                        <div className='flex vertical gap-5'>
                            <FormInput title='Status' value={event.result?.statusCode}
                                type={InputTypes.Select}
                                values={{
                                    0: '[O] Off message',
                                    1: '[Q] MAY BE ENQ',
                                    2: '[R] OBJECTION',
                                    3: '[S] STEWARDS ENQ.',
                                    4: '[U] ENQ. WINNER OK',
                                    5: '[V] RESULT STANDS',
                                    6: '[W] Horse: WEIGHED IN  Dog: -- unused --',
                                    7: '[Y] Horse: -- unused --  Dog: RESULT COMPLETE',
                                    8: '[Z] AMENDED RESULT',
                                }} onChange={(v) => {
                                    event.result.statusCode = parseInt(v);
                                    updateData(event);
                                }} />
                            <FormInput title='Settling Status' value={event.result?.settlingStatus}
                                type={InputTypes.Select}
                                values={{
                                    0: '[F] Partial Frame Result',
                                    1: '[P] Result Places Complete',
                                    2: '[S] Result and Each Way Places Complete',
                                    3: '[D] Result Places',
                                    4: '[H] Hold Result (Objection, Stewards, etc.)',
                                    5: '[R] Result Fully Complete (Greyhound Racing)',
                                    6: '[V] Result Void (Race Abandoned, Result Invalid etc.)'
                                }}
                                onChange={(v) => {
                                    event.result.settlingStatus = parseInt(v);
                                    updateData(event);
                                }}
                            />
                            <div className='border radius padding warning'>
                                {settlementDescriptions[event.result?.settlingStatus]}
                            </div>
                        </div>
                    </Form>
                    <Card title='Race Bets' className='border margin-top'>
                        {renderRaceBets()}
                    </Card>
                    <Card title='Positions' className='border margin-top' buttons={() => <Button title='Add' onClick={() => {
                        if (!event.result) event.result = [];
                        if (!event.result.positions) event.result.positions = [];
                        event.result.positions.push({ id: Date.now(), ii: event.result.positions.length });
                        updateData(event);
                    }} />}>
                        <Table data={event.result?.positions ?? []} model={resultPositions} context={context} buttons={(row) => {
                            return <Button title='Delete' onClick={() => {
                                event.result.positions = event.result.positions.filter(x => x.ii !== row.ii);
                                updateData(event);
                            }} />;
                        }} />
                    </Card>
                </Card>
            </div>
            <div className='padding border margin-top'>
                <Button title='Save' onClick={() => {
                    saveHorseManualSettlementData(event);
                }} />
            </div>
        </Card>;
    };

    return <Card title='Manual Settlement'>
        <Form>
            <div className='row'>
                <FormInput title='Event' onChange={(v) => setEventKey(v)} value={eventKey} />
                <Button title='Find' onClick={() => getHorseEvent(eventKey).then((result) => {
                    setEvent(result.result);
                })} />
            </div>
        </Form>
        {renderCard()}
    </Card>;
};