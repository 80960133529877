import { InputTypes} from '../CentrumForm/Inputs/CentrumInput';
import { ProviderGameTypes, ProviderTypes } from './ModelEnums';





export const ProviderModels = {
    searchProviders:{
        queries: {
        },
        fields: [
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ProviderTypes,
                acceptEmptyValues:true
            },
            {
                name: 'Enabled',
                type: InputTypes.select,
                value: true,
                values: {
                    true: 'True',
                    false: 'False'
                },
                acceptEmptyValues:true
            }
        ]
    },
    providerInformation: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name:'Name'
            },
            {
                name: 'Display'
            },
            {
                name:'Type',
                type:InputTypes.select,
                values:ProviderTypes
            },
            {
                name: 'Enabled',
                display: 'Enabled',
                type: InputTypes.bool
            }
        ]
    },
    operatorProviderInformation: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                identity: true,
            },
            {
                name:'Name'
            },
            {
                name: 'Display'
            },
            {
                name: 'Type',
                display:'Type',
                type: InputTypes.select,
                values: ProviderTypes,
                acceptEmptyValues:true
            },
            {
                name: 'Enabled',
                display: 'Enabled',
                type: InputTypes.bool
            },
            {
                name: 'DisabledByPlatform',
                display: 'Disabled By Platform',
                type: InputTypes.bool
            }
        ]
    },    
    searchProviderGames:{
        queries: {
        },
        fields: [
            {
                name: 'Id',
                type: InputTypes.number
            },
            {
                name: 'ProviderId',
                display:'Provider',
                type: InputTypes.select,
                acceptEmptyValues:true,
                valueEndPoint: {
                    source: 'providers/gaming',
                    bindingKey: 'id',
                    bindingText: ['display']
                }
            },
            {
                name: 'LiveDealer',
                display:'Live Dealer',
                type: InputTypes.select,
                values: {
                    true: 'True',
                    false: 'False'
                },
                acceptEmptyValues:true
            },
            {
                name: 'ProviderGameId',
                display:'Provider Game Id',
                type: InputTypes.number
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: ProviderGameTypes,
                acceptEmptyValues:true
            },
            {
                name: 'Enabled',
                type: InputTypes.select,
                value: true,
                values: {
                    true: 'True',
                    false: 'False'
                },
                acceptEmptyValues:true
            }
        ]
    },
    searchOperatorProviderGames:{
        queries: {
        },
        fields: [
            {
                name: 'Id',
                type: InputTypes.number
            },
            {
                name: 'ProviderId',
                type: InputTypes.select,
                acceptEmptyValues:true,
                valueEndPoint: {
                    source: 'providers',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'LiveDealer',
                display:'Live Dealer',
                type: InputTypes.select,
                values: {
                    true: 'True',
                    false: 'False'
                },
                acceptEmptyValues:true
            },
            {
                name: 'ProviderGameId',
                display:'Provider Game Id',
                type: InputTypes.number
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: ProviderGameTypes,
                acceptEmptyValues:true
            },
            {
                name: 'Disabled',
                type: InputTypes.select,
                value: false,
                values: {
                    true: 'True',
                    false: 'False'
                },
                acceptEmptyValues:true
            }
        ]
    },
    providerGamesInformation: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                identity:true
            },
            {
                name:'Weight',
                type:InputTypes.number
            },            
            {
                name: 'ProviderName',
                display:'Provider'
            },
            {
                name: 'LiveDealer',
                display:'Live Dealer',
                type: InputTypes.bool
            },
            {
                name: 'ProviderGameId',
                display:'Provider Game Id',
                type: InputTypes.number
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: ProviderGameTypes
            },
            {
                name: 'Online',
                type: InputTypes.bool
            },
            {
                name: 'Enabled',
                type: InputTypes.bool
            }
        ]
    },
    operatorProviderGamesInformation: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                identity:true
            },
            {
                name:'Weight',
                type:InputTypes.number
            },            
            {
                name: 'ProviderName',
                display:'Provider'
            },
            {
                name: 'LiveDealer',
                display:'Live Dealer',
                type: InputTypes.bool
            },
            {
                name: 'ProviderGameId',
                display:'Provider Game Id',
                type: InputTypes.number
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: ProviderGameTypes
            },
            {
                name: 'Online',
                type: InputTypes.bool
            },
            {
                name: 'Disabled',
                type: InputTypes.bool
            },
            {
                name: 'DisabledByPlatform',
                display:'Disabled by Platform',
                type: InputTypes.bool
            }
        ]
    },
    gameEdit: {
        fields: [
            {
                name: 'Id',
                type: InputTypes.number,
                readonly:true,
                identity:true
            },
            {
                name: 'Name',
                type: InputTypes.text
            },
            {
                name: 'ProviderName',
                display:'Provider',
                readonly:true
            },
            {
                name: 'ProviderGameId',
                display:'Provider Game Id',
                readonly:true
            },
            {
                name: 'Type',
                type: InputTypes.select,
                values: ProviderGameTypes
            },
            {
                name:'Weight',
                type:InputTypes.number
            },
            {
                name: 'LiveDealer',
                display:'Live Dealer',
                type: InputTypes.bool
            },
            {
                name: 'New',
                display:'New',
                type: InputTypes.bool
            },
            {
                name: 'Featured',
                display:'Featured',
                type: InputTypes.bool
            },
        ]
    },
};