import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Panel } from '../../../Centrum/Card/Card';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';

export const Turnover = (props) => {
    const [turnover, setTurnover] = useState(props.turnover ? props.turnover :
        {
            multiplier: 0,
            deductDepositAmount: false
        });
    const context = {
        save: () => {
            var copy = Object.assign({}, turnover);
            setTurnover(copy);
            return copy;
        }
    };

    useEffect(() => {
        if (!props.context) return;
        props.context(context);
    });

    var depositTurnoverMultiplier = {};
    for (var i = 1; i <= 100; i++) {
        depositTurnoverMultiplier[i] = 'x' + i;
    }


    return <Panel title='Turnover' className='margin'>
        <Form data={Object.assign({}, turnover)}>
            <div className='row'>
                <FormInput title='Turnover Multiplier' name='multiplier' type={'select'} values={depositTurnoverMultiplier} onChange={(f, r, value) => {
                    setTurnover({
                        multiplier: value,
                        deductDepositAmount: turnover.deductDepositAmount
                    });
                }} />
                <FormInput title='Deduct Deposit Amount' name='deductDepositAmount' type={'bool'}
                    onChange={(f, r, value) => {
                        setTurnover({
                            multiplier: turnover.multiplier,
                            deductDepositAmount: value
                        });
                    }} />
            </div>
        </Form>
    </Panel>;
};

Turnover.propTypes = {
    turnover: PropTypes.object,
    context: PropTypes.func
};