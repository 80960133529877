import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';

const Languages = {
    'en': 'English',
    'tr': 'Türkçe',
    'fr': 'Francoise',
    'de': 'Deutsch',
    'ir': 'Persian'
};

export const CMSModels = {
    searchWebLocaleString: {
        fields: [
            {
                name: 'Language',
                display: 'Language',
                type: InputTypes.select,
                values: Languages
            },
            {
                name: 'Tag',
                display: 'Tag',
                type: InputTypes.text
            },
            {
                name: 'Key',
                display: 'Key',
                type: InputTypes.text
            },
            {
                name: 'Value',
                display: 'Value',
                type: InputTypes.text
            }
        ]
    },
    resultWebLocaleString: {
        fields: [
            {
                name: 'Id'
            },
            {
                name: 'Language',
                display: 'Language',
                type: InputTypes.select,
                values: Languages
            },
            {
                name: 'Tag',
                display: 'Tag',
                type: InputTypes.text
            },
            {
                name: 'Key',
                display: 'Key',
                type: InputTypes.text
            },
            {
                name: 'Value',
                display: 'Value',
                type: InputTypes.text
            }
        ]
    }
};