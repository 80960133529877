
import React, { useEffect, useState } from 'react';
import { Card } from '../../../../../v2/Components/Card/Card';
import { Button } from '../../../../../v2/Components/Controls/Button/Button';
import { Form } from '../../../../../v2/Components/Controls/Form/Form';
import { API, CasinoLimitsEndpoints, ProviderEndpoints } from '../../../../../v2/Lib/Api/Api';
import { application } from '../../../../Application/Application';
import { lang } from '../../../../Centrum/Localization/lang';
import { ErrorModal } from '../../../../Modal/Modals/Error/ErrorModal';
import { CasinoLimitsGameTypes } from './CasinoLimitsGameTypes';



export const CasinoProviderGameTypeLimits = () => {
    const [limits, setLimits] = useState({});
    const [providers, setProviders] = useState(null);
    const [selectedProvider, selectProvider] = useState(null);
    const [updateKey, setUpdateKey] = useState(null);
    const [gameTypes, setGameTypes] = useState(null);

    const providersSelectionInputRef = React.createRef();
    const selectedProviderRef = React.createRef();

    useEffect(() => {
        API.post(ProviderEndpoints.Gaming).then((result) => {
            setProviders(result.result);
        });
    }, []);

    useEffect(() => {
        if (!selectedProvider) return;
        API.post(`${CasinoLimitsEndpoints.GetProviderLimits}/${selectedProvider}`).then((result) => {
            var converted = null;
            try {
                converted = JSON.parse(result.result);
            } catch (err) {
                //
            }
            API.post(`${ProviderEndpoints.GameTypes}/${selectedProvider}`).then((gameList) => {
                setGameTypes(gameList.result);
                setLimits(converted);

                setUpdateKey(Date.now());

            });
        });
    }, [selectedProvider]);


    const save = () => {
        var data = selectedProviderRef.current.getData();
        API.post(`${CasinoLimitsEndpoints.SaveProviderLimits}/${selectedProvider}`, data).then(() => {
            application().snackbar.open('Limits updated');
        }).catch(() => {
            application().modal.open(<ErrorModal title='Error'>Unable to save limits</ErrorModal>);
        });
    };



    return <React.Fragment>
        <div className='padding flex surface'>
            <Form>
                <div className='row'>
                    {
                        providers &&
                        <div className='form-group'>
                            <div className='flex gap-5'>
                                <span>{lang('Providers')}</span>
                                <select ref={providersSelectionInputRef} onChangeCapture={(e) => {
                                    var id = parseInt(e.target.value);
                                    selectProvider(id);
                                }}>
                                    {!selectedProvider && <option value='null'>Please select</option>}
                                    {
                                        providers.map(provider => {
                                            return <option value={provider.id} key={provider.id}>{provider.display}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    }
                </div>
            </Form>
        </div>
        {selectedProvider && <CasinoLimitsGameTypes key={updateKey}
            limits={limits ?? { general: {}, groups: {} }}
            gameTypes={gameTypes ?? []}
            saveEnabled={false} ref={selectedProviderRef} />}

        {selectedProvider &&
            <Card className='margin-top'>
                <div className='centrum-flex'>
                    <Button title='Save' className='margin alignRight' onClick={() => save()} />
                </div>
            </Card>
        }
    </React.Fragment >;
};
