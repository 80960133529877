import { listerInitial, defaultPending, defaultFulfilled, defaultRejected } from './ApplicationReducer';

import {
    SEARCH_SHOPS_PENDING,
    SEARCH_SHOPS_FULFILLED,
    SEARCH_SHOPS_REJECTED,
    SEARCH_SHOP_TRANSACTIONS_PENDING,
    SEARCH_SHOP_TRANSACTIONS_FULFILLED,
    SEARCH_SHOP_TRANSACTIONS_REJECTED,
    GET_SHOP_PENDING,
    GET_SHOP_FULFILLED,
    GET_SHOP_REJECTED
} from '../actions/ShopActions';
const initialState = {
    shops: listerInitial,
    loading: false,
    loaded: false,
    failed: false,
    error: {},
    errorDisplayed: false
};

const RESET_STATE = 'RESET_STATE';
export function resetState(state) {
    return {
        type: RESET_STATE,
        payload: state
    };
}

export const ShopReducer = (state = initialState, action) => {
    switch (action.type) {
        // ReportByGame
        case SEARCH_SHOPS_PENDING:
            return defaultPending('shops', state, action);
        case SEARCH_SHOPS_FULFILLED:
            return defaultFulfilled('shops', state, action);
        case SEARCH_SHOPS_REJECTED:
            return defaultRejected('shops', state, action);


        case SEARCH_SHOP_TRANSACTIONS_PENDING:
            return defaultPending('shopTransactions', state, action);
        case SEARCH_SHOP_TRANSACTIONS_FULFILLED:
            return defaultFulfilled('shopTransactions', state, action);
        case SEARCH_SHOP_TRANSACTIONS_REJECTED:
            return defaultRejected('shopTransactions', state, action);


        case GET_SHOP_PENDING:
            return defaultPending('shop', state, action);
        case GET_SHOP_FULFILLED:
            return defaultFulfilled('shop', state, action);
        case GET_SHOP_REJECTED:
            return defaultRejected('shop', state, action);
        default:
            return state;
    }
};      