import { InputTypes} from '../CentrumForm/Inputs/CentrumInput';



export const ExchangeRatesModels = {
    exchangeRate:{
        fields: [
            {
                name: 'CurrencyCode',
                display:'Currency',
                type: InputTypes.text,
                hidden:true
            },            
            {
                name: 'Base',
                display:'Base',
                type: InputTypes.text,
                hidden:true
            },            
            {
                name: 'Buy',
                display:'Buy',
                type: InputTypes.number
            },
            {
                name:'Sell',
                display:'Sell',
                type:InputTypes.number
            },
            {
                name: 'EntryDate',
                display:'Last update',
                type: InputTypes.dateTime,
                readonly:true
            }
        ]
    }
};