import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormContext } from './Form';
import { lang } from '../../Centrum/Localization/lang';
import { toLocalDate } from '../../Centrum/helpers';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Switch } from '../Inputs/Switch/Switch';



export class AutoCompleteFormInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <FormInput type={this.props.type} title={this.props.title} onChange={this.props.onChange.bind(this)} >
                {this.props.children}
            </FormInput>
        </React.Fragment>;
    }
}

AutoCompleteFormInput.defaultProps = {
    onChange: () => { }
};

AutoCompleteFormInput.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.node
};



export class FormInput extends Component {
    constructor(props) {
        super(props);
        this.formContext = null;
        this.component = null;
        this.elements = null;

        this.state = Object.assign({}, props);
    }

    componentDidMount() {
        this.setDefaultValue();

        if (this.props.validateOnStart && !this.state.readOnly) {
            this.validate(this.getDefaultValue());
        }

        this.setState({ formatted: this.getFormattedValue() });
    }

    getDefaultValue() {
        var field = this.formContext.getField(this.props.name);
        var value = field.value;
        return value;
    }

    getFormattedValue() {
        if (!this.formContext) return;
        var field = this.formContext.getField(this.props.name);

        var formatted = field.value;
        var value = field.value == undefined ? '' : field.value;
        if (this.state.readOnly && this.props.type == 'select') {
            value = this.props.values[value];
            return value;
        }
        if (field.formatter) formatted = field.formatter(field.value, this.formContext.data);
        var dateValue = null;
        switch (this.props.type) {
            case InputTypes.date:
                dateValue = new Date(value);
                formatted = dateValue.getFullYear() + '-' + ((dateValue.getMonth() + 1).toString().padStart(2, '0')) + '-' + (dateValue.getDate().toString().padStart(2, '0'));
                break;
            case InputTypes.dateTime:
                dateValue = new Date(value);
                formatted = toLocalDate(dateValue).toLocaleString('en-Gb', { hour12: false });
                break;
            case InputTypes.bool:
                return <Switch className='marginLeft' value={field.value} enabled={false} />;
        }

        formatted = this.props.formatter(formatted);
        return formatted;
    }

    // sets default value from form
    setDefaultValue() {
        if (!this.formContext) return;
        if (this.state.readOnly || this.formContext.readOnly) return;
        var field = this.formContext.getField(this.props.name);
        var value = field.value;
        if (field.formatter) value = field.formatter(field.value, this.formContext.data);

        switch (this.props.type) {
            case InputTypes.bool:
                this.component.setValue(value);
                break;
            case InputTypes.select:
                this.component.value = value;
                break;
            case InputTypes.date:
                this.component.value = value ? value.split('T')[0] : '';
                break;
            default:
                this.component.value = value;
                break;
        }
    }

    setValue(value) {
        this.component.value = value;
        this.formContext.update({
            name: this.props.name
        }, value);
    }

    setReadOnly(value) {
        this.setState({ readOnly: value });
    }

    getValue() {
        if (!this.component) return;
        var value = this.component.value;
        switch (this.props.type) {
            case InputTypes.bool:
                value = this.component.getValue() == true;
                break;
        }

        return value;
    }

    onKeyUp(e) {
        if (!this.formContext) {
            this.props.onKeyUp(null, { name: this.props.name, elements: this.elements }, e.target.value, e);
            return;
        }
        this.props.onKeyUp(this.formContext.data, { name: this.props.name, elements: this.elements }, e.target.value, e);
    }

    onKeyDown(e) {
        if (!this.formContext) {
            this.props.onKeyDown(null, { name: this.props.name, elements: this.elements }, e.target.value, e);
            return;
        }
        this.props.onKeyDown(this.formContext.data, { name: this.props.name, elements: this.elements }, e.target.value, e);
    }

    validate(value) {
        if (!this.formContext) {
            this.props.onChange(null, { name: this.props.name, type: this.props.type }, value, this.component);
            return;
        }
        var message = '';
        var validated = true;

        if (this.props.required && (value == null || value == '')) {
            message = lang('can not be empty');
            validated = false;
        }

        if (this.props.type == InputTypes.number && (value != null || value !== '')) {
            value = parseFloat(value);
            if (isNaN(value)) {
                validated = false;
            }
        }

        var validationResult = this.props.onValidate(this.formContext.data, { name: this.props.name, elements: this.elements }, value);
        if (validationResult != true) {
            validated = false;
            message = validationResult.message;
        }


        if (validated) {
            this.formContext.update(this.props, value);
            this.formContext.validate(this.props, { status: true, message: '' });
            this.setState({ error: false, errorMessage: '' });
            this.props.onChange(this.formContext, { name: this.props.name, type: this.props.type }, value);
            return;
        }

        this.formContext.validate(this.props, { status: false, message: message });
        this.setState({ error: true, errorMessage: message });
        return false;
    }

    onChangeCapture() {
        var value = this.getValue();
        this.validate(value);
    }


    toggleEdit() {
        if (this.state.readOnly) return;
        this.elements.classList.toggle('edit');
    }

    renderReadOnly() {
        var classNames = [];
        classNames.push('form-group');
        classNames.push(this.props.className);
        classNames.push(this.props.type);

        return <div className={classNames.join(' ')} ref={(r) => this.elements = r}>
            {this.props.title != '' && <label htmlFor={this.props.name}>{lang(this.props.title)}</label>}
            <div className='readonly value' ref={(r) => this.inputValue = r}>{this.getFormattedValue()}</div>
            {this.props.children}
        </div>;
    }

    renderNormal() {
        var component = null;
        var values = this.props.values;

        switch (this.props.type) {
            case 'date':
                component = <input type={this.props.type} name={this.props.name} defaultValue={this.props.value} readOnly={this.state.readOnly} ref={(r) => this.component = r} onKeyUp={this.onKeyUp.bind(this)} onChangeCapture={this.onChangeCapture.bind(this)} />;
                break;
            case 'bool':
                component = <Switch name={this.props.name} title={this.props.title} checked={this.props.value} readOnly={this.state.readOnly} ref={(r) => this.component = r} onChange={this.onChangeCapture.bind(this)} />;
                break;
            case 'textArea':
                component = <textarea name={this.props.name} placeholder={this.props.placeholder} defaultValue={this.props.value} readOnly={this.state.readOnly} ref={(r) => this.component = r}
                    onKeyUp={this.onKeyUp.bind(this)}
                    onKeyDown={this.onKeyDown.bind(this)}
                    onChangeCapture={this.onChangeCapture.bind(this)} />;
                break;
            case 'text':
            case 'number':
            default:
                component = <input type={this.props.type} name={this.props.name} placeholder={this.props.placeholder} defaultValue={this.props.value} readOnly={this.state.readOnly} mask={this.props.mask} pattern={this.props.pattern} ref={(r) => this.component = r} onKeyUp={this.onKeyUp.bind(this)} onChangeCapture={this.onChangeCapture.bind(this)} />;
                break;
            case 'select':
                component = <select name={this.props.name} defaultValue={this.props.value} readOnly={this.state.readOnly} ref={(r) => this.component = r} onChangeCapture={this.onChangeCapture.bind(this)}>
                    {
                        Object.keys(values).map(key => {
                            return <option key={key} value={key}>{lang(values[key])}</option>;
                        })
                    }
                </select>;
                break;
        }


        return <div className={('form-group ' + this.props.type) + (this.state.error ? ' validation-error' : '') + (this.props.className != '' ? ' ' + this.props.className : '')} ref={(r) => this.elements = r}>
            {this.props.title != '' && this.props.type != 'bool' && <label htmlFor={this.props.name}>{lang(this.props.title)}</label>}
            {this.props.addon == null && component}
            {this.props.addon != null && <div className='input-addon'>{component}{this.props.addon}</div>}
            {this.props.children}
            {this.state.error && <div className='error  '>{this.state.errorMessage}</div>}
        </div>;
    }

    render() {
        var readOnly = this.state.readOnly;
        return <FormContext.Consumer>
            {
                (context) => {
                    this.formContext = context;
                    if (context != null) context.addField(this);
                    if (this.formContext && this.formContext.readOnly) {
                        readOnly = true;
                    }
                    if (readOnly) {
                        return this.renderReadOnly();
                    } else {
                        return this.renderNormal();
                    }
                }
            }
        </FormContext.Consumer>;
    }
}


FormInput.defaultProps = {
    className: '',
    type: InputTypes.text,
    placeholder: '',
    mask: '',
    title: '',
    name: '',
    value: '',
    values: {},
    readOnly: false,
    validateOnStart: false,
    children: null,
    addon: null,

    validation: {
    },
    required: false,
    error: false,
    errorMessage: '',

    pattern: null,

    styles: {},

    formatter: (value) => { return value; },

    onValidate: () => { return true; },
    onKeyUp: () => { return; },
    onKeyDown: () => { return; },
    onChange: () => { return; },
};

FormInput.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    mask: PropTypes.string,
    placeholder: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    values: PropTypes.object,
    validateOnStart: PropTypes.bool,
    readOnly: PropTypes.bool,

    pattern: PropTypes.string,

    children: PropTypes.node,
    addon: PropTypes.node,

    styles: PropTypes.object,


    validation: PropTypes.object,
    required: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,

    formatter: PropTypes.func,

    onValidate: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func

};

