import { InputTypes } from '../../../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { PhysicalRetailUserTypes, RetailUserTypes, VirtualRetailUserType, VirtualRetailUserTypes } from '../../../common';

export const searchVirtualRetails = {
    fields: [
        {
            name: 'UserGroupId',
            display: 'Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: VirtualRetailUserTypes
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Surname',
            display: 'Surname'
        },
        {
            name: 'Email',
            display: 'Email'
        },
        {
            name: 'Username',
            display:'Username'
        }
    ]
};



export const searchUserModel = {
    fields: [
        {
            name: 'UserGroupId',
            display: 'Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: PhysicalRetailUserTypes
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Surname',
            display: 'Surname'
        },
        {
            name: 'Email',
            display: 'Email'
        },
        {
            name: 'Username',
            display:'Username'
        }
    ]
};

export const userModel = {
    fields: [
        {
            name: 'UserGroupId',
            display: 'Type',
            type: InputTypes.select,
            values: RetailUserTypes
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Surname',
            display: 'Surname'
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'Username',
            display:'Username'
        },
        {
            name: 'Email',
            display: 'Email'
        }
    ]
};

export const newUser = {
    fields: [
        {
            name: 'UserGroupId',
            display: 'Type',
            type: InputTypes.select,
            values: PhysicalRetailUserTypes
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Surname',
            display: 'Surname'
        },
        {
            name: 'Username',
            display:'Username'
        },
        {
            name: 'Email',
            display: 'Email'
        },
        {
            name: 'Password',
            display: 'Password'
        }
    ]
};

export const editUser = {
    fields: [
        {
            name: 'UserGroupId',
            display: 'Type',
            type: InputTypes.select,
            values: PhysicalRetailUserTypes
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Surname',
            display: 'Surname'
        },
        {
            name: 'Username',
            display:'Username'
        },
        {
            name: 'Email',
            display: 'Email'
        },
        {
            name: 'Password',
            display: 'Password'
        }
    ]
};

export const virtualUser = {
    fields: [
        {
            name: 'UserGroupId',
            display: 'Type',
            type: InputTypes.select,
            values: VirtualRetailUserType
        },
        {
            name: 'Name',
            display: 'Name'
        },
        {
            name: 'Surname',
            display: 'Surname'
        },
        {
            name: 'Username',
            display:'Username'
        },
        {
            name: 'Email',
            display: 'Email'
        },
        {
            name: 'Password',
            display: 'Password'
        }
    ]
};