import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { TableRow } from './Row';


const SortableItem = SortableElement(({ children }) => {
    return children;
});

const SortableCont = SortableContainer(({ children }) => {
    return children;
});




export const TableBody = (props) => {
    const [data, setData] = useState(props.data);
    if (!props.data) return <React.Fragment />;

    const onSelectionChange = (updatedRow, value) => {
        const index = data.findIndex(x => x === updatedRow);
        data[index].__selected = value;
        setData([...data]);
        if (props.onSelectionChange) props.onSelectionChange(updatedRow, value);
    };

    const body = <tbody>
        {props.data.map((row, index) => {
            const item = <TableRow key={index} row={row} {...props} onSelectionChange={onSelectionChange.bind(this)} />;
            return props.draggable ? <SortableItem {...props} onSelectionChange={onSelectionChange.bind(this)} key={index} index={index} ><TableRow key={index} row={row} {...props} onSelectionChange={onSelectionChange.bind(this)} /></SortableItem> : item;
        })}
    </tbody>;

    return props.draggable ? <SortableCont
        onSortStart={props.onDragStart}
        onSortEnd={props.onDragEnd}
        axis='y'
        lockAxis='y'
        lockToContainerEdges={true}
        lockOffset={['30%', '50%']}
        helperClass='draggable-row'
        useDragHandle={true}
    >{body}</SortableCont> : body;
};

TableBody.propTypes = {
    context: PropTypes.object,
    model: PropTypes.object,
    data: PropTypes.array,
    selectable: PropTypes.bool,
    readonly: PropTypes.bool,
    buttons: PropTypes.func,
    draggable: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func
};
