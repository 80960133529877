import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const SEARCH_SPORT_CATEGORIES            = 'SEARCH_SPORT_CATEGORIES';
export const SEARCH_SPORT_CATEGORIES_PENDING    = 'SEARCH_SPORT_CATEGORIES_PENDING';
export const SEARCH_SPORT_CATEGORIES_FULFILLED  = 'SEARCH_SPORT_CATEGORIES_FULFILLED';
export const SEARCH_SPORT_CATEGORIES_REJECTED   = 'SEARCH_SPORT_CATEGORIES_REJECTED';


export const SEARCH_SPORT_REGIONS             = 'SEARCH_SPORT_REGIONS';
export const SEARCH_SPORT_REGIONS_PENDING     = 'SEARCH_SPORT_REGIONS_PENDING';
export const SEARCH_SPORT_REGIONS_FULFILLED   = 'SEARCH_SPORT_REGIONS_FULFILLED';
export const SEARCH_SPORT_REGIONS_REJECTED    = 'SEARCH_SPORT_REGIONS_REJECTED';

export const SEARCH_SPORT_LEAGUES           = 'SEARCH_SPORT_LEAGUES';
export const SEARCH_SPORT_LEAGUES_PENDING   = 'SEARCH_SPORT_LEAGUES_PENDING';
export const SEARCH_SPORT_LEAGUES_FULFILLED = 'SEARCH_SPORT_LEAGUES_FULFILLED';
export const SEARCH_SPORT_LEAGUES_REJECTED  = 'SEARCH_SPORT_LEAGUES_REJECTED';

export const SEARCH_SPORT_COMPETITORS           = 'SEARCH_SPORT_COMPETITORS';
export const SEARCH_SPORT_COMPETITORS_PENDING   = 'SEARCH_SPORT_COMPETITORS_PENDING';
export const SEARCH_SPORT_COMPETITORS_FULFILLED = 'SEARCH_SPORT_COMPETITORS_FULFILLED';
export const SEARCH_SPORT_COMPETITORS_REJECTED  = 'SEARCH_SPORT_COMPETITORS_REJECTED';

export const SEARCH_SPORT_EVENTS            = 'SEARCH_SPORT_EVENTS';
export const SEARCH_SPORT_EVENTS_PENDING    = 'SEARCH_SPORT_EVENTS_PENDING';
export const SEARCH_SPORT_EVENTS_FULFILLED  = 'SEARCH_SPORT_EVENTS_FULFILLED';
export const SEARCH_SPORT_EVENTS_REJECTED   = 'SEARCH_SPORT_EVENTS_REJECTED';

export const LOAD_SPORT_EVENT_MARKETS           = 'LOAD_SPORT_EVENT_MARKETS';
export const LOAD_SPORT_EVENT_MARKETS_PENDING   = 'LOAD_SPORT_EVENT_MARKETS_PENDING';
export const LOAD_SPORT_EVENT_MARKETS_FULFILLED = 'LOAD_SPORT_EVENT_MARKETS_FULFILLED';
export const LOAD_SPORT_EVENT_MARKETS_REJECTED  = 'LOAD_SPORT_EVENT_MARKETS_REJECTED';


export const LOAD_SPORT_MARKET_TYPES           = 'LOAD_SPORT_MARKET_TYPES';
export const LOAD_SPORT_MARKET_TYPES_PENDING   ='LOAD_SPORT_MARKET_TYPES_PENDING';
export const LOAD_SPORT_MARKET_TYPES_FULFILLED ='LOAD_SPORT_MARKET_TYPES_FULFILLED';
export const LOAD_SPORT_MARKET_TYPES_REJECTED  ='LOAD_SPORT_MARKET_TYPES_REJECTED';


export const LOAD_SPORT_MARKETS           ='LOAD_SPORT_MARKETS';
export const LOAD_SPORT_MARKETS_PENDING   ='LOAD_SPORT_MARKETS_PENDING';
export const LOAD_SPORT_MARKETS_FULFILLED ='LOAD_SPORT_MARKETS_FULFILLED';
export const LOAD_SPORT_MARKETS_REJECTED  ='LOAD_SPORT_MARKETS_REJECTED';



// ACTION CREATORS

export function searchCategories(data) {
    return {
        type: SEARCH_SPORT_CATEGORIES,
        payload: postData(data, Endpoints.SportsbookCategories)
    };
}

export function searchRegions(data) {
    return {
        type: SEARCH_SPORT_REGIONS,
        payload: postData(data, Endpoints.SportsbookRegions)
    };
}

export function searchLeagues(data) {
    return {
        type: SEARCH_SPORT_LEAGUES,
        payload: postData(data, Endpoints.SportsbookLeagues)
    };
}


export function searchCompetitors(data) {
    return {
        type: SEARCH_SPORT_COMPETITORS,
        payload: postData(data, Endpoints.SportsbookCompetitors)
    };
}

export function searchEvents(data) {
    return {
        type: SEARCH_SPORT_EVENTS,
        payload: postData(data, Endpoints.SportsbookEvents)
    };
}

export function loadEventMarkets(data) {
    return {
        type: LOAD_SPORT_EVENT_MARKETS,
        payload: postData({}, Endpoints.SportsbookEventMarkets + data.eventId+'&providerName='+data.providerName)
    };
}

export function searchMarketTypes(data) {
    return {
        type: LOAD_SPORT_MARKET_TYPES,
        payload: postData(data, Endpoints.SportsbookMarketTypes)
    };
}

export function searchMarkets(data) {
    return {
        type: LOAD_SPORT_MARKETS,
        payload: postData(data, Endpoints.SportsbookSearchMarkets)
    };
}





