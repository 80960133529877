import './_table.scss';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { arrayMove } from 'react-sortable-hoc';
import { TableBody } from './Body';
import { TableHead } from './Head';

export const EditableTable = (props) => {
    const [data, updateData] = useState(props.data);
    const onUpdateData = (updated) => {
        updateData([...updated]);
    };

    const onDragStart = useCallback(({ node, index, collection, isKeySorting }) => {
        if (props.onDragStart) {
            props.onDragStart(node, index, collection, isKeySorting);
        }
    });

    const onDragEnd = useCallback(({ oldIndex, newIndex }) => {
        var items = oldItems => {
            var res = arrayMove(oldItems, oldIndex, newIndex);
            props.onDataChange(res);
            return res;
        };


        if (props.onDragEnd) {
            props.onDragEnd(oldIndex, newIndex);
        }

        updateData(items);
    });

    return <table className='centrum-table editable-table'>
        <TableHead {...props} data={data} onUpdateData={onUpdateData.bind(this)} />
        <TableBody {...props} data={data} onDragStart={onDragStart} onDragEnd={onDragEnd} onDoubleClick={(row) => {
            if (props.draggable) {
                var oldIndex = data.findIndex(x => x == row);
                var newIndex = 0;
                var res = arrayMove(data, oldIndex, newIndex);
                updateData(res);
                if (props.onDataChange) {
                    props.onDataChange(res);
                }
            }
            if (props.onDoubleClick) props.onDoubleClick(row);
        }} />
    </table>;
};

EditableTable.defaultProps = {
    data: [],
    onChange: () => { },
    onRowClick: () => { }
};

EditableTable.propTypes = {
    model: PropTypes.object,
    data: PropTypes.array,
    readonly: PropTypes.bool,
    context: PropTypes.object,
    selectable: PropTypes.bool,
    draggable: PropTypes.bool,
    sortable: PropTypes.bool,
    buttons: PropTypes.func,
    onDataChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onRowClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func
};