import React from 'react';
import PropTypes from 'prop-types';

import { CentrumComponent } from '../CentrumComponent';
import { CentrumForm } from './CentrumForm';
import { models } from '../Shared';
import { InputTypes } from './Inputs/CentrumInput';
import { lang } from '../Localization/lang';


const queryBuilderButtons =  [
    {
        type: 'submit',
        name: 'Submit',
        caption: 'Ok'
    },
    {
        type: 'reset',
        name: 'Reset',
        caption: 'Reset'
    },
    {
        type: 'button',
        name: 'Close',
        caption: 'Close'
    }
];



export class QueryBuilder extends CentrumComponent {
    constructor(props) {
        super(props);
        this.state = Object.assign({data:null},props);
    }


    onModelSet(model) {
        let defaultProperties = models.get('qbdefault');
        let sourceFields = defaultProperties.fields;
        let targetFields = model.fields;
        let combinedFields = [];
        combinedFields.push(...sourceFields);
        combinedFields.push(...targetFields);

        return {fields:combinedFields};
    }

    onButtonClicked(button) {
        if (button.name=='Close') {
            if (this.props.onClose!==null) this.props.onClose();
        }
    }

    onFieldValueChanged(field,value) {
        if (this.props.onFieldValueChanged==null) return;
        this.props.onFieldValueChanged(this.props.field,field,value);
    }

    onFormReset(values) {
        if (this.props.onReset==null) return;
        this.props.onReset(this.props.field,values);
    }

    render() {
        if (this.props.field===null) return <div/>;
        let type = 'text';
        type = InputTypes[this.props.field.type];
        if (type===undefined) type ='text';
        var display = (this.props.field.display === undefined || this.props.field.display=== null) ? this.props.field.name : this.props.field.display;
        return (
            <div className='query-builder'>
                <h1>{lang('Query options for')} <span style={{color:'blue'}}>{lang(display)}</span></h1>
                <CentrumForm 
                    model={'qb'+type} 
                    buttons={queryBuilderButtons} 
                    onReset={this.onFormReset.bind(this)}
                    onModelSet={this.onModelSet.bind(this)}  
                    onButtonClicked={this.onButtonClicked.bind(this)} 
                    onFieldValueChanged={(field,value)=>this.onFieldValueChanged(field,value)}/>
            </div>
        );
    }
}

QueryBuilder.defaultProps = {
    field: null,
    onClose:null,
    onReset:null,
    onFieldValueChanged:null
};


QueryBuilder.props = {
    field:PropTypes.object,
    onClose:PropTypes.func,
    onReset:PropTypes.func,
    onFieldValueChanged:PropTypes.func
};