import './_form.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '../../Modal/Modals/Dialog/Dialog';
import { application } from '../../Application/Application';
import { models } from '../../Centrum/Shared';

export const FormContext = React.createContext();

export class Form extends Component {
    constructor(props) {
        super(props);

        this.data = Object.assign({}, props.data);
        this.formContext = {
            data: this.data,
            fields: {},
            errors: [],
            validations: {},
            model: models.get(props.model),
            readOnly: props.readOnly,
            getField: (name) => {
                var field = this.formContext.model.fields.find(x => x.name.toLowerCase() == name.toLowerCase());
                return field ? Object.assign({ value: this.formContext.data[name] }, field) : { value: this.formContext.data[name] == undefined ? '' : this.formContext.data[name] };
            },
            addField: (field) => {
                this.formContext.fields[field.props.name] = field;
            },
            validate: (field, data) => {
                this.formContext.validations[field.name] = data;
            },
            update: (field, value) => {
                this.data[field.name] = value;
                this.props.onChange(this.data);
            }
        };

        this.state = Object.assign({}, props);
    }

    componentDidMount() {
    }

    isValidated() {
        var validated = true;
        Object.keys(this.formContext.fields).map(fieldName => {
            var field = this.formContext.fields[fieldName];
            field.validate(field.getValue());
        });
        Object.keys(this.formContext.validations).map(fieldName => {
            if (!this.formContext.validations[fieldName].status) {
                validated = false;
                this.formContext.errors.push(this.formContext.validations[fieldName].message);
            }
        });

        return validated;
    }

    submit(e) {
        if (e) e.preventDefault();
        return this.post();
    }


    post() {
        if (!this.isValidated()) {
            var errorFields = [];
            Object.keys(this.formContext.validations).map(fieldName => {
                if (!this.formContext.validations[fieldName].status) {
                    errorFields.push(<li key={fieldName}><span>{fieldName}</span> {this.formContext.validations[fieldName].message}</li>);
                }
            });
            application().modal.open(<Dialog title='Please correct the required fields'><ul>{errorFields}</ul></Dialog>);
            return false;
        }

        this.props.onSubmit(this.formContext.data);
        return this.formContext.data;
    }

    render() {
        return <form className={'centrum-form ' + this.props.className} onSubmit={(e) => this.submit(e)} autoComplete='off'>
            <FormContext.Provider value={this.formContext}>
                {this.props.children}
            </FormContext.Provider>
        </form>;
    }
}


Form.defaultProps = {
    className: '',
    model: '',
    data: {},
    children: null,
    confirm: true,
    readOnly: false,
    onSubmit: () => { },
    onChange: () => { }
};

Form.propTypes = {
    className: PropTypes.string,
    model: PropTypes.any,
    data: PropTypes.object,

    // show confirmation before posting
    comfirm: PropTypes.bool,
    readOnly: PropTypes.bool,

    // child elements
    children: PropTypes.node,

    // events
    onSubmit: PropTypes.func,
    onChange: PropTypes.func
};

