// IMPORT PACKAGE REFERENCES

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../Centrum/Card/Card';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Button } from '../../../Controls/Buttons/Button';
import { ProviderGameTypes} from '../../../Centrum/_models/ModelEnums';





// COMPONENT
export class GameFilters extends Component {
    constructor(props) {
        super(props);



        this.providers = {};
        props.games.map(x => {
            this.providers[x.providerId] = x.providerName;
        });
        this.state = Object.assign({}, props);
    }

    componentDidMount() {

    }


    render() {
        return <Card caption='Filters' className='provider-game-filters'>
            <div className='gameFilters'>
                <Form data={{ enabled: true }} onSubmit={(data) => {
                    const games = Object.assign([], this.props.games).filter(x => {
                        var res = x.enabled == data.enabled;
                        if (!res) return false;
                        if (data.name.length > 3) {
                            res = x.name.toLowerCase().indexOf(data.name.toLowerCase()) >= 0;
                            if (!res) return false;
                        }
                        if (data.fav) {
                            res = x.featured;
                            if (!res) return false;
                        }
                        if (data.new) {
                            res = x.new;
                            if (!res) return false;
                        }
                        if (data.new) {
                            res = x.new;
                            if (!res) return false;
                        }
                        if (data.provider) {
                            res = x.providerId == parseFloat(data.provider);
                            if (!res) return false;
                        }    
                        if (data.type) {
                            res = x.type == parseFloat(data.type);
                            if (!res) return false;
                        }    
                        return true;
                    });
                    this.props.onFilterChanged(games);
                }}>
                    <div className='row'>
                        <FormInput name='name' title='Game name' />
                        <FormInput name='provider' title='Provider' values={this.providers} type={InputTypes.select} />
                        <FormInput name='type' title='Type' values={ProviderGameTypes} type={InputTypes.select} />
                    </div>
                    <div className='row'>
                        <FormInput type={InputTypes.bool} name='new' title='New Games' />
                        <FormInput type={InputTypes.bool} name='fav' title='Favourites' />
                        <FormInput type={InputTypes.bool} name='enabled' title='Enabled' />
                    </div>
                    <div className='row margin-top'>
                        <Button title='Filter' type='submit' />
                    </div>
                </Form>
            </div>
        </Card>;
    }
}

GameFilters.defaultProps = {
    onFilterChanged: () => { }
};

GameFilters.propTypes = {
    games: PropTypes.array,
    providers: PropTypes.object,
    onFilterChanged: PropTypes.func
};

