
import {
    NEW_OPERATOR_PENDING,
    NEW_OPERATOR_FULFILLED,
    NEW_OPERATOR_REJECTED,
    SEARCH_OPERATORS_PENDING,
    SEARCH_OPERATORS_FULFILLED,
    SEARCH_OPERATORS_REJECTED,
    GET_OPERATOR_PENDING,
    GET_OPERATOR_FULFILLED,
    GET_OPERATOR_REJECTED,
    UPDATE_OPERATOR_PENDING,
    UPDATE_OPERATOR_FULFILLED,
    UPDATE_OPERATOR_REJECTED,
    NEW_OPERATOR_USER_PENDING,
    NEW_OPERATOR_USER_FULFILLED,
    NEW_OPERATOR_USER_REJECTED,
    SEARCH_OPERATOR_USERS_PENDING,
    SEARCH_OPERATOR_USERS_FULFILLED,
    SEARCH_OPERATOR_USERS_REJECTED,
    GET_OPERATOR_USER_PENDING,
    GET_OPERATOR_USER_FULFILLED,
    GET_OPERATOR_USER_REJECTED,
    UPDATE_OPERATOR_USER_PENDING,
    UPDATE_OPERATOR_USER_FULFILLED,
    UPDATE_OPERATOR_USER_REJECTED,
    GET_OPERATOR_USER_GROUPS_PENDING,
    GET_OPERATOR_USER_GROUPS_FULFILLED,
    GET_OPERATOR_USER_GROUPS_REJECTED,
    SEARCH_OPERATOR_USER_ACTIVITIES_PENDING,
    SEARCH_OPERATOR_USER_ACTIVITIES_FULFILLED,
    SEARCH_OPERATOR_USER_ACTIVITIES_REJECTED
} from '../actions/OperatorActions';

export const defaultPending = (target, state, properties) => {
    let res = {
        ...state,
        errorDisplayed: false,
        loading: true,
        loaded: false,
        failed: false
    };


    if (target !== '') {
        //res[target] = {};
        res[target + '_loading'] = true;
        res[target + '_loaded'] = false;
        res[target + '_failed'] = false;
    }

    if (properties !== undefined) {
        return Object.assign(res, properties);
    }

    return res;
};

export const defaultFulfilled = (target, state, action) => {
    let res = {
        ...state,
        errorDisplayed: false,
        error: action.payload.error,
        loading: false,
        loaded: true,
        failed: false
    };

    res[target] = action.payload;
    res[target + '_loaded'] = true;
    res[target + '_loading'] = false;
    res[target + '_failed'] = false;
    return res;
};

export const defaultRejected = (target, state, action, properties) => {

    var res = {
        ...state,
        errorDisplayed: false,
        error: action.payload.toString() === 'SyntaxError: Unexpected token < in JSON at position 0' ? generalError : action.payload.error,
        loading: false,
        loaded: false,
        failed: true
    };

    res[target + '_failed'] = true;
    res[target + '_loading'] = false;
    res[target + '_loaded'] = false;
    if (properties !== undefined) {
        return Object.assign(res, properties);
    }

    window.centrumError(Object.keys(action.payload).length>0 ?  action.payload.error : action.payload);
    return res;
};

export const resetApplicationState = (target, state) => {
    var res = {
        ...state,
        loading: false,
        loaded: false,
        failed: false
    };

    if (target !== '') {
        res[target + '_failed'] = true;
        res[target + '_loading'] = false;
        res[target + '_loaded'] = false;
    }

    return res;
};




// INITIALIZE STATE

export const listerInitial = {
    result: [],
    footer: [],
    model: '',
    total: 0,
    currentPage: 0,
    recordsPerPage: 0,
    request: null,
    sorting: {
        enabled: true,
        column: '',
        direction: 0
    }
};

const initialState = {
    loading: false,
    loaded: false,
    failed: false,
    error: {},
    errorDisplayed: false
};

const generalError = {
    errorNumber: 0,
    message: 'An error occured please try later.'
};


export const RESET_STATE = 'RESET_STATE';
export function resetState(state) {
    return {
        type: RESET_STATE,
        payload: state
    };
}


// REDUCER
export const ApplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STATE:
            var object = Object.assign({}, state);
            if (action.payload === '' ||
                action.payload === undefined ||
                action.payload === null) {
                //console.log('can not reset undefined or null state object');
                object.failed = false;
                return object;
            }
            object[action.payload].failed = false;
            return object;

        // Operators
        // Search operator
        case NEW_OPERATOR_PENDING:
            return defaultPending('operator', state);
        case NEW_OPERATOR_FULFILLED:
            return defaultFulfilled('operator', state, action);
        case NEW_OPERATOR_REJECTED:
            return defaultRejected('operator', state, action);

        case SEARCH_OPERATORS_PENDING:
            return defaultPending('operators', state);
        case SEARCH_OPERATORS_FULFILLED:
            return defaultFulfilled('operators', state, action);
        case SEARCH_OPERATORS_REJECTED:
            return defaultRejected('operators', state, action);

        case SEARCH_OPERATOR_USER_ACTIVITIES_PENDING:
            return defaultPending('operatorUserActivities', state);
        case SEARCH_OPERATOR_USER_ACTIVITIES_FULFILLED:
            return defaultFulfilled('operatorUserActivities', state, action);
        case SEARCH_OPERATOR_USER_ACTIVITIES_REJECTED:
            return defaultRejected('operatorUserActivities', state, action);

        // Get operator
        case GET_OPERATOR_PENDING:
            return defaultPending('operator', state);
        case GET_OPERATOR_FULFILLED:
            return defaultFulfilled('operator', state, action);
        case GET_OPERATOR_REJECTED:
            return defaultRejected('operator', state, action);

        // Update operator
        case UPDATE_OPERATOR_PENDING:
            return defaultPending('operator', state);
        case UPDATE_OPERATOR_FULFILLED:
            return defaultFulfilled('operator', state, action);
        case UPDATE_OPERATOR_REJECTED:
            return defaultRejected('operator', state, action);

        // Operators
        // Search operator User
        case NEW_OPERATOR_USER_PENDING:
            return defaultPending('operatorUser', state);
        case NEW_OPERATOR_USER_FULFILLED:
            return defaultFulfilled('operatorUser', state, action);
        case NEW_OPERATOR_USER_REJECTED:
            return defaultRejected('operatorUser', state, action);

        case SEARCH_OPERATOR_USERS_PENDING:
            return defaultPending('operatorUsers', state);
        case SEARCH_OPERATOR_USERS_FULFILLED:
            return defaultFulfilled('operatorUsers', state, action);
        case SEARCH_OPERATOR_USERS_REJECTED:
            return defaultRejected('operatorUsers', state, action);

        // Get operator
        case GET_OPERATOR_USER_PENDING:
            return defaultPending('operatorUser', state);
        case GET_OPERATOR_USER_FULFILLED:
            return defaultFulfilled('operatorUser', state, action);
        case GET_OPERATOR_USER_REJECTED:
            return defaultRejected('operatorUser', state, action);

        // Update operator
        case UPDATE_OPERATOR_USER_PENDING:
            return defaultPending('operatorUser', state);
        case UPDATE_OPERATOR_USER_FULFILLED:
            return defaultFulfilled('operatorUser', state, action);
        case UPDATE_OPERATOR_USER_REJECTED:
            return defaultRejected('operatorUser', state, action);


        case GET_OPERATOR_USER_GROUPS_PENDING:
            return defaultPending('operatorUserGroups', state);
        case GET_OPERATOR_USER_GROUPS_FULFILLED:
            return defaultFulfilled('operatorUserGroups', state, action);
        case GET_OPERATOR_USER_GROUPS_REJECTED:
            return defaultRejected('operatorUserGroups', state, action);


        default:
            return state;
    }
};