/* eslint-disable no-undef */
// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Search } from '../Centrum/Search/Search';

import { Page } from '../Centrum/Page/Page';
import { searchPlayer, searchActivity } from '../../state/actions/PlayerActions';
import { getProfile } from '../../state/actions/PlayerActions';

import { ActionTypes, getActions, getAction } from '../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../Centrum/Application/CentrumApplication';
import { Card } from '../Centrum/Card/Card';
import { lang, currency } from '../Centrum/Localization/lang';
import { SaveButtons, CentrumForm } from '../Centrum/CentrumForm/CentrumForm';
import { toPascalCase } from '../Centrum/helpers';
import { Endpoints, application, apiResult } from '../Application/Application';
import { Table } from '../Centrum/Table/Table';
import { PlayerRiskManagementPage } from './Players/PlayerRiskManagementPage';
import { Tab } from '../Centrum/Tab/Tab';
import { BreadCrumbs } from '../Controls/BreadCrumbs/BreadCrumbs';
import { ConfirmationModal } from '../Modal/Modals/Confirmation/ConfirmationModal';
import { PlayerBonusSelection } from './Players/PlayerBonusSelection';
import { getHistory } from '../routers/AppRouter';
import { PlayerBonusComplete } from './Players/PlayerBonusComplete';
import { PlayerShortcuts } from './Players/Shortcuts/PlayerShortcuts';
import { depositPlayer, movePlayerToAffiliate, movePlayerToRetail, removePlayerFromAffiliate, removePlayerFromRetail, withdrawPlayer } from './Players/actions';
import { InputTypes } from '../Centrum/CentrumForm/Inputs/CentrumInput';
import { PlayerVerificationType } from '../Centrum/_models/ModelEnums';
import { PlayerEndpoints } from '../../v2/Lib/Api/Api';
import { UploadedDocument } from './Players/UploadedDocument';
import { UploadedDocumentKey } from './Players/VerificationConstants';
import { Button } from '../../v2/Components/Controls/Button/Button';
import { PlayerNotifications } from './Players/PlayerNotifications/PlayerNotifications';

const applicationActionType = ActionTypes.Player;
const applicationActions = getActions(applicationActionType);



const searchPlayerVerifications = {
    fields: [
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Operator',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.number,
            identity: true,
        },
        {
            name: 'Code',
            type: InputTypes.text,
            queryBuilderOptions: {
                Exact: true
            }
        },
        {
            name: 'Username',
            type: InputTypes.text,
            queryBuilderOptions: {
                Exact: false
            }
        },
        {
            name: 'Name',
            type: InputTypes.text
        },
        {
            name: 'Surname',
            type: InputTypes.text
        },
        {
            name: 'IdNumber',
            display: 'Id / Passport',
            type: InputTypes.text,
        },
        {
            name: 'Email',
            display: 'Email',
            type: InputTypes.text,
        },
        {
            name: 'Tag',
            display: 'Tags',
            type: InputTypes.text,
        },
        {
            name: 'Balance',
            type: InputTypes.number,
            max: 32
        },
        {
            name: 'IdStatus',
            display: 'Id / Passport Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: PlayerVerificationType
        },
        {
            name: 'AddressStatus',
            display: 'Address Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: PlayerVerificationType
        },
        {
            name: 'EmailStatus',
            display: 'Email Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: PlayerVerificationType
        },
        {
            name: 'PhoneStatus',
            display: 'Phone Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: PlayerVerificationType
        }
    ]
};


export const playerVerificationResult = {
    fields: [
        {
            name: 'Group',
            display: 'Player Group'
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.number,
            identity: true,
        },
        {
            name: 'Username',
            type: InputTypes.text
        },
        {
            name: 'Name',
            type: InputTypes.text
        },
        {
            name: 'Surname',
            type: InputTypes.text
        },
        {
            name: 'IdNumber',
            display: 'Id / Passport',
            type: InputTypes.text,
        },
        {
            name: 'Email',
            display: 'Email',
            type: InputTypes.text,
        },
        {
            name: 'Tag',
            display: 'Tags',
            type: InputTypes.text,
        },
        {
            name: 'Balance',
            type: InputTypes.number,
            max: 32
        },
        {
            name: 'IdStatus',
            display: 'Id / Passport Status',
            type: InputTypes.select,
            values: PlayerVerificationType
        },
        {
            name: 'AddressStatus',
            display: 'Address Status',
            type: InputTypes.select,
            values: PlayerVerificationType
        },
        {
            name: 'EmailStatus',
            display: 'Email Status',
            type: InputTypes.select,
            values: PlayerVerificationType
        },
        {
            name: 'PhoneStatus',
            display: 'Phone Status',
            type: InputTypes.select,
            values: PlayerVerificationType
        }
    ]
};

// COMPONENT
class PlayersPage extends Page {
    constructor(props) {
        super(props);
        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        //state.selectedPlayer = { Id: 1 };
        state.modal = null;
        state.action = action;

        this.state = state;
        this.applicationContext = null;
    }

    componentDidMount() {
        this.getGroups();
    }

    getGroups() {
        application().resolve(null, Endpoints.GetPlayerGroups, {}, 'retreiving player groups', 'unable to fetch groups').then((result) => {
            this.setState({ playerGroups: result.result });
        });
    }

    openProfile(player) {
        const location = `/Player/PlayerShortcuts/Profile/${player.Id}`;
        getHistory().push({
            pathname: location,
            state: { id: player.Id, profile: true }
        });
    }

    savePlayer(qb) {
        this.applicationContext.confirmActionAndPost(qb, 'newPlayer', '/api/player/new', (data) => {
            this.applicationContext.navigate(ActionTypes.SearchPlayer);
            var playerInformation = toPascalCase(data.result);
            this.onActionClicked({ type: ActionTypes.PlayerProfile }, [playerInformation]);
        }, 'col2', 'Save Player', 'Player Saved.', true);
    }

    savePlayerGroup(data) {
        if (Object.keys(data).length == 0) return;
        if (data.Name.trim().length == 0) return;
        this.applicationContext.confirmActionAndPost(data, 'newPlayerGroup', Endpoints.CreatePlayerGroup, () => {
            this.getGroups();
        }, 'col2', 'Save Player Group', 'Player Group Saved.', true);

    }

    createManualTransaction(action, form, formData, handler) {
        var data = {
            playerId: this.state.selectedPlayer.Id,
            username: this.state.selectedPlayer.Username,
            currency: formData.currency,
            balance: currency(formData.balance, 2, formData.currency),
            transactionType: action === ActionTypes.CreateManualDeposit ? 0 : 1
        };

        switch (action) {
            case ActionTypes.CreateManualDeposit:
                depositPlayer(data, handler);
                break;
            case ActionTypes.CreateManualWithdraw:
                withdrawPlayer(data, handler);
                break;
        }

        /*
        this.applicationContext.confirmActionAndPost(data, 'createTransaction', Endpoints.CreateTransaction,
            (apiResult) => {
                handler({ currency: apiResult.result.currency });
            }, 'col2', action === ActionTypes.CreateManualDeposit ? 'Create Deposit' : 'Create Withdraw',
            action === ActionTypes.CreateManualDeposit ? 'Deposit completed.' : 'Withdraw completed.', false);
        */
    }

    isExportEnabled(target) {
        var user = window.user?.UserPrivileges?.SpecialActions;
        if (!user) return false;
        if (user.find(x => x == target)) {
            return true;
        }
        return false;
    }

    createBonusTransaction(action) {
        switch (action) {
            case ActionTypes.DepositBonus:
                getProfile(this.state.selectedPlayer.Id, this.state.selectedPlayer.Currency).then((result) => {
                    application().resolve(null, Endpoints.ListPromotions, {}, 'fetching bonus list', 'unable to fetch bonus list').then((bonusList) => {
                        var activeBonuses = bonusList.result.filter(x => x.Enabled);
                        application().modal.open(<ConfirmationModal title='Create Bonus'
                            onConfirm={() => {
                                var data = this.playerBonusSelection.values();
                                data.playerId = this.state.selectedPlayer.Id;

                                application().resolve(null, Endpoints.CreateBonusTransaction, data, 'creating bonus', 'unable to create bonus').then(() => {
                                    application().snackbar.open('Bonus Created');
                                    this.setState({ updateDate: Date.now() });

                                });
                            }}>
                            <PlayerBonusSelection profile={result.result} activeBonuses={activeBonuses} ref={(r) => this.playerBonusSelection = r} />
                        </ConfirmationModal>);
                    });
                });
                break;
            case ActionTypes.WithdrawBonus:
                application().modal.open(<ConfirmationModal title='Remove Bonus'
                    onConfirm={() => {
                        application().resolve(null, Endpoints.RemoveBonus + '/' + this.state.selectedPlayer.Id, {}, 'removing bonus', 'unable to romve bonus').then(() => {
                            application().snackbar.open('Bonus Removed');
                            this.setState({ updateDate: Date.now() });

                        });
                    }}>
                    <p>{lang('Please confirm bonus removal. Player bonus balance will be reset to 0')}.</p>
                </ConfirmationModal>);
                break;
            case ActionTypes.CompleteBonus:
                getProfile(this.state.selectedPlayer.Id, this.state.selectedPlayer.Currency).then((result) => {
                    application().resolve(null, Endpoints.ListPromotions, {}, 'fetching bonus list', 'unable to fetch bonus list').then((bonusList) => {

                        application().modal.open(<ConfirmationModal title='Complete Bonus'
                            onConfirm={() => {
                                application().resolve(null, Endpoints.RemoveBonus + '/' + this.state.selectedPlayer.Id, {}, 'removing bonus', 'unable to romve bonus').then(() => {
                                    application().snackbar.open('Bonus Removed');
                                    this.setState({ updateDate: Date.now() });

                                });
                            }}>
                            <p>{lang('Please confirm bonus removal. Player bonus balance will be reset to 0')}.</p>
                            <PlayerBonusComplete profile={result.result} activeBonuses={bonusList.result} ref={(r) => this.PlayerBonusComplete = r} />
                        </ConfirmationModal>);
                    });
                });

                break;
        }
    }

    onActionClicked(action, items) {
        const isActionEnabled = () => {
            if (window.user.UserPrivileges?.SpecialActions?.indexOf('UpdatePlayerDetails') >= 0) {
                return true;
            }
            return false;
        };

        var player = items[0];
        var tab = this.applicationContext.getComponentByName('searchPlayers');
        switch (action.type) {
            case ActionTypes.ViewPlayerVerification:
                this.setState({ selectPlayerToVerify: player });
                break;
            case ActionTypes.PlayerProfile:
                this.openProfile(player);
                break;
            case ActionTypes.PlayerRiskManagement:
                this.playersBreadCrumbs.push('Risk Management');
                tab.selectByKey('riskManagement');
                this.setState({ riskManagement: player });
                break;
            case ActionTypes.UpdatePlayer:
                this.applicationContext.loadData({}, '/api/player/' + player.Id, (data) => {
                    this.applicationContext.edit(data.result, 'Edit Player', isActionEnabled() ? 'editPlayer' : 'editPlayerWithoutPhone', '/api/player/update', 'Player information updated.');
                });
                break;
            case ActionTypes.ChangePlayerPassword:
                this.applicationContext.edit({ Id: player.Id }, 'Edit Player', 'changePlayerPassword', '/api/player/changePassword', 'Player password changed.');
                break;
            case ActionTypes.ToggleEnablePlayer:
                this.applicationContext.confirmActionAndPost(player, 'enableDisablePlayer', Endpoints.ToggleEnablePlayer + player.Id, null, 'col2', action.caption,
                    player.Enabled ? 'Player disabled' : 'Player enabled');
                break;
            case ActionTypes.MovePlayerToRetail:
                if (player.RetailName != null) {
                    removePlayerFromRetail(player);
                } else {
                    movePlayerToRetail(player);
                }
                break;
            case ActionTypes.AddPlayerToAffiliate:
                if (player.Affiliate != null) {
                    removePlayerFromAffiliate(player, () => {
                        player.Affiliate = '';
                    });
                } else {
                    movePlayerToAffiliate(player, () => {
                        player.Affiliate = 'sss';
                    });
                }
                break;
            default:
                console.log(action.type);
                break;
        }
    }

    onRenderPlayerSearchActions(action, selection) {
        if (selection.length == 0) action;
        var item = selection[0];
        if (item === undefined) return action;
        switch (action.type) {
            case ActionTypes.ToggleEnablePlayer:
                return item.Enabled ? { caption: lang('Deactivate Player'), icon: 'disable', enabled: true } : { caption: lang('Activate Player'), icon: 'enable', enabled: true };
            case ActionTypes.MovePlayerToRetail:
                return item.RetailName != null ? { caption: lang('Remove from Retail'), enabled: true } : { caption: lang('Move To Retail'), enabled: true };
            case ActionTypes.MovePlayerToAffiliate:
                return item.Affiliate != null ? { caption: lang('Remove from Affiliate'), enabled: true } : { caption: lang('Move To Affiliate'), enabled: true };
        }
        return action;
    }

    render() {
        return (
            <CentrumApplication caption='Players' icon='users' type={applicationActionType} loading={this.props.loading}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <Card caption='New Player'>
                                        <CentrumForm model='newPlayer' className='vertical' buttons={SaveButtons} onSubmit={this.savePlayer.bind(this)} />
                                    </Card>
                                    <Card caption='Player groups'>
                                        {this.state.createNewGroup && <CentrumForm model='newPlayerGroup' className='vertical' buttons={SaveButtons} onSubmit={this.savePlayerGroup.bind(this)} />}
                                        {!this.state.createNewGroup && <button className='marginLeft margin-top' onClick={() => this.setState({ createNewGroup: true })}>{lang('Create new group')}</button>}
                                        {this.state.playerGroups.length > 0 && <Table className='margin' model='newPlayerGroup' data={{ 'model': 'newPlayerGroup', result: this.state.playerGroups }} selectable={true} multipleSelect={false} showResultsSummary={false} showActions={false} showPagination={false} sortingEnabled={false} />}
                                    </Card>
                                    <Card name='players'>
                                        <BreadCrumbs root='Players' ref={(r) => this.playersBreadCrumbs = r} onClick={() => { this.playersTab.selectByKey('search'); this.setState({ selectedPlayer: null, riskManagement: null }); }} />
                                        <Tab name='searchPlayers' ref={(r) => this.playersTab = r}>
                                            <Search key='search'
                                                model='playerSearch'
                                                exportToExcel={this.isExportEnabled('ExportPlayers')}
                                                exportAllData={this.isExportEnabled('ExportPlayers')}
                                                resultModel='playerSummary'
                                                data={this.props.players}
                                                actions={getAction(ActionTypes.SearchPlayer).convertToItems()}
                                                onActionClicked={this.onActionClicked.bind(this)}
                                                onRenderAction={this.onRenderPlayerSearchActions.bind(this)}
                                                onSubmit={(data, qb) => {
                                                    this.riskManagement = null;
                                                    return apiResult('players', Endpoints.PlayerSearch, qb, 'fetching players', 'unable to fetch ticekts');
                                                }}
                                                onSubmit2={(data, qb) => {
                                                    this.riskManagement = null;
                                                    this.props.searchPlayer(qb);
                                                }}
                                            />
                                            <React.Fragment key='riskManagement'>
                                                {
                                                    this.state.riskManagement != null &&
                                                    <PlayerRiskManagementPage player={this.state.riskManagement} />
                                                }
                                            </React.Fragment>
                                        </Tab>
                                    </Card>
                                    <Card name='playerVerifications'>
                                        <Search key='search'
                                            title='Player Verifications'
                                            model={searchPlayerVerifications}
                                            exportToExcel={false}
                                            resultModel={playerVerificationResult}
                                            data={this.props.playerVerifications}
                                            actions={getAction(ActionTypes.SearchPlayerVerifications).convertToItems()}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            onRenderAction={this.onRenderPlayerSearchActions.bind(this)}
                                            onSubmit={(data, qb) => { return apiResult('playerVerifications', PlayerEndpoints.Verficiations, qb, 'fetching verificatrions', 'unable to fetch verifications'); }}
                                        >
                                            {this.state.selectPlayerToVerify != null && <div className='panel padding' key={this.state.selectedPlayerToVerify}>
                                                <div className='flex gap-5 fit padding'>
                                                    <label>Player documents</label><span>{this.state.selectPlayerToVerify.Username}</span>
                                                    <Button title='Close' onClick={() => this.setState({ selectPlayerToVerify: null })} />
                                                </div>
                                                <div className='flex vertical gap-10'>
                                                    <Card title="Uploaded Address Documents">
                                                        <UploadedDocument PlayerId={this.state.selectPlayerToVerify.Id} Status={this.state.selectPlayerToVerify.AddressStatus} DocumentKey={UploadedDocumentKey.Address} />
                                                    </Card>
                                                    <Card title="Uploaded Id Documents">
                                                        <UploadedDocument PlayerId={this.state.selectPlayerToVerify.Id} Status={this.state.selectPlayerToVerify.IdStatus} DocumentKey={UploadedDocumentKey.Id} />
                                                    </Card>
                                                </div>

                                            </div>}
                                        </Search>
                                    </Card>
                                    <PlayerNotifications />
                                    <Card name='newPlayers'>
                                        <Tab name='searchPlayers' ref={(r) => this.playersTab = r}>
                                            <Search key='searchNewPlayers'
                                                model='newPlayersSearch'
                                                exportToExcel={false}
                                                resultModel='newPlayersSummary'
                                                data={this.props.newPlayersSummary}
                                                actions={getAction(ActionTypes.SearchPlayer).convertToItems()}
                                                onActionClicked={this.onActionClicked.bind(this)}
                                                onRenderAction={this.onRenderPlayerSearchActions.bind(this)}
                                                onSubmit={(data, qb) => { apiResult('newPlayersSummary', Endpoints.PlayerSearch, qb, 'searching players', 'unable to fetch results'); }}
                                            />
                                        </Tab>
                                    </Card>
                                    <Card caption='Search'>
                                        <Search key='activity'
                                            model='playerActivitySearch'
                                            exportToExcel={false}
                                            resultModel='playerActivitySearchResult'
                                            data={this.props.playerActivity}
                                            actions={{ items: [] }}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            onSubmit={(data, qb) => { this.props.searchActivity(qb); }}
                                        />
                                    </Card>
                                    <Card caption='Search'>
                                        <Search key='sameIp'
                                            model='playersUsingSameIp'
                                            export={false}
                                            resultModel='sameIpSearchResult'
                                            data={this.props.playersUsingSameIp}
                                            actions={{ items: [] }}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            onSubmit={(data, qb) => { apiResult('playersUsingSameIp', Endpoints.SearchSameIpPlayers, qb, 'searching players using same ip', 'unable to fetch results'); }}
                                        />
                                    </Card>
                                    <PlayerShortcuts />
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication >
        );
    }
}

PlayersPage.defaultProps = {
    action: null,
    error: null,
    playerGroups: []
};

PlayersPage.propTypes = {
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    failed: PropTypes.bool,
    error: PropTypes.object,
    players: PropTypes.object,
    playerVerifications: PropTypes.object,
    playerActivity: PropTypes.object,
    playerGroups: PropTypes.array,
    playersUsingSameIp: PropTypes.object
};


const mapStateToProps = state => {
    return {
        loading: state.player.loading,
        loaded: state.player.loaded,
        failed: state.player.failed,
        players: state.data.players,
        playerActivity: state.player.playerActivity,
        playerVerifications: state.data.playerVerifications,
        error: state.application.error,
        newPlayersSummary: state.data.newPlayersSummary,
        playersUsingSameIp: state.data.playersUsingSameIp
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        searchPlayer: searchPlayer,
        searchActivity: searchActivity
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(PlayersPage);
export { hoc as PlayersPage };