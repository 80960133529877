import React from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';
import { CheckBox } from '../../Inputs/CheckBox/CheckBox';
import { FormatField } from './DefaultFormatters';
import { Icon } from '../../Icon/Icon';
import { EditableRow } from './EditableRow';


const SortableHandler = SortableHandle((props) => props.handle);
SortableHandler.defaultProps = {
    handle: <div className='handle'><Icon icon='grip-vertical' /></div>
};

SortableHandler.propTypes = {
    handle: PropTypes.node
};


const Row = (props) => {
    const findTr = (element) => {
        if (!element.parentElement) return null;
        if (element.parentElement.tagName == 'TR') {
            return element.parentElement;
        }
        return findTr(element.parentElement);
    };

    const toggleCheck = (row) => {
        row.__selected = !row.__selected;
        if (props.onSelectionChange) props.onSelectionChange(row, row.__selected == true);
    };


    var result = true;
    const row = props.row;
    if (props.onRenderRow) result = props.onRenderRow(row);
    if (props.model.onRenderRow) result = props.model.onRenderRow(row, props.context);
    if (!result) return;
    const selected = row.__selected;
    const items = <tr
        className={result.className}
        onDoubleClick={props.onDoubleClick ? (e) => {
            var tr = findTr(e.target);
            if (!tr) return;
            var body = null;
            body = tr.parentElement;

            var selectedRow = body.getElementsByClassName('row-selected');
            if (selectedRow && selectedRow[0]) {
                selectedRow[0].classList.remove('row-selected');
            }

            tr.classList.toggle('row-selected');
            props.onDoubleClick(row);

            if (e.target.tagName !== 'TD') return;
        } : null}
        onClick={(e) => {
            var tr = findTr(e.target);
            if (!tr) return;
            var body = null;
            body = tr.parentElement;

            var selectedRow = body.getElementsByClassName('row-selected');
            if (selectedRow && selectedRow[0]) {
                selectedRow[0].classList.remove('row-selected');
            }

            tr.classList.toggle('row-selected');

            if (e.target.tagName !== 'TD') return;
            props.onRowClick(row);
        }}>
        {props.draggable && <td className='table-option-draggable-column'>
            <SortableHandler />
        </td>
        }
        {props.selectable && <td><CheckBox key={selected} onClick={() => toggleCheck(row)} checked={selected} /></td>}
        {
            props.model.fields.map(field => {
                var value = row[field.name];
                if (field.editable && !props.readonly) return <EditableRow key={field.name} row={row} {...props} field={field} />;
                if (!field.formatter || field.render) value = FormatField(field, value);
                if (field.formatter) return <td key={field.name} className={field.className}>{field.formatter(row, props.context, props.readonly)}</td>;
                if (field.render) return <td key={field.name} className={field.className}>{field.render(row, props.context, props.readonly)}</td>;
                return <td key={field.name} className={field.className}>{value}</td>;
            })
        }
        {props.buttons && <td>{props.buttons(row)}</td>}
    </tr>;


    return items;
};

Row.propTypes = {
    row: PropTypes.object,
    model: PropTypes.object,
    selectable: PropTypes.bool,
    buttons: PropTypes.object,
    context: PropTypes.object,
    readonly: PropTypes.bool,
    draggable: PropTypes.bool,
    onRenderRow: PropTypes.func,
    onChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onRowClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onToggleCheckAll: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func
};

export const TableRow = React.memo(Row);