const defaultStorage = {
    settings: {
        components: {
            fluxSearch: {
                models: {

                }
            }
        },
        favouriteActions:
        {

        }
    }
};




export const LocalStorage = {
    get: () => {
        if (!window.localStorage) return { ...defaultStorage };
        var copy = localStorage.getItem('flux-settings');
        if (!copy) return { ...defaultStorage };

        return JSON.parse(copy);
    },
    save: (settings) => {
        if (!window.localStorage) return;
        localStorage.setItem('flux-settings', JSON.stringify(settings));
    },
    getSearchModel: (modelName) => {
        var settings = LocalStorage.get().settings;
        if (settings.components.fluxSearch.models[modelName]) {
            return settings.components.fluxSearch.models[modelName];
        }

        return null;
    },
    saveSearchModel: (modelName, model) => {
        var settings = LocalStorage.get();
        settings.components.fluxSearch.models[modelName] = model;
        LocalStorage.save(settings);
    }
};