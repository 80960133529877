import React, { useEffect, useState } from 'react';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { API, RiskLevelEndpoints } from '../../../../v2/Lib/Api/Api';
import { application } from '../../../Application/Application';
import { SportRiskLevels } from './RiskLevelsConfiguration';

const model = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'name',
            title: 'Name'
        },
        {
            name: 'low',
            title: '',
            renderRow: (row, field, context) => {
                const selected = context.levels[row.id] == SportRiskLevels.Low;
                return <div className={`Low${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.id, SportRiskLevels.Low)}>Low</div>;
            }
        },
        {
            name: 'medium',
            title: '',
            renderRow: (row, field, context) => {
                const selected = context.levels[row.id] == SportRiskLevels.Medium;
                return <div className={`Medium${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.id, SportRiskLevels.Medium)}>Medium</div>;
            }
        },
        {
            name: 'high',
            title: '',
            renderRow: (row, field, context) => {
                const selected = context.levels[row.id] == SportRiskLevels.High;
                return <div className={`High${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.id, SportRiskLevels.High)}>High</div>;
            }
        }
    ]
};


export const RiskLevelSports = (props) => {
    const [levels, setLevels] = useState({});
    const [saving, setSaving] = useState(false);

    const context = {
        levels: levels,
        update: (id, level) => {
            var copy = structuredClone(levels);
            copy[id] = level;
            setLevels(copy);
        }
    };

    useEffect(() => {
        var config = {};
        API.post(RiskLevelEndpoints.Sports).then((sport) => {
            let sportConfig = sport.result ? JSON.parse(sport.result) : {};
            props.sports.map(x => {
                config[x.id] = sportConfig[x.id] ?? SportRiskLevels.Low;
            });

            setLevels(config);
        });
    }, []);

    const save = () => {
        setSaving(true);
        API.post(RiskLevelEndpoints.SaveSports, levels).then(() => {
            application().snackbar.open('Levels Saved.');
            setSaving(false);
        });
    };

    return <div className={`risk-level-limits${saving ? ' disabled' : ''}`}>
        <div className='content'>
            {props.sports.length && <Table model={model} data={props.sports} context={context} />}
        </div>
        <div className='panel padding'>
            <Button title='Save' onClick={() => save()} />
        </div>
    </div>;
};