import './_page.scss';

import { CentrumComponent } from '../CentrumComponent';
import React from 'react';

import PropTypes from 'prop-types';
import { ApplicationContext, ActionClose, ActionYesNo } from '../Application/CentrumApplication';
import { lang } from '../Localization/lang';
import { CentrumForm } from '../CentrumForm/CentrumForm';
import { postData } from '../../../state/services/ServiceHelpers';
import { Icon } from '../../Controls/Icon/Icon';



export class Page extends CentrumComponent {
    constructor(props) {
        super(props);
        this.overlay = null;
    }

    openModal(modalData) {
        this.applicationContext.openModal(modalData);
    }

    openBusyModal(title, content) {
        this.applicationContext.closeModal();
        this.applicationContext.openModal({
            title: title,
            data: null,
            actions: { items: [] },
            content: content,
            onActionClicked: () => { }
        });
    }

    openInformationModal(content) {
        this.applicationContext.closeModal();
        this.applicationContext.openModal({
            title: 'Information',
            data: null,
            actions: ActionClose,
            content: content,
            onActionClicked: () => { this.closeModal(); }
        });
    }

    openErrorModal(content) {
        this.applicationContext.closeModal();
        this.applicationContext.openModal({
            title: 'Error',
            data: null,
            actions: ActionClose,
            content: content,
            onActionClicked: () => { this.closeModal(); }
        });
    }

    closeModal() {
        this.applicationContext.closeModal();
    }

    renderPages() {
        let pages = this.props.pages;
        if (pages === undefined || pages === null || Object.keys(pages).length === 0) return;
        return Object.keys(pages).map(pageTitle => {
            return pages[pageTitle];
        });
    }


    loadData(data, endpoint, resultHandler) {
        postData(data, endpoint).then((res) => {
            resultHandler(res);
        }).catch((err) => {
            console.warn(err);
        });
    }

    confirmActionAndPost(data, model, endPoint, handler, className, title, successMessage,readonly) {
        if (className === undefined) className = 'col2';
        if (title === undefined) title = 'Confirm?';
        if (successMessage===undefined) successMessage = 'Saved';
        if (readonly == undefined) readonly = true;


        var onActionConfirmed = (action) => {
            this.closeModal();

            if (action.type === 'confirm') {
                this.openBusyModal(lang('Loading'), <p>{lang('Please wait')}</p>);

                postData(data, endPoint).then((res) => {
                    this.openInformationModal(<p>{lang(successMessage)}</p>);
                    if (handler != null && handler !== undefined) {
                        handler(res);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.openErrorModal(<p>{lang('Unable to update data, please try later')}</p>);
                });
            }
        };



        this.openModal({
            title: lang(title),
            data: data,
            actions: ActionYesNo,
            content: <CentrumForm className={className} data={data} model={model} readonly={readonly} buttons={[]} onFieldValueChanged={(field,value)=>data[field.name]=value}/>,
            onActionClicked: onActionConfirmed.bind(this)
        });
    }

    getClassNames() {
        let show = this.props.show;
        if (show === null) show = true;
        if (show === undefined) show = true;

        let classNames = '';
        classNames = ' ' + (this.props.className) + (show ? ' fade-in' : ' fade-out hidden');

        // return if there are no sub pages.
        if (this.props.pages === undefined || this.props.pages === null || Object.keys(this.props.pages).length === 0) classNames;

        classNames += ' centrum-sub-page-' + Object.keys(this.props.pages).length;
        return classNames;
    }

    hideOverlay() {
        let overlay = this.overlay;
        overlay.style.display = 'none';
    }

    showOverlay() {
        let overlay = this.overlay;
        overlay.style.display = 'block';
    }

    render() {
        return (
            <ApplicationContext.Consumer>
                {
                    (context) => {
                        this.applicationContext = context;
                        return (
                            <React.Fragment>
                                <div className={this.getClassNames()} key={this.componentIndex} onClick={() => this.componentClicked()}>
                                    {this.props.children}
                                    {this.renderPages()}
                                </div>
                                <div className='page-overlay' ref={(overlay) => this.overlay = overlay}>
                                    <div className='page-overlay-content'>
                                        {this.props.overlay}
                                    </div>
                                    <div className='page-overlay-close' onClick={this.hideOverlay.bind(this)}><Icon icon={'times'} onClick={this.hideOverlay.bind(this)} /></div>
                                </div>
                            </React.Fragment>
                        );
                    }
                }
            </ApplicationContext.Consumer>
        );
    }
}

Page.defaultProps = {
    pages: {},
    className: 'centrum-page'
};

Page.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool,
    children: PropTypes.node,
    pages: PropTypes.object,
    overlay: PropTypes.node,
    showOverlay: PropTypes.bool
};