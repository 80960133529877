import '../Players/_playerProfilePage.scss';

// IMPORT PACKAGE REFERENCES
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getProfile } from '../../../state/actions/PlayerActions';
import { lang } from '../../Centrum/Localization/lang';
import { LoadingIndicator } from '../../shared/LoadingIndicator/LoadingIndicator';
import { CentrumForm } from '../../Centrum/CentrumForm/CentrumForm';
import { Search } from '../../Centrum/Search/Search';
import { getAction, ActionTypes } from '../../Centrum/Common/ActionManager';
import { Table } from '../../Controls/Table/Table';
import { application, Endpoints } from '../../Application/Application';
import { Button } from '../../Controls/Buttons/Button';
import { Tab } from '../../Centrum/Tab/Tab';
import { Form } from '../../Controls/Form/Form';
import { FormInput } from '../../Controls/Form/FormInput';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { ChartRanges } from '../../Centrum/Charts/CentrumLineChart';
import { Money } from '../../Controls/Money/Money';
import { PlayerFlagsToClass, resolveFlags } from '../../Centrum/_models/ModelEnums';
import { LabelValue } from '../Dashboards/components/LabelValue/LabelValue';
import { DateTime } from '../../Controls/DateTime/DateTime';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { betSlipResults, horseBetSlipResults, openBetModal } from '../RiskManagement/models';
import { searchHorseBettingReport } from '../Sports/Models/horse';
import { Icon } from '../../Controls/Icon/Icon';
import { API, HistoryEndpoints, PlayerEndpoints } from '../../../v2/Lib/Api/Api';
import { ErrorModal } from '../../Modal/Modals/Error/ErrorModal';
import { Loading } from '../../Modal/Modals/Loading/Loading';
import { Card } from '../../../v2/Components/Card/Card';
import { PlayerBonusPage } from '../Players/PlayerBonusPage';
import { cancelTransaction } from '../Players/actions';

const tabButtons = [
    {
        name: 'summary',
        title: 'Summary'
    },
    {
        name: 'transactions',
        title: 'Transactions'
    },
    {
        name: 'gameHistory',
        title: 'Game History'
    },
    {
        name: 'sports',
        title: 'Betting History'
    },
    {
        name: 'racing',
        title: 'Race History'
    },
    {
        name: 'resolved-tickets',
        title: 'Resolved Tickets'
    },
    {
        name: 'bonus',
        title: 'Bonus'
    },
    {
        name: 'notifications',
        title: 'Notifications'
    },
    {
        name: 'activity',
        title: 'Activity'
    }
];
const searchHorseTickets = (playerId) => {
    var model = { ...searchHorseBettingReport };
    model.fields = model.fields.filter(x => x.name != 'PlayerId');
    model.fields = model.fields.filter(x => x.name != 'OperatorId');
    model.fields = model.fields.filter(x => x.name != 'Username');
    model.fields = model.fields.filter(x => x.name != 'TestAccounts');
    model.fields = model.fields.filter(x => x.name != 'Retails');
    model.fields = model.fields.filter(x => x.name != 'Group');
    model.fields = model.fields.filter(x => x.name != 'RetailCode');
    model.fields = model.fields.filter(x => x.name != 'Code');
    model.fields.push({
        name: 'PlayerId',
        value: playerId,
        readonly: true
    });
    model.fields.push({
        name: 'TestAccounts',
        value: 1
    });
    return model;
};


// COMPONENT
export const AffilatePlayerProfile = (props) => {
    const [state, setState] = useState({
        transactions: {},
        playerGameHistory: {},
        playerBetHistory: {},
        horseTickets: {},
        selectedBet: null,
        playerActivitiy: {}
    });

    const tabs = React.createRef();

    useEffect(() => {
        getProfile(props.id, props.currency).then(result => {
            var copy = { ...state };
            copy.loading = false;
            copy.profile = result.result;
            setState(copy);
        });
    }, []);

    const prepareTransactionsModel = (model) => {
        var operator = model.fields.find(x => x.name == 'Operator' || x.name == 'OperatorId');
        if (operator) {
            operator.readonly = true;
            operator.value = state.profile.player.operatorId;
        }

        var userId = model.fields.find(x => x.name == 'PlayerId' || x.name == 'UserId');
        userId.readonly = true;
        userId.value = state.profile.player.id;

        model.fields = model.fields.filter(x => x.name != 'Currency');
        model.fields = model.fields.filter(x => x.name != 'TestAccounts');
        model.fields.push({ name: 'TestAccounts', value: 3, hidden: true });

        return model;
    };



    const prepareModel = (model) => {
        var userId = model.fields.find(x => x.name == 'PlayerId' || x.name == 'UserId');
        userId.readonly = true;
        userId.value = state.profile.player.id;

        model.fields = model.fields.filter(x => x.name != 'Currency');
        return model;
    };

    const searchTokenActivity = () => {
        var token = state.profile.player.lastToken;
        console.log(token);
        tabs.current.selectByKey('activity');
    };


    const onBetSlipDataClicked = (row) => {
        var data = JSON.parse(row.bets);

        var copy = { ...state };
        copy.selectedBet = data;
        setState(copy);
    };

    const onTabSelected = (index, key) => {
        console.log(key);
    };

    const search = (target, endpoint, payload, text, errorTitle) => {
        application().modal.open(<Loading title={text} />);
        API.post(endpoint, payload).then((result) => {
            application().modal.close();
            var copy = { ...state };
            copy[target] = result;
            setState(copy);
        }).catch(() => {
            application().modal.open(<ErrorModal title={'Failed'}><p>{lang(errorTitle)}</p></ErrorModal>);
        });
    };

    const loadProfile = () => {
        getProfile(props.id, state.profile.player.currency).then(result => {
            var copy = { ...state };
            copy.loading = false;
            copy.profile = result.result;
            copy.updateTime = Date.now();
            setState(copy);
        });
    };

    const onTransactionsActionClicked = (action, items) => {
        let item = items[0];
        item.ConfirmedAmount = item.Amount = Math.abs(item.Amount);
        switch (action.type) {
            case ActionTypes.RejectTransaction:
                cancelTransaction({ id: item.Id }, () => {

                });
                break;
            case ActionTypes.TransactionDetails:
                if (item.ProviderId == 42000) {
                    openBetModal(item.ExternalTransactionId, false);
                }
                break;
        }
    };

    const onGameHistoryActionClicked = (action, items) => {
        let item = items[0];
        API.post(`${HistoryEndpoints.CasinoHandDetailsWithHistoryId}/${item.id}`).then((result) => {
            if (result.result.url) {
                window.open(result.result.url);
            }
        });
    };

    const editPlayer = () => {
        API.post(`${PlayerEndpoints.Get}/${props.id}`, {}).then((result) => {
            console.log(result);
            application().modal.open(<ConfirmationModal title='Edit Player' onConfirm={() => {

            }}>
                <CentrumForm model='editPlayer' data={result.result} />
            </ConfirmationModal>);
        });
    };

    if (state.loading) {
        return <LoadingIndicator busy={state.loading} />;
    }

    if (state.profile == null) {
        return <div />;
    }

    var data = Object.assign({}, state.profile);

    return (
        <React.Fragment>
            <div className='pageButtons'>
                <div className='buttons'>
                    <Icon icon={'sync'} onClick={() => loadProfile()} />
                    <Icon icon={'times'} onClick={() => props.onClose()} />
                </div>
            </div>
            <Tab buttons={tabButtons} ref={tabs} onTabSelected={onTabSelected.bind(this)}>
                <React.Fragment key='summary'>

                    {/* top , profile and chart*/}
                    <div className='flex vertical padding gap-10'>
                        <div className='flex gap-10 align-items-start'>
                            <div className='flex vertical gap-10'>
                                <Card title={data.player.username} buttons={() => <div className='hidden'><Button title='Edit' onClick={() => editPlayer()} /></div>}>
                                    <Form className='vertical singleLine noborder'
                                        data={data.player} model='playerSummary' key={state.updateTime}>
                                        <div className='row'>
                                            <FormInput type={InputTypes.dateTime} name='created' title='Registration' readOnly={true} />
                                        </div>
                                       
                                        <Card title='Player Flags'>
                                            <div className='legends big marginBottom'>
                                                {PlayerFlagsToClass(resolveFlags(state.profile.player.playerFlags), true)}
                                            </div>
                                        </Card>
                                        <Card title='Personal Information'>
                                            <div className='row'>
                                                <FormInput type={InputTypes.text} name='name' title='Name' readOnly={true} />
                                                <FormInput type={InputTypes.text} name='surname' title='Surname' readOnly={true} />
                                            </div>
                                            <div className='row'>
                                                <FormInput type={InputTypes.text} name='birthDay' title='Birthday' readOnly={true} />
                                                <FormInput type={InputTypes.text} name='gender' title='Gender' readOnly={true} />
                                            </div>
                                        </Card>
                                        <Card title='Contact Details'>
                                            <div className='row'>
                                                <FormInput type={InputTypes.text} name='country' title='Country' readOnly={true} />
                                                <FormInput type={InputTypes.text} name='city' title='City' readOnly={true} />
                                            </div>
                                        </Card>
                                    </Form>
                                </Card>
                                <Card title='Login'>
                                    <Form className='vertical singleLine noborder' data={data.player} model='playerSummary' readOnly={true} key={state.updateTime}>
                                        <div className='row'>
                                            <FormInput type={InputTypes.dateTime} name='lastLogin' title='Last Login' readOnly={true} />
                                            <FormInput type={InputTypes.text} name='lastLoginIp' title='Ip' readOnly={true} />
                                        </div>
                                        <div className='row'>
                                            <FormInput type={InputTypes.text} name='lastToken' title='Last Token' readOnly={true}><Icon icon={'search'} className='link' onClick={searchTokenActivity.bind(this)} /></FormInput>
                                        </div>
                                        <div className='row'>
                                            <FormInput type={InputTypes.textArea} name='lastLoginDevice' title='Device' readOnly={true} />
                                        </div>
                                    </Form>
                                </Card>
                            </div>
                            <div className='flex vertical fit-width gap-10'>
                                <div className='flex '>
                                    <Card title={lang('Casino')}>
                                        <div className='flex vertical stretch'>
                                            <div className='group padding'>
                                                <LabelValue title='Overall Bet'><Money value={Math.abs(data.casino.bet)} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Overall Win'><Money value={Math.abs(data.casino.win)} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Profit'><Money value={(Math.abs(data.casino.bet) - Math.abs(data.casino.cancel) - Math.abs(data.casino.win))} currency={data.player.currency} /></LabelValue>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className='flex col2'>
                                    {
                                        Object.keys(data.sports).map(x => {
                                            var caption = '';
                                            switch (parseFloat(x)) {
                                                case 9999:
                                                    caption = 'Horse';
                                                    break;
                                                case 42000:
                                                    caption = 'Sports';
                                                    break;
                                                case 43000:
                                                    caption = 'Booked Bet';
                                                    break;
                                            }
                                            const sport = data.sports[x];
                                            return <Card title={caption} key={x}>
                                                <div className='group padding'>
                                                    <LabelValue title='Tickets'>{sport.totalTickets}</LabelValue>
                                                    <LabelValue title='Open Tickets'>{sport.openTickets}</LabelValue>
                                                    <LabelValue title='Bet'><Money value={Math.abs(sport.bet)} currency={data.player.currency} /></LabelValue>
                                                    <LabelValue title='Win'><Money value={Math.abs(sport.win)} currency={data.player.currency} /></LabelValue>
                                                    <LabelValue title='Profit Cashed out'><Money value={Math.abs(sport.cashoutProfit)} currency={data.player.currency} /></LabelValue>
                                                </div>
                                            </Card>;
                                        })
                                    }
                                </div>
                                {/* middle information*/}
                                <div className='flex vertical gap-10 align-items-stretch'>
                                    <div className='flex gap-10 align-stretch'>
                                        <Card title={lang('Transactions / All (Main Wallet)')} className='align-stretch'>
                                            <div className='align vertical padding'>
                                                <LabelValue title='Deposit'><Money value={data.transactionSummary.overalTotalDeposit} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Deposit Count'><span>{data.transactionSummary.overalDepositCount}</span></LabelValue>
                                            </div>
                                            <div className='align vertical padding border-top'>
                                                <LabelValue title='Withdraw'><Money value={data.transactionSummary.overalTotalWithdraw} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Withdraw Count'><span>{data.transactionSummary.overalWithdrawCount}</span></LabelValue>
                                            </div>
                                        </Card>
                                        <Card title={lang('Transactions / Payments')} className='align-stretch'>
                                            <div className='align vertical padding'>
                                                <LabelValue title='Deposit'><Money value={data.transactionSummary.totalDeposit} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Deposit Count'><span>{data.transactionSummary.depositCount}</span></LabelValue>
                                            </div>
                                            <div className='align vertical padding border-top'>
                                                <LabelValue title='Withdraw'><Money value={data.transactionSummary.totalWithdraw} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Withdraw Count'><span>{data.transactionSummary.withdrawCount}</span></LabelValue>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className='flex gap-10 align-items-stretch'>
                                        <Card title={lang('Pending Deposit')} className='align-stretch'>
                                            <Money className='marginLeft margin-top' value={data.transactionSummary.pendingDepositAmount} currency={data.player.currency} size='big' />
                                            <div className='summary margin-top padding'>
                                                <LabelValue title='Last Deposit'><Money value={Math.abs(data.transactionSummary.lastDeposit)} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Date'><DateTime date={data.transactionSummary.lastDepositDate} /></LabelValue>
                                            </div>
                                        </Card>
                                        <Card title={lang('Locked Withdraw')} className='align-stretch'>
                                            <Money className='marginLeft margin-top' value={data.transactionSummary.lockedWithdrawalAmount} currency={data.player.currency} size='big' />
                                            <div className='summary margin-top padding'>
                                                <LabelValue title='Last Withdraw'><Money value={Math.abs(data.transactionSummary.lastWithdraw)} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Date'><DateTime date={data.transactionSummary.lastWithdrawDate} /></LabelValue>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className='flex gap-10 align-items-stretch'>
                                        <Card title={lang('Bonus')} className='align-stretch'>
                                            <div className='summary margin-top padding'>
                                                <LabelValue title='Total Bonus'><Money value={Math.abs(data.transactionSummary.totalBonusDeposit)} currency={data.player.currency} /></LabelValue>
                                                <LabelValue title='Total Bonus Count'><span>{data.transactionSummary.totalBonusCount}</span></LabelValue>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                <React.Fragment key='transactions'>
                    <Search
                        model='searchPlayerLastTransactions'
                        onModelSet={prepareModel.bind(this)}
                        actions={getAction(ActionTypes.SeachPlayerTransactions).convertToItems()}
                        onActionClicked={onTransactionsActionClicked.bind(this)}
                        resultModel='playerLastTransactions'
                        onSubmit={(data, qb) => { return search('transactions', PlayerEndpoints.SearchTransactions, qb, 'loading player transactions please wait', 'Unable to fetch transactions'); }}
                        data={state.transactions}
                    />
                </React.Fragment>
                <React.Fragment key='gameHistory'>
                    <Search
                        model='searchPlayerGameHistory'
                        actions={getAction(ActionTypes.SearchPlayerGameHistory).convertToItems()}
                        onModelSet={prepareTransactionsModel.bind(this)}
                        onActionClicked={onGameHistoryActionClicked.bind(this)}
                        resultModel='resultPlayerGameHistory'
                        onSubmit={(data, qb) => { return search('playerGameHistory', Endpoints.SearchPlayerGameHistory, qb, 'loading player game history please wait', 'Unable to fetch player game history'); }}
                        data={state.playerGameHistory}
                    />
                </React.Fragment>
                <React.Fragment key='sports'>
                    <Search
                        model='searchPlayerBetHistory'
                        onModelSet={prepareTransactionsModel.bind(this)}
                        resultModel={betSlipResults}
                        onSubmit={(data, qb) => { return search('playerBetHistory', Endpoints.SearchSportTickets, qb, 'loading player bet history please wait', 'Unable to fetch player bet history'); }}
                        onRowClicked={(row) => onBetSlipDataClicked(row)}
                        data={state.playerBetHistory}
                    />
                    {
                        state.selectedBet &&
                        <Card title='Bet Details' className='margin'>
                            <Table
                                data={state.selectedBet} model='playerBetDetails' />
                        </Card>
                    }
                </React.Fragment>
                <React.Fragment key='racing'>
                    <Search
                        model={searchHorseTickets(state.profile.player.id)}
                        resultModel={horseBetSlipResults}
                        data={state.searchHorseTickets}
                        onSubmit={(data, qb) => {
                            return search('searchHorseTickets', Endpoints.SearchHorseGreyhoundTickets, qb, 'fetching sport tickets', 'unable to fetch ticekts');
                        }}
                    />
                    {
                        state.selectedBet &&
                        <Card title='Bet Details' className='margin'>
                            <Table
                                data={state.selectedBet} model='playerBetDetails' />
                        </Card>
                    }
                </React.Fragment>
                <React.Fragment key='resolved-tickets'>
                    <Search
                        model='searchResolvedTickets'
                        onModelSet={prepareTransactionsModel.bind(this)}
                        resultModel={betSlipResults}
                        onSubmit={(data, qb) => {
                            return search('playerBetHistory', Endpoints.SearchSportTickets, qb, 'loading player bet history please wait', 'Unable to fetch player bet history');
                        }}
                        onRowClicked={(row) => onBetSlipDataClicked(row)}
                        data={state.playerBetHistory}
                    />
                    {
                        state.selectedBet &&
                        <Card title='Bet Details' className='margin'>
                            <Table
                                data={state.selectedBet} model='playerBetDetails' />
                        </Card>
                    }
                </React.Fragment>
                <React.Fragment key='bonus'>
                    <PlayerBonusPage profile={state.profile.player} onCreateBonusTransaction={props.onCreateBonusTransaction.bind(this)} />
                </React.Fragment>
                <React.Fragment key='notifications'>
                    <Search
                        model='playerNotifications'
                        onModelSet={prepareModel.bind(this)}
                        resultModel='playerNotification'
                        onSubmit={(data, qb) => { search('playerNotifications', Endpoints.SearchPlayerNotifications, qb, 'loading player notifications please wait', 'Unable to fetch transactions'); }}
                        data={state.playerNotifications}
                    />
                </React.Fragment>

                <React.Fragment key='activity'>
                    <Search
                        model='playerActivitySearch'
                        onModelSet={prepareModel.bind(this)}
                        resultModel='playerActivitySearchResult'
                        onSubmit={(data, qb) => { search('playerActivitiy', Endpoints.SearchPlayerActivity, qb, 'loading player transactions please wait', 'Unable to fetch transactions'); }}
                        data={state.playerActivitiy}
                    />
                </React.Fragment>
            </Tab>
        </React.Fragment >
    );
};

AffilatePlayerProfile.defaultProps = {
    id: 0,
    currency: 'default',
    transactions: null,
    profile: null,
    loading: true,
    onCreateManualTransaction: () => { },
    onCreateBonusTransaction: () => { },
    playerRevenueRange: ChartRanges.Past7Days
};

AffilatePlayerProfile.propTypes = {
    application: PropTypes.object,
    id: PropTypes.number,

    currency: PropTypes.string,

    loading: PropTypes.bool,

    profile: PropTypes.object,
    transactions: PropTypes.object,
    playerGameHistory: PropTypes.object,
    playerBetHistory: PropTypes.object,
    playerActivitiy: PropTypes.object,
    playerNotifications: PropTypes.object,
    searchHorseTickets: PropTypes.object,
    addressDocuments: PropTypes.object,

    // functions
    getProfile: PropTypes.func,
    onCreateManualTransaction: PropTypes.func,
    onCreateBonusTransaction: PropTypes.func,
    onClose: PropTypes.func,
    playerRevenueRange: PropTypes.number
};
