import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../../../Centrum/Localization/lang';
import { Icon } from '../../../../Controls/Icon/Icon';
import { application, Endpoints } from '../../../../Application/Application';


export const LeagueCard = (props) => {
    const inputRef = React.createRef();
    const eventRef = React.createRef();
    const league = props.bulletin.data.leagues!=null ? props.bulletin.data.leagues[props.league.id] : props.league;

    const [showEvents, toggleShowEvents] = useState(false);
    const [events, setEvents] = useState(null);

    const [updateKey, setUpdateKey] = useState(0);

    useEffect(() => {
        if (!showEvents) return;
        const dateStart = props.bulletin.dateStart;
        const dateEnd = props.bulletin.dateEnd;

        const payload = {
            sportId: props.league.sport.id,
            leagueId: props.league.id,
            startDate: dateStart,
            endDate: dateEnd
        };
        application().resolve(null, Endpoints.SportEvents, payload, 'fetching events', 'unable to fetch events').then((result) => {
            setEvents(result.result);
        });
    }, [showEvents]);

    const updateEventCode = (event, code) => {
        var copy = { ...props.bulletin };
        var startingCode = props.league.code;
        if (!startingCode) startingCode = code;

        copy.data.leagues[props.league.id] = {
            name: props.league.name,
            id: props.league.id,
            code: startingCode,
            sport: {
                id: props.league.sport.id,
                name: props.league.sport.name
            },
            region: {
                id: props.league.region,
                name: props.league.regionName
            }
        };

        props.league.code = startingCode;
        event.code = code;
        copy.data.events[event.id] = event;
        props.onUpdate(copy);
    };

    const deleteEvent = (event) => {
        eventRef.current.value = '';

        var copy = { ...props.bulletin };
        delete copy.data.events[event.id];
        props.onUpdate(copy);
        setUpdateKey(Date.now());
    };

    const renderEvents = () => {
        if (!events) return;
        return <div className='events' key={updateKey}>
            {events.map(x => {
                const event = props.bulletin.data.events[x.id];
                const code = event != null ? event.code : '';
                return <div className='event padding' key={x.id + '_' + (event != null ? event.code : '')}>
                    <div className='flex gap-20'>
                        <span>{x.id}</span>
                        <span>{new Date(x.date).toLocaleString('en-Gb', { hour12: false })}</span>
                        <span>{x.name}</span>
                        <div className='alignRight flex gap-20'>
                            <input type='number'
                                ref={eventRef}
                                defaultValue={code}
                                onKeyUp={(e) => {
                                    if (e.key == 'Enter') {
                                        updateEventCode(x, parseFloat(e.target.value));
                                    }
                                }} />
                            <Icon icon='times' onClick={() => deleteEvent(x)} />
                        </div>
                    </div>
                </div>;
            })}
        </div>;
    };

    return <div className='league flex vertical fit-width gap-10 padding'>
        <div className='flex gap-10'>
            {props.league.fav && <Icon icon='star' />}
            <Icon icon={props.selected ? 'check' : 'square'} type={props.selected ? 'fas' : 'far'} onClick={() => props.toggleSelection(props.league)} />
            <div className='flex gap-5 clickable' onClick={() => props.toggleSelection(props.league)}>
                <span className='bold'>{props.league.region.name}</span>
                -
                <span>{props.league.name}</span>
            </div>
            <div className='alignRight flex gap-20'>
                <span>{lang('%1 events', [props.league.totalEvents])}</span>
                <input type='number'
                    ref={inputRef}
                    defaultValue={league != null ? league.code : ''}
                    onKeyUp={(e) => {
                        if (e.key == 'Enter') {
                            props.onChangeLeagueCode(props.league, parseInt(e.target.value));
                        }
                    }} />
                <Icon icon={showEvents ? 'angle-up' : 'angle-down'} onClick={() => toggleShowEvents(!showEvents)} />
                <Icon icon='times' onClick={() => props.onDelete(props.league, inputRef)} />
            </div>
        </div>
        {showEvents && renderEvents()}
    </div>;
};

LeagueCard.propTypes = {
    selected: PropTypes.bool,
    league: PropTypes.object,
    bulletin: PropTypes.object,
    onChangeLeagueCode: PropTypes.func,
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    toggleSelection: PropTypes.func
};
