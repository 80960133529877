// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from '../../../Controls/Table/Table';
import { Card } from '../../../Centrum/Card/Card';
import { Button } from '../../../Controls/Buttons/Button';
import { application, Endpoints } from '../../../Application/Application';
import { Icon } from '../../../Controls/Icon/Icon';



// COMPONENT
class ConfigureOperatorCasino extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
        this.configuration = props.configuration.sort((a, b) => {
            return a.aggregatorId - b.aggregatorId;
        });
    }

    save() {
        application().resolve(null, Endpoints.SaveOperatorCasino + '/' + this.props.operatorId, this.configuration, 'updating casino please wait', 'Unable to update casino').then(() => {
            application().snackbar.open('Operator Casino Configuration saved.');
        });
    }


    onSelectionChange(providerName, row, index, value) {
        if (!row) {
            this.configuration.filter(x => x.name == providerName).map(x => {
                x.disabledByPlatform = !value;
            });
            return;
        }

        row.disabledByPlatform = !value;
        row.__selected = value;
    }

    render() {
        var providers = {};
        this.props.configuration.map(configuration => {
            if (!(configuration.name in providers)) {
                providers[configuration.name] = [];
            }

            providers[configuration.name].push(configuration);
        });


        return <React.Fragment>
            <div className='padding configure-operator-casino'>
                {Object.keys(providers).map(providerName => {
                    var providerConfiguration = providers[providerName];
                    var aggregator = false;
                    var aggregatorName = '';

                    providerConfiguration.map((config) => {
                        aggregator = config.aggregator;
                        aggregatorName = config.aggregatorName;
                        config.__selected = !config.disabledByPlatform && !config.disabled;
                    });

                    return <Card key={providerName} caption={providerName} menu={aggregator && <React.Fragment><span className='marginRight'>{aggregatorName}</span><Icon icon={'handshake'} /></React.Fragment>}>
                        <Table
                            model={'casinoConfiguration'} data={providerConfiguration}
                            selectable={true} editable={true}
                            editableFields='commission'
                            onSelectionChange={(row, index, value) => this.onSelectionChange(providerName, row, index, value)} />
                    </Card>;
                })}
            </div>
            <Button className='margin' onClick={() => this.save()} title='Save'></Button>
        </React.Fragment>;
    }
}

ConfigureOperatorCasino.defaultProps = {
    configuration: [],
    selected: []
};

ConfigureOperatorCasino.propTypes = {
    operatorId: PropTypes.number.isRequired,
    configuration: PropTypes.array,
    selected: PropTypes.array
};

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({

    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ConfigureOperatorCasino);
export { hoc as ConfigureOperatorCasino };