import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../../../../v2/Components/Card/Card';
import { Table } from '../../../../../v2/Components/Controls/Table/Table';



const betsSummaryModel = {
    fields: [
        {
            name: 'Title',
            title: ''
        },
        {
            name: 'Total',
            title: 'Total'
        }
    ]
};



export const BetsSummary = () => {
    const summary = useSelector((state) => state.volumes).betSummary;

    return <Card title='Slips' className='no-padding border'>
        <Table model={betsSummaryModel} data={summary} key={summary.key} options={{ header: { show: false } }} />
    </Card>;
};

