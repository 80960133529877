import './_welcomeBonus.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { BonusGeneralSettings } from '../Components/BonusComponents';
import { Tab } from '../../../Centrum/Tab/Tab';
import { Card, Panel } from '../../../Centrum/Card/Card';
import { application, Endpoints } from '../../../Application/Application';
import { WelcomeBonusSportRules } from './WelcomeBonusRules';
import { CurrencyMinMaxConfiguration } from '../Components/CurrencyMinMaxConfiguration';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Form } from '../../../Controls/Form/Form';


export class WelcomeBonus extends React.Component {
    constructor(props) {
        super(props);
        var state = Object.assign({}, props);
        this.state = state;
        this.state.promotion.Configuration = (typeof this.state.promotion.Configuration === 'object' && this.state.promotion.Configuration !== null) ? this.state.promotion.Configuration : JSON.parse(this.state.promotion.Configuration);
        if (!this.state.promotion.Configuration) {
            this.state.promotion.Configuration = {
                deposit: {
                    depositOnRegister: false
                },
                stake: {},
                turnover: {
                    deductDepositAmount: false,
                    multiplier: 0
                },
                payout: {
                    automaticPayout: false,
                    amount: {}
                }
            };
        }
    }


    componentDidMount() {
        application().resolve(null, Endpoints.GetPromotionPlayerGroups + '/' + (this.state.promotion.Id), {}, 'fetching promotion player groups', 'unable to fetch promotion player groups').then((groups) => {
            var converted = [];
            converted.push({ value: null, display: 'All' });
            groups.result.map(x => {
                converted.push({ display: x.name, value: x.id });
            });

            this.setState({ loaded: true, playerGroups: converted });
        });

    }


    save() {
        var promotion = this.generalSettings.save();
        var welcomeBonusSportRules = this.welcomeBonusSportRules.save();
        var result = Object.assign(promotion, {});
        result.Configuration.deposit = {
            depositOnRegister: this.state.promotion.Configuration.deposit.depositOnRegister,
            amount: this.depositTable.save()
        };

        result.Configuration.stake = this.stakeConfigurationTable.save();
        result.Configuration.sports = welcomeBonusSportRules;

        result.Configuration.payout = {
            automaticPayout: this.state.promotion.Configuration.payout.automaticPayout,
            amount: this.payoutConfigurationTable.save()
        };

        var registerBonus = result.Configuration.deposit.depositOnRegister;
        result.DeductBonusAmount = result.Configuration.turnover.deductDepositAmount;
        result.Configuration = JSON.stringify(result.Configuration);
        result.RegisterBonus = registerBonus;
        return result;
    }

    render() {
        if (!this.state.loaded) return <div />;
        var tabButtons = [
            {
                title: 'General'
            },
            {
                title: 'Bonus Credit'
            },
            {
                title: 'Stake'
            },
            {
                title: 'Sports'
            },
            {
                title: 'Turnover Configuration'
            },
            {
                title: 'Payout'
            }
        ];

        var depositTurnoverMultiplier = {};
        for (var i = 1; i <= 100; i++) {
            depositTurnoverMultiplier[i] = 'x' + i;
        }



        return <React.Fragment>
            <Tab buttons={tabButtons}>
                <Card key='general'>
                    <BonusGeneralSettings promotion={this.state.promotion} ref={(r) => this.generalSettings = r} />
                </Card>
                <Card className='margin-top'>
                    <Form data={this.state.promotion.Configuration.deposit}>
                        <div className='row'>
                            <FormInput name='depositOnRegister' title='Deposit on register' type='bool' onChange={(f, t, value) => {
                                var promotion = Object.assign({}, this.state.promotion.Configuration);
                                promotion.deposit.depositOnRegister = value;
                                this.setState({ Configuration: promotion });
                            }} />
                        </div>
                    </Form>
                    <Panel className='margin' title='Deposit amount'>
                        <CurrencyMinMaxConfiguration data={this.state.promotion.Configuration.deposit.amount}
                            model={[
                                { name: 'Amount', display: 'Amount', type: 'number' }
                            ]}
                            fieldTitles={{ Amount: 'Amount' }} ref={(r) => this.depositTable = r} />
                    </Panel>
                </Card>
                <Card className='margin-top'>
                    <Panel className='margin' title='Amount'>
                        <CurrencyMinMaxConfiguration
                            data={this.state.promotion.Configuration.stake}
                            model={[
                                { name: 'Min', display: 'Min', type: 'number' },
                                { name: 'Max', display: 'Max', type: 'number' }
                            ]}
                            ref={(r) => this.stakeConfigurationTable = r} />
                    </Panel>
                </Card>
                <WelcomeBonusSportRules context={(context) => this.welcomeBonusSportRules = context} promotion={this.state.promotion} />
                <Panel title='Turnover' className='margin'>
                    <Form data={this.state.promotion.Configuration.turnover}>
                        <div className='row'>
                            <FormInput title='Turnover Multiplier' name='multiplier' type={'select'} values={depositTurnoverMultiplier} onChange={(f, r, value) => {
                                var promotion = Object.assign({}, this.state.promotion.Configuration);
                                promotion.turnover.multiplier = value;
                                this.setState({ Configuration: promotion });

                            }} />
                            <FormInput title='Deduct Deposit Amount' name='deductDepositAmount' type={'bool'}
                                onChange={(f, r, value) => {
                                    var promotion = Object.assign({}, this.state.promotion.Configuration);
                                    promotion.turnover.deductDepositAmount = value;
                                    promotion.deductBonusAmount = value;
                                    this.setState({ Configuration: promotion });
                                }} />
                        </div>
                    </Form>
                </Panel>
                <Card>
                    <Form data={this.state.promotion.Configuration.payout}>
                        <div className='row'>
                            <FormInput type='bool' name='automaticPayout' title='Automatic payout' />
                        </div>
                    </Form>
                    <Panel className='margin' title='Payout amount'>
                        <CurrencyMinMaxConfiguration
                            model={[
                                { name: 'Max', display: 'Max', type: 'number' }
                            ]}
                            data={this.state.promotion.Configuration.payout.amount}
                            fieldTitles={{ Max: 'Max' }} ref={(r) => this.payoutConfigurationTable = r} />
                    </Panel>
                </Card>

            </Tab >
        </React.Fragment >;
    }
}

WelcomeBonus.propTypes = {
    promotion: PropTypes.object
};

