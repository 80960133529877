/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { emptyMargin, model } from './SportsbookMargins';
import { API, SportEndpoints } from '../../../../v2/Lib/Api/Api';
import { lang } from '../../../Centrum/Localization/lang';

const eventModel = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'name',
            title: 'Name',
            renderRow: (row) => {
                return <div className='flex vertical gap-5'>
                    <span>{row.name}</span>
                    <div className='flex gap-5'><span>{row.sport.name}</span>/<span>{row.region.name}</span>/<span>{row.league.name}</span></div>
                </div>;
            }
        },
        {
            name: 'markets',
            title: 'Markets',
            renderRow: (row) => {
                return Object.keys(row.markets).length;
            }
        }
    ]
};

const EventMarkets = (props) => {
    const [markets, setMarkets] = useState([]);

    const masterData = props.masterData;
    const [data, setData] = useState([]);

    const updatedRows = props.updatedRows;
    const setUpdatedRows = props.setUpdatedRows;


    const marketRef = React.createRef();

    useEffect(() => {
        API.post(`${SportEndpoints.Markets}/${props.event.sport.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.id > b.id);
            result.result = result.result.filter((x) => x.name !== '');

            setMarkets(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, []);

    useEffect(() => {
        if (!markets) return;
        var marketsData = props.margins.markets[props.event.id].markets;
        setData(Object.values(marketsData));
    }, [markets]);


    const context = {
        renderId: (row) => {
            return row.id;
        },
        renderName: (row) => {
            return row.name;
        },
        isChanged: (row) => {
            var key = `${props.event.id}_${row.id}`;
            if (updatedRows.markets[props.event.id]?.markets[key]) {
                return true;
            }
            return false;
        },
        addToChanges: (copy, key, payload, type = 'update') => {
            var copyOfUpdatedRows = { ...updatedRows };
            if (!masterData.markets[props.event.id]) {
                masterData.markets[props.event.id] = {

                };

                var e = {
                    sport: {
                        id: props.event.sportId,
                        name: props.event.sport
                    },
                    region: {
                        id: props.event.regionId,
                        name: props.event.region
                    },
                    league: {
                        id: props.event.leagueId,
                        name: event.league
                    },
                    markets: {},
                    id: props.event.id,
                    name: props.event.name,
                    date: props.event.date
                };
                masterData.markets[props.event.id] = e;
            }

            if (JSON.stringify(masterData.markets[props.event.id].markets[key]) == JSON.stringify(copy.markets[props.event.id].markets[key])) {
                if (copyOfUpdatedRows.markets[props.event.id]) {
                    delete copyOfUpdatedRows.markets[props.event.id].markets[key];
                    if (Object.keys(copyOfUpdatedRows.markets[props.event.id].markets).length == 0) {
                        delete copyOfUpdatedRows.markets[props.event.id];
                    }
                }
            } else {
                if (!copyOfUpdatedRows.markets[props.event.id]) {
                    copyOfUpdatedRows.markets[props.event.id] = { markets: {} };
                }

                copyOfUpdatedRows.markets[props.event.id].markets[key] = { ...payload };
                copyOfUpdatedRows.markets[props.event.id].markets[key]._type = type;
            }
            setUpdatedRows(copyOfUpdatedRows);
        },
        update: (row, value, field) => {
            var key = `${props.event.id}_${row.id}`;
            const target = field.name;
            var copy = { ...props.margins };
            if (!copy.markets[props.event.id].markets[key]) {
                copy.markets[props.event.id].markets[key] = emptyMargin();
            }

            copy.markets[props.event.id].markets[key][target] = (value == true || value == false) ? value : parseFloat(value);
            props.setMargins(copy);

            context.addToChanges(copy, key, copy.markets[props.event.id].markets[key]);
        }
    };

    const remove = (row) => {
        var copy = { ...props.margins };
        var key = `${props.event.id}_${row.id}`;
        delete copy.markets[props.event.id].markets[key];
        props.setMargins(copy);
        setMarkets([...markets]);
        context.addToChanges(copy, key, update(row, true), 'delete');


        var copyOfUpdatedRows = { ...updatedRows };
        if (!copyOfUpdatedRows.markets[props.event.id]) {
            console.log('sss');
            var event = props.event;
            var e = {
                sport: {
                    id: event.sportId,
                    name: event.sport
                },
                region: {
                    id: event.regionId,
                    name: event.region
                },
                league: {
                    id: event.leagueId,
                    name: event.league
                },
                markets: {},
                id: event.id,
                name: event.name,
                date: event.date
            };

            copyOfUpdatedRows.markets[props.event] = e;

            setUpdatedRows(copyOfUpdatedRows);

        }
    };

    const update = (row, empty) => {
        var payload = {
            markets: {

            }
        };

        var key = `${props.event.id}_${row.id}`;

        payload.markets[props.event.id] = { markets: {} };
        payload.markets[props.event.id].markets[key] = empty ? emptyMargin() : row;

        return payload.markets[props.event.id].markets[key];
    };


    const addMarket = () => {
        var marketId = parseInt(marketRef.current.value);
        var market = markets.find(x => x.id == marketId);
        var key = `${props.event.id}_${marketId}`;
        var copy = { ...props.margins };
        if (!copy.markets[props.event.id].markets[key]) {
            copy.markets[props.event.id].markets[key] = emptyMargin();
            copy.markets[props.event.id].markets[key].id = market.id;
            copy.markets[props.event.id].markets[key].name = market.name;
            copy.markets[props.event.id].markets[key].markets = {};
        }
        props.setMargins(copy);
        setData(Object.values(copy.markets[props.event.id].markets));
    };

    return <div className='flex vertical gap-10 margin-top'>
        <div className='panel'>
            <div className='body padding'>
                <div className='flex gap-5'>
                    <span>{props.event.name}</span>
                    <span>{new Date(props.event.date).toLocaleString('en-Gb', { hours12: false })}</span>
                    <select ref={marketRef}>
                        {markets.map(x => {
                            return <option key={x.id} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                    <Button title='Add' onClick={() => addMarket()} />
                    <Button title='Close' className='align-right' onClick={() => props.close()} />
                </div>
            </div>
        </div>
        <Table model={model}
            data={data}
            context={context}
            onRenderRow={(row, elements, className, context) => {
                var c = context.isChanged(row) ? 'changed' : className;
                return <tr className={c}>
                    {elements}
                </tr>;
            }}
            buttons={(row) => {
                return <div className='flex gap-10'>
                    <Button title='Remove' onClick={() => remove(row)} />
                </div>;
            }}
        />
    </div>;
};

EventMarkets.propTypes = {
    event: PropTypes.object,
    margins: PropTypes.object,
    masterData: PropTypes.object,
    setMargins: PropTypes.func,
    close: PropTypes.func,
    updatedRows: PropTypes.object,
    setUpdatedRows: PropTypes.func
};

export const EventMarketMargins = (props) => {
    const [loading, setLoading] = useState(false);
    const [sport, selectSport] = useState(null);
    const [regions, setRegions] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [events, setEvents] = useState([]);

    const [data, setData] = useState([]);
    const [updatedRows, setUpdatedRows] = useState({ markets: {} });

    const [selectedRegion, selectRegion] = useState(null);
    const [selectedLeague, selectLeague] = useState(null);

    const [selectedEvent, selectEvent] = useState(null);

    const [showAll, setShowAll] = useState(null);

    const regionRef = React.createRef();
    const leagueRef = React.createRef();
    const eventRef = React.createRef();

    useEffect(() => {
        selectSport(props.sports);
        selectSport(props.sports[0]);

        setShowAll(true);
        setData(Object.values(props.margins.markets));
    }, []);

    useEffect(() => {
        if (showAll == null) return;
    }, [showAll]);

    useEffect(() => {
        if (!sport) return;
        setLoading(true);
        API.post(`${SportEndpoints.Regions}/${sport.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.name > b.name ? 1 : -1);
            result.result = result.result.filter((x) => x.name !== '');
            setRegions(result.result);
            selectRegion(result.result[0]);
        }).catch((e) => {
            console.log(e);
        });

    }, [sport]);


    useEffect(() => {
        if (!selectedRegion) return;
        setLoading(true);
        API.post(`${SportEndpoints.Leagues}/${sport.id}/${selectedRegion.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.name > b.name ? 1 : -1);
            result.result = result.result.filter((x) => x.name !== '');
            setLeagues(result.result);
            selectLeague(result.result[0]);
        }).catch((e) => {
            setLoading(false);
            console.log(e);
        });
    }, [selectedRegion]);

    useEffect(() => {
        if (!selectedLeague) return;
        setLoading(true);
        API.post(SportEndpoints.Events, {
            sportId: sport.id,
            regionId: selectedRegion.id,
            leagueId: selectedLeague.id
        }).then((result) => {
            result.result = result.result.sort((a, b) => a.name > b.name ? 1 : -1);
            result.result = result.result.filter((x) => x.name !== '');
            setLoading(false);
            setEvents(result.result);
        }).catch((e) => {
            setLoading(false);
            console.log(e);
        });
    }, [selectedLeague]);

    const context = {
        renderId: (row) => {
            return row.id;
        },
        renderName: (row) => {
            if (showAll) return <div className='flex vertical'>
                <span>{row.name}</span>
                <span>{row.sport.name} / {row.region.name} {row.league.name}</span>
            </div>;
            return row.name;
        },
        update: (row, value, field) => {
            var key = `${selectedEvent.id}_${row.id}`;
            const target = field.name;
            var copy = { ...props.margins };
            if (!copy.markets[selectedEvent.id].markets[key]) {
                copy.markets[selectedEvent.id].markets[key] = emptyMargin();
                copy.markets[selectedEvent.id].markets[key].id = row.id;
            }
            copy.markets[selectedEvent.id].markets[key][target] = (value == true || value == false) ? value : parseFloat(value);
            props.setMargins(copy);
        }
    };

    const add = () => {
        var event = events.find(x => x.id == parseFloat(eventRef.current.value));
        var copy = { ...props.margins };
        var key = event.id;
        if (!copy.markets[key]) {
            var e = {
                sport: {
                    id: event.sportId,
                    name: event.sport
                },
                region: {
                    id: event.regionId,
                    name: event.region
                },
                league: {
                    id: event.leagueId,
                    name: event.league
                },
                markets: {},
                id: event.id,
                name: event.name,
                date: event.date
            };
            copy.markets[key] = e;
            selectEvent(e);
        } else {
            selectEvent(copy.markets[key]);
        }
        setData(Object.values(copy.markets));
        props.setMargins(copy);
    };

    const remove = (row) => {
        var copy = { ...props.margins };
        var key = row.id;

        var updatedCopy = { ...updatedRows };
        updatedCopy.markets[key] = copy.markets[key];


        delete copy.markets[key];
        props.setMargins(copy);
        setData(Object.values(copy.markets));

        Object.keys(updatedCopy.markets[key].markets).map(x => {
            updatedCopy.markets[key].markets[x] = emptyMargin();
        });

        setUpdatedRows(updatedCopy);
    };

    return <div className='sport-margins'>
        {loading && <div className='flex gap-10 padding information margin-top margin-bottom'>
            <i className='fas fa-spinner fa-spin'></i>
            <span>{lang('loading')}</span>
        </div>}
        <div className={`content ${loading ? 'disabled' : ''}`}>
            <div className={`panel ${selectedEvent ? 'disabled' : ''}`}>
                <div className='padding flex gap-5'>
                    <div className='flex gap-10'>
                        <select defaultValue={sport?.id} onChangeCapture={(e) => {
                            selectSport(props.sports.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {props.sports.sort((a, b) => a.weight > b.weight ? 1 : -1).map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        <select ref={regionRef} onChangeCapture={(e) => {
                            selectRegion(regions.find(x => x.id == parseInt(e.target.value)));
                        }}>

                            {regions.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        {
                            (leagues && leagues.length > 0) && <select ref={leagueRef} onChangeCapture={(e) => {
                                selectLeague(leagues.find(x => x.id == parseInt(e.target.value)));
                            }}>
                                {leagues.map(x => {
                                    return <option key={x.id} value={x.id}>{x.name}</option>;
                                })}
                            </select>
                        }
                        {
                            (!leagues || !leagues.length) && <div><span>{lang('No leagues found')}</span></div>
                        }
                        {events && events.length > 0 && <select ref={eventRef}>
                            {events.map(x => {
                                return <option key={x.id} value={x.id}>{new Date(x.date).toLocaleString('en-Gb', { hours12: false })} {x.name}</option>;
                            })}
                        </select>
                        }
                        {(!events || !events.length) && <div><span>{lang('No events found')}</span></div>}
                        {events && events.length > 0 && <Button title='Add' onClick={() => add()} />}
                    </div>
                    <div className='flex align-right'>
                        <Button title={showAll ? lang('Show %1 Only', [sport.name]) : 'Show All'} onClick={() => setShowAll(!showAll)} />
                    </div>
                </div>
            </div>
            {!selectedEvent && <Table model={eventModel}
                data={data}
                context={context}
                buttons={(row) => {
                    return <div className='flex gap-5'>
                        <Button title='Edit' onClick={() => selectEvent(row)} />
                        <Button title='Remove' onClick={() => remove(row)} />;
                    </div>;
                }}
            />
            }
            {selectedEvent && <EventMarkets event={selectedEvent} {...props} close={() => selectEvent(null)} updatedRows={updatedRows} setUpdatedRows={setUpdatedRows} />}
        </div>
        <div className='panel padding'>
            {Object.keys(updatedRows.markets).length > 0 ? <Button title={lang('Save %1 changes', [Object.keys(updatedRows.markets).length])} onClick={() => {
                props.save(updatedRows);
                setUpdatedRows({ markets: {} });
                selectEvent(null);
            }} /> : <Button title='Save' className='disabled' />}
        </div>
    </div >;
};

EventMarketMargins.propTypes = {
    sports: PropTypes.array,
    margins: PropTypes.object,
    setMargins: PropTypes.func,
    save: PropTypes.func
};