import './_affiliatePage.scss';

import React from 'react';
import PropTypes from 'prop-types';


import { Page } from '../../Centrum/Page/Page';
import { application, Endpoints } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { Table } from '../../../v2/Components/Controls/Table/Table';
import { Button } from '../../Controls/Buttons/Button';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../../Controls/Form/Form';
import { FormInput } from '../../Controls/Form/FormInput';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Icon } from '../../Controls/Icon/Icon';
import { AffiliatesModel } from '../../Centrum/_models/AffiliatesModel';



class AffiliateCommissionRange extends React.Component {
    constructor(props) {
        super(props);
        var configuration = Object.assign([], props.configuration);
        configuration.map((x, index) => {
            x.__id = index;
        });

        this.state = { configuration: configuration };
    }

    addNew() {
        var configuration = this.state.configuration;
        configuration.push({ __id: Date.now(), from: 0, to: 0, commission: 0 });
        this.setState({ configuration: configuration });
    }

    remove(data) {
        var configuration = this.state.configuration;
        configuration = configuration.filter(x => x.__id != data.__id);
        this.setState({ configuration: configuration });
    }

    getData() {
        return this.state.configuration;
    }

    render() {
        var data = this.state.configuration;
        return <React.Fragment>
            <Table model={'affiliateCommissionRanges'} data={data}
                className='affiliateCommissionRanges'
                editable={true} editableFields={'from,to,commission'}
                buttons={(row) => {
                    return <Button onClick={() => this.remove(row)} ><Icon icon={'trash'} /></Button>;
                }} />
            <div className='centrum-flex margin-top marginBottom'>
                <Button title='Add Range' className='button alignRight' onClick={this.addNew.bind(this)} />
            </div>
        </React.Fragment>;
    }
}

AffiliateCommissionRange.propTypes = {
    configuration: PropTypes.array
};


// COMPONENT
export class AffiliateGroups extends Page {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);

    }

    componentDidMount() {
        this.loadGroups();
    }


    loadGroups() {
        var page = 0;
        application().resolve(null, Endpoints.ListAffiliateGroups, {
            currentPage: page ?? 0,
            recordsPerPage: 1000
        }, 'retreiving affiliate groups', 'unable to fetch affiliate groups').then((result) => {
            this.setState({ groups: result.result, total: result.total, addNewData: null, updateDate: Date.now() });
        });
    }

    addNew(data) {
        if (this.state.addNewData) data = this.state.addNewData;
        var types = {
            0: 'GGR',
            1: 'Deposit'
        };

        if (!data) {
            data = {

            };
        }

        var configuration = JSON.parse(data.Configuration ? data.Configuration : '[]');

        application().modal.open(<ConfirmationModal
            onConfirm={() => {
                var data = this.form.submit();
                //var config = this.commissionConfiguration.getData();
                //data.Configuration = JSON.stringify(config);
                application().resolve(null, Endpoints.SaveAffiliateGroup, data, 'saving please wait', 'unable to save').then(() => {
                    this.loadGroups();
                }).catch(() => {
                    this.setState({ addNewData: data });
                });
            }}
            title='Add new'>
            <Form data={data} ref={(r) => this.form = r}>
                <div className='row'>
                    <FormInput name='Name' title='Name' required={true} />
                </div>
                <div className='row'>
                    <FormInput name='Description' title='Description' type={InputTypes.textArea} />
                </div>
                <div className='row'>
                    <FormInput name='Type' title='Type' type={InputTypes.select} values={types} />
                    <FormInput name='Commission' title='Commission' type={InputTypes.number} />
                </div>
                <div className='row'>
                    <FormInput name='ExcludeBonus' title='Exclude Bonus' type={InputTypes.bool} />
                    <FormInput name='ExcludePaymentGatewayComissions' title='Exclude Payment Gateway Commissions' type={InputTypes.bool} />
                </div>
                <div className='row'>
                    <FormInput name='Enabled' title='Enabled' type={InputTypes.bool} />
                </div>
            </Form>
        </ConfirmationModal>);
    }

    render() {
        return <React.Fragment>
            <Card caption='Affiliate Groups' menu={<Button title='Add New' onClick={() => this.addNew()} className='button' />}>
                <Table model={AffiliatesModel.affiliatesGroups} data={this.state.groups} key={this.state.updateDate}
                    options={
                        {
                            pagination: {
                                rows: 20,
                                total: this.state.total,
                                showPages: true
                            }
                        }
                    }
                    buttons={(row) => {
                        return <div className='flex gap-5'>
                            <Button key='edit' title='Edit' onClick={() => this.addNew(row)} />
                        </div>;
                    }} />
            </Card>
        </React.Fragment>;
    }
}

AffiliateGroups.defaultProps = {
    groups: []
};

AffiliateGroups.propTypes = {
    groups: PropTypes.array
};

