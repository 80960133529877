// IMPORT PACKAGE REFERENCES
import './_operatorConfigurations.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { lang } from '../../Centrum/Localization/lang';
import { Card } from '../../Centrum/Card/Card';
import { CentrumForm, UpdateButtons } from '../../Centrum/CentrumForm/CentrumForm';
import { ConfigureOperatorCountries } from './OperatorCountries/ConfigureOperatorCountries';
import { ConfigureOperatorCurrencies } from './OperatorCurrencies/ConfigureOperatorCurrencies';
import { ConfigureOperatorCasino } from './OperatorCasino/ConfigureOperatorCasino';
import { ConfigurePaymentGateways } from './OperatorPaymentGateways/ConfigurePaymentGateways';
import { Tab } from '../../Centrum/Tab/Tab';
import { API, OperatorConfigurationEndpoints } from '../../../v2/Lib/Api/Api';
import { ConfigureOperatorRisk } from './OperatorRisk/ConfigureOperatorRisk';


export const configuration = {
    countries: [

    ],
    currencies: [

    ]
};


// COMPONENT
export const OperatorConfiguration = (props) => {
    const [configuration, setConfiguration] = useState(null);

    useEffect(() => {
        var id = props.id;
        API.post(OperatorConfigurationEndpoints.Get + id).then((result) => {
            setConfiguration(result.result);
        });

    }, []);


    const saveOperatorFeatures = (data) => {
        props.onSaveFeatures(data);
    };


    if (!configuration) {
        return <></>;
    }

    let tabButtons = [
        {
            name: 'countries',
            title: 'Countries'
        },
        {
            name: 'currencies',
            title: 'Currencies'
        },
        {
            name: 'casino',
            title: 'Casino'
        },
        {
            name: 'payment',
            title: 'Payment Gateways'
        },
        {
            name: 'risk',
            title: 'Risk Management'
        }
    ];

    console.log(configuration);
    return <div className='padding centrum-flex vertical'>
        <Card caption={lang('Features')}>
            <CentrumForm key={props.id}  data={configuration} model='operatorFeatures' buttons={UpdateButtons} onSubmit={saveOperatorFeatures.bind(this)} />
        </Card>
        <Card>
            <Tab buttons={tabButtons} className='operator-configurations'>
                <ConfigureOperatorCountries operatorId={props.id} configuration={configuration.configuration} key={props.id} />
                <ConfigureOperatorCurrencies operatorId={props.id} configuration={configuration.configuration} key={props.id} />
                <ConfigureOperatorCasino operatorId={props.id} configuration={configuration.casinoConfiguration} key={props.id} />
                <ConfigurePaymentGateways operatorId={props.id} configuration={configuration.paymentGatewayConfiguration} key={props.id} />
                <ConfigureOperatorRisk operatorId={props.id} configuration={configuration.configuration.risk} key={props.id} />
            </Tab>
        </Card>
    </div>;
};


OperatorConfiguration.defaultProps = {
    id: 0,
    transactions: null,
    configurationLoading: true,

    onSaveConfiguration: null,
    onSaveFeatures: null
};

OperatorConfiguration.propTypes = {
    id: PropTypes.number,

    // objects from state
    configuration: PropTypes.object,
    configurationLoading: PropTypes.bool,

    getOperatorConfiguration: PropTypes.func,


    onSaveConfiguration: PropTypes.func,
    onSaveFeatures: PropTypes.func
};

