import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const GET_MY_PROFILE            = 'GET_MY_PROFILE';
export const GET_MY_PROFILE_PENDING    = 'GET_MY_PROFILE_PENDING';
export const GET_MY_PROFILE_FULFILLED  = 'GET_MY_PROFILE_FULFILLED';
export const GET_MY_PROFILE_REJECTED   = 'GET_MY_PROFILE_REJECTED';

export const UPDATE_MY_PROFILE            = 'UPDATE_MY_PROFILE';
export const UPDATE_MY_PROFILE_PENDING    = 'UPDATE_MY_PROFILE_PENDING';
export const UPDATE_MY_PROFILE_FULFILLED  = 'UPDATE_MY_PROFILE_FULFILLED';
export const UPDATE_MY_PROFILE_REJECTED   = 'UPDATE_MY_PROFILE_REJECTED';


export const SEARCH_MY_ACTIVITY            = 'SEARCH_MY_ACTIVITY';
export const SEARCH_MY_ACTIVITY_PENDING    = 'SEARCH_MY_ACTIVITY_PENDING';
export const SEARCH_MY_ACTIVITY_FULFILLED  = 'SEARCH_MY_ACTIVITY_FULFILLED';
export const SEARCH_MY_ACTIVITY_REJECTED   = 'SEARCH_MY_ACTIVITY_REJECTED';



export function getMyProfile() {
    return {
        type: GET_MY_PROFILE,
        payload: postData({},Endpoints.MyProfileGet)
    };
}



export function updateMyProfile(query) {
    return {
        type: UPDATE_MY_PROFILE,
        payload: postData(query,Endpoints.MyProfileUpdate)
    };
}



export function searchMyActivity(query) {
    return {
        type: SEARCH_MY_ACTIVITY,
        payload: postData(query,Endpoints.MyProfileActivity)
    };
}

