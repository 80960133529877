import './_sportsPage.scss';

import React from 'react';

import { getActions, ActionTypes } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';

import { Search } from '../../Centrum/Search/Search';
import { searchHorseTickets } from '../ReportsPage';
import { horseBetSlipResults } from '../RiskManagement/models';
import { apiResult, Endpoints } from '../../Application/Application';
import { useSelector } from 'react-redux';
import { Card } from '../../Centrum/Card/Card';
import { HorseManualSettlement } from './HorseManualSettlement';

//import { SportsConfiguration } from './Configuration/SportsConfiguration';

const applicationActionType = ActionTypes.HorseGreyHound;
const applicationActions = getActions(applicationActionType);


// COMPONENT
export const HorsePage = () => {
    const tickets = useSelector((state) => state.data.searchAllHorseTickets);

    return <CentrumApplication
        caption='Horse' icon='horse'
        type={applicationActionType}
    >
        <ApplicationContext.Consumer>
            {
                () => {
                    return <React.Fragment>
                        <CentrumApplicationActions actions={applicationActions} />
                        <CentrumApplicationContents>
                            <Card caption='Search Tickets'>
                                <Search
                                    model={searchHorseTickets}
                                    resultModel={horseBetSlipResults}
                                    data={tickets}
                                    onSubmit={(data, qb) => { return apiResult('searchAllHorseTickets', Endpoints.SearchHorseGreyhoundTickets, qb, 'fetching sport tickets', 'unable to fetch ticekts'); }}
                                />
                            </Card>
                            <HorseManualSettlement/>
                        </CentrumApplicationContents>
                    </React.Fragment>;
                }
            }
        </ApplicationContext.Consumer>
    </CentrumApplication>;
};