import React from 'react';
import { application, Endpoints } from '../../../../Application/Application';
import { InputTypes } from '../../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { toCamelCase } from '../../../../Centrum/helpers';
import { Form } from '../../../../Controls/Form/Form';
import { FormInput } from '../../../../Controls/Form/FormInput';
import { ConfirmationModal } from '../../../../Modal/Modals/Confirmation/ConfirmationModal';

export const depositPlayer = (player, success) => {
    var form = null;
    application().modal.open(<ConfirmationModal
        onConfirm={() => {
            var data = form.submit();
            data.playerId = player.Id;
            data.retailId = player.RetailId;
            application().resolve('_retail', Endpoints.DepositRetailPlayer, data, 'please wait', 'unable to deposit', true).then((result) => {
                if (success) success(toCamelCase(result.result));
                application().snackbar.open('Deposit success.');
            });
        }}
        title='Deposit Player'>
        <Form ref={(r) => form = r}>
            <div className='row'>
                <FormInput title='Amount' name='Amount' type={InputTypes.number} />
            </div>
            <div className='row'>
                <FormInput title='Notes' name='Notes' type={InputTypes.textArea} />
            </div>
        </Form>
    </ConfirmationModal>);
};

export const withdrawPlayer = (player, success) => {
    var form = null;
    application().modal.open(<ConfirmationModal
        onConfirm={() => {
            var data = form.submit();
            data.playerId = player.Id;
            data.retailId = player.RetailId;
            application().resolve('_retail', Endpoints.WithdrawRetailPlayer, data, 'please wait', 'unable to withdraw', true).then((result) => {
                if (success) success(toCamelCase(result.result));
                application().snackbar.open('Withdraw success.');
            });
        }}
        title='Withdraw Player'>
        <Form ref={(r) => form = r}>
            <div className='row'>
                <FormInput title='Amount' name='Amount' type={InputTypes.number} />
            </div>
            <div className='row'>
                <FormInput title='Notes' name='Notes' type={InputTypes.textArea} />
            </div>
        </Form>
    </ConfirmationModal>);
};


export const playerTags = (player, success) => {
    var form = null;
    application().modal.open(<ConfirmationModal
        onConfirm={() => {
            var data = form.submit();
            data.Id = player.Id;
            application().resolve(null, `${Endpoints.ChangePlayerTag}`, data, 'please wait', 'unable to save tags', true).then(() => {
                application().snackbar.open('Player tags updated.');
                if (success) success();
            });
        }}
        title='Change player tags'>
        <Form ref={(r) => form = r} data={player}>
            <div className='row'>
                <FormInput title='Tags' name='Tag' type={InputTypes.text} />
            </div>
        </Form>
    </ConfirmationModal>);
};