import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const GET_NODE_BY_TYPE               = 'GET_NODE_BY_TYPE';
export const GET_NODE_BY_TYPE_PENDING       = 'GET_NODE_BY_TYPE_PENDING';
export const GET_NODE_BY_TYPE_FULFILLED     = 'GET_NODE_BY_TYPE_FULFILLED';
export const GET_NODE_BY_TYPE_REJECTED      = 'GET_NODE_BY_TYPE_REJECTED';

export const GET_CRASH_NODES               = 'GET_CRASH_NODES';
export const GET_CRASH_NODES_PENDING       = 'GET_CRASH_NODES_PENDING';
export const GET_CRASH_NODES_FULFILLED     = 'GET_CRASH_NODES_FULFILLED';
export const GET_CRASH_NODES_REJECTED      = 'GET_CRASH_NODES_REJECTED';

export const GET_NODE_BY_KEY               = 'GET_NODE_BY_KEY';
export const GET_NODE_BY_KEY_PENDING       = 'GET_NODE_BY_KEY_PENDING';
export const GET_NODE_BY_KEY_FULFILLED     = 'GET_NODE_BY_KEY_FULFILLED';
export const GET_NODE_BY_KEY_REJECTED      = 'GET_NODE_BY_KEY_REJECTED';

export const CLOSE_CRASH_ROOM               = 'CLOSE_CRASH_ROOM';
export const CLOSE_CRASH_ROOM_PENDING       = 'CLOSE_CRASH_ROOM_PENDING';
export const CLOSE_CRASH_ROOM_FULFILLED     = 'CLOSE_CRASH_ROOM_FULFILLED';
export const CLOSE_CRASH_ROOM_REJECTED      = 'CLOSE_CRASH_ROOM_REJECTED';

export const OPEN_CRASH_ROOM               = 'OPEN_CRASH_ROOM';
export const OPEN_CRASH_ROOM_PENDING       = 'OPEN_CRASH_ROOM_PENDING';
export const OPEN_CRASH_ROOM_FULFILLED     = 'OPEN_CRASH_ROOM_FULFILLED';
export const OPEN_CRASH_ROOM_REJECTED      = 'OPEN_CRASH_ROOM_REJECTED';

export const NOTICE_CRASH_ROOM               = 'NOTICE_CRASH_ROOM';
export const NOTICE_CRASH_ROOM_PENDING       = 'NOTICE_CRASH_ROOM_PENDING';
export const NOTICE_CRASH_ROOM_FULFILLED     = 'NOTICE_CRASH_ROOM_FULFILLED';
export const NOTICE_CRASH_ROOM_REJECTED      = 'NOTICE_CRASH_ROOM_REJECTED';

export const SHUTDOWN_HUB               = 'SHUTDOWN_HUB';
export const SHUTDOWN_HUB_PENDING       = 'SHUTDOWN_HUB_PENDING';
export const SHUTDOWN_HUB_FULFILLED     = 'SHUTDOWN_HUB_FULFILLED';
export const SHUTDOWN_HUB_REJECTED      = 'SHUTDOWN_HUB_REJECTED';

export const RESTART_HUB               = 'RESTART_HUB';
export const RESTART_HUB_PENDING       = 'RESTART_HUB_PENDING';
export const RESTART_HUB_FULFILLED     = 'RESTART_HUB_FULFILLED';
export const RESTART_HUB_REJECTED      = 'RESTART_HUB_REJECTED';

export const NOTICE_HUB               = 'NOTICE_HUB';
export const NOTICE_HUB_PENDING       = 'NOTICE_HUB_PENDING';
export const NOTICE_HUB_FULFILLED     = 'NOTICE_HUB_FULFILLED';
export const NOTICE_HUB_REJECTED      = 'NOTICE_HUB_REJECTED';

export const KICK_PLAYER_FROM_HUB               = 'KICK_PLAYER_FROM_HUB';
export const KICK_PLAYER_FROM_HUB_PENDING       = 'KICK_PLAYER_FROM_HUB_PENDING';
export const KICK_PLAYER_FROM_HUB_FULFILLED     = 'KICK_PLAYER_FROM_HUB_FULFILLED';
export const KICK_PLAYER_FROM_HUB_REJECTED      = 'KICK_PLAYER_FROM_HUB_REJECTED';

export const BAN_PLAYER_FROM_HUB               = 'BAN_PLAYER_FROM_HUB';
export const BAN_PLAYER_FROM_HUB_PENDING       = 'BAN_PLAYER_FROM_HUB_PENDING';
export const BAN_PLAYER_FROM_HUB_FULFILLED     = 'BAN_PLAYER_FROM_HUB_FULFILLED';
export const BAN_PLAYER_FROM_HUB_REJECTED      = 'BAN_PLAYER_FROM_HUB_REJECTED';

export const NOTICE_PLAYER_AT_HUB               = 'NOTICE_PLAYER_AT_HUB';
export const NOTICE_PLAYER_AT_HUB_PENDING       = 'NOTICE_PLAYER_AT_HUB_PENDING';
export const NOTICE_PLAYER_AT_HUB_FULFILLED     = 'NOTICE_PLAYER_AT_HUB_FULFILLED';
export const NOTICE_PLAYER_AT_HUB_REJECTED      = 'NOTICE_PLAYER_AT_HUB_REJECTED';

export const REVOKE_RESTRICTION_ON_HUB               = 'REVOKE_RESTRICTION_ON_HUB';
export const REVOKE_RESTRICTION_ON_HUB_PENDING       = 'REVOKE_RESTRICTION_ON_HUB_PENDING';
export const REVOKE_RESTRICTION_ON_HUB_FULFILLED     = 'REVOKE_RESTRICTION_ON_HUB_FULFILLED';
export const REVOKE_RESTRICTION_ON_HUB_REJECTED      = 'REVOKE_RESTRICTION_ON_HUB_REJECTED';

export const SEARCH_NODE_RESTRICTIONS            = 'SEARCH_NODE_RESTRICTIONS';
export const SEARCH_NODE_RESTRICTIONS_PENDING    = 'SEARCH_NODE_RESTRICTIONS_PENDING';
export const SEARCH_NODE_RESTRICTIONS_FULFILLED  = 'SEARCH_NODE_RESTRICTIONS_FULFILLED';
export const SEARCH_NODE_RESTRICTIONS_REJECTED   = 'SEARCH_NODE_RESTRICTIONS_REJECTED';



// ACTION CREATORS
export function getNodeByType(type) {
    return {
        type: GET_NODE_BY_TYPE,
        payload: postData({},Endpoints.CentrumGetNodeByType + type)
    };
}

export function getCrashNodes() {
    return {
        type: GET_CRASH_NODES,
        payload: postData({},Endpoints.CentrumGetCrashNodes)
    };
}


export function getNodeByKey(key) {
    return {
        type: GET_NODE_BY_KEY,
        payload: postData({},Endpoints.CentrumGetNodeByType + key)
    };
}

export function closeCrashRoom(nodeKey,roomId,message) {
    return {
        type: CLOSE_CRASH_ROOM,
        payload: postData({nodeKey:nodeKey, payload:{roomId:roomId,message:message}},Endpoints.CentrumCloseCrashRoom)
    };
}

export function openCrashRoom(nodeKey,roomId) {
    return {
        type: OPEN_CRASH_ROOM,
        payload: postData({nodeKey:nodeKey, payload:{roomId:roomId}},Endpoints.CentrumOpenCrashRoom)
    };
}

export function noticeCrashRoom(nodeKey,roomId,message) {
    return {
        type: NOTICE_CRASH_ROOM,
        payload: postData({nodeKey:nodeKey, payload:{roomId:roomId,message:message}},Endpoints.CentrumNoticeCrashRoom)
    };
}



export function shutdownHub(nodeKey,message) {
    return {
        type: SHUTDOWN_HUB,
        payload: postData({nodeKey:nodeKey, payload:{message:message}},Endpoints.CentrumShutDownHub)
    };
}

export function restartHub(nodeKey,message) {
    return {
        type: RESTART_HUB,
        payload: postData({nodeKey:nodeKey, payload:{message:message}},Endpoints.CentrumRestartHub)
    };
}

export function noticeHub(nodeKey,message) {
    return {
        type: NOTICE_HUB,
        payload: postData({nodeKey:nodeKey, payload:{message:message}},Endpoints.CentrumNoticeHub)
    };
}


export function kickPlayer(nodeKey,data) {
    return {
        type: KICK_PLAYER_FROM_HUB,
        payload: postData({nodeKey:nodeKey, payload:data},Endpoints.CentrumKickPlayer)
    };
}

export function banPlayer(nodeKey,data) {
    return {
        type: BAN_PLAYER_FROM_HUB,
        payload: postData({nodeKey:nodeKey, payload:data},Endpoints.CentrumBanPlayer)
    };
}


export function noticePlayer(nodeKey,data) {
    return {
        type: NOTICE_PLAYER_AT_HUB,
        payload: postData({nodeKey:nodeKey, payload:data},Endpoints.CentrumNoticePlayer)
    };
}


export function revokeRestriction(nodeKey,data) {
    return {
        type: REVOKE_RESTRICTION_ON_HUB,
        payload: postData({nodeKey:nodeKey, payload:data},Endpoints.CentrumRevokeRestriction)
    };
}


export function searchNodeRestrictions(data) {
    return {
        type : SEARCH_NODE_RESTRICTIONS,
        payload:postData(data,Endpoints.CentrumSearchNodeRestrictions)
    };
}

