import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { currency } from '../Localization/lang';
import { ProviderGameTypes, PlayerFlagsToClass, resolveFlags } from './ModelEnums';


export const DashboardModels = {
    dashboardTopTenPlayers: {
        fields: [
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'TotalBetAmount',
                display: 'Total Bets',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            },
            {
                name: 'Profit',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            },
            {
                name: 'ProfitATH',
                display: 'Profit(ATH)',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            },
            {
                name: 'TotalGames',
                display: 'Total Games',
                type: InputTypes.number
            }
        ]
    },
    dashboardCrashTopWins: {
        fields: [
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'TotalBetAmount',
                display: 'Bet Amount',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            },
            {
                name: 'Multiplier',
                type: InputTypes.number,
                formatter: (val) => {
                    return parseFloat(val).toFixed(2) + 'x';
                }
            },
            {
                name: 'TotalWinAmount',
                display: 'Win Amount',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            }
        ]
    },
    dashboardCrashHighestMultipliers: {
        fields: [
            {
                name: 'TimeStart',
                display: 'Start',
                type: InputTypes.dateTime
            },
            {
                name: 'TimeEnd',
                display: 'End',
                type: InputTypes.dateTime
            },
            {
                name: 'Room',
                display: 'Room',
                type: InputTypes.string
            },
            {
                name: 'Multiplier',
                type: InputTypes.number,
                formatter: (val) => {
                    return parseFloat(val).toFixed(2) + 'x';
                }
            },
            {
                name: 'Hash',
                display: 'Game Hash',
                type: InputTypes.string
            },
            {
                name: 'TotalBet',
                display: 'Total Bet',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            },
            {
                name: 'TotalWin',
                display: 'Total Win',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            }
        ]
    },
    dashboardCrashSummary: {
        fields: [
            {
                name: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'NewPlayers',
                display: 'New Players',
                type: InputTypes.number
            },
            {
                name: 'NumberOfSessions',
                display: 'Number of Sessions',
                type: InputTypes.number
            },
            {
                name: 'TotalBet',
                display: 'Total Bet',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            },
            {
                name: 'TotalWin',
                display: 'Total Win',
                type: InputTypes.number,
                formatter: (val) => {
                    return currency(val, 2, 'EUR');
                }
            },
            {
                name: 'RTP',
                display: 'RTP',
                type: InputTypes.number,
                formatter: (val, values) => {
                    try {
                        var rtp = (values.TotalWin * 100) / values.TotalBet;
                        rtp = rtp.toFixed(2);
                        return '%' + rtp;
                    } catch (err) {
                        return '--';
                    }
                }
            },
            {
                name: 'GGR',
                display: 'GGR',
                type: InputTypes.number,
                formatter: (val, values) => {
                    try {
                        var ggr = values.TotalBet - values.TotalWin;
                        return currency(ggr, 2, 'EUR');
                    } catch (err) {
                        return '--';
                    }

                }
            }
        ]
    },
    operatorPlayerActivitySummary: {
        fields: [
            {
                name: 'created',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'username',
                display: 'Player',
                type: InputTypes.text
            },
            {
                name: 'playerFlags',
                display: 'Flags',
                type: InputTypes.text,
                formatter: (val) => {
                    return PlayerFlagsToClass(resolveFlags(val), true);
                },
            },
            {
                name: 'group',
                display: 'Group',
                type: InputTypes.text
            },
            {
                name: 'activity',
                display: 'Activity',
                max: 255,
                type: InputTypes.text
            },
            {
                name: 'data',
                display: 'Data',
                max: 255,
                type: InputTypes.text
            }
        ]
    },
    dashboardTopWins: {
        fields: [
            {
                name: 'transactionDate',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'username',
                display: 'Player',
                type: InputTypes.text
            },
            {
                name: 'playerFlags',
                display: 'Flags',
                type: InputTypes.text,
                formatter: (val) => {
                    return PlayerFlagsToClass(resolveFlags(val), true);
                },
            },
            {
                name: 'group',
                display: 'Group',
                type: InputTypes.text
            },
            {
                name: 'amount',
                display: 'Amount',
                formatter: (val, row) => {
                    return currency(val, 2, row.currency);
                }
            },
            {
                name: 'gameType',
                display: 'Game',
                formatter: (val, row) => {
                    return row.prodiverName + '/' + ProviderGameTypes[val] + '/' + row.gameName;
                }
            }
        ]
    },
    dashboardLastBets: {
        fields: [
            {
                name: 'transactionDate',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'username',
                display: 'Player',
                type: InputTypes.text
            },
            {
                name: 'playerFlags',
                display: 'Flags',
                type: InputTypes.text,
                formatter: (val) => {
                    return PlayerFlagsToClass(resolveFlags(val), true);
                },
            },
            {
                name: 'group',
                display: 'Group',
                type: InputTypes.text
            },
            {
                name: 'amount',
                display: 'Amount',
                formatter: (val, row) => {
                    return currency(val, 2, row.currency);
                }
            },
            {
                name: 'gameType',
                display: 'Game',
                formatter: (val, row) => {
                    return row.prodiverName + '/' + ProviderGameTypes[val] + '/' + row.gameName;
                }
            }
        ]
    },
    globalNotifications: {
        fields: [
            {
                name: 'created',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime
            },
            {
                name: 'level',
                display: 'Level',
                type: InputTypes.text
            },
            {
                name: 'message',
                display: 'Message',
                max: 255,
                type: InputTypes.text
            }
        ]
    },
};