// IMPORT PACKAGE REFERENCES

import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';

import { Page } from '../../Centrum/Page/Page';


import { getActions, ActionTypes, getAction } from '../../Centrum/Common/ActionManager';
import { searchOperatorUsers, searchOperatorUserActivities } from '../../../state/actions/OperatorActions';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { CentrumForm, SaveButtons } from '../../Centrum/CentrumForm/CentrumForm';
import { Card } from '../../Centrum/Card/Card';
import { Search } from '../../Centrum/Search/Search';
import { lang } from '../../Centrum/Localization/lang';
import { Notification } from '../../Centrum/Notifications/Notification/Notification';
import { apiResult, application, Endpoints } from '../../Application/Application';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { API, OperatorUserEndpoints } from '../../../v2/Lib/Api/Api';
import { ErrorModal } from '../../Modal/Modals/Error/ErrorModal';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { Icon } from '../../../v2/Components/Icon/Icon';



const applicationActionType = ActionTypes.Management;
const applicationActions = getActions(applicationActionType);

const UserGroups = {
    2: 'Owner',
    3: 'Manager',
    4: 'Setup',
    5: 'Call Center',
    6: 'Finance',
    7: 'Marketing'
};


const newOperatorUserModel = {
    fields: [
        {
            name: 'OperatorId',
            display: 'Operator',
            type: InputTypes.select,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'UserGroupId',
            display: 'User Group',
            type: InputTypes.select,
            values: UserGroups
        },
        {
            name: 'Username',
            type: InputTypes.text
        },
        {
            name: 'Email',
            type: InputTypes.text,
            validation: 'text',
            max: 128
        },
        {
            name: 'Name',
            type: InputTypes.text,
            validation: 'text',
            max: 128
        },
        {
            name: 'Surname',
            type: InputTypes.text,
            validation: 'text',
            max: 128
        },
        {
            name: 'Password',
            type: InputTypes.password,
            validation: 'text',
            max: 128
        },
        {
            name: 'Enabled',
            type: InputTypes.bool,
            max: 128
        }
    ]
};

export const ManagementPage = () => {
    const operatorUsers = useSelector((state) => state.data.operatorUsers);
    const operatorUserActivities = useSelector((state) => state.data.operatorUserActivities);

    const [newUser, setNewUser] = useState(null);
    const [edit, setEdit] = useState(false);

    const onRenderUserActions = (action, selection) => {
        if (selection.length == 0) return action;
        var item = selection[0];
        switch (action.type) {
            case ActionTypes.ToggleMyUserEnabled:
                return item.Enabled ? { caption: lang('Deactivate User'), icon: 'disable', enabled: true } : { caption: lang('Activate User'), icon: 'enable', enabled: true };
        }
        return Object.assign({ enabled: true }, action);
    };


    const onActionClicked = (action, items) => {
        var item = items[0];
        switch (action.type) {
            case ActionTypes.EditMyUser:
                //this.applicationContext.edit(item, 'Edit User', 'editMyOperatorUser', '/api/operatorUsers/update', 'Updated');
                editUser(item);
                break;

            case ActionTypes.ChangeMyUserPassword:
                //this.applicationContext.edit(item, 'Change Password', 'editUserPassword', '/api/operatorUsers/password/update/' + item.Id, 'Password Changed');
                changePassword(item);
                break;

            case ActionTypes.ToggleMyUserEnabled:
                application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
                    API.post(`${OperatorUserEndpoints.ToggleEnableDisable}${item.Id}`, null, 'Please wait').then(() => {
                        application().snackbar.open(item.Enabled ? 'User disabled' : 'User enabled');
                    });
                }}>
                    <p className='padding'>{lang(item.Enabled ? 'Do you want to Disable User?' : 'Do you want to Enable User?')}</p>
                </ConfirmationModal>);
                break;
            default:
                console.log(action);
                break;
        }
    };


    const editUser = (userData) => {
        var copy = { ...userData };

        var model = { ...newOperatorUserModel };
        if (userData) {
            model.fields = model.fields.filter(x => x.name != 'UserGroupId');
            model.fields = model.fields.filter(x => x.name != 'Password');
        }
        application().modal.open(<ConfirmationModal title='Edit User' onConfirm={() => {
            saveUser(copy, model);
        }}>
            <CentrumForm model={model} className='vertical' onFieldValueChanged={(field, value) => { copy[field.name] = value; }} data={userData} buttons={[]} />
        </ConfirmationModal>);
    };

    const changePassword = (existingUser) => {
        var model = { ...newOperatorUserModel };
        var copy = { ...existingUser };

        model.fields = model.fields.filter(x => x.name == 'Password');
        application().modal.open(<ConfirmationModal title='Change Password' onConfirm={() => {
            API.post(`${OperatorUserEndpoints.ChangePassword}/${existingUser.Id}`, { password: copy.Password }).then(() => {
                application().snackbar.open('Password changed');
            }).catch((err) => {
                application().modal.open(<ErrorModal title='Error' description={err.error.message} />);
            });

        }}>
            <CentrumForm model={model} className='vertical' onFieldValueChanged={(field, value) => { copy[field.name] = value; }} buttons={[]} />
        </ConfirmationModal>);
    };


    const saveUser = (userData, model, onComplete) => {
        application().modal.open(<ConfirmationModal title={userData.Id > 0 ? 'Confirm update user' : 'Confirm save user'} onConfirm={() => {
            API.post(userData.Id > 0 ? OperatorUserEndpoints.UpdateUser : OperatorUserEndpoints.SaveUser, userData).then((result) => {
                application().snackbar.open('User saved');
                if (onComplete) {
                    onComplete(result.result);
                }
            }).catch((err) => {
                application().modal.open(<ErrorModal title='Error' description={err.error.message} />);
            });
        }}>
            <CentrumForm model={model} className='vertical' readonly data={userData} buttons={[]} />
        </ConfirmationModal>);
    };

    return (
        <CentrumApplication
            caption='Management' icon='wrench'
            type={applicationActionType}
        >
            <ApplicationContext.Consumer>
                {
                    () => {
                        return <React.Fragment>
                            <CentrumApplicationActions actions={applicationActions} />
                            <CentrumApplicationContents>
                                <Card>
                                    {(!newUser || edit) &&
                                        <CentrumForm model='newOperatorUser' className='vertical' data={edit ? newUser : null} buttons={SaveButtons} onSubmit={(data) => {
                                            data.Id = newUser?.Id;
                                            saveUser(data, newOperatorUserModel, (res) => {
                                                var copy = { ...data };
                                                copy.Id = res;
                                                setNewUser(copy);
                                            });
                                        }} />
                                    }
                                    {
                                        newUser && !edit && <div className='padding flex vertical gap-10'>
                                            <div className='flex padding gap-10 information'>
                                                <Icon icon='check-circle' />
                                                <span>{newUser.Id > 0 ? lang('User Update') : lang('User Saved')}</span>
                                            </div>
                                            <div className='flex gap-5'>
                                                <Button title='Edit User' onClick={() => setEdit(true)} />
                                                <Button title='New User' onClick={() => {
                                                    setNewUser(null);
                                                    setEdit(false);
                                                }} />
                                            </div>
                                        </div>
                                    }
                                </Card>
                                <Card caption='Search'>
                                    <Search key='searchOperatorUsers'
                                        model='searchOperatorUsers'
                                        resultModel='operatorUserSearchResult'
                                        data={operatorUsers}
                                        actions={getAction(ActionTypes.SearchMyUsers).convertToItems()}
                                        onRenderAction={onRenderUserActions.bind(this)}
                                        onActionClicked={onActionClicked.bind(this)}
                                        onSubmit={(data, qb) => { return apiResult('operatorUsers', Endpoints.OperatorUsersSearch, qb, 'searching', 'unable to search'); }} />
                                </Card>
                                <Card>
                                    <Search
                                        model='searchOperatorUserActivity'
                                        resultModel='operatorUserActivityInformation'
                                        data={operatorUserActivities}
                                        onSubmit={(data, qb) => { return apiResult('operatorUserActivities', Endpoints.OperatorUsersActivity, qb, 'searching', 'unable to search'); }}
                                    />
                                </Card>
                            </CentrumApplicationContents>
                        </React.Fragment>;
                    }
                }
            </ApplicationContext.Consumer>
        </CentrumApplication>
    );
};



// COMPONENT
class ManagementPage2 extends Page {
    constructor(props) {
        super(props);


        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.modal = null;
        state.action = action;

        state.newUser = null;

        this.state = state;
        this.applicationContext = null;

    }

    saveOperatorUser(qb) {
        this.applicationContext.confirmActionAndPost(qb, 'newOperatorUser', '/api/operatorUsers/new', () => {
            this.setState({ newUser: qb });
        }, 'col2', 'Confirm Create New User', 'User saved.', true);
    }


    onRenderUserActions(action, selection) {
        if (selection.length == 0) return action;
        var item = selection[0];
        switch (action.type) {
            case ActionTypes.ToggleMyUserEnabled:
                return item.Enabled ? { caption: lang('Deactivate User'), icon: 'disable', enabled: true } : { caption: lang('Activate User'), icon: 'enable', enabled: true };
        }
        return Object.assign({ enabled: true }, action);
    }


    onActionClicked(action, items) {
        var item = items[0];
        switch (action.type) {
            case ActionTypes.EditMyUser:
                this.applicationContext.edit(item, 'Edit User', 'editMyOperatorUser', '/api/operatorUsers/update', 'Updated');
                break;

            case ActionTypes.ChangeMyUserPassword:
                this.applicationContext.edit(item, 'Change Password', 'editUserPassword', '/api/operatorUsers/password/update/' + item.Id, 'Password Changed');
                break;

            case ActionTypes.ToggleMyUserEnabled:
                this.applicationContext.confirmActionAndPost(item, 'operatorUserSearchResult', '/api/operatorUsers/toggleEnabled/' + item.Id,
                    () => {
                        item.Enabled = !item.Enabled;
                    }, 'col2', item.Enabled ? 'Disable User' : 'Enable User', item.Enabled ? 'User disabled' : 'User enabled', true);
                break;

            default:
                console.log(action);
                break;
        }
    }

    render() {
        return (
            <CentrumApplication
                caption='Management' icon='wrench'
                type={applicationActionType}
                loading={this.props.loading}
            >
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    <Card>
                                        {this.state.newUser == null &&
                                            <CentrumForm model='newOperatorUser' className='vertical' buttons={SaveButtons} onSubmit={this.saveOperatorUser.bind(this)} />
                                        }
                                        {
                                            this.state.newUser != null &&
                                            <React.Fragment>
                                                <CentrumForm model='newOperatorUser' className='vertical' buttons={[]} readonly={true} data={this.state.newUser} />
                                                <Notification>
                                                    <button type='button' onClick={() => this.setState({ newUser: null })}>{lang('Create another user')}</button>
                                                </Notification>
                                            </React.Fragment>
                                        }
                                    </Card>
                                    <Card caption='Search'>
                                        <Search key='searchOperatorUsers'
                                            model='searchOperatorUsers'
                                            resultModel='operatorUserSearchResult'
                                            data={this.props.operatorUsers}
                                            actions={getAction(ActionTypes.SearchMyUsers).convertToItems()}
                                            onRenderAction={this.onRenderUserActions.bind(this)}
                                            onActionClicked={this.onActionClicked.bind(this)}
                                            onSubmit={(data, qb) => this.props.searchOperatorUsers(qb)} />
                                    </Card>
                                    <Card>
                                        <Search
                                            model='searchOperatorUserActivity'
                                            resultModel='operatorUserActivityInformation'
                                            data={this.props.operatorUserActivities}
                                            onSubmit={(data, qb) => { this.props.searchOperatorUserActivities(qb); }}
                                        />
                                    </Card>
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}


const mapStateToProps = state => {
    return {
        operatorUsers: state.application.operatorUsers,
        operatorUserActivities: state.application.operatorUserActivities,
        loading: state.application.loading
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        searchOperatorUsers: searchOperatorUsers,
        searchOperatorUserActivities: searchOperatorUserActivities
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ManagementPage2);
export { hoc as ManagementPage2 };