import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Panel } from '../../../Centrum/Card/Card';
import { Table } from '../../../Controls/Table/Table';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Button } from '../../../Controls/Buttons/Button';
import { openSportsBettingConfiguration } from './SportsBettingConfiguration';


const ticketTypes = [
    { value: 0, display: 'Single' },
    { value: 1, display: 'Multi' },
    { value: 2, display: 'System' }
];



const rangeModel = {
    fields: [
        {
            name: 'ticketType',
            display: 'Ticket Type',
            type: 'select',
            readOnly: true,
            values: ticketTypes,
            formatter: (value) => {
                return ticketTypes.find(x => x.value == value).display;
            }
        },
        {
            name: 'selection',
            display: 'Minimum Selection',
            type: 'number'
        },
        {
            name: 'odds',
            display: 'Odds',
            type: 'number'
        },
        {
            name: 'ew',
            display: 'Eachway',
            type: 'bool'
        },
        {
            name: 'enabled',
            display: 'Enabled',
            type: 'bool'
        }
    ]
};

const defaultOddsConfig = [
    {
        ticketType: 0,
        selection: 1,
        odds: 0,
        enabled: false
    },
    {
        ticketType: 1,
        selection: 2,
        odds: 0,
        enabled: false
    }
];

class OddsPayoutConfiguration extends React.Component {
    constructor(props) {
        super(props);
        var configuration = Object.assign([], props.configuration);
        this.state = { configuration: configuration };
        if (!this.state.configuration || Object.keys(this.state.configuration).length == 0) {
            this.state.configuration = Object.assign([], defaultOddsConfig);
        }
    }

    save() {
        return this.state.configuration;
    }

    render() {
        return <React.Fragment>
            <Table model={rangeModel} data={this.state.configuration}
                key={this.state.selectedCurrency}
                inlineEdit={true}
                inlineNewRow={false}
                deleteEnabled={false}
                editableFields={'enabled'}
                editable={true} buttons_backup={(row) => {
                    return <Button title='Configure' onClick={() => {
                        openSportsBettingConfiguration(row);
                    }} />;
                }} />
        </React.Fragment>;
    }
}

OddsPayoutConfiguration.defaultProps = {
    configuration: defaultOddsConfig
};

OddsPayoutConfiguration.propTypes = {
    configuration: PropTypes.array
};

export const RacingRules = (props) => {
    const [sports, saveSports] = useState(Object.assign({}, props.configuration ? props.configuration : {
        bet: {},
        enabled: false
    }));
    var betsTable = null;


    const context = {
        save: () => {
            var sportsCopy = {
                bet: betsTable.save(),
                enabled: sports.enabled
            };


            saveSports(sportsCopy);
            return sportsCopy;
        }
    };

    useEffect(() => {
        props.context(context);
    });


    return <div className='padding'>
        <Form data={sports}>
            <div className='row'>
                <FormInput name='enabled' title='Enable Horse / Greyhound racing' type='bool' onChange={(f, r, value) => {
                    saveSports({
                        enabled: value,
                        bet: sports.bet
                    });
                }} />
            </div>
        </Form>
        <Panel title='Bet Configuration' className={'margin-top ' + (sports.enabled ? '' : 'disabled')}>
            <OddsPayoutConfiguration ref={(r) => betsTable = r} configuration={sports.bet} />
        </Panel>
    </div>;
};

RacingRules.propTypes = {
    configuration: PropTypes.object,

    context: PropTypes.func
};

