export const newData = (data, url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    try {
                        if (result.status === 1) {
                            resolve(result);
                        } else {
                            reject(result);
                        }
                    } catch (err) {
                        // error number 1 is for general error
                        // you can supress this error and show a human understandble error message
                        // instead of err , 
                        // catch(err) will just simply give you javascript exception message
                        reject({
                            error: {
                                errorNumber: 1,
                                message: 'Service is not available, please try later.'
                            },
                            result: null,
                            status: 0
                        });
                    }
                },
                (error) => {
                    reject(error);
                }
            ).catch(e => {
                // supresss
                console.warn(e);
            });
    });
};

export const searchData = (data, url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    try {
                        if (result.status === 1) {
                            resolve(result);
                        } else {
                            reject(result);
                        }
                    } catch (err) {
                        // error number 1 is for general error
                        // you can supress this error and show a human understandble error message
                        // instead of err , 
                        // catch(err) will just simply give you javascript exception message
                        reject({
                            error: {
                                errorNumber: 1,
                                message: 'Service is not available, please try later.'
                            },
                            result: null,
                            status: 0
                        });
                    }
                },
                (error) => {
                    reject(error);
                }
            ).catch(e => {
                // supresss
                console.warn(e);
            });
    });
};

export const getData = (url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    try {
                        if (result.status === 1) {
                            resolve(result);
                        } else {
                            reject(result);
                        }
                    } catch (err) {
                        // error number 1 is for general error
                        // you can supress this error and show a human understandble error message
                        // instead of err , 
                        // catch(err) will just simply give you javascript exception message
                        reject({
                            error: {
                                errorNumber: 1,
                                message: 'Service is not available, please try later.'
                            },
                            result: null,
                            status: 0
                        });
                    }
                },
                (error) => {
                    if (typeof error === 'string' || error instanceof String || error.error === null || error.error === undefined) {
                        reject({
                            error: {
                                errorNumber: 1,
                                message: 'Service is not available, please try later.'
                            },
                            result: null,
                            status: 0
                        });
                    } else reject(error);
                }
            ).catch(e => {
                // supresss
                console.warn(e);
            });
    });
};

export const updateData = (data, url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    try {
                        if (result.status === 1) {
                            resolve(result);
                        } else {
                            reject(result);
                        }
                    } catch (err) {
                        // error number 1 is for general error
                        // you can supress this error and show a human understandble error message
                        // instead of err , 
                        // catch(err) will just simply give you javascript exception message
                        reject({
                            error: {
                                errorNumber: 1,
                                message: 'Service is not available, please try later.'
                            },
                            result: null,
                            status: 0
                        });
                    }
                },
                (error) => {
                    reject(error);
                }
            ).catch(e => {
                // supresss
                console.warn(e);
            });
    });
};



export const postData = (data, url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: data != null ? JSON.stringify(data) : ''
        })
            .then(res => res.json())
            .then(
                (result) => {
                    try {
                        if (result.status === 1) {
                            resolve(result);
                        } else {
                            reject(result);
                        }
                    } catch (err) {
                        // error number 1 is for general error
                        // you can supress this error and show a human understandble error message
                        // instead of err , 
                        // catch(err) will just simply give you javascript exception message
                        reject({
                            error: {
                                errorNumber: 1,
                                message: result.error.message
                            },
                            result: null,
                            status: 0
                        });
                    }
                },
                (error) => {
                    console.log(error);
                    reject({
                        error: {
                            errorNumber: 1,
                            message: 'Service not available, please try later.'
                        },
                        result: null,
                        status: 0
                    });
                }
            ).catch(e => {
                // supresss
                console.warn(e);
            });
    });
};
