import './_multiselect.scss';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReactSelect from 'react-select';

export const MultiSelect = props => {
    // isOptionSelected sees previous props.value after onChange
    const valueRef = useRef(props.value);
    valueRef.current = props.value;

    const selectAllOption = {
        value: '<SELECT_ALL>',
        label: 'Select All'
    };

    const isSelectAllSelected = () => {
        if (valueRef.current.length === props.options.length) {
            selectAllOption.value = props.options.map(i => i.value);
            return true;
        }else{
            selectAllOption.value ='<SELECT_ALL>';
        }
        return false;
    };


    const isOptionSelected = option =>
        valueRef.current.some(({ value }) => value === option.value) ||
        isSelectAllSelected();

    const getOptions = () => [selectAllOption, ...props.options];

    const getValue = () =>
        isSelectAllSelected() ? [selectAllOption] : props.value;

    const onChange = (newValue, actionMeta) => {
        const { action, option, removedValue } = actionMeta;

        if (action === 'select-option' && option.value === selectAllOption.value) {
            props.onChange(props.options, actionMeta);
        } else if (
            (action === 'deselect-option' &&
                option.value === selectAllOption.value) ||
            (action === 'remove-value' &&
                removedValue.value === selectAllOption.value)
        ) {
            props.onChange([], actionMeta);
        } else if (
            actionMeta.action === 'deselect-option' &&
            isSelectAllSelected()
        ) {
            props.onChange(
                props.options.filter(({ value }) => value !== option.value),
                actionMeta
            );
        } else {
            props.onChange(newValue || [], actionMeta);
        }
    };

    return (
        <ReactSelect
            className='multi-select'
            isOptionSelected={isOptionSelected}
            options={getOptions()}
            value={getValue()}
            onChange={onChange}
            defaultValue={props.defaultValue}
            name={props.name}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isMulti
            isClearable={true}
            isSearchable={true}
        />
    );
};

MultiSelect.propTypes = {
    value: PropTypes.string,
    options: PropTypes.array,
    name: PropTypes.string,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func
};
