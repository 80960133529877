// IMPORT PACKAGE REFERENCES

import { combineReducers } from 'redux';

// IMPORT REDUCERS

import { DataReducer } from '../reducers/DataReducer';
import { UserReducer } from '../reducers/UserReducer';
import { LanguageReducer } from '../reducers/LanguageReducer';
import { CentrumNodeReducer } from '../reducers/CentrumNodeReducer';
import { ApplicationReducer } from '../reducers/ApplicationReducer';
import { PlayerReducer } from '../reducers/PlayerReducer';
import { OperatorReducer } from '../reducers/OperatorReducer';
import { TransactionsReducer } from '../reducers/TransactionsReducer';
import { SportsbookReducer } from '../reducers/SportsbookReducer';
import { CrashGameReducer } from '../reducers/CrashGameReducer';
import { WidgetReducer } from '../reducers/WidgetReducer';
import { createSettings } from '../../config';
import { MarketingReducer as MarketingReducer } from './MarketingReducer';
import { ProvidersReducer } from './ProvidersReducer';
import { ProfileReducer } from './ProfileReducer';
import { ShopReducer } from './ShopReducer';
import { SettingsReducer } from './SettingsReducer';
import { VolumesReducer } from './Volumes/VolumesReducer';
import { FluxReducer } from './FluxReducer';


// EXPORT APP REDUCER

export const AppReducer = combineReducers({
    settings: SettingsReducer,
    data: DataReducer,
    user: UserReducer,
    flux: FluxReducer,
    sportsbook: SportsbookReducer,
    language: LanguageReducer,
    application: ApplicationReducer,
    providers: ProvidersReducer,
    centrumNode: CentrumNodeReducer,
    transactions: TransactionsReducer,
    marketing: MarketingReducer,
    crashGame: CrashGameReducer,
    player: PlayerReducer,
    operator: OperatorReducer,
    profile: ProfileReducer,
    widgets: WidgetReducer,
    shop: ShopReducer,
    volumes: VolumesReducer
});


const date = new Date();
window.offsetInHours = (date.getTimezoneOffset() / 60) * -1;

createSettings({
    socket: window.socket
});




// remove initial state
delete window.__PRELOADED_STATE__;