import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';

export const PromotionModels = {
    bonus: {
        fields: [
            {
                name: 'Name'
            },
            {
                name: 'Code'
            },
            {
                name: 'IsRegisterCode',
                display:'Only in Register',
                type:InputTypes.bool
            },
            {
                name: 'TriggerDeposit',
                display:'Deposit',
                type:InputTypes.number
            },
            {
                name: 'DepositCurrency',
                display: 'Currency',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'mycurrencies',
                    bindingKey: 'CurrencyCode',
                    bindingText: ['CurrencyCode']
                }
            },
            {
                name: 'Enabled',
                types: InputTypes.bool
            }
        ]
    },

};