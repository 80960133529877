
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalControl from '../Components/Modals/Modal';
import OverlayControl from '../Components/Overlay/Overlay';

export const CentrumContext = React.createContext();

var _modalContext = null;
var _overlayContext = null;

export const Modal = {
    open: (modal) => {
        if (!_modalContext) return;
        _modalContext.open(modal);
    },
    close: () => {
        if (!_modalContext) return;
        _modalContext.close();
    }
};

export const Overlay = {
    open: (overlay) => {
        if (!_overlayContext) return;
        _overlayContext.open(overlay);
    },
    close: () => {
        if (!_overlayContext) return;
        _overlayContext.close();
    }
};


const Centrum = (props) => {
    const [modal, openModal] = useState(null);
    const [overlay, openOverlay] = useState(null);
    const context = {
        modal: _modalContext,
        overlay: _overlayContext
    };

    _modalContext = {
        open: (control) => {
            openModal(control);
        },
        close: () => {
            openModal(null);
        }
    };

    _overlayContext = {
        open: (control) => {
            openOverlay(control);
        },
        close: () => {
            openOverlay(null);
        }
    };

    return <div className='application'>
        <CentrumContext.Provider value={context}>
            {props.children}
            <OverlayControl overlay={overlay} />
            <ModalControl modal={modal} />
        </CentrumContext.Provider>
    </div>;
};

Centrum.propTypes = {
    user: PropTypes.object,
    children: PropTypes.node
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    return {
        user: state.user.profile,
        socket: state.socket
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(Centrum);

// EXPORT COMPONENT
export default hoc;
