import './_managePromotions.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { application, Endpoints } from '../../../Application/Application';
import { Button } from '../../../Controls/Buttons/Button';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Card, Panel } from '../../../Centrum/Card/Card';
import { lang } from '../../../Centrum/Localization/lang';


import { Table } from '../../../Controls/Table/Table';
import { BonusTypes, EnumToArray } from '../../../Centrum/_models/ModelEnums';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { getHistory } from '../../../routers/AppRouter';
import { DepositBonus } from '../DepositBonus/DepositBonus';
import { DiscountBonus } from '../DiscountBonus/DiscountBonus';
import { Dropdown } from '../../../Controls/DropDown/Dropdown';
import { ManualBonus } from '../ManualBonus/ManualBonus';
import { BetslipBonus } from '../BetslipBonus/BetslipBonus';
import { WelcomeBonus } from '../WelcomeBonus/WelcomeBonus';
import { BetslipLoserBonus } from '../BetslipBonus/BetslipLoserBonus';
import { CashBonus } from '../CashBonus/CashBonus';
import { Icon } from '../../../Controls/Icon/Icon';
import { API, PromotionEndpoints } from '../../../../v2/Lib/Api/Api';
import { SlipLotteryBonus } from '../BetslipBonus/SlipLotteryBonus';
import { BetBoosterBonus } from '../BetslipBonus/BetBoosterBonus';
import { MultiplierBonus } from '../BetslipBonus/MultiplierBonus';


// COMPONENT
class ManagePromotions extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    componentDidMount() {
        this.loadPromotions();
    }

    loadPromotions() {
        application().resolve(null, Endpoints.ListPromotions, {}).then((result) => {
            var path = window.location.pathname;
            path = path.split('/');
            var promotionSelected = null;
            if (path[path.length - 2].toLowerCase() == 'edit') {
                var id = parseInt(path[path.length - 1]);
                promotionSelected = result.result.find(x => x.Id == id);
            }
            this.setState({ promotions: result.result, updateTime: Date.now(), promotionSelected: promotionSelected });
        });
    }

    savePromotion(data) {
        data.TotalPlayers = parseInt(data.TotalPlayers);
        //data.Product = parseInt(data.Product);
        data.Product = 0;
        data.Type = parseInt(this.state.promotionSelected ? this.state.promotionSelected.Type : data.Type);
        data.Id = this.state.promotionSelected ? this.state.promotionSelected.Id : data.Id;
        data.Removed = false;
        data.DaysOfWeek = typeof (data.DaysOfWeek) === 'object' ? JSON.stringify(data.DaysOfWeek) : data.DaysOfWeek;

        if (data.Configuration instanceof Object) {
            data.Configuration = JSON.stringify(data.Configuration);
        }

        if (data.Id) {
            data.OperatorId = this.state.promotionSelected?.OperatorId ?? data.OperatorId;
        }

        application().resolve(null, Endpoints.SavePromotion, data).then(() => {
            application().snackbar.open('saved');
            this.loadPromotions();
        });
    }


    deletePromotionFormSubmit(data) {
        application().resolve(null, Endpoints.DeletePromotion + '/' + data.Id, data, 'deleting please wait', 'unable to delete promotion').then(() => {
            application().snackbar.open('deleted');
            if (this.state.promotionSelected) {
                this.setState({ promotionSelected: null }, () => { getHistory().goBack(); this.loadPromotions(); });
            } else {
                this.loadPromotions();
            }
        });
    }

    delete(data) {
        application().modal.open(<ConfirmationModal title='Delete Promotion'
            onConfirm={
                () => {
                    this.form.post();
                }
            }>
            <Form model={'promotion'} data={data}
                onSubmit={this.deletePromotionFormSubmit.bind(this)}
                ref={(r) => this.form = r}>
                <p>{lang('Please confirm if you would like to delete')}</p>
            </Form>
        </ConfirmationModal>);
    }

    duplicate(data) {
        var d = Object.assign({}, data);
        d.Id = 0;
        this.addNew(d);
    }

    addNew(data) {
        if (!data) {
            data = { Name: '', Id: 0 };
        }
        application().modal.open(<ConfirmationModal title='New Promotion'
            onConfirm={
                () => {
                    this.form.post();
                }
            }>
            <Form model={'promotion'} data={data}
                onSubmit={this.savePromotion.bind(this)}
                ref={(r) => this.form = r}>
                <div className='row'>
                    <FormInput name='Name' title='Name' required={true} />
                    <FormInput name='Type' title='Bonus Type' type={InputTypes.select} values={EnumToArray(BonusTypes, true)} required={true} />
                </div>
                <div className='row'>
                    <FormInput name='Description' title='Description' required={true} type={InputTypes.textArea} />
                </div>
            </Form>
        </ConfirmationModal>);
    }

    toggleEnabled(checked, promotion) {
        application().modal.open(<ConfirmationModal title={promotion.Name}
            onConfirm={
                () => {
                    var updatedData = Object.assign({}, promotion);
                    updatedData.Enabled = checked;
                    API.post(`${PromotionEndpoints.ToggleEnabled}/${promotion.Id}/${checked}`, null).then(() => {
                        this.loadPromotions();
                    });
                }
            }
            onCancel={
                () => {
                    promotion.Enabled = !promotion.Enabled;
                    this.setState({ cancel: true, updateTime: Date.now() });
                }
            }
        >
            {lang('Do you want to %1 promotion', [checked ? 'enable' : 'disable'])}
        </ConfirmationModal>);
    }

    tabSelected(index) {
        this.setState({ selectedLanguage: index });
    }

    renderPromotionConfiguration() {
        var component = null;
        switch (this.state.promotionSelected.Type) {
            case BonusTypes.Manual:
                component = <ManualBonus promotion={this.state.promotionSelected} ref={(r) => this.bonusComponent = r} />;
                break;
            case BonusTypes.Deposit:
                component = <DepositBonus promotion={this.state.promotionSelected} ref={(r) => this.bonusComponent = r} />;
                break;
            case BonusTypes['Cash Bonus']:
                component = <CashBonus promotion={this.state.promotionSelected} ref={(r) => this.bonusComponent = r} />;
                break;
            case BonusTypes.Discount:
                component = <DiscountBonus promotion={this.state.promotionSelected} ref={(r) => this.bonusComponent = r} />;
                break;
            case BonusTypes['Betslip Bonus']:
                component = <BetslipBonus promotion={this.state.promotionSelected} ref={(r) => this.bonusComponent = r} />;
                break;
            case BonusTypes['Multiplier Bonus']:
                component = <MultiplierBonus promotion={this.state.promotionSelected} context={(c) => this.bonusComponent = c} />;
                break;
            case BonusTypes['Near Miss Bonus']:
                component = <BetslipLoserBonus promotion={this.state.promotionSelected} ref={(r) => this.bonusComponent = r} />;
                break;
            case BonusTypes.Welcome:
                component = <WelcomeBonus promotion={this.state.promotionSelected} ref={(r) => this.bonusComponent = r} />;
                break;
            case BonusTypes['Slip Lottery']:
                component = <SlipLotteryBonus promotion={this.state.promotionSelected} context={(c) => this.bonusComponent = c} />;
                break;
            case BonusTypes['Bet Booster']:
                component = <BetBoosterBonus promotion={this.state.promotionSelected} context={(c) => this.bonusComponent = c} />;
                break;
            default:
                component = null;
                break;
        }

        if (component == null) {
            component = <div>{lang('configuration is not available')}</div>;
        }

        return <React.Fragment>
            <Card caption={this.state.promotionSelected.Name + ' ' + EnumToArray(BonusTypes, true)[this.state.promotionSelected.Type]}
                icon={<div className='marginRight link' onClick={() => { this.setState({ promotionSelected: null }, () => getHistory().goBack()); }}><Icon icon={'arrow-left'} />{lang('Back')}</div>}
                menu={
                    <Dropdown position='right' type='menu' title='Actions'>
                        <Button key='a' title='Duplicate' onClick={() => {
                            this.duplicate(this.state.promotionSelected);
                        }} />
                        <Button key='b' title='Delete' onClick={() => {
                            this.delete(this.state.promotionSelected);
                        }} />
                    </Dropdown>
                }>
                <div className='promotion-configuration'>
                    {component}
                </div>
            </Card>
            <Card className='margin-top'>
                <div className='centrum-flex'>
                    <Button title='Save' type='submit' className='margin' onClick={() => this.savePromotion(this.bonusComponent.save())} />
                    <Button title='Close' type='reset' className='margin alignRight' onClick={() => {
                        getHistory().goBack();
                        this.setState({ promotionSelected: null });
                    }} />
                </div>
            </Card>
        </React.Fragment>;
    }

    renderPromotions() {
        var promotions = this.state.promotions.sort((a, b) => a.OperatorId - b.OperatorId);
        return <React.Fragment key={this.state.updateTime}>
            {
                this.state.promotionSelected && this.renderPromotionConfiguration()
            }
            {!this.state.promotionSelected &&
                <React.Fragment>
                    <Panel className='marginBottom start'>
                        <div className='centrum-flex marginLeft'>
                            <Button title='Add New' type='submit' className='margin alignRight' onClick={() => this.addNew()} />
                        </div>
                    </Panel>

                    <Card>
                        <Table
                            model={{
                                fields: [
                                    { name: 'Operator', display: 'Operator' },
                                    { name: 'Name', display: 'Name' },
                                    {
                                        name: 'Type', display: 'Type', formatter: (field, row) => {
                                            return EnumToArray(BonusTypes, true)[row.Type];
                                        }
                                    },
                                    { name: 'Code', display: 'Code' },
                                    { name: 'Enabled', display: 'Enabled', type: 'bool' }
                                ]
                            }}
                            data={promotions}
                            editable={true}
                            editableFields='Enabled'
                            onDataChange={(field, promotion) => {
                                this.toggleEnabled(promotion.Enabled, promotion);
                            }}
                            buttons={(row) => {
                                return <React.Fragment>
                                    <Button key='edit' title='Edit'
                                        onClick={() => {
                                            var path = window.location.pathname;
                                            if (path.toLowerCase().indexOf('edit') == -1) {
                                                if (path[path.length - 1] != '/') path += '/';
                                                getHistory().push(path + 'edit/' + row.Id);
                                            }

                                            this.setState({ promotionSelected: row });
                                        }} icon={'edit'} />
                                    <Button
                                        key='duplicate'
                                        title='Duplicate' onClick={() => this.duplicate(row)} icon={'copy'} />
                                    <Button key='delete'
                                        title='Delete' onClick={() => this.delete(row)} icon={'times'} />
                                </React.Fragment>;
                            }}
                        />
                    </Card>
                </React.Fragment>
            }
        </React.Fragment>;
    }

    render() {
        return <React.Fragment>
            {this.renderPromotions()}
        </React.Fragment>;
    }
}

ManagePromotions.defaultProps = {
    promotions: []
};

ManagePromotions.propTypes = {
    promotions: PropTypes.object
};


const mapStateToProps = state => {
    return {
        language: state.language.selected
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ManagePromotions);
export { hoc as ManagePromotions };