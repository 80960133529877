

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../Centrum/Card/Card';
import { CurrencyMinMaxConfiguration } from '../../../Promotions/Components/CurrencyMinMaxConfiguration';
import { Currencies } from '../../../../Centrum/_models/ModelEnums';
import { Button } from '../../../../Controls/Buttons/Button';
import { application, Endpoints } from '../../../../Application/Application';
import { Form } from '../../../../Controls/Form/Form';
import { Icon } from '../../../../Controls/Icon/Icon';
import { API, DataEndpoints } from '../../../../../v2/Lib/Api/Api';




const slipTypes = [
    { value: 0, display: 'Prematch' },
    { value: 1, display: 'Live' },
    { value: 2, display: 'Combined' }
];

const ticketTypes = [
    { value: 0, display: 'Single' },
    { value: 1, display: 'Multi' },
    { value: 2, display: 'System' }
];

// COMPONENT
export class SportsLimitsGeneral extends React.Component {
    constructor(props) {
        super(props);

        var state = Object.assign({}, props);
        state.selectedCurrency = window.user.DefaultCurrency ?? 'USD';

        if (!state.limits) {
            state.limits = {
                general: {},
                groups: {}
            };
        }

        Object.keys(Currencies).map(currency => {
            if (!state.limits.general.hasOwnProperty(currency)) {
                state.limits.general[currency] = this.createEmptyData();
            }
        });

        this.state = state;
    }

    componentDidMount() {

        API.post(DataEndpoints.EnabledCurrencies).then((currencyResult) => {
            var currencyLimits = {
                general: {},
                groups: {}
            };
            currencyResult.result.map(x => {
                var code = x.CurrencyCode;
                if (x.Enabled) {
                    if (!this.state.limits.general.hasOwnProperty(code)) {
                        currencyLimits.general[code] = this.createEmptyData();
                    } else {
                        currencyLimits.general[code] = this.state.limits.general[code];
                    }
                }
            });
        });


        application().resolve(null, Endpoints.GetPlayerGroups, {}, 'loading player groups', 'unable to load player groups').then((result) => {
            var groups = {};
            groups[0] = 'Select a group for configuration';
            result.result.map(x => {
                groups[x.Id] = x.Name;
            });

            this.setState({ groups: groups });
        });
    }

    getData() {
        return this.state.limits;
    }

    clearSelectedGroup() {
        var limits = this.state.limits;
        if (this.groupsSelect) {
            this.groupsSelect.selectedIndex = 0;
        }
        delete limits.groups[this.state.selectedGroup];
        this.setState({ selectedGroup: null });
    }

    selectGroup(group) {
        var limits = this.state.limits;

        if (!limits.groups.hasOwnProperty(group)) {
            Object.keys(Currencies).map(currency => {
                if (!limits.groups[group]) {
                    limits.groups[group] = {};
                }

                limits.groups[group][currency] = this.createEmptyData();
            });
        }
        this.setState({ limits: limits, selectedGroup: group });
    }

    createEmptyData() {
        var data = [

        ];



        Object.keys(slipTypes).map(slipType => {
            Object.keys(ticketTypes).map(ticketType => {
                var row = {
                    SlipType: slipType,
                    TicketType: ticketType,
                    MinBet: 0,
                    MaxBet: 0,
                    MaxItems: 0,
                    MinOdds: 0,
                    MaxOdds: 0,
                    MaxPayout: 0
                };
                data.push(row);
            });
        });

        return data;
    }

    render() {
        return <React.Fragment>
            <Card className='scroll'>
                <CurrencyMinMaxConfiguration
                    ref={(r) => this.betLimitsSettings = r}
                    selectedCurrency={this.state.selectedCurrency}
                    data={this.state.limits.general}
                    newRow={false}
                    deleteEnabled={false}
                    model={
                        [
                            {
                                name: 'SlipType',
                                display: 'Type',
                                type: 'select',
                                readOnly: true,
                                values: slipTypes,
                                formatter: (value) => {
                                    return slipTypes.find(x => x.value == value).display;
                                }
                            },
                            {
                                name: 'TicketType',
                                display: 'Ticket Type',
                                type: 'select',
                                readOnly: true,
                                values: ticketTypes,
                                formatter: (value) => {
                                    return ticketTypes.find(x => x.value == value).display;
                                }
                            },
                            {
                                name: 'MinBet',
                                display: 'Min Stake',
                                type: 'number'
                            },
                            {
                                name: 'MaxBet',
                                display: 'Max Stake',
                                type: 'number'
                            },
                            {
                                name: 'MaxItems',
                                display: 'Max Number of Bets',
                                type: 'number'
                            },
                            {
                                name: 'MinOdds',
                                display: 'Min Odds',
                                type: 'number'
                            },
                            {
                                name: 'MaxOdds',
                                display: 'Max Odds',
                                type: 'number'
                            },
                            {
                                name: 'MaxPayout',
                                display: 'Max Payout',
                                type: 'number'
                            }
                        ]
                    }

                />
                <Card caption={'Player groups'}
                    menu={this.state.selectedGroup && <Icon icon='trash' className='margin-top' onClick={() => this.clearSelectedGroup()} />}
                >
                    <Form>
                        <div className='row'>
                            {
                                this.state.groups &&
                                <div className='form-group'>
                                    <select ref={(r) => this.groupsSelect = r} onChangeCapture={(e) => {
                                        var id = parseInt(e.target.value);
                                        this.selectGroup(id);
                                    }}>
                                        {Object.keys(this.state.groups).map(groupId => {
                                            return <option value={groupId} key={groupId}>{this.state.groups[groupId]}</option>;
                                        })}
                                    </select>
                                </div>
                            }
                        </div>
                    </Form>
                </Card>
                {
                    this.state.selectedGroup &&
                    <Card>
                        <CurrencyMinMaxConfiguration
                            key={this.state.selectedGroup}
                            selectedCurrency={this.state.selectedCurrency}
                            data={this.state.limits.groups[this.state.selectedGroup]}
                            newRow={false}
                            deleteEnabled={false}
                            model={
                                [
                                    {
                                        name: 'SlipType',
                                        display: 'Type',
                                        type: 'select',
                                        readOnly: true,
                                        values: slipTypes,
                                        formatter: (value) => {
                                            return slipTypes.find(x => x.value == value).display;
                                        }
                                    },
                                    {
                                        name: 'TicketType',
                                        display: 'Ticket Type',
                                        type: 'select',
                                        readOnly: true,
                                        values: ticketTypes,
                                        formatter: (value) => {
                                            return ticketTypes.find(x => x.value == value).display;
                                        }
                                    },
                                    {
                                        name: 'MinBet',
                                        display: 'Min Stake',
                                        type: 'number'
                                    },
                                    {
                                        name: 'MaxBet',
                                        display: 'Max Stake',
                                        type: 'number'
                                    },
                                    {
                                        name: 'MaxItems',
                                        display: 'Max Items',
                                        type: 'number'
                                    },
                                    {
                                        name: 'MinOdds',
                                        display: 'Min Odds',
                                        type: 'number'
                                    },
                                    {
                                        name: 'MaxOdds',
                                        display: 'Max Odds',
                                        type: 'number'
                                    },
                                    {
                                        name: 'MaxPayout',
                                        display: 'Max Payout',
                                        type: 'number'
                                    }
                                ]
                            }
                            ref={(r) => this.payoutRules = r} />
                    </Card>
                }
            </Card>
            {this.props.saveEnabled &&
                <Card className='margin-top centrum-flex'>
                    <Button title='Save' type='submit' className='margin alignRight' />
                </Card>
            }
        </React.Fragment >;
    }
}

SportsLimitsGeneral.defaultProps = {
    title: '',
    saveEnabled: true,

    selectedCurrency: 'USD',
    limits: {
        general: {},
        groups: {}
    }
};

SportsLimitsGeneral.propTypes = {
    title: PropTypes.string,
    saveEnabled: PropTypes.bool,

    selectedCurrency: PropTypes.string,
    limits: PropTypes.object
};

