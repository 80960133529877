/* eslint-disable react/display-name */
import './_reports.scss';


import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Search } from '../Centrum/Search/Search';
import { Page } from '../Centrum/Page/Page';


import { ActionTypes, getActions } from '../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../Centrum/Application/CentrumApplication';
import { Card } from '../Centrum/Card/Card';
import { apiResult, application, Endpoints } from '../Application/Application';
import { horseDistanceReportsResult, horsePlayerRevenues, horseRegionReportsResult, horseRevenueSummaryResult, horseVenueReportsResult, searchHorseBettingReport, searchHorseBettingVenuesReport } from './Sports/Models/horse';
import { retailAccountSummary, retailAccountSummaryReportResult, retailPlayerTransactionsReport, retailPlayerTransactionsReportResult, retailTransactionsReport, retailTransactionsReportResult, sportLeagueReports } from './Reports/models';
import { betSlipResults, horseBetSlipResults, playersWithoutBetsResult } from './RiskManagement/models';
import { InputTypes } from '../Centrum/CentrumForm/Inputs/CentrumInput';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes, TestAccountFilter, TicketFlags, TransactionTypes } from '../Centrum/_models/ModelEnums';
import { DateRanges } from '../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';
import { readFromFile, saveXLSX } from '../Centrum/Common/Excel/ExcelHelpers';
import { FinanceReport } from './Reports/FinanceReport';
import { RacingTicketTypes } from '../../v2/Components/Sports/Tickets/BettingEnums';
import { RiskTickets } from './RiskManagement/Volumes/Components/RiskTickets/RiskTickets';
import { EventSummaryReport } from './Reports/EventSummaryReport';
import { currencyAndFooter } from '../Centrum/_models/ReportsModel';
import { currency } from '../../v2/Lib/Common/currency';

const applicationActionType = ActionTypes.Reports;
const applicationActions = getActions(applicationActionType);


const calculateProfit = (row, type, isExporting, isFooter) => {

    if (isFooter) {
        var targets = ['Bets', 'Win', 'BetsBonus', 'WinBonus'];
        var values = {};
        targets.map(target => {
            var rowValue = row[`${type}${target}`];
            if (!values[target]) {
                values[target] = {

                };
            }

            Object.keys(rowValue).map(c => {
                values[target][c] = rowValue[c];
            });
        });

        var bets = {

        };
        var wins = {

        };

        Object.keys(values).map(x => {
            var targetList = bets;
            if (x.indexOf('Win') >= 0) {
                targetList = wins;
            }

            var currencies = values[x];
            Object.keys(currencies).map(y => {
                var amount = currencies[y];
                if (!targetList[y]) {
                    targetList[y] = 0;
                }

                targetList[y] += amount;
            });
        });

        var profits = {};

        Object.keys(bets).map(c => {
            if (!profits[c]) {
                profits[c] = 0;
            }
            var winValue = wins[c] ? wins[c] : 0;
            profits[c] += bets[c];
            profits[c] -= winValue;
        });

        var elements = [];
        Object.keys(profits).map(c => {
            elements.push(<div key={c}>{currency(profits[c], c, 2)}</div>);
        });
        return <div className='flex vertical'>
            {elements}
        </div>;
    }
    if (!row[`${type}Bets`]) row[`${type}Bets`] = 0;
    if (!row[`${type}Win`]) row[`${type}Win`] = 0;
    if (!row[`${type}BetsBonus`]) row[`${type}BetsBonus`] = 0;
    if (!row[`${type}WinBonus`]) row[`${type}WinBonus`] = 0;

    var res = (Math.abs(row[`${type}Bets`]) + Math.abs(row[`${type}BetsBonus`])) - (row[`${type}Win`] + row[`${type}WinBonus`]);
    if (isExporting) return res;
    return parseFloat(res).toFixed(2);
};

const calculateWinLoss = (row, type, isExporting, isFooter) => {
    if (isFooter || isExporting) return;
    if (!row[`${type}Bets`]) row[`${type}Bets`] = 0;
    if (!row[`${type}Win`]) row[`${type}Win`] = 0;
    if (!row[`${type}BetsBonus`]) row[`${type}BetsBonus`] = 0;
    if (!row[`${type}WinBonus`]) row[`${type}WinBonus`] = 0;

    var bets = (Math.abs(row[`${type}Bets`]) + Math.abs(row[`${type}BetsBonus`]));
    var win = (row[`${type}Win`] + row[`${type}WinBonus`]);
    var profit = bets - win;

    var perc = profit / bets;
    if (isNaN(perc)) perc = 0;
    if (bets == 0) perc = 0;
    if (isExporting) return perc;
    return '%' + perc.toFixed(2);

};

export const dailyRevenuesSummaryReport = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Date',
            display: 'Date',
            type: InputTypes.dateTime,

            queryBuilderOptions: {
                Between: true,
                data: DateRanges['This Week']
            }
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};

export const dailyRevenuesSummaryReportResult = {
    fields: [
        {
            name: 'Date',
            display: 'Date',
            type: InputTypes.date
        },
        {
            name: 'Operator'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'TotalBonusDeposit',
            display: 'Given Bonus',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Given</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }

        },
        {
            name: 'SportTickets',
            display: 'Sport Tickets',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>Tickets</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'SportTicketsBonus',
            display: 'Bonus Tickets',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Tickets</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'SportBets',
            display: 'Sport Stakes',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'SportWin',
            display: 'Sport Win',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>Win</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'SportBetsBonus',
            display: 'Bonus Stake',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Stake</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'SportWinBonus',
            display: 'Bonus Win',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Win</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'SportProfit',
            display: 'Sport Profit',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>Profit</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return calculateProfit(row, 'Sport', isExporting, isFooter);
            },
            currency: true
        },
        {
            name: 'SportWinLoss',
            display: 'Sport Win/Loss',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>W/L</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return calculateWinLoss(row, 'Sport', isExporting, isFooter);
            }
        },
        {
            name: 'HorseTickets',
            display: 'Horse Tickets',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>Tickets</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'HorseTicketBonus',
            display: 'Bonus Tickets',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Tickets</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'HorseBets',
            display: 'Horse Stakes',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'HorseWin',
            display: 'Horse Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>Win</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'HorseBetsBonus',
            display: 'Bonus Stake',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'HorseWinBonus',
            display: 'Bonus Win',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Win</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'HorseProfit',
            display: 'Horse Profit',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>Profit</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return calculateProfit(row, 'Horse', isExporting, isFooter);
            },
            currency: true

        },
        {
            name: 'HorseWinLoss',
            display: 'Horse Win/Loss',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>W/L</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return calculateWinLoss(row, 'Horse', isExporting, isFooter);
            }
        },
        {
            name: 'VirtualGames',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Virtual</span>
                    <span className='center'>Games</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'VirtualTicketBonus',
            display: 'Bonus Games',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Games</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'VirtualBets',
            display: 'Virtual Stakes',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Virtual</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'VirtualWin',
            display: 'Virtual Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Virtual</span>
                    <span className='center'>Win</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'VirtualBetsBonus',
            display: 'Bonus Stake',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'VirtualWinBonus',
            display: 'Bonus Win',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Win</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'VirtualProfit',
            display: 'Virtual Profit',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Profit</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return calculateProfit(row, 'Virtual', isExporting, isFooter);
            },
            currency: true
        },
        {
            name: 'VirtualWinLoss',
            display: 'Virtual Win/Loss',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>W/L</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return calculateWinLoss(row, 'Virtual', isExporting, isFooter);
            }
        },
        {
            name: 'CasinoGames',
            display: 'Casino Games',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Games</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'CasinoGamesBonus',
            display: 'Bonus Games',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Games</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'CasinoBets',
            display: 'Casino Stakes',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'CasinoWin',
            display: 'Casino Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Win</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'CasinoBetsBonus',
            display: 'Bonus Stake',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Stake</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'CasinoWinBonus',
            display: 'Bonus Win',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Win</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'CasinoProfit',
            display: 'Casino Profit',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Profit</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return calculateProfit(row, 'Casino', isExporting, isFooter);
            },
            currency: true
        },
        {
            name: 'CasinoWinLoss',
            display: 'Casino Win/Loss',
            type: InputTypes.number,
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>W/L</span>
                </div>;
            },
            formatter: (val, row, isFooter, isExporting) => {
                return calculateWinLoss(row, 'Casino', isExporting, isFooter);
            }
        }
    ],
    onParseExcel: (data) => {
        readFromFile('/report-files/summary.xlsx').then((workbook) => {
            var rawReportSheet = workbook.getWorksheet('By Currencies');

            var currencies = data.data.rates;
            var defaultCurrency = data.data.defaultCurrency;
            var currencySheet = workbook.getWorksheet('Currency Rates');
            var reportSheet = workbook.getWorksheet('Report');

            const convert = (amount, source) => {
                if (source == defaultCurrency) return amount;
                var rate = currencies.find(x => x.base == defaultCurrency && x.currencyCode == source);
                return amount * rate.buy;
            };

            var testAccounts = data.request.fields.find(x => x.name == 'TestAccounts');
            if (testAccounts.value == '0') testAccounts = 'Excluded';
            if (testAccounts.value == '1') testAccounts = 'Included';
            if (testAccounts.value == '2') testAccounts = 'Only test accounts';


            reportSheet.getCell('A1').value = 'Daily revenue summary report';
            reportSheet.getCell('B2').value = new Date();
            reportSheet.getCell('B3').value = defaultCurrency;
            reportSheet.getCell('B4').value = new Date(data.request.fields.find(x => x.name == 'Date').min);
            reportSheet.getCell('C4').value = new Date(data.request.fields.find(x => x.name == 'Date').max);
            reportSheet.getCell('B5').value = testAccounts;

            var convertedRows = {};
            currencies.map(x => {
                var row = [];
                if (x.base == defaultCurrency) {
                    //ws.getCell('A1').numFmt = '# ?/?';
                    row.push(1);
                    row.push(x.currencyCode);
                    row.push(x.buy);
                    row.push(defaultCurrency);
                    currencySheet.insertRow(5, row);
                }
            });

            data.result.map(x => {
                var row = [];
                var reportRow = [];
                if (!convertedRows.hasOwnProperty(x.Date)) {
                    convertedRows[x.Date] = {};
                }

                data.model.fields.map(field => {
                    var value = x[field.name];
                    if (field.type == InputTypes.date) {
                        value = new Date(value);
                    } else {
                        value = field.formatter ? field.formatter(value, x, false, true) : value;
                    }
                    if (!convertedRows[x.Date].hasOwnProperty(field.name)) {
                        convertedRows[x.Date][field.name] = 0;
                    }
                    if (field.currency) {
                        value = convert(value, x.Currency);
                        convertedRows[x.Date][field.name] += value;
                    } else {
                        if (field.type == InputTypes.number) {
                            convertedRows[x.Date][field.name] += value;
                        } else {
                            convertedRows[x.Date][field.name] = value;
                        }
                    }
                    reportRow.push(value);
                    row.push(value);
                });
                rawReportSheet.insertRow(3, row, 'o');
            });

            /*
            var totalRows = Object.keys(convertedRows).length;
            var totals = 'C,D,E,F,G,I,J,K,L,M,O,P,Q,R,S,U,V,W,X,Y,AA,AB,AC,AD,AE';
            totals.split(',').map(x => {
                reportSheet.getCell(`${x}9`).value = { formula: `=SUBTOTAL(109,${x}11:${x}${11 + totalRows})` };
            });
            */
            Object.values(convertedRows).map(x => {
                delete x.Currency;
                reportSheet.insertRow(11, Object.values(x), 'o');
            });



            saveXLSX(workbook, 'daily-summary.xlsx');
        });
    }
};

export const searchBetSlips = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id'
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,

            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'EventId',
            display: 'Event Id',
            type: InputTypes.number
        },
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            value: 0,
            values: SportsTicketStatus
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Flags',
            type: InputTypes.select,
            values: TicketFlags,
            acceptEmptyValues: true
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};



export const searchPlayersWithoutBets = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Created',
            display: 'Registration Date',
            type: InputTypes.dateTime,

            queryBuilderOptions: {
                Between: true,
                data: DateRanges['This Month']
            }
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};


export const searchHorseTickets = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,

            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'TicketType',
            display: 'Betting Types',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: RacingTicketTypes
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketStatus
        },
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id',
            type: InputTypes.number
        },
        {
            name: 'EventId',
            display: 'Event Id',
            type: InputTypes.number
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },

        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'BonusWallet',
            display: 'Bonus Wallet',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: {
                1: 'Yes',
                0: 'No'
            }
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};


const searchTransactionsAccountSummary = {
    fields: [
        {
            name: 'TransactionDate',
            display: 'Date',
            type: InputTypes.dateTime,

            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};
const transactionsAccountSummary = {
    fields: [
        {
            name: 'TransactionDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'UserId',
            display: 'Player Id'
        },
        {
            name: 'Username'
        },
        {
            name: 'PlayerName',
            display: 'Name Surname'
        },
        {
            name: 'Operator'
        },
        {
            name: 'RetailName',
            display: 'Retail'
        },
        {
            name: 'PlayerGroup',
            display: 'Group'
        },
        {
            name: 'Type',
            display: 'Type',
            formatter: (val) => {
                return TransactionTypes[val];
            }
        },
        {
            name: 'Amount',
            display: 'Amount'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'OperatorUsername',
            display: 'Admin'
        }
    ]
};


const searchFirstTransactions = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Type',
            type: InputTypes.select,
            values: {
                0: 'Deposit',
                1: 'Withdraw',
                2: 'Win',
                3: 'Bet'
            }
        },
        {
            name: 'RegisterDate',
            display: 'Register Date',
            type: InputTypes.dateTime,

            queryBuilderOptions: {
                Between: true,
                data: DateRanges['This Week']
            }
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};


const firstTransactionSummary = {
    fields: [
        {
            name: 'TransactionDate',
            display: 'Transaction Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Id',
            display: 'Player Id'
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'Retail'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group'
        },
        {
            name: 'Admin'
        },
        {
            name: 'Currency'
        },
        {
            name: 'Amount',
            display: 'Amount',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return Math.abs(val);
                if (isFooter) return;
                if (!val) return 0;
                val = Math.abs(val);
                return currency(parseFloat(val).toFixed(2), row.Currency, 2);
            },
            currency: true
        },
        {
            name: 'BalanceBefore',
            display: 'Balance Before',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return Math.abs(val);
                if (isFooter) return;
                if (!val) return 0;
                val = Math.abs(val);
                return currency(parseFloat(val).toFixed(2), row.Currency, 2);
            },
            currency: true
        },
        {
            name: 'BalanceAfter',
            display: 'Balance After',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return Math.abs(val);
                if (isFooter) return;
                if (!val) return 0;
                val = Math.abs(val);
                return currency(parseFloat(val).toFixed(2), row.Currency, 2);
            },
            currency: true
        },
        {
            name: 'CurrentBalance',
            display: 'Current Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return Math.abs(val);
                if (isFooter) return;
                if (!val) return 0;
                val = Math.abs(val);
                return currency(parseFloat(val).toFixed(2), row.Currency, 2);
            },
            currency: true
        },
        {
            name: 'Notes'
        }
    ]
};
// COMPONENT
class ReportsPage extends Page {
    constructor(props) {
        super(props);
        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.selectedPlayer = null;
        state.modal = null;
        state.action = action;

        this.state = state;
        this.applicationContext = null;
    }

    showHorseTickets(searchFields, row) {
        var copy = Object.assign([], searchFields);
        copy = copy.filter(x => x.name != 'Venues');
        copy.push({
            name: 'Venues',
            exact: true,
            and: true,
            value: row.Venues
        });

        var request = {
            fields: copy
        };

        apiResult('horseTicketsOfEvent', Endpoints.SearchHorseGreyhoundTickets, request, 'fetching sport tickets', 'unable to fetch ticekts').then(result => {
            console.log(result);
        });
    }

    showTicketDetails(searchFields, row) {
        var copy = Object.assign([], searchFields);
        copy.push({
            name: 'EventId',
            exact: true,
            and: true,
            value: row.EventId
        });
        copy = copy.filter(x => x.name != 'Retails');
        var request = {
            fields: copy
        };

        const search = (r) => {
            return application().resolve(null, Endpoints.SearchSportEventTickets, r);
        };

        search(request).then((result) => {
            application().modal.open(<RiskTickets search={(r) => this.showTicketDetails(r.fields, row)} data={result} key={Date.now()} />);
        });

    }


    showTickets(endpoint, request, row) {
        const search = (r) => {
            return application().resolve(null, endpoint, r);
        };

        search(request).then((result) => {
            application().modal.open(<RiskTickets search={(r) => this.showTickets(endpoint, r, row)} data={result} key={Date.now()} />);
        });

    }

    render() {
        return (
            <CentrumApplication caption='Reports' icon='reports' type={applicationActionType} loading={this.props.loading}>
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents>
                                    {/* REVENUES */}
                                    <Card caption='Daily Summary'>
                                        <Search
                                            model={dailyRevenuesSummaryReport}
                                            resultModel={dailyRevenuesSummaryReportResult}
                                            data={this.props.dailyRevenuesSummaryReport}
                                            onSubmit={(data, qb) => { return apiResult('dailyRevenuesSummaryReport', Endpoints.DailyRevenuesSummaryReport, qb, 'fetching report please wait', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Casino'>
                                        <Search
                                            model='casinoReport'
                                            resultModel='casinoReportResult'
                                            data={this.props.casinoReportResult}
                                            onSubmit={(data, qb) => { return apiResult('casinoReportResult', Endpoints.CasinoRevenuesReport, qb, 'fetching game revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Providers'>
                                        <Search
                                            model='casinoProviderReport'
                                            resultModel='casinoProviderReportResult'
                                            data={this.props.casinoProviderReport}
                                            onSubmit={(data, qb) => { return apiResult('casinoProviderReport', Endpoints.CasinoProviderRevenuesReport, qb, 'fetching game revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Games'>
                                        <Search
                                            model='casinoGamesReport'
                                            resultModel='casinoGamesReportResult'
                                            data={this.props.casinoGamesReport}
                                            onSubmit={(data, qb) => { return apiResult('casinoGamesReport', Endpoints.GameRevenuesReports, qb, 'fetching game revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Casino Player Revenues'>
                                        <Search
                                            model='casinoGamesReport'
                                            resultModel='playerGameRevenuesReportResult'
                                            data={this.props.playerGameRevenuesReportResult}
                                            onSubmit={(data, qb) => { return apiResult('playerGameRevenuesReportResult', Endpoints.PlayerCasinoRevenuesReport, qb, 'fetching game revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Player Groups Game Revenues'>
                                        <Search
                                            model='casinoGamesReport'
                                            resultModel='playerGroupsGameRevenuesReportResult'
                                            data={this.props.playerGroupsGameRevenuesReportResult}
                                            onSubmit={(data, qb) => { return apiResult('playerGroupsGameRevenuesReportResult', Endpoints.PlayerGroupsGameRevenuesReportResult, qb, 'fetching game revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Casino Provider GGR'>
                                        <Search
                                            model='casinoProviderCommission'
                                            resultModel='casinoProviderCommissionResult'
                                            data={this.props.casinoProviderCommissionResult}
                                            showPagination={false}
                                            onSubmit={(data, qb) => { return apiResult('casinoProviderCommissionResult', Endpoints.CasinoProviderCommissionReport, qb, 'fetching game revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Providers Summary'>
                                        <Search
                                            model='providerRevenuesReport'
                                            resultModel='providerRevenuesReportResult'
                                            data={this.props.providerRevenuesReport}
                                            onSubmit={(data, qb) => { return apiResult('providerRevenuesReport', Endpoints.ProviderRevenuesReport, qb, 'fetching providedr revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Betting'>
                                        <Search
                                            model='sportsBettingRevenues'
                                            resultModel='sportBettingRevenuesReport'
                                            data={this.props.sportBettingRevenuesReport}
                                            showPagination={false}
                                            onSubmit={(data, qb) => { return apiResult('sportBettingRevenuesReport', Endpoints.SportBettingRevenuesReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Betting Players'>
                                        <Search
                                            model='sportsBettingRevenues'
                                            resultModel='sportBettingPlayerRevenuesReport'
                                            data={this.props.sportBettingPlayerRevenuesReport}
                                            onSubmit={(data, qb) => { return apiResult('sportBettingPlayerRevenuesReport', Endpoints.SportBettingPlayerRevenuesReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Betting Player Groups'>
                                        <Search
                                            model='sportsBettingRevenues'
                                            resultModel='sportPlayerGroupRevenues'
                                            data={this.props.sportPlayerGroupRevenues}
                                            showPagination={false}
                                            onSubmit={(data, qb) => { return apiResult('sportPlayerGroupRevenues', Endpoints.SportPlayerGroupRevenues, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>



                                    {/* Sports Betting */}
                                    <Card caption='Categories'>
                                        <Search
                                            model='sportsBettingRevenues'
                                            resultModel='sportCategoryRevenueSummaryReport'
                                            data={this.props.sportCategoryRevenueSummaryReport}
                                            showPagination={false}
                                            onDoubleClick={(row, request) => {
                                                request.fields = request.fields.filter(x => x.name != 'OperatorId');
                                                request.fields = request.fields.filter(x => x.name != 'SportId');
                                                request.fields = request.fields.filter(x => x.name != 'ClosedTickets');
                                                if (request.fields.filter(x => x.name == 'Status').length == 0) {
                                                    request.fields.push({ name: 'ClosedTickets', value: true });
                                                }

                                                request.fields.push({ name: 'OperatorId', value: row.OperatorId, and: true, exact: true });
                                                request.fields.push({ name: 'SportId', value: row.SportId, and: true, exact: true });

                                                this.showTickets(Endpoints.SearchSportTickets, request);
                                            }}
                                            onSubmit={(data, qb) => { return apiResult('sportCategoryRevenueSummaryReport', Endpoints.SportCategoryRevenueSummaryReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Regions'>
                                        <Search
                                            model='sportsBettingRevenues'
                                            resultModel='sportRegionRevenueSummaryReport'
                                            data={this.props.sportRegionRevenueSummaryReport}
                                            onDoubleClick={(row, request) => {
                                                request.fields = request.fields.filter(x => x.name != 'OperatorId');
                                                request.fields = request.fields.filter(x => x.name != 'SportId');
                                                request.fields = request.fields.filter(x => x.name != 'RegionId');
                                                request.fields = request.fields.filter(x => x.name != 'ClosedTickets');
                                                if (request.fields.filter(x => x.name == 'Status').length == 0) {
                                                    request.fields.push({ name: 'ClosedTickets', value: true });
                                                }

                                                request.fields.push({ name: 'OperatorId', value: row.OperatorId, and: true, exact: true });
                                                request.fields.push({ name: 'SportId', value: row.SportId, and: true, exact: true });
                                                request.fields.push({ name: 'RegionId', value: row.RegionId, and: true, exact: true });

                                                this.showTickets(Endpoints.SearchSportTickets, request);
                                            }}
                                            onSubmit={(data, qb) => { return apiResult('sportRegionRevenueSummaryReport', Endpoints.SportRegionRevenueSummaryReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Leagues'>
                                        <Search
                                            model={sportLeagueReports}
                                            resultModel='sportLeagueRevenueSummaryReport'
                                            data={this.props.sportLeagueRevenueSummaryReport}
                                            showPagination={false}
                                            onDoubleClick={(row, request) => {
                                                request.fields = request.fields.filter(x => x.name != 'OperatorId');
                                                request.fields = request.fields.filter(x => x.name != 'LeagueId');
                                                request.fields = request.fields.filter(x => x.name != 'ClosedTickets');
                                                if (request.fields.filter(x => x.name == 'Status').length == 0) {
                                                    request.fields.push({ name: 'ClosedTickets', value: true });
                                                }

                                                request.fields.push({ name: 'LeagueId', value: row.LeagueId, and: true, exact: true });

                                                this.showTickets(Endpoints.SearchSportTickets, request);
                                            }}
                                            onSubmit={(data, qb) => { return apiResult('sportLeagueRevenueSummaryReport', Endpoints.SportLeagueRevenueSummaryReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Events'>
                                        <Search
                                            model='searchSportsBettingRevenuesByWithEvent'
                                            resultModel='sportEventRevenueSummaryReport'
                                            data={this.props.sportEventRevenueSummaryReport}
                                            onSubmit={(data, qb) => {
                                                this.setState({ sportEventTickets: null, sportEventId: null }, () => {
                                                    apiResult('sportEventRevenueSummaryReport', Endpoints.SportEventRevenueSummaryReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report');
                                                });
                                            }}
                                            onDoubleClick={(row) => {
                                                this.showTicketDetails(this.props.sportEventRevenueSummaryReport.request.fields, row);
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Markets'>
                                        <Search
                                            model='sportsBettingRevenuesWithMarkets'
                                            resultModel='sportMarketRevenueSummaryReport'
                                            data={this.props.sportMarketRevenueSummaryReport}
                                            onDoubleClick={(row, request) => {
                                                request.fields = request.fields.filter(x => x.name != 'OperatorId');
                                                request.fields = request.fields.filter(x => x.name != 'SportId');
                                                request.fields = request.fields.filter(x => x.name != 'RegionId');
                                                request.fields = request.fields.filter(x => x.name != 'LeagueId');
                                                request.fields = request.fields.filter(x => x.name != 'EventId');
                                                request.fields = request.fields.filter(x => x.name != 'ClosedTickets');
                                                if (request.fields.filter(x => x.name == 'Status').length == 0) {
                                                    request.fields.push({ name: 'ClosedTickets', value: true });
                                                }

                                                request.fields.push({ name: 'SportId', value: row.SportId, and: true, exact: true });
                                                request.fields.push({ name: 'RegionId', value: row.RegionId, and: true, exact: true });
                                                request.fields.push({ name: 'LeagueId', value: row.LeagueId, and: true, exact: true });
                                                request.fields.push({ name: 'EventId', value: row.EventId, and: true, exact: true });

                                                this.showTickets(Endpoints.SearchSportTickets, request);
                                            }}
                                            onSubmit={(data, qb) => { return apiResult('sportMarketRevenueSummaryReport', Endpoints.SportMarketRevenueSummaryReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Selections'>
                                        <Search
                                            model='sportsBettingRevenues'
                                            resultModel='sportSelectionRevenueSummaryReport'
                                            data={this.props.sportSelectionRevenueSummaryReport}
                                            onDoubleClick={(row, request) => {
                                                request.fields = request.fields.filter(x => x.name != 'OperatorId');
                                                request.fields = request.fields.filter(x => x.name != 'SportId');
                                                request.fields = request.fields.filter(x => x.name != 'RegionId');
                                                request.fields = request.fields.filter(x => x.name != 'LeagueId');
                                                request.fields = request.fields.filter(x => x.name != 'EventId');
                                                request.fields = request.fields.filter(x => x.name != 'SelectionId');
                                                request.fields = request.fields.filter(x => x.name != 'ClosedTickets');
                                                if (request.fields.filter(x => x.name == 'Status').length == 0) {
                                                    request.fields.push({ name: 'ClosedTickets', value: true });
                                                }

                                                request.fields.push({ name: 'SportId', value: row.SportId, and: true, exact: true });
                                                request.fields.push({ name: 'RegionId', value: row.RegionId, and: true, exact: true });
                                                request.fields.push({ name: 'LeagueId', value: row.LeagueId, and: true, exact: true });
                                                request.fields.push({ name: 'EventId', value: row.EventId, and: true, exact: true });
                                                request.fields.push({ name: 'SelectionId', value: row.SelectionId, and: true, exact: true });

                                                console.log(request.fields);
                                                this.showTickets(Endpoints.SearchSportTickets, request);
                                            }}
                                            onSubmit={(data, qb) => { return apiResult('sportSelectionRevenueSummaryReport', Endpoints.SportSelectionRevenueSummaryReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Players'>
                                        <Search
                                            model='sportsBettingRevenuesOfPlayer'
                                            resultModel='sportPlayerRevenueSummaryReport'
                                            data={this.props.sportPlayerRevenueSummaryReport}
                                            onDoubleClick={(row, request) => {
                                                request.fields = request.fields.filter(x => x.name != 'OperatorId');
                                                request.fields = request.fields.filter(x => x.name != 'PlayerId');
                                                request.fields = request.fields.filter(x => x.name != 'ClosedTickets');
                                                if (request.fields.filter(x => x.name == 'Status').length == 0) {
                                                    request.fields.push({ name: 'ClosedTickets', value: true });
                                                }

                                                request.fields.push({ name: 'PlayerId', value: row.PlayerId, and: true, exact: true });

                                                this.showTickets(Endpoints.SearchSportTickets, request);
                                            }} onSubmit={(data, qb) => { return apiResult('sportPlayerRevenueSummaryReport', Endpoints.SportPlayerRevenueSummaryReport, qb, 'fetching sport betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Tickets'>
                                        <Search
                                            model={searchBetSlips}
                                            resultModel={betSlipResults}
                                            data={this.props.sportTicketsOfEvent}
                                            onSubmit={(data, qb) => { return apiResult('sportTicketsOfEvent', Endpoints.SearchSportTickets, qb, 'fetching sport tickets', 'unable to fetch ticekts'); }}
                                        />
                                    </Card>
                                    <EventSummaryReport />
                                    <Card caption='Players without bets'>
                                        <Search
                                            model={searchPlayersWithoutBets}
                                            resultModel={playersWithoutBetsResult}
                                            data={this.props.playersWithoutBetsResult}
                                            onSubmit={(data, qb) => { return apiResult('playersWithoutBetsResult', Endpoints.SearchPlayersWithoutSportBets, qb, 'fetching sport tickets', 'unable to fetch ticekts'); }}
                                        />
                                    </Card>

                                    {/* Horse */}
                                    <Card caption='Horse / Greyhound'>
                                        <Search
                                            model={searchHorseBettingReport}
                                            resultModel={horseRevenueSummaryResult}
                                            data={this.props.horseGreyhoundSummaryReport}
                                            onSubmit={(data, qb) => { return apiResult('horseGreyhoundSummaryReport', Endpoints.HorseGreyhoundSummaryReport, qb, 'fetching horse betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Horse / Greyhound Regions'>
                                        <Search
                                            model={searchHorseBettingReport}
                                            resultModel={horseRegionReportsResult}
                                            data={this.props.horseGreyHoundRegions}
                                            onSubmit={(data, qb) => { return apiResult('horseGreyHoundRegions', Endpoints.HorseGreyHoundRegions, qb, 'fetching horse betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Horse / Greyhound Venues'>
                                        <Search
                                            model={searchHorseBettingVenuesReport()}
                                            resultModel={horseVenueReportsResult}
                                            data={this.props.horseGreyHoundVenues}
                                            onSubmit={(data, qb) => { return apiResult('horseGreyHoundVenues', Endpoints.HorseGreyHoundVenues, qb, 'fetching horse betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Horse / Greyhound Distance'>
                                        <Search
                                            model={searchHorseBettingReport}
                                            resultModel={horseDistanceReportsResult}
                                            data={this.props.horseGreyHoundDistance}
                                            onSubmit={(data, qb) => { return apiResult('horseGreyHoundDistance', Endpoints.HorseGreyHoundDistance, qb, 'fetching horse betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Horse / Greyhound Players'>
                                        <Search
                                            model={searchHorseBettingReport}
                                            resultModel={horsePlayerRevenues}
                                            data={this.props.horseGreyHoundPlayers}
                                            onSubmit={(data, qb) => { return apiResult('horseGreyHoundPlayers', Endpoints.HorseGreyHoundPlayers, qb, 'fetching horse betting revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Tickets'>
                                        <Search
                                            model={searchHorseBettingReport}
                                            resultModel={horseBetSlipResults}
                                            data={this.props.horseTickets}
                                            onSubmit={(data, qb) => { return apiResult('horseTickets', Endpoints.SearchHorseGreyhoundTickets, qb, 'fetching sport tickets', 'unable to fetch ticekts'); }}
                                        />
                                    </Card>


                                    {/* PLAYERS */}
                                    <Card caption='Player Revenue Summary'>
                                        <Search
                                            model='searchReportByPlayer'
                                            resultModel='playerRevenueReportResult'
                                            data={this.props.playerReport}
                                            onSubmit={(data, qb) => { return apiResult('playerReport', Endpoints.PlayerRevenuesReport, qb, 'fetching player revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='Player Game Summary'>
                                        <Search
                                            model='searchPlayerGamesReport'
                                            resultModel='resultPlayerGamesReport'
                                            data={this.props.playerGamesReport}
                                            onSubmit={(data, qb) => { return apiResult('playerGamesReport', Endpoints.PlayerGameRevenuesReport, qb, 'fetching player revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>



                                    {/* Transactions */}
                                    <Card caption='Transactions Account Summary'>
                                        <Search
                                            model={searchTransactionsAccountSummary}
                                            resultModel={transactionsAccountSummary}
                                            data={this.props.transactionsAccountSummary}
                                            showPagination={false}
                                            onSubmit={(data, qb) => {
                                                return apiResult('transactionsAccountSummary', Endpoints.SearchTransactionsAccountSummary, qb, 'fetching player revenues reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Transactions by Provider'>
                                        <Search
                                            model='operatorTransactionsReport'
                                            resultModel='operatorTransactionsReportResult'
                                            showPagination={false}
                                            onRowClicked={(row) => {
                                                var query = Object.assign({}, this.operatorTransactionsReportQuery);
                                                query.fields.push({
                                                    name: 'ProviderId',
                                                    value: row.ProviderId,
                                                    exact: true,
                                                    and: true
                                                });
                                                return apiResult('playerTransactionsOfSelectedProvider', Endpoints.PlayerProviderTransactions, query, 'fetching players', 'unable to fetch report').then(() => {
                                                    this.setState({ selectedTransactionProvider: row.ProviderId });
                                                });
                                            }}
                                            data={this.props.providerTransactionsReport}
                                            onSubmit={(data, qb) => {
                                                this.operatorTransactionsReportQuery = qb;
                                                return apiResult('providerTransactionsReport', Endpoints.ProviderTransactionsReport, qb, 'fetching transactions report', 'unable to fetch report');
                                            }}
                                        />
                                        {
                                            this.state.selectedTransactionProvider > 0 &&
                                            <Card className='margin'>
                                                <Search
                                                    caption='Players'
                                                    showQuery={false}
                                                    showSummary={false}
                                                    model={null}
                                                    resultModel='playerTransactionsOfSelectedProvider'
                                                    showPagination={false}
                                                    data={this.props.playerTransactionsOfSelectedProvider}
                                                />
                                            </Card>
                                        }
                                    </Card>
                                    <Card caption='Player Transactions'>
                                        <Search
                                            model='reportPlayerTransactionsByProvider'
                                            resultModel='resultPlayerTransactionsByProvider'
                                            showRecordsPerPageSelection={true}
                                            data={this.props.reportPlayerTransactionsByProvider}
                                            onSubmit={(data, qb) => { return apiResult('reportPlayerTransactionsByProvider', Endpoints.PlayerProviderTransactionsDetails, qb, 'fetching player revenues reports', 'unable to fetch report'); }}
                                        />
                                    </Card>
                                    <Card caption='First Transactions'>
                                        <Search
                                            model={searchFirstTransactions}
                                            resultModel={firstTransactionSummary}
                                            data={this.props.firstTransactions}
                                            onSubmit={(data, qb) => {
                                                return apiResult('firstTransactions', Endpoints.SearchFirstTransactions, qb, 'fetching player revenues reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>

                                    {/* Retail Transactions */}
                                    <Card caption='Retail Account Summary'>
                                        <Search
                                            model={retailAccountSummary}
                                            resultModel={retailAccountSummaryReportResult}
                                            data={this.props.retailAccountSummary}
                                            showPagination={false}
                                            onSubmit={(data, qb) => {
                                                return apiResult('retailAccountSummary',
                                                    Endpoints.RetailAccountSummary, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Retail Transactions'>
                                        <Search
                                            model={retailTransactionsReport}
                                            resultModel={retailTransactionsReportResult}
                                            data={this.props.retailTransactionsReport}
                                            onSubmit={(data, qb) => {
                                                return apiResult('retailTransactionsReport',
                                                    Endpoints.RetailTransactionsReport, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Retail Player Transactions'>
                                        <Search
                                            model={retailPlayerTransactionsReport}
                                            resultModel={retailPlayerTransactionsReportResult}
                                            data={this.props.retailPlayerTransactionsReport}
                                            onSubmit={(data, qb) => {
                                                return apiResult('retailPlayerTransactionsReport',
                                                    Endpoints.RetailPlayerTransactionsReport, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>

                                    {/* Bonus */}
                                    <Card caption='Bonus Transactions'>
                                        <Search
                                            model='searchPlayerBonusTransactions'
                                            resultModel='playerBonusTransactions'
                                            data={this.props.playerBonusTransactions}
                                            onSubmit={(data, qb) => {
                                                return apiResult('playerBonusTransactions',
                                                    Endpoints.SearchBonusTransactions, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Player Bonus'>
                                        <Search
                                            model='searchPlayerBonusReports'
                                            resultModel='playerBonusReports'
                                            data={this.props.playerBonus}
                                            onSubmit={(data, qb) => {
                                                return apiResult('playerBonus',
                                                    Endpoints.SearchPlayerBonusProgress, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Player Bonus Summary'>
                                        <Search
                                            model='searchPlayerBonusReports'
                                            resultModel='playerBonusSummary'
                                            data={this.props.playerBonusSummary}
                                            onSubmit={(data, qb) => {
                                                return apiResult('playerBonusSummary',
                                                    Endpoints.SearchPlayerBonusSummary, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Completed Bonus Payouts'>
                                        <Search
                                            model='searchCompletedBonusPayouts'
                                            resultModel='completedBonusPayouts'
                                            data={this.props.completedBonusPayouts}
                                            onSubmit={(data, qb) => {
                                                return apiResult('completedBonusPayouts',
                                                    Endpoints.SearchCompletedBonusPayouts, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Completed Bonus Payouts Summary'>
                                        <Search
                                            model='searchCompletedBonusPayoutsSummary'
                                            resultModel='completedBonusPayoutsSummary'
                                            data={this.props.completedBonusPayoutsSummary}
                                            onSubmit={(data, qb) => {
                                                return apiResult('completedBonusPayoutsSummary',
                                                    Endpoints.SearchCompletedBonusPayoutsSummary, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <Card caption='Removed Bonuses'>
                                        <Search
                                            model='searchRemovedBonusPayoutsSummary'
                                            resultModel='removedBonusSummary'
                                            data={this.props.removedBonusReports}
                                            onSubmit={(data, qb) => {
                                                return apiResult('removedBonusReports',
                                                    Endpoints.SearchRemovedBonusReports, qb, 'fetching reports', 'unable to fetch report');
                                            }}
                                        />
                                    </Card>
                                    <FinanceReport />
                                </CentrumApplicationContents>
                            </React.Fragment>;

                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication >
        );
    }

}

ReportsPage.defaultProps = {
    action: null
};

ReportsPage.propTypes = {
    action: PropTypes.object,
    loading: PropTypes.bool
};


const mapStateToProps = state => {
    return {
        loading: state.data.loading,
        casinoReportResult: state.data.casinoReportResult,
        casinoProviderReport: state.data.casinoProviderReport,
        casinoGamesReport: state.data.casinoGamesReport,
        playerGameRevenuesReportResult: state.data.playerGameRevenuesReportResult,
        playerGroupsGameRevenuesReportResult: state.data.playerGroupsGameRevenuesReportResult,
        casinoProviderCommissionResult: state.data.casinoProviderCommissionResult,

        dailyRevenuesSummaryReport: state.data.dailyRevenuesSummaryReport,
        sportBettingRevenuesReport: state.data.sportBettingRevenuesReport,
        sportBettingPlayerRevenuesReport: state.data.sportBettingPlayerRevenuesReport,
        sportPlayerGroupRevenues: state.data.sportPlayerGroupRevenues,
        sportCategoryRevenueSummaryReport: state.data.sportCategoryRevenueSummaryReport,
        sportRegionRevenueSummaryReport: state.data.sportRegionRevenueSummaryReport,
        sportLeagueRevenueSummaryReport: state.data.sportLeagueRevenueSummaryReport,
        sportEventRevenueSummaryReport: state.data.sportEventRevenueSummaryReport,
        sportMarketRevenueSummaryReport: state.data.sportMarketRevenueSummaryReport,
        sportSelectionRevenueSummaryReport: state.data.sportSelectionRevenueSummaryReport,
        sportPlayerRevenueSummaryReport: state.data.sportPlayerRevenueSummaryReport,
        sportTicketsOfEvent: state.data.sportTicketsOfEvent,
        playersWithoutBetsResult: state.data.playersWithoutBetsResult,
        eventRevenueReport: state.data.eventRevenueReport,



        operatorRevenuesReport: state.data.operatorRevenuesReport,
        providerRevenuesReport: state.data.providerRevenuesReport,

        playerReport: state.data.playerReport,
        playerGamesReport: state.data.playerGamesReport,

        providerTransactionsReport: state.data.providerTransactionsReport,
        playerTransactionsOfSelectedProvider: state.data.playerTransactionsOfSelectedProvider,

        horseGreyhoundSummaryReport: state.data.horseGreyhoundSummaryReport,
        horseGreyHoundRegions: state.data.horseGreyHoundRegions,
        horseGreyHoundVenues: state.data.horseGreyHoundVenues,
        horseGreyHoundDistance: state.data.horseGreyHoundDistance,
        horseGreyHoundPlayers: state.data.horseGreyHoundPlayers,
        horseTickets: state.data.horseTickets,


        transactionsAccountSummary: state.data.transactionsAccountSummary,
        firstTransactions: state.data.firstTransactions,
        reportPlayerTransactionsByProvider: state.data.reportPlayerTransactionsByProvider,


        retailTransactionsReport: state.data.retailTransactionsReport,
        retailPlayerTransactionsReport: state.data.retailPlayerTransactionsReport,
        retailAccountSummary: state.data.retailAccountSummary,

        playerBonus: state.data.playerBonus,

        playerBonusSummary: state.data.playerBonusSummary,
        playerBonusTransactions: state.data.playerBonusTransactions,
        completedBonusPayouts: state.data.completedBonusPayouts,
        completedBonusPayoutsSummary: state.data.completedBonusPayoutsSummary,
        removedBonusReports: state.data.removedBonusReports
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({

    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
export { hoc as ReportsPage };