import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Panel } from '../../../Centrum/Card/Card';
import { Table } from '../../../Controls/Table/Table';
import { Card } from '../../../Centrum/Card/Card';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Button } from '../../../Controls/Buttons/Button';
import { openSportsBettingConfiguration } from './SportsBettingConfiguration';
import { CurrencyMinMaxConfiguration } from './CurrencyMinMaxConfiguration';

const slipTypes = [
    { value: 0, display: 'Prematch' },
    { value: 1, display: 'Live' },
    { value: 2, display: 'Combined (Prematch&Live)' }
];

const ticketTypes = [
    { value: 0, display: 'Single' },
    { value: 1, display: 'Multi' },
    { value: 2, display: 'System' }
];



const rangeModel = {
    fields: [
        {
            name: 'slipType',
            display: 'Type',
            type: 'select',
            readOnly: true,
            values: slipTypes,
            formatter: (value) => {
                return slipTypes.find(x => x.value == value).display;
            }
        },
        {
            name: 'ticketType',
            display: 'Ticket Type',
            type: 'select',
            readOnly: true,
            values: ticketTypes,
            formatter: (value) => {
                return ticketTypes.find(x => x.value == value).display;
            }
        },
        {
            name: 'selection',
            display: 'Minimum Selection',
            type: 'number'
        },
        {
            name: 'minOdds',
            display: 'Min Odds',
            type: 'number'
        },
        {
            name: 'odds',
            display: 'Max Odds',
            type: 'number'
        },
        {
            name: 'enabled',
            display: 'Enabled',
            type: 'bool'
        }
    ]
};

const defaultOddsConfig = [
    {
        slipType: 0,
        ticketType: 0,
        selection: 1,
        minOdds: 0,
        odds: 0,
        enabled: false
    },
    {
        slipType: 0,
        ticketType: 1,
        selection: 2,
        minOdds: 0,
        odds: 0,
        enabled: false
    },
    {
        slipType: 1,
        ticketType: 0,
        selection: 1,
        minOdds: 0,
        odds: 0,
        enabled: false
    },
    {
        slipType: 1,
        ticketType: 1,
        selection: 2,
        minOdds: 0,
        odds: 0,
        enabled: false
    },
    {
        slipType: 2,
        ticketType: 1,
        selection: 2,
        minOdds: 0,
        odds: 0,
        enabled: false
    }
];

class OddsPayoutConfiguration extends React.Component {
    constructor(props) {
        super(props);
        var configuration = Object.assign([], props.configuration);
        this.state = { configuration: configuration };
        if (!this.state.configuration || Object.keys(this.state.configuration).length == 0) {
            this.state.configuration = Object.assign([], defaultOddsConfig);
        }
    }

    save() {
        return this.state.configuration;
    }

    render() {
        return <React.Fragment>
            <Table model={rangeModel} data={this.state.configuration}
                key={this.state.selectedCurrency}
                inlineEdit={true}
                inlineNewRow={false}
                deleteEnabled={false}
                editableFields={'enabled'}
                editable={true} buttons_backup={(row) => {
                    return <Button title='Configure' onClick={() => {
                        openSportsBettingConfiguration(row);
                    }} />;
                }} />
        </React.Fragment>;
    }
}

OddsPayoutConfiguration.defaultProps = {
    configuration: defaultOddsConfig
};

OddsPayoutConfiguration.propTypes = {
    configuration: PropTypes.array
};


export const SportsRules = (props) => {
    const [sports, saveSports] = useState(Object.assign({}, props.configuration ? props.configuration : {
        bet: {},
        stake: {},
        enabled: false
    }));
    var betsTable = null;


    const stakeConfiguration = sports.stake ? sports.stake : {};
    const context = {
        save: () => {
            var sportsCopy = {
                bet: betsTable.save(),
                cashout: sports.cashout,
                enabled: sports.enabled,
                stake: sports.stake
            };


            saveSports(sportsCopy);
            return sportsCopy;
        }
    };

    useEffect(() => {
        props.context(context);
    });


    const update = (target, value) => {
        var copy = { ...sports };
        copy[target] = value;
        saveSports(copy);
    };


    return <div className='padding'>
        <Form data={sports}>
            <div className='row'>
                <FormInput name='enabled' title='Enable Sports' type='bool' onChange={(f, r, value) => {
                    saveSports({
                        enabled: value,
                        cashout: sports.cashout,
                        bet: sports.bet
                    });
                }} />
            </div>
        </Form>
        <Card caption='Cashout' className='margin-top'>
            <Form data={sports}>
                <div className='row'>
                    <FormInput title='Allow Cashout' name='cashout' type={'bool'} value={sports.cashout}
                        onChange={(f, r, value) => {
                            sports.cashout = value;
                        }} />
                </div>
            </Form>
        </Card>
        <Panel title='Bet Configuration' className={'margin-top ' + (sports.enabled ? '' : 'disabled')}>
            <OddsPayoutConfiguration ref={(r) => betsTable = r} configuration={sports.bet} />
        </Panel>
        <Card caption='Stake Configuration' className='margin-top'>
            <CurrencyMinMaxConfiguration
                key={stakeConfiguration}
                data={stakeConfiguration}
                fieldTitles={{ From: 'Min', To: 'Max' }}
                onUpdate={(data) => update('stake', data)} />
        </Card>

    </div>;
};

SportsRules.propTypes = {
    configuration: PropTypes.object,

    context: PropTypes.func
};

