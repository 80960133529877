import './_kpiValue.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ChartRanges } from '../../../../Centrum/Charts/CentrumLineChart';
import { lang, currency } from '../../../../Centrum/Localization/lang';
import { LabelValue } from '../LabelValue/LabelValue';
import { Icon } from '../../../../Controls/Icon/Icon';


// COMPONENT
export class KPIValue extends React.Component {
    constructor(props) {
        super(props);
    }


    formatValue(value, index) {
        var formatted = value;
        var type = this.props.indicatorTypes[index];
        if (type == 'money') {
            formatted = currency(value, 2, this.props.currency);
        }

        return formatted;
    }

    onClick() {
        this.props.onClick(this.props.range);
    }

    render() {
        var changeIcon = 'angle-double-up';

        if (this.props.change == 'down') {
            changeIcon = 'angle-double-down';
        }

        var range = '';
        var rangeBefore = '';

        switch (this.props.range) {
            case ChartRanges.Today:
                range = lang('Today');
                rangeBefore = lang('Yesterday');
                break;
            case ChartRanges.WTD:
                range = lang('This week');
                rangeBefore = lang('Last week');
                break;
            case ChartRanges.MTD:
                range = lang('This month');
                rangeBefore = lang('Last month');
                break;
            case ChartRanges.QTD:
                range = lang('Since last quarter');
                rangeBefore = lang('Last quarter');
                break;
            case ChartRanges.YTD:
                range = lang('Since last year');
                rangeBefore = lang('Last year');
                break;
        }
        var value = this.props.value;

        if (this.props.type == 'money') {
            value = currency(value, 2, this.props.currency);
        }

        return <div className={'kpiValue ' + this.props.change + ' ' + (this.props.loading ? 'kpi-loading' : '')}>
            <div className='value'><Icon icon={changeIcon} /><span>{this.props.onClick ? <a href='' onClick={(e) => { e.preventDefault(); this.onClick(); }}>{value}</a> : value}</span><span className='trend'>{'%' + (this.props.trend ? this.props.trend.toFixed(2) : 0.00)}</span></div>
            <div className='details'>
                <div className='seperator'><span>{lang(range)}</span></div>
                <div className='values'>
                    {
                        this.props.indicators.map((indicator, index) => {
                            return <LabelValue key={index} title={indicator}>{!this.props.loading && this.formatValue(this.props.values[1][index], index)}</LabelValue>;
                        })
                    }
                </div>
            </div>
            <div className='details'>
                <div className='seperator'><span>{lang(rangeBefore)}</span></div>
                <div className='values'>
                    {
                        this.props.indicators.map((indicator, index) => {
                            return <LabelValue key={index} title={indicator}>{!this.props.loading && this.formatValue(this.props.values[0][index], index)}</LabelValue>;
                        })
                    }
                </div>
            </div>

        </div>;
    }
}

KPIValue.defaultProps = {
    loading: true,
    type: '',

    indicators: [],
    indicatorTypes: [],

    value: '',
    values: [],

    change: '',
    trend: 0,
    currency: null,
    range: ChartRanges.Today,

    onClick: null
};

KPIValue.propTypes = {
    loading: PropTypes.bool,

    type: PropTypes.string,

    indicators: PropTypes.array,
    indicatorTypes: PropTypes.array,

    value: PropTypes.number,
    values: PropTypes.array,

    change: PropTypes.string,
    trend: PropTypes.number,
    currency: PropTypes.string,
    range: PropTypes.number,

    onClick: PropTypes.func
};

