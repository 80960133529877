import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../../v2/Lib/Common/language';


export const AutoCompleteGame = (props) => {
    const games = props.games;
    const [foundGames, setFoundGames] = useState([]);
    const [timeoutRef, setTimeOutRef] = useState(null);

    return <div className='flex vertical gap-5'>
        <div className='flex gap-5'>
            <label>{lang('Search Games')}</label>
            <input onChange={(e) => {
                var value = e.target.value;
                var foundResult = [];
                if (value.length > 2) {
                    clearTimeout(timeoutRef);
                    setTimeOutRef(setTimeout(() => {
                        foundResult = games.filter(x => x.name.toLowerCase().indexOf(value) >= 0);
                        setFoundGames(foundResult);
                    }, 200));
                } else {
                    clearTimeout(timeoutRef);
                    if (foundGames.length > 0) setFoundGames([]);
                }
            }} />
        </div>
        {foundGames.length > 0 && <div className='flex padding border radius surface low'>
            <div className='flex vertical gap-5'>{
                foundGames.map(x => {
                    return <div className='flex gap-5 clickable' key={x.gameId} onClick={() => {
                        props.onSelected(x);
                        setFoundGames([]);
                    }}>{x.name}</div>;
                })
            }
            </div>
        </div>
        }
    </div>;
};

AutoCompleteGame.propTypes = {
    games: PropTypes.array,
    onSelected: PropTypes.func
};

