import React from 'react';
import PropTypes from 'prop-types';
import { ChartRanges } from '../../../Centrum/Charts/CentrumLineChart';

const RangeNames = {
    0: 'Today',
    1: 'This week',
    2: 'This month',
    3: 'Last Quarter',
    4: 'This Year',
    5: 'Past 24 Hours',
    6: 'Past 7 Day',
    7: 'Past 15 Day',
    8: 'Past 30 Day',
    9: 'Custom'
};

/*
export class RangeDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
        this.selected = props.selected;
    }

    render() {
        return <Dropdown className={this.props.className} position='right' selectedIndex={this.props.selected} onSelected={(index)=>{if (this.selected!==index) this.props.onSelected(index); this.selected=index;}}>
            {
                Object.keys(ChartRanges).map((item, index) => {
                    return <div key={index}>{RangeNames[index]}</div>;
                })
            }
        </Dropdown>;
    }
}
*/

export const RangeDropdown = (props) => {
    return <div {...props}>
        <select defaultValue={props.selected} onChange={(e) => {
            props.onSelected(parseInt(e.target.value));
        }}>
            {
                Object.keys(ChartRanges).map((item, index) => {
                    return <option key={index} value={index}>{RangeNames[index]}</option>;
                })
            }
        </select>
    </div>;
};

RangeDropdown.defaultProps = {
    className: '',
    selected: 0,
    onSelected: () => { }
};

RangeDropdown.propTypes = {
    className: PropTypes.string,
    selected: PropTypes.number,
    onSelected: PropTypes.func
};
