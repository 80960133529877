import { CentrumComponent } from '../CentrumComponent';
import React from 'react';
import PropTypes from 'prop-types';

import './_card.scss';
import { lang } from '../Localization/lang';
import { ApplicationContext, ComponentTypes } from '../Application/CentrumApplication';
import { Icon } from '../../Controls/Icon/Icon';



export class Card extends CentrumComponent {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.props);
    }

    getName() {
        return this.props.name;
    }

    renderCollapsible() {
        var collapsible = this.props.collapsible;
        if (collapsible) {
            return <div className='collapsible-button'><Icon icon={this.state.collapsed ? 'angle-down' : 'angle-up'} className='alignLeft' onClick={() => this.setState({ collapsed: !this.state.collapsed })} /></div>;
        }
    }

    renderChildren() {
        if (this.props.collapsible) {
            return <div className={'collapsible' + (this.state.collapsed ? ' collapsed' : '')}>
                {this.props.children}
            </div>;
        }

        return this.props.children;
    }

    render() {
        var menu = null;
        if (this.props.menu) {
            menu = <div className='control'>{this.props.menu}</div>;
        }
        return (
            <ApplicationContext.Consumer>
                {
                    (appContext) => {
                        if (appContext) appContext.registerComponent(ComponentTypes.Card, this);
                        return <div className={'centrum-card ' + this.props.className} style={this.props.style}>
                            {this.props.caption != null && <h1 className='title'>{this.props.icon}{lang(this.state.caption)}{menu}{this.renderCollapsible()}</h1>}
                            {this.renderChildren()}
                        </div>;
                    }
                }
            </ApplicationContext.Consumer>
        );
    }
}

Card.defaultProps = {
    className: '',
    name: '',
    caption: null,

    children: null,
    icon: null,
    menu: null,

    collapsible: false,
    collapsed: false,

    style: {},
    autoChangeCaption: true
};

Card.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    caption: PropTypes.node,

    children: PropTypes.node,
    icon: PropTypes.node,
    menu: PropTypes.node,

    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,

    style: PropTypes.object,
    autoChangeCaption: PropTypes.bool
};








export class MiniCard extends CentrumComponent {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.props);
    }


    renderChildren() {
        return this.props.children;
    }

    render() {
        var menu = null;
        if (this.props.menu) {
            menu = <div className='control'>{this.props.menu}</div>;
        }
        return (
            <ApplicationContext.Consumer>
                {
                    (appContext) => {
                        if (appContext) appContext.registerComponent(ComponentTypes.Card, this);
                        return <div className={'centrum-card ' + this.props.className} style={this.props.style}>
                            {this.props.caption != null && <h1 className='title'>{this.props.icon}{this.state.caption}</h1>}
                            {menu}
                            {this.renderChildren()}
                        </div>;
                    }
                }
            </ApplicationContext.Consumer>
        );
    }
}

MiniCard.defaultProps = {
    className: '',
    name: '',
    caption: null,

    children: null,
    icon: null,
    menu: null,

    collapsible: false,
    collapsed: false,

    style: {},
};

MiniCard.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    caption: PropTypes.node,

    children: PropTypes.node,
    icon: PropTypes.node,
    menu: PropTypes.node,

    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,

    style: PropTypes.object
};


export class Panel extends CentrumComponent {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.props);
    }
    render() {
        return <div className={'panel ' + this.props.className}>
            {this.props.title && <header><title><h1 className='title'>{this.props.title}</h1></title>{this.props.buttons}</header>}
            {this.props.children}
        </div>;
    }
}

Panel.defaultProps = {
    className: '',
    title: null
};

Panel.propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    buttons: PropTypes.node,
    className: PropTypes.string
};
