/* eslint-disable react/display-name */
import './_mainDashboard.scss';

import React, { useEffect, useState } from 'react';

import { Page } from '../../../Centrum/Page/Page';



import { CentrumApplication } from '../../../Centrum/Application/CentrumApplication';
import { Card } from '../../../Centrum/Card/Card';
import { Table } from '../../../Controls/Table/Table';
import { KPICard } from '../components/KPICard/KPICard';
import { KPIRangeSelector, KPIRangeContext } from '../components/KPIRangeSelector/KPIRangeSelector';
import { lang } from '../../../Centrum/Localization/lang';
import { Icon } from '../../../Controls/Icon/Icon';
import { API, DashboardEndponts } from '../../../../v2/Lib/Api/Api';
import { SportsTicketTypes } from '../../../../v2/Components/Sports/Tickets/BettingEnums';
import { openBetsOfTicket } from '../../RiskManagement/models';
import { currency } from '../../../../v2/Lib/Common/currency';




export const MainDashboard = () => {
    const [kpiRangeContext, setKPIRangeContext] = useState(KPIRangeContext('summary'));
    const [data, setData] = useState({
        playersSummary: {
            totalPlayers: 0
        },
        gameSessions: [],
        openTickets: [],
        newTickets: [],
        topSportPlayers: [],
        liabilitySummary: []
    });


    console.log(data.liabilitySummary);
    useEffect(() => {
        var dashboarData = {
            gameSessions: {}
        };

        API.post(DashboardEndponts.TotalPlayers, null).then((result) => {
            dashboarData.playersSummary = result.result;
            API.post(DashboardEndponts.GameSessions, null).then((result) => {
                dashboarData.gameSessions = result.result;
                API.post(DashboardEndponts.MostPlayedGames, null).then((result) => {
                    dashboarData.gameSummary = result.result;

                    API.post(DashboardEndponts.TopSportTicketBets, null).then((result) => {
                        dashboarData.topSportTicketBets = result.result;
                        API.post(DashboardEndponts.TopSportWins, null).then((result) => {
                            dashboarData.topSportWins = result.result;
                            API.post(DashboardEndponts.NewTickets, null).then((result) => {
                                dashboarData.newTickets = result.result;
                                API.post(DashboardEndponts.OpenTickets, null).then((result) => {
                                    dashboarData.openTickets = result.result;

                                    API.post(DashboardEndponts.TopSportPlayers, null).then((result) => {
                                        dashboarData.topSportPlayers = result.result;
                                        API.post(DashboardEndponts.Liability, null).then((result) => {
                                            dashboarData.liabilitySummary = result.result;
                                            setData(dashboarData);
                                        });
                                    });
                                });
                            });

                        });

                    });
                });
            });

        });

    }, []);

    const showNewPlayers = () => {

    };

    const showGGR = () => {

    };

    const showDepositsAndWithdraws = () => {

    };

    return <Page>
        <CentrumApplication caption='Dashboard' icon='dashboard'>
            <div className='application-contents'>
                <div className='application-content dashboard flex vertical gap-5'>
                    <KPIRangeSelector context={kpiRangeContext} title='KPI Summary' onContextChange={(index) => {
                        kpiRangeContext.changeRange(index);
                        setKPIRangeContext(kpiRangeContext);
                    }} />
                    <div className='flex gap-10 fit'>
                        <div className='flex gap-5 fit'>
                            <KPICard
                                context={kpiRangeContext} title='New Players'
                                indicators={['Players']}
                                url='/api/dashboard/main/players'
                                icon={<Icon icon={'user-alt'} />}
                                onClick={() => showNewPlayers()}
                            />
                            <KPICard
                                context={kpiRangeContext} title='GGR'
                                indicators={['Bets', 'Wins']}
                                indicatorTypes={['money', 'money']}
                                url='/api/dashboard/main/ggr'
                                type='money'
                                icon={<Icon icon={'coins'} />}
                                onClick={() => showGGR()}
                            />
                        </div>
                        <div className='flex gap-5 fit'>
                            <KPICard
                                context={kpiRangeContext} title='Deposit' indicators={['Deposits']}
                                url='/api/dashboard/main/deposits'
                                indicatorTypes={['money', 'money']}
                                type='money'
                                icon={<Icon icon={'hand-holding-usd'} />}
                                onClick={() => showDepositsAndWithdraws()}
                            />
                            <KPICard
                                context={kpiRangeContext} title='Withdraw' indicators={['Withdraws']}
                                url='/api/dashboard/main/withdraws'
                                indicatorTypes={['money', 'money']}
                                type='money'
                                icon={<Icon icon={'money-check-alt'} />}
                                onClick={() => showDepositsAndWithdraws()}
                            />
                        </div>
                    </div>
                    <div className='flex gap-5 fit'>
                        <div className='flex vertical gap-10'>
                            <Card caption='Most played games today' className='align-top'>
                                <Table
                                    model={{
                                        fields: [
                                            {
                                                name: 'totalHands', display: 'Total Hands'
                                            },
                                            {
                                                name: 'gameName', display: 'Game'
                                            },
                                            {
                                                name: 'bet', display: 'Bet', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            },
                                            {
                                                name: 'win', display: 'Win', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            },
                                            {
                                                name: 'ggr', display: 'GGR', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            }
                                        ]
                                    }}
                                    data={data.gameSummary ?? []}
                                />
                            </Card>
                            <Card caption='Top 5 Sport Betting Highets Stakes' className='align-top'>
                                <Table
                                    model={{
                                        fields: [
                                            {
                                                name: 'externalTicketId', display: 'Ticket Id', formatter: (val, row) => {
                                                    return openBetsOfTicket(val, {
                                                        Product: 0,
                                                        ExternalTicketId: row.externalTicketId,
                                                        TicketType: row.ticketType
                                                    });
                                                }
                                            },
                                            {
                                                name: 'username', display: 'Username'
                                            },
                                            {
                                                name: 'ticketType', display: 'Ticket Type', formatter: (val) => {
                                                    return SportsTicketTypes[val];
                                                }
                                            },
                                            {
                                                name: 'bet', display: 'Bet', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            },
                                            {
                                                name: 'odds', display: 'Odds', type: 'number', formatter: (val, row) => {
                                                    return row.odds.toFixed(2);
                                                }
                                            },
                                            {
                                                name: 'possibleWin', display: 'Possible Win', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            },

                                        ]
                                    }}
                                    data={data.topSportTicketBets ?? []}
                                />
                            </Card>
                            <Card caption='Top 5 Sport Betting Winners' className='align-top'>
                                <Table
                                    model={{
                                        fields: [
                                            {
                                                name: 'externalTicketId', display: 'Ticket Id', formatter: (val, row) => {
                                                    return openBetsOfTicket(val, {
                                                        Product: 0,
                                                        ExternalTicketId: row.externalTicketId,
                                                        TicketType: row.ticketType
                                                    });
                                                }
                                            },
                                            {
                                                name: 'username', display: 'Username'
                                            },
                                            {
                                                name: 'ticketType', display: 'Ticket Type', formatter: (val) => {
                                                    return SportsTicketTypes[val];
                                                }
                                            },
                                            {
                                                name: 'bet', display: 'Bet', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            },
                                            {
                                                name: 'odds', display: 'Odds', type: 'number', formatter: (val, row) => {
                                                    return row.odds.toFixed(2);
                                                }
                                            },
                                            {
                                                name: 'win', display: 'Win', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            },

                                        ]
                                    }}
                                    data={data.topSportWins}
                                />
                            </Card>
                            <Card caption='Top 10 Sports Betting Players' className='align-top'>
                                <Table
                                    model={{
                                        fields: [
                                            {
                                                name: 'username', display: 'Username'
                                            },

                                            {
                                                name: 'total', display: 'Total Tickets'
                                            },
                                            {
                                                name: 'bet', display: 'Bet', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            },
                                            {
                                                name: 'possibleWin', display: 'Possible Win', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            },
                                            {
                                                name: 'payout', display: 'Payout', type: 'number', formatter: (val, row) => {
                                                    return currency(val, row.currency, 2);
                                                }
                                            }

                                        ]
                                    }}
                                    data={data.topSportPlayers ?? []}
                                />
                            </Card>
                        </div>
                        <div className='flex vertical gap-10 align-start'>
                            <Card caption='Players' icon={<Icon icon={'wallet'} />}>
                                <div className='flex vertical gal-5 padding'>
                                    <div className='flex gap-5'>
                                        <label>{lang('Total')}</label>
                                        <span className='align-right'>{data.playersSummary.totalPlayers}</span>
                                    </div>
                                    <div className='flex gap-5'>
                                        <label>{lang('Online')}</label>
                                        <span className='align-right'>{data.playersSummary.onlinePlayers}</span>
                                    </div>
                                </div>
                            </Card>
                            <Card caption='Liability Summary' icon={<Icon icon={'wallet'} />}>
                                <div className='flex vertical gal-5 padding'>
                                    <div className='flex gap-5'>
                                        <label>{lang('Balances')}</label>
                                        <span className='align-right'>{currency(data.liabilitySummary.balances, data.liabilitySummary.currency, 2)}</span>
                                    </div>
                                    <div className='flex gap-5'>
                                        <label>{lang('Pending Withdraws')}</label>
                                        <span className='align-right'>{currency(Math.abs(data.liabilitySummary.pendingWithdraws), data.liabilitySummary.currency, 2)}</span>
                                    </div>
                                </div>
                            </Card>
                            <div className='flex gap-10 fit'>
                                <Card caption='Casino Hands' icon={<Icon icon={'wallet'} />}>
                                    <div className='flex gap-10 padding fit'>
                                        <div className='flex vertical gap-5'>
                                            <div className='flex gap-5'>
                                                <label>{lang('Slot')}</label>
                                                <span className='align-right'>{data.gameSessions.slots}</span>
                                            </div>
                                            <div className='flex gap-5'>
                                                <label>{lang('Live')}</label>
                                                <span className='align-right'>{data.gameSessions.live}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                <Card caption='Casino GGR' icon={<Icon icon={'wallet'} />}>
                                    <div className='flex gap-10 padding fit'>
                                        <div className='flex vertical gap-5'>
                                            <div className='flex gap-5'>
                                                <label>{lang('Slot')}</label>
                                                <span className='align-right'>{currency(data.gameSessions.slotsGGR, 'EUR', 2, false)}</span>
                                            </div>
                                            <div className='flex gap-5'>
                                                <label>{lang('Live')}</label>
                                                <span className='align-right'>{currency(data.gameSessions.liveGGR, 'EUR', 2, false)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>

                            <div className='flex gap-10 fit'>
                                <Card caption='New Tickets' icon={<Icon icon={'wallet'} />}>
                                    <div className='flex gap-10 padding fit'>
                                        <div className='flex vertical gap-5'>
                                            <div className='flex gap-5'>
                                                <label>{lang('Total')}</label>
                                                <span className='align-right'>{data.newTickets.total}</span>
                                            </div>
                                            <div className='flex gap-5'>
                                                <label>{lang('Total Stake')}</label>
                                                <span className='align-right'>{data.newTickets.bet}</span>
                                            </div>
                                            <div className='flex gap-5'>
                                                <label>{lang('Possible Win')}</label>
                                                <span className='align-right'>{data.newTickets.possibleWin}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                <Card caption='Open Tickets' icon={<Icon icon={'wallet'} />}>
                                    <div className='flex gap-10 padding fit'>
                                        <div className='flex vertical gap-5'>
                                            <div className='flex gap-5'>
                                                <label>{lang('Total')}</label>
                                                <span className='align-right'>{data.openTickets.total}</span>
                                            </div>
                                            <div className='flex gap-5'>
                                                <label>{lang('Total Stake')}</label>
                                                <span className='align-right'>{data.openTickets.bet}</span>
                                            </div>
                                            <div className='flex gap-5'>
                                                <label>{lang('Possible Win')}</label>
                                                <span className='align-right'>{data.openTickets.possibleWin}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </CentrumApplication>
    </Page>;
};
