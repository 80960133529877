import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';


var __socketContext = null;

export const getMonitorSocket = () => {
    return __socketContext;
};

export const getWSUri = (operatorId, relUri) => {
    var uri = new URL(relUri, window.location.href);

    return (uri.protocol === 'https:' ? 'wss' : 'ws') + `://t${operatorId}ws.appslnd.net/ws` + uri.search;

    //return 'ws://localhost:2121/ws' + uri.search;
};



var pingInterval = null;

export const BetMonitorSocket = (props) => {
    const user = useSelector((state) => state.user.loginInformation);

    const [socket, createSocket] = useState(null);
    const [retryCount, setRetryCount] = useState(0);

    const dispatch = useDispatch();


    __socketContext = {
        send: send,
        message: (a, b) => message(a, b)
    };

    useEffect(() => {
        connect();
        return () => {
            clearInterval(pingInterval);
        };
    }, []);

    useEffect(() => {
        if (!socket) return;
        window.ss = socket;
        socket.onopen = () => {
            if (pingInterval) clearInterval(pingInterval);
            pingInterval = setInterval(() => {
                console.log('sending ping');
                send('p');
            }, 10000);
            authenticate();
        };
        socket.onerror = () => {
            clearInterval(pingInterval);
            setTimeout(() => {
                handleSocketExceptions();
            }, 1000);
        };
        socket.onclose = () => {
            clearInterval(pingInterval);
            setTimeout(() => {
                handleSocketExceptions();
            }, 1000);
        };
        socket.onmessage = (message) => onMessageReceived(message);
    }, [socket]);

    useEffect(() => {
        if (!retryCount) return;
        let timeOut = retryCount * 1000;
        setTimeout(() => {
            connect();
        }, timeOut);
    }, [retryCount]);

    const handleSocketExceptions = () => {
        dispatch({ type: 'CONNECTION_LOST', payload: null });
        setRetryCount(!retryCount ? 1 : retryCount + 1);
    };
    const connect = () => {
        createSocket(new WebSocket(getWSUri(user.UserPrivileges.PointOfView, `/?&token=${user.Token}`)));
    };

    const authenticate = () => {
        message('authenticate', user.lastToken);
    };

    const message = (type, data) => {
        const msg = JSON.stringify({ type: type, data: data });
        send(msg);
    };

    const send = (msg) => {
        if (!socket) return;
        if (socket.readyState != 1) return;
        try {
            socket.send(msg);
        } catch (err) {
            //
            console.log(err);
        }
    };

    const onMessageReceived = (raw) => {
        if (raw.data == 'p') return;
        const message = JSON.parse(raw.data);
        switch (message.type) {
            case 'authenticate':
                if (message.payload) {
                    props.onConnected({
                        send: (o) => {
                            send(JSON.stringify(o));
                        },
                        message: (a, b) => message(a, b)
                    });
                }
                break;
            case 'new':
                props.onNewTicket(message.payload);
                break;
            case 'auto-reject':
                props.onRemoveTicket(message.payload);
                break;
            default:
                console.log(message);
                break;
        }
    };

    return <React.Fragment />;
};

BetMonitorSocket.propTypes = {
    onConnected: PropTypes.func,
    onNewTicket: PropTypes.func,
    onRemoveTicket: PropTypes.func
};