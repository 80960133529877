export const volumeSummary = (state) => {
    try {
        var data = state.data;
        var vSummary = [];
        var bSummary = [];
        var convertedAmount = {
            Currency: state.currency,
            Prematch: 0,
            LiveStake: 0,
            Liability: 0,
            LiveLiability: 0
        };


        Object.keys(data.TicketVolumes?.Stakes).map(c => {
            vSummary.push({
                Currency: c,
                Prematch: data.TicketVolumes.Stakes[c].Prematch,
                LiveStake: data.TicketVolumes.Stakes[c].Live,
                Liability: data.TicketVolumes.Liabilities[c].Prematch,
                LiveLiability: data.TicketVolumes.Liabilities[c].Live
            });


            convertedAmount.Prematch += data.TicketVolumes.Stakes[c].Prematch / state.rate;
            convertedAmount.LiveStake += data.TicketVolumes.Stakes[c].Live / state.rate;
            convertedAmount.Liability += data.TicketVolumes.Liabilities[c].Prematch / state.rate;
            convertedAmount.LiveLiability += data.TicketVolumes.Liabilities[c].Live / state.rate;
        });


        vSummary.key = bSummary.key = Date.now();
        state.volumesSummary = vSummary;

        bSummary.push({
            Title: 'Prematch',
            Total: data.TicketVolumes.Slips.Prematch
        });
        

        bSummary.push({
            Title: 'Live',
            Total: data.TicketVolumes.Slips.Live
        });
        

        bSummary.push({
            Title: 'Total',
            Total: data.TicketVolumes.Slips.Prematch+data.TicketVolumes.Slips.Live
        });
        




        state.betSummary = bSummary;
    } catch (err) {
        console.log(err);
    }


    return { ...state };
};


export const selectCurrency = (selectedCurrency, state) => {
    try {
        state.currency = selectedCurrency;
    } catch (err) {
        console.log(err);
    }

    return { ...state };
};

export const prepareVolumesData = (state) => {
    if (!state.data) return state;
    var data = state.data;

    state = selectCurrency(state.currency ?? data?.DefaultCurrency, state);
    state = volumeSummary(state);

    var eventList = [];
    var sportList = {};
    var regionList = {};
    var leagueList = {};

    var eventLimits = data.EventLimits;
    var leagueLimits = data.LeagueLimits;
    var sportLimits = data.SportLimits;
    if (!eventLimits) eventLimits = {};
    if (!leagueLimits) leagueLimits = {};
    if (!sportLimits) sportLimits = {};
    
    Object.values(data.Events).sort((a, b) => { return a.Name < b.Name ? -1 : 1; }).map(event => {
        eventList.push({ id: event.Id, name: event.Name });
        sportList[event.SportId] = event.Sport;
        regionList[event.RegionId] = event.Region;
        leagueList[event.LeagueId] = event.League;

        var maxStakeLimit = 0;
        var maxStakeLimitLive = 0;
        var maxLiabilityLimit = 0;
        var maxLiabilityLimitLive = 0;

        var eventLimit = eventLimits[event.Id];
        var leagueLimit = leagueLimits[event.LeagueId];
        var sportLimit = sportLimits[event.SportId];
        if (!sportLimit) sportLimit = sportLimits[0];
        if (eventLimit && eventLimit.General) {
            maxStakeLimit = eventLimit.General.Stake.Prematch;
            maxStakeLimitLive = eventLimit.General.Stake.Live;

            maxLiabilityLimit = eventLimit.General.Liability.Prematch;
            maxLiabilityLimitLive = eventLimit.General.Liability.Live;
        }

        // take stake and liabilities from leagues if values are not set
        if (!maxStakeLimit && leagueLimit) {
            maxStakeLimit = leagueLimit.General.Stake.Prematch;
        }
        if (!maxStakeLimitLive && leagueLimit) {
            maxStakeLimitLive = leagueLimit.General.Stake.Live;
        }

        if (!maxLiabilityLimit && leagueLimit) {
            maxLiabilityLimit = leagueLimit.General.Liability.Prematch;
        }
        if (!maxStakeLimitLive && leagueLimit) {
            maxLiabilityLimitLive = leagueLimit.General.Liability.Live;
        }
        //


        // take stake and liabilities from sports if values are not set
        if (!maxStakeLimit && sportLimit) {
            maxStakeLimit = sportLimit.General.Stake.Prematch;
        }
        if (!maxStakeLimitLive && sportLimit) {
            maxStakeLimitLive = sportLimit.General.Stake.Live;
        }

        if (!maxLiabilityLimit && sportLimit) {
            maxLiabilityLimit = sportLimit.General.Liability.Prematch;
        }
        if (!maxStakeLimitLive && sportLimit) {
            maxLiabilityLimitLive = sportLimit.General.Liability.Live;
        }
        //


        event.MaxStakeLimit = maxStakeLimit;
        event.MaxStakeLimitLive = maxStakeLimitLive;

        event.MaxLiabilityLimit = maxLiabilityLimit;
        event.MaxLiabilityLimitLive = maxLiabilityLimitLive;

        event.DefaultCurrency = data.DefaultCurrency;
    });

    state.sports = sportList;
    state.regions = regionList;
    state.leagues = leagueList;

    state.events = eventList;
    state.key = data.key;

    state.volumes = data;
    return { ...state };
};