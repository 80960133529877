import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CentrumForm } from '../Centrum/CentrumForm/CentrumForm';
import './_emailTemplate.scss';
import { Page } from '../Centrum/Page/Page';
class EmailTemplate extends Page {

    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    addTerms(event) {

        if (event.target.tagName == 'A') {
            event.preventDefault();
            var insertText = '[' + event.target.href.split('/').pop() + ']';
            this.insertAtCaret('body', insertText);
        }
    }

    insertAtCaret(areaId, text) {
        var txtarea = document.getElementById(areaId);
        var scrollPos = txtarea.scrollTop;
        var strPos = 0;
        var range;
        var br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
            'ff' : (document.selection ? 'ie' : false));
        if (br == 'ie') {
            txtarea.focus();
            range = document.selection.createRange();
            range.moveStart('character', -txtarea.value.length);
            strPos = range.text.length;
        }
        else if (br == 'ff') strPos = txtarea.selectionStart;

        var front = (txtarea.value).substring(0, strPos);
        var back = (txtarea.value).substring(strPos, txtarea.value.length);
        txtarea.value = front + text + back;
        strPos = strPos + text.length;
        if (br == 'ie') {
            txtarea.focus();
            range = document.selection.createRange();
            range.moveStart('character', -txtarea.value.length);
            range.moveStart('character', strPos);
            range.moveEnd('character', 0);
            range.select();
        }
        else if (br == 'ff') {
            txtarea.selectionStart = strPos;
            txtarea.selectionEnd = strPos;
            txtarea.focus();
        }
        txtarea.scrollTop = scrollPos;
        var tempModel = { ...this.state.emailTemplateModel };
        tempModel.Body = txtarea.value;
        this.setState({ emailTemplateModel: tempModel });
    }


    inputChangedHandler = (event) => {
        var tempModel = { ...this.state.emailTemplateModel };
        switch (event.target.id) {
            case 'name':
                tempModel.Name = event.target.value;
                break;
            case 'title':
                tempModel.Title = event.target.value;
                break;
            case 'body':
                tempModel.Body = event.target.value;
                break;
        }

        this.setState({ emailTemplateModel: tempModel });
    }

    onSubmit(button) {
        this.props.onSubmit(button, this.state.emailTemplateModel);
    }

    onFieldValueChanged(field, value) {
        if (field) {
            var tempModel = { ...this.state.emailTemplateModel };
            tempModel[field.name] = value;
            this.setState({ emailTemplateModel: tempModel });
        }
    }

    render() {
        if (this.state.actionType === 'preview') {
            return <React.Fragment ><div dangerouslySetInnerHTML={{ __html: this.state.emailTemplateModel.Body }}></div></React.Fragment>;
        } else {
            return (
                <CentrumForm
                    type=''
                    enabled={true}
                    buttons={this.state.actions}
                    className='default col2'
                    model={this.state.modelName}
                    data={this.state.emailTemplateModel}
                    onButtonClicked={this.onSubmit.bind(this)}
                    onFieldValueChanged={this.onFieldValueChanged.bind(this)}
                >
                    <div className='row'>
                        <div className='row padding-AutoSubstutions'>
                            <div>
                                <label>Auto-substitution:</label>
                            </div>
                            <div className='centrum-termsBox' id='terms' onClick={this.addTerms.bind(this)}>
                                <a href='site_url' className='btn btn-outline-secondary ' >Site url</a>
                                <a href='site_name' className='btn btn-outline-secondary'>Site name</a>
                                <a href='support_email' className='btn btn-outline-secondary'>WLC support email</a>
                                <a href='first_name' className='btn btn-outline-secondary' >Firstname</a>
                                <a href='last_name' className='btn btn-outline-secondary' >Lastname</a>
                                <a href='email' className='btn btn-outline-secondary' >User e-mail</a>
                                <a href='password' className='btn btn-outline-secondary'>User password</a>
                                <a href='reset_password_url' className='btn btn-outline-secondary'>Password reset url</a>
                                <a href='user_balance' className='btn btn-outline-secondary'>User balance</a>
                                <a href='user_last_deposit' className='btn btn-outline-secondary'>User Last deposit</a>
                                <a href='user_last_withdraw' className='btn btn-outline-secondary'>User last withdraw</a>
                                <a href='user_currency' className='btn btn-outline-secondary'>User currency</a>
                                <a href='restore_url' className='btn btn-outline-secondary'>Password restore link</a>
                                <a href='change_profile' className='btn btn-outline-secondary'>Change profile</a>
                                <a href='registration_url' className='btn btn-outline-secondary'>Email verification link</a>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='form-group'>
                                <label>Body : </label>
                                <textarea onChange={(event) => this.inputChangedHandler(event)} id='body' defaultValue={this.state.emailTemplateModel.Body} rows='28' placeholder='Enter your mail template body' className='form-control' ></textarea>
                            </div>
                        </div>

                    </div>


                </CentrumForm>
            );
        }

    }
}

EmailTemplate.defaultProps = {
    onSubmit: null,
    actions: [],
    modelName: '',
    emailTemplateModel: {},
    postDataModel: {},
};

EmailTemplate.propTypes = {
    emailTemplateModel: PropTypes.object,
    modelName: PropTypes.string,
    actions: PropTypes.array,
    actionType: PropTypes.string,
    onSubmit: PropTypes.func,
    postDataModel: PropTypes.object,
};
const mapStateToProps = state => {
    return {
        loading: state.marketing.loading,
        loaded: state.marketing.loaded,
        failed: state.marketing.failed,
        templates: state.marketing.templates,
        error: state.marketing.error,
        showModal: state.marketing.showModal
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
export { hoc as EmailTemplate };