import { defaultPending, defaultFulfilled, listerInitial, defaultRejected } from './ApplicationReducer';
import {
    SEARCH_SPORT_CATEGORIES_PENDING,
    SEARCH_SPORT_CATEGORIES_FULFILLED,
    SEARCH_SPORT_CATEGORIES_REJECTED,

    SEARCH_SPORT_REGIONS_PENDING,
    SEARCH_SPORT_REGIONS_FULFILLED,
    SEARCH_SPORT_REGIONS_REJECTED,

    SEARCH_SPORT_LEAGUES_PENDING,
    SEARCH_SPORT_LEAGUES_FULFILLED,
    SEARCH_SPORT_LEAGUES_REJECTED,

    SEARCH_SPORT_COMPETITORS_PENDING,
    SEARCH_SPORT_COMPETITORS_FULFILLED,
    SEARCH_SPORT_COMPETITORS_REJECTED,

    SEARCH_SPORT_EVENTS_PENDING,
    SEARCH_SPORT_EVENTS_FULFILLED,
    SEARCH_SPORT_EVENTS_REJECTED,
    LOAD_SPORT_EVENT_MARKETS_PENDING,
    LOAD_SPORT_EVENT_MARKETS_FULFILLED,
    LOAD_SPORT_EVENT_MARKETS_REJECTED,
    LOAD_SPORT_MARKET_TYPES_PENDING,
    LOAD_SPORT_MARKET_TYPES_FULFILLED,
    LOAD_SPORT_MARKET_TYPES_REJECTED,
    LOAD_SPORT_MARKETS_PENDING,
    LOAD_SPORT_MARKETS_FULFILLED,
    LOAD_SPORT_MARKETS_REJECTED,
} from '../actions/SportsbookActions';


const initialState = {
    categories: listerInitial,
    regions: listerInitial,
    leagues: listerInitial,
    competitors: listerInitial,
    events: listerInitial,
    eventMarkets: {},
    marketTypes: listerInitial,
    markets: listerInitial,
    loading: false,
    loaded: false,
    failed: false
};

// REDUCER
export const SportsbookReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_SPORT_CATEGORIES_PENDING:
            return defaultPending('categories', state);
        case SEARCH_SPORT_CATEGORIES_FULFILLED:
            return defaultFulfilled('categories', state, action);
        case SEARCH_SPORT_CATEGORIES_REJECTED:
            return defaultRejected('categories', state, action);

        case SEARCH_SPORT_REGIONS_PENDING:
            return defaultPending('regions', state);
        case SEARCH_SPORT_REGIONS_FULFILLED:
            return defaultFulfilled('regions', state, action);
        case SEARCH_SPORT_REGIONS_REJECTED:
            return defaultRejected('regions', state, action);

        case SEARCH_SPORT_LEAGUES_PENDING:
            return defaultPending('leagues', state);
        case SEARCH_SPORT_LEAGUES_FULFILLED:
            return defaultFulfilled('leagues', state, action);
        case SEARCH_SPORT_LEAGUES_REJECTED:
            return defaultRejected('leagues', state, action);

        case SEARCH_SPORT_COMPETITORS_PENDING:
            return defaultPending('competitors', state);
        case SEARCH_SPORT_COMPETITORS_FULFILLED:
            return defaultFulfilled('competitors', state, action);
        case SEARCH_SPORT_COMPETITORS_REJECTED:
            return defaultRejected('competitors', state, action);

        case SEARCH_SPORT_EVENTS_PENDING:
            return defaultPending('events', state);
        case SEARCH_SPORT_EVENTS_FULFILLED:
            return defaultFulfilled('events', state, action);
        case SEARCH_SPORT_EVENTS_REJECTED:
            return defaultRejected('events', state, action);

        case LOAD_SPORT_EVENT_MARKETS_PENDING:
            return defaultPending('eventMarkets', state);
        case LOAD_SPORT_EVENT_MARKETS_FULFILLED:
            return defaultFulfilled('eventMarkets', state, action);
        case LOAD_SPORT_EVENT_MARKETS_REJECTED:
            return defaultRejected('eventMarkets', state, action);


        case LOAD_SPORT_MARKET_TYPES_PENDING:
            return defaultPending('marketTypes', state);
        case LOAD_SPORT_MARKET_TYPES_FULFILLED:
            return defaultFulfilled('marketTypes', state, action);
        case LOAD_SPORT_MARKET_TYPES_REJECTED:
            return defaultRejected('marketTypes', state, action);


        case LOAD_SPORT_MARKETS_PENDING:
            return defaultPending('markets', state);
        case LOAD_SPORT_MARKETS_FULFILLED:
            return defaultFulfilled('markets', state, action);
        case LOAD_SPORT_MARKETS_REJECTED:
            return defaultRejected('markets', state, action);
        default:
            return state;
    }
};