import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Search } from '../../../../Centrum/Search/Search';
import { apiResult, Endpoints } from '../../../../Application/Application';
import { InputTypes } from '../../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { TransactionTypes } from '../../common';
import { DateRanges } from '../../../../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';
import { currency } from '../../../../Centrum/Localization/lang';



export const searchTransactionsModel = {
    fields: [
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'Type',
            display: 'Type',
            type: InputTypes.select,
            values: TransactionTypes,
            acceptEmptyValues: true
        },
        {
            name: 'Code',
            display: 'Code'
        }
    ]
};

export const searchTransactionsWithRetailModel = {
    fields: [
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges['This Month']
            }
        },
        {
            name: 'Type',
            display: 'Type',
            type: InputTypes.select,
            values: TransactionTypes,
            acceptEmptyValues: true
        },
        {
            name: 'Code',
            display: 'Code'
        }
    ]
};

export const transactionsModel = {
    fields: [
        {
            name: 'Id',
            display: 'Id'
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'RetailName',
            display: 'Retail Name'
        },
        {
            name: 'OperatorUser',
            display: 'Admin'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'Type',
            type: InputTypes.select,
            values: TransactionTypes
        },
        {
            name: 'Username'
        },
        {
            name: 'PlayerCode'
        },
        {
            name: 'Amount',
            display: 'Amount',
            formatter: (val, row, isFooter, isExporting) => {
                if (isFooter) {
                    // this is an object
                    if (Object.keys(val).length > 0) {
                        return Object.keys(val).map(key => {
                            return <p key={key}>{currency(val[key], 2, key)}</p>;
                        });
                    } else {
                        if (isExporting) return val;
                        return currency(val, 2, row.Currency, false);
                    }
                } else {
                    if (isExporting) return val;
                    return currency(val, 2, row.Currency, false);
                }
            }
        },
        {
            name: 'BalanceBefore',
            display: 'Balance Before',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'Balance',
            display: 'Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        }
    ]
};



const RetailTransactions = (props) => {
    return <div>
        <Search
            model={searchTransactionsModel}
            resultModel={transactionsModel}
            data={props.transactions}
            exportToExcel={true}
            onSubmit={(data, qb) => {
                return apiResult('retailTransactions', `${Endpoints.ListRetailTransactions}/${props.retail.Id}`,
                    qb, 'fetching transactions', 'unable to fetch transactions');
            }}
        />
    </div>;
};

RetailTransactions.propTypes = {
    retail: PropTypes.object,
    transactions: PropTypes.objcet
};



const mapStateToProps = state => {
    return {
        transactions: state.data.retailTransactions
    };
};
const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(RetailTransactions);
export { hoc as RetailTransactions };