import './_game.scss';

import React from 'react';
import PropTypes from 'prop-types';




export const Game = (props) => {
    const game = props.games?.find(x => x.gameId == props.gameId) ?? {};

    const renderDetails = () => {
        return <div className='flex vertical gap-5 fit'>
            <div className='flex fit'>
                <label>Id</label>
                <span className='align-right'>{game.gameId}</span>
            </div>
            <div className='flex vertical'>
                <label>Name</label>
                <span>{game.name}</span>
            </div>
            <div className='flex vertical fit'>
                <label>Provider</label>
                <span>{game.providerName}</span>
            </div>
            <div className='flex vertical fit'>
                <label>Tag</label>
                <span>{game.tag != '' ? game.tag : '-'}</span>
            </div>

            {props.children}
        </div>;
    };

    if (!game) return <div className='padding warning'>game not found!</div>;

    var src = 'http://d.tentangle.com/game/' + game.imageName;
    return <div className='flex vertical gap-5 padding border surface low fit'>
        <img src={src} />
        {props.details && renderDetails()}
    </div>;
};

Game.propTypes = {
    gameId: PropTypes.object,
    details: PropTypes.bool,
    games: PropTypes.array,
    children: PropTypes.node
};


