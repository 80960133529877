import './_depositBonus.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { BonusGeneralSettings, BonusDepositSettings, BonusPlayerSettings, BonusPromotionCodeSettings } from '../Components/BonusComponents';
import { Tab } from '../../../Centrum/Tab/Tab';
import { application, Endpoints } from '../../../Application/Application';
import { Payout } from '../Components/Payout';
import { Turnover } from '../Components/Turnover';
import { SportsRules } from '../Components/SportsRules';
import { RacingRules } from '../Components/RacingRules';

export class DepositBonus extends React.Component {
    constructor(props) {
        super(props);
        var state = Object.assign({}, props);
        state.multiplers = {};
        for (var i = 0; i < 100; i++) {
            state.multiplers[i] = 'x' + i;
        }

        state.promotionCodeSettings = {
            ConsumeOnlyOnce: state.promotion.ConsumeOnlyOnce
        };

        state.promotion.Configuration = (typeof state.promotion.Configuration === 'object' && state.promotion.Configuration !== null) ? state.promotion.Configuration : JSON.parse(state.promotion.Configuration);


        if (!state.promotion.Configuration) {
            state.promotion.Configuration = {
                payout: null,
                turnover: null,
                deposit: {},
                sport: {
                    enabled: false,
                }
            };
        }


        this.state = state;
    }


    componentDidMount() {
        application().resolve(null, Endpoints.GetPromotionPlayerGroups + '/' + (this.state.promotion.Id), {}, 'fetching promotion player groups', 'unable to fetch promotion player groups').then((groups) => {
            var converted = [];
            converted.push({ value: null, display: 'All' });
            groups.result.map(x => {
                converted.push({ display: x.name, value: x.id });
            });

            this.setState({ loaded: true, playerGroups: converted });
        });

    }


    save() {
        var promotion = this.generalSettings.save();
        var result = Object.assign(promotion, {});

        var playerSettings = this.playerSettings.save();

        result = Object.assign(playerSettings, result);
        result.Configuration.deposit = this.depositSettings.save();

        result.Configuration.sport = this.sportSettings.save();
        result.Configuration.racing = this.racingSettings.save();
        result.Configuration.turnover = this.turnoverSettings.save();
        result.Configuration.payout = this.payoutSettings.save();

        result.DeductBonusAmount = result.Configuration.turnover.deductDepositAmount;
        result.Configuration = JSON.stringify(result.Configuration);

        result.ConsumeOnlyOnce = this.state.promotionCodeSettings.ConsumeOnlyOnce;

        return result;
    }

    render() {
        if (!this.state.loaded) return <div />;
        var tabButtons = [
            {
                title: 'General'
            },
            {
                title: 'Player Settings'
            },
            {
                title: 'Bonus Code'
            },
            {
                title: 'Bonus Credit'
            },
            {
                title: 'Sports'
            },
            {
                title: 'Racing'
            },
            {
                title: 'Turnover Configuration'
            },
            {
                title: 'Payout'
            }
        ];
        return <React.Fragment>
            <Tab buttons={tabButtons}>
                <BonusGeneralSettings promotion={this.state.promotion} ref={(r) => this.generalSettings = r} />
                <BonusPlayerSettings promotion={this.state.promotion} ref={(r) => this.playerSettings = r} />
                <BonusPromotionCodeSettings promotion={this.state.promotionCodeSettings} onUpdate={(data) => {
                    this.setState({ promotionCodeSettings: data });
                }} />
                <BonusDepositSettings promotion={this.state.promotion.Configuration.deposit} promotionId={this.state.promotion.Id} ref={(r) => this.depositSettings = r} />
                <SportsRules configuration={this.state.promotion.Configuration.sport} context={(context) => this.sportSettings = context} />
                <RacingRules configuration={this.state.promotion.Configuration.racing} context={(context) => this.racingSettings = context} />
                <Turnover turnover={this.state.promotion.Configuration.turnover} context={(context) => this.turnoverSettings = context} />
                <Payout payout={this.state.promotion.Configuration.payout} context={(context) => this.payoutSettings = context} />
            </Tab >
        </React.Fragment >;
    }
}

DepositBonus.propTypes = {
    promotion: PropTypes.object
};

