/* eslint-disable react/display-name */
import './_betmonitor-risk-levels.scss';
import React, { useEffect, useState } from 'react';
import { lang } from '../../../../v2/Lib/Common/language';
import { Card } from '../../../../v2/Components/Card/Card';
import { Tab } from '../../../Centrum/Tab/Tab';
import { LiabilityMonitor } from './LiabilityMonitor/LiabilityMonitor';
import { MonitorTickets } from './MonitorTickets/MonitorTickets';
//import { ConfigureRiskEvents } from './ConfigureEvents/ConfigureRiskEvents';





export const LabelValue = (props) => {
    const { label, value } = { ...props };
    return <div className='flex gap-5'>
        <label>{lang(label)}</label>
        <span>{value}</span>
    </div>;
};

export const BetMonitorRiskLevels = () => {
    const [tabButtons, setTabButtons] = useState([]);
    useEffect(() => {
        setTabButtons([
            {
                name: 'tickets',
                title: 'Tickets'
            },
            {
                name: 'liability',
                title: 'Liability '
            },
            /*
            {
                name: 'events',
                title: 'Events '
            },
            {
                name: 'players',
                title: 'Players '
            }
            */
        ]);
    }, []);
    if (!tabButtons.length) return <></>;
    return <Card title='Monitor Risk Levels'>
        <Tab buttons={tabButtons}>
            <MonitorTickets />
            <LiabilityMonitor />
            {/*
            <ConfigureRiskEvents />
            <ConfigureRiskEvents />
            */}
        </Tab>
    </Card>;
};