import { postData } from '../services/ServiceHelpers';
import { Endpoints }  from '../../components/Application/Application';

export const SEARCH_SHOPS = 'SEARCH_SHOPS';
export const SEARCH_SHOPS_PENDING = 'SEARCH_SHOPS_PENDING';
export const SEARCH_SHOPS_FULFILLED = 'SEARCH_SHOPS_FULFILLED';
export const SEARCH_SHOPS_REJECTED = 'SEARCH_SHOPS_REJECTED';

export const SEARCH_SHOP_TRANSACTIONS = 'SEARCH_SHOP_TRANSACTIONS';
export const SEARCH_SHOP_TRANSACTIONS_PENDING = 'SEARCH_SHOP_TRANSACTIONS_PENDING';
export const SEARCH_SHOP_TRANSACTIONS_FULFILLED = 'SEARCH_SHOP_TRANSACTIONS_FULFILLED';
export const SEARCH_SHOP_TRANSACTIONS_REJECTED = 'SEARCH_SHOP_TRANSACTIONS_REJECTED';

export const GET_SHOP = 'GET_SHOP';
export const GET_SHOP_PENDING = 'GET_SHOP_PENDING';
export const GET_SHOP_FULFILLED = 'GET_SHOP_FULFILLED';
export const GET_SHOP_REJECTED = 'GET_SHOP_REJECTED';

// ACTION CREATORS
export function searchShops(query) {
    return {
        type: SEARCH_SHOPS,
        payload: postData(query, Endpoints.ShopSearch)
    };
}


export function searchShopTransactions(query) {
    return {
        type: SEARCH_SHOP_TRANSACTIONS,
        payload : postData(query,Endpoints.ShopSearchTransactions)
    };
}



export function getShop(id) {
    return {
        type: GET_SHOP,
        payload : postData({},Endpoints.ShopGet + id)
    };
}


