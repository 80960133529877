import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { DateRanges } from '../../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes } from '../../Centrum/_models/ModelEnums';


export const searchEventsOfOpenSlipsModel = {
    fields: [
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,
            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Yesterday
            }
        },
        {
            name: 'Sport',
            display: 'Sports',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'SportCategories',
                bindingKey: 'id',
                bindingText: ['name']
            }
        },
        {
            name: 'EventId',
            display: 'Event Id'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            values: {
                '0': 'Only Open Tickets',
                '10': 'Refund Tickets',
                '0,10': 'All'
            }
        },

    ]
};



export const eventsOfOpenSlipsModel = {
    fields: [
        {
            name: 'Sport',
            display: 'Sport'
        },
        {
            name: 'EventId',
            display: 'Event Id'
        },
        {
            name: 'Event',
            display: 'Event'
        },
        {
            name: 'EventDate',
            display: 'Event Date',
            type: InputTypes.dateTime
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'TotalStake',
            display: 'Stake'
        },
        {
            name: 'Liability',
            display: 'Possible Win'
        },
        {
            name: 'TotalSlips',
            display: 'Total Slips'
        },
        {
            name: 'TotalRefunded',
            display: 'Total Refunded'
        }
    ]
};


export const searchManualSettledTicketsModel = {
    fields: [
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id'
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,

            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'EventId',
            display: 'Event Id',
            type: InputTypes.number
        },
        {
            name: 'Type',
            display: 'Betting Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            display: 'Ticket Type',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: SportsTicketStatus
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        }
    ]
};
