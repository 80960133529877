import React, { useState } from 'react';
import { Card } from '../../../v2/Components/Card/Card';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { Form } from '../../../v2/Components/Controls/Form/Form';
import { FormInput } from '../../../v2/Components/Controls/Form/FormInput';
import { API, SportEndpoints } from '../../../v2/Lib/Api/Api';

export const EventRecovery = () => {
    const [eventId, setEventId] = useState(null);
    return <Card title='Event Recovery'>
        <Form>
            <div className='row'>
                <FormInput title='Event Id' onChange={(value) => {
                    setEventId(value);
                }} />
                <Button title='Recover' onClick={() => {
                    API.post(`${SportEndpoints.RecoverEvent}/${eventId}`, null, 'please wait').then((result)=>{
                        console.log(result);
                    });
                }} />
            </div>
        </Form>
    </Card>;
};