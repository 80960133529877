import React from 'react';
import PropTypes from 'prop-types';
import { Currencies } from '../../../Centrum/_models/ModelEnums';
import { Table } from '../../../Controls/Table/Table';
import { Tab } from '../../../Centrum/Tab/Tab';
import { API, DataEndpoints } from '../../../../v2/Lib/Api/Api';



export class CurrencyMinMaxConfiguration extends React.Component {
    constructor(props) {
        super(props);
        var state = Object.assign({}, props);
        this.state = state;
        var editableFields = state.editableFields ? state.editableFields.split(',') : [];

        if (!state.model) {
            state.model = [
                {
                    name: 'From',
                    display: state.fieldTitles ? state.fieldTitles.From : 'From',
                    type: 'number'
                },
                {
                    name: 'To',
                    display: state.fieldTitles ? state.fieldTitles.To : 'To',
                    type: 'number'
                }
            ];
        }

        if (props.fields) {
            state.model = state.model.concat(props.fields);
            props.fields.map(x => {
                editableFields.push(x.Name);
            });
        }

        if (!state.currencies) {
            state.currencies = Object.assign({}, Currencies);
        }

        state.editableFields = editableFields.join(',');

        try {
            if (typeof state.data === 'object') {
                state.data = props.data;
            } else {
                state.data = JSON.parse(props.data);
            }
        } catch (e) {
            state.data = {};
        }

        if (!state.data) state.data = {};

        this.state = state;
    }

    componentDidMount() {
        API.post(DataEndpoints.EnabledCurrencies).then((result) => {
            var currencies = {};
            result.result.map(x => {
                currencies[x.CurrencyCode] = x.Name;
            });

            var data = this.state.data;
            result.result.map(x => {
                if (!data.hasOwnProperty(x.CurrencyCode)) {
                    delete data[x.CurrencyCode];
                }
            });
            this.setState({ currencies: currencies, selectedCurrency: window.user.features.currency, data: data });
        });
    }

    save() {
        return this.state.data;
    }

    getCurrencyData(currency) {
        if (!currency)
            return [];
        var data = this.state.data[currency];
        if (!data)
            return [];
        return data;
    }


    render() {
        var tabButtons = Object.keys(this.state.currencies).map(key => {
            return { title: key };
        });


        return <div className='full-width'>
            <Tab buttons={tabButtons}
                className={'tab transparent ' + this.props.className}
                selected={Object.keys(this.state.currencies).findIndex(x => x == this.state.selectedCurrency)}
                onTabSelected={(index) => this.setState({ selectedCurrency: Object.keys(this.state.currencies)[index] })}
            />
            <Table
                model={{ fields: this.state.model }}
                key={this.state.selectedCurrency}
                inlineEdit={true}
                inlineNewRow={this.props.newRow}
                deleteEnabled={this.props.deleteEnabled}
                data={this.getCurrencyData(this.state.selectedCurrency)}
                editable={true}
                editableFields={this.state.editableFields}
                onDataChange={(field, row, rows) => {
                    var data = this.state.data;
                    data[this.state.selectedCurrency] = rows;
                    this.setState({ data: data });
                    if (this.props.onUpdate) {
                        this.props.onUpdate(data);
                    }
                }} />
        </div>;
    }
}

CurrencyMinMaxConfiguration.defaultProps = {
    className: '',
    model: null,
    data: '',
    newRow: true,
    deleteEnabled: true,
    currencies: null,
    promotion: null,
    configurationKey: '',
    selectedCurrency: 'USD'
};

CurrencyMinMaxConfiguration.propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    fields: PropTypes.array,
    data: PropTypes.object,
    newRow: PropTypes.bool,
    deleteEnabled: PropTypes.bool,
    currencies: PropTypes.object,
    promotion: PropTypes.object,
    configurationKey: PropTypes.string,
    selectedCurrency: PropTypes.string,
    onUpdate: PropTypes.func
};

