import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditableTable } from '../../Controls/Table/Editable/EditableTable';
import { Card } from '../../Centrum/Card/Card';
import { Button } from '../../Controls/Buttons/Button';
import { application, Endpoints } from '../../Application/Application';

export const SetupCategories = (props) => {
    const [data, setData] = useState(props.categories);
    const [weights, setWeights] = useState(null);

    if (!props.categories) return <div></div>;

    useEffect(() => {
        application().resolve(null, Endpoints.SetupSportCategories).then((result) => {
            try {
                setWeights(JSON.parse(result.result));
            } catch (err) {
                //
            }
        });
    }, []);

    useEffect(() => {
        if (!weights) return;
        data.map(x => {
            if (weights.hasOwnProperty(x.id)) {
                x.weight = weights[x.id].weight;
                x.enabled = weights[x.id].enabled;
            } else {
                x.enabled = false;
            }
        });

        var sorted = Object.assign([], data).sort((b, a) => { return a.weight - b.weight; });

        sorted.key = Date.now();
        setData(sorted);
    }, [weights]);

    const save = () => {
        var categories = {};
        data.map((x, index) => {
            x.weight = data.length - index;
            categories[x.id] = x;
        });


        setWeights(data);

        application().resolve(null, Endpoints.SaveSetupSportCategories, categories).then(() => {
            application().snackbar.open('Category entities saved.');
        });
    };

    const sortNames = () => {
        var copy = [...data];
        copy = copy.sort((a, b) => {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        copy.key = Date.now();
        setData(copy);
    };

    const sortTypes = () => {
        var copy = [...data];
        copy = copy.sort((a, b) => {
            return a.id - b.id;
        });
        copy.key = Date.now();
        setData(copy);
    };

    return <Card caption='Manage Categories' menu={
        <div className='flex gap-5'>
            <Button title='Sort Types' onClick={() => sortTypes()} />
            <Button title='Sort Names' onClick={() => sortNames()} />
            <Button title='Save' onClick={() => save()} />
        </div>
    }>
        <EditableTable model={{
            fields: [
                {
                    name: 'id',
                    display: 'Id'
                },
                {
                    name: 'name',
                    display: 'Name'
                },
                {
                    name: 'enabled',
                    display: 'Enabled',
                    type: 'bool',
                    editable: true
                }
            ]
        }} key={data.key} data={data} draggable={true} onDataChange={(data) => setData(data)} />
    </Card>;
};


SetupCategories.propTypes = {
    categories: PropTypes.array
};