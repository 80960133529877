import './_cmsPage.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


import { getActions, ActionTypes } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { application, Endpoints } from '../../Application/Application';
import { GameThumbs } from './GameThumbs/GameThumbs';
import { GameConfigurations } from './GameConfigurations/GameConfigurations';
import { CMSLanguages } from './CMSLanguages/CMSLanguages';
import { CMSPromotions } from './Promotions/CMSPromotions';
import { WebSitePages } from './WebsitePages/WebSitePages';
import { LSportsSportIcons } from './LSportsTeamIcons/LsportsTeamIcons';

const applicationActionType = ActionTypes.CMS;
const applicationActions = getActions(applicationActionType);


// COMPONENT
export const CMSPage = () => {
    const [configuration, setConfiguration] = useState(null);
    const [games, setGames] = useState(null);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        application().resolve(null, Endpoints.CMSGetContents + '/true', { type: 'language', key: 'DefaultLanguage' }, 'retreiving language file', 'unable to fetch language').then((result) => {
            var df = result.result[0].content;
            application().resolve(null, Endpoints.GetCMSSkinConfiguration, {}, 'retreiving skin configuration', 'unable to fetch configuration').then((result) => {
                var configuration = JSON.parse(result.result);
                application().resolve(null, Endpoints.GetCMSGames, {}, 'fetching games please wait', 'unable to fetch games').then((games) => {
                    setConfiguration(configuration);
                    setGames(games);
                    setDefaultLanguage(df);
                    setLoaded(true);
                });
            });
        });

    }, []);

    if (!loaded) return <></>;
    return (
        <CentrumApplication
            caption='CMS' icon='cogs'
            type={applicationActionType}
        >
            <ApplicationContext.Consumer>
                {
                    () => {
                        return <React.Fragment>
                            <CentrumApplicationActions actions={applicationActions} />
                            <CentrumApplicationContents actions={applicationActions} renderAll={false}>
                                <GameThumbs configuration={configuration} games={games} />
                                <GameConfigurations games={games} />
                                <WebSitePages games={games} defaultLanguage={defaultLanguage} />
                                <CMSPromotions defaultLanguage={defaultLanguage} />
                                <CMSLanguages defaultLanguage={defaultLanguage} />
                                <LSportsSportIcons />
                            </CentrumApplicationContents>
                        </React.Fragment>;
                    }
                }
            </ApplicationContext.Consumer>
        </CentrumApplication>
    );
};

CMSPage.defaultProps = {
    configuration: {}
};

CMSPage.propTypes = {
    configuration: PropTypes.object
};

