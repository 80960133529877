import './_user-group-bindings.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../v2/Components/Card/Card';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { API, ConfigurationEndpoints } from '../../../v2/Lib/Api/Api';
import { Switch } from '../../../v2/Components/Controls/Inputs/Switch/Switch';
import { application } from '../../Application/Application';


const UserGroupBinding = (props) => {
    const { group } = props;
    const [bindings, setBindigns] = useState(null);
    const [showEnabled, setShowEnabled] = useState(false);
    const [filter, setFilter] = useState(null);
    useEffect(() => {
        API.post(`${ConfigurationEndpoints.UserGroupBinding}${group.Id}`).then((result) => {
            result.result.update = Date.now();
            setBindigns(result.result);
        });
    }, []);

    if (!bindings) return <></>;
    return <Card title={group.Name} className='border' key={bindings.update} buttons={() => {
        return <div className='flex gap-5'>
            <input type='text' onKeyUpCapture={(e) => {
                if (e.key == 'Enter') {
                    setFilter(e.target.value.toLowerCase());
                }
                if (e.key == 'Escape') {
                    e.target.value = '';
                    setFilter(null);
                }
            }} />
            <Switch title='Show only enabled' checked={showEnabled} onChange={(v) => setShowEnabled(v)} />
            <Button title='Copy Rights' onClick={() => window.userBindingsClipboard = { ...bindings }} />
            <Button title='Paste Rights' className={window.userBindingsClipboard ? '' : 'disabled'} onClick={() => {
                const copy = { ...window.userBindingsClipboard };
                window.userBindingsClipboard = null;
                copy.update = Date.now();
                setBindigns(copy);
            }} />
            <Button title='Save' onClick={() => {
                let copy = { ...bindings };
                delete copy.update;
                API.post(ConfigurationEndpoints.UpdateUserGroupBindings + group.Id, copy).then(() => {
                    application().snackbar.open('User group bindings updated');
                });

            }} />
        </div>;
    }}>
        <div className='col-2 grid' key={bindings.update}>
            {
                Object.keys(bindings).sort((a, b) => a > b ? 1 : -1).map((binding) => {
                    if (binding == 'update') return;
                    if (filter) {
                        if (binding.toLowerCase().indexOf(filter) == -1) {
                            return;
                        }
                    }
                    if (showEnabled) {
                        if (!Object.values(bindings[binding]).find(x => x === true)) {
                            return;
                        }
                    }
                    const element = <Card title={binding} key={binding} className='border'>
                        <table>
                            <tbody>
                                {Object.keys(bindings[binding]).sort((a, b) => a > b ? 1 : -1).map(x => {
                                    return <tr key={x}>
                                        <td>{x}</td>
                                        <td>
                                            <Switch checked={bindings[binding][x] === true} onChange={(v) => {
                                                let copy = { ...bindings };
                                                copy[binding][x] = v;
                                                setBindigns(copy);
                                            }} />
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </Card>;

                    return element;
                })
            }
        </div>
    </Card>;
};

UserGroupBinding.propTypes = {
    group: PropTypes.object
};

export const UserGroupsConfiguration = () => {
    const groupTypes = {
        0: 'Operator',
        1: 'Virtual Retails',
        2: 'Retails'
    };

    const [selectedGroupType, selectGroupType] = useState(null);
    const [selectedGroup, selectGroup] = useState(null);
    const [userGroups, setUserGroups] = useState(null);

    useEffect(() => {
        API.post(ConfigurationEndpoints.UserGroups, {}).then((result) => {
            setUserGroups(result.result.sort((a, b) => b.GroupType - b.GroupType));
        });
    }, []);

    useEffect(() => {
        if (!userGroups) return;
        if (selectedGroupType == null) {
            selectGroupType(0);
        }
    });

    useEffect(() => {
        if (!userGroups) return;
        if (selectedGroupType == null) return;
        selectGroup(userGroups.filter(x => x.GroupType == selectedGroupType)[0]);
    }, [selectedGroupType]);

    const renderGroup = () => {
        if (!selectedGroup) return;
        return <UserGroupBinding group={selectedGroup} key={selectedGroup.Id} />;
    };


    if (!userGroups) return <></>;
    if (!selectedGroup) return <></>;
    const tabs = userGroups.filter(group => !group.SuperGroup).filter(x => x.GroupType == selectedGroupType).map(group => {
        return <Button title={group.Name} key={group.Id} onClick={() => selectGroup(group)} className={selectedGroup == group ? 'selected' : ''}></Button>;
    });

    return <Card title='User Groups' className='user-group-bindings' buttons={() => {
        const elements = Object.keys(groupTypes).map(x => {
            return <Button title={groupTypes[x]} key={x} onClick={() => selectGroupType(x)} className={selectedGroupType == x ? 'active' : ''} />;
        });

        return <div className='flex gap-5'>
            {elements}
        </div>;
    }}>
        <div className='tab'>
            <div className='tab-buttons'>
                {tabs}
            </div>
            <div className='tab-contents'>
                {renderGroup()}
            </div>
        </div>
    </Card>;
};