// IMPORT PACKAGE REFERENCES

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { currency } from './Centrum/Localization/lang';


var socket = null;

export const getLiveConnection = () => {
    return socket;
};


class FrontendSocket extends React.Component {
    constructor(props) {
        super(props);
        this.onConnected = () => {
            console.log(this.pendingMessages);
            this.pendingMessages.map(x => {
                this.sendRaw(x);
            });
            this.pendingMessages = [];
        };
        this.pingInterval = 5000;
        socket = this;
        this.pendingMessages = [];
        this.subscriptions = {};
    }


    subscribe(node, fnc) {
        this.subscriptions[node] = fnc;
        this.sendMessage('subscribe', node, true);
    }

    messageHandler(messageRaw) {
        try {
            if (messageRaw.data == 'p') return;
            var message = JSON.parse(messageRaw.data);
            switch (message.Type) {
                case 'playerBalance':
                    var payload = message.Payload.split(':');
                    var playerId = payload[0];
                    var playerCurrency = payload[1];
                    var balance = payload[2];
                    var className = 'player_balance_' + playerId + '_' + playerCurrency;
                    var elements = document.getElementsByClassName(className);
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements[i];
                        element.innerHTML = currency(balance, 2, playerCurrency);
                    }
                    break;
                case 'subscription':
                    var subscription = JSON.parse(message.Payload);
                    var subscriptionName = subscription.type;
                    var subscriptionData = subscription.data;
                    this.subscriptions[subscriptionName](subscriptionData);
                    break;
                default:
                    console.log(message);
                    break;
            }

        } catch (err) {
            throw (err);
        }
    }

    startPing() {
        clearInterval(this.ping);
        this.ping = setInterval(() => {
            this.sendRaw('p');
        }, this.pingInterval);
    }

    sendRaw(msg) {
        if (!this.connected) return;
        if (!this.connection || this.connection.readyState != 1) return;
        try {
            this.connection.send(msg);
        } catch (error) {
            console.log('can not send message');
            console.log(error);
        }
    }

    connect() {
        var host = window.location.host;
        if (!this.props.user) return;

        var connectionString = (window.location.href.indexOf('https') == 0 ? 'wss://' : 'ws://') + host + '/ws?&token=' + this.props.user.Token;
        this.connected = false;

        try {
            this.connection = new WebSocket(connectionString);
            this.connection.onerror = () => {
                this.connected = false;
            };

            this.connection.onclose = () => {
                this.connected = false;
                setTimeout(() => {
                    this.connect();
                }, 500);
            };

            this.connection.onmessage = (message) => this.messageHandler(message);

            this.connection.onopen = () => {
                console.log('connected');
                this.connected = true;
                this.onConnected();
                this.startPing();
            };
        } catch (error) {
            console.log(error);
        }
    }


    componentDidMount() {
        this.connect();
    }

    componentDidUpdate() {
        if (this.props.user != null) {
            if (!this.loggedIn) {
                this.loggedIn = true;
                this.connect();
            }
        }
    }

    sendMessage(type, data, buffer) {
        if (!this.connection || this.connection.readyState != 1) {
            if (buffer) {
                this.pendingMessages.push(JSON.stringify({ type: type, data: data }));
            }
            return;
        }
        try {
            this.connection.send(JSON.stringify({ type: type, data: data }));
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        return <React.Fragment />;
    }
}

FrontendSocket.defaultProps = {
    user: null
};

FrontendSocket.propTypes = {
    user: PropTypes.object
};

const mapStateToProps = state => {
    return {
        user: state.user.loginInformation
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(FrontendSocket);

// EXPORT COMPONENT
export { hoc as FrontendSocket };