import React from 'react';
import PropTypes from 'prop-types';
import { SliderConfigurator } from '../AppFeatures/AppSlider';



export class AppSportsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, props);
    }

    saveSliderConfiguration(slider) {
        var configuration = this.state.configuration.configuration;
        configuration.slider = slider;
        this.props.onSave(configuration);
    }

    saveBannerConfiguration(banner) {
        var configuration = this.state.configuration.configuration;
        configuration.banner = banner;
        this.props.onSave(configuration);
    }

    render() {
        var configuration = this.state.configuration.configuration;
        return <div className='surface high border'>
            <SliderConfigurator name={'sports'} configuration={configuration?.slider} prefix={this.props.prefix} defaultLanguage={this.props.defaultLanguage} onSave={(data) => {
                var page = this.state.configuration;
                page.configuration.slider = data;
                this.props.onSave(page);
            }} />
        </div>;
    }
}

AppSportsPage.defaultProps = {
    configuration: {},
    defaultLanguage: 'en',
    prefix: '',
    onSave: () => { }
};

AppSportsPage.propTypes = {
    configuration: PropTypes.object,
    defaultLanguage: PropTypes.string,
    prefix: PropTypes.string,
    onSave: PropTypes.func
};