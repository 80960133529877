import './_sportCategoryLimits.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../../Application/Application';
import { Card } from '../../../../Centrum/Card/Card';

import { Button } from '../../../../Controls/Buttons/Button';
import { EditableTable } from '../../../../Controls/Table/Editable/EditableTable';
import { Dialog } from '../../../../Modal/Modals/Dialog/Dialog';
import { currency, lang } from '../../../../Centrum/Localization/lang';
import { Form } from '../../../../Controls/Form/Form';
import { FormInput } from '../../../../Controls/Form/FormInput';
import { Icon } from '../../../../Controls/Icon/Icon';


const categoryModel = {
    fields: [
        {
            name: 'username',
            display: 'Username'
        },
        {
            name: 'code',
            display: 'Code'
        },
        {
            name: 'minimumEvent',
            type: 'string',
            display: 'Minimum Event',
            editable: true
        },
        {
            name: 'marketCap',
            type: 'number',
            display: 'Market Cap',
            editable: true
        },
        {
            name: 'maximumStake',
            type: 'number',
            display: 'Stake Volume',
            editable: true
        },
        {
            name: 'maximumStakeLive',
            type: 'number',
            display: 'Stake Live Volume',
            editable: true
        },
        {
            name: 'maximumLiability',
            type: 'number',
            display: 'Liability',
            editable: true
        },
        {
            name: 'maximumLiabilityLive',
            type: 'number',
            display: 'Live Liability',
            editable: true
        },
        {
            name: 'offer',
            type: 'bool',
            display: 'Offer',
            editable: true
        }
    ]
};



export const SportPlayerLimits = () => {
    const [configurationEnabled, setConfigurationEnabled] = useState(true);
    const [limits, setLimits] = useState([]);

    const [player, showPlayer] = useState(null);

    useEffect(() => {
        const riskType = window.user.features.configuration?.risk ? JSON.parse(window.user.features.configuration.risk).type : { type: 0 };
        if (riskType.type == 1) {
            setConfigurationEnabled(false);
            return;
        }

        application().resolve(null, Endpoints.GetPlayerLimits).then((result) => {
            try {
                var limitsConverted = result.result;
                try {
                    limitsConverted = JSON.parse(limitsConverted);
                } catch (err) {
                    limitsConverted = null;
                }
                var limits = limitsConverted ? limitsConverted : {};

                var sorted = [];


                Object.values(limits).map((x, index) => {
                    var limit = {
                        id: x.id,
                        index: index,
                        username: x.username,
                        code: x.code,
                        minimumEvent: x.minimumEvent,
                        marketCap: x.marketCap,
                        maximumStake: x.stake.prematch,
                        maximumStakeLive: x.stake.live,
                        maximumLiability: x.liability.prematch,
                        maximumLiabilityLive: x.liability.live,
                        offer: x.offer
                    };
                    sorted.push(limit);
                });


                sorted.key = Date.now();
                setLimits(sorted);
            } catch (err) {
                console.log('player limits', err);
            }
        });
    }, []);



    const save = () => {
        var saveData = Object.assign([], limits);
        var data = {};
        saveData.map(x => {
            delete x.index;
            if (x.id == 'undefined' || !x.id) {
                return;
            }

            data[x.id] = {
                id: parseInt(!x.id ? 0 : x.id),
                username: x.username,
                code: x.code,
                minimumEvent: parseInt(x.minimumEvent),
                marketCap: parseInt(!x.marketCap ? 0 : x.marketCap),
                stake: {
                    live: parseInt(!x.maximumStakeLive ? 0 : x.maximumStakeLive),
                    prematch: parseInt(!x.maximumStake ? 0 : x.maximumStake)
                },
                liability: {
                    live: parseInt(!x.maximumLiabilityLive ? 0 : x.maximumLiabilityLive),
                    prematch: parseInt(!x.maximumLiability ? 0 : x.maximumLiability)
                },
                offer: x.offer
            };
        });


        application().resolve(null, Endpoints.SavePlayerLimits, data).then(() => {
            application().modal.open(<Dialog title={'Limits'}>
                {lang('Player limits saved')}
            </Dialog >);
        });
    };


    const deleteLimit = (row) => {
        var copy = Object.assign([], limits.filter(x => x.index != row.index));
        copy.key = Date.now();
        setLimits(copy);
    };



    const search = (fields) => {
        application().resolve('playersForShortcuts', Endpoints.PlayerSummary, fields).then((result) => {
            if (fields.Code == '') return;
            if (fields.Id == '') return;
            showPlayer(result.result);
        });
    };

    const addPlayerLimit = () => {
        var defaultLimit = null;

        if (!defaultLimit) {
            defaultLimit = {
                stake: {
                    live: 0,
                    prematch: 0
                },
                liability: {
                    live: 0,
                    prematch: 0
                }
            };
        }

        var limit = {
            id: player.id,
            index: Date.now(),
            username: player.username,
            code: player.code,
            minimumEvent: defaultLimit.minimumEvent,
            marketCap: defaultLimit.marketCap,
            maximumStake: defaultLimit.stake.prematch,
            maximumStakeLive: defaultLimit.stake.live,
            maximumLiability: defaultLimit.liability.prematch,
            maximumLiabilityLive: defaultLimit.liability.live,
            offer: defaultLimit.offer
        };

        var copy = Object.assign([], limits);
        copy.push(limit);
        copy.key = Date.now();
        setLimits(copy);
    };


    const playerSummary = () => {
        if (!player) return;
        if (!player.id) return;
        return <div className='padding groups borders big'>
            <div className='group gap'>
                <label>Id</label><span>{player.id}</span>
                <label>Username</label><span>{player.username}</span>
                <label>Name</label><span>{player.name} {player.surname}</span>
                <label>Code</label><span>{player.code}</span>
                <label>{lang('Balance')}</label><span>{currency(player.balance, 2, player.currency)}</span>
                <label>{lang('Bonus Balance')}</label><span>{currency(player.bonusBalance, 2, player.currency)}</span>
            </div>
            {player.retailId > 0 &&
                <div className='group gap'>
                    <label>Retail</label><span>{player.retailCode} {player.retailName}</span>
                    <label>{lang('Balance')}</label><span>{currency(player.retailBalance, 2, player.retailCurrency)}</span>
                </div>
            }
            <div className='row'>
                <Button title='Add' onClick={() => {
                    addPlayerLimit(player);
                }} />
            </div>
        </div>;
    };

    if (!configurationEnabled) {
        return <Card caption='Player Limits' className='event-limits'>
            <div className='padding border radius margin flex gap-5'>
                <Icon icon='info-circle' />
                <p>{lang('Player Limits is not enabled for this risk management configuration, use Risk Levels')}</p>
            </div>
        </Card>;
    }


    return <Card caption='Player Limits' className='event-limits'>
        <Card>
            <React.Fragment>
                <Form>
                    <div className='row'>
                        <FormInput title='Id' name='Id' onKeyUp={(a, b, value, e) => {
                            if (e.key == 'Enter') {
                                search({ 'Id': parseFloat(value) });
                            }
                        }} />
                        <FormInput title='Code' name='Code' onKeyUp={(a, b, value, e) => {
                            if (e.key == 'Enter') {
                                search({ 'Code': value });
                            }
                        }} />
                        <FormInput title='Username' name='Username' onKeyUp={(a, b, value, e) => {
                            if (e.key == 'Enter') {
                                search({ 'Username': value });
                            }
                        }} />
                        <FormInput title='Email' name='Email' onKeyUp={(a, b, value, e) => {
                            if (e.key == 'Enter') {
                                search({ 'Email': value });
                            }
                        }} />
                    </div>
                </Form>
                {playerSummary()}
            </React.Fragment>
        </Card>
        <div className={'centrum-flex stretch event-list'}>
            <div className='event-items'>
                <EditableTable key={limits.key} model={categoryModel} data={limits} buttons={(row) => {
                    return <div className='centrum-flex'>
                        {row.id != 0 && <Button title='Delete' onClick={() => deleteLimit(row)} />}
                    </div>;
                }} />
            </div>
        </div>
        <div className='centrum-flex padding'><Button className='alignRight' icon={'save'} title='Save' onClick={() => save()} /></div>
    </Card>;
};

SportPlayerLimits.propTypes = {
    categories: PropTypes.object,
    league: PropTypes.object
};
