import './styles/app.scss';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


import { createBrowserHistory } from 'history';
import { createAppStore } from './state/stores/AppStore';
import { updateLanguage } from './components/Centrum/Localization/lang';
import { API, DataEndpoints } from './v2/Lib/Api/Api';
export const appHistory = createBrowserHistory();


Notification.requestPermission().then(function () { });

var store = null;
export const getStore = () => {
    return store;
};


const sentryEnabled = false;
if (sentryEnabled) {
    Sentry.init({
        dsn: "https://cf2eab5a1cdd4d14abe497f8bc856815@o960400.ingest.sentry.io/6354734",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const loadApplication = (user) => {
    window.user = user;
    store = createAppStore();

    if (user != null) {
        if (user?.RetailId > 0) throw ('unable to sign in');
    }
    return new Promise((resolve) => {
        fetch('/api/cms/language/backoffice/get', {
            method: 'Post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin'
        }).then(res => res.json()).then((languageResult) => {
            try {
                document.getElementById('p_loading').remove();
            } catch (err) {
                //
            }
            resolve(store);
            var language = languageResult?.result?.language ?? {};
            updateLanguage(language);
        });
    });
};

fetch('/api/session', {
    method: 'Get',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    credentials: 'same-origin'
}).then(res => res.json()).then((result) => {
    loadApplication(result).then((store) => {
        API.post(DataEndpoints.MyFeatures).then((result) => {
            window.user.features = result.result;
            ReactDOM.render(<App history={appHistory} store={store} />, document.getElementById('app'));
        }).catch((err) => {
            if (err.toString().indexOf("Unauthorized Access")) {
                //window.location.href = '/logout';
                console.log('sdfsdfsdf');
            }

            loadApplication().then((store) => {
                ReactDOM.render(<App history={appHistory} store={store} />, document.getElementById('app'));
            }).catch((error) => {
                console.log(error);
            });

        });
    }).catch((error) => {
        console.log(error);
    });
}).catch(() => {
    loadApplication().then((store) => {
        ReactDOM.render(<App history={appHistory} store={store} />, document.getElementById('app'));
    }).catch((error) => {
        console.log(error);
    });
});


document.getElementsByTagName('body')[0].addEventListener('keydown', function (e) {
    if (e.key === "Enter") {
        const target = e.target;
        if (target.tagName == 'INPUT' || target.tagName == 'SELECT' || target.tagName == 'TEXTAREA') {
            var parent = target;
            var found = false;
            while (!found) {
                parent = parent.parentElement;
                if (!parent) {
                    found = true;
                    break;
                }
                if (parent.tagName == 'BODY') {
                    found = true;
                    break;
                }
                if (parent.tagName == 'FORM') {
                    found = true;
                    break;
                }
            }

            if (parent && parent.tagName == 'FORM' && parent.classList.contains('tab-next')) {
                const inputs = parent.querySelectorAll('input,textarea,select');
                var index = 0;
                for (index = 0; index < inputs.length; ++index) {
                    if (inputs[index] == target) {
                        break;
                    }
                }
                const next = inputs[index + 1];
                if (next && index != inputs.length - 1) {
                    next.focus();
                }
                if (!next && index == inputs.length - 1) {
                    //
                }

            }
            e.preventDefault();
            return false;

        }
    }
});
