import React, { useEffect, useState } from 'react';
import { Card } from '../../../../v2/Components/Card/Card';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { Icon } from '../../../../v2/Components/Icon/Icon';
import { API, PaymentProviders } from '../../../../v2/Lib/Api/Api';
import { Tab } from '../../../Centrum/Tab/Tab';
import PropTypes from 'prop-types';
import { application } from '../../../Application/Application';
import { ErrorModal } from '../../../Modal/Modals/Error/ErrorModal';
import { lang } from '../../../Centrum/Localization/lang';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';


const CurrencyRates = (props) => {
    const [rates, setRates] = useState(props.configuration.rates ?? [
        { base: 'USD', target: 'TTD', rate: 1 },
        { base: 'USD', target: 'IQD', rate: 1 },
        { base: 'USD', target: 'TRY', rate: 1 }
    ]);

    const updateRate = (rate, value) => {
        rate.rate = value;
        setRates([...rates]);
    };

    return <div className='flex vertical gap-10'>
        <div className='flex gap-5 padding'>
            <label>Default Currency</label>
            <span>USD</span>
            <Button title='Save' className='align-right' onClick={() => {
                var errors = [];
                rates.map(x => {
                    var amount = parseFloat(x.rate);
                    if (isNaN(amount) || x.rate.indexOf(',') >= 0) {
                        errors.push(`${x.base} / ${x.target} currency value ${x.rate} is wrong, use '.' for separator.`);
                    } else if (amount == 0) {
                        errors.push(`${x.base} / ${x.target} currency value can not be zero.`);
                    } else if (amount < 0) {
                        errors.push(`${x.base} / ${x.target} currency value can not be negative.`);
                    }
                });

                if (errors.length > 0) {
                    application().modal.open(<ErrorModal title={'Validation error'}>
                        <div className='flex gap-5 vertical padding'>
                            {errors.map(x => {
                                return <span key={x}>{x}</span>;
                            })}
                        </div>
                    </ErrorModal>);
                    return;
                }
                props.configuration.rates = rates;
                API.post(`${PaymentProviders.SaveConfiguration}/${props.id}`, props.configuration).then((result) => {
                    application().snackbar.open('Configuration updated');
                });
            }} />
        </div>
        {
            rates.map(rate => {
                return <div className='border flex gap-5 padding surface' key={rate}>
                    <span>{`${rate.base} / ${rate.target}`}</span>
                    <input type='text' className='align-right' value={rate.rate} onChange={(e) => updateRate(rate, e.target.value)} />
                </div>;
            })
        }
    </div>;
};

CurrencyRates.propTypes = {
    id: PropTypes.object,
    configuration: PropTypes.object
};

export const PaymentProvidersPage = () => {
    const [providers, setProviders] = useState([]);
    const [selectedProvider, selectProvider] = useState(null);
    useEffect(() => {
        loadProviders();
    }, []);


    const loadProviders = () => {
        API.post(PaymentProviders.Get).then((result) => {
            result.result.update = Date.now();
            setProviders(result.result);
        });
    };

    const toggleEnableDisableProvider = () => {
        application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
            API.post(`${selectedProvider.enabled ? PaymentProviders.Disable : PaymentProviders.Enable}/${selectedProvider.id}`, null).then((result => {
                if (result.result) {
                    var copy = { ...selectedProvider };
                    copy.enabled = !copy.enabled;
                    selectProvider(copy);
                    application().snackbar.open('Provider configuration changed.');
                } else {
                    application().snackbar.open('Unable to update configuration.');
                }
            }));
        }}>
            <p>{lang('Do you want to %1 provider?', [selectedProvider.enabled ? 'Disable' : 'Enable'])}</p>
        </ConfirmationModal>);
    };

    if (selectedProvider) {
        let tabButtons = [
            {
                name: 'general',
                title: 'General'
            },
            {
                name: 'currency',
                title: 'Currency Rates'
            }
        ];

        const configuration = JSON.parse(selectedProvider.configuration);
        return <Card title={`[${selectedProvider.display}] Provider Configuration`} buttons={() => <Button title='Close' onClick={() => {
            selectProvider(null);
            setProviders([]);
            loadProviders();
        }} />}>
            <Tab buttons={tabButtons}>
                <div className='padding'>
                    <Button title={lang('%1 provider', [selectedProvider.enabled ? 'Disable' : 'Enable'])} onClick={() => toggleEnableDisableProvider()} />
                </div>
                <CurrencyRates id={selectedProvider.id} configuration={configuration} />
            </Tab>
        </Card>;
    }


    return <Card title='Configure payment providers'>
        <Table model={{
            fields: [
                {
                    name: 'id',
                    title: 'Id'
                },
                {
                    name: 'display',
                    title: 'Display'
                },
                {
                    name: 'enabled',
                    title: 'Enabled',
                    // eslint-disable-next-line react/display-name
                    renderRow: (row) => {
                        return <Icon icon={row.enabled ? 'square-check' : 'square-times'} />;
                    }
                }
            ]
        }} data={providers} key={providers.update} buttons={(row) => {
            return <Button title='Configure' onClick={() => selectProvider(row)} />;
        }} />
    </Card>;
};