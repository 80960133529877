import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { emptyMargin, model } from './SportsbookMargins';
import { lang } from '../../../Centrum/Localization/lang';


export const SportMargins = (props) => {
    const [tableSorting, setSorting] = useState({ field: 'id', direction: 1 });
    
    const masterData = props.masterData;
    const [data, setData] = useState([]);
    const [updatedRows, setUpdatedRows] = useState({ categories: {} });

    const sportRef = React.createRef();

    useEffect(() => {
        setData(Object.values(props.margins.categories));
    }, []);

    const context = {
        renderId: (row) => {
            return row.id;
        },
        renderName: (row) => {
            return row.name;
        },
        isChanged: (row) => {
            var key = row.id;
            if (updatedRows.categories[key]) {
                return true;
            }
            return false;
        },
        addToChanges: (copy, key, payload, type = 'update') => {
            var copyOfUpdatedRows = { ...updatedRows };
            if (JSON.stringify(masterData.categories[key]) == JSON.stringify(copy.categories[key])) {
                delete copyOfUpdatedRows.categories[key];
            } else {
                copyOfUpdatedRows.categories[key] = { ...payload };
                copyOfUpdatedRows.categories[key]._type = type;
            }
            setUpdatedRows(copyOfUpdatedRows);
        },
        update: (row, value, field) => {
            var key = row.id;
            const target = field.name;
            var copy = { ...props.margins };
            if (!copy.categories[key]) {
                copy.categories[key] = {
                    preMargin: 0,
                    preMin: 0,
                    preMax: 0,
                    liveMargin: 0,
                    liveMin: 0,
                    liveMax: 0
                };
            }
            copy.categories[key][target] = (value == true || value == false) ? value : parseFloat(value);
            props.setMargins(copy);

            context.addToChanges(copy, key, copy.categories[key]);
        }
    };

    const add = () => {
        var sport = props.sports.find(x => x.id == parseInt(sportRef.current.value));
        var copy = { ...props.margins };
        var key = sport.id;
        if (!copy.categories[key]) {
            copy.categories[key] = emptyMargin();
            copy.categories[key].id = sport.id;
            copy.categories[key].name = sport.name;
        }

        setData(Object.values(copy.categories));
        props.setMargins(copy);
    };

    const update = (row, empty) => {
        var payload = {
            categories: {

            }
        };

        payload.categories[row.id] = empty ? emptyMargin() : row;
        return payload.categories[row.id];
    };

    const remove = (row) => {
        var copy = { ...props.margins };
        var key = row.id;
        delete copy.categories[key];
        props.setMargins(copy);
        setData(Object.values(copy.categories));
        context.addToChanges(copy, key, update(row, true), 'delete');
    };

    return <div className='sport-margins'>
        <div className='content'>
            <div className='panel'>
                <div className='padding flex gap-5'>
                    <div className='flex gap-10'>
                        <select ref={sportRef}>
                            {props.sports.sort((a, b) => a.weight > b.weight ? 1 : -1).map(x => {
                                if (props.margins.categories[x.id]) return;
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        <Button title='Add' onClick={() => add()} />
                    </div>
                </div>
            </div>
            {props.sports.length &&
                <Table model={model}
                    options={{
                        sorting: {
                            enabled: true,
                            field: {
                                column: tableSorting.field,
                                direction: tableSorting.direction
                            },
                            onSortingChange: (sorting) => {
                                if (sorting.column == 'buttons') return;
                                setSorting({ field: sorting.column, direction: sorting.direction });
                            }
                        }
                    }}
                    data={data.sort((a, b) => {
                        switch (tableSorting.field) {
                            case 'id':
                                return tableSorting.direction == 1 ? (b.weight > a.weight ? -1 : 1) : (a.weight > b.weight ? 1 : -1);
                            case 'name':
                                return tableSorting.direction == 1 ? (a.name > b.name ? -1 : 1) : (a.name > b.name ? 1 : -1);
                            default:
                                return tableSorting.direction == 1 ? (new Date(a.date) - new Date(b.date)) : (new Date(b.date) - new Date(a.date));
                        }
                    })}
                    context={context}
                    onRenderRow={(row, elements, className, context) => {
                        var c = context.isChanged(row) ? 'changed' : className;
                        return <tr className={c}>
                            {elements}
                        </tr>;
                    }}
                    buttons={(row) => {
                        return <div className='flex gap-10'>
                            <Button title='Remove' onClick={() => remove(row)} />
                        </div>;
                    }}
                />}
        </div>
        <div className='panel padding'>
            {Object.keys(updatedRows.categories).length > 0 ? <Button title={lang('Save %1 changes', [Object.keys(updatedRows.categories).length])} onClick={() => {
                props.save(updatedRows);
            }} /> : <Button title='Save' className='disabled' />}
        </div>
    </div>;
};

SportMargins.propTypes = {
    sports: PropTypes.array,
    margins: PropTypes.object,
    masterData: PropTypes.object,
    setMargins: PropTypes.func,
    save: PropTypes.func
};