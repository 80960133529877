import { addDays, subDays } from 'date-fns/esm';

export const convertDate = (dt) => {
    return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds();
};

export const getToday = (hours = true) => {
    var dt = new Date();
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), hours ? 23 : 0, hours ? 59 : 0);
};

export const getStartingOfThisWeek = () => {
    var dt = new Date();
    return subDays(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), -dt.getDay());
};

export const getStartingOfThisMonth = () => {
    var dt = new Date();
    return subDays(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), -dt.getDate());
};

export const getStartingOfThisQuarter = () => {
    var dt = new Date();
    var month = dt.getMonth() + 1;
    var targetStartOfQuarter = Math.ceil(month / 4);
    var quarters = [
        new Date(dt.getFullYear(), 1, 1),
        new Date(dt.getFullYear(), 4, 1),
        new Date(dt.getFullYear(), 8, 1)
    ];

    return quarters[targetStartOfQuarter - 1];
};

export const getStartingOfThisYear = () => {
    var dt = new Date();
    return new Date(dt.getFullYear(), 1, 1);
};

export const getDay = (days) => {
    var dt = new Date();
    dt = addDays(dt, days);
    dt.setHours(0);
    dt.setMinutes(0);
    return dt;
};


export const convertTZ = (date, tzString) => {
    return (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-Gb', { timeZone: tzString, hour12: false });
};

export const getTime = (date) => {
    var data = date.split(' ')[1].split(':');
    var hours = data[0];
    var minutes = data[1];
    var time = hours.toString().padStart(2, '0') + ':' + minutes.padStart(2, '0');
    return time;
};


export const toLocalDate = (date) => {
    var dt = new Date();
    var localDate = new Date(date.valueOf() + (dt.getTimezoneOffset() * 60 * 1000) * -1);
    return localDate;
};

export const getUtcDate = (date) => {
    var now = new Date();
    if (date !== undefined) now = date;
    var utc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()));
    return utc;
};


export const toCamelCase = (obj) => {
    var newObj = {};
    Object.keys(obj).map(key => {

        var newKey = key;
        newKey.charAt(0).toUpperCase() + newKey.slice(1);
        newObj[newKey] = obj[key];
    });

    return newObj;
};


export const toSnakeCase = (obj) => {
    var newObj = {};
    Object.keys(obj).map(key => {

        var newKey = key;
        newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
        newObj[newKey] = obj[key];
    });

    return newObj;
};


export const toPascalCase = (obj) => {
    var newObj = {};
    Object.keys(obj).map(key => {

        var newKey = key;
        newKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);
        newObj[newKey] = obj[key];
    });

    return newObj;
};



export const swapArrayElements = (a, x, y) => {
    if (a.length === 1) return a;
    a.splice(y, 1, a.splice(x, 1, a[y])[0]);
    return a;
};




export const getBrand = () => {
    var logo = '7island.png';
    var title = '7island';

    try {
        if (window.location.host.toLowerCase().indexOf('agata') >= 0) {
            logo = 'Agata.png';
            title = 'Agatatech';
        }
    } catch (err) {
        console.log(err);
    }

    return {
        logo: logo,
        title: title
    };
};



var defaultLocalStorage = {
    selectedEvents: {}
};



export const setLocalStorage = (target, data) => {
    var store = '';
    try {
        store = localStorage.getItem('bo');
        if (!store) {
            store = defaultLocalStorage;
        } else {
            store = JSON.parse(store);
        }
        store[target] = data;
        store = JSON.stringify(store);
        localStorage.setItem('bo', store);

        return;
    } catch (e) {
        //
        defaultLocalStorage[target] = data;
        return;
    }
};

export const getLocalStorage = (target) => {
    var store = '';
    try {
        store = localStorage.getItem('bo');
        if (!store) {
            store = defaultLocalStorage;
        } else {
            store = JSON.parse(store);
        }

        return store[target];
    } catch (e) {
        return defaultLocalStorage[target];
    }
};
