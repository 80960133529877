import { InputTypes } from '../CentrumForm/Inputs/CentrumInput';
import { UserGroupTypes, ArrayToKeys, OperatorActivities } from './ModelEnums';
import { DateRanges } from '../CentrumForm/Inputs/Pickers/DateTimePicker';

const UserGroups = {
    2: 'Owner',
    3: 'Manager',
    4: 'Setup',
    5: 'Call Center',
    6: 'Finance',
    7: 'Marketing'
};

export const OperatorUsersModel = {
    searchOperatorUsers: {
        fields: [
            {
                name: 'Operator',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'Group',
                display: 'User Group',
                type: InputTypes.select,
                values: UserGroupTypes,
                acceptEmptyValues: true
            },
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true,
                max: 128
            }
        ]
    },
    operatorUserSearchResult: {
        fields: [
            {
                name: 'Operator',
                display: 'Operator',
                type: InputTypes.text,
            },
            {
                name: 'Group',
                display: 'User Group',
                type: InputTypes.text
            },
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true,
                max: 128
            }
        ]
    },
    newOperatorUser: {
        fields: [
            {
                name: 'OperatorId',
                display: 'Operator',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'UserGroupId',
                display: 'User Group',
                type: InputTypes.select,
                values: UserGroups
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Password',
                type: InputTypes.password,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                max: 128
            }
        ]
    },
    editOperatorUser: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly: true,
                identity: true,
            },
            {
                name: 'OperatorId',
                display: 'Operator',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'UserGroupId',
                display: 'User Group',
                type: InputTypes.select,
                values: UserGroups
            },
            {
                name: 'Username',
                type: InputTypes.text
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Password',
                type: InputTypes.text,
                validation: 'text',
                value: '',
                max: 128
            }
        ]
    },
    editMyOperatorUser: {
        fields: [
            {
                name: 'Id',
                max: 5,
                type: InputTypes.number,
                readonly: true,
                identity: true,
            },
            {
                name: 'OperatorId',
                display: 'Operator',
                type: InputTypes.select,
                valueEndPoint: {
                    source: 'operators',
                    bindingKey: 'Id',
                    bindingText: ['Name']
                }
            },
            {
                name: 'UserGroupId',
                display: 'User Group',
                type: InputTypes.select,
                values: UserGroups
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Password',
                type: InputTypes.text,
                validation: 'text',
                readonly: true,
                hidden: true
            }
        ]
    },
    editUserPassword: {
        fields: [
            {
                name: 'password',
                type: InputTypes.text
            }
        ]
    },
    searchOperatorUserActivity: {
        fields: [
            {
                name: 'Created',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Month']
                }
            },
            {
                name: 'Activity',
                display: 'Activity',
                max: 255,
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: ArrayToKeys(OperatorActivities)
            },
            {
                name: 'Ip',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Agent',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Token',
                max: 255,
                type: InputTypes.string
            }
        ]
    },
    searchMyActivity: {
        fields: [
            {
                name: 'Created',
                display: 'Date',
                max: 19,
                type: InputTypes.dateTime,
                queryBuilderOptions: {
                    Between: true,
                    data: DateRanges['This Month']
                }
            },
            {
                name: 'Activity',
                display: 'Activity',
                max: 255,
                type: InputTypes.select,
                acceptEmptyValues: true,
                values: ArrayToKeys(OperatorActivities)
            },
            {
                name: 'Ip',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Agent',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Token',
                max: 255,
                type: InputTypes.string
            }
        ]
    },
    operatorUserActivityInformation: {
        fields: [
            {
                name: 'Email'
            },
            {
                name: 'Token',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Created',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'Activity',
                display: 'Activity'
            },
            {
                name: 'Ip',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Agent',
                max: 255,
                type: InputTypes.string
            }
        ]
    },
    activityDetails: {
        fields: [
            {
                name: 'Created',
                display: 'Date',
                type: InputTypes.dateTime
            },
            {
                name: 'Activity',
                display: 'Activity'
            },
            {
                name: 'Data',
                display: 'Detail'
            }
        ]
    },
    activitySummary: {
        fields: [
            {
                name: 'Email'
            },
            {
                name: 'Token',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Ip',
                max: 255,
                type: InputTypes.string
            },
            {
                name: 'Agent',
                max: 255,
                type: InputTypes.string
            }
        ]
    },
    searchAffiliateUsers: {
        fields: [
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true,
                max: 128
            }
        ]
    },
    affiliateUser: {
        fields: [
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Name',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Surname',
                type: InputTypes.text,
                validation: 'text',
                max: 128
            },
            {
                name: 'Enabled',
                type: InputTypes.bool,
                value: true,
                max: 128
            }
        ]
    },
};