import './_openEvent.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../Centrum/Card/Card';
import Table from '../../../Controls/Table/SortableTable/SortableTable';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Button } from '../../../Controls/Buttons/Button';
import { currency, lang } from '../../../Centrum/Localization/lang';
import { application, Endpoints } from '../../../Application/Application';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Icon } from '../../../Controls/Icon/Icon';
import { Dialog } from '../../../Modal/Modals/Dialog/Dialog';
import { ErrorModal } from '../../../Modal/Modals/Error/ErrorModal';
import { lotteryGames } from '../../../Centrum/_models/LotteryModels';



const lotteryGamesModel = {
    fields: [
        {
            name: 'name',
            display: 'Name'
        },
        {
            name: 'events',
            display: 'Events',
            formatter: (field, row) => {
                return row.events.length;
            }
        },
        {
            name: 'openCoupons',
            display: 'Open Coupons'
        }
    ]
};


const lotteryEventModel = {
    fields: [
        {
            name: 'date',
            display: 'Date',
            type: InputTypes.dateTime
        },
        {
            name: 'openCoupons',
            display: 'Open Coupons'
        },
        {
            name: 'status',
            display: 'Status',
            formatter: (field, row) => {
                switch (row.status) {
                    case 0:
                        return lang('New');
                    case 1:
                        return lang('Resulted');
                    case 2:
                        return lang('Closed');
                    default:
                        return '--';
                }
            }
        },
        {
            name: 'results',
            display: 'Results',
            formatter: (field, row) => {
                return renderResults(row);
            }
        }
    ]
};




const payoutPreviewModel = {
    fields: [
        {
            name: 'ticketCount',
            display: 'Winning Tickets'
        },
        {
            name: 'currency',
            display: 'Currency'
        },
        {
            name: 'totalAmount',
            display: 'Total Payout',
            formatter: (field, row) => {
                return currency(row.totalAmount, 2, row.currency);
            }
        }
    ]
};


const summaryModel = {
    fields: [
        {
            name: 'currency',
            display: 'Currency'
        },
        {
            name: 'totalAmount',
            display: 'Total Stake',
            formatter: (field, row) => {
                return currency(row.totalAmount, 2, row.currency);
            }
        },
        {
            name: 'ticketCount',
            display: 'Tickets'
        }
    ]
};


const renderResults = (event) => {
    var result = event.results;
    if (!result) return;
    result = result.split(',');
    return <ul className='lottery-numbers'>
        {result.map((x, index) => {
            return <li key={index}>{x}</li>;
        })}
        <li className='bonus'>{event.bonusBall}</li>
    </ul>;
};

///api/EventSummary/12
const eventDetails = (event) => {
    application().resolve('', Endpoints.LotteryEventDetails + '/' + event.id, 'fetching event summary', 'unable to fetch event summary').then((result) => {
        var summary = [];
        result.result.totals.map(x => {
            summary.push(x);
        });
        application().modal.open(<Dialog title='Summary'>
            <Table
                model={summaryModel}
                data={summary}
                options={{
                    header: {
                        show: true
                    },
                    draggable: {
                        enabled: false
                    },
                    selectable: false
                }}
            />
        </Dialog>);
        console.log(result);
    });
};


const enterResults = (event, onComplete) => {
    var form = null;
    var data = {};
    var results = event.results;
    data.bonusBall = event.bonusBall ? event.bonusBall : null;
    if (results) {
        results = results.split(',');
        results.map((x, index) => {
            data['ball' + (index + 1)] = x;
        });
    }

    application().modal.open(<ConfirmationModal title='Event Results' onConfirm={() => {
        var data = {
            EventId: event.id,
            ProviderGameId: event.providerGameId,
            WinningNumbers: [
                parseFloat(form.data.ball1),
                parseFloat(form.data.ball2),
                parseFloat(form.data.ball3),
                parseFloat(form.data.ball4),
                parseFloat(form.data.ball5)
            ]
        };

        if (event.maxBallCount > 5) {
            data.WinningNumbers.push(parseFloat(form.data.ball6));
        }
        if (event.hasBonusBall) {
            data.BonusBall = parseFloat(form.data.bonusBall);
        }
        application().resolve('', Endpoints.LotteryResult, data, 'submiting results', 'unable to submit results').then(() => {
            event.results = data.WinningNumbers.join(',');
            event.bonusBall = data.BonusBall;

            application().snackbar.open('Event results submited');
            onComplete();
        }).catch((error) => {
            var message = 'Service is not working.';
            if (error.error) message = error.error.message;
            application().modal.open(<ErrorModal title='Can not save event'>
                {lang(message)}
            </ErrorModal>);
        });
    }}>
        <Form ref={(r) => form = r} data={data}>
            <div className='row'>
                <FormInput type={InputTypes.text} name='ball1' title='Ball 1' />
                <FormInput type={InputTypes.text} name='ball2' title='Ball 2' />
                <FormInput type={InputTypes.text} name='ball3' title='Ball 3' />
            </div>
            <div className='row'>
                <FormInput type={InputTypes.text} name='ball4' title='Ball 4' />
                <FormInput type={InputTypes.text} name='ball5' title='Ball 5' />
                {event.maxBallCount > 5 && <FormInput type={InputTypes.text} name='ball6' title='Ball 6' />}
            </div>
            {
                event.hasBonusBall && <div className='row'>
                    <FormInput type={InputTypes.text} name='bonusBall' title='Bonus Ball' />
                </div>
            }
        </Form>
    </ConfirmationModal >);
};


const closeEvent = (event, onEventClosed) => {
    application().resolve('', Endpoints.LotteryEventPayoutPreview + '/' + event.id, {}, 'submiting results', 'unable to submit results').then((result) => {
        const preview = result.result;
        var summary = [];
        preview.totals.map(x => {
            summary.push(x);
        });
        application().modal.open(<ConfirmationModal title='Do you want to close event?' onConfirm={() => {
            application().resolve('', Endpoints.LotteryClose + '/' + event.id, {}, 'submiting results', 'unable to submit results').then(() => {
                event.status = 2;
                onEventClosed();
                application().modal.open(<Dialog title='Event Closed'>
                    <p>{lang('Event is closed and all payments made successfully')}</p>
                </Dialog>);
            });
        }}>
            <div className='centrum-flex'>
                <Table
                    model={payoutPreviewModel}
                    data={summary}
                    options={{
                        header: {
                            show: true
                        },
                        draggable: {
                            enabled: false
                        },
                        selectable: false
                    }}
                />
            </div>
        </ConfirmationModal>);
    });
};



const removeEvent = (event, onEventRemoved) => {
    application().modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
        application().resolve('', Endpoints.LotteryRemoveEvent + '/' + event.id, {}, 'removing event', 'unable to remove event').then(() => {
            event.status = 3;
            application().snackbar.open('Event removed');
            onEventRemoved();
        });
    }}>
        <p>{lang('Do you want to remove event?')}</p>
    </ConfirmationModal>);
};


const prepareEvents = (events) => {
    var providers = {};

    events.map(x => {
        if (!providers.hasOwnProperty(x.providerGameName)) {
            providers[x.providerGameName] = {
                id: x.providerGameId,
                name: x.providerGameName,
                openCoupons: 0,
                events: [],
                maxBallCount: x.maxBallCount,
                maxNumber: x.maxNumber,
                hasBonusBall: x.hasBonusBall
            };
        }

        providers[x.providerGameName].events.push(x);
        providers[x.providerGameName].openCoupons += x.openCoupons;
    });

    return providers;
};


const newEvent = (eventSaved, eventData, edit) => {
    var form = null;
    var formData = null;
    application().modal.open(<ConfirmationModal title='New Event' onConfirm={() => {
        var data = {
            eventDate: form.data.date + ' ' + form.data.time,
            providerGameId: parseFloat(form.data.game)
        };
        formData = Object.assign({}, form.data);
        if (edit) {
            data.eventId = eventData.eventId;
        }
        var date = new Date(data.eventDate);

        if (date == 'Invalid Date' || new Date(formData.date) == 'Invalid Date') {
            application().modal.open(<ErrorModal title='Can not continue' onClose={() => newEvent(eventSaved, formData, edit)}>
                {lang('Invalid Date')}
            </ErrorModal>);
            return;
        }

        if (isNaN(data.providerGameId)) {
            application().modal.open(<ErrorModal title='Can not continue' onClose={() => newEvent(eventSaved, formData, edit)}>
                {lang('Please pick a game')}
            </ErrorModal>);
            return;
        }
        application().resolve(null, edit ? Endpoints.LotteryUpdateEvent : Endpoints.LotteryAddEvent, data, 'saving event', 'unable to save event').then(() => {
            application().snackbar.open('Event saved');
            eventSaved();
        }).catch((error) => {
            var message = 'Service is not working.';
            if (error.error) message = error.error.message;
            application().modal.open(<ErrorModal title='Can not save event' onClose={() => newEvent(eventSaved, formData, edit)}>
                {lang(message)}
            </ErrorModal>);
        });
    }}>
        <Form ref={(r) => form = r} data={eventData}>
            <div className='row'>
                <FormInput type={InputTypes.date} name='date' title='UTC Date' />
                <FormInput type={InputTypes.text} name='time' title='Time' />
                <FormInput type={InputTypes.select} values={lotteryGames} name='game' title='Game' />
            </div>
        </Form>
    </ConfirmationModal>);
};

export const OpenEvents = (props) => {
    const [selectedEventType, selectEventType] = useState(null);
    const [updated, setUpdated] = useState(null);
    const [events, updateEvents] = useState(Object.values(prepareEvents(props.events)));
    const [update, setUpdate] = useState(0);

    const reloadEvents = () => {
        application().resolve(null, Endpoints.LotteryEvents, {}, 'fetching events', 'unable to fetch events').then((result) => {
            updateEvents(prepareEvents(result.result));
            setUpdate(update + 1);
        });
    };

    return <div className='centrum-flex nowrap lottery-events vertical' key={update}>
        <Card caption='Events' className='event-list' menu={
            <React.Fragment>
                <Icon icon='sync-alt' onClick={() => {
                    reloadEvents();
                }} />
                <Button title='New Event' onClick={() => {
                    newEvent(() => {
                        reloadEvents();
                    });
                }} ><Icon icon='plus' />
                </Button>
            </React.Fragment>
        }>
            <Table
                model={lotteryGamesModel}
                data={Object.values(events)}
                key={events}
                onRowClick={(element, row) => {
                    selectEventType(row.events);
                    setUpdated(Date.now());
                }}
                options={{
                    header: {
                        show: true
                    },
                    draggable: {
                        enabled: false
                    },
                    selectable: false
                }}
            />
        </Card>
        <Card caption='Calendar'>
            {
                selectedEventType &&
                <Table
                    model={lotteryEventModel}
                    data={Object.values(selectedEventType)}
                    key={updated}
                    options={{
                        header: {
                            show: true
                        },
                        draggable: {
                            enabled: false
                        },
                        selectable: false
                    }}
                    buttons={(row) => {
                        return <React.Fragment>
                            {row.status == 0 &&
                                <Button title='Edit' onClick={() => {
                                    var date = row.date.split('T');
                                    var eventData = {
                                        eventId: row.id,
                                        date: date[0],
                                        time: date[1],
                                        game: row.providerGameId
                                    };
                                    newEvent(() => {
                                        reloadEvents();
                                        setUpdated(Date.now());
                                    }, eventData, true);
                                }}><Icon icon='edit' /></Button>
                            }
                            {row.status == 0 &&
                                <Button title='Results' onClick={() => {
                                    enterResults(row, () => {
                                        row.status = 1;
                                        updateEvents(Object.assign({}, events));
                                    });
                                }}><Icon icon='trophy' /></Button>
                            }
                            {
                                row.status == 1 &&
                                <Button onClick={() => {
                                    eventDetails(row);
                                }}><Icon icon='coins' /></Button>
                            }
                            {
                                row.status == 1 &&
                                <Button title='Close' onClick={() => {
                                    closeEvent(row, () => {
                                        reloadEvents();
                                    });
                                }} />
                            }
                            {
                                row.status == 0 &&
                                <Button title='Remove' onClick={() => {
                                    removeEvent(row, () => {
                                        selectEventType(null);
                                        reloadEvents();
                                    });
                                }} />

                            }
                        </React.Fragment>;
                    }}
                />
            }
        </Card>
    </div>;
};

OpenEvents.propTypes = {
    events: PropTypes.object
};
