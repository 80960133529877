import './_top-leagues.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { application, Endpoints } from '../../../Application/Application';
import { Card } from '../../../Centrum/Card/Card';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { lang } from '../../../Centrum/Localization/lang';
import { Button } from '../../../Controls/Buttons/Button';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import Table from '../../../Controls/Table/SortableTable/SortableTable';
import { Dialog } from '../../../Modal/Modals/Dialog/Dialog';
import { Tab } from '../../../Centrum/Tab/Tab';
import { SportsTree } from '../../../Controls/SportsTree/SportsTree';


const topLeaguesModel = {
    fields: [
        {
            name: 'league',
            display: 'League',
            formatter: (field, row) => {
                return row.sport + '/' + row.region + '/' + row.league;
            }
        }
    ]
};



export const TopLeagues = (props) => {
    const categories = props.categories;
    if (!categories) return <React.Fragment />;

    const [category, selectCategory] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [league, setLeague] = useState(null);
    const [regions, setRegions] = useState(null);
    const [region, setRegion] = useState(null);
    const [topLeagues, setTopLeagues] = useState(null);
    const [topLeaguesLive, setTopLeaguesLive] = useState(null);
    const [selectedTreeType, selectTreeType] = useState(0);

    const [liveSports, setLiveSports] = useState(null);
    const [selectedLiveSport, selectLiveSport] = useState(null);
    const [liveUpdateKey, setLiveUpdateKey] = useState(0);

    useEffect(() => {
        if (!topLeagues) {
            var prematch = [];
            var live = [];
            application().resolve(null, Endpoints.GetTopLeagues, {}, 'fetching top leagues', 'unable to fetch top leagues').then((result) => {
                try {
                    prematch = JSON.parse(result.result);
                    if (!prematch) prematch = [];
                } catch (err) {
                    //
                }

                application().resolve(null, Endpoints.GetLiveTopLeagues, {}, 'fetching top leagues', 'unable to fetch top leagues').then((result) => {
                    try {
                        live = JSON.parse(result.result);
                        if (!live) live = [];
                    } catch (err) {
                        //
                    }


                    setTopLeagues(prematch);
                    setTopLeaguesLive(live);
                });

            });
        }
    });

    useEffect(() => {
        if (!topLeaguesLive) return;
        var copy = {};
        topLeaguesLive.map(x => {
            if (!copy[x.sport]) {
                copy[x.sport] = {
                    weight: categories.find(y => y.id == x.sportId).weight,
                    name: x.sport,
                    id: x.sportId,
                    items: []
                };
            }

            copy[x.sport].items.push(x);
        });


        setLiveSports(copy);
        if (!selectedLiveSport && topLeaguesLive.length) {
            var first = Object.values(copy).sort((a, b) => a.weight > b.weight)[0].name;
            selectLiveSport(first);
        }

    }, [topLeaguesLive]);

    const onCategoryChanged = (cat) => {
        application().resolve(null, Endpoints.SportRegions + '/' + cat.id, {}, 'fetching regions', 'unable to fetch regions').then((result) => {
            selectCategory(cat);
            setRegions(result.result.filter(x => x.name !== '').sort((a, b) => a.name > b.name ? 1 : -1));
        });
    };

    const onRegionChanged = (regionId) => {
        application().resolve(null, Endpoints.SportLeagues + '/' + category.id + '/' + regionId, {}, 'fetching leagues', 'unable to fetch leagues').then((result) => {
            setRegion(regions.find(x => x.id == regionId));
            const sorted = result.result.filter(x => x.name !== '').sort((a, b) => a.name.localeCompare(b.name));
            setLeagues(sorted);

        });
    };

    const save = () => {
        var targetTree = selectedTreeType == 0 ? topLeagues : topLeaguesLive;

        if (selectedTreeType == 1) {
            targetTree = [];
            Object.values(liveSports).map(x => {
                if (x.items.length) {
                    var count = x.items.length;
                    x.items.map((y, index) => {
                        y.weight = count - index;
                    });
                    targetTree = Array.concat(targetTree, x.items);
                }
            });
        }
        
        var saveData = Object.assign([], targetTree);
        saveData.map(x => {
            delete x.__selected;
        });


        application().resolve(null, selectedTreeType == 0 ? Endpoints.SaveTopLeagues : Endpoints.SaveLiveTopLeagues, saveData, 'fetching leagues', 'unable to fetch leagues').then(() => {
            application().modal.open(<Dialog title={'Data Saved'}>
                {lang('%1, top leagues saved, web site data updated.', [selectedTreeType == 0 ? 'Prematch' : ' Live'])}
            </Dialog >);
        });

    };


    var sports = { 0: 'Please Select' };
    var countries = { 0: 'Please Select' };
    if (categories) categories.sort((a, b) => { return b.weight - a.weight; }).map(category => {
        sports[category.name] = category.name;
    });

    if (regions) regions.map(region => {
        countries[region.name + ':' + region.id] = region.name;
    });




    if (!topLeagues) return <div />;
    if (!topLeaguesLive) return <div />;
    if (!liveSports) return <></>;

    const tabButtons = [
        {
            name: 'prematch',
            title: 'Prematch'
        },
        {
            name: 'live',
            title: 'Live'
        }
    ];

    var liveSportsKeys = {};
    var liveSportTabs = [];
    topLeaguesLive.map(x => {
        if (!liveSportsKeys[x.sport]) {
            liveSportsKeys[x.sport] = true;
            liveSportTabs.push({
                name: x.sport,
                title: x.sport
            });
        }
    });




    return <div className='surface'>
        <Card caption='Categories'>
            <Form>
                <div className='row margin-top'>
                    <FormInput title='Sports' type={InputTypes.select} values={sports} onChange={(form, field, value) => {
                        const selectedCategory = categories.find(x => x.name == value);
                        if (selectedCategory) {
                            onCategoryChanged(selectedCategory);
                        }
                    }} />
                </div>
                {
                    category &&
                    <div className='row margin-top'>
                        <FormInput title='Regions' type={InputTypes.select} values={countries} onChange={(form, field, value) => {
                            value = parseFloat(value.split(':')[1]);
                            if (value) {
                                onRegionChanged(value);
                            }
                        }} />
                    </div>
                }
                {
                    leagues && <div className='row margin-top'>
                        <div className='form-group'>
                            <label>Leagues</label>
                            <select name='leagues' onChangeCapture={(e) => setLeague(leagues.find(x => x.id == parseFloat(e.target.value)))}>
                                {leagues.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                            </select>
                        </div>
                        <Button title='Add' className='marginLeft alignBottom' onClick={() => {
                            const leagueData = {
                                sport: category.name,
                                sportId: category.id,
                                region: region.name,
                                regionId: region.id,
                                league: league.name,
                                leagueId: league.id
                            };
                            if (!topLeagues.find(x => x.leagueId == league.id)) {
                                topLeagues.push(leagueData);
                                setTopLeagues(Object.assign([], topLeagues));
                            }
                        }} />
                    </div>
                }
            </Form>
        </Card>

        <div className='flex gap-10 fit padding-top top-leagues'>
            <SportsTree categories={categories.sort((a, b) => a.weight - b.weight)} regions leagues onRenderLeagueOptions={(league) => {
                var targetTree = selectedTreeType == 0 ? topLeagues : topLeaguesLive;
                var selected = targetTree.find(x => x.leagueId == league.id);

                if (selected) return <div className='flex add-league small' onClick={() => {
                    var copy = [...targetTree];
                    copy = copy.filter(x => x.leagueId != league.id);
                    if (selectedTreeType == 0) setTopLeagues(copy);
                    if (selectedTreeType == 1) setTopLeaguesLive(copy);
                }
                }>{lang('remove')}</div>;

                return <div className='flex add-league small' onClick={() => {
                    var copy = [...targetTree];
                    copy.push({
                        league: league.name,
                        leagueId: league.id,
                        region: league.regionName,
                        regionId: league.region,
                        sport: league.sportName,
                        sportId: league.sport
                    });

                    if (selectedTreeType == 0) setTopLeagues(copy);
                    if (selectedTreeType == 1) setTopLeaguesLive(copy);
                }
                }>{lang('add')}</div>;
            }} />
            <div className='flex gap-5 fit align-start'>
                <Tab buttons={tabButtons} onTabSelected={(index) => selectTreeType(index)}>
                    <div className=''>
                        <Card caption='Top Leagues Prematch'>
                            <Table
                                model={topLeaguesModel}
                                data={topLeagues}
                                key={topLeagues}
                                options={{
                                    data: {
                                        delete: {
                                            enabled: true,
                                            header: true
                                        }
                                    },
                                    draggable: {
                                        enabled: true
                                    },
                                    selectable: true
                                }}
                                onDataChange={(row, index, value) => {
                                    setTopLeagues(Object.assign([], value));
                                }} />

                            <div className='centrum-flex padding'>
                                <Button title='Save' onClick={() => save()} className='button alignRight' />
                            </div>
                        </Card>
                    </div>
                    <div className=''>
                        <Card caption='Top Leagues Live'>
                            {liveSportTabs.length && <React.Fragment>
                                <Tab buttons={liveSportTabs} onTabSelected={(index) => {
                                    var selected = Object.values(liveSports).sort((a, b) => a.weight > b.weight)[index].name;
                                    selectLiveSport(selected);
                                    setLiveUpdateKey(Date.now());
                                }}>
                                </Tab>
                                <div className='flex vertical gap-5'>
                                    <Table
                                        model={topLeaguesModel}
                                        data={liveSports[selectedLiveSport].items}
                                        key={liveUpdateKey}
                                        options={{
                                            data: {
                                                delete: {
                                                    enabled: true,
                                                    header: true
                                                }
                                            },
                                            draggable: {
                                                enabled: true
                                            },
                                            selectable: true
                                        }}
                                        onDataChange={(row, index, value) => {
                                            var copy = { ...liveSports };
                                            copy[selectedLiveSport].items = Object.assign([], value);
                                            setLiveSports(copy);
                                            setLiveUpdateKey(Date.now());
                                        }} />

                                    <div className='centrum-flex padding'>
                                        <Button title='Save' onClick={() => save()} className='button alignRight' />
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        </Card>
                    </div>
                </Tab>
            </div>
        </div>
    </div>;
};

TopLeagues.propTypes = {
    categories: PropTypes.object
};

