// IMPORT PACKAGE REFERENCES
import './_imageDrop.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../Centrum/Localization/lang';
import { Endpoints, application } from '../../Application/Application';
import { Loading } from '../../Modal/Modals/Loading/Loading';
import { ErrorModal } from '../../Modal/Modals/Error/ErrorModal';



// COMPONENT
export class ImageDrop extends Component {
    constructor(props) {
        super(props);
        this.providers = {};
        this.state = Object.assign({}, props);
    }

    componentDidMount() {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.preventDefaults.bind(this), false);
            document.body.addEventListener(eventName, this.preventDefaults.bind(this), false);
        });

        ['dragenter', 'dragover'].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.highlight.bind(this), false);
        });

        ['dragleave', 'drop'].forEach(eventName => {
            this.dropArea.addEventListener(eventName, this.unhighlight.bind(this), false);
        });

        this.dropArea.addEventListener('drop', this.handleDrop.bind(this), false);


        var previewImage = null;
        if (this.props.imageSource && typeof this.props.imageSource === 'object') {
            let img = document.createElement('img');
            img.src = this.props.imageSource.image;
            img.classList.add('newImage');
            previewImage = this.dropArea.getElementsByClassName('preview')[0];
            previewImage.style.display = 'none';
            this.dropArea.appendChild(img);

        } else if (this.props.imageSource && this.props.imageSource.indexOf('data:') >= 0) {
            let img = document.createElement('img');
            img.src = this.props.imageSource;
            img.classList.add('newImage');
            previewImage = this.dropArea.getElementsByClassName('preview')[0];
            previewImage.style.display = 'none';

            this.dropArea.appendChild(img);
        }
    }

    preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    highlight() {
        this.dropArea.classList.add('highlight');
    }

    unhighlight() {
        this.dropArea.classList.remove('highlight');
    }

    handleDrop(e) {
        var dt = e.dataTransfer;
        var files = dt.files;
        this.handleFiles(files[0]);
    }

    handleFiles(file) {
        this.extension = file.name.split('.')[1];
        this.setState({ modified: true }, () => {
            this.previewFile(file);
        });
    }

    previewFile(file) {
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {

                var newImage = this.dropArea.getElementsByClassName('newImage')[0];
                if (newImage) {
                    newImage.remove();
                }

                let img = document.createElement('img');
                img.src = reader.result;
                img.classList.add('newImage');
                var previewImage = this.dropArea.getElementsByClassName('preview')[0];
                previewImage.style.display = 'none';

                this.dropArea.appendChild(img);
                this.props.onImageDrop(this.getBase64Image());
            };
        } catch (e) {
            console.log(e);
        }
    }


    clearImage() {
        var image = this.dropArea.getElementsByClassName('newImage')[0];
        if (image) {
            image.src = 'data:img/png;';
        }
        image = this.dropArea.getElementsByClassName('preview')[0];
        if (image) {
            image.src = 'data:img/png;';
        }
    }

    getBase64Image() {
        var image = this.dropArea.getElementsByClassName('newImage')[0];
        if (!image) {
            return this.state.imageSource;
        }

        if (image.src.indexOf('data') == 0) return image.src;


        var canvas = document.createElement('canvas');
        canvas.width = image.clientWidth;
        canvas.height = image.clientHeight;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        return canvas.toDataURL('image/png', 1);
    }

    createImageContent(url, data) {
        application().modal.open(<Loading title='saving image please wait' />);
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                processData: false,
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then((result) => {
                    application().modal.close();
                    resolve(result);
                }).catch(e => {
                    console.log(e);
                    application().modal.open(<ErrorModal title={'Image save failed'}><p>{lang('Unable to save image.')}</p></ErrorModal>);
                    reject(e);
                });
        });
    }


    save() {
        var image = this.getBase64Image();


        if (this.state.modified) {
            const name = this.props.newKey ? Date.now() : this.props.name;
            return this.createImageContent(Endpoints.CMSUploadImage, {
                image: image,
                path: this.props.path,
                name: name + '.' + this.extension
            });
        }

        return new Promise((resolve) => {
            resolve({ result: this.props.imageSource, status: 1 });
        });
    }


    render() {
        var source = this.state.imageSource + '?&rnd=' + Date.now();
        return <React.Fragment>
            <div className='imageDrop' ref={(r) => this.dropArea = r}>
                <span>{lang('drag image here')}</span>
                <img className='preview' src={source} />
            </div>
        </React.Fragment>;
    }
}

ImageDrop.defaultProps = {
    imageSource: '',
    path: '',
    name: '',
    modified: false,
    newKey: false
};

ImageDrop.propTypes = {
    imageSource: PropTypes.string,
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    modified: PropTypes.bool,
    newKey: PropTypes.bool,

    onImageDrop: PropTypes.func
};

