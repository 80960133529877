// IMPORT PACKAGE REFERENCES

import React, { useEffect, useState } from 'react';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { API, DataEndpoints } from '../../../../v2/Lib/Api/Api';
import { application } from '../../../Application/Application';
import PropTypes from 'prop-types';


export const ConfigureOperatorCurrencies = (props) => {
    const [currencies, setCurrencies] = useState(null);

    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies).then((result) => {
            var operatorCurrencies = JSON.parse(props.configuration.currencies ? props.configuration.currencies : '[]');
            operatorCurrencies.map(x => {
                result.result.find(y => y.CurrencyCode == x.CurrencyCode).Enabled = true;
            });
            setCurrencies(result.result);
        });
    }, []);

    const save = () => {
        API.post(DataEndpoints.SaveOperatorCurrencies + '/' + props.operatorId, currencies.filter(x => x.Enabled), 'updating currencies please wait', 'Unable to update currencies').then(() => {
            application().snackbar.open('Operator currencies saved.');
        });
    };

    if (!currencies) return <></>;

    return <React.Fragment>
        {
            currencies &&
            <div className='padding'>
                <Table model={{
                    fields: [
                        {
                            name: 'Name',
                            title: 'Name'
                        },
                        {
                            name: 'CurrencyCode',
                            title: 'Code'
                        }
                    ]
                }} key={currencies} data={currencies} buttons={(row) => {
                    return <Button title={row.Enabled ? 'Disable' : 'Enable'} className={row.Enabled ? 'flat' : ''} onClick={() => {
                        row.Enabled = !row.Enabled;
                        currencies.find(x => x.CurrencyCode == row.CurrencyCode).Enabled = row.Enabled;
                        setCurrencies([...currencies]);
                    }} />;
                }} onRenderRow={(row, elements) => {
                    return <tr className={row.Enabled ? 'success' : ''}>
                        {elements}
                    </tr>;
                }} />
            </div>
        }
        <Button className='margin' onClick={() => save()} title='Save' />
    </React.Fragment>;
};


ConfigureOperatorCurrencies.propTypes = {
    operatorId: PropTypes.number,
    configuration: PropTypes.object
};