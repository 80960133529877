/* eslint-disable react/display-name */

import './_configuration.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Panel } from '../../../Centrum/Card/Card';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { Button } from '../../../Controls/Buttons/Button';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Icon } from '../../../Controls/Icon/Icon';
import { Switch } from '../../../Controls/Inputs/Switch/Switch';
import { Table } from '../../../../v2/Components/Controls/Table/Table';



const focus = (e) => {
    if (e.target.value == '0') {
        e.target.value = '';
    }
};

const blur = (e) => {
    if (e.target.value == '') {
        e.target.value = '0';
    }
};

const limitRangeModel = {
    fields: [
        {
            name: 'ticketType',
            title: 'Ticket Type',
            renderRow: (row, field, context) => {
                return <select defaultValue={row.ticketType} onChange={(e) => context.update(row, e.target.value, field)}>
                    <option value='0'>Single</option>
                    <option value='1'>Combined</option>
                </select>;
            }
        },
        {
            name: 'maxSelection',
            title: 'Max Selection',
            renderRow: (row, field, context) => {
                if (row.ticketType == 0) {
                    return 1;
                }
                return <input type='number' defaultValue={row.maxSelection} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'minBet',
            title: 'Bet Min',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.minBet} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxBet',
            title: 'Bet Max',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.maxBet} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxWin',
            title: 'Max Win',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.maxWin} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'percentage',
            title: 'Percentage %',
            renderRow: (row, field, context) => {
                return <input type='number' defaultValue={row.percentage} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        }
    ]
};



export const HorseConfiguration = (props) => {
    const [retail, setRetail] = useState(props.configuration);

    const addLimit = () => {
        var copy = Object.assign({}, retail);
        copy.configuration.horse.revenueLimits.push({
            __id: Date.now(),
            ticketType: 0,
            maxSelection: 0,
            eachWay: true,
            forecast: true,
            tricast: true,
            minBet: 0,
            maxBet: 0,
            maxWin: 0,
            minOdds: 0,
            maxOdds: 0,
            percentage: 0
        });
        setRetail(copy);
    };

    const removeLimit = (row) => {
        var copy = Object.assign({}, retail);
        copy.configuration.horse.revenueLimits = copy.configuration.horse.revenueLimits.filter(x => x.__id != row.__id);
        setRetail(copy);
    };


    const changeRevenueType = (type) => {
        var copy = Object.assign({}, retail);
        copy.configuration.horse.revenueType = type;
        setRetail(copy);
    };

    useEffect(() => {
        props.onUpdate(retail);
    }, [retail]);



    const context = {
        update: (row, value, field) => {
            var copy = { ...retail };
            copy.configuration.horse.revenueLimits.find(x => x.__id == row.__id)[field.name] = value;
            if (field.name == 'ticketType' && value == '0') {
                copy.configuration.horse.revenueLimits.find(x => x.__id == row.__id).maxSelection = 1;
            }
            if (field.name == 'ticketType' && value == '1') {
                var maxSelection = parseFloat(copy.configuration.horse.revenueLimits.find(x => x.__id == row.__id).maxSelection);
                if (isNaN(maxSelection) || !maxSelection || maxSelection == 1) maxSelection = 0;
                copy.configuration.horse.revenueLimits.find(x => x.__id == row.__id).maxSelection = maxSelection;
            }
            setRetail(copy);
        }
    };

    if (!retail) return <div />;
    
    return <div>
        <Form data={retail.configuration.horse} key={retail}>
            <div className='row'>
                <FormInput title='Enabled' value={retail.configuration.horse.enabled} type={InputTypes.bool} name='enabled' onChange={(form, field, value) => {
                    var copy = Object.assign({}, retail);
                    copy.configuration.horse.enabled = value;
                    setRetail(copy);
                    props.onUpdate(copy);
                }} />
                <FormInput title='Deduct Bonus' value={retail.configuration.horse.deductBonus} type={InputTypes.bool} name='deductBonus' onChange={(form, field, value) => {
                    var copy = Object.assign({}, retail);
                    copy.configuration.horse.deductBonus = value;
                    setRetail(copy);
                    props.onUpdate(copy);
                }} />
            </div>
        </Form>
        <Panel title='Limits' className='margin' buttons={
            <div className='flex gap-5 alignRight padding-right'>
                <span>GGR</span>
                <Switch checked={retail.configuration.horse.revenueType == 'GGR'}
                    key={retail.configuration.horse.revenueType}
                    onChange={(value) => changeRevenueType(value ? 'GGR' : 'Stake')} />
                <span>Stake</span>
            </div>
        }>
            <Table model={limitRangeModel}
                data={retail.configuration.horse.revenueLimits}
                className='horse-configuration'
                context={context}
                buttons={(row) => {
                    return <Button onClick={() => removeLimit(row)} className='alignRight button' title='Delete'><Icon icon={'times'} className='marginRight' /></Button>;
                }} />
            <div className='centrum-flex margin-top marginBottom padding'>
                <Button title='Add New' className='button alignRight' onClick={() => addLimit()} />
            </div>
        </Panel>
    </div>;
};

HorseConfiguration.propTypes = {
    configuration: PropTypes.object,
    onUpdate: PropTypes.func
};