import './_cmsPromotions.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { application, Endpoints } from '../../../Application/Application';
import { Button } from '../../../Controls/Buttons/Button';
import { ConfirmationModal } from '../../../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../../../Controls/Form/Form';
import { FormInput } from '../../../Controls/Form/FormInput';
import { Card } from '../../../Centrum/Card/Card';
import { lang } from '../../../Centrum/Localization/lang';
import { Tab } from '../../../Centrum/Tab/Tab';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';

import { ImageDrop } from '../../../Controls/ImageDrop/ImageDrop';
import { ErrorModal } from '../../../Modal/Modals/Error/ErrorModal';
import TinyMCE from 'react-tinymce';
import { API, DataEndpoints, PromotionEndpoints } from '../../../../v2/Lib/Api/Api';
import Table from '../../../Controls/Table/SortableTable/SortableTable';
import { Icon } from '../../../../v2/Components/Icon/Icon';



// COMPONENT
class CMSPromotions extends React.Component {
    constructor(props) {
        super(props);

        var state = Object.assign({}, props);
        state.languages.sort(x => {
            var language = x.key;
            if (language == state.defaultLanguage) return -1;
            return 0;
        });


        this.imageDatas = {};

        this.state = Object.assign({}, props);
        this.state.newOrders = false;
    }

    componentDidMount() {
        API.post(DataEndpoints.AvailableLanguages, {}).then((result) => {
            result.result.map(x => {
                x.key = x.key.toLowerCase();
            });

            this.setState({ languages: result.result });
            this.loadPromotions();
        });
    }

    loadPromotions() {
        application().resolve(null, Endpoints.ListPromotions, {}, 'fetching promotions list', 'unable to fetch promotions list').then((result) => {
            var operatorId = this.props.user.UserPrivileges.PointOfView;
            this.loadContents(result.result.filter(x => x.OperatorId == operatorId));
        });
    }

    loadContents(promotions) {
        application().resolve(null, Endpoints.CMSGetContents, { type: 'promotions' }, 'fetching contents', 'unable to fetch contents').then((contents) => {
            contents.result.map(content => {
                if (content.key.toLowerCase().toString() !== 'rules') {
                    var promotion = promotions.find(x => x.Id.toString() == content.key);
                    if (promotion) {
                        promotion.Enabled = content.enabled;
                    }
                }
            });

            application().resolve(null, Endpoints.CMSGetContents + '/true', { type: 'website' }, 'fetching pages', 'unable to fetch pages').then((result) => {
                var contents = result.result;
                var prefix = contents.find(x => x.key == 'Prefix').content;
                this.setState({ promotions: promotions.sort((a, b) => b.Weight > a.Weight ? 1 : -1), updateTime: Date.now(), prefix: prefix });
            });

        });
    }

    openCMS(promotion) {
        this.imageDatas = {};
        application().resolve(null, Endpoints.CMSGetContent, { Type: 'promotions', Key: promotion.Id.toString(), OperatorId: promotion.OperatorId }, 'loading promotion content', 'unable to fetch promotion content').then((result) => {
            var content = JSON.parse(result.result);
            this.setState({ promotionSelected: promotion, content: content });
        });
    }

    saveContent() {
        this.image.save().then((result) => {
            console.log(result);
            if (result.status != 1) {
                application().modal.open(<ErrorModal title={'Image save failed'}><p>{lang('Unable to save image.')}</p></ErrorModal>);
                return;
            }
            var imageName = result.result.split('/').reverse()[0];
            var language = this.state.languages[this.state.selectedLanguage].key;

            var content = this.state.content;
            if (Object.keys(content) == 0) {
                this.state.languages.map(x => {
                    content[x.key] = {
                    };
                });
            }

            content[language].image = imageName;

            application().resolve(null, Endpoints.CMSSaveContent, {
                Type: 'promotions',
                Key: this.state.promotionSelected.Id,
                Content: JSON.stringify(content)
            }, 'saving promotion content', 'unable to save promotion content').then(() => {
                application().snackbar.open('promotion saved');
            });
        });
    }

    deleteContent() {
        var language = this.state.languages[this.state.selectedLanguage].key;
        var content = this.state.content;
        delete content[language];
        this.setState({ content: content, updateTime: Date.now() });
    }


    publish() {
        application().modal.open(<ConfirmationModal title='Confirmation'
            onConfirm={
                () => {
                    application().resolve(null, Endpoints.PublishPromotions, null, 'publishing promotions', 'unable to publish promotions').then(() => {
                        application().snackbar.open('promotions published');
                    });
                }
            }>
            <p>{lang('Please confirm before you publish the promotions')}</p>
        </ConfirmationModal>);
    }

    tabSelected(index) {
        this.setState({ selectedLanguage: index });
    }

    onCMSDataChange(data) {
        var language = this.state.languages[this.state.selectedLanguage].key;
        var content = this.state.content;
        content[language] = data;
        this.setState({ content: content });
    }

    handleEditorChange(context, editor) {
        var language = this.state.languages[this.state.selectedLanguage].key;
        var content = this.state.content;
        content[language].content = editor.getContent();
        this.setState({ content: content });
    }

    renderCMS() {
        var tabButtons = this.state.languages.map(x => {
            return {
                title: x.name
            };
        });

        var language = this.state.languages[this.state.selectedLanguage].key;
        var content = this.state.content;
        var data = content[language];

        var imageData = this.imageDatas[language];

        //var imageName = 'image-' + this.state.promotionSelected.Id + '-' + language;
        var imageName = data ? data.image : null;
        if (!imageName || imageName == '') imageName = `${Date.now()}.jpg`;
        var source = `http://${this.state.prefix}.tentangle.com/content/promotions/images/${imageName}`;
        if (imageData) source = imageData;
        if (!data) data = { content: '' };
        return <Card caption={this.state.promotionSelected.Name}>
            <div className='margin'>
                <Tab buttons={tabButtons} onTabSelected={this.tabSelected.bind(this)}></Tab>
                <React.Fragment>
                    <Form key={this.state.selectedLanguage + this.state.updateTime} data={data} onChange={(data) => this.onCMSDataChange(data)}>
                        <div className='seperator'>
                            <span>{lang('Images')}</span>
                        </div>
                        <ImageDrop name={imageName} newKey={true} path='content/promotions/images' key={source}
                            imageSource={source} ref={(r) => this.image = r} onImageDrop={(imageData) => {
                                this.imageDatas[language] = imageData;
                            }} />
                        <div className='seperator'>
                            <span>{lang('Information')}</span>
                        </div>
                        <div className='row'>
                            <FormInput title='Title' name='title' required={true} />
                        </div>
                        <div className='row'>
                            <FormInput title='Description' name='description' type={InputTypes.textArea} required={true} />
                        </div>
                        <div className='seperator'>
                            <span>{lang('Content')}</span>
                        </div>
                        <div className='wyiwyg-holder'>
                            <TinyMCE
                                content={data.content}
                                config={{
                                    menubar: true,
                                    plugins: 'autolink link image lists print preview code',
                                    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                }}
                                onChange={this.handleEditorChange.bind(this)}
                            />
                        </div>
                    </Form>
                    <div className='centrum-flex alignRight'>
                        <Button title={'Delete ' + this.state.languages[this.state.selectedLanguage].name} className='warning' onClick={() => this.deleteContent()} />
                        <Button title='Close' className='alignRight' onClick={() => this.setState({ promotionSelected: null, selectedLanguage: 0 })} />
                        <Button title='Save' className='alignRight' onClick={() => this.saveContent()} />
                    </div>
                </React.Fragment>
            </div>
        </Card>;
    }

    toggleEnabled(checked, promotion, component) {
        application().modal.open(<ConfirmationModal title={promotion.Name}
            onConfirm={
                () => {
                    var updatedData = Object.assign({}, promotion);
                    updatedData.Enabled = checked;

                    application().resolve(null, Endpoints.CMSSaveContent, { type: 'promotions', key: promotion.Id, enabled: checked }, 'updating content', 'unable to update').then(() => {
                        this.loadContents(this.state.promotions);
                    });
                }
            }
            onCancel={
                () => {
                    promotion.Enabled = !checked;
                    component.setValue(!checked);
                    this.setState({ cancel: true, updateTime: Date.now() });
                }
            }
        >
            {lang('Do you want to %1 publishing promotion', [checked ? 'enable' : 'disable'])}
        </ConfirmationModal>);
    }

    updateSortings() {
        var weights = [];
        this.state.promotions.filter(x => x.Enabled).map(x => {
            weights.push({ Id: x.Id, Weight: x.Weight });
        });
        API.post(PromotionEndpoints.UpdateWeights, weights, 'please wait', 'unable to update promotion weights').then(() => {
            application().snackbar.open('Updated');
            this.setState({ newOrders: false });
        });
    }

    renderPromotions() {
        var promotions = this.state.promotions;
        return <div key={this.state.updateTime}>
            {!this.state.promotionSelected &&
                <Card className='marginBottom'>
                    <div className='centrum-flex'>
                        <p className='margin'>
                            {lang('Click Publish button to update the promotions page on your website.')}
                        </p>
                        <Button title='Publish' className='alignRight margin' onClick={() => this.publish()} />
                    </div>
                </Card>
            }
            {
                !this.state.promotionSelected && this.state.newOrders && <Card className='margin-bottom'>
                    <div className='flex gap-10 padding'>
                        <Icon icon='info-circle' />
                        <span>{lang('Sortings changed, do you want to update sortings?')}</span>
                        <Button title='Update Sorting' onClick={() => {
                            this.updateSortings();
                        }} />
                    </div>
                </Card>
            }
            <Card>
                {this.state.promotionSelected && this.renderCMS()}
                {!this.state.promotionSelected &&
                    <Table
                        model={{
                            fields: [
                                { name: 'Operator', display: 'Operator' },
                                { name: 'Name', display: 'Name' },
                                { name: 'Code', display: 'Code' },
                                {
                                    name: 'Enabled',
                                    display: 'Publish',
                                    type: 'bool',
                                    render: (field, row) => {
                                        return <FormInput type={InputTypes.bool} value={row.Enabled == true} onChange={(a, b, value, component) => {
                                            this.toggleEnabled(value, row, component);
                                        }} />;
                                    }
                                }]
                        }}
                        data={promotions}
                        editable={true}
                        editableFields='Enabled'
                        key={this.state.updateTime}
                        onDataChange={(row, index, value) => {
                            var total = value.length;
                            value.map((x, i) => {
                                x.Weight = total - i;
                            });
                            this.setState({ newOrders: true, promotions: value, updateTime: Date.now() });
                        }}

                        options={{
                            draggable: {
                                enabled: true
                            },
                            selectable: false
                        }}


                        buttons={(row) => {
                            return <React.Fragment>
                                <Button key='cms' title='Edit' onClick={() => this.openCMS(row)} />
                            </React.Fragment>;
                        }}
                    />
                }
            </Card>
            {!this.state.promotionSelected &&
                <Card className='margin-top marginBottom'>
                    <div className='centrum-flex'>
                        <p className='margin'>{lang('Promotion Rules')}</p>
                        <Button title='Edit' className='alignRight margin' onClick={() => this.openCMS({ Id: 'rules' })} />
                    </div>
                </Card>
            }
        </div>;
    }

    render() {

        return <React.Fragment>
            {this.renderPromotions()}
        </React.Fragment>;
    }
}

CMSPromotions.defaultProps = {
    promotions: [],
    selectedLanguage: 0,
    languages: [

    ],
};

CMSPromotions.propTypes = {
    propmotions: PropTypes.object,
    selectedLanguage: PropTypes.number,
    defaultLanguage: PropTypes.string,
    languages: PropTypes.array,
    user: PropTypes.object
};


const mapStateToProps = state => {
    return {
        user: state.user.loginInformation,
        language: state.language.selected
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(CMSPromotions);
export { hoc as CMSPromotions };