/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../v2/Components/Controls/Button/Button';
import { Table } from '../../../../v2/Components/Controls/Table/Table';
import { emptyMargin, model } from './SportsbookMargins';
import { API, SportEndpoints } from '../../../../v2/Lib/Api/Api';
import { lang } from '../../../Centrum/Localization/lang';


const categoryModel = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'name',
            title: 'Name'
        },
        {
            name: 'markets',
            title: 'Markets',
            renderRow: (row) => {
                return Object.keys(row.markets).length;
            }
        }
    ]
};



const SportMarkets = (props) => {
    const [markets, setMarkets] = useState([]);
    const masterData = props.masterData;
    const [data, setData] = useState([]);


    const updatedRows = props.updatedRows;
    const setUpdatedRows = props.setUpdatedRows;

    const marketRef = React.createRef();

    useEffect(() => {
        API.post(`${SportEndpoints.Markets}/${props.sport.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.id > b.id);
            result.result = result.result.filter((x) => x.name !== '');

            setMarkets(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, []);

    useEffect(() => {
        if (!markets) return;
        var marketsData = props.margins.categoryMarkets[props.sport.id].markets;
        setData(Object.values(marketsData));
    }, [markets]);


    const context = {
        renderId: (row) => {
            return row.id;
        },
        renderName: (row) => {
            return row.name;
        },
        isChanged: (row) => {
            var key = props.sport.id;
            if (updatedRows.categoryMarkets[key]?.markets[`${row.id}`]) {
                return true;
            }
            return false;
        },
        addToChanges: (copy, key, payload, type = 'update') => {
            var copyOfUpdatedRows = { ...updatedRows };
            if (!masterData.categoryMarkets[props.sport.id]) {
                masterData.categoryMarkets[props.sport.id] = {
                    id: props.sport.id,
                    name: props.sport.name,
                    markets: {}
                };
            }
            if (JSON.stringify(masterData.categoryMarkets[props.sport.id].markets[key]) == JSON.stringify(copy.categoryMarkets[props.sport.id].markets[key])) {
                delete copyOfUpdatedRows.categoryMarkets[props.sport.id].markets[key];
                if (Object.keys(copyOfUpdatedRows.categoryMarkets[props.sport.id].markets).length == 0) {
                    delete copyOfUpdatedRows.categoryMarkets[props.sport.id];
                }
            } else {
                if (!copyOfUpdatedRows.categoryMarkets[props.sport.id]) {
                    copyOfUpdatedRows.categoryMarkets[props.sport.id] = { markets: {} };
                }
                copyOfUpdatedRows.categoryMarkets[props.sport.id].markets[key] = { ...payload };
                copyOfUpdatedRows.categoryMarkets[props.sport.id].markets[key]._type = type;
            }
            setUpdatedRows(copyOfUpdatedRows);
        },
        update: (row, value, field) => {
            var key = `${props.sport.id}_${row.id}`;
            const target = field.name;
            var copy = { ...props.margins };
            if (!copy.categoryMarkets[props.sport.id].markets[key]) {
                copy.categoryMarkets[props.sport.id].markets[key] = emptyMargin();
            }
            copy.categoryMarkets[props.sport.id].markets[key][target] = (value == true || value == false) ? value : parseFloat(value);
            props.setMargins(copy);

            context.addToChanges(copy, key, copy.categoryMarkets[props.sport.id].markets[key]);
        }
    };


    const remove = (row) => {
        var copy = { ...props.margins };
        var key = `${props.sport.id}_${row.id}`;
        delete copy.categoryMarkets[props.sport.id].markets[key];
        props.setMargins(copy);
        setMarkets([...markets]);
        context.addToChanges(copy, key, update(row, true), 'delete');
    };

    const update = (row, empty) => {
        var payload = {
            categoryMarkets: {

            }
        };

        payload.categoryMarkets[props.sport.id] = { markets: {} };
        payload.categoryMarkets[props.sport.id].markets[`${props.sport.id}_${row.id}`] = empty ? emptyMargin() : row;

        return payload.categoryMarkets[props.sport.id].markets[`${props.sport.id}_${row.id}`];
    };


    const addMarket = () => {
        var marketId = parseInt(marketRef.current.value);
        var market = markets.find(x => x.id == marketId);
        var key = `${props.sport.id}_${marketId}`;

        var copy = { ...props.margins };

        if (!copy.categoryMarkets[props.sport.id].markets[key]) {
            copy.categoryMarkets[props.sport.id].markets[key] = emptyMargin();
            copy.categoryMarkets[props.sport.id].markets[key].id = market.id;
            copy.categoryMarkets[props.sport.id].markets[key].name = market.name;
        }
        props.setMargins(copy);
        setData(Object.values(copy.categoryMarkets[props.sport.id].markets));
    };

    return <div className='flex vertical gap-10 margin-top'>
        <div className='panel'>
            <div className='body padding'>
                <div className='flex gap-5'>
                    <span>{props.sport.name}</span>
                    <select ref={marketRef}>
                        {markets.map(x => {
                            return <option key={x.id} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                    <Button title='Add' onClick={() => addMarket()} />
                    <Button title='Close' className='align-right' onClick={() => props.close()} />
                </div>
            </div>
        </div>
        <Table model={model}
            data={data}
            context={context}
            onRenderRow={(row, elements, className, context) => {
                var c = context.isChanged(row) ? 'changed' : className;
                return <tr className={c}>
                    {elements}
                </tr>;
            }}
            buttons={(row) => {
                return <div className='flex gap-10'>
                    <Button title='Remove' onClick={() => remove(row)} />
                </div>;
            }}
        />
    </div>;
};

SportMarkets.propTypes = {
    sport: PropTypes.object,
    margins: PropTypes.object,
    masterData: PropTypes.object,
    setMargins: PropTypes.func,
    close: PropTypes.func,
    updatedRows: PropTypes.object,
    setUpdatedRows: PropTypes.func
};


export const SportMarketMargins = (props) => {
    const [selectedSport, selectSport] = useState(null);

    const [data, setData] = useState([]);
    const [updatedRows, setUpdatedRows] = useState({ categoryMarkets: {} });

    const sportRef = React.createRef();

    useEffect(() => {
        setData(Object.values(props.margins.categoryMarkets));
    }, []);

    const context = {
        renderId: (row) => {
            return row.id;
        },
        renderName: (row) => {
            return row.name;
        },
        update: (row, value, field) => {
            var key = row.id;
            const target = field.name;
            var copy = { ...props.margins };
            if (!copy.categoryMarkets[key]) {
                copy.categoryMarkets[key] = {
                    preMargin: 0,
                    preMin: 0,
                    preMax: 0,
                    liveMargin: 0,
                    liveMin: 0,
                    liveMax: 0
                };
            }
            copy.categoryMarkets[key][target] = (value == true || value == false) ? value : parseFloat(value);
            props.setMargins(copy);
        }
    };

    const add = () => {
        var sport = props.sports.find(x => x.id == parseInt(sportRef.current.value));
        var copy = { ...props.margins };
        var key = sport.id;
        if (!copy.categoryMarkets[key]) {
            copy.categoryMarkets[key] = emptyMargin();
            copy.categoryMarkets[key].id = sport.id;
            copy.categoryMarkets[key].name = sport.name;
            copy.categoryMarkets[key].markets = {};
        }

        setData(Object.values(copy.categoryMarkets));
        props.setMargins(copy);
        selectSport(sport);
    };

    const remove = (row) => {
        var copy = { ...props.margins };
        var key = row.id;

        var updatedCopy = { ...updatedRows };
        updatedCopy.categoryMarkets[key] = copy.categoryMarkets[key];

        delete copy.categoryMarkets[key];
        props.setMargins(copy);
        setData(Object.values(copy.categoryMarkets));

        Object.keys(updatedCopy.categoryMarkets[key].markets).map(x => {
            updatedCopy.categoryMarkets[key].markets[x] = emptyMargin();
        });

        setUpdatedRows(updatedCopy);
    };

    return <div className='sport-margins'>
        <div className='content'>
            <div className={`panel ${selectedSport ? 'disabled' : ''}`}>
                <div className='padding flex gap-5'>
                    <div className='flex gap-10'>
                        <select ref={sportRef}>
                            {props.sports.sort((a, b) => a.weight > b.weight ? 1 : -1).map(x => {
                                if (props.margins.categoryMarkets[x.id]) return;
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })}
                        </select>
                        <Button title='Add' onClick={() => add()} />
                    </div>
                </div>
            </div>
            {!selectedSport && <Table model={categoryModel}
                data={data}
                context={context}
                buttons={(row) => {
                    return <div className='flex gap-5'>
                        <Button title='Edit' onClick={() => selectSport(row)} />
                        <Button title='Remove' onClick={() => remove(row)} />;
                    </div>;
                }}
            />
            }
            {selectedSport && <SportMarkets sport={selectedSport} {...props} close={() => selectSport(null)} updatedRows={updatedRows} setUpdatedRows={setUpdatedRows} />}
        </div>
        <div className='panel padding'>
            {Object.keys(updatedRows.categoryMarkets).length > 0 ? <Button title={lang('Save %1 changes', [Object.keys(updatedRows.categoryMarkets).length])} onClick={() => {
                props.save(updatedRows);
            }} /> : <Button title='Save' className='disabled' />}
        </div>
    </div>;
};

SportMarketMargins.propTypes = {
    sports: PropTypes.array,
    margins: PropTypes.object,
    masterData: PropTypes.object,
    setMargins: PropTypes.func,
    save: PropTypes.func
};