
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


import { Page } from '../../Centrum/Page/Page';
import { application, Endpoints } from '../../Application/Application';
import { Card } from '../../Centrum/Card/Card';
import { Button } from '../../Controls/Buttons/Button';
import { ConfirmationModal } from '../../Modal/Modals/Confirmation/ConfirmationModal';
import { FormInput } from '../../Controls/Form/FormInput';
import { InputTypes } from '../../Centrum/CentrumForm/Inputs/CentrumInput';
import { AffiliateProfile } from './AffiliateProfile';
import { getHistory } from '../../routers/AppRouter';
import { lang } from '../../Centrum/Localization/lang';
import { Icon } from '../../Controls/Icon/Icon';
import { Table } from '../../../v2/Components/Controls/Table/Table';
import { AffiliatesModel } from '../../Centrum/_models/AffiliatesModel';


const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const randomString = (length) => {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};




export const Affiliates = () => {
    const [affiliates, setAffiliates] = useState([]);
    const [selectedAffiliate, selectAffiliate] = useState(null);
    const [showDisabled, setShowDisabled] = useState(false);

    useEffect(() => {

    }, []);

    useEffect(() => {
        loadAffiliates();
    }, [showDisabled]);

    const loadAffiliates = (page) => {
        application().resolve(null, Endpoints.ListAffiliates, {
            currentPage: page ?? 0,
            recordsPerPage: 1000,
            fields: [
                {
                    name: 'Enabled',
                    value: showDisabled ? 'false' : 'true'
                }
            ]
        }, 'retreiving affiliates', 'unable to fetch affiliates').then((result) => {
            //var selected = result.result[1];

            var path = window.location.pathname;
            path = path.split('/');
            var selected = null;
            if (path[path.length - 2].toLowerCase() == 'profile') {
                var id = parseInt(path[path.length - 1]);
                selected = result.result.find(x => x.Id == id);
            }

            setAffiliates(result);
            selectAffiliate(selected);
        });
    };


    const renderAffiliateProfile = () => {
        return <Card caption={selectedAffiliate.Name}
            icon={<div className='marginRight link' onClick={() => {
                selectAffiliate(null);
                getHistory().goBack();
            }}><Icon icon={'arrow-left'} />{lang('Back')}</div>}>
            <AffiliateProfile affiliate={selectedAffiliate} />
        </Card>;
    }

    const addNew = (data) => {
        if (!data) {
            data = {
                Link: randomString(6)
            };
        }



        application().resolve(null, Endpoints.ListAffiliateGroups, {}, 'fetching groups', 'unable to fetch affiliate groups').then((result) => {
            var groups = {};
            result.result.map(x => groups[x.Id] = x.Name);
            if (!data.Group) {
                data.Group = Object.keys(groups)[0];
            }

            application().modal.open(<ConfirmationModal
                onConfirm={() => {
                    application().resolve(null, Endpoints.SaveAffiliate, data, 'saving please wait', 'unable to save').then(() => {
                        loadAffiliates();
                    }).catch(() => {
                        addNew(data);
                    });
                }}
                title='Add new'>
                <form className='form'>
                    <div className='row'>
                        <FormInput name='Group' title='Group' type={InputTypes.select} values={groups} required={true} value={data.Group} onChange={(f, v, value) => {
                            data.Group = value;
                        }} />
                    </div>
                    <div className='row'>
                        <FormInput name='Name' title='Name' required={true} value={data.Name} onChange={(f, v, value) => {
                            data.Name = value;
                        }} />
                    </div>
                    <div className='row'>
                        <FormInput name='Description' title='Description' type={InputTypes.textArea} value={data.Description} onChange={(f, v, value) => {
                            data.Description = value;
                        }} />
                    </div>
                    <div className='row'>
                        <FormInput name='Link' title='Link' type={InputTypes.text} value={data.Link} onChange={(f, v, value) => {
                            data.Link = value;
                        }} />
                    </div>
                    <div className='row'>
                        <FormInput name='Enabled' title='Enabled' type={InputTypes.bool} value={data.Enabled} onChange={(f, v, value) => {
                            data.Enabled = value;
                        }} />
                    </div>
                </form>
            </ConfirmationModal>);
        });
    }

    if (selectedAffiliate) {
        return renderAffiliateProfile();
    }

    return <Page>
        <Card caption='Affiliates' menu={
            <div className='flex gap-5'>
                <Button title={showDisabled ? 'Show Enabled' : 'Show Disabled'} onClick={() => setShowDisabled(!showDisabled)} />
                <Button title='Add New' onClick={() => addNew()} className='button' />
            </div>
        }>
            <Table model={AffiliatesModel.affiliates} data={affiliates.result} key={affiliates} options={
                {
                    pagination: {
                        rows: 20,
                        total: affiliates.total,
                        showPages: true
                    }
                }
            } buttons={(row) => {
                return <React.Fragment>
                    <Button key='edit' title='Edit' onClick={() => addNew(row)} />
                    <Button key='profile' title='Profile' onClick={() => {
                        var path = window.location.pathname;
                        if (path.toLowerCase().indexOf('profile') == -1) {
                            if (path[path.length - 1] != '/') path += '/';
                            getHistory().push(path + 'profile/' + row.Id);
                        }

                        selectAffiliate(row);
                    }} />
                </React.Fragment>;
            }} />
        </Card>
    </Page>;
};

Affiliates.defaultProps = {
    affiliates: []
};

Affiliates.propTypes = {
    affiliates: PropTypes.array
};

