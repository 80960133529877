import React from 'react';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { DateRanges } from '../../../Centrum/CentrumForm/Inputs/Pickers/DateTimePicker';
import { currency } from '../../../Centrum/Localization/lang';
import { TestAccountFilter } from '../../../Centrum/_models/ModelEnums';

const currencyAndFooter = (val, row, isFooter, isExporting) => {
    if (!val) return;
    if (isFooter) {

        if (Object.keys(val).length > 0) {
            var results = [];
            results = Object.keys(val).map(key => {
                if (isExporting) {
                    if (isExporting) return val[key] + key;
                }
                return <p key={key}>{currency(val[key], 2, key, true)}</p>;
            });
            if (isExporting) return results;
            return results;
        } else {
            if (isExporting) return val;
            return currency(val, 2, row.Currency, false);
        }
    } else {
        if (isExporting) return val;
        return currency(val, 2, row.Currency, false);
    }
};

export const searchHorseBettingReport = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'operators',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'EntryDate',
            display: 'Date',
            type: InputTypes.dateTime,

            queryBuilderOptions: {
                Between: true,
                data: DateRanges.Today
            }
        },
        {
            name: 'Status',
            display: 'Status',
            type: InputTypes.select,
            acceptEmptyValues: true,
            values: {
                0: 'Open',
                1: 'Won',
                2: 'Lost',
                4: 'Cancel',
                5: 'Cashback'
            }
        },
        {
            name: 'ExternalTicketId',
            display: 'Ticket Id'
        },
        {
            name: 'EventId',
            display: 'Event Id'
        },
        {
            name: 'PlayerId',
            display: 'Player Id',
            type: InputTypes.number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};


export const searchHorseBettingVenuesReport = () => {
    var b = Object.assign([], searchHorseBettingReport.fields);
    b.push({ name: 'Venues', display: 'Venue' });
    return {
        fields: b
    };
};


export const horseRevenueSummaryResult = {
    fields: [
        {
            name: 'Operator',
            display: 'Operator'
        },
        {
            name: 'Slips',
            display: 'Slips',
            formatter: (val) => {
                return val;
            }
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'OverallStake',
            display: 'Overall Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallWin',
            display: 'Overall Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallGGR',
            display: 'Overall GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Stake',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusBet',
            display: 'Bonus Bet',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusWin',
            display: 'Bonus Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusLiability',
            display: 'Bonus Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusGGR',
            display: 'Bonus GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        }
    ]
};


export const horseRegionReportsResult = {
    fields: [
        {
            name: 'Operator'
        },
        {
            name: 'Country'
        },
        {
            name: 'Slips',
            display: 'Slips',
            formatter: (val) => {
                return val;
            }
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'OverallStake',
            display: 'Overall Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallWin',
            display: 'Overall Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallGGR',
            display: 'Overall GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Stake',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusBet',
            display: 'Bonus Bet',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusWin',
            display: 'Bonus Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusLiability',
            display: 'Bonus Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusGGR',
            display: 'Bonus GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        }
    ]
};



export const horseVenueReportsResult = {
    fields: [
        {
            name: 'Country'
        },
        {
            name: 'Venues',
            display: 'Venue'
        },
        {
            name: 'Slips',
            display: 'Slips',
            formatter: (val) => {
                return val;
            }
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'OverallStake',
            display: 'Overall Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallWin',
            display: 'Overall Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallGGR',
            display: 'Overall GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Stake',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusBet',
            display: 'Bonus Bet',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusWin',
            display: 'Bonus Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusLiability',
            display: 'Bonus Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusGGR',
            display: 'Bonus GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        }
    ]
};

export const horseDistanceReportsResult = {
    fields: [
        {
            name: 'Country'
        },
        {
            name: 'Venues',
            display: 'Venue'
        },
        {
            name: 'Distance'
        },
        {
            name: 'Slips',
            display: 'Slips',
            formatter: (val) => {
                return val;
            }
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'OverallStake',
            display: 'Overall Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallWin',
            display: 'Overall Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallGGR',
            display: 'Overall GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Stake',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusBet',
            display: 'Bonus Bet',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusWin',
            display: 'Bonus Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusLiability',
            display: 'Bonus Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusGGR',
            display: 'Bonus GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        }
    ]
};
export const horsePlayerRevenues = {
    fields: [
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Username',
            display: 'Username'
        },
        {
            name: 'Slips',
            display: 'Slips',
            formatter: (val) => {
                return val;
            }
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'OverallStake',
            display: 'Overall Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallWin',
            display: 'Overall Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'OverallGGR',
            display: 'Overall GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Stake',
            display: 'Stake',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Win',
            display: 'Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'GGR',
            display: 'GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'Liability',
            display: 'Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusBet',
            display: 'Bonus Bet',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusWin',
            display: 'Bonus Win',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusLiability',
            display: 'Bonus Liability',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        },
        {
            name: 'BonusGGR',
            display: 'Bonus GGR',
            type: InputTypes.number,
            formatter: (val, row, isFooter, isExporting) => {
                return currencyAndFooter(val, row, isFooter, isExporting);
            }
        }
    ]
};