import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card } from '../../../../Centrum/Card/Card';
import { Button } from '../../../../Controls/Buttons/Button';
import { application, Endpoints } from '../../../../Application/Application';
import { ConfirmationModal } from '../../../../Modal/Modals/Confirmation/ConfirmationModal';
import { Form } from '../../../../Controls/Form/Form';
import { FormInput } from '../../../../Controls/Form/FormInput';
import { InputTypes } from '../../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { toPascalCase } from '../../../../Centrum/helpers';
import { currency } from '../../../../Centrum/Localization/lang';


const addCredit = (retail) => {
    var form = null;
    application().modal.open(<ConfirmationModal
        onConfirm={() => {
            var data = form.submit();
            data.retailId = retail.Id;
            application().resolve('_retail', Endpoints.AddRetailCredit, data, 'please wait', 'unable to remove credit', true).then(() => {
                application().snackbar.open('Credit added');
            });
        }}
        title='Add Credit'>
        <Form ref={(r) => form = r}>
            <div className='row'>
                <FormInput title='Amount' name='Amount' type={InputTypes.number} />
            </div>
            <div className='row'>
                <FormInput title='Notes' name='Notes' type={InputTypes.textArea} />
            </div>
        </Form>
    </ConfirmationModal>);
};


const removeCredit = (retail) => {
    var form = null;
    application().modal.open(<ConfirmationModal
        onConfirm={() => {
            var data = form.submit();
            data.retailId = retail.Id;
            application().resolve('_retail', Endpoints.RemoveRetailCredit, data, 'please wait', 'unable to remove credit', true).then(() => {
                application().snackbar.open('Credit removed');
            });
        }}
        title='Add Credit'>
        <Form ref={(r) => form = r}>
            <div className='row'>
                <FormInput title='Amount' name='Amount' type={InputTypes.number} />
            </div>
            <div className='row'>
                <FormInput title='Notes' name='Notes' type={InputTypes.textArea} />
            </div>
        </Form>
    </ConfirmationModal>);
};

const RetailSummary = (props) => {
    if (!props.retail) return <React.Fragment />;
    const c = props.retail.DefaultCurrency;
    return <div className='centrum-flex vertical padding'>
        <div className='centrum-flex'>
            <Card caption='Summary' className>
                <div className='padding groups borders col2'>
                    <div className='group'>
                        <label>Balance</label><span>{currency(props.retail.Balance, 2, c)}</span>
                    </div>
                    <div className='group'>
                        <label>Players</label><span>{props.retail.TotalPlayers}</span>
                    </div>
                </div>
                <div className='centrum-flex padding center'>
                    <Button title='Add Credit' onClick={() => {
                        addCredit(props.retail);
                    }} />
                    <Button title='Remove Credit' onClick={() => {
                        removeCredit(props.retail);
                    }} />
                </div>
            </Card>
            <Card caption='Sports'>
                <div className='padding groups borders col2'>
                    <div className='group'>
                        <label>Open</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Win</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Cashout</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Lost</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Profit</label><span>0</span>
                    </div>
                </div>
            </Card>
            <Card caption='Horse'>
                <div className='padding groups borders'>
                    <div className='group'>
                        <label>Open</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Win</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Cashout</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Lost</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Profit</label><span>0</span>
                    </div>
                </div>
            </Card>
            <Card caption='Casino'>
                <div className='padding groups borders'>
                    <div className='group'>
                        <label>Bet</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Cancel</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>Win</label><span>0</span>
                    </div>
                    <div className='group'>
                        <label>GGR</label><span>0</span>
                    </div>
                </div>
            </Card>
        </div>
        <div className='centrum-flex'>
            <Card caption='GGR'>

            </Card>
        </div>
    </div>;
};

RetailSummary.propTypes = {
    retail: PropTypes.object,
    users: PropTypes.objcet
};



const mapStateToProps = state => {
    return {
        retail: toPascalCase(state.data.retail ? state.data.retail : {})
    };
};
const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(RetailSummary);
export { hoc as RetailSummary };