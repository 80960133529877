import { postData } from '../services/ServiceHelpers';
import { application, Endpoints } from '../../components/Application/Application';

export const SEARCH_PLAYER = 'SEARCH_PLAYER';
export const SEARCH_PLAYER_PENDING = 'SEARCH_PLAYER_PENDING';
export const SEARCH_PLAYER_FULFILLED = 'SEARCH_PLAYER_FULFILLED';
export const SEARCH_PLAYER_REJECTED = 'SEARCH_PLAYER_REJECTED';


export const GET_PLAYER = 'GET_PLAYER';
export const GET_PLAYER_PENDING = 'GET_PLAYER_PENDING';
export const GET_PLAYER_FULFILLED = 'GET_PLAYER_FULFILLED';
export const GET_PLAYER_REJECTED = 'GET_PLAYER_REJECTED';

export const GET_PLAYER_PROFILE = 'GET_PLAYER_PROFILE';
export const GET_PLAYER_PROFILE_PENDING = 'GET_PLAYER_PROFILE_PENDING';
export const GET_PLAYER_PROFILE_FULFILLED = 'GET_PLAYER_PROFILE_FULFILLED';
export const GET_PLAYER_PROFILE_REJECTED = 'GET_PLAYER_PROFILE_REJECTED';


export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PLAYER_PENDING = 'UPDATE_PLAYER_PENDING';
export const UPDATE_PLAYER_FULFILLED = 'UPDATE_PLAYER_FULFILLED';
export const UPDATE_PLAYER_REJECTED = 'UPDATE_PLAYER_REJECTED';

export const SEARCH_PLAYER_GAME_HISTORY = 'SEARCH_PLAYER_GAME_HISTORY';
export const SEARCH_PLAYER_GAME_HISTORY_PENDING = 'SEARCH_PLAYER_GAME_HISTORY_PENDING';
export const SEARCH_PLAYER_GAME_HISTORY_FULFILLED = 'SEARCH_PLAYER_GAME_HISTORY_FULFILLED';
export const SEARCH_PLAYER_GAME_HISTORY_REJECTED = 'SEARCH_PLAYER_GAME_HISTORY_REJECTED';

export const SEARCH_PURSE = 'SEARCH_PURSE';
export const SEARCH_PURSE_PENDING = 'SEARCH_PURSE_PENDING';
export const SEARCH_PURSE_FULFILLED = 'SEARCH_PURSE_FULFILLED';
export const SEARCH_PURSE_REJECTED = 'SEARCH_PURSE_REJECTED';

export const CLOSE_PURSE = 'CLOSE_PURSE';
export const CLOSE_PURSE_PENDING = 'CLOSE_PURSE_PENDING';
export const CLOSE_PURSE_FULFILLED = 'CLOSE_PURSE_FULFILLED';
export const CLOSE_PURSE_REJECTED = 'CLOSE_PURSE_REJECTED';


export const SEARCH_PLAYER_ACTIVITY = 'SEARCH_PLAYER_ACTIVITY';
export const SEARCH_PLAYER_ACTIVITY_PENDING = 'SEARCH_PLAYER_ACTIVITY_PENDING';
export const SEARCH_PLAYER_ACTIVITY_FULFILLED = 'SEARCH_PLAYER_ACTIVITY_FULFILLED';
export const SEARCH_PLAYER_ACTIVITY_REJECTED = 'SEARCH_PLAYER_ACTIVITY_REJECTED';

export const GET_REPLAY = 'GET_REPLAY';
export const GET_REPLAY_PENDING = 'GET_REPLAY_PENDING';
export const GET_REPLAY_FULFILLED = 'GET_REPLAY_FULFILLED';
export const GET_REPLAY_REJECTED = 'GET_REPLAY_REJECTED';



// ACTION CREATORS

function searchPlayerAction(query) {
    return {
        type: SEARCH_PLAYER,
        payload: postData(query, Endpoints.PlayerSearch)
    };
}

function searchPlayerGameHistoryAction(query) {
    return {
        type: SEARCH_PLAYER_GAME_HISTORY,
        payload: postData(query, Endpoints.PlayerSearchGameHistory)
    };
}

function searchActivityAction(query) {
    return {
        type: SEARCH_PLAYER_ACTIVITY,
        payload: postData(query, Endpoints.SearchPlayerActivity)
    };
}

function getPlayerAction(id) {
    return {
        type: GET_PLAYER,
        payload: postData(null, Endpoints.PlayerGetAction + id)
    };
}

export function getProfile(id, currency) {
    return application().resolve(GET_PLAYER_PROFILE, Endpoints.GetProfile + id + '/' + currency + '/', {}, 'loading profile', 'unable to load profile');
}

function updatePlayerAction(data) {
    return {
        type: UPDATE_PLAYER,
        payload: postData(data, Endpoints.PlayerUpdate)
    };
}




// EXPORT ACTIONS
export { searchPlayerAction as searchPlayer };
export { getPlayerAction as getPlayer };
export { updatePlayerAction as updatePlayer };
export { searchPlayerGameHistoryAction as searchPlayerGameHistory };
export { searchActivityAction as searchActivity };

