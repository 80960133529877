import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Card } from '../../../Centrum/Card/Card';
import { Search } from '../../../Centrum/Search/Search';
import { InputTypes } from '../../../Centrum/CentrumForm/Inputs/CentrumInput';
import { TestAccountFilter } from '../../../Centrum/_models/ModelEnums';
import { currency } from '../../../Centrum/Localization/lang';
import { apiResult, Endpoints } from '../../../Application/Application';
import { ActionTypes, getAction } from '../../../Centrum/Common/ActionManager';
import { getHistory } from '../../../routers/AppRouter';




const searchModel = {
    fields: [
        {
            name: 'Username',
            type: InputTypes.text,
            hidden: true
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            display: 'Retail Code'
        },
        {
            name: 'Group',
            display: 'Player Group',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'myPlayerGroups',
                bindingKey: 'Id',
                bindingText: ['Name']
            }
        },
        {
            name: 'Currency',
            type: InputTypes.select,
            acceptEmptyValues: true,
            valueEndPoint: {
                source: 'EnabledCurrencies',
                bindingKey: 'CurrencyCode',
                bindingText: ['CurrencyCode']
            }
        },
        {
            name: 'Retails',
            display: 'Only Retails',
            type: InputTypes.bool
        },
        {
            name: 'TestAccounts',
            display: 'Test Accounts',
            type: InputTypes.select,
            value: 0,
            values: TestAccountFilter
        }
    ]
};

const resultModel = {
    fields: [
        {
            name: 'PlayerId',
            display: 'Player Id'
        },
        {
            name: 'Code',
            display: 'Code'
        },
        {
            name: 'Currency',
            display: 'Currency'
        },
        {
            name: 'Username'
        },
        {
            name: 'PlayerCode',
            display: 'Player Code'
        },
        {
            name: 'Group',
            display: 'Player Group'
        },
        {
            name: 'Balance',
            display: 'Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        },
        {
            name: 'BonusBalance',
            display: 'Bonus Balance',
            formatter: (val, row, isFooter, isExporting) => {
                if (isExporting) return val;
                return currency(val, 2, row.Currency, false);
            }
        }
    ]
};

export const PlayerWithoutBonus = () => {
    const data = useSelector((state) => state.data);
    useEffect(() => {
    }, []);

    const onActionClicked = (action, items) => {
        var player = items[0];
        switch (action.type) {
            case ActionTypes.PlayerProfile:
                var location = `/Player/playerShortcuts/profile//${player.PlayerId}`;
                getHistory().push({
                    pathname: location,
                    state: { id: player.Id, profile: true }
                });
                break;
        }

    };

    return <Card caption='Players Without Bonus'>
        <Search
            multipleSelect={true}
            model={searchModel}
            resultModel={resultModel}
            onActionClicked={onActionClicked.bind(this)}
            actions={getAction(ActionTypes.SearchPlayersWithoutBonus).convertToItems()}
            data={data.playerWithoutBonus}
            onSubmit={(data, qb) => {
                return apiResult('playerWithoutBonus',
                    Endpoints.SearchPlayersWithoutBonus, qb, 'fetching reports', 'unable to fetch report');
            }}
        />
    </Card>;
};


