// INITIALIZE STATE

const initialState = {
    menu: {
        minimized: false
    }
};




// REDUCER
export const SettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_MENU':
            state.menu.minimized = action.payload;
            return { ...state };
    }
    return state;
};