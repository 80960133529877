import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import { getActions, ActionTypes } from '../../Centrum/Common/ActionManager';
import { CentrumApplication, CentrumApplicationActions, CentrumApplicationContents, ApplicationContext } from '../../Centrum/Application/CentrumApplication';
import { Page } from '../../Centrum/Page/Page';
import { ExchangeRates } from '../Configuration/ExchangeRates';
import { application, Endpoints } from '../../Application/Application';
import { TopLeagues } from '../Sports/Configuration/TopLeagues';
import { TopEvents } from '../Sports/Configuration/TopEvents';
import { EventCodes } from '../Sports/Configuration/EventCodes/EventCodes';
import { SetupCategories } from './SetupCategories';
import { SetupRegions } from './SetupRegions';
import { SetupLeagues } from './SetupLeagues';
import { SetupEvents } from './SetupEvents';
import { PaymentProvidersPage } from './PaymentProviders/PaymentProvidersPage';
import { SetupLanguages } from './SetupLanguages';
import { SetupSportTaxes } from './SetupSportTaxes';
import { LeagueWeights } from '../Sports/Configuration/LeagueWeights';
import { SetupMarketGroups } from './SetupMarketGroups';
import { SetupOverviewMarkets } from './SetupOverviewMarkets';

const applicationActionType = ActionTypes.Setup;
const applicationActions = getActions(applicationActionType);



// COMPONENT
class SetupPage extends Page {
    constructor(props) {
        super(props);

        var action = applicationActions.getActionFromRoute();
        let state = Object.assign({}, props);

        state.action = action;

        this.state = state;
        this.applicationContext = null;
    }

    componentDidMount() {
        application().resolve(null, Endpoints.SportCategories, {}, 'fetching categories', 'unable to fetch categories').then((result) => {
            const categories = result.result.sort((a, b) => a.weight - b.weight);
            this.setState({ categories: categories });
        });
    }

    render() {
        if (!this.state.categories) return <div />;
        return (
            <CentrumApplication
                caption='Setup' icon='wrench'
                type={applicationActionType}
                loading={this.props.loading}
            >
                <ApplicationContext.Consumer>
                    {
                        (context) => {
                            this.applicationContext = context;
                            return <React.Fragment>
                                <CentrumApplicationActions actions={applicationActions} />
                                <CentrumApplicationContents actions={applicationActions} renderAll={false}>
                                    <SetupCategories categories={this.state.categories} />
                                    <SetupRegions categories={this.state.categories} />
                                    <SetupLeagues categories={this.state.categories} />
                                    <SetupEvents categories={this.state.categories} />
                                    <SetupMarketGroups categories={this.state.categories} />
                                    <SetupOverviewMarkets categories={this.state.categories} />
                                    <TopLeagues categories={this.state.categories} />
                                    <TopEvents categories={this.state.categories} />
                                    <LeagueWeights categories={this.state.categories} />
                                    <EventCodes categories={this.state.categories} />
                                    <ExchangeRates />
                                    <PaymentProvidersPage />
                                    <SetupLanguages />
                                    <SetupSportTaxes />
                                </CentrumApplicationContents>
                            </React.Fragment>;
                        }
                    }
                </ApplicationContext.Consumer>
            </CentrumApplication>
        );
    }
}

SetupPage.defaultProps = {
    configuration: {}
};

SetupPage.propTypes = {
    categories: PropTypes.object
};

const mapStateToProps = () => {
    return {
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(SetupPage);
export { hoc as SetupPage };

